export const sharedStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  fontFamily: 'Sarabun, arial, sans-serif',
  color: '#000000',
  lineHeight: '24px',
};

export const variantStylesLookup = {
  oval: {
    width: '100%',
    height: '57px',
    border: 'none',
    borderRadius: '100px',
    fontWeight: '600',
    fontSize: '20px',
  },
  rectangle: {
    width: 'calc(100% - 8px)',
    height: '76px',
    border: '4px solid #FFFFFF',
    borderRadius: '8px',
    fontWeight: '700',
    fontSize: '16px',
  },
};

export const rectangleSubVariantStylesLookup = {
  shortImageBelow: {
    paddingTop: '30px',
    height: '127px',
  },
  longImageBelow: {
    paddingTop: '30px',
    height: '198px',
  },
};

export const selectedStylesLookup = {
  oval: {
    backgroundColor: '#F7AE41',
    color: '#FFFFFF',
  },
  rectangle: {
    border: '4px solid #F7AE41',
  },
};

export const contentStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
};
