/* eslint-disable import/prefer-default-export */
export const quizData = [
  {
    label: 'MBTI Myers-Briggs',
    value: 'MBTI Myers-Briggs',
    drawerHeader: 'What was your personality type?',
    subQuestions: [
      {
        label: 'What was your personality type?',
        value: 'What was your personality type?',
        inputIconImage: 'meyersBriggs',
        inputPlaceholder: 'Personality Type',
        options: [
          'ISTJ',
          'ISFJ',
          'INFJ',
          'INTJ',
          'ISTP',
          'ISFP',
          'INFP',
          'INTP',
          'ESTP',
          'ESFP',
          'ENFP',
          'ENTP',
          'ESTJ',
          'ESFJ',
          'ENFJ',
          'ENTJ',
        ],
      },
    ],
  },
  {
    label: 'Enneagram',
    value: 'Enneagram',
    drawerHeader: 'What was your result?',
    subQuestions: [
      {
        label: 'Enneagram Primary',
        value: 'Enneagram Primary',
        inputIconImage: 'hexagon',
        inputPlaceholder: 'Enneagram Type',
        options: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'],
      },
      {
        label: 'Enneagram Wing',
        value: 'Enneagram Wing',
        inputIconImage: 'wing',
        inputPlaceholder: 'Enneagram Wing',
        options: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'],
      },
    ],
  },
  {
    label: 'HIGH5',
    value: 'HIGH5',
    drawerHeader: 'What is your HIGH5 profile?',
    subQuestions: [
      {
        label: 'What is your HIGH5 profile?',
        value: 'What is your HIGH5 profile?',
        inputIconImage: 'high5',
        inputPlaceholder: 'HIGH5',
        maxNumberOfChoices: 5,
        options: [
          'Believer',
          'Deliverer',
          'Focus Expert',
          'Problem Solver',
          'Chameleon',
          'Coach',
          'Empathizer',
          'Optimist',
          'Peace Keeper',
          'Catalyst',
          'Commander',
          'Self-Believer',
          'Storyteller',
          'Winner',
          'Analyst',
          'Brainstormer',
          'Philomath',
          'Strategist',
          'Thinker',
        ],
      },
    ],
  },
  {
    label: 'StrengthsFinder',
    value: 'StrengthsFinder',
    drawerHeader: 'What are your top 5 strengths?',
    subQuestions: [
      {
        label: 'What are your top 5 strengths?',
        value: 'What are your top 5 strengths?',
        inputIconImage: 'strength',
        inputPlaceholder: 'Strengths',
        maxNumberOfChoices: 5,
        options: [
          'Achiever',
          'Activator',
          'Adaptability',
          'Analytical',
          'Arranger',
          'Belief',
          'Command',
          'Communication',
          'Competition',
          'Connectedness',
          'Consistency',
          'Context',
          'Developer',
          'Discipline',
          'Empathy',
          'Focus',
          'Futuristic',
          'Harmony',
          'Ideation',
          'Includer',
          'Individualization',
          'Input',
          'Intellection',
          'Learner',
          'Maximizer',
          'Positivity',
          'Relator',
          'Responsibility',
          'Restorative',
          'Self-Assurance',
          'Significance',
          'Strategic',
          'Woo',
        ],
      },
    ],
  },
  {
    label: 'Astrology',
    value: 'Astrology',
    drawerHeader: 'What is your star sign?',
    subQuestions: [
      {
        label: 'What is your star sign?',
        value: 'What is your star sign?',
        inputIconImage: 'zodiac',
        inputPlaceholder: 'Astrological Sign',
        options: [
          'Aries',
          'Taurus',
          'Gemini',
          'Cancer',
          'Leo',
          'Virgo',
          'Libra',
          'Scorpius',
          'Sagittarius',
          'Capricorn',
          'Aquarius',
          'Pisces',
        ],
      },
    ],
  },
];
