import React from 'react';
import useFeatureFlags from '../hooks/useFeatureFlags';
import { useAuthContext } from './auth-provider';

export const FeatureFlagContext = React.createContext(null);

export const FeatureFlagsProvider = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const featureFlags = useFeatureFlags(isAuthenticated);
  const [state, setState] = React.useState({});

  React.useEffect(() => {
    if (featureFlags && Object.keys(featureFlags).length) {
      setState(featureFlags);
    }
  }, [featureFlags]);

  return (
    <FeatureFlagContext.Provider value={state}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export function useFeatureFlagsContext() {
  return React.useContext(FeatureFlagContext);
}
