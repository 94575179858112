import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';
import { childhoodData } from './childhoodData';

const ProfileRelationshipsIntakeChildhoodPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={childhoodData}
    itemVariant="rectangle"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Childhood"
    subtitle="Select any that apply to you."
    categoryId="relationships"
    questionId="childhood"
    isTwoColumns
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchChildhoodState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveChildhoodState)}
    backgroundOverlay="/assets/profile/relationships/childhood-overlay.svg"
  />
);

export default ProfileRelationshipsIntakeChildhoodPage;
