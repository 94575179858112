/* eslint-disable max-len */
import React from 'react';
import {
  Backdrop, CircularProgress, Stack, SwipeableDrawer, Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  textStyle,
  ctaButtonStyle,
  ctaButtonDisabledStyles,
  childrenStyle,
  roundedTopStyle,
  optionalInfoButtonContainer,
  infoButtonStyles,
  drawerHeaderSx,
  spinnerWrapperStyles,
  spinnerStyles,
  loadingCtaTextStyles,
} from './bottomDrawerStyles';

const InfoButtonIcon = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 9.5C0 4.25329 4.47715 0 10 0C15.5228 0 20 4.25329 20 9.5C20 14.7467 15.5228 19 10 19C4.47715 19 0 14.7467 0 9.5ZM11.1429 5.15714C11.1429 5.75677 10.6312 6.24286 10 6.24286C9.36882 6.24286 8.85714 5.75677 8.85714 5.15714C8.85714 4.55752 9.36882 4.07143 10 4.07143C10.6312 4.07143 11.1429 4.55752 11.1429 5.15714ZM10 8.14286C10.4734 8.14286 10.8571 8.50743 10.8571 8.95714V14.3857C10.8571 14.8354 10.4734 15.2 10 15.2C9.52661 15.2 9.14286 14.8354 9.14286 14.3857V8.95714C9.14286 8.50743 9.52661 8.14286 10 8.14286Z" fill="#9C9C9C" />
  </svg>
);

const DrawerHeader = ({
  title,
  subTitle,
  ctaClick,
  ctaLabel,
  onInfoButtonClick,
  ctaButtonStyleOverride,
  showCtaSpinner,
  isCtaLoading,
  isCtaDisabled,
  hideCta = false,
  containerStylesOverride,
}) => {
  let headerButtonStyles = { ...ctaButtonStyle, ...ctaButtonStyleOverride };
  if (isCtaDisabled) {
    headerButtonStyles = {
      ...headerButtonStyles,
      ...ctaButtonDisabledStyles,
    };
  }

  return (
    <Stack
      spacing={0.5}
      sx={{
        ...drawerHeaderSx,
        ...(subTitle && { alignItems: 'flex-start' }),
        ...containerStylesOverride,
      }}
    >
      <div style={{
        ...optionalInfoButtonContainer,
        ...(hideCta && { padding: '15px' }),
      }}
      >
        <div>
          <h4 style={{
            ...textStyle,
            ...(hideCta && { textAlign: 'center' }),
          }}
          >
            {title}
          </h4>
          <p>
            {subTitle}
          </p>
        </div>
        {onInfoButtonClick ? (
          <div style={infoButtonStyles} onClick={onInfoButtonClick}>
            <InfoButtonIcon />
          </div>
        )
          : null}
      </div>
      {!hideCta && (
        <Button
          onClick={ctaClick}
          type="button"
          disabled={isCtaDisabled}
          style={headerButtonStyles}
        >
          <span style={showCtaSpinner && isCtaLoading ? loadingCtaTextStyles : {}}>{ctaLabel}</span>
          {showCtaSpinner && isCtaLoading ? (
            <div style={spinnerWrapperStyles}>
              <CircularProgress size={15} thickness={5} sx={spinnerStyles} />
            </div>
          ) : null}
        </Button>
      )}
    </Stack>
  );
};

const BottomDrawer = ({
  toggleDrawer,
  open,
  children,
  variant,
  showBackdrop,
  title,
  subTitle,
  ctaLabel,
  handleCTAClick,
  onInfoButtonClick,
  isCtaLoading,
  showCtaSpinner,
  ctaButtonStyleOverride,
  isCtaDisabled,
  hideCta,
  containerStylesOverride,
}) => (
  <>
    <Backdrop open={showBackdrop} />
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      variant={variant || 'temporary'}
      PaperProps={{ style: roundedTopStyle }}
    >
      <DrawerHeader
        title={title}
        subTitle={subTitle}
        ctaClick={handleCTAClick}
        ctaLabel={ctaLabel}
        onInfoButtonClick={onInfoButtonClick}
        ctaButtonStyleOverride={ctaButtonStyleOverride}
        isCtaLoading={isCtaLoading}
        showCtaSpinner={showCtaSpinner}
        isCtaDisabled={isCtaDisabled}
        hideCta={hideCta}
        containerStylesOverride={containerStylesOverride}
      />
      {children ? (
        <div style={childrenStyle}>
          {children}
        </div>
      ) : null }
    </SwipeableDrawer>
  </>
);

export const BottomDrawerPropTypes = {
  toggleDrawer: PropTypes.func,
  open: PropTypes.bool,
  showBackdrop: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf, PropTypes.element]),
  variant: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ctaLabel: PropTypes.string,
  handleCTAClick: PropTypes.func,
  isCtaLoading: PropTypes.bool,
  showCtaSpinner: PropTypes.bool,
  ctaButtonStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const BottomDrawerDefaultProps = {
  toggleDrawer: () => { },
  open: false,
  showBackdrop: false,
  children: null,
  variant: '',
  title: '',
  subTitle: '',
  ctaLabel: '',
  handleCTAClick: () => { },
  isCtaLoading: false,
  showCtaSpinner: false,
  ctaButtonStyleOverride: null,
};

BottomDrawer.propTypes = BottomDrawerPropTypes;
BottomDrawer.defaultProps = BottomDrawerDefaultProps;

export default BottomDrawer;
