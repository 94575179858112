import axios from 'axios';
import { apiBase } from '../settings/backends';

const baseURL = apiBase;

const axiosInstance = axios.create({
  baseURL,
  timeout: 15000,
});

export default axiosInstance;
