import React from 'react';
import toast from 'react-hot-toast';
import {
  Stack,
  Typography,
  ButtonBase,
} from '@mui/material';
import { openAppSettings } from '../../helpers/appSettings';
import appStrings from '../../common/app_strings';

const { toasts: toastStrings } = appStrings;

const cameraErrors = {
  photoDenied: 'photoDenied',
  cameraDenied: 'cameraDenied',
  failedUpload: 'failedUpload',
  cancelled: 'cancelled',
  noImagePicked: 'noImagePicked', // android only
};

const nonTerminalErrorMessages = {
  [cameraErrors.cancelled]: 'cancelled',
  [cameraErrors.cameraDenied]: 'User denied access to camera',
  [cameraErrors.photoDenied]: 'User denied access to photos',
  [cameraErrors.noImagePicked]: 'No image picked',
};

const imageUploadToastStrings = {
  [cameraErrors.photoDenied]: toastStrings.photoDenied,
  [cameraErrors.cameraDenied]: toastStrings.cameraDenied,
  [cameraErrors.failedUpload]: toastStrings.failedUpload,
};

const toastDuration = 4000;

export const shouldSetError = (error) => {
  if (Object.values(nonTerminalErrorMessages).some((e) => error.message.includes(e))) {
    return false;
  }

  return true;
};

const ToastButton = ({
  label,
  onClick,
}) => (
  <ButtonBase onClick={onClick}>
    <Typography
      sx={{ color: '#063D8F', fontSize: 15, fontWeight: 700 }}
    >
      {label}
    </Typography>
  </ButtonBase>
);

export const imageUploadToast = (type, suppressed = false) => {
  if (suppressed) {
    return;
  }

  if (!imageUploadToastStrings[type]) {
    console.error(`imageUploadToastStrings[${type}] is not defined`);
    return;
  }

  const showSettingsActionButton = type !== cameraErrors.failedUpload;
  const message = imageUploadToastStrings[type];
  const dismissToastButtonLabel = showSettingsActionButton ? toastStrings.maybeLater : toastStrings.dismiss;

  toast(
    (t) => (
      <Stack>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message}
        </Typography>
        <Stack direction="row" spacing={3} justifyContent="center">
          <ToastButton label={dismissToastButtonLabel} onClick={() => toast.dismiss(t.id)} />
          {showSettingsActionButton && (
            <ToastButton
              label={toastStrings.goToSettings}
              onClick={() => {
                toast.dismiss(t.id);
                openAppSettings();
              }}
            />
          )}
        </Stack>
      </Stack>
    ),
    {
      duration: toastDuration,
      position: 'top-center',
    },
  );
};

export const handleErrorSideEffects = (error, suppressToasts = false) => {
  if (!error?.message) {
    return;
  }

  // user denied camera access
  if (error.message.toLowerCase().includes(nonTerminalErrorMessages.cameraDenied.toLowerCase())) {
    imageUploadToast(cameraErrors.cameraDenied, suppressToasts);
    return;
  }

  // user denied photo access
  if (error.message.toLowerCase().includes(nonTerminalErrorMessages.photoDenied.toLowerCase())) {
    imageUploadToast(cameraErrors.photoDenied, suppressToasts);
    return;
  }

  // user canceled photo selection: ios
  if (error.message.toLowerCase().includes(nonTerminalErrorMessages.cancelled.toLowerCase())) {
    // no-op
    return;
  }

  // user canceled photo selection: android
  if (error.message.toLowerCase().includes(nonTerminalErrorMessages.noImagePicked.toLowerCase())) {
    // no-op
    return;
  }

  imageUploadToast('failedUpload');
};
