import React, { useEffect, useState } from 'react';
import {
  IonContent, IonPage, IonHeader, IonToolbar, IonButtons,
} from '@ionic/react';
import {
  Box, Stack, Button, CircularProgress, ButtonBase,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useAuthContext } from '../../providers/auth-provider';
import { useAppState } from '../../providers/app-state/app-state-provider';
import appStrings from '../../common/app_strings';
import CustomButton from '../../common/atoms/button/Button';
import GeneralTextCardAtom from '../../common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import GeneralTextAtom from '../../common/atoms/renders/GeneralTextCardAtom';
import MatchingProtegePreview from '../../common/molecules/MatchingProtegePreview/MatchingProtegePreview';
import UnsureModal from '../../common/molecules/UnsureMatchModal';
import OptimizeMatch from '../wholeme/pages/matching/optimizeMatches/optimizeMatch';
import './MatchQuizResults.css';

const {
  matchingQuiz: { matchingResults },
} = appStrings;
const noResultsArr = [
  { key: 1, string: matchingResults.noResultsBody.para1 },
  { key: 2, string: matchingResults.noResultsBody.para2 },
  { key: 3, string: matchingResults.noResultsBody.para3 },
];

const titleStyles = {
  textAlign: 'center',
  color: '#FFFFFF',
  fontSize: '28px',
  fontWeight: 700,
};

const bodyStyles = {
  fontSize: '15px',
  textAlign: 'center',
  color: '#FFFFFF',
  opacity: 1,
};

const MatchQuizResults = () => {
  const [previews, setPreviews] = useState(null);
  const [selectedProteges, setSelectedProteges] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unsureModalOpen, setUnsureModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const history = useHistory();
  const { user } = useAuthContext();
  const { user: userState } = useAppState();
  const { get, abort } = useFetch();
  const { post } = useFetch('/matching/confirm-match-selections');
  const { get: getBackgroundCheck } = useFetch('users/current-user/background-check');
  const joinTeams = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const protegesRequiringBgCheck = previews.filter((protege) => selectedProteges.includes(protege.match_result_id) && protege.requiresBgCheck);
    try {
      await post({ run_id: previews[0]?.match_run_id, selected: selectedProteges });
    } catch (error) {
      console.error('Error selecting proteges', error);
    }
    const backgroundCheck = await getBackgroundCheck();
    setIsSubmitting(false);
    if (protegesRequiringBgCheck.length > 0 && backgroundCheck === 'No background check found') {
      history.push('/tabs/backr/background-check-v2');
    } else {
      history.push('/tabs/backr/teams');
    }
  };

  const openUnsureModal = () => {
    setUnsureModalOpen(true);
  };

  const closeUnsureModal = () => {
    setUnsureModalOpen(false);
  };

  const postUnsureUrl = '/users/send-unsure-matches';

  const chooseProtege = (id) => {
    const vals = [...selectedProteges];
    const removeNumber = (i) => {
      const newVals = vals.filter((pro) => pro !== i);
      return newVals;
    };
    if (vals.indexOf(id) > -1) {
      setSelectedProteges(removeNumber(id));
    } else {
      vals.push(id);
      setSelectedProteges(vals);
    }
  };

  const goToFeed = () => {
    history.push('/tabs/feed');
  };

  const goToQuiz = () => {
    history.push('/wholeme/pre-matching');
  };

  const handleAboutMeExpand = (value, isSelected) => {
    if (isSelected) {
      setSelectedItem(selectedItem.filter((item) => item !== value));
    } else setSelectedItem([value]);
  };

  useEffect(() => {
    if (userState?.matchRun) {
      if (!userState?.matchRun?.id) {
        // FIX IT: add better handling, UX, or route guards for this condition
        history.push('/');
      } else {
        setIsLoading(true);
        setTimeout(() => { // simulate kicking off matching process
          get(`/matching/match-results/${userState?.matchRun?.id}`).then((results) => {
            setPreviews(results);
            setIsLoading(false);
          });
        }, 3000);
      }
    }
    return () => {
      abort();
    };
  }, [userState?.matchRun]);

  if (isLoading) return <OptimizeMatch />;

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="quiz-button-toolbar"
          style={{
            '--background': 'rgba(6, 61, 143)',
          }}
        >
          <IonButtons slot="end">
            <ButtonBase sx={{ px: 0.5, mt: 0.5 }} onClick={goToFeed}>
              <Close sx={{ color: 'white', fontSize: 30 }} />
            </ButtonBase>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="itsAMatchCont">
        <Box
          sx={{
            px: '24px',
            pt: 1,
            mb: 8,
            mt: 2,
          }}
        >
          <Stack
            sx={{
              px: '24px',
              mb: '45px',
            }}
          >
            <GeneralTextCardAtom
              title={previews?.length ? matchingResults.header : matchingResults.noResults.header}
              body={previews?.length ? matchingResults.subHeader : null}
              titleStyles={{
                ...titleStyles,
              }}
              bodyStyles={{
                ...bodyStyles,
                fontSize: 16,
              }}
            />
          </Stack>
          {((previews && previews?.length === 0) || !previews) && (
            <Stack
              spacing={2}
              sx={{
                mt: '-35px',
              }}
            >
              {noResultsArr.map((text) => (
                <GeneralTextAtom
                  key={text.key}
                  body={text.string}
                  bodyStyles={{
                    ...bodyStyles,
                    fontSize: 16,
                  }}
                />
              ))}
            </Stack>
          )}
          {previews && previews?.length >= 1 && (
            <Stack spacing={2.5}>
              {previews.map((card) => {
                const isSelected = !!selectedItem.includes(card.user_id);
                return (
                  <MatchingProtegePreview
                    key={card.user_id}
                    user={card}
                    aboutMe={card.excited}
                    goalBreakdown={card.goal_breakdown}
                    selectedId={selectedProteges.indexOf(card.match_result_id) > -1}
                    setSelectedProtege={chooseProtege}
                    isSelected={isSelected}
                    handleAboutMeExpand={() => handleAboutMeExpand(card.user_id, isSelected)}
                  />
                );
              })}
            </Stack>
          )}
          <Stack
            sx={{
              px: '24px',
              mt: '60px',
            }}
          >
            {previews?.length >= 1 ? (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  fullWidth
                  disabled={isSubmitting || selectedProteges.length < 1}
                  sx={{
                    color: '#fff',
                    bgcolor: '#2cb669',
                    fontSize: 20,
                    fontWeight: 700,
                    textTransform: 'none',
                    minHeight: 57,
                    maxHeight: 57,
                    borderRadius: 100,
                    alignSelf: 'center',
                    '&.Mui-disabled': {
                      bgcolor: '#c3c3b9',
                      color: '#fff',
                    },
                  }}
                  onClick={joinTeams}
                >
                  {!isSubmitting ? (
                    matchingResults.buttonLabel
                  ) : (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      sx={{ color: 'hsl(0 0% 100% / 50%)' }}
                    />
                  )}
                </Button>
                <CustomButton labelStyles={{ fontWeight: 400 }} label={matchingResults.unsure} onClickCallBack={openUnsureModal} />
              </>
            ) : (

              <Button
                variant="contained"
                disableElevation
                disableRipple
                fullWidth
                sx={{
                  color: '#fff',
                  bgcolor: '#2cb669',
                  fontSize: 20,
                  fontWeight: 700,
                  textTransform: 'capitalize',
                  minHeight: 57,
                  maxHeight: 57,
                  borderRadius: 100,
                  alignSelf: 'center',
                }}
                onClick={goToQuiz}
              >
                {matchingResults.noResults.button}
              </Button>
            )}
          </Stack>
          <UnsureModal
            isOpen={unsureModalOpen}
            handleClose={closeUnsureModal}
            url={postUnsureUrl}
            user={user}
          />
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default MatchQuizResults;
