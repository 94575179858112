export const portraitFrameStyles = {
  position: 'relative',
  bgcolor: '#3B395D',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  width: 'fit-content',
  padding: '9px',
  borderRadius: '8px',
};

export const portraitHeadingWrapperStyles = {
  color: '#2d2d2d',
  alignItems: 'center',
  textAlign: 'center',
};

export const portraitHeadingStyles = {
  fontSize: 28,
  fontWeight: 700,
};

export const portraitSubheadingStyles = {
  fontSize: 20,
  maxWidth: '30ch',
};

export const portraitImageWrapperStyles = {
  bgcolor: '#E8EEF9',
};

export const portraitImageStyles = {
  padding: '16px 12px',
  boxSizing: 'border-box',
};

export const portraitLabelStyles = {
  position: 'absolute',
  bottom: '-6px',
  color: '#2d2d2d',
  bgcolor: '#FFB95C',
  fontSize: '12px',
  fontWeight: 800,
  textTransform: 'capitalize',
  padding: '2px 12px',
  borderRadius: '5px',
};

export const portraitStyleOverrides = {
  health: {
    padding: '10px 12px',
  },
  relationships: {
    padding: '16px 28px',
  },
  finances: {
    padding: '4px 12px',
  },
  education: {
    padding: '12px 20px',
  },
  interests: {
    padding: '12px 26px 2px',
  },
};
