// eslint-disable-next-line import/prefer-default-export
export const ethnicities = [
  { label: 'East Asian', value: 'East Asian' },
  { label: 'South Asian', value: 'South Asian' },
  { label: 'Indigenous', value: 'Indigenous' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
  { label: 'Biracial or Multiracial', value: 'Biracial or Multiracial' },
  { label: 'Middle Eastern or North African', value: 'Middle Eastern or North African' },
  { label: 'White', value: 'White' },
  { label: 'Other', value: 'Other' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
];
