// eslint-disable-next-line no-unused-vars
import React from 'react';
import useFetch from 'use-http';

const userServiceLogger = () => {
  const { post } = useFetch();

  const log = async ({
    type = 'log',
    message = '',
    header = null,
    key = null,
    error = null,
  }) => {
    const payload = {
      type,
      message,
      header,
      key,
      error: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : null,
    };

    try {
      await post('/debug/logger', payload);
    } catch (e) {
      console.error('Failed to log error', e);
    }
  };

  return {
    serviceLogger: log,
  };
};

export default userServiceLogger;
