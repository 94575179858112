/* eslint-disable import/prefer-default-export */
export const careerXPData = [
  {
    label: 'Career switch',
    value: 'career-switch',
    imageSrc: 'assets/profile/education/career-switch.svg',
    imageAlt: 'crossroad signs',
    iconSrc: 'assets/profile/wholeMe/experiences/crossroad.svg',
  },
  {
    label: 'Manual Labor/Trades',
    value: 'manual-labor-trades',
    imageSrc: 'assets/profile/education/manual-trade-labor.svg',
    imageAlt: 'package lift',
    iconSrc: 'assets/profile/wholeMe/experiences/dolly-box.svg',
  },
  {
    label: 'Professional Work',
    value: 'professional-work',
    imageSrc: 'assets/profile/education/professional-work.svg',
    imageAlt: 'desk',
    iconSrc: 'assets/profile/wholeMe/experiences/desk.svg',
  },
  {
    label: 'Service Industry/Retail',
    value: 'service-industry-retail',
    imageSrc: 'assets/profile/education/service-industry-retail.svg',
    imageAlt: 'steaming coffee cup',
    iconSrc: 'assets/profile/wholeMe/experiences/hot-tea.svg',
  },
  {
    label: 'Started/Own a Business',
    value: 'started-own-a-business',
    imageSrc: 'assets/profile/education/started-own-business.svg',
    imageAlt: 'small baber shop',
    iconSrc: 'assets/profile/wholeMe/experiences/barber-shop.svg',
  },
  {
    label: 'Management',
    value: 'Management',
    imageSrc: 'assets/profile/education/management.svg',
    imageAlt: 'computer screen',
    iconSrc: 'assets/profile/wholeMe/experiences/desktop-file.svg',
  },
  {
    label: 'Interview/Hiring',
    value: 'interview-hiring',
    imageSrc: 'assets/profile/education/interview-hiring.svg',
    imageAlt: 'qa thought bubles',
    iconSrc: 'assets/profile/wholeMe/experiences/q-a-bubble.svg',
  },
];
