import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import ResourceFetchingSearchInputField from '../searchInputField/ResourceFetchingSearchInputField';

const ResourceLookupPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  categoryId,
  questionId,
  title,
  subtitle,
  dataFetcher,
  dataSaver,
  disableCustomInputs,
  resourceType,
  resultAttributeId,
  backgroundOverlay,
  children,
  isSingleValue,
  exitIconOnClickOverride,
  minQueryLength,
  selectionRequired,
  freeFormDisabled,
  queryOnInputFocus,
}) => {
  const [selectedFieldState, setSelectedFieldState] = useState();

  useEffect(async () => {
    const state = (await dataFetcher()) || [];
    setSelectedFieldState(state.map((n) => n.value));
  }, []);

  const saveResource = async () => {
    const resources = selectedFieldState.map((value) => ({
      category_id: categoryId,
      question_id: questionId,
      value,
    }));
    await dataSaver(resources);
    nextOnClick();
  };

  const nextButtonDisabled = selectionRequired && (!selectedFieldState || selectedFieldState.length === 0);

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={title}
      subtitle={subtitle}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: saveResource, isDisabled: nextButtonDisabled }}
      backgroundOverlay={backgroundOverlay}
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      <div>
        {!selectedFieldState ? (
          <WholeMeLoadingCircle />
        ) : (
          <>
            <ResourceFetchingSearchInputField
              selectedOptions={selectedFieldState}
              setSelectedOptions={setSelectedFieldState}
              searchOptionsFetcher={wholeMeClient.fetchResourceList}
              resourceType={resourceType}
              resultAttributeId={resultAttributeId}
              disableCustomInputs={disableCustomInputs}
              inputTheme="light"
              isSingleValue={isSingleValue}
              hidePills={isSingleValue}
              minQueryLength={minQueryLength}
              freeFormDisabled={freeFormDisabled}
              queryOnInputFocus={queryOnInputFocus}
            />
            {children}
          </>
        )}
      </div>
    </ProfileIntakeStepPage>
  );
};
export default ResourceLookupPage;
