import React from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import AvatarAdmin from '../../../components/AvatarAdmin';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const SendIconAvatar = ({ user }) => {
  if (user?.role === 'admin') {
    return (
      <AvatarAdmin />
    );
  }
  return (
    <Avatar src={user?.profile_image}>
      <img style={{ width: 'rem', height: '3rem' }} src={defaultProfileImage} alt="profile" />
    </Avatar>
  );
};

SendIconAvatar.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    profile_image: PropTypes.string,
  }).isRequired,
};

export default SendIconAvatar;
