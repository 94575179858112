import { Typography, Stack, Button } from '@mui/material';
import React from 'react';
import { useFetch } from 'use-http';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ValidationError } from '../../helpers/errors';
import StyledDropdown from '../atoms/inputs/StyledDropdown';
import StyledTextarea from '../atoms/inputs/StyledTextarea';
import BaseModal from '../atoms/BaseModal/BaseModal';
import { useAuthContext } from '../../providers/auth-provider';
import appStrings from '../app_strings';

const UnsureMatchModal = ({
  isOpen,
  handleClose,
  url,
  user,
}) => {
  const {
    unsureModal: {
      header, subheader, textarea, dropdown,
    },
  } = appStrings;
  const history = useHistory();
  const { setHasGivenFeedback } = useAuthContext();
  const { post, response } = useFetch();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    if (!url) {
      setHasGivenFeedback(true);
      handleClose();
      reset({ [textarea.name]: '', [dropdown.name]: 'email' });
      return;
    }
    try {
      const sendData = {
        reason: data.reason,
        preferred_contact: data.preferred_contact,
        name: `${user?.first_name} ${user?.last_name}`,
        email: `${user?.email}`,
        phone: `${user?.phone}`,
      };
      const res = await post(url, sendData);
      if (!response.ok) {
        if (response.status === 400) {
          throw new ValidationError('Invalid inputs', res.errors);
        } else {
          throw new Error('Unknown Error');
        }
      }
      setHasGivenFeedback(true);
      handleClose();
      reset({ [textarea.name]: '', [dropdown.name]: 'email' });
      history.push('/tabs/feed');
    } catch (e) {
      if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      }
    }
  };

  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose}>
      <Typography
        sx={{
          color: '#2d2d2d',
          fontSize: 18,
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          color: '#2d2d2d',
          lineHeight: 1.3125,
          mt: 1,
        }}
      >
        {subheader}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} mt={3} mb={2}>
          <StyledTextarea
            control={control}
            name={textarea.name}
            title={textarea.label}
            placeholder={textarea.placeholder}
            error={errors?.[textarea.name]}
            minRows={6}
            maxRows={6}
            required
          />
          <StyledDropdown
            name={dropdown.name}
            title={dropdown.label}
            control={control}
            choices={dropdown.choices}
            defaultValue={dropdown.defaultValue}
            error={errors?.[dropdown.name]}
            required
          />
          <Button
            variant="contained"
            disableElevation
            disableRipple
            fullWidth
            type="submit"
            disabled={!isDirty || !isValid}
            sx={{
              maxWidth: 220,
              fontWeight: 700,
              textTransform: 'capitalize',
              py: 0.875,
              borderRadius: 100,
              alignSelf: 'center',
            }}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </BaseModal>
  );
};

UnsureMatchModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  url: PropTypes.string,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
};

UnsureMatchModal.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  url: null,
};

export default UnsureMatchModal;
