import React from 'react';
import { InputAdornment, SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const Adornment = ({
  position, iconStyles, viewBox, children,
}) => (
  <InputAdornment disablePointerEvents position={position}>
    <SvgIcon viewBox={viewBox} sx={iconStyles}>{children}</SvgIcon>
  </InputAdornment>
);

Adornment.propTypes = {
  position: PropTypes.string.isRequired,
  iconStyles: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  viewBox: PropTypes.string,
};

Adornment.defaultProps = {
  iconStyles: { fontSize: 24 },
  viewBox: '0 0 24 24',
};

export default Adornment;
