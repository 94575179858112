import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/capacitor';
import { Box, CircularProgress } from '@mui/material';
import { toast } from 'react-hot-toast';
import { firstOf } from 'pages/wholeme/util/profile_util';
import { usePhotoGallery } from 'hooks/usePhotoGallery';
import { useAppState } from 'providers/app-state/app-state-provider';
import WholeMeProfilePurposeVideoThumbnail from 'pages/wholeme/molecules/wholeMeProfilePurposeVideoThumbnail/wholeMeProfilePurposeVideoThumbnail';
import appStrings from '../../../../common/app_strings';

const { wholeMeProfilePurposeVideo: { cardTitle } } = appStrings;

const EditView = ({ profile, wholeMeClient }) => {
  if (!profile) return null;
  const [purposeVideo, setPurposeVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    platform: { isNativePlatform },
  } = useAppState();
  const { takeVideo, takeWebVideo } = usePhotoGallery();

  const handleWebVideoUpload = async () => {
    const { media } = await takeWebVideo();
    return media;
  };

  const handleMobileVideoUpload = async () => {
    const { media } = await takeVideo();
    return media;
  };

  const handleVideoUpload = async () => {
    if (isLoading) {
      return;
    }
    const uploader = isNativePlatform ? handleMobileVideoUpload : handleWebVideoUpload;
    try {
      setIsLoading(true);
      const uploadedVideo = await uploader();
      if (uploadedVideo?.id) {
        await wholeMeClient.savePurposeVideoState(uploadedVideo.id);
        setPurposeVideo(uploadedVideo?.uri);
      }
    } catch (e) {
      toast.error('Unable to update intro video');
      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initialPurposeVideo = firstOf(profile?.purpose_video)?.uri;
    setPurposeVideo(initialPurposeVideo);
  }, []);

  return (
    <>
      <p style={{ color: '#5B5B5B', fontSize: '14px', marginTop: '8px' }}>{cardTitle}</p>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            minHeight: '180px',
            maxHeight: '200px',
            display: 'grid',
            placeContent: 'center',
          }}
        >
          <CircularProgress thickness={5} size={70} />
        </Box>
      ) : (
        <WholeMeProfilePurposeVideoThumbnail videoUrl={purposeVideo} isLoading={isLoading} onClick={handleVideoUpload} />
      )}
    </>
  );
};

export default EditView;
