export const containerStyles = {
  display: 'grid',
  gap: '1rem',
  alignItems: 'baseline',
  justifyContent: 'start',
  backgroundColor: '#FFF',
  borderRadius: '8px',
  fontSize: '10px',
  color: '#5B5B5B',
  textAlign: 'center',
  padding: '21px 24px 15px 24px',
  width: 'inherit',
  justifyItems: 'center',
};

export const modalStyles = {
  width: '285px',
  borderRadius: '8px',
  backgroundColor: '#FFF',
};

export const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '23px',
  gap: '15px',
};
