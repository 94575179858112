/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const healthCredentialsData = [
  {
    label: 'Licensed MD or equivalent',
    value: 'licensed-md-or-equivalent',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=11387902109baf6aJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTI4MQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=licensed+md&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvTWVkaWNhbF9saWNlbnNl&ntb=1',
  },
  {
    label: 'Licensed therapist',
    value: 'licensed-therapist',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=3b2e44e3957f8a5fJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIyNg&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=licensed+therapist&u=a1aHR0cHM6Ly9sb2NhdG9yLmFwYS5vcmcv&ntb=1',
  },
  {
    label: 'Licensed social worker',
    value: 'licensed-social-worker',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=f166394f49ee6587JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTE5NQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=licensed+social+worker&u=a1aHR0cHM6Ly9zb2NpYWx3b3JrbGljZW5zZW1hcC5jb20vYmVjb21lLWEtc29jaWFsLXdvcmtlci9sbXN3LXZzLWxjc3cv&ntb=1',
  },
  {
    label: 'Licensed nurse',
    value: 'licensed-nurse',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=8d186839978c2774JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTUxNQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=licensed+nurse&u=a1aHR0cHM6Ly93d3cud2VibWQuY29tL2EtdG8tei1ndWlkZXMvd2hhdC1pcy1yZWdpc3RlcmVkLW51cnNlIzp-OnRleHQ9QSUyMHJlZ2lzdGVyZWQlMjBudXJzZSUyMCUyOFJOJTI5JTIwaXMlMjBhJTIwbGljZW5zZWQlMjBtZWRpY2FsLGhhbmRzLW9uJTIwY2FyZSUyMGluJTIwZGlmZmVyZW50JTIwbWVkaWNhbCUyMGFuZCUyMGNvbW11bml0eSUyMHNldHRpbmdzLg&ntb=1',
  },
  {
    label: 'EMT',
    value: 'EMT',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=0c200a4c642d589dJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIyNQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=EMT&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvRW1lcmdlbmN5X21lZGljYWxfdGVjaG5pY2lhbg&ntb=1',
  },
  {
    label: 'Licensed pharmacist',
    value: 'licensed-pharmacist',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=97a7840fa10530cbJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTI0Mg&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=pharmasitst&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvUGhhcm1hY2lzdA&ntb=1',
  },
  {
    label: 'Dentist',
    value: 'dentist',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=7f666e228eb20a12JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTI5Nw&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=dentist&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvRGVudGlzdA&ntb=1',
  },
  {
    label: 'Medical technician',
    value: 'medical-technician',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=30160f6aa03dbaa1JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxOQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=medical+techncian&u=a1aHR0cHM6Ly93d3cuaW5kZWVkLmNvbS9jYXJlZXItYWR2aWNlL2ZpbmRpbmctYS1qb2IvdHlwZXMtb2YtbWVkaWNhbC10ZWNobmljaWFucw&ntb=1',
  },
];
