import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SelectableItem, { selectableItemVariants } from 'common/atoms/selectableItem/SelectableItem';

const listStyles = {
  width: '100%',
  display: 'inline-grid',
  gap: '15px',
};

const SelectableItemsList = ({
  items,
  itemVariant,
  selectionType,
  isTwoColumns,
  onChange,
  activeItems = [],
  activeItemsOverridesInternalSelection,
  onSelection,
  listStylesOverride,
}) => {
  const [selectedItems, setSelectedItems] = useState(activeItems);

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (activeItemsOverridesInternalSelection) setSelectedItems(activeItems);
  }, [activeItems]);

  const handleItemClick = (value, isSelected) => {
    if (onSelection) {
      onSelection(value, !isSelected);
    }
    if (!activeItemsOverridesInternalSelection) {
      if (isSelected) {
        setSelectedItems(selectedItems.filter((item) => item !== value));
      } else if (selectionType === 'single') setSelectedItems([value]);
      else setSelectedItems([...selectedItems, value]);
    }
  };

  listStyles.gridTemplateColumns = isTwoColumns ? 'calc(50% - 7.5px) calc(50% - 7.5px)' : '1fr';

  const hasRectangleSubVariant = itemVariant === 'shortImageBelow' || itemVariant === 'longImageBelow';
  const variant = hasRectangleSubVariant ? 'rectangle' : itemVariant;
  const rectangleSubVariant = hasRectangleSubVariant ? itemVariant : '';

  return (
    <Box sx={listStylesOverride || listStyles}>
      {items?.length
        && items.map((item) => {
          const isSelected = !!selectedItems.includes(item.value);

          return (
            <SelectableItem
              key={item.value}
              {...item}
              variant={variant}
              rectangleSubVariant={rectangleSubVariant}
              isSelected={isSelected}
              onClick={() => handleItemClick(item.value, isSelected)}
              parentOverridesSelectedState={activeItemsOverridesInternalSelection}
              contentStylesOverride={item.style?.content}
              imgStylesOverride={item.style?.img}
            />
          );
        })}
    </Box>
  );
};

export const SelectableItemsListPropTypes = {
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  activeItems: PropTypes.oneOfType([PropTypes.array]),
  selectionType: PropTypes.string,
  isTwoColumns: PropTypes.bool,
  onChange: PropTypes.func,
  itemVariant: PropTypes.oneOf(selectableItemVariants).isRequired,
};

export const SelectableItemsListDefaultProps = {
  activeItems: [],
  selectionType: 'single',
  isTwoColumns: false,
  onChange: () => {},
};

SelectableItemsList.propTypes = SelectableItemsListPropTypes;
SelectableItemsList.defaultProps = SelectableItemsListDefaultProps;

export default SelectableItemsList;
