import React, { useLayoutEffect, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PageShell from 'common/molecules/pageShell/PageShell';
import TitleAtom from 'common/atoms/title/TitleAtom';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import StepPageHeader from '../stepPageHeader/StepPageHeader';
import {
  contentStyles,
  childrenStyles,
} from './profileIntakePageStyles';

const padding = 20;

const ProfileIntakePage = ({
  variant,
  paginationMeta,
  backgroundColor,
  backgroundOverlay,
  title,
  subtitle,
  isTitleTextWhite,
  NavComponent,
  children,
  hasLargeSubheader,
  titleStyleOverride,
  topBackArrowOnClickHandler,
  noAutoMarginOnChildren,
  splashPictureAlignsBottom,
  linkedTextUnderNavControl,
  exitIconOnClickOverride,
  childrenOverrideStyles,
}) => {
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardIsOpen(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardIsOpen(false);
    });
  }
  const isSplashPage = variant === 'splash';
  const isStepPage = variant === 'step';
  const backgroundImage = isNative
    ? backgroundOverlay
      ? `url(${backgroundOverlay})`
      : ''
    : '';
  const titleStyles = isTitleTextWhite
    ? { ...titleStyleOverride, styles: { color: '#FFFFFF' } }
    : titleStyleOverride;
  const contentContainerStyles = isSplashPage ? {} : contentStyles;

  const conditionalChildrenStyles = isSplashPage && splashPictureAlignsBottom
    ? { margin: 'auto 0 0', paddingBottom: '0' }
    : isSplashPage || noAutoMarginOnChildren
      ? {}
      : { margin: 'auto 0' };

  const navContainerStyles = {
    height: 'fit-content',
    width: '100%',
    marginTop: splashPictureAlignsBottom ? '-4px' : '20px',
  };

  const initialPageContainerStyles = {
    height: splashPictureAlignsBottom ? '75vh' : `calc(100vh - ${padding * 2}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: splashPictureAlignsBottom ? `12vh ${padding}px` : `${padding}px`,
  };

  useEffect(() => () => {
    if (isNative) {
      Keyboard.removeAllListeners();
    }
  }, []);

  const [pageContainerStyles, setPageContainerStyles] = useState(initialPageContainerStyles);
  useLayoutEffect(() => {
    if (isStepPage) { // help responsiveness w/ keyboard present
      if (keyboardIsOpen) setPageContainerStyles({ ...initialPageContainerStyles, height: 'initial' });
      else setPageContainerStyles(initialPageContainerStyles);
    } else setPageContainerStyles(initialPageContainerStyles);
  }, [keyboardIsOpen, variant]);

  const renderContent = (
    <>
      <TitleAtom
        hasLargeSubheader={hasLargeSubheader}
        header={title}
        subHeader={subtitle}
        stylesOverride={titleStyles}
      />
      {children?.length
        ? children?.map((child) => (
          <div style={{ ...childrenStyles, ...conditionalChildrenStyles }}>{child}</div>
        ))
        : <div style={{ ...childrenStyles, ...conditionalChildrenStyles, ...childrenOverrideStyles }}>{children}</div>}
      <div style={navContainerStyles}>{NavComponent}</div>
      {linkedTextUnderNavControl && linkedTextUnderNavControl}
    </>
  );

  return (
    <PageShell styles={{ backgroundColor, backgroundImage }}>
      <div style={pageContainerStyles}>
        {isStepPage && (
          <StepPageHeader
            showProgressDots={!!paginationMeta.total}
            paginationMeta={paginationMeta}
            topBackArrowOnClickHandler={topBackArrowOnClickHandler}
            exitIconOnClickOverride={exitIconOnClickOverride}
          />
        )}
        {isSplashPage && renderContent}
        {!isSplashPage && <Box style={contentContainerStyles}>{renderContent}</Box>}
      </div>
    </PageShell>
  );
};

export const ProfileIntakePagePropTypes = {
  variant: PropTypes.oneOf(['splash', 'step']),
  paginationMeta: PropTypes.shape({ current: PropTypes.number, total: PropTypes.number }),
  backgroundColor: PropTypes.string.isRequired,
  backgroundOverlay: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isTitleTextWhite: PropTypes.bool,
  NavComponent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  titleStyleOverride: PropTypes.oneOfType([PropTypes.object]),
};

export const ProfileIntakePageDefaultProps = {
  variant: 'step',
  paginationMeta: { current: 0, total: 0 },
  backgroundOverlay: '',
  title: '',
  subtitle: '',
  isTitleTextWhite: false,
  titleStyleOverride: {},
};

ProfileIntakePage.propTypes = ProfileIntakePagePropTypes;
ProfileIntakePage.defaultProps = ProfileIntakePageDefaultProps;

export default ProfileIntakePage;
