import React from 'react';
import { styled } from '@mui/material/styles';

const FlexContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
});

const defaultStyles = {
  paddingBottom: '5px',
  paddingTop: '5px',
};

const StyledDiv = styled('div')({
  marginLeft: '10px',
  fontSize: '14px',
  color: '#2D2D2D',
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '20px',
  width: '20px',
  minWidth: '20px',
});

const SubText = styled('span')({
  fontStyle: 'italic',
  fontWeight: '300',
  fontSize: '15px',
  color: 'rgba(45, 45, 45, 0.7)',
});

const defaultDateStyles = {
  fontStyle: 'italic',
};

const defaultLabelStyle = {
  fontSize: '19px',
};

const WholeMeProfileSectionRowLinkedIn = ({
  label,
  meta,
  iconSrc,
  labelStyle,
  subTextContainerStyle,
  datesStyle,
  styleOverrides = { ...defaultStyles },
}) => (
  !!label && (
    <FlexContainer
      style={styleOverrides}
    >
      <>
        <IconContainer>
          <object className="no-pointer-events" type="image/svg+xml" style={{ height: '20px', width: '20px', marginTop: '6px' }} data={iconSrc}>
            <img src={iconSrc} alt={iconSrc} />
          </object>
        </IconContainer>
        <div>
          <StyledDiv style={{ ...labelStyle, ...defaultLabelStyle }}>{label}</StyledDiv>
          <StyledDiv style={{ ...subTextContainerStyle }}>
            <SubText>{meta?.job_title || meta?.degree}</SubText>
            {' '}
            {(!!meta?.job_title || !!meta?.degree) && !!meta?.dates && <span>•</span>}
            {' '}
            {!!meta?.dates && <SubText style={{ ...datesStyle, ...defaultDateStyles }}>{meta?.dates}</SubText>}
          </StyledDiv>
        </div>
      </>
    </FlexContainer>
  )
);

export default WholeMeProfileSectionRowLinkedIn;
