import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useAppState } from '../../../providers/app-state/app-state-provider';

const pageShellStyles = {
  height: '100vh',
  width: '100vw',
  backgroundSize: 'cover',
};

const PageShell = ({ styles, children }) => {
  const { platform } = useAppState();

  const defaultStyles = {
    ...pageShellStyles,
    ...styles,
  };

  const webViewStyles = {
    ...styles,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  return (
    <Box style={platform?.isNativePlatform ? defaultStyles : webViewStyles}>
      {children}
    </Box>
  );
};

export const PageShellPropTypes = {
  styles: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node.isRequired,
};

export const PageShellDefaultProps = {
  styles: {},
};

PageShell.propTypes = PageShellPropTypes;
PageShell.defaultProps = PageShellDefaultProps;

export default PageShell;
