import React from 'react';
import { Stack, Typography } from '@mui/material';
import Linkify from 'linkify-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { linkifyOpts } from '../../../helpers/textManipulation';

const RightAlignedMessage = ({ message }) => (
  <div
    className="chat-msg-wrapper chat-msg-right"
  >
    <Stack spacing={0.5} mr={0.5} alignItems="flex-end">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
        ml={2}
      >
        {false && <div className="convos-item-unread" />}
        <Typography
          sx={{
            position: 'relative',
            top: 1,
            color: false
              ? 'hsl(0 0% 18% / 0.5)'
              : 'hsl(0 0% 18%)',
            fontSize: 10,
            fontWeight: 600,
          }}
        >
          {moment(
            message.state.timestamp.toLocaleString(),
          ).fromNow()}
        </Typography>
      </Stack>
      <div className="talk-bubble bubble-taupe tri-right round right-top">
        <div className="chat-text">
          <Linkify tagName="p" options={linkifyOpts}>
            {message.state.body}
          </Linkify>
        </div>
      </div>
    </Stack>
  </div>
);

RightAlignedMessage.propTypes = {
  message: PropTypes.shape({
    state: PropTypes.shape({
      timestamp: PropTypes.instanceOf(Date),
      body: PropTypes.string,
    }),
  }).isRequired,
};

export default RightAlignedMessage;
