import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const CardContentAtom = ({
  image,
  body,
}) => (
  <Box
    sx={{
      backgroundColor: '#ffff',
      display: 'flex',
      borderRadius: '10px',
      width: 'fit-content',
      padding: '20px',
    }}
  >
    <object
      type="image/svg+xml"
      data={image}
      style={{
        width: '60px',
      }}
    >
      <img src={image} alt="svg" />
    </object>
    <Typography sx={{
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '21px',
      maxWidth: '189px',
      pl: '15px',
      pt: '5px',
    }}
    >
      {body}
    </Typography>
  </Box>
);

CardContentAtom.propTypes = {
  image: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default CardContentAtom;
