import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import SelectableItemsList from 'common/molecules/selectableItemsList/SelectableItemsList';
import { useFeatureFlagsContext } from 'providers/featureflags-provider';

const childrenStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const SimpleMultiSelectPage = ({
  fields,
  itemVariant = 'oval',
  isTwoColumns,
  paginationMeta,
  nextOnClick,
  prevOnClick,
  title,
  subtitle,
  categoryId,
  questionId,
  dataFetcher,
  dataSaver,
  children,
  onSelection,
  selectionType = 'multi',
  backgroundOverlay,
  allowForDisabledButtonState,
  childrenOverrideStyles,
  exitIconOnClickOverride,
}) => {
  const [selectedFieldsState, setSelectedFieldsState] = useState(null);
  const { isSaveAndContinueAlwaysEnabled } = useFeatureFlagsContext() || {};

  useEffect(async () => {
    const state = await dataFetcher();
    const updatedFieldState = (state[questionId] || []).flatMap((n) => (!n.option_id ? [] : n.option_id));
    setSelectedFieldsState(updatedFieldState);
  }, []);

  const saveField = () => {
    const converted = selectedFieldsState.reduce(
      (acc, fieldValue) => ({
        ...acc,
        [fieldValue]: {
          category_id: categoryId,
          question_id: questionId,
          value: fieldValue,
        },
      }),
      {},
    );
    dataSaver(converted).then(() => {
      nextOnClick();
    });
  };

  const onListChange = (fieldValue) => (fieldValue ? setSelectedFieldsState(fieldValue) : setSelectedFieldsState([]));
  const isNextButtonDisabled = !isSaveAndContinueAlwaysEnabled && allowForDisabledButtonState && !selectedFieldsState?.length;

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={title}
      subtitle={subtitle}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: saveField, isDisabled: isNextButtonDisabled }}
      backgroundOverlay={backgroundOverlay}
      childrenOverrideStyles={childrenOverrideStyles}
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      {!selectedFieldsState ? (
        <WholeMeLoadingCircle />
      ) : (
        <>
          <SelectableItemsList
            activeItems={selectedFieldsState}
            items={fields}
            itemVariant={itemVariant}
            onChange={(fieldValue) => onListChange(fieldValue)}
            selectionType={selectionType}
            isTwoColumns={isTwoColumns}
            onSelection={onSelection}
          />
          {children && <div style={childrenStyles}>{children}</div>}
        </>
      )}
    </ProfileIntakeStepPage>
  );
};

export default SimpleMultiSelectPage;
