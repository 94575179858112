export const backrsButtonStyles = {
  height: '57px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '100px',
  border: 'none',
  fontWeight: '600',
  fontSize: '20px',
  fontFamily: 'Sarabun, arial, sans-serif',
  textTransform: 'none',
  lineHeight: '25px',
};

export const variantStylesLookup = {
  primary: {
    backgroundColor: '#063D8F',
    color: '#FFFFFF',
  },
  secondary: {
    backgroundColor: '#FFFFFF',
    color: '#F7AE41',
    border: '3px solid #F7AE41',
  },
  text: {
    backgroundColor: '#FFFFFF00',
    color: '#000000',
    border: '3px solid #FFFFFF00',
  },
};
