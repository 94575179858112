import { Box } from '@mui/material';
import React from 'react';
import { listStyles } from './columnRendererStyles';

const ColumnRenderer = ({ children }) => (
  <Box sx={listStyles}>
    {children
      .flat()
      .filter((n) => !!n)
      .map((next) => next)}
  </Box>
);

export default ColumnRenderer;
