import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { openInBrowser } from '../../../helpers/broswer';

const textStyles = ({ url }) => ({
  color: '#5B5B5B',
  cursor: url ? 'pointer' : 'default',
  textDecoration: url ? 'underline' : null,
});

const HyperLinkText = ({ text, url, styleOverride }) => (
  <Typography
    sx={{ ...textStyles({ url }), ...styleOverride }}
    onClick={url ? () => openInBrowser(url) : null}
  >
    {text}
  </Typography>
);

HyperLinkText.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  styleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

HyperLinkText.defaultProps = {
  url: null,
  styleOverride: {},
};

export default HyperLinkText;
