export const textStyle = {
  fontFamily: "'Sarabun',arial, sans-serif",
  fontWeight: '700',
  fontSize: '20px',
  color: '#03295F',
};

export const ctaButtonStyle = {
  backgroundColor: '#03295F',
  color: '#fff',
  width: '73.05px',
  height: '30px',
  borderRadius: '20%/50%',
  cursor: 'pointer',
  minWidth: '73px',
  position: 'relative',
  textTransform: 'capitalize',
};

export const ctaButtonDisabledStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  color: 'rgba(0, 0, 0, 0.26)',
};

export const childrenStyle = {
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '175px',
  maxHeight: '75vh',
  padding: '0 20px 20px 20px',
};

export const roundedTopStyle = {
  borderRadius: '17px 17px 0 0',
};

export const optionalInfoButtonContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const infoButtonStyles = {
  height: 'min-content',
  width: 'min-content',
  marginLeft: '10px',
  paddingTop: '5px',
  cursor: 'pointer',
};

export const drawerHeaderSx = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px',
};

export const spinnerWrapperStyles = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -40%)',
};

export const spinnerStyles = {
  color: '#fff',
};

export const loadingCtaTextStyles = {
  opacity: 0,
};
