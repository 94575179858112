import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import {
  multiSelectDataFetcherAdapter,
  multiSelectDataSaverAdapter,
} from '../../../util/profile_util';
import { relationshipXpData } from './relationshipXpData';

const ProfileRelationshipsIntakeXpPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={relationshipXpData}
    itemVariant="rectangle"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Relational Experiences"
    subtitle="Select any that apply to you."
    categoryId="relationships"
    questionId="relationships_xp"
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchRelationshipsXpState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveRelationshipsXpState)}
    backgroundOverlay="/assets/profile/relationships/relational-experiences-overlay.svg"
  />
);

export default ProfileRelationshipsIntakeXpPage;
