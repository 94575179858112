import React from 'react';
import {
  Box,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import Close from '@mui/icons-material/Close';

const ReportCueCard = ({ userRole, onClickCallBack }) => (
  <Stack
    sx={{
      width: 1,
      mb: 2,
    }}
    alignItems="center"
  >
    <Box
      sx={{
        width: '87%',
        background: '#063D8F',
        height: '137px',
        borderRadius: '8px',
      }}
    >
      <IconButton
        sx={{
          position: 'relative',
          left: '90%',
          color: '#FFFFFF',
          top: '5px',
          opacity: '50%',
          padding: 0,
        }}
        onClick={onClickCallBack}
      >
        <Close />
      </IconButton>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'center',
          bgcolor: '#063D8F',
          color: '#fff',
          display: 'flex',
        }}
      >
        {userRole === 'backr' && (
          <img
            src="/assets/reported-backr.svg"
            alt="sparkly eye"
          />
        )}
        {userRole === 'protege' && (
          <img
            src="/assets/reported-protege.svg"
            alt="sparkly eye"
          />
        )}
        <Box
          sx={{
            pl: '10px',
            width: '60%',
          }}
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            Your account has been reported.
          </Typography>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: 13,
            }}
          >
            Please message Coach Lubav for more information.
          </Typography>
        </Box>
      </Stack>
    </Box>
  </Stack>
);

ReportCueCard.propTypes = {
  userRole: PropTypes.string.isRequired,
  onClickCallBack: PropTypes.func,
};

ReportCueCard.defaultProps = {
  onClickCallBack: () => { },
};

export default ReportCueCard;
