import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import HyperLinkText from '../../../common/atoms/hyperLinkText/HyperLinkText';
import appStrings from '../../../common/app_strings';
import { NetworkSponsorshipRenderStyle } from './NetworkSponsorRenderStyle';

const {
  containerStyle,
  textStyle,
  sponsorBoxStyle,
  nameStyle,
  imageStyle,
} = NetworkSponsorshipRenderStyle;

const { common: { sponsoredBy } } = appStrings;

const NetworkSponsorshipRender = ({
  meta,
  nameStyleOverride,
  containerStyleOverride,
  imageStyleOverride,
  sponsorBoxStyleOverride,
  textStyleOverride,
}) => (
  <Box sx={{ ...containerStyle, ...containerStyleOverride }}>
    <Stack direction="row" spacing={0.5} alignItems="center">
      <img src="assets/icon/sparkle-double-2.svg" alt="icon" style={{ ...imageStyle, ...imageStyleOverride }} />
      <Typography display="inline" sx={{ ...textStyle, ...textStyleOverride }}>{sponsoredBy}</Typography>
    </Stack>
    <Stack direction="row" spacing={0.5} alignItems="center" sx={{ ...sponsorBoxStyle, ...sponsorBoxStyleOverride }}>
      {meta.map((sponsor, index) => (
        <React.Fragment key={sponsor.name}>
          <HyperLinkText
            text={sponsor.name}
            url={sponsor.target_url}
            styleOverride={{ ...nameStyle, ...nameStyleOverride }}
          />
          {index < meta.length - 1 && ' ,'}
        </React.Fragment>
      ))}
    </Stack>
  </Box>
);

NetworkSponsorshipRender.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    target_url: PropTypes.string,
  })).isRequired,
  nameStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  imageStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sponsorBoxStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  textStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

NetworkSponsorshipRender.defaultProps = {
  nameStyleOverride: {},
  containerStyleOverride: {},
  imageStyleOverride: {},
  sponsorBoxStyleOverride: {},
  textStyleOverride: {},
};

export default NetworkSponsorshipRender;
