import React, { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonPage,
  IonHeader,
  IonToast,
  IonButtons,
  IonToolbar,
  IonIcon,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  Box,
  Stack,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ButtonBase,
  InputAdornment,
  Link,
} from '@mui/material';
import { Close, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { alertOutline } from 'ionicons/icons';
import NumberFormat from 'react-number-format';
import appStrings from 'common/app_strings';
import { useAuthContext } from '../../providers/auth-provider';
import './Register.css';
import { ValidationError } from '../../helpers/errors';
import { TaupeTextField } from '../../components/StyledInput';
import TermsAndConditionsContent from '../TermsAndConditionsContent';
import { useAppState } from '../../providers/app-state/app-state-provider';

const {
  textBtn, login,
  registerHeader,
  tsAndCs,
  tac,
  errors: { registrationFailed },
} = appStrings.onboarding;
const { contactBtn } = appStrings.otpValidation;

const CssTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  '& .MuiFilledInput-root': {
    background: '#f4f4ee',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#f4f4ee',
    borderRadius: 8,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#2d2d2d',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
});

const DuplicateHelperText = ({ type = 'email' }) => {
  const history = useHistory();
  const { platform: { isNativePlatform } } = useAppState();
  const goToLogin = async () => {
    if (isNativePlatform) {
      await Keyboard.hide();
    }
    history.push('/register/login');
  };
  const typeKeys = {
    email: 'email',
    phone: 'mobile number',
  };
  return (
    <>
      {`An account with this ${typeKeys[type]} already exists, please `}
      <Typography
        component="span"
        sx={{
          color: '#063D8F',
          fontSize: 'inherit',
          fontWeight: 700,
          display: 'inline',
          cursor: 'pointer',
        }}
        onClick={goToLogin}
      >
        log in
      </Typography>
      {' or reach out to '}
      <Link
        href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
        sx={{
          color: '#063D8F',
          fontSize: 'inherit',
          fontWeight: 700,
          display: 'inline',
          textDecoration: 'none',
        }}
      >
        support@backrs.com
      </Link>
      .
    </>
  );
};

const duplicateErrorKeys = {
  'Email already in use.': <DuplicateHelperText type="email" />,
  'Phone number already in use.': <DuplicateHelperText type="phone" />,
};

const BackrRegistration = () => {
  const history = useHistory();
  const { platform: { isNativePlatform } } = useAppState();
  const bottomRef = React.useRef(null);

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardWillShow', () => {
      if (bottomRef && bottomRef.current) {
        bottomRef.current.style.visibility = 'hidden';
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (bottomRef && bottomRef.current) {
        bottomRef.current.style.visibility = 'visible';
      }
    });
  }

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [isFourteen, setIsFourteen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [toastState, setToastState] = React.useState({
    isOpen: false,
    message: '',
  });

  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const closeTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleClickShowPassword = (e) => {
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  const { handleBackrPreRegistration } = useAuthContext();

  const clearFieldErrors = () => {
    setFieldErrors(null);
  };

  const goToLogin = async () => {
    if (isNativePlatform) {
      await Keyboard.hide();
    }
    history.push('/register/login');
  };

  const doRegister = async () => {
    clearFieldErrors();

    try {
      const response = await handleBackrPreRegistration({
        firstName,
        lastName,
        email,
        password,
        phone,
        isFourteen,
      });

      if (response?.status_code !== 200) {
        throw new Error(response);
      }

      if (!response?.authExempt) {
        history.push('/register/register/verify');
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        setFieldErrors(e.errors);
      } else {
        setFieldErrors({ unknown: e.message || 'Unknown error!' });
      }
    }
  };

  const isFourteenInputColor = fieldErrors?.is_fourteen_plus && !isFourteen ? '#d32f2f' : '#063D8F';

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="back-button-toolbar">
          <IonButtons slot="start">
            <ButtonBase sx={{ px: 0.5, mt: 0.5 }} onClick={() => history.goBack()}>
              <IonIcon
                style={{ fontSize: '1.25rem' }}
                slot="icon-only"
                src="/assets/back-arrow.svg"
              />
            </ButtonBase>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton mode="ios" style={{ width: 'auto' }} onClick={goToLogin}>
              <Stack spacing={1} direction="row" alignItems="center">
                <IonIcon
                  style={{ fontSize: '18px' }}
                  slot="icon-only"
                  src="/assets/login-lock.svg"
                  mode="ios"
                />
                <Typography
                  sx={{ color: '#063D8F', fontSize: 14, fontWeight: 700 }}
                >
                  {login}
                </Typography>
              </Stack>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          expand="block"
          isOpen={toastState.isOpen}
          onDidDismiss={() => setToastState({ isOpen: false, message: '' })}
          message={toastState.message}
          icon={alertOutline}
          duration={5000}
          position="top"
        />
        <Stack>
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: 20,
                fontWeight: 700,
                textAlign: 'center',
                mt: 3,
                mb: 4,
              }}
            >
              {registerHeader}
            </Typography>
            <Box
              sx={{
                background: '#fff',
                p: 3,
                pt: 3.5,
                pb: fieldErrors?.unknown ? 0.2 : undefined,
                mx: 3,
                mt: 1,
                borderRadius: 2,
              }}
            >
              <Stack spacing={1.5}>
                <Stack spacing={0}>
                  <Stack
                    direction="row"
                    spacing={1.5}
                    justifyContent="space-between"
                  >
                    <CssTextField
                      fullWidth
                      placeholder="First Name"
                      value={firstName}
                      size="small"
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      onKeyUp={(e) => e.key === 'Enter' && doRegister()}
                      error={!!fieldErrors?.first_name}
                      inputProps={{ autoCapitalize: 'on' }}
                    />
                    <CssTextField
                      fullWidth
                      placeholder="Last Name"
                      value={lastName}
                      size="small"
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      onKeyUp={(e) => e.key === 'Enter' && doRegister()}
                      error={!!fieldErrors?.last_name}
                      inputProps={{ autoCapitalize: 'on' }}
                    />
                  </Stack>
                  <Stack spacing={-0.5} pl={1.75}>
                    <FormHelperText error>{fieldErrors?.first_name}</FormHelperText>
                    <FormHelperText error>{fieldErrors?.last_name}</FormHelperText>
                  </Stack>
                </Stack>
                <Box>
                  <CssTextField
                    fullWidth
                    placeholder="Email Address"
                    value={email}
                    size="small"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={(e) => e.key === 'Enter' && doRegister()}
                    error={!!fieldErrors?.email}
                    helperText={fieldErrors?.email && (duplicateErrorKeys[fieldErrors.email] || fieldErrors.email)}
                  />
                </Box>
                <Box>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    onValueChange={(v) => setPhone(v.value)}
                    value={phone}
                    customInput={TaupeTextField}
                    fullWidth
                    size="small"
                    error={!!fieldErrors?.phone}
                    helperText={fieldErrors?.phone && (duplicateErrorKeys[fieldErrors.phone] || fieldErrors.phone)}
                    type="tel"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="Phone Number"
                  />
                </Box>
                <Box>
                  <CssTextField
                    fullWidth
                    placeholder="Password"
                    value={password}
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyUp={(e) => e.key === 'Enter' && doRegister()}
                    error={!!fieldErrors?.password}
                    helperText={fieldErrors?.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            disableRipple
                            sx={{ color: '#C3C3B9' }}
                          >
                            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Stack>
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{
                      color: isFourteenInputColor,
                      '&.Mui-checked': {
                        color: '#063D8F',
                      },
                      ml: 1,
                    }}
                    checked={isFourteen}
                    onChange={(e) => setIsFourteen(e.target.checked)}
                  />
                )}
                label={(
                  <Typography
                    sx={{
                      color: isFourteenInputColor,
                      fontSize: 12,
                    }}
                  >
                    {tsAndCs}
                  </Typography>
                )}
              />
              {fieldErrors?.unknown && (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <FormHelperText error>
                    {registrationFailed(true)}
                    <a
                      className="red"
                      href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
                    >
                      {(contactBtn).toLowerCase()}
                    </a>
                  </FormHelperText>
                </Box>
              )}
            </Box>
          </Box>
          <Stack
            ref={bottomRef}
            spacing={2.5}
            sx={{
              alignItems: 'center',
              mt: 3,
              mx: 3,
            }}
          >
            <object
              className="no-pointer-events"
              type="image/svg+xml"
              data="/assets/creation-connection.svg"
              style={{ width: '100%', maxWidth: '265px' }}
            >
              <img
                src="/assets/creation-connection.svg"
                alt="connection account creation"
              />
            </object>
            <IonButton
              className="register-btn"
              size="large"
              expand="block"
              shape="round"
              onClick={doRegister}
              disabled={
                !isFourteen
                || !email
                || !phone
                || !password
              }
              style={{
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                width: '100%',
              }}
              mode="ios"
            >
              {textBtn}
            </IonButton>
            <Stack
              alignItems="center"
              mt={1.25}
              pb={3.5}
            >
              <Box>
                <Typography
                  sx={{
                    color: '#2D2D2D',
                    fontSize: 14,
                  }}
                >
                  {tac.acceptBtac}
                  <Typography
                    component="span"
                    sx={{
                      color: '#063D8F',
                      fontSize: 'inherit',
                      fontWeight: 700,
                      display: 'inline',
                      marginLeft: '4px',
                    }}
                    onClick={() => setOpenTermsModal(true)}
                  >
                    {tac.btac}
                  </Typography>
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Dialog
          PaperProps={{
            style: {
              borderRadius: 8,
              'max-height': 'calc(100% - 450px)',
            },
          }}
          open={openTermsModal}
          onClose={closeTermsModal}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontSize: 20,
                color: '#063D8F',
                fontWeight: 700,
              }}
            >
              {tac.btac}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={closeTermsModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              px: 2,
              pb: 2,
              borderTop: '0.5px solid grey',
            }}
          >
            <TermsAndConditionsContent whiteBgModal />
          </DialogContent>
        </Dialog>
      </IonContent>
    </IonPage>
  );
};

export default BackrRegistration;
