import React from 'react';
import {
  Box, Stack, Typography, Avatar,
} from '@mui/material';
import {
  differenceInDays,
  formatDistanceToNowStrict,
  parseISO,
} from 'date-fns';
import PropTypes from 'prop-types';
import { displayName } from 'helpers/textManipulation';
import './TeamSummaryCard.css';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const TeamSummaryCard = ({ relationship, navigateToTeam, isPending }) => {
  const displayBackingTime = (backingTime) => {
    const backingStartDate = parseISO(backingTime);
    const backingPeriodInDays = differenceInDays(new Date(), backingStartDate);
    if (backingPeriodInDays < 1) return 'today';
    return formatDistanceToNowStrict(backingStartDate);
  };

  const opacity = isPending ? { opacity: '0.5' } : null;

  const displayBackrCount = (count, pending) => {
    if (count < 2 && !pending) return 'You';
    if (count === 2) return 'You and 1 other';
    if (pending) return '1 other';
    return `${!pending && 'You and '}${count - 1} others`;
  };

  const renderSummaryHeader = (pending) => {
    if (pending) {
      return (
        <p>
          <span className="bold-text">
            Pending Verification
          </span>
        </p>
      );
    }
    return (
      <p className="backedFor">
        You backed for
        {' '}
        <span className="bold-text">
          {displayBackingTime(relationship?.updated_at)}
        </span>
      </p>
    );
  };

  return (
    <Box
      className="content-box"
      sx={{ bgcolor: '#fff', p: 2, borderRadius: 2 }}
      onClick={() => navigateToTeam(relationship?.related_user_id)}
    >
      <div className="protege-details-content">
        {renderSummaryHeader(isPending)}
        <img
          className="followed-icon"
          src="assets/icon/followedBy.svg"
          alt="backrs on protege team"
        />
        <p className="followedby-text">
          Backed by
          {' '}
          {displayBackrCount(relationship?.backr_count, isPending)}
        </p>

      </div>
      <Stack style={opacity} className="card" spacing={-1.5}>
        <Avatar
          src={relationship?.related_profile?.profile_image}
          sx={{
            width: 108,
            height: 108,
            fontSize: 60,
          }}
        >
          <img src={defaultProfileImage} alt="profile" />
        </Avatar>
        <Box
          sx={{
            width: 1,
            maxWidth: 108,
            background: '#2CB669',
            py: 0.35,
            marginTop: '-17.5px',
            borderRadius: 2,
            zIndex: 10,
            boxSizing: 'border-box',
          }}
        >
          <Stack spacing={-0.5}>
            <Typography
              align="center"
              sx={{ color: '#fff', fontSize: 13, fontWeight: 700 }}
            >
              {displayName(
                relationship?.related_profile?.first_name,
                relationship?.related_profile?.last_name,
                true,
              )}
            </Typography>
            <Typography
              align="center"
              sx={{ color: '#fff', fontSize: 10, fontWeight: 700 }}
            >
              Protege
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

TeamSummaryCard.propTypes = {
  relationship: PropTypes.shape({
    related_user_id: PropTypes.number,
    backr_count: PropTypes.number,
    updated_at: PropTypes.string,
    related_profile: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profile_image: PropTypes.string,
    }),
  }).isRequired,
  isPending: PropTypes.bool.isRequired,
  navigateToTeam: PropTypes.func.isRequired,
};

export default TeamSummaryCard;
