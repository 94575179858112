import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ProtegeInfo from 'common/atoms/meetProteges/ProtegeInfo';
import GeneralTextCardAtom from 'common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import BackrInfoAvatar from 'common/atoms/backrInfo/backrInfoAvatar';

const intermissionHeaderStyles = {
  color: '#fff',
  fontSize: 28,
  fontWeight: 700,
  mt: 9,
};

const TopCardSection = ({ meta }) => meta && (
<Box>
  {meta?.profile && (
  <ProtegeInfo
    avatar={meta?.profile?.avatar_url}
    name={meta?.profile?.first_name}
    isCollegeStudent
    education={meta?.profile?.school}
    location={meta?.profile?.location}
  />
  )}
  {meta?.backing_header && (
  <GeneralTextCardAtom
    title={meta?.backing_header?.title}
    titleStyles={meta?.backing_header?.title_styles}
    body={meta?.backing_header?.body}
    bodyStyles={meta?.backing_header?.body_styles}
  />
  )}
  {meta?.backr_profile && <BackrInfoAvatar meta={meta?.backr_profile} />}
  {meta?.intermission_header && (
  <GeneralTextCardAtom
    title={meta?.intermission_header?.title}
    titleStyles={intermissionHeaderStyles}
  />
  )}
  {meta?.wibHeader && (
  <GeneralTextCardAtom title={meta?.wibHeader?.title} titleStyles={meta?.wibHeader?.styles} />
  )}
</Box>
);

TopCardSection.propTypes = {
  meta: PropTypes.shape({
    profile: PropTypes.shape({
      avatar_url: PropTypes.string,
      first_name: PropTypes.string,
      school: PropTypes.string,
      location: PropTypes.string,
    }),
    backing_header: PropTypes.shape({
      title: PropTypes.string,
      title_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      body: PropTypes.string,
      body_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
    backr_profile: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      src: PropTypes.string,
      about: PropTypes.shape({
        location: PropTypes.string,
        occupation: PropTypes.string,
        education: PropTypes.string,
        organization: PropTypes.string,
      }),
      interests: PropTypes.arrayOf(PropTypes.string),
    }),
    intermission_header: PropTypes.shape({
      title: PropTypes.string,
      title_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
    wibHeader: PropTypes.shape({
      title: PropTypes.string,
      styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
  }),
};

TopCardSection.defaultProps = {
  meta: null,
};

export default TopCardSection;
