import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import BaseModal from '../atoms/BaseModal/BaseModal';

const DonorboxModal = ({
  isOpen, handleClose, widgetSrc, widgetTitle, iframeOverrideStyle, header, subtitle,
}) => (
  <BaseModal isOpen={isOpen} handleClose={handleClose}>
    <Typography>{header}</Typography>
    <Typography>{subtitle}</Typography>
    <Box sx={{ padding: '9px 0px', height: 'auto' }}>
      <iframe
        title={widgetTitle}
        src={widgetSrc}
        name="donorbox"
        allowpaymentrequest="allowpaymentrequest"
        style={{
          border: 0,
          overflow: 'hidden',
          ...iframeOverrideStyle,
        }}
      />
    </Box>
  </BaseModal>
);

DonorboxModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  widgetSrc: PropTypes.string.isRequired,
  widgetTitle: PropTypes.string.isRequired,
};

export default DonorboxModal;
