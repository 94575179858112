import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import { motion } from 'framer-motion';
import useFetch, { CachePolicies } from 'use-http';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useStore } from '../../providers/store-provider';
import { useAuthContext } from '../../providers/auth-provider';
import NotificationItem from './NotificationItem';

const NotificationsPage = () => {
  const { user } = useAuthContext();
  const { refreshNotificationCount } = useStore();
  const [notifications, setNotifications] = useState([]);
  const [infiniteDisabled, setInfiniteDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { get, response, abort } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const history = useHistory();
  const contentRef = useRef(null);
  const [PER_PAGE, setPerPage] = useState();
  const [isBootstrapped, setIsBootstrapped] = useState(false);

  // add a page of data to the infinite scroll list
  const addPage = async (refresh = false) => {
    if (!loading) {
      setLoading(true);
      const range = refresh
        ? `[0,${PER_PAGE - 1}]`
        : `[${notifications.length},${notifications.length + PER_PAGE - 1}]`;
      const params = new URLSearchParams();
      params.append('range', range);
      params.append('sort', '["created_at","DESC"]');
      params.append('filter', `{"user_id":"${user.id}"}`);
      let data = [];
      try {
        data = await get(`/notifications?${params.toString()}`);
      } catch (e) {
        console.error('Error in addPage: ', e);
      }
      refreshNotificationCount();
      let total = 0;
      if (refresh) {
        setNotifications(data);
        setInfiniteDisabled(false);
        total = data.length;
      } else if (data !== undefined) {
        setNotifications([...notifications, ...data]);
        total = data.length + notifications.length;
      }
      if (total >= response?.headers?.get('x-total-count')) {
        setInfiniteDisabled(true);
      }
      setLoading(false);
    }
  };

  const loadData = async (ev) => {
    await addPage();
    ev.target.complete();
    if (notifications.length >= 1000) {
      setInfiniteDisabled(true);
    }
  };

  const doRefresh = (ev) => {
    addPage(true);
    ev.detail.complete();
  };

  const handleNotificationNav = (notification) => {
    if (notification?.data?.to) {
      history.push(notification.data.to);
    }
  };

  useLayoutEffect(() => {
    if (contentRef.current && !isBootstrapped) {
      setTimeout(() => {
        const ionContentHeight = contentRef.current.offsetHeight;
        if (ionContentHeight >= 700) {
          setPerPage(20);
        } else {
          setPerPage(10);
        }
        setIsBootstrapped(true);
      }, 0);
    }
  }, [isBootstrapped]);

  useEffect(() => {
    if (user && isBootstrapped) {
      addPage();
    }
  }, [user, isBootstrapped]);

  useEffect(() => () => abort(), []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen ref={contentRef}>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {loading && notifications.length < 1 ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <IonList>
              {notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onClick={handleNotificationNav}
                />
              ))}
            </IonList>

            {/*
          for some reason infinite scroll breaks when disabled changes from true back to false
          this is a workaround, just remove the element when it's disabled and recreate when enabled
        */}
            {infiniteDisabled || (
              <IonInfiniteScroll
                onIonInfinite={loadData}
                threshold="100px"
                disabled={infiniteDisabled}
              >
                <IonInfiniteScrollContent loadingSpinner="crescent" />
              </IonInfiniteScroll>
            )}
          </motion.div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default NotificationsPage;
