import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import DropdownInput from 'common/atoms/dropDown/DropdownInput';
import { useFeatureFlagsContext } from 'providers/featureflags-provider';
import { Stack } from '@mui/material';
import MainImage from '../mainImage/MainImage';

const imgStyle = {
  paddingTop: '70px',
  marginBottom: '18px',
  marginTop: 0,
};

const DropdownListPage = ({
  fields,
  paginationMeta,
  nextOnClick,
  prevOnClick,
  title,
  subtitle,
  categoryId,
  questionId,
  dataFetcher,
  dataSaver,
  backgroundOverlay,
  allowForDisabledButtonState,
  label,
  mainImage,
  titleStyleOverride,
  exitIconOnClickOverride,
}) => {
  const [selectedFieldsState, setSelectedFieldsState] = useState(null);
  const { isSaveAndContinueAlwaysEnabled } = useFeatureFlagsContext() || {};

  useEffect(async () => {
    const state = await dataFetcher();
    const updatedFieldState = (state[questionId] || []).flatMap((n) => (!n.option_id ? [] : n.option_id));
    setSelectedFieldsState(updatedFieldState);
  }, []);

  const saveField = () => {
    const converted = selectedFieldsState.map((fieldValue) => ({
      category_id: categoryId,
      question_id: questionId,
      option_id: fieldValue,
      value: 'selected',
    }));
    dataSaver(converted).then(() => {
      nextOnClick();
    });
  };

  const onListChange = (fieldValue) => (fieldValue ? setSelectedFieldsState(fieldValue) : setSelectedFieldsState([]));
  const isNextButtonDisabled = !isSaveAndContinueAlwaysEnabled && allowForDisabledButtonState && !selectedFieldsState?.length;

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={title}
      subtitle={subtitle}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: saveField, isDisabled: isNextButtonDisabled }}
      backgroundOverlay={backgroundOverlay}
      titleStyleOverride={titleStyleOverride}
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      {!selectedFieldsState ? (
        <WholeMeLoadingCircle />
      ) : (
        <Stack>
          <DropdownInput
            selectedItem={selectedFieldsState}
            items={fields}
            onChange={(fieldValue) => onListChange(fieldValue)}
            label={label}
          />
          <MainImage imageSrc={mainImage} imageAlt={mainImage} imageStylesOverride={imgStyle} />
        </Stack>
      )}
    </ProfileIntakeStepPage>
  );
};

export default DropdownListPage;
