import React from 'react';
import {
  Dialog,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { modalStyles, boxStyles } from './wholeMeProfileBadgesStyles';
import { CloseIcon } from '../../icons/Icons';

const WholeMeProfileBadgesModal = ({ isOpen, children, handleClose }) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    PaperProps={{
      style: { ...modalStyles },
    }}
  >
    <Stack>
      <Box onClick={handleClose}>
        <CloseIcon cursor="pointer" width={14} height={14} marginLeft="16rem" paddingTop={14} paddingRight={7} />
      </Box>
      <Box
        sx={boxStyles}
      >
        <Typography sx={{ fontSize: '18px', color: '#2D2D2D', fontWeight: '700' }}>{children.title}</Typography>
        <img width="112px" height="124px" src={children.icon} alt={children.title} />
        <Typography sx={{ fontSize: '14px', color: '#5B5B5B', fontWeight: '400' }}>{children.description}</Typography>
      </Box>
    </Stack>
  </Dialog>
);

export default WholeMeProfileBadgesModal;
