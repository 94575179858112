/* eslint-disable import/prefer-default-export */
export const healthIntakeXpData = [
  {
    label: 'Set or achieved a fitness goal',
    value: 'set-or-achieved-a-fitness-goal',
    imageSrc: 'assets/profile/health/fitness-goal.svg',
    imageAlt: 'strong person',
    iconSrc: 'assets/profile/health/fitness-goal.svg',
  },
  {
    label: 'Set or achieved a spiritual goal',
    value: 'set-or-achieved-a-spiritual-goal',
    imageSrc: 'assets/profile/health/spiritual-goal.svg',
    imageAlt: 'person meditating',
    iconSrc: 'assets/profile/health/spiritual-goal.svg',
  },
  {
    label: 'Set or achieved a nutrition goal',
    value: 'set-or-achieved-a-nutrition-goal',
    imageSrc: 'assets/profile/health/nutrition-goal.svg',
    imageAlt: 'person with happy tummy',
    iconSrc: 'assets/profile/health/nutrition-goal.svg',
  },
  {
    label: 'Found a health provider',
    value: 'found-a-health-provider',
    imageSrc: 'assets/profile/health/found-health-provider.svg',
    imageAlt: 'stethoscope',
    iconSrc: 'assets/profile/health/found-health-provider.svg',
  },
  {
    label: 'Navigated a mental health challenge',
    value: 'navigated-a-mental-health-challenge',
    imageSrc: 'assets/profile/health/mental-health-challenge.svg',
    imageAlt: 'person sitting up in bed',
    iconSrc: 'assets/profile/health/mental-health-challenge.svg',
  },
  {
    label: 'Struggled with body image',
    value: 'struggled-with-body-image',
    imageSrc: 'assets/profile/health/body-image.svg',
    imageAlt: 'dual-toned person',
    iconSrc: 'assets/profile/health/body-image.svg',
  },
  {
    label: 'Serious accident or health condition',
    value: 'serious-accident-or-health-condition',
    imageSrc: 'assets/profile/health/serious-accident.svg',
    imageAlt: 'person in wheelchair',
    iconSrc: 'assets/profile/health/serious-accident.svg',
  },
  {
    label: 'Serious health challenge of a loved one',
    value: 'serious-health-challenge-of-a-loved-one',
    imageSrc: 'assets/profile/health/health-challenge-loved-one.svg',
    imageAlt: 'heart break',
    iconSrc: 'assets/profile/health/health-challenge-loved-one.svg',
  },
];
