import appStrings from 'common/app_strings';

const {
  allLabel, academicLabel, passionLabel, wellnessLabel, careerLabel,
} = appStrings.goals.labels;
const defaultImgProps = {
  sx: {
    height: '32px',
    maxWidth: '40px',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const filters = [
  {
    value: 'all',
    label: allLabel,
    icon: '/assets/all-goal.svg',
    type: 'group',
    classname: 'goals-indicator-all',
    avatarStyles: {
      backgroundColor: '#03295f',
    },
    imgProps: {
      ...defaultImgProps,
      sx: {
        ...defaultImgProps.sx,
        position: 'relative',
        left: '3px',
        maxWidth: '32px',
      },
    },
  },
  {
    value: 'academic',
    label: academicLabel,
    icon: '/assets/academic-goal.svg',
    type: 'group',
    classname: 'goals-indicator-academic',
    avatarStyles: {
      backgroundColor: '#2cb669',
    },
    imgProps: {
      ...defaultImgProps,
      sx: {
        ...defaultImgProps.sx,
        height: '30px',
      },
    },
  },
  {
    value: 'passion',
    label: passionLabel,
    icon: '/assets/passion-goal.svg',
    type: 'group',
    classname: 'goals-indicator-passion',
    avatarStyles: {
      backgroundColor: '#f66324',
    },
    imgProps: {
      ...defaultImgProps,
      sx: {
        ...defaultImgProps.sx,
      },
    },
  },
  {
    value: 'health',
    label: wellnessLabel,
    icon: '/assets/health-goal.svg',
    type: 'group',
    classname: 'goals-indicator-health',
    avatarStyles: {
      backgroundColor: '#062d8f',
    },
    imgProps: {
      ...defaultImgProps,
      sx: {
        ...defaultImgProps.sx,
        height: '39px',
      },
    },
  },
  {
    value: 'career',
    label: careerLabel,
    icon: '/assets/career-goal.svg',
    type: 'group',
    classname: 'goals-indicator-career',
    avatarStyles: {
      backgroundColor: '#2d2d2d',
    },
    imgProps: {
      ...defaultImgProps,
      sx: {
        ...defaultImgProps.sx,
        height: '36px',
      },
    },
  },
];
