import React, {
  useState,
  useEffect,
} from 'react';
import {
  IonFooter,
  IonIcon,
  IonText,
  IonRouterLink,
} from '@ionic/react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import './Footer.css';
import { useAuthContext } from '../providers/auth-provider';
import { useStore } from '../providers/store-provider';
import { useAppState } from '../providers/app-state/app-state-provider';
import { feedSelector } from '../redux/slices/feed';
import appStrings from '../common/app_strings';
import { buttonNoSelectStyles } from '../theme/styles';

const { common: strings } = appStrings;

const Footer = (_, ref) => {
  const location = useLocation();
  const history = useHistory();
  const { activeFilter } = useSelector(feedSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasPostTargets, setHasPostTargets] = useState(false);
  const { user } = useAuthContext();
  const appState = useAppState();
  const open = Boolean(anchorEl);
  const { notificationCount } = useStore();

  const handlePostNavigation = () => {
    if (user?.role === 'protege' || hasPostTargets) {
      history.push('/post', activeFilter);
    }
  };

  const handlePlusClick = (event) => {
    if (user?.role === 'protege') {
      setAnchorEl(event.currentTarget);
    } else {
      handlePostNavigation();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(async () => {
    if (user?.isFlagged && user?.role !== 'admin') {
      return;
    }

    if (appState.user?.postTargets) {
      const { groups, teams } = appState.user.postTargets;
      const hasTargets = !!(groups?.length || teams?.length);
      setHasPostTargets(hasTargets);
    }
  }, [user, appState]);

  return (
    <IonFooter class="ion-no-border" ref={ref}>
      <div className="footer-container">
        {user && (
          <IonRouterLink routerLink="/tabs/feed">
            <div className="footer-button">
              <img
                alt="explore-icon"
                src={
                  location.pathname === '/tabs/feed'
                    ? '/assets/icon/footer-icons/Explore-Dark.svg'
                    : '/assets/icon/footer-icons/Explore.svg'
                }
              />
              <br />
              {strings.explore}
            </div>
          </IonRouterLink>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        {user && (
          <IonRouterLink routerLink={`/tabs/${user?.role}/goals`}>
            <div className="footer-button">
              <img
                alt="goal icon"
                src={
                  location.pathname.startsWith(`/tabs/${user?.role}/goals`)
                    ? '/assets/icon/footer-icons/Goals-Dark.svg'
                    : '/assets/icon/footer-icons/Goals.svg'
                }
              />
              <br />
              {strings.goals}
            </div>
          </IonRouterLink>
        )}
        {user?.role === 'protege' && (
          <Menu
            id="plus-tab-popup"
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <MenuItem disableRipple onClick={handlePostNavigation}>
              {strings.addPost}
            </MenuItem>
            <MenuItem disableRipple>
              <IonRouterLink routerLink="/protege/goals-create">
                {strings.addGoal}
              </IonRouterLink>
            </MenuItem>
          </Menu>
        )}
        <div>
          <Button
            className="footer-button"
            onClick={handlePlusClick}
            disabled={!hasPostTargets}
            style={buttonNoSelectStyles}
          >
            <IonIcon
              src="/assets/icon/orange-circle-filled.svg"
              className="plus-button"
              color={!hasPostTargets ? 'unverified' : 'warning'}
            />
          </Button>
        </div>
        <IonRouterLink routerLink="/tabs/updates">
          <div className="footer-button">
            <div className="notification-icon-container">
              <img
                alt="updates icon"
                src={
                  location.pathname === '/tabs/updates'
                    ? '/assets/icon/footer-icons/Update-filled.svg'
                    : '/assets/icon/footer-icons/Update.svg'
                }
              />
              {notificationCount > 0 && (
                <div className="notification-dot">{notificationCount}</div>
              )}
            </div>
            <IonText>
              {strings.updates}
            </IonText>
          </div>
        </IonRouterLink>
        {user?.role !== 'admin' ? (
          <IonRouterLink routerLink={`/tabs/${user?.role}/teams`}>
            <div className="footer-button">
              <img
                alt="team icon"
                src={
                  location.pathname.startsWith(`/tabs/${user?.role}/teams`)
                    || location.pathname.startsWith('/team-view/')
                    || location.pathname.startsWith('/tabs/teams')
                    ? '/assets/icon/footer-icons/Teams-Dark.svg'
                    : '/assets/icon/footer-icons/Teams.svg'
                }
              />
              <br />
              <IonText>
                {user?.role === 'protege' ? strings.teams : strings.proteges}
              </IonText>
            </div>
          </IonRouterLink>
        ) : (
          <IonRouterLink routerLink="/tabs/teams/admin">
            <div className="footer-button">
              <img
                alt="team icon"
                src={
                  location.pathname === '/tabs/teams/admin'
                    ? '/assets/icon/footer-icons/Teams-Dark.svg'
                    : '/assets/icon/footer-icons/Teams.svg'
                }
              />
              <br />
              {strings.proteges}
            </div>
          </IonRouterLink>
        )}
      </div>
    </IonFooter>
  );
};

export default React.forwardRef(Footer);
