import React, { useEffect, useState } from 'react';
import IonicProviderPageShell from 'common/molecules/ionicProviderPageShell/IonicProviderPageShell';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthContext } from 'providers/auth-provider';
import { computeSteps } from 'pages/wholeme/util/compute_container';
import ProfileIntakeWizard from '../pages/wizard/ProfileIntakeWizard';
import { WholeMeClient } from '../util/wholeme_client';

const ProfileIntakeWizardRoute = () => {
  const history = useHistory();
  const { categoryId, stepId } = useParams();
  const { hasCompletedProfile } = useAuthContext();
  const [steps, setSteps] = useState([]);

  const onSubmitBack = () => {
    if (!categoryId) {
      history.push('/');
      return;
    }

    if (stepId) {
      history.goBack();
      return;
    }

    history.push('/');
  };

  const onSubmitFinal = () => {
    if (!categoryId) {
      history.push('/');
      return;
    }

    if (stepId) {
      history.goBack();
      return;
    }

    const wholeMeClient = new WholeMeClient();
    wholeMeClient.fetchPortrait().then((fetched) => {
      const completed = fetched?.portrait?.completed;
      const firstIncompleteCategory = Object.keys(fetched?.portrait.detail).find((key) => !fetched?.portrait.detail[key]);
      if (completed && !hasCompletedProfile) {
        history.push('/profile-completed');
      } else if (completed && hasCompletedProfile) {
        history.push('/');
      } else {
        history.replace(`/wholeme/wizard/${firstIncompleteCategory}`);
        history.go();
      }
    });
  };

  useEffect(() => {
    computeSteps(categoryId, stepId).then((result) => setSteps(result));
  }, []);

  return (
    <IonicProviderPageShell>
      <ProfileIntakeWizard
        onSubmitBack={onSubmitBack}
        onSubmitFinal={onSubmitFinal}
        wholeMeClient={new WholeMeClient()}
        stepCategoryKeys={steps}
      />
    </IonicProviderPageShell>
  );
};
export default ProfileIntakeWizardRoute;
