// eslint-disable-next-line import/prefer-default-export
export const buildProtegeSchool = (
  grade,
  schoolType,
) => {
  let school = '';
  let gradeLvl = '';

  if (schoolType === 'CO') {
    school = 'College';
  } else {
    school = 'High School';
  }

  if (grade === 'Year 1 Post Secondary') {
    gradeLvl = 'Freshman';
  } else if (grade === '10' || grade === 'Year 2 Post Secondary') {
    gradeLvl = 'Sophomore';
  } else if (grade === '11' || grade === 'Year 3 Post Secondary') {
    gradeLvl = 'Junior';
  } else if (grade === '12' || grade === 'Year 4 Post Secondary') {
    gradeLvl = 'Senior';
  }
  const schoolString = `${school} ${gradeLvl}`;
  return schoolString;
};
