import React from 'react';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import {
  IonContent,
  IonButton,
  IonPage,
  IonButtons,
  IonRouterLink,
  IonIcon,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import appStrings from 'common/app_strings';
import { useAppState } from '../providers/app-state/app-state-provider';

const {
  errorScreen,
} = appStrings;

const titleStyle = {
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 700,
  fontSize: '24px',
};
const bodyStyle = {
  color: '#2d2d2d',
  mt: 3.5,
  fontSize: '18px',
  fontWeight: 500,
};

const ErrorScreen = ({ onLogin }) => {
  const platform = useAppState();

  return (
    <IonPage>
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar
            className="login-button-toolbar"
            style={{
              '--background': 'var(--ion-background-color)',
            }}
          >
            <IonButtons slot="end">
              <IonRouterLink routerLink="/register/login">
                <IonButton mode="ios" style={{ width: 'auto', '--background-hover': 'transparent' }} onClick={onLogin}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <IonIcon
                      style={{ fontSize: '18px' }}
                      slot="icon-only"
                      src="/assets/login-lock.svg"
                      mode="ios"
                    />
                    <Typography
                      sx={{ color: '#063D8F', fontSize: 15, fontWeight: 700 }}
                    >
                      {errorScreen.login}
                    </Typography>
                  </Stack>
                </IonButton>
              </IonRouterLink>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <Stack sx={{
          alignItems: 'center', mx: 5, gap: '3rem',
        }}
        >
          <Box sx={{ pt: 5, mx: 2 }}>
            <Typography align="center" sx={titleStyle}>
              {errorScreen.title}
            </Typography>
            <Typography align="center" sx={bodyStyle}>
              {errorScreen.body}
              <br />
              {errorScreen.body2}
            </Typography>
          </Box>
          <Stack
            spacing={2.5}
            sx={{
              alignItems: 'center',
              mt: 3,
              mx: 3,
              mb: 3,
            }}
          >
            <object
              className="no-pointer-events"
              type="image/svg+xml"
              data="/assets/register/four-fish.svg"
              style={{ width: '100%', maxWidth: '265px' }}
            >
              <img
                src="/assets/register/four-fish.svg"
                alt="four fish"
              />
            </object>

          </Stack>
          <IonButton
            href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
            className="register-btn"
            size="large"
            expand="block"
            shape="round"
            style={{
              '--background-hover': '#063D8F',
              '--background-activated': '#063D8F',
              '--background-focused': '#063D8F',
              width: platform?.isNativePlatform ? '100%' : '75%',
            }}
            mode="ios"
          >
            {errorScreen.contactSupport}
          </IonButton>
        </Stack>
      </IonContent>
    </IonPage>
  );
};

export default ErrorScreen;
