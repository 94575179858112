import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
} from '@ionic/react';
import {
  Stack,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'use-http';
import { motion } from 'framer-motion';
import appStrings from '../../../common/app_strings';
import BackrgroundMeterContent from './BackrgroundMeterContent';
import Header from '../../../components/Header';
import LoadingSpinner from '../../../components/generic/LoadingSpinner';

const { checkrStatusScreen } = appStrings;

const BackgroundCheckMeterScreen = () => {
  const history = useHistory();
  const { get: getBackgroundCheck, response: backgroundRes } = useFetch('users/current-user/background-check');
  const [backgroundCheckProgress, setBackgroundCheckProgress] = useState();
  const [bgNotStarted, setBgNotStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const buildLubavMeta = {
    title: checkrStatusScreen.lubavCard.title,
    body: checkrStatusScreen.lubavCard.body,
    callback: () => { history.push('/profile/0?role=admin'); },
  };

  const noBGMeta = {
    title: checkrStatusScreen.noBgCard.title,
    body: checkrStatusScreen.noBgCard.body,
    callback: () => { history.push('/checkr-form'); },
  };

  const headToFeedMeta = {
    title: checkrStatusScreen.headToFeedCard.title,
    body: checkrStatusScreen.headToFeedCard.body,
    callback: () => { history.push('/tabs/feed'); },
  };

  const formatText = (text) => {
    const regex = /explore the community/;
    const replaceWith = '<strong>explore the commuity</strong>';
    const formattedText = text.replace(regex, () => replaceWith);

    return (
      <span dangerouslySetInnerHTML={{ __html: formattedText }} />
    );
  };

  useEffect(async () => {
    try {
      setIsLoading(true);
      const backgroundRecord = await getBackgroundCheck();
      if (backgroundRes.ok) {
        if (backgroundRes?.data === 'No background check found') {
          setBgNotStarted(true);
        }
        if (backgroundRes?.data?.id) {
          const progress = backgroundRecord?.status && 100;
          setBackgroundCheckProgress(progress);
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent
        fullscreen
      >
        <Stack
          sx={{
            mx: '24px',
            pt: '15px',
            paddingBottom: '40px',
          }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <BackrgroundMeterContent
                header={checkrStatusScreen.header}
                body={bgNotStarted ? checkrStatusScreen.bodyNoBG : formatText(checkrStatusScreen.body)}
                contentMeta={buildLubavMeta}
                statusMeter={backgroundCheckProgress}
                showNoBgCard={bgNotStarted}
                noBgMeta={noBGMeta}
                completeProfileMeta={headToFeedMeta}
              />
            </motion.div>
          )}

        </Stack>
      </IonContent>
    </IonPage>
  );
};

export default BackgroundCheckMeterScreen;
