import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { useHistory, useParams } from 'react-router-dom';
import { IonContent } from '@ionic/react';
import toast from 'react-hot-toast';
import PageWithBackButton from '../components/generic/PageWithBackButton';
import Post from '../components/Post';
import Footer from '../components/Footer';
import { useAuthContext } from '../providers/auth-provider';

const PostPage = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [post, setPost] = useState(null);
  const { get, response } = useFetch();
  const history = useHistory();

  useEffect(async () => {
    if (user) {
      const data = await get(`/posts/${id}`);
      if (response.ok) {
        setPost(data);
      } else {
        // in practice, these notifications shouldn't exist, because we are
        // deleting notifications when a post is deleted but just in case,
        // handle it gracefully
        toast(
          'Post not found',
          { duration: 2500 },
        );
        history.push('/tabs/feed');
      }
    }
  }, [user]);

  return (
    <PageWithBackButton backButtonText={post?.goal_id ? 'Goal' : 'Post'}>
      <IonContent>
        {post && <Post post={post} setPost={setPost} />}
      </IonContent>
      <Footer />
    </PageWithBackButton>
  );
};

export default PostPage;
