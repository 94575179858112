import React, { useRef, useEffect } from 'react';
import { IonContent } from '@ionic/react';
import { Stack } from '@mui/material';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useAuthContext } from '../../providers/auth-provider';
import PageWithBackButton from '../../components/generic/PageWithBackButton';
import Footer from '../../components/Footer';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.3 } },
};

const BackrGeneralInvest = () => {
  const { user } = useAuthContext();

  const footerRef = useRef(null);

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardWillShow', () => {
      if (footerRef.current) {
        footerRef.current.style.display = 'none';
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (footerRef.current) {
        footerRef.current.style.display = 'initial';
      }
    });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://donorbox.org/widget.js';
    script.async = true;
    script.paypalExpress = false;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageWithBackButton backButtonText="Invest">
      <IonContent>
        {user.role === 'backr' ? (
          <motion.div variants={variants} initial="hidden" animate="visible" style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack
              spacing={3}
              sx={{
                mx: 3,
                mt: 2,
              }}
            >
              <iframe
                title="donorbox campaign"
                // eslint-disable-next-line max-len
                src="https://donorbox.org/embed/support-backrs?default_interval=m&hide_donation_meter=true&enable_auto_scroll=false"
                name="donorbox"
                allowpaymentrequest="allowpaymentrequest"
                seamless="seamless"
                height="900px"
                width="100%"
                style={{
                  overflow: 'hidden',
                  border: 0,
                  maxWidth: '500px',
                  minWidth: '310px',
                  maxHeight: 'none! important',
                }}
              />
            </Stack>
          </motion.div>
        ) : null}
      </IonContent>
      <Footer ref={footerRef} />
    </PageWithBackButton>
  );
};

BackrGeneralInvest.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default BackrGeneralInvest;
