/* eslint-disable camelcase */
import React from 'react';
import WholeMeProfileSection from 'pages/wholeme/molecules/wholeMeProfileSection/WholeMeProfileSection';
import WholeMeProfileSectionDetail from 'pages/wholeme/molecules/wholeMeProfileSectionDetail/WholeMeProfileSectionDetail';
import { FinancesIcon } from 'pages/wholeme/icons/Icons';
import WholeMeProfileSectionRow from '../wholeMeProfileSectionRow/WholeMeProfileSectionRow';
import { fieldInfoUrlFor, fieldLabelFor } from '../../util/profile_field_data';
import { resolveDefaultIcons } from '../../util/profile_util';

const WholeMeProfileFinances = ({
  profile, onEdit, onPost, isViewOnly,
}) => {
  const { finances_profile = [], finances_credentials = [] } = profile;

  if (!onEdit && finances_profile.length < 1 && finances_credentials.length < 1) {
    return null;
  }

  const renderEdit = () => (
    <>
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Financial Profile"
        items={finances_profile.map((n) => fieldLabelFor(n.option_id, n.category_id, n.question_id))}
        categoryId="finances"
        stepId="finances_profile"
        isViewOnly={isViewOnly}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Financial Credentials"
        items={finances_credentials.map((n) => fieldLabelFor(n.option_id, n.category_id, n.question_id))}
        categoryId="finances"
        stepId="finances_credentials"
        isViewOnly={isViewOnly}
      />
    </>
  );

  const renderView = () => (
    <>
      {finances_profile.length > 0
        && finances_profile.map((n) => (
          <WholeMeProfileSectionRow
            onPost={onPost}
            iconSrc="assets/icons/financial/mingcute_pig-money-fill.svg"
            infoUrl={fieldInfoUrlFor(n.option_id, n.category_id, n.question_id)}
            label={fieldLabelFor(n.option_id, n.category_id, n.question_id)}
          />
        ))}
      {finances_credentials.length > 0
        && finances_credentials.map((n) => (
          <WholeMeProfileSectionRow
            onPost={onPost}
            iconSrc={resolveDefaultIcons('finances_credentials', n.option_id)}
            infoUrl={fieldInfoUrlFor(n.option_id, n.category_id, n.question_id)}
            label={fieldLabelFor(n.option_id, n.category_id, n.question_id)}
          />
        ))}
    </>
  );

  return (
    <WholeMeProfileSection
      title="Finances"
      editable={!!onEdit}
      titleIcon={<FinancesIcon height={67} />}
    >
      {onEdit ? renderEdit() : renderView()}
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileFinances;
