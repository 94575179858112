/* eslint-disable */
export const ProfileMock = {
  first_name: [
    {
      category_id: 'identity',
      question_id: 'first_name',
      value: 'Jerry Backrs🙂🤢',
    },
  ],
  last_name: [
    {
      category_id: 'identity',
      question_id: 'last_name',
      value: 'BackrsHi',
    },
  ],
  city: [
    {
      category_id: 'identity',
      question_id: 'city',
      value: 'Gurnee',
    },
  ],
  state: [
    {
      category_id: 'identity',
      question_id: 'state',
      value: 'IL',
    },
  ],
  zip_code: [
    {
      category_id: 'identity',
      question_id: 'zip_code',
      value: '60031',
    },
  ],
  birthday: [
    {
      category_id: 'identity',
      question_id: 'birthday',
      value: '01/11/1980',
    },
  ],
  excited: [
    {
      category_id: 'identity',
      question_id: 'excited',
      value: 'Young people have so much to teach the adults in their lives, and a committed backr will learn and grow just as much as a protege through the experience!',
    },
  ],
  role: [
    {
      category_id: 'identity',
      question_id: 'role',
      value: 'backr',
    },
  ],
  profile_media_id: [
    {
      category_id: 'identity',
      question_id: 'profile_media_id',
      value: 846,
    },
  ],
  profile_image: [
    {
      user_id: 1019,
      category_id: 'identity',
      question_id: 'profile_image',
      value:
        'https://media.staging.backrs-dev.com/images%2Fb9dadca8-1779-4d98-9ddd-5ac006819215?Expires=1688853000396&Key-Pair-Id=KEF9FUJVH1W0H&Signature=SEshobnYOMpWYAcuxcrPwx1sSK2sO2ksiDLbNcYjPPdbrcRqxvPBqvr1XvcO0u-NQxrvphNlVswHTB-PXdsqhb99ZUzLJ57zF9K1GpDIr9fL7fRaxfSOWqI1RfdGXk0nC6UNxAj93hazvj9QJFBj7DJLA8u4SSpaDU8K16J948rbHtJACHgiOa8WnXJJ-lgT~uA9Xjf~c4yLnyX83FvBRrSXBDplUILcHrKbx-YCYkU9Kod55GdjR8MyhqVYRsEh2RpbL1c5~Fu91W4OnxAotwvSQnlOQ0njEMCfRrBkW-TcU4KROsbmRz-hMpgK09VuTIrnupTwm~6JaWizFZmGVg__',
    },
  ],
  purpose_video: [
    {
      category_id: 'video',
      created_at: '2023-09-13T19:58:36.000Z',
      id: 1,
      option_id: null,
      question_id: 'video',
      suboption_id: null,
      updated_at: '2023-09-13T19:58:36.000Z',
      uri: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      user_id: 1019,
      value: '36',
    }
  ],
  purpose_video_thumbnail: [
    {
      uri: 'https://s3-alpha-sig.figma.com/img/da78/0ac1/4d1a7635044baa910915457b9d856b07?Expires=1691366400&Signature=N5XEpyXWCL~7F7euRzoUzurSWi8n1d0YySzGTeJolkdz00rWHh5ul3Tsl2ZmE30E5EEKRSXZZYQ46q8G2cbku7PzYEl-53TWpnbMl-ru5X1bqyUtxIBJ-tV4-uUmjW0688rFqKir84Q3Yr3vA7H4cDD9hH0x-KrYHz4W4FyCwMAzXckSGrd~tNg-vlDe4F20GU7yQzVZA2yJxECFQP9Uf-7cO4DvEBF35w5fpiuxWur0YByU5ptEobHtpFLz98VWEyvxm~FfozwbATZtCEfzOe-wbbvpWV~dPwtr58Tv5E9pPVjbAQ53JdxJDn658OExwAHxfZT~PLkVxhsAuS5IEg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    }
  ],
  gender: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'gender',
      value: 'female',
    },
  ],
  ethnicity: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'ethnicity',
      value: 'white',
    },
  ],
  first_gen_college: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'first_gen_college',
      value: true,
    },
  ],
  immigrant: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'immigrant',
      value: true,
    },
  ],
  rural: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'rural',
      value: false,
    },
  ],
  military: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'military',
      value: false,
    },
  ],
  disability: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'disability',
      value: true,
    },
  ],
  lgbtq: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'lgbtq',
      value: false,
    },
  ],
  college_athletics: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'college_athletics',
      value: true,
    },
  ],
  adopted_foster: [
    {
      user_id: 1019,
      category: 'identity',
      option_id: 'adopted_foster',
      value: false,
    },
  ],
  religion: [
    {
      id: 2417,
      user_id: 1019,
      created_at: '2023-07-09T00:00:02.000Z',
      updated_at: '2023-07-09T00:00:02.000Z',
      category_id: 'identity',
      question_id: 'religion',
      option_id: 'islam',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2418,
      user_id: 1019,
      created_at: '2023-07-09T00:00:02.000Z',
      updated_at: '2023-07-09T00:00:02.000Z',
      category_id: 'identity',
      question_id: 'religion',
      option_id: 'hinduism',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2419,
      user_id: 1019,
      created_at: '2023-07-09T00:00:02.000Z',
      updated_at: '2023-07-09T00:00:02.000Z',
      category_id: 'identity',
      question_id: 'religion',
      option_id: 'judaism',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2420,
      user_id: 1019,
      created_at: '2023-07-09T00:00:02.000Z',
      updated_at: '2023-07-09T00:00:02.000Z',
      category_id: 'identity',
      question_id: 'religion',
      option_id: 'other',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2421,
      user_id: 1019,
      created_at: '2023-07-09T00:00:02.000Z',
      updated_at: '2023-07-09T00:00:02.000Z',
      category_id: 'identity',
      question_id: 'religion',
      option_id: 'athiesm',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2422,
      user_id: 1019,
      created_at: '2023-07-09T00:00:02.000Z',
      updated_at: '2023-07-09T00:00:02.000Z',
      category_id: 'identity',
      question_id: 'religion',
      option_id: 'other',
      suboption_id: 'other_religion',
      value: 'DOG',
    },
  ],
  quiz: [
    {
      id: 2392,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'Enneagram',
      suboption_id: 'Enneagram Primary',
      value: 'Four',
    },
    {
      id: 2393,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'Enneagram',
      suboption_id: 'Enneagram Wing',
      value: 'Three',
    },
    {
      id: 2394,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'Enneagram',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2395,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'HIGH5',
      suboption_id: 'Doing',
      value: 'Focus Expert',
    },
    {
      id: 2396,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'HIGH5',
      suboption_id: 'Doing',
      value: 'Optimist',
    },
    {
      id: 2397,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'HIGH5',
      suboption_id: 'Doing',
      value: 'Problem Solver',
    },
    {
      id: 2398,
      user_id: 1019,
      created_at: '2023-07-08T22:57:44.000Z',
      updated_at: '2023-07-08T22:57:44.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'HIGH5',
      suboption_id: null,
      value: 'selected',
    },
  ],
  hobbies: [
    {
      id: 1047,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Creating',
      suboption_id: 'Crocheting',
      value: 'selected',
    },
    {
      id: 1048,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Creating',
      suboption_id: 'Design',
      value: 'selected',
    },
    {
      id: 1049,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Creating',
      suboption_id: 'Drawing',
      value: 'selected',
    },
    {
      id: 1050,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Performing arts',
      suboption_id: 'Jazz Dance',
      value: 'selected',
    },
    {
      id: 1051,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Performing arts',
      suboption_id: 'Stagecrew',
      value: 'selected',
    },
    {
      id: 1052,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Performing arts',
      suboption_id: 'Choreography',
      value: 'selected',
    },
    {
      id: 1053,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Gaming',
      suboption_id: 'First-person shooter',
      value: 'selected',
    },
    {
      id: 1054,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Gaming',
      suboption_id: 'Racing games',
      value: 'selected',
    },
    {
      id: 1055,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Gaming',
      suboption_id: 'Real-time strategy',
      value: 'selected',
    },
    {
      id: 1056,
      user_id: 1019,
      created_at: '2023-07-08T08:02:10.000Z',
      updated_at: '2023-07-08T08:02:10.000Z',
      category_id: 'interests',
      question_id: 'hobbies',
      option_id: 'Reading',
      suboption_id: 'Science',
      value: 'selected',
    },
  ],
  clubs: [
    {
      id: 738,
      user_id: 1019,
      created_at: '2023-07-07T15:34:28.000Z',
      updated_at: '2023-07-07T15:34:28.000Z',
      category_id: 'interests',
      question_id: 'clubs',
      option_id: null,
      suboption_id: null,
      value: 'AFS Intercultural Programs—USA (American Field Service)',
    },
    {
      id: 739,
      user_id: 1019,
      created_at: '2023-07-07T15:34:28.000Z',
      updated_at: '2023-07-07T15:34:28.000Z',
      category_id: 'interests',
      question_id: 'clubs',
      option_id: null,
      suboption_id: null,
      value: 'Education, American Council on (ACE),',
    },
    {
      id: 740,
      user_id: 1019,
      created_at: '2023-07-07T15:34:28.000Z',
      updated_at: '2023-07-07T15:34:28.000Z',
      category_id: 'interests',
      question_id: 'clubs',
      option_id: null,
      suboption_id: null,
      value: 'American Jail Association',
    },
  ],
  degree: [
    {
      id: 911,
      user_id: 1019,
      created_at: '2023-07-08T02:55:56.000Z',
      updated_at: '2023-07-08T02:55:56.000Z',
      category_id: 'education',
      question_id: 'degree',
      option_id: null,
      suboption_id: null,
      value: 'masters',
    },
  ],
  schools: [
    {
      id: 692,
      user_id: 1019,
      created_at: '2023-07-07T15:12:01.000Z',
      updated_at: '2023-07-07T15:12:01.000Z',
      category_id: 'education',
      question_id: 'schools',
      option_id: null,
      suboption_id: null,
      value: 'University of Washington Michael G. Foster School of Business',
    },
    {
      id: 693,
      user_id: 1019,
      created_at: '2023-07-07T15:12:01.000Z',
      updated_at: '2023-07-07T15:12:01.000Z',
      category_id: 'education',
      question_id: 'schools',
      option_id: null,
      suboption_id: null,
      value: 'Influential U',
    },
    {
      id: 694,
      user_id: 1019,
      created_at: '2023-07-07T15:12:01.000Z',
      updated_at: '2023-07-07T15:12:01.000Z',
      category_id: 'education',
      question_id: 'schools',
      option_id: null,
      suboption_id: null,
      value: 'N. L. Dalmia Institute',
    },
  ],
  education_xp: [
    {
      id: 695,
      user_id: 1019,
      created_at: '2023-07-07T15:12:02.000Z',
      updated_at: '2023-07-07T15:12:02.000Z',
      category_id: 'education',
      question_id: 'education_xp',
      option_id: 'Athlete',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 696,
      user_id: 1019,
      created_at: '2023-07-07T15:12:02.000Z',
      updated_at: '2023-07-07T15:12:02.000Z',
      category_id: 'education',
      question_id: 'education_xp',
      option_id: 'Residential Advisor',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 697,
      user_id: 1019,
      created_at: '2023-07-07T15:12:02.000Z',
      updated_at: '2023-07-07T15:12:02.000Z',
      category_id: 'education',
      question_id: 'education_xp',
      option_id: 'Research',
      suboption_id: null,
      value: 'selected',
    },
  ],
  occupation: [
    {
      id: 1149,
      user_id: 1019,
      created_at: '2023-07-08T08:10:34.000Z',
      updated_at: '2023-07-08T08:10:34.000Z',
      category_id: 'career',
      question_id: 'occupation',
      option_id: null,
      suboption_id: null,
      value: 'DOG TOR1',
    },
  ],
  career_stage: [
    {
      id: 1150,
      user_id: 1019,
      created_at: '2023-07-08T08:10:35.000Z',
      updated_at: '2023-07-08T08:10:35.000Z',
      category_id: 'career',
      question_id: 'career_stage',
      option_id: null,
      suboption_id: null,
      value: 'post-career',
    },
  ],
  career_xp: [],
  career_fields: [
    {
      id: 1164,
      user_id: 1019,
      created_at: '2023-07-08T08:12:17.000Z',
      updated_at: '2023-07-08T08:12:17.000Z',
      category_id: 'career',
      question_id: 'career_fields',
      option_id: 'Arts, entertainment, media',
      suboption_id: 'What subcategory?',
      value: 'Creator / artist',
    },
    {
      id: 1165,
      user_id: 1019,
      created_at: '2023-07-08T08:12:17.000Z',
      updated_at: '2023-07-08T08:12:17.000Z',
      category_id: 'career',
      question_id: 'career_fields',
      option_id: 'Arts, entertainment, media',
      suboption_id: 'What subcategory?',
      value: 'Entertainment and sports',
    },
    {
      id: 1166,
      user_id: 1019,
      created_at: '2023-07-08T08:12:17.000Z',
      updated_at: '2023-07-08T08:12:17.000Z',
      category_id: 'career',
      question_id: 'career_fields',
      option_id: 'Arts, entertainment, media',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 1167,
      user_id: 1019,
      created_at: '2023-07-08T08:12:17.000Z',
      updated_at: '2023-07-08T08:12:17.000Z',
      category_id: 'career',
      question_id: 'career_fields',
      option_id: 'Trades / services',
      suboption_id: 'What subcategory?',
      value: 'Manufacturing trades',
    },
    {
      id: 1168,
      user_id: 1019,
      created_at: '2023-07-08T08:12:17.000Z',
      updated_at: '2023-07-08T08:12:17.000Z',
      category_id: 'career',
      question_id: 'career_fields',
      option_id: 'Trades / services',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 1169,
      user_id: 1019,
      created_at: '2023-07-08T08:12:17.000Z',
      updated_at: '2023-07-08T08:12:17.000Z',
      category_id: 'career',
      question_id: 'career_fields',
      option_id: 'Food and natural resources',
      suboption_id: null,
      value: 'selected',
    },
  ],
  employers: [
    {
      id: 1170,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'Z-Trip',
    },
    {
      id: 1171,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'Seaway Bolt & Specials',
    },
    {
      id: 1172,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'asdfasd',
    },
    {
      id: 1173,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'a lk k dlksd',
    },
    {
      id: 1174,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: '2238 asdfasd as',
    },
    {
      id: 1175,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'Olio Devices',
    },
    {
      id: 1176,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'MIT-IBM Watson AI Lab',
    },
    {
      id: 1177,
      user_id: 1019,
      created_at: '2023-07-08T08:12:18.000Z',
      updated_at: '2023-07-08T08:12:18.000Z',
      category_id: 'career',
      question_id: 'employers',
      option_id: null,
      suboption_id: null,
      value: 'IBM SCOTT',
    },
  ],
  health_xp: [
    {
      id: 823,
      user_id: 1019,
      created_at: '2023-07-08T01:45:07.000Z',
      updated_at: '2023-07-08T01:45:07.000Z',
      category_id: 'health',
      question_id: 'health_xp',
      option_id: 'serious-accident-or-health-condition',
      suboption_id: null,
      value: 'selected',
    },
  ],
  health_interests: [
    {
      id: 846,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Mental and spiritual health',
      suboption_id: 'Interpersonal therapy (IPT)',
      value: 'selected',
    },
    {
      id: 847,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Nutritional practices',
      suboption_id: 'Eating local',
      value: 'selected',
    },
    {
      id: 848,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Nutritional practices',
      suboption_id: 'Keto ',
      value: 'selected',
    },
    {
      id: 849,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Outdoors',
      suboption_id: 'Canoeing',
      value: 'selected',
    },
    {
      id: 850,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Outdoors',
      suboption_id: 'Fishing',
      value: 'selected',
    },
    {
      id: 851,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Outdoors',
      suboption_id: 'Hiking',
      value: 'selected',
    },
    {
      id: 852,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Outdoors',
      suboption_id: 'Rock climbing',
      value: 'selected',
    },
    {
      id: 853,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Water sports',
      suboption_id: 'Sailing',
      value: 'selected',
    },
    {
      id: 854,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Water sports',
      suboption_id: 'Diving',
      value: 'selected',
    },
    {
      id: 855,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Water sports',
      suboption_id: 'Paddleboarding',
      value: 'selected',
    },
    {
      id: 856,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Water sports',
      suboption_id: 'Skimboarding',
      value: 'selected',
    },
    {
      id: 857,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Sport II',
      suboption_id: 'Heptathlon',
      value: 'selected',
    },
    {
      id: 858,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Sport II',
      suboption_id: 'Cricket',
      value: 'selected',
    },
    {
      id: 859,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Sport II',
      suboption_id: 'Racquetball',
      value: 'selected',
    },
    {
      id: 860,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Sport II',
      suboption_id: 'Squash',
      value: 'selected',
    },
    {
      id: 861,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Sport II',
      suboption_id: 'Table tennis',
      value: 'selected',
    },
    {
      id: 862,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Sport II',
      suboption_id: 'Triathlon',
      value: 'selected',
    },
    {
      id: 863,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Nutritional practices',
      suboption_id: 'Paleo',
      value: 'selected',
    },
    {
      id: 864,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Nutritional practices',
      suboption_id: 'Veganism',
      value: 'selected',
    },
    {
      id: 865,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Nutritional practices',
      suboption_id: 'Pescatarianism',
      value: 'selected',
    },
    {
      id: 866,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Fitness',
      suboption_id: 'Spin',
      value: 'selected',
    },
    {
      id: 867,
      user_id: 1019,
      created_at: '2023-07-08T01:45:18.000Z',
      updated_at: '2023-07-08T01:45:18.000Z',
      category_id: 'health',
      question_id: 'health_interests',
      option_id: 'Fitness',
      suboption_id: 'Swimming',
      value: 'selected',
    },
  ],
  health_credentials: [
    {
      id: 868,
      user_id: 1019,
      created_at: '2023-07-08T01:45:19.000Z',
      updated_at: '2023-07-08T01:45:19.000Z',
      category_id: 'health',
      question_id: 'health_credentials',
      option_id: 'Licensed nurse',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 869,
      user_id: 1019,
      created_at: '2023-07-08T01:45:19.000Z',
      updated_at: '2023-07-08T01:45:19.000Z',
      category_id: 'health',
      question_id: 'health_credentials',
      option_id: 'Dentist',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 870,
      user_id: 1019,
      created_at: '2023-07-08T01:45:19.000Z',
      updated_at: '2023-07-08T01:45:19.000Z',
      category_id: 'health',
      question_id: 'health_credentials',
      option_id: 'Licensed social worker',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 871,
      user_id: 1019,
      created_at: '2023-07-08T01:45:19.000Z',
      updated_at: '2023-07-08T01:45:19.000Z',
      category_id: 'health',
      question_id: 'health_credentials',
      option_id: 'Medical technician',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 872,
      user_id: 1019,
      created_at: '2023-07-08T01:45:19.000Z',
      updated_at: '2023-07-08T01:45:19.000Z',
      category_id: 'health',
      question_id: 'health_credentials',
      option_id: 'Licensed pharmacist',
      suboption_id: null,
      value: 'selected',
    },
  ],
  relationships_xp: [
    {
      id: 1189,
      user_id: 1019,
      created_at: '2023-07-08T08:15:12.000Z',
      updated_at: '2023-07-08T08:15:12.000Z',
      category_id: 'relationships',
      question_id: 'relationships_xp',
      option_id: 'Sustained a friendship',
      suboption_id: null,
      value: null,
    },
  ],
  family: [
    {
      id: 1190,
      user_id: 1019,
      created_at: '2023-07-08T08:15:12.000Z',
      updated_at: '2023-07-08T08:15:12.000Z',
      category_id: 'relationships',
      question_id: 'family',
      option_id: 'Grandchildren',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 1191,
      user_id: 1019,
      created_at: '2023-07-08T08:15:12.000Z',
      updated_at: '2023-07-08T08:15:12.000Z',
      category_id: 'relationships',
      question_id: 'family',
      option_id: 'Children',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 1192,
      user_id: 1019,
      created_at: '2023-07-08T08:15:12.000Z',
      updated_at: '2023-07-08T08:15:12.000Z',
      category_id: 'relationships',
      question_id: 'family',
      option_id: 'Foster parent',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 1193,
      user_id: 1019,
      created_at: '2023-07-08T08:15:12.000Z',
      updated_at: '2023-07-08T08:15:12.000Z',
      category_id: 'relationships',
      question_id: 'family',
      option_id: 'Married',
      suboption_id: null,
      value: 'selected',
    },
  ],
  childhood: [
    {
      id: 1194,
      user_id: 1019,
      created_at: '2023-07-08T08:15:13.000Z',
      updated_at: '2023-07-08T08:15:13.000Z',
      category_id: 'relationships',
      question_id: 'childhood',
      option_id: 'Other',
      suboption_id: null,
      value: 'selected',
    },
  ],
  languages: [
    {
      id: 1195,
      user_id: 1019,
      created_at: '2023-07-08T08:15:17.000Z',
      updated_at: '2023-07-08T08:15:17.000Z',
      category_id: 'relationships',
      question_id: 'languages',
      option_id: null,
      suboption_id: null,
      value: 'Algonquin',
    },
    {
      id: 1196,
      user_id: 1019,
      created_at: '2023-07-08T08:15:17.000Z',
      updated_at: '2023-07-08T08:15:17.000Z',
      category_id: 'relationships',
      question_id: 'languages',
      option_id: null,
      suboption_id: null,
      value: 'Rhaeto-Romance',
    },
    {
      id: 1197,
      user_id: 1019,
      created_at: '2023-07-08T08:15:17.000Z',
      updated_at: '2023-07-08T08:15:17.000Z',
      category_id: 'relationships',
      question_id: 'languages',
      option_id: null,
      suboption_id: null,
      value: 'Macedo-Romanian',
    },
    {
      id: 1198,
      user_id: 1019,
      created_at: '2023-07-08T08:15:17.000Z',
      updated_at: '2023-07-08T08:15:17.000Z',
      category_id: 'relationships',
      question_id: 'languages',
      option_id: null,
      suboption_id: null,
      value: 'Ancient Manipuri',
    },
  ],
  network: [
    {
      id: 2368,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '25+ college graduates',
      suboption_id: null,
      value: 'no',
    },
    {
      id: 2369,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ adults in 10+ cities',
      suboption_id: null,
      value: 'no',
    },
    {
      id: 2370,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '10+ business leaders',
      suboption_id: null,
      value: 'no',
    },
    {
      id: 2371,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ physicians',
      suboption_id: null,
      value: 'no',
    },
    {
      id: 2372,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '10+ engineers',
      suboption_id: null,
      value: 'no',
    },
    {
      id: 2373,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ PhDs',
      suboption_id: null,
      value: 'no',
    },
    {
      id: 2374,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ professional creators',
      suboption_id: null,
      value: 'yes',
    },
    {
      id: 2375,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '25+ adults outside USA',
      suboption_id: null,
      value: 'yes',
    },
    {
      id: 2376,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ professional entertainers',
      suboption_id: null,
      value: 'yes',
    },
    {
      id: 2377,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ governement officials',
      suboption_id: null,
      value: 'yes',
    },
    {
      id: 2378,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '5+ business owners',
      suboption_id: null,
      value: 'yes',
    },
    {
      id: 2379,
      user_id: 1019,
      created_at: '2023-07-08T22:54:21.000Z',
      updated_at: '2023-07-08T22:54:21.000Z',
      category_id: 'network',
      question_id: 'network',
      option_id: '25+ active military',
      suboption_id: null,
      value: 'no',
    },
  ],
  finances_profile: [
    {
      id: 2409,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_profile',
      option_id: 'Multiple income streams',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2410,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_profile',
      option_id: 'Real estate investor',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2411,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_profile',
      option_id: 'Homeowner',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2412,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_profile',
      option_id: 'Family/Small Business Owner',
      suboption_id: null,
      value: 'selected',
    },
  ],
  finances_xp: [
    {
      id: 178,
      user_id: 1019,
      created_at: '2023-07-06T00:06:22.000Z',
      updated_at: '2023-07-06T00:06:22.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'invested-money',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 179,
      user_id: 1019,
      created_at: '2023-07-06T00:06:22.000Z',
      updated_at: '2023-07-06T00:06:22.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'secured-a-scholarship',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2403,
      user_id: 1019,
      created_at: '2023-07-08T23:00:29.000Z',
      updated_at: '2023-07-08T23:00:29.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'invested-money',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2404,
      user_id: 1019,
      created_at: '2023-07-08T23:00:29.000Z',
      updated_at: '2023-07-08T23:00:29.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'secured-a-scholarship',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2405,
      user_id: 1019,
      created_at: '2023-07-08T23:00:29.000Z',
      updated_at: '2023-07-08T23:00:29.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'rented-an-apartment',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2406,
      user_id: 1019,
      created_at: '2023-07-08T23:01:17.000Z',
      updated_at: '2023-07-08T23:01:17.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'invested-money',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2407,
      user_id: 1019,
      created_at: '2023-07-08T23:01:17.000Z',
      updated_at: '2023-07-08T23:01:17.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'secured-a-scholarship',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2408,
      user_id: 1019,
      created_at: '2023-07-08T23:01:17.000Z',
      updated_at: '2023-07-08T23:01:17.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'rented-an-apartment',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2413,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'invested-money',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2414,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'secured-a-scholarship',
      suboption_id: null,
      value: 'selected',
    },
    {
      id: 2415,
      user_id: 1019,
      created_at: '2023-07-08T23:02:18.000Z',
      updated_at: '2023-07-08T23:02:18.000Z',
      category_id: 'finances',
      question_id: 'finances_xp',
      option_id: 'rented-an-apartment',
      suboption_id: null,
      value: 'selected',
    },
  ],
  finances_credentials: [
    {
      id: 2416,
      user_id: 1019,
      created_at: '2023-07-08T23:02:19.000Z',
      updated_at: '2023-07-08T23:02:19.000Z',
      category_id: 'finances',
      question_id: 'finances_credentials',
      option_id: 'Retirement income certified professional (RICP)',
      suboption_id: null,
      value: 'selected',
    },
  ],
};
