import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRouterLink,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import appStrings from '../../common/app_strings';
import './PageWithBackButton.css';

const {
  onboarding: {
    login,
  },
} = appStrings;

const PageWithBackButton = ({ children, backButtonText, showLogInButton }) => {
  const location = useLocation();
  const [showBackButton, setShowBackButton] = useState(true);

  useEffect(() => {
    if (location.pathname.includes('login')) {
      setShowBackButton(false);
    }
  }, [location]);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar className="back-button-toolbar" mode="ios">
          <IonButtons slot="start">
            {showBackButton && (
              <IonBackButton
                text={backButtonText}
                icon="/assets/back-arrow.svg"
                style={{
                  '--icon-font-size': '1.25rem',
                  '--icon-padding-start': '8px',
                  '--icon-padding-end': '12px',
                  '--icon-padding-top': '4px',
                  '--icon-margin-end': '6px',
                }}
                className="back-button-custom"
                mode="ios"
                defaultHref="/"
              />
            )}
          </IonButtons>
          {showLogInButton && (
          <IonButtons slot="end">
            <IonRouterLink routerLink="/register/login">
              <IonButton mode="ios" style={{ width: 'auto' }}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <IonIcon
                    style={{ fontSize: '18px' }}
                    slot="icon-only"
                    src="/assets/login-lock.svg"
                    mode="ios"
                  />
                  <Typography
                    sx={{ color: '#063D8F', fontSize: 15, fontWeight: 700 }}
                  >
                    {login}
                  </Typography>
                </Stack>
              </IonButton>
            </IonRouterLink>
          </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      {children}
    </IonPage>
  );
};

PageWithBackButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  backButtonText: PropTypes.string.isRequired,
  showLogInButton: PropTypes.bool,
};

PageWithBackButton.defaultProps = {
  showLogInButton: false,
};

export default PageWithBackButton;
