/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
import React from 'react';
import { Stack } from '@mui/material';
import HorizontalScrollBar from 'pages/wholeme/molecules/horizontalScrollBar/HorizontalScrollBar';

const WholeMeProfileExperienceRibbon = ({ experiences, onPost }) => {
  const goToExpPage = (label) => {
    if (onPost) {
      onPost(label);
    }
  }; // should include route to experience page if ready for routing
  return (
    <Stack
      direction="row"
      sx={{
        whiteSpace: 'nowrap',
        'overflow-x': 'auto',
        'overflow-y': 'hidden',
      }}
    >
      <HorizontalScrollBar
        onPost={onPost}
        itemMeta={{
          experiences,
          onClickCallBack: goToExpPage,
        }}
      />
    </Stack>
  );
};

export default WholeMeProfileExperienceRibbon;
