import { useEffect } from 'react';
import useTwilio from '../../../hooks/useTwilio';

const TwilioOnMessageAddedWatcher = ({
  callback = async () => {},
  onMessageAdded = async () => {},
  tag = null,
}) => {
  useEffect(() => {
    callback();
  }, []);
  useTwilio({
    onMessageAdded,
    tag,
  });
  return null;
};

export default TwilioOnMessageAddedWatcher;
