const allTeamsFilter = {
  type: 'team',
  value: 'all',
  label: 'All Teams',
  icon: '/assets/all-posts-white.svg',
  avatarStyles: {
    backgroundColor: '#03295F',
  },
};

const relationshipToTeamFilter = (relationship) => {
  const {
    related_profile: {
      id,
      profile_image: icon,
      first_name: firstName,
      last_name: lastName,
    },
    relationship: relation,
  } = relationship;
  const label = `${firstName} ${lastName?.charAt(0)}.`;

  return {
    type: 'team',
    icon,
    value: id,
    label,
    relation,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const getTeamFilters = (
  relationships,
) => {
  if (relationships.length <= 1) return null;

  const teamFilters = relationships.map((r) => relationshipToTeamFilter(r));
  return [allTeamsFilter, ...teamFilters];
};
