const containerStyle = {
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '8px',
};

const textStyle = {
  fontSize: '14px',
  lineHeight: '15px',
};

const imageStyle = {
  width: '0.938rem',
  height: '0.938rem',
  maxWidth: '1rem',
  maxHeight: '1rem',
  minWidth: '0.938rem',
  minHeight: '0.938rem',
};

export const ProfileSponsorshipRenderStyles = {
  containerStyle,
  textStyle,
  imageStyle,
};
