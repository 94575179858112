import React, { useState, createRef, useEffect } from 'react';
import { Avatar, Stack } from '@mui/material';
import { usePhotoGallery } from 'hooks/usePhotoGallery';
import Cropper from 'react-cropper';
import BackrsButton from '../../atoms/backrsButton/BackrsButton';
import 'cropperjs/dist/cropper.css';
import './Cropper.css';

const avatarPlaceHolder = 'assets/profile/wholeMe/blue-avatar-placeholder.svg';
const avatarStyle = {
  color: 'darkgray',
  fontSize: 50,
  width: '236px',
  height: '236px',
};

const buttonMeta = {
  confirmImageSelection: {
    label: 'Save Profile Photo', // app strings
  },
  tryAnotherImage: {
    label: 'Try another photo', // app strings
  },
};

const PhotoSelector = ({
  previewMeta, setBlob, setIsCropping, isWorking,
}) => {
  const [localBlob, setLocalBlob] = useState(null);
  const [showAvatar, setShowAvatar] = useState(true);
  const [working, setWorking] = useState(isWorking);

  const [image, setImage] = useState(null);
  const cropperRef = createRef();

  const cropperStyles = {
    maxHeight: '276px',
    maxWidth: '276px',
    minHeight: avatarStyle.height,
    minWidth: avatarStyle.width,
  };

  useEffect(() => {
    setWorking(isWorking);
  }, [isWorking]);

  const getCropData = () => {
    setWorking(true);

    if (typeof cropperRef.current?.cropper !== 'undefined') {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
        setShowAvatar(true);
        setLocalBlob(blob);
        setBlob(blob);

        setWorking(false);
      });
    }
  };

  const { takePhoto } = usePhotoGallery();

  const handleClick = async () => {
    setWorking(true);

    try {
      const { webviewPath } = await takePhoto(
        {
          setMaxImageAxisPixelValue: true,
        },
      );

      setIsCropping(true);
      setShowAvatar(false);
      setImage(webviewPath);
    } catch (e) {
      console.error(e);
    } finally {
      setWorking(false);
    }
  };

  if (working) {
    return (
      <Stack alignContent="center" alignItems="center">
        <div>Please wait...</div>
      </Stack>
    );
  }

  return (
    <Stack>
      {showAvatar && (
        <Stack alignContent="center" alignItems="center">
          <Avatar
            onClick={handleClick}
            src={localBlob ? URL.createObjectURL(localBlob) : previewMeta?.uri || avatarPlaceHolder}
            alt="head icon"
            style={avatarStyle}
          />
        </Stack>
      )}
      {!showAvatar && (
        <Stack>
          <Stack style={{ display: 'flex', alignItems: 'center' }}>
            <Cropper
              ref={cropperRef}
              initialAspectRatio={1}
              aspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              guides
              style={cropperStyles}
            />
          </Stack>
          <Stack>
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span style={{ width: '220px' }}>
                  <BackrsButton
                    label={buttonMeta.confirmImageSelection?.label}
                    onClick={getCropData}
                  />
                </span>
              </div>
              <BackrsButton
                variant="text"
                label={buttonMeta.tryAnotherImage?.label}
                onClick={handleClick}
              />
            </div>
          </Stack>
          <br style={{ clear: 'both' }} />
        </Stack>
      )}
    </Stack>
  );
};

export default PhotoSelector;
