import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'providers/auth-provider';
import ProtegeBottom from 'common/atoms/protegeCard/ProtegeBottom';
import GeneralTextCardAtom from 'common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import InfoCardAtom from 'common/atoms/card/InfoCardAtom';
import CardAtom from 'common/atoms/card/CardAtom';

const BottomCardSection = ({ meta, spacing }) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const goToAccountCreation = () => {
    history.push('/register/register/backr');
  };
  return (
    meta && (
      <Box width={1} mt={meta?.margin_top || spacing}>
        {meta?.purpose_text && (
          <GeneralTextCardAtom
            title={meta?.purpose_text?.title}
            body={meta?.purpose_text?.body}
            titleStyles={meta?.purpose_text?.title_styles}
            bodyStyles={meta?.purpose_text?.body_styles}
            showQuoteIcon
          />
        )}
        {meta?.purpose_video && (
          <ProtegeBottom
            title={meta?.purpose_video?.title}
            videoUrl={meta?.purpose_video?.video_url}
            videoPreviewUrl={meta?.purpose_video?.video_preview_url}
          />
        )}
        {meta?.backing_card && (
          <CardAtom
            header={meta?.backing_card?.title}
            body={meta?.backing_card?.body}
            img={meta?.backing_card?.icon}
            callback={!user ? goToAccountCreation : null}
          />
        )}
        {meta?.backr_bio && (
          <CardAtom
            body={meta?.backr_bio?.body}
            img={meta?.backr_bio?.icon}
            imgStyles={meta?.backr_bio?.icon_styles}
            containerStyles={{ minHeight: 130, display: 'flex' }}
            backgroundImage={meta?.backr_bio?.background_image}
          />
        )}
        {meta?.backr_motivation && (
          <CardAtom
            header="Motivation"
            body={meta?.backr_motivation?.body}
            containerStyles={{ bgcolor: '#fff', color: '#2d2d2d' }}
            headerStyles={{ fontSize: 18 }}
            bodyStyles={{
              fontSize: 18,
              fontWeight: 700,
              opacity: 0.6,
              mt: 1,
            }}
          />
        )}
        {meta?.infocard_2 && (
          <InfoCardAtom
            body={meta?.infocard_2.body}
            imageSrc={meta?.infocard_2.img}
            altImgText={meta?.infocard_2.altImg}
          />
        )}
        {meta?.intermission_graphic && (
          <img
            style={{
              width: '100%',
              ...meta?.intermission_graphic?.image_styles,
            }}
            src={meta?.intermission_graphic?.image_url}
            alt={meta?.intermission_graphic?.alt_text}
          />
        )}
      </Box>
    )
  );
};

BottomCardSection.propTypes = {
  meta: PropTypes.shape({
    margin_top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    purpose_video: PropTypes.shape({
      title: PropTypes.string,
      video_url: PropTypes.string,
      video_preview_url: PropTypes.string,
    }),
    purpose_text: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      title_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      body_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
    backing_card: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      icon: PropTypes.string,
    }),
    backr_bio: PropTypes.shape({
      body: PropTypes.string,
      icon: PropTypes.string,
      icon_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      background_image: PropTypes.string,
    }),
    backr_motivation: PropTypes.shape({
      body: PropTypes.string,
    }),
    infocard_2: PropTypes.shape({
      body: PropTypes.string,
      img: PropTypes.string,
      altImg: PropTypes.string,
    }),
    intermission_graphic: PropTypes.shape({
      image_url: PropTypes.string,
      alt_text: PropTypes.string,
      image_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
  }),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

BottomCardSection.defaultProps = {
  meta: null,
};

export default BottomCardSection;
