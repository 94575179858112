import React from 'react';
import ResourceLookupPage from 'pages/wholeme/molecules/resourceLookupPage/ResourceLookupPage';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';

const ProfileCareerIntakeEmployersPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <ResourceLookupPage
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    wholeMeClient={wholeMeClient}
    categoryId="career"
    questionId="employers"
    title="Businesses & Employers"
    subtitle="Where have you worked? Add as many institutions that you have experience at."
    dataFetcher={async () => (await wholeMeClient.fetchEmployersState())?.employers}
    dataSaver={wholeMeClient.saveEmployersState}
    resourceType="employers"
    resultAttributeId="employer"
    backgroundOverlay="/assets/profile/education/occupation-overlay.svg"
  >
    <MainImage
      imageSrc="./assets/profile/career/employers.svg"
      imageAlt="office building"
    />
  </ResourceLookupPage>
);

export default ProfileCareerIntakeEmployersPage;
