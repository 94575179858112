/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  IonButton,
  IonSpinner,
  IonBackButton,
} from '@ionic/react';
import {
  Menu, MenuItem, Stack, MenuList, Avatar, Box,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { useFetch } from 'use-http';
import { useHistory, useLocation } from 'react-router-dom';
import './Header.css';
import PropTypes from 'prop-types';
import ResourceLibrary from './ResourceLibrary';
import { useAuthContext } from '../providers/auth-provider';
import { useAppStateDispatch, actions, useAppState } from '../providers/app-state/app-state-provider';
import appStrings from '../common/app_strings';
import shareBackrsInvite from '../common/shareBackrsUtil';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: 8,
    width: 'fit-content',
    color: '#03295F',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiList-root:focus-visible': {
      outline: 'none',
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontWeight: 500,
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const IonIconBase = React.forwardRef(({ className, slot, icon }, ref) => (
  <IonIcon className={className} slot={slot} icon={icon} ref={ref} />
));

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const Header = ({ logoOverride }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [bgLoading, setBgLoading] = useState(false);
  const {
    user, logout, reported,
  } = useAuthContext();
  const { conversations: { unreadMessagesCount }, platform } = useAppState();
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { hamburgerMenu: menuStrings } = appStrings;
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleShareInvite = async () => {
    handleMenuClose();
    await shareBackrsInvite();
  };

  const hideConversationsIcon = false;
  const showIndicatorCount = true;
  const appStateDispatch = useAppStateDispatch();
  const history = useHistory();
  const location = useLocation();
  const { post: postMatchRun, response: matchRunResponse } = useFetch('/matching/match-run');
  const { get: getBackgroundCheck, response: getBackgroundResponse } = useFetch('users/current-user/background-check');
  const { post: postCommunitySid, response: communitySidRes } = useFetch(`/conversations/request-sid/${user?.id}/community`);

  const shouldShowBackButton = location.pathname === '/tabs/profile' || location.pathname.startsWith('/profile');
  const shouldShowBgCheck = user?.role === 'backr' && !user.is_verified;

  const isOnFAQ = location.pathname === '/faq';

  const backrsLogoSrc = platform?.isNativePlatform ? '/assets/logo_v2.svg' : '/assets/backrs-beta.png';

  const BackrsLogo = (
    platform?.isNativePlatform ? (
      <object
        className="no-pointer-events"
        type="image/svg+xml"
        data={backrsLogoSrc}
        style={{
          height: '40px',
        }}
      >
        <img src={backrsLogoSrc} alt="Backrs Logo" />
      </object>
    ) : (
      <img src={backrsLogoSrc} alt="Backrs Logo" style={{ height: '40px' }} />
    )
  );
  const ReferAFriend = (
    platform?.isNativePlatform ? (
      menuStrings.sendInvite
    ) : (
      <a style={{ textDecoration: 'none', color: '#03295F' }} href={menuStrings.sendInviteWeb}>
        {menuStrings.sendInvite}
      </a>
    )
  );

  const handleAdminSupport = async () => {
    try {
      const res = await postCommunitySid();
      if (communitySidRes.ok && res?.sid) {
        history.push(`/chat/${res.sid}`);
      } else {
        throw new Error('Community conversation request fail');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
      toast.error('Direct Messaging Unavailable. Try again later.');
    }
  };

  const handleJoinATeamOnClick = async () => {
    try {
      const matchRun = await postMatchRun();
      if (!matchRunResponse.ok) {
        throw new Error(matchRunResponse.data);
      }
      appStateDispatch(actions.setMatchRun(matchRun));

      if (matchRun?.id) {
        history.push('/match-preview-screen');
      } else {
        history.push('/wholeme/pre-matching');
      }
    } catch (e) {
      console.error(e);
      handleMenuClose();
      toast.error(`Unable to Join a Team: ${e.message}`, {
        id: 'join_a_team_error',
      });
    }
  };

  const handleGetVerifiedOnClick = async () => {
    let route;
    const loadingDelay = setTimeout(() => {
      setBgLoading(true);
    }, 100);

    try {
      await getBackgroundCheck();
      if (!getBackgroundResponse.ok) {
        throw new Error(getBackgroundResponse.data);
      }

      if (getBackgroundResponse.ok) {
        if (getBackgroundResponse?.data === 'No background check found') {
          route = '/tabs/backr/background-check-v2';
        }
        if (getBackgroundResponse?.data?.id) {
          route = '/background-status-meter';
        }
      }
    } catch (e) {
      console.error(e);
      handleMenuClose();
      toast.error('Unable to Start Verification Process', {
        id: 'get_verified_error',
      });
    } finally {
      clearTimeout(loadingDelay);
      setBgLoading(false);
      history.push(route);
    }
  };

  const outlineBlueStyle = '2px solid #007AFF';

  return (
    <IonHeader className="ion-no-border">
      <IonToolbar mode="md">
        {logoOverride && logoOverride}
        {!logoOverride && !shouldShowBackButton && (
          <IonTitle>
            <IonRouterLink routerLink="/tabs/feed">
              {BackrsLogo}
            </IonRouterLink>
          </IonTitle>
        )}
        {!logoOverride && shouldShowBackButton && (
          <IonButtons slot="start" style={{ paddingLeft: '20px' }}>
            <IonBackButton
              text=""
              icon="/assets/back-arrow-blue.svg"
              style={{
                '--icon-font-size': '1.25rem',
                '--icon-padding-start': '8px',
                '--icon-padding-end': '12px',
                '--icon-padding-top': '4px',
                '--icon-margin-end': '6px',
                '--background-hover-opacity': '0',
              }}
              mode="ios"
              defaultHref="/"
            />
          </IonButtons>
        )}
        <IonButtons slot="end">
          <IonRouterLink routerLink="/faq">
            <IonButton
              style={{
                '--padding-start': '0',
                '--padding-end': '0',
                fontSize: '1rem',
              }}
            >
              <IonIcon
                slot="icon-only"
                icon={isOnFAQ ? 'assets/icon/blue-question-circle-filled.svg' : 'assets/icon/blue-question-circle.svg'}
              />
            </IonButton>
          </IonRouterLink>
          {!hideConversationsIcon && (
            <IonRouterLink routerLink="/conversations">
              <IonButton>
                <IonIcon slot="icon-only" icon="assets/icon/DMbubble.svg" />
                {!!unreadMessagesCount && (
                  <div className="notification-dot">
                    {showIndicatorCount ? unreadMessagesCount : ''}
                  </div>
                )}
              </IonButton>
            </IonRouterLink>
          )}
          <StyledMenu
            aria-label="Header Menu"
            open={open}
            onClose={handleMenuClose}
            anchorEl={anchorEl}
            anchorReference="anchorEl"
          >
            <MenuList dense>
              {user?.role !== 'admin' && (
              <IonRouterLink routerLink="/tabs/profile">
                <MenuItem key="my-profile" disableRipple>
                  {menuStrings.myProfile}
                </MenuItem>
              </IonRouterLink>
              )}
              {!reported && user?.role === 'backr' && (
              <IonRouterLink onClick={handleJoinATeamOnClick}>
                <MenuItem key="join-a-team" disableRipple>
                  {menuStrings.joinATeam}
                </MenuItem>
              </IonRouterLink>

              )}
              {shouldShowBgCheck && (
                <MenuItem key="get-verified" disableRipple>
                  {bgLoading ? <Stack width="100%" sx={{ alignItems: 'center' }}><IonSpinner name="crescent" /></Stack>
                    : (
                      <IonRouterLink onClick={handleGetVerifiedOnClick}>
                        {menuStrings.getVerified}
                      </IonRouterLink>
                    )}
                </MenuItem>
              )}
              {user?.role === 'backr' && (
              <IonRouterLink routerLink="/tabs/backr/general-invest-landing">
                <MenuItem key="financially-back" disableRipple>
                  {menuStrings.financiallyBack}
                </MenuItem>
              </IonRouterLink>

              )}
              <MenuItem
                disableRipple
                onClick={platform?.isNativePlatform ? handleShareInvite : handleMenuClose}
              >
                {ReferAFriend}
              </MenuItem>
              {user?.role !== 'admin' && (
                <MenuItem key="contact-us" disableRipple onClick={handleAdminSupport}>
                  {menuStrings.contactSupport}
                </MenuItem>
              )}
              <ResourceLibrary isMenuItem />
              <MenuItem
                disableRipple
                onClick={() => logout()}
              >
                {menuStrings.logOut}
              </MenuItem>
            </MenuList>
          </StyledMenu>
          <Box
            onClick={handleMenuOpen}
            sx={{
              ml: '10px', mr: '20px', cursor: 'pointer', pb: '5px',
            }}
          >
            <Avatar
              src={user?.profile_image}
              sx={{
                fontFamily: "'Sarabun', arial, sans-serif",
                width: 35,
                height: 35,
                ...(open ? { outline: outlineBlueStyle } : null),
                '&:hover': { outline: outlineBlueStyle },
              }}
            >
              <img style={{ width: 35, height: 35 }} src={defaultProfileImage} alt="profile" />
            </Avatar>
          </Box>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

Header.propTypes = {
  logoOverride: PropTypes.element,
};

Header.defaultProps = {
  logoOverride: undefined,
};

IonIconBase.propTypes = {
  className: PropTypes.string,
  slot: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

IonIconBase.defaultProps = {
  className: null,
  slot: null,
};

export default Header;
