import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from '../../../../components/VideoPlayer';

const FirstTimeUserMediaPlayer = ({ media }) => {
  const [error, setError] = useState(false);
  const setVideoError = () => {
    setError(true);
  };

  if (error && media?.video_preview_url) {
    return media?.video_preview_url.includes('svg') ? (
      <object style={{ width: '100%' }} type="image/svg+xml" data={media?.video_preview_url}>
        <img src={media?.video_preview_url} alt="" />
      </object>
    ) : (
      <img style={{ width: '100%' }} src={media?.video_preview_url} alt="" />
    );
  }

  if (media?.image_url) {
    return media?.image_url.includes('svg') ? (
      <object style={{ width: '100%' }} type="image/svg+xml" data={media?.image_url}>
        <img src={media?.image_url} alt="" />
      </object>
    ) : (
      <img style={{ width: '100%' }} src={media?.image_url} alt="" />
    );
  }

  return (
    <VideoPlayer
      isAnimation
      videos={[{ uri: media?.video_url, thumbnail: media?.video_preview_url }]}
      errorCallback={setVideoError}
    />
  );
};

FirstTimeUserMediaPlayer.propTypes = {
  media: PropTypes.shape({
    video_url: PropTypes.string,
    video_preview_url: PropTypes.string,
    image_url: PropTypes.string,
  }).isRequired,
};

export default FirstTimeUserMediaPlayer;
