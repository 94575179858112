import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';

const splashImage = {
  src: './assets/profile/finances/finances-splash.svg',
  alt: 'money being dispensed',
  styles: {
    width: '60%',
  },
};

const profileFinancesIntakeSplashPage = ({ nextOnClick, prevOnClick }) => (
  <ProfileIntakeSplashPage
    title="Finances"
    subtitle="Share a little bit about your financial history to help users who are building their own portfolios."
    splashImage={splashImage}
    nextButton={{ label: "Let's Go", onClick: nextOnClick }}
    prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    backgroundOverlay="/assets/profile/finances/finances-splash-overlay.svg"
  />
);

export default profileFinancesIntakeSplashPage;
