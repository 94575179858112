import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner = ({ sx, size, thickness = 4 }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 7,
    }}
  >
    <CircularProgress size={size} thickness={Number(thickness)} sx={sx} />
  </Box>
);

export default LoadingSpinner;
