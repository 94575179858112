/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import IonicProviderPageShell from 'common/molecules/ionicProviderPageShell/IonicProviderPageShell';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { sessionSelector } from '../../../redux/slices/session';
import ProfileIntakeWizard from '../pages/wizard/ProfileIntakeWizard';
import { WholeMeClient } from '../util/wholeme_client';
import { useAuthContext } from '../../../providers/auth-provider';
import { useAppStateDispatch, actions } from '../../../providers/app-state/app-state-provider';
import { ValidationError } from '../../../helpers/errors';
import { computeMatchingWizardSteps } from '../util/compute_container';
import AgeRestrictionModal from '../../../components/AgeRestrictionModal';

const MatchingWizardRoute = () => {
  const history = useHistory();
  const { refreshUser } = useAuthContext();
  const { hasSeenReadyToBack } = useSelector(sessionSelector);
  const appStateDispatch = useAppStateDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subFormData, setSubFormData] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [isAgeRestricted, setIsAgeRestricted] = useState(false);
  const { get: getInitialData, response: dataResponse } = useFetch('/matching/quiz-data');
  const { post, response } = useFetch('/matching/request-match-run');
  const { request } = useFetch();
  const [steps, setSteps] = useState([]);
  const renderReadyToBackRef = useRef(false);

  const {
    handleSubmit,
    reset: resetForm,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const handleInitialDataLoad = async () => {
    try {
      const data = await getInitialData();
      if (!dataResponse.ok) {
        throw new Error(dataResponse.data);
      }

      const { identity = {}, preferences = {}, ...rest } = data;

      if (preferences) {
        if (preferences.age_group === 'None') {
          preferences.age_group = 'No Preference';
        }
      }

      const {
        age_group = 'No Preference',
        ...identityMatchPrefs
      } = preferences || {};

      const initialSubformData = {
        ...rest,
        identity,
        preferences: {
          ...identityMatchPrefs, match_code: '', age_group: [age_group],
        },
      };

      setSubFormData(initialSubformData);
      setInitialData(initialSubformData);
      resetForm({ ...rest, preferences: { age_group } });
      // eslint-disable-next-line consistent-return
      return data;
    } catch (e) {
      console.error(e);
      if (dataResponse.status === 403) {
        toast.error(`Unable to Join a Team: ${e.message}`, { id: 'forbidden' });
        history.push('/tabs/feed');
      }
    }

    // eslint-disable-next-line consistent-return
    return {};
  };

  const handlePreferencesSubFormSubmit = (key, data) => {
    setSubFormData((prevState) => ({
      ...prevState,
      preferences: {
        ...prevState.preferences, [key]: data,
      },
    }));
  };

  const onSubmitFinal = async (data) => {
    if (isSubmitting) {
      return;
    }
    clearErrors();
    try {
      const freshData = await getInitialData();
      const defaultIdentityPrefs = {
        Disability: !!freshData?.identity.disability,
        Immigrant: !!freshData?.identity.immigrant,
        LGBTQ: !!freshData?.identity.lgbtq,
        Rural: !!freshData?.identity.rural,
        Military: !!freshData?.identity.military,
        first_gen_college: !!freshData?.identity.first_gen_college,
        gender: !!freshData?.identity.gender,
        ethnicity: !!freshData?.identity.ethnicity,
        College_Athletics: !!freshData?.identity.college_athletics,
        Adopted_Foster: !!freshData?.identity.adopted_foster,
      };

      setIsSubmitting(true);
      const location = await request.post('/users/zip', { zip: freshData.zip_code });

      const payload = {
        ...data,
        city: location.city,
        state: location.state,
        identity: freshData.identity,
        zip_code: freshData.zip_code,
        birthday: freshData.birthday,
        preferences: {
          ...defaultIdentityPrefs,
          age_group: subFormData.preferences.age_group[0],
          match_code: subFormData.preferences.match_code.toUpperCase(),
        },
      };
      const res = await post(payload);
      if (!response.ok) {
        if (response?.data === 'Invalid Cohort or Protege Code') {
          throw new Error('Invalid Cohort or Protege Code');
        } else if (response?.data?.msg === 'Match run already in progress') {
          throw new Error('Match run already in progress');
        } else if (response?.data?.msg === 'Requesting user is underage') {
          setIsAgeRestricted(true);
          return;
        } else if (response.status === 400 && !response?.data?.msg) {
          if (!location || location?.message === 'zip - Invalid zip code format.') {
            throw new ValidationError('Invalid inputs', [
              ...res.errors,
              { msg: 'Invalid zip code', param: 'zip_code' },
            ]);
          } else {
            throw new ValidationError('Invalid inputs', res.errors);
          }
        } else {
          throw new Error('Unknown Error');
        }
      }
      await refreshUser();
      appStateDispatch(actions.setMatchRun(res));
      history.push('/match-preview-screen');
    } catch (e) {
      // ?: Maybe should open modal w/ link to match preview instead of immediately redirecting
      if (e.message === 'Match run already in progress') {
        history.push('/match-preview-screen');
      } else if (e.message === 'Invalid Cohort or Protege Code') {
        setError('preferences.match_code', {
          type: 'server',
          message: 'Invalid Cohort or Protege Code',
        });
      } else if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      } else {
        toast.error(`There was an unexpected error:\n${String(e)}`, { id: 'mq-error' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitBack = () => {
    history.push('/');
  };

  const componentProps = {
    onSubFormSubmit: handlePreferencesSubFormSubmit,
    errors,
    preferences: subFormData?.preferences,
    clearErrors,
    exitIconOnClickOverride: () => history.push('/tabs/feed'),
  };

  useEffect(() => {
    handleInitialDataLoad();
    renderReadyToBackRef.current = hasSeenReadyToBack;
  }, []);

  useEffect(() => {
    const stepKeys = computeMatchingWizardSteps({
      matchData: initialData,
      hasSeenReadyToBack: renderReadyToBackRef.current,
      dataIsLoaded: !!initialData,
    });
    setSteps(stepKeys);
  }, [initialData]);

  return (
    <IonicProviderPageShell>
      {isAgeRestricted && <AgeRestrictionModal />}
      <ProfileIntakeWizard
        onSubmitBack={onSubmitBack}
        onSubmitFinal={handleSubmit(onSubmitFinal)}
        wholeMeClient={new WholeMeClient()}
        stepCategoryKeys={steps}
        {...componentProps}
      />
    </IonicProviderPageShell>
  );
};
export default MatchingWizardRoute;
