/* eslint-disable */
import { careerFieldData } from '../pages/career/career_fields/careerFieldData';
import { careerXPData } from '../pages/career/career_xp/careerXPData';
import { degreeData } from '../pages/education/degree/degreeData';
import { educationXPData } from '../pages/education/education_xp/educationXPData';
import { financesCredentialsData } from '../pages/finances/finances_credentials/financesCredentialsData';
import { financesXpData } from '../pages/finances/finances_xp/financesXpData';
import { financesProfileData } from '../pages/finances/profile/financesProfileData';
import { healthCredentialsData } from '../pages/health/health_credentials/healthCredentialsData';
import { healthInterestsData } from '../pages/health/health_interests/healthInterestsData';
import { healthIntakeXpData } from '../pages/health/health_xp/healthIntakeXpData';
import { affinityData } from '../pages/identity/affinity/affinityData';
import { ethnicityData } from '../pages/identity/ethnicity/ethnicityData';
import { genderData } from '../pages/identity/gender/genderData';
import { quizData } from '../pages/identity/quiz/quizData';
import { stageData } from '../pages/career/career_stage/stageData';
import { religionData } from '../pages/identity/religion/religionData';
import { hobbiesData } from '../pages/interests/hobbies/hobbiesData';
import { networkData } from '../pages/network/network/networkData';
import { familyData } from '../pages/relationships/family/familyData';
import { relationshipXpData } from '../pages/relationships/relationships_xp/relationshipXpData';

const processFieldData = (data, id, fieldMeta) => {
  fieldMeta[id] = {
    fields: data,
    fieldValueMap: data.reduce(
      (acc, next) => ({
        ...acc,
        [next.value]: {
          ...next,
          subFieldValueMap: next.subQuestions
            ? next.subQuestions.reduce(
                (acc2, next2) => ({
                  ...acc2,
                  [next2.value]: next2,
                }),
                {},
              )
            : {},
        },
      }),
      {},
    ),
  };
};

const fieldMeta = {};

processFieldData(careerFieldData, 'career-career_fields', fieldMeta);
processFieldData(careerXPData, 'career-career_xp', fieldMeta);
processFieldData(stageData, 'career-career_stage', fieldMeta);
processFieldData(degreeData, 'education-degree', fieldMeta);
processFieldData(educationXPData, 'education-education_xp', fieldMeta);
processFieldData(financesCredentialsData, 'finances-finances_credentials', fieldMeta);
processFieldData(financesXpData, 'finances-finances_xp', fieldMeta);
processFieldData(financesProfileData, 'finances-finances_profile', fieldMeta);
processFieldData(healthCredentialsData, 'health-health_credentials', fieldMeta);
processFieldData(healthInterestsData, 'health-health_interests', fieldMeta);
processFieldData(healthIntakeXpData, 'health-health_xp', fieldMeta);
processFieldData(affinityData, 'identity-affinity', fieldMeta);
processFieldData(ethnicityData, 'identity-ethnicity', fieldMeta);
processFieldData(genderData, 'identity-gender', fieldMeta);
processFieldData(quizData, 'identity-quiz', fieldMeta);
processFieldData(religionData, 'identity-religion', fieldMeta);
processFieldData(hobbiesData, 'interests-hobbies', fieldMeta);
processFieldData(networkData, 'network-network', fieldMeta);
processFieldData(familyData, 'relationships-family', fieldMeta);
processFieldData(relationshipXpData, 'relationships-relationships_xp', fieldMeta);

export const fieldsFor = (id) => {
  return fieldMeta[id]?.fields || [];
};

export const fieldsMapFor = (id) => {
  return fieldMeta[id]?.fieldValueMap || {};
};

export const fieldLabelFor = (fieldValue, categoryId, stepId, subStepId) => {
  const key = `${categoryId}-${stepId}`;
  const field = fieldsMapFor(key)[fieldValue];
  if (!subStepId) {
    return field?.label || fieldValue;
  }

  return field.subFieldValueMap[subStepId]?.label || fieldValue;
};

export const fieldInfoUrlFor = (fieldValue, categoryId, stepId, subStepId) => {
  const key = `${categoryId}-${stepId}`
  const field = fieldsMapFor(key)[fieldValue];
  if (!subStepId) {
    return field?.infoUrl || "";
  }

  return field.subFieldValueMap[subStepId]?.infoUrl || "";
}

export const fieldIconSrcFor = (fieldValue, categoryId, stepId, subStepId) => {
  const key = `${categoryId}-${stepId}`;
  const field = fieldsMapFor(key)[fieldValue];
  if (!subStepId) {
    return field?.iconSrc;
  }

  return field.subFieldValueMap[subStepId]?.iconSrc;
};
