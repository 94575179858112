// /* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import { useAuthContext } from 'providers/auth-provider';
import CategoryPortrait from './atoms/CategoryPortrait';
import {
  PortraitHeadingText,
  PortraitDisplayWrapper,
  LeftPortraitColumn,
  RightPortraitColumn,
  CompleteProfileButton,
  ViewProfileButton,
} from './atoms/CategoryPortraitComponents';
import categoryData from './profileIntakePortraitUtils';

const ProfileIntakePortrait = ({ wholeMeClient }) => {
  const history = useHistory();
  const { hasCompletedProfile } = useAuthContext();

  // add portrait usestate
  const [portrait, setPortrait] = useState();

  useEffect(async () => {
    const fetched = await wholeMeClient.fetchPortrait();
    setPortrait(fetched?.portrait);
    if (fetched?.portrait?.completed && !hasCompletedProfile) {
      history.push('/profile-completed');
    }
  }, []);

  if (!portrait) {
    return (
      <div style={{ paddingTop: '50%', paddingBottom: '50%' }}>
        <WholeMeLoadingCircle />
      </div>
    );
  }

  const { detail } = portrait;
  const goToCategory = (categoryId) => {
    history.push(`/wholeme/wizard/${categoryId}`);
  };

  const goToProfile = () => {
    history.push('/tabs/profile');
  };

  const firstIncompleteCategory = Object.keys(detail).find((key) => !detail[key]);

  const leftPortraits = Object.keys(categoryData)
    .filter((category) => categoryData[category]?.column === 'left')
    .map((category) => categoryData[category]);

  const rightPortraits = Object.keys(categoryData)
    .filter((category) => categoryData[category]?.column === 'right')
    .map((category) => categoryData[category]);

  const pageContentStyling = {
    padding: '20px',
    width: 'calc(100% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const showCompletedNavButton = !firstIncompleteCategory && hasCompletedProfile;

  return (
    <div style={pageContentStyling}>
      <PortraitHeadingText />
      <PortraitDisplayWrapper>
        <LeftPortraitColumn>
          {leftPortraits.map((portraitMeta) => (
            <CategoryPortrait
              key={portraitMeta.category}
              category={portraitMeta.label}
              imageSrc={portraitMeta.src}
              portraitStylesOverride={portraitMeta.portraitStylesOverride}
              hasCompleted={detail[portraitMeta.category]}
              handlePortraitClick={() => goToCategory(portraitMeta.category)}
            />
          ))}
        </LeftPortraitColumn>
        <RightPortraitColumn>
          {rightPortraits.map((portraitMeta) => (
            <CategoryPortrait
              key={portraitMeta.category}
              category={portraitMeta.label}
              imageSrc={portraitMeta.src}
              portraitStylesOverride={portraitMeta.portraitStylesOverride}
              hasCompleted={detail[portraitMeta.category]}
              handlePortraitClick={() => goToCategory(portraitMeta.category)}
            />
          ))}
        </RightPortraitColumn>
      </PortraitDisplayWrapper>
      {firstIncompleteCategory && (
        <CompleteProfileButton
          handleProfileNavigation={() => goToCategory(firstIncompleteCategory)}
        />
      )}
      {showCompletedNavButton && (
        <ViewProfileButton handleProfileNavigation={goToProfile} />
      )}
    </div>
  );
};

export default ProfileIntakePortrait;
