import React, { useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import {
  IconButton,
  Menu, MenuItem, styled,
} from '@mui/material';
import toast from 'react-hot-toast';
import { banOutline, ellipsisVertical } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { useFetch } from 'use-http';
import { useAuthContext } from 'providers/auth-provider';
import BlockModal from '../blockModal/BlockModal';

const FixedIcon = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 0,
  color: '#03295F',
  padding: '8px 0',
  marginRight: '-.4rem',
});

const ProfileOptionsMenu = ({ profile }) => {
  const { id } = useParams();
  const history = useHistory();
  const [userBlocked, setUserBlocked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [profileType, setProfileType] = useState('user');
  const menuOpen = Boolean(anchorEl);
  const {
    get: doGet,
  } = useFetch();
  const { first_name: firstName, last_name: lastName } = profile;
  const { logout, user } = useAuthContext();

  const checkIfUserBlocked = async () => doGet(`/users/current-user/blocked-users/${id}`);

  useEffect(() => {
    checkIfUserBlocked().then((res) => {
      setUserBlocked(!!res);
    });

    return () => {};
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBlockModalOpen = () => {
    setProfileType(profile?.role === 'admin' ? 'admin' : 'user');
    handleMenuClose();
    setBlockModalOpen(true);
  };

  const handleBlockModalClose = () => {
    setBlockModalOpen(false);
  };

  const goToReportPage = () => {
    history.push(`/users/${id}/report`, { reportedUser: profile });
  };

  const showPresent = (toastMessage) => toast(
    toastMessage,
    {
      duration: 2500,
    },
  );

  const generateMenuItem = (key, onClick, color, label) => ({
    key,
    onClick,
    sx: {
      color: `${color} !important`,
    },
    label,
  });

  const MenuItems = ({
    userProfile,
    userIsBlocked,
    handleBlockModalOpenFunc,
    goToReportPageFunc,
  }) => {
    let menuItems = [];

    const color = '#F66324';
    const adminMenuOptions = [
      generateMenuItem('BlockAdmin', handleBlockModalOpenFunc, color, 'Block Lubav'),
      generateMenuItem('ReportAdmin', goToReportPageFunc, color, 'Report Lubav'),
    ];

    if (userProfile?.role === 'admin') {
      menuItems = adminMenuOptions;
    } else if (!userIsBlocked) {
      menuItems = [
        generateMenuItem('BlockUser', handleBlockModalOpenFunc, color, 'Block'),
        generateMenuItem('ReportUser', goToReportPageFunc, color, 'Report User'),
      ];
    } else {
      menuItems = [{
        key: 'UnblockUser',
        onClick: handleBlockModalOpenFunc,
        label: (
          <>
            <IonIcon slot="icon-only" icon={banOutline} />
          &nbsp; Unblock
          </>
        ),
      }];
    }

    return (
      menuItems.map((item) => (
        <MenuItem
          key={item.key}
          onClick={item.onClick}
          sx={item.sx}
          disableRipple
        >
          {item.label}
        </MenuItem>
      ))
    );
  };

  return (
    <>
      <Menu
        aria-label="Profile Options Menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
      >
        <MenuItems
          userProfile={profile}
          userIsBlocked={userBlocked}
          handleBlockModalOpenFunc={handleBlockModalOpen}
          goToReportPageFunc={goToReportPage}
        />
      </Menu>
      <FixedIcon onClick={handleClick}>
        <IonIcon slot="icon-only" icon={ellipsisVertical} />
      </FixedIcon>
      <BlockModal
        isOpen={blockModalOpen}
        handleClose={handleBlockModalClose}
        id={id}
        user={user}
        logout={logout}
        profileType={profileType}
        setUserBlocked={setUserBlocked}
        showPresent={showPresent}
        firstName={firstName}
        lastName={lastName}
        userBlocked={userBlocked}
      />
    </>
  );
};

export default ProfileOptionsMenu;
