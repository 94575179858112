import { firstValueOf } from '../../util/profile_util';

export const affinitiesFromProfile = (profile) => {
  const {
    first_gen_college: firstGenCollege,
    immigrant,
    rural,
    military,
    disability,
    lgbtq,
    college_athletics: collegeAthletics,
    adopted_foster: adoptedFoster,
  } = profile;
  const affinities = [
    {
      label: 'First Gen College',
      value: firstValueOf(firstGenCollege),
      iconSrc: 'assets/icons/identity-affiliation/first-contribution.svg',
    },
    {
      label: 'Immigrant',
      value: firstValueOf(immigrant),
      iconSrc: 'assets/icons/identity-affiliation/plane-solid.svg',
    },
    {
      label: 'Military',
      value: firstValueOf(military),
      iconSrc: 'assets/icons/identity-affiliation/shield-rounded.svg',
    },
    {
      label: 'Disability',
      value: firstValueOf(disability),
      iconSrc: 'assets/icons/health/health.svg',
    },
    {
      label: 'LGBTQ+',
      value: firstValueOf(lgbtq),
      iconSrc: 'assets/icons/identity-affiliation/rainbow.svg',
    },
    {
      label: 'Rural',
      value: firstValueOf(rural),
      iconSrc: 'assets/icons/identity-affiliation/tree.svg',
    },
    {
      label: 'Adopted/Foster',
      value: firstValueOf(adoptedFoster),
      iconSrc: 'assets/icons/identity-affiliation/teddy-bear.svg',
    },
    {
      label: 'College Athletics',
      value: firstValueOf(collegeAthletics),
      iconSrc: 'assets/icons/identity-affiliation/shoe.svg',
    },
  ];

  return affinities.filter((n) => !!n.value) || [];
};

export const resolveQuizIcon = (optionId, subOptionId, value) => {
  if (value === 'Ask me for help!') {
    return 'assets/icon/light-blue-question-circle-filled.svg';
  }
  if (value === 'Backrs') {
    return 'assets/icons/education-career/graduation-cap-blue.svg';
  }
  switch (optionId) {
    case 'MBTI Myers-Briggs': {
      return 'assets/icons/identity-affiliation/head-thinking.svg';
    }
    case 'Astrology': {
      return `assets/icons/identity-affiliation/zodiac-${value?.toLowerCase()}.svg`;
    }
    case 'HIGH5': {
      return 'assets/icons/identity-affiliation/high5.svg';
    }
    case 'StrengthsFinder': {
      return 'assets/icons/identity-affiliation/strong-arm.svg';
    }
    default:
  }

  switch (subOptionId) {
    case 'Enneagram Primary': {
      return 'assets/icon/hexagon.svg';
    }
    case 'Enneagram Wing': {
      return 'assets/icon/wing.svg';
    }
    default:
  }

  return null;
};

export const mapQuizValues = (quiz) => quiz.reduce((acc, cur) => {
  if (cur.value !== 'selected') {
    acc[cur.option_id]?.push(cur.value);
  }
  return acc;
}, {
  Enneagram: [],
  HIGH5: [],
  'MBTI Myers-Briggs': [],
  StrengthsFinder: [],
  Astrology: [],
});

const PERMITTED_ENNEAGRAM = { id: 'Enneagram', sub_id: 'Enneagram Wing' };
const KEYS_TO_FILTER = ['Enneagram', 'HIGH5'];

const keyExists = (arr, val) => (arr.some((a) => a.option_id === val));

const allowedItem = (item) => {
  if (item.option_id !== PERMITTED_ENNEAGRAM.id) { return true; }
  if (item.suboption_id === PERMITTED_ENNEAGRAM.sub_id) { return true; }
  return false;
};

export const filteredQuizItems = (quiz) => quiz.reduce((acc, cur) => {
  if (cur.value !== 'selected') {
    if (cur.option_id && cur.suboption_id && cur.value) {
      if (!KEYS_TO_FILTER.includes(cur.option_id)) {
        acc.push(cur);
      } else if (allowedItem(cur) && !keyExists(acc, cur.option_id)) {
        acc.push(cur);
      }
    }
  }
  return acc;
}, []) || [];

export const resolveReligionValue = (religionArr) => {
  if (!religionArr?.length) {
    return [];
  }
  let selectedValue;
  religionArr.forEach((religion) => {
    const { option_id: optionId, value } = religion;
    if (value === 'selected' || value === '') {
      selectedValue = optionId;
    } else {
      selectedValue = value;
    }
  });
  return [selectedValue];
};
