import React from 'react';
import './Landing.css';
import {
  IonButton,
  IonContent,
  IonPage,
  IonRouterLink,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButtons,
} from '@ionic/react';
import { Box, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import appStrings from '../common/app_strings';
import Button from '../common/atoms/button/Button';

const {
  onboarding:
  {
    getStarted,
    whatIsBackrs,
    login,
  },
} = appStrings;

const NewUserLanding = () => {
  const history = useHistory();

  const goToAbout = () => {
    history.push('/about');
  };

  return (
    <IonPage className="loginPage">
      <IonHeader className="ion-no-border" mode="ios">
        <IonToolbar className="login-button-toolbar" mode="ios">
          <IonButtons slot="end">
            <IonRouterLink routerLink="/register/login">
              <IonButton mode="ios">
                <Stack
                  sx={{ marginTop: '10px' }}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <IonIcon
                    style={{ fontSize: '18px' }}
                    slot="icon-only"
                    src="/assets/login-lock.svg"
                    mode="ios"
                  />
                  <Typography
                    sx={{ color: '#063D8F', fontSize: 16, fontWeight: 700 }}
                  >
                    {login}
                  </Typography>
                </Stack>
              </IonButton>
            </IonRouterLink>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Stack
          spacing={4}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 'calc(100vh - 84px - var(--ion-safe-area-top))',
            mb: 5,
            '@media screen and (max-height: 736px)': {
              height: 'auto',
            },
          }}
          className="landing-wrapper"
        >
          <Box
            sx={{
              display: 'block',
              width: '60%',
              marginTop: '10px',
              maxWidth: 223,
              objectFit: 'contain',
              mx: 'auto',
              textAlign: 'center',
            }}
            component="img"
            alt="Backrs logo"
            src="/assets/logo.png"
          />
          <object
            style={{ width: '90vw', marginRight: '1px' }}
            type="image/svg+xml"
            data="/assets/register/connections_large_v2.svg"
          >
            <img
              src="/assets/register/connections_large_v2.svg"
              alt="people connected"
            />
          </object>
          <Stack spacing={1} mx={20} alignItems="center">
            <IonButton
              size="large"
              expand="block"
              shape="round"
              routerLink="/what-is-backrs"
              className="join-a-team-button"
              style={{
                fontSize: '22px',
                fontWeight: 700,
                width: '100%',
                'padding-top': 2,
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                '--padding-start': '3.5rem',
                '--padding-end': '3.5rem',
              }}
            >
              {getStarted}
            </IonButton>
            <Button
              label={whatIsBackrs}
              labelStyles={{
                fontSize: '20px',
                fontWeight: 600,
                pt: 2,
                color: '#063D8F',
              }}
              buttonStyles={{
                width: '200px',
                height: '50px',
              }}
              onClickCallBack={goToAbout}
            />
          </Stack>
        </Stack>
      </IonContent>
    </IonPage>
  );
};

export default NewUserLanding;
