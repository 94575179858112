// eslint-disable-next-line import/prefer-default-export
export const grades = [
  { name: '10', key: 1 },
  { name: '11', key: 2 },
  { name: '12', key: 3 },
  { name: 'Year 1 Post Secondary', key: 4 },
  { name: 'Year 2 Post Secondary', key: 5 },
  { name: 'Year 3 Post Secondary', key: 6 },
  { name: 'Year 4 Post Secondary', key: 7 },
];
