/* eslint-disable no-restricted-syntax */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as jose from 'jose';
import useFetch from 'use-http';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import appStrings from 'common/app_strings';
import { useAuthContext } from '../../providers/auth-provider';
import LoadingSpinner from '../../components/generic/LoadingSpinner';
import ChatHeader from './ChatHeader';
import RightAlignedMessage from './molecules/RightAlignedMessage';
import LeftAlignedMessage from './molecules/LeftAlignedMessage';
import ChatInput from './molecules/ChatInput';
import useTwilio from '../../hooks/useTwilio';
import './Chat.css';

const { chat: { beginning, convoReported, loadingError } } = appStrings;

const ChatBeginningText = styled(Typography)({
  color: '#063d8f',
  fontSize: 12,
  fontStyle: 'italic',
  textAlign: 'center',
  opacity: 0.7,
  marginTop: '8px',
});

const Chat = () => {
  const { user } = useAuthContext();
  const { control, handleSubmit, reset } = useForm();
  const { conversationId } = useParams();

  const { get: doGet, response } = useFetch();

  const contentRef = useRef(null);
  const stackRef = useRef(null);
  const chatInputRef = useRef(null);
  const footerRef = useRef(null);

  const [twilioJwt, setTwilioJwt] = useState(null);
  const [messages, setMessages] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [jwtPayload, setJwtPayload] = useState(null);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chatError, setChatError] = useState(null);
  const [otherParticipantData, setOtherParticipantData] = useState(null);
  const [isBeginning, setIsBeginning] = useState(false);
  const [conversationContext, setConversationContext] = useState(null);

  /**
   *  aliasAdminAsSystem is a flag to potentially support admin -> admin conversations
   *  there may be use case in future where admin's need to be able to chat as themselves,
   *  EG: not as 'Coach Lubav'
   */
  const aliasAdminAsSystem = true;

  const scrollToBottom = () => {
    contentRef?.current?.scrollToBottom(500);
  };

  const scrollDownFast = () => {
    contentRef?.current?.scrollToBottom(0);
  };

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardWillShow', () => {
      if (footerRef.current && stackRef.current && chatInputRef.current) {
        footerRef.current.style.display = 'none';
        stackRef.current.style.marginBottom = '90px';
        chatInputRef.current.style.bottom = 0;
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (footerRef.current && stackRef.current && chatInputRef.current) {
        footerRef.current.style.display = 'initial';
        stackRef.current.style.marginBottom = '120px';
        chatInputRef.current.style.bottom = '24px';
      }
    });
  }

  useEffect(() => {
    const initTwilioClient = async () => {
      try {
        const jwt = await doGet(`/users/v2/twilio-jwt/${conversationId}`);
        setTwilioJwt(jwt.twilio_jwt);
      } catch (e) {
        setChatError(e);
      }
    };
    initTwilioClient();
    return () => {
      setCurrentConversation(null);
    };
  }, []);

  useEffect(() => {
    if (!twilioJwt) {
      return;
    }

    const payload = jose.decodeJwt(twilioJwt);
    setJwtPayload(payload);
  }, [twilioJwt]);

  const sendMessage = useCallback(
    (data) => {
      if (currentConversation && data.body) {
        currentConversation.sendMessage(data.body, {
          author_backrs_id: user.id,
        });
        reset({ body: '' });
      }
    },
    [user, currentConversation, reset],
  );

  const handleMessageAdded = (message) => {
    const eventConversationId = message?.conversation?.sid;
    const isEventConversation = eventConversationId === conversationId;

    // if the new message is not part of this conversation, ignore it
    if (!isEventConversation) {
      return;
    }

    setMessages((prev) => [...prev, message]);
    const userIdentity = user.role === 'admin' ? 'super-admin' : user.id.toString();
    if (userIdentity === message.state.author) {
      scrollToBottom();
    }
  };

  const { client: twilioClient, handleRefreshTwilioClient } = useTwilio({
    usingPassedJwt: true,
    passedJwt: twilioJwt,
    onMessageAdded: handleMessageAdded,
    tag: 'chat',
  });

  useEffect(() => {
    if (!twilioClient) {
      return;
    }

    const getConversationContext = async () => {
      const conversationContextResponse = await doGet(`/conversations/context/${conversationId}`);
      if (response.ok) {
        const { conversation, otherParticipant } = conversationContextResponse;
        setConversationContext(conversation);
        setOtherParticipantData(otherParticipant);
      } else {
        throw new Error('Error fetching conversation context');
      }
    };

    const initConversationEvents = async () => {
      twilioClient.on('connectionStateChanged', async (state) => {
        if (state === 'connected') {
          try {
            setChatError(null);
            setIsLoading(true);
            const conversationClient = await twilioClient.getConversationBySid(conversationId);
            await getConversationContext();
            setCurrentConversation(conversationClient);
            const conversationMessages = await conversationClient.getMessages();
            setPaginator(conversationMessages);
            setMessages(conversationMessages.items);
            scrollDownFast();
          } catch (e) {
            setChatError(e);
          } finally {
            setIsLoading(false);
          }
        }
      });
    };

    initConversationEvents();
  }, [twilioClient]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      const setMessagesRead = async () => {
        const lastIndex = messages[messages.length - 1].index;
        await currentConversation.updateLastReadMessageIndex(lastIndex);
      };

      if (messages[0].state.index === 0) {
        setIsBeginning(true);
      }

      setMessagesRead();
    }
  }, [messages]);

  const fetchOlderMessages = useCallback(async () => {
    if (!currentConversation || !paginator?.hasPrevPage) {
      return;
    }

    const oldMessages = await paginator.prevPage();
    setPaginator(oldMessages);
    setMessages((prev) => oldMessages.items.concat(prev));
  });

  const onIonRefresh = async (event) => {
    await fetchOlderMessages();
    event.detail.complete();
  };

  useEffect(() => {
    if (twilioClient) {
      setIsLoading(true);
      handleRefreshTwilioClient();
      setIsLoading(false);
    }
  }, [conversationId]);

  const inputDisabled = !conversationContext || conversationContext?.isReported;

  const mainContent = () => {
    if (chatError) {
      return (
        <Typography
          sx={{
            color: '#2d2d2d',
            textAlign: 'center',
            mt: 3,
          }}
        >
          {loadingError}
        </Typography>
      );
    }
    if (conversationContext?.isReported) {
      return (
        <Typography
          sx={{
            color: '#2d2d2d',
            textAlign: 'center',
            mt: 3,
          }}
        >
          {convoReported}
        </Typography>
      );
    }
    return (
      <>
        {isBeginning && <ChatBeginningText>{beginning}</ChatBeginningText>}
        <Stack ref={stackRef} mx={3} mt={3} mb={15} spacing={2}>
          {isLoading && <LoadingSpinner />}
          {!isLoading
            && messages.map((message) => {
              if (jwtPayload.grants.identity !== message.state.author) {
                return (
                  <LeftAlignedMessage
                    key={message.state.sid}
                    aliasAdminAsSystem={aliasAdminAsSystem}
                    author={otherParticipantData}
                    message={message}
                  />
                );
              }

              return <RightAlignedMessage key={message.state.sid} message={message} />;
            })}
        </Stack>
      </>
    );
  };

  const renderContent = () => (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5 }}
      >
        {mainContent()}
      </motion.div>
      <ChatInput
        ref={chatInputRef}
        control={control}
        isLoading={isLoading}
        isDisabled={inputDisabled}
        onFocus={scrollToBottom}
        onSubmit={handleSubmit(sendMessage)}
        user={user}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        ref={footerRef}
        className="chat-input-bottom"
        slot="fixed"
      />
    </>
  );

  return (
    <IonPage>
      <ChatHeader conversationMeta={conversationContext} otherParticipant={otherParticipantData} />
      <IonContent ref={contentRef}>
        <IonRefresher slot="fixed" onIonRefresh={onIonRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {renderContent()}
      </IonContent>
    </IonPage>
  );
};

export default Chat;
