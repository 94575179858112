import { configureStore } from '@reduxjs/toolkit';
import feedReducer from './slices/feed';
import sessionReducer from './slices/session';

export const store = configureStore({
  reducer: {
    feed: feedReducer,
    session: sessionReducer,
  },
});
