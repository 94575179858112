/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuthContext } from '../providers/auth-provider';

const ProtectedRoute = ({ component, ...args }) => {
  const {
    isLoading,
    isAuthenticated,
    user,
    logout,
  } = useAuthContext();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    logout();
  }, [
    isLoading,
    isAuthenticated,
    user,
  ]);

  return (
    <Route
      {...args}
      component={component}
    />
  );
};

export default ProtectedRoute;
