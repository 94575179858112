import React from 'react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Theme variables */
import '../theme/variables.css';
import '../App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { IonApp } from '@ionic/react';

const IonAppShell = ({ children }) => (
  <IonApp>
    <CssBaseline enableColorScheme />
    {children}
  </IonApp>
);

export default IonAppShell;
