import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SelectableItem from '../../../../common/atoms/selectableItem/SelectableItem';

const images = [
  'assets/profile/network/orange_circle_white_x.svg',
  'assets/profile/network/orange_circle_white_x_white_outline.svg',
  'assets/profile/network/green_circle_white_checkmark.svg',
  'assets/profile/network/green_circle_white_checkmark_white_outline.svg',
];

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  backgroundColor: '#ffffff00',
  width: '100%',
  height: 'fit-content',
};

const basicYupNope = {
  variant: 'rectangle',
  rectangleSubVariant: 'longImageBelow',
  itemStylesOverride: {
    color: '#FFF',
    border: 'none',
    fontSize: '24px',
    paddingBottom: '20px',
    width: '158px',
    height: '158px',
  },
  selectedStylesOverride: {
    border: 'none',
  },
};

const YupNopeNavigation = ({ handleNext, selected }) => {
  const [isSelected, setisSelected] = useState(
    selected !== undefined ? (selected ? 'yup' : 'nope') : undefined,
  );

  const yupSelected = isSelected === 'yup';
  const nopeSelected = isSelected === 'nope';

  const nope = {
    ...basicYupNope,
    label: 'Nope',
    imageAlt: 'orange circle white x',
    value: 'nope',
    itemStylesOverride: {
      ...basicYupNope.itemStylesOverride,
      backgroundColor: '#FD5C36',
    },
    isSelected: nopeSelected,
    imageSrc: !nopeSelected ? images[0] : images[1],
    onClick: (value) => {
      setisSelected(value);
      handleNext(false);
    },
  };

  const yup = {
    ...basicYupNope,
    label: 'Yup!',
    isSelected: yupSelected,
    imageAlt: 'green circle white checkmark',
    value: 'yup',
    itemStylesOverride: {
      ...basicYupNope.itemStylesOverride,
      backgroundColor: '#2CB669',
    },
    imageSrc: !yupSelected ? images[2] : images[3],
    onClick: (value) => {
      setisSelected(value);
      handleNext(true);
    },
  };

  return (
    <div style={containerStyle}>
      <SelectableItem {...nope} />
      <SelectableItem {...yup} />
    </div>
  );
};

const YupNopePropTypes = {
  yupOrNope: PropTypes.PropTypes.oneOfType([PropTypes.object]).isRequired,
};

YupNopeNavigation.prototype = YupNopePropTypes;

export default YupNopeNavigation;
