/* eslint-disable */
import React from 'react';
import { WDYKIcon, ExperiencesIcon } from 'pages/wholeme/icons/Icons';
import WholeMeProfileExperienceRibbon from 'pages/wholeme/molecules/wholeMeProfileExperienceRibbon/WholeMeProfileExeprienceRibbon';
import { createEditHandler, firstValueOf } from '../../util/profile_util';
import { Stack } from '@mui/material';
import WholeMeProfileSectionMini from '../wholeMeProfileSection/WholeMeProfileSectionMini';
import useWindowDimensions from '../../../../helpers/hooks';

const stackStyle = {
  display: 'grid',
  gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)',
  gap: '20px',
  justifyContent: 'center',
};

const WholeMeProfileExperiences = ({ profile, onEdit, onPost }) => {
  const { education_xp = [], career_xp = [], finances_xp = [], relationships_xp = [], health_xp = [] } = profile;
  const allExperiences = [].concat(education_xp, career_xp, finances_xp, relationships_xp, health_xp);
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 375;
  const role = firstValueOf(profile?.role);
  if (!onEdit) {
    if (allExperiences.length < 1 || role === 'protege') {
      // hide
      return null;
    } else {
      // show ribbon
      return <WholeMeProfileExperienceRibbon onPost={onPost} experiences={allExperiences} />;
    }
  }

  // show edit
  return (
    <Stack sx={stackStyle}>
      <WholeMeProfileSectionMini
        title="Experiences"
        editable={true}
        onTitleClick={createEditHandler(onEdit, 'experiences_all', '_')}
        titleIcon={<ExperiencesIcon width={50} height={45.3} marginLeft={60} marginTop={32}  />}
      />
      <WholeMeProfileSectionMini
        title="Who do you know?"
        editable={true}
        onTitleClick={createEditHandler(onEdit, 'network', 'network')}
        titleIcon={<WDYKIcon width={35.859} height={42.5} marginLeft={isSmallScreen ? ".95rem" : "1.5rem"} marginTop={34} />}
      />
    </Stack>
  );
};

export default WholeMeProfileExperiences;
