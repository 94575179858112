import React from 'react';
import ProfileIntakeStepPage from '../profileIntakeStepPage/ProfileIntakeStepPage';

const ProfileInputPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  title,
  subtitle,
  backgroundOverlay,
  backgroundColor,
  children,
  isCtaDisabled,
  nextButtonIsLoading,
  exitIconOnClickOverride,
}) => {
  const profileInputStyling = {
    subHeaderStyles: {
      maxWidth: '300px',
    },
    styles: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };
  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={title}
      subtitle={subtitle}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: nextOnClick, isDisabled: isCtaDisabled, isLoading: nextButtonIsLoading }}
      backgroundOverlay={backgroundOverlay}
      backgroundColor={backgroundColor}
      titleStyleOverride={profileInputStyling}
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      {children}
    </ProfileIntakeStepPage>
  );
};

export default ProfileInputPage;
