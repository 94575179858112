import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

export const openAppSettings = () => {
  /**
 * Note that the only supported option by Apple is "App".
 * Using other options might break in future iOS versions
 * or have your app rejected from the App Store.
 */
  NativeSettings.open({
    optionAndroid: AndroidSettings.ApplicationDetails,
    optionIOS: IOSSettings.App,
  });
};
