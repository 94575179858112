import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Box, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import appStrings from '../../common/app_strings';
import GeneralTextCardAtom from '../../common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import BackrsButton from '../../common/atoms/backrsButton/BackrsButton';
import './YoureInScreen.css';
import MainImage from '../wholeme/molecules/mainImage/MainImage';

const {
  onboarding: { youreInPage },
} = appStrings;

const youreInBtnStyleOverride = {
  width: '100%',
  height: '57px',
  fontSize: '20px',
  fontWeight: 700,
  borderRadius: '40px',
  color: '#063D8F',
  backgroundColor: '#FFFFFF',
  border: 'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
};

const fixedButtonContOverride = {
  px: '24px',
  position: 'fixed',
  bottom: '1px',
  width: '90%',
  backgroundColor: '#063D8F',
  height: '110px',
};

const YoureInScreen = () => {
  const history = useHistory();

  const goToQuickProfile = () => {
    history.push('/wholeme/pre-matching');
  };

  return (
    <IonPage>
      <IonContent fullscreen className="yourInCont">
        <Box
          sx={{
            px: '24px',
            pt: '25%',
          }}
        >
          <Stack
            sx={{
              mb: '60px',
            }}
          >
            <GeneralTextCardAtom
              title={youreInPage.youreIn}
              body={youreInPage.getStarted}
              titleStyles={{
                textAlign: 'center',
                color: '#FFFFFF',
                fontSize: '28px',
                fontWeight: 700,
              }}
              bodyStyles={{
                fontSize: '21px',
                fontWeight: 400,
                textAlign: 'center',
                color: '#FFFFFF',
                opacity: 1,
              }}
            />
          </Stack>
          <Stack spacing={2.5} sx={{ mb: '1px' }}>
            <MainImage
              imageSrc="/assets/register/youre-in/youre-in-hero.svg"
              imageAlt="connected attributes"
            />
          </Stack>
        </Box>
        <Stack
          sx={fixedButtonContOverride}
        >
          <BackrsButton
            label={youreInPage.cta}
            onClick={goToQuickProfile}
            buttonStylesOverride={youreInBtnStyleOverride}
          />
        </Stack>
      </IonContent>
    </IonPage>
  );
};

export default YoureInScreen;
