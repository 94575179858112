import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Typography } from '@mui/material';
import useFetch from 'use-http';
import BackrsButton from '../../../../common/atoms/backrsButton/BackrsButton';
import appStrings from '../../../../common/app_strings';

const { profileMessageButton } = appStrings;

const messageButtonStyle = {
  position: 'absolute',
  right: '20px',
  top: '20px',
  maxWidth: '96px',
  maxHeight: '40px',
  fontSize: '14px',
  backgroundColor: '#03295F',
};

const WholeMeProfileMessageButton = ({
  setIsLoading,
  isViewingAdmin,
  userId,
  isMessageButtonLoading,
  userIsAdmin,
  label = profileMessageButton.label,
  buttonStyleOverride = messageButtonStyle,
  isText,
}) => {
  const { post, response } = useFetch();
  const history = useHistory();
  const handleMessageButtonClick = async () => {
    let conversationSid = null;
    try {
      setIsLoading(true);
      const type = (isViewingAdmin || userIsAdmin) ? 'community' : 'p2p';
      const res = await post(`/conversations/request-sid/${userId}/${type}`);
      if (response.ok && res?.sid) {
        conversationSid = res.sid;
      } else {
        throw new Error('request failed');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
      toast.error('Direct Messaging Unavailable. Try again later.');
    } finally {
      setIsLoading(false);
      if (conversationSid) {
        history.push(`/chat/${conversationSid}`);
      }
    }
  };

  return (
    isText ? (
      <Typography sx={buttonStyleOverride} onClick={handleMessageButtonClick}>
        {label}
      </Typography>
    ) : (
      <BackrsButton
        label={label}
        onClick={handleMessageButtonClick}
        buttonStylesOverride={buttonStyleOverride}
        isLoading={isMessageButtonLoading}
      />
    )
  );
};

export default WholeMeProfileMessageButton;
