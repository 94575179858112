import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { containerStyles } from './wholeMeProfileBadgesStyles';
import WholeMeProfileBadgesModal from './WholeMeProfileBadgesModal';
import useWindowDimensions from '../../../../helpers/hooks';

const WholeMeProfileBadges = ({ profile = {}, wholeMeClient }) => {
  const [badgesMeta, setBadgesMeta] = useState([]);
  const { badges } = profile;
  const badgeIds = (badges || []).map((badge) => Number(badge.value));
  const [isOpen, setOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const { width } = useWindowDimensions();

  const isSmallScreen = width <= 375;

  const handleClose = () => {
    setOpen(!isOpen);
  };

  const handleModalData = (id) => {
    setOpen(!isOpen);
    const chosenBadge = badgesMeta.filter((b) => b.id === id);
    setModalData(chosenBadge[0]);
  };

  useEffect(() => {
    wholeMeClient.fetchBadges(badgeIds).then((meta) => {
      setBadgesMeta(meta);
    });
  }, []);

  if (badgesMeta?.length < 1) {
    return null;
  }
  return (
    <>
      <Stack
        sx={{
          ...containerStyles,
          gridTemplateColumns: isSmallScreen ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
        }}
      >
        {badgesMeta.map((badge) => (
          <Box
            style={{ width: '3.5rem' }}
            key={badge.id}
            direction="column"
            id={badge.id}
            onClick={() => handleModalData(badge.id)}
          >
            <img style={{ width: '3.5rem', height: '3.5rem' }} src={badge.icon} alt={badge.title} />
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                fontWeight: '700',
                letterSpacing: '-1px',
              }}
              bold
            >
              {badge.title}
            </Typography>
          </Box>
        ))}
      </Stack>
      <WholeMeProfileBadgesModal isOpen={isOpen} handleClose={handleClose}>
        {modalData}
      </WholeMeProfileBadgesModal>
    </>
  );
};

export default WholeMeProfileBadges;
