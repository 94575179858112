export const categoryHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '10px',
  color: '#03295F',
  fontSize: '20px',
  fontFamily: 'Sarabun, arial, sans-serif',
  fontWeight: '600',
};

export const categoryHeaderImageStyles = { height: '65px' };

export const sectionMargin = { margin: '30px 0' };
