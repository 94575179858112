import React from 'react';
import {
  IonAvatar,
  IonItem,
  // IonLabel,
} from '@ionic/react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import AvatarRole from '../../components/AvatarRole';
import AvatarAdmin from '../../components/AvatarAdmin';
import { parseNotificationTemplate } from './notification_utils';

const buildNotificationTitle = ({ user, template }) => {
  const parsedTemplate = parseNotificationTemplate({
    user,
    template,
  });
  if (!user) {
    return (
      <Box width="100%" display="flex">
        <Typography className="comment-caption">
          {parsedTemplate.raw}
        </Typography>
      </Box>
    );
  }

  const {
    parts: { displayName, body: titleBody },
  } = parsedTemplate;
  return (
    <Box width="100%" display="flex">
      <Box className="comment-caption" marginTop={0}>
        {displayName && (
          <Box component="span" className="comment-name">
            {displayName}
          </Box>
        )}
        {titleBody}
      </Box>
    </Box>
  );
};

const NotificationItem = ({ notification, onClick }) => {
  const {
    title: template,
    body,
    read,
    notifying_user: notifyingUser,
    image_url: imageUrl,
    created_at: createdAt,
  } = notification;

  const notificationTitle = buildNotificationTitle({
    template: template || '',
    user: notifyingUser,
  });

  return (
    <IonItem
      lines="none"
      onClick={() => onClick(notification)}
      style={{
        margin: '1rem',
        alignItems: 'flex-start',
      }}
    >
      <Box display="flex" className="Avatar-Container">
        {notifyingUser && notifyingUser.role !== 'admin' ? (
          <AvatarRole
            user={notifyingUser}
            style={{ marginRight: '1rem' }}
            unsetTopMargin
          />
        ) : (
          <AvatarAdmin style={{ marginRight: '1rem' }} />
        )}
      </Box>
      <Stack>
        {notificationTitle}
        {body && (
          <Typography
            sx={{
              color: '#2d2d2d',
              fontSize: 14,
              lineHeight: 1.25,
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {body}
          </Typography>
        )}
        <Stack alignItems="baseline" pt={0.25}>
          {!read && (
            <CircleIcon
              sx={{
                fontSize: '7px',
                color: '#FD5C36',
                marginRight: '5px',
              }}
            />
          )}
          <Box
            component="span"
            sx={{
              fontSize: '0.625rem',
              color: read ? 'rgb(175, 175, 175)' : 'black',
            }}
          >
            {formatDistanceToNowStrict(parseISO(createdAt), {
              addSuffix: true,
            })}
          </Box>
        </Stack>
      </Stack>
      {imageUrl && (
        <IonAvatar slot="end">
          <img src={imageUrl} alt="" style={{ borderRadius: '4px' }} />
        </IonAvatar>
      )}
    </IonItem>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationItem;
