import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import BackrsButton from 'common/atoms/backrsButton/BackrsButton';

const containerStyles = { marginTop: '1rem', width: '100%' };
const spaceBetweenButtons = { marginBottom: '.5rem' };

const SplashPageNavControl = ({ nextButton, prevButton }) => (
  <Box as="nav-control" style={containerStyles}>
    <div style={spaceBetweenButtons}>
      <BackrsButton label={nextButton?.label} onClick={nextButton?.onClick} />
    </div>
    <div>
      <BackrsButton label={prevButton?.label} onClick={prevButton?.onClick} variant="secondary" />
    </div>
  </Box>
);

const buttonPropTypes = PropTypes.shape(
  { label: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired },
);

export const SplashPageNavControlPropTypes = {
  nextButton: buttonPropTypes.isRequired,
  prevButton: buttonPropTypes.isRequired,
};

SplashPageNavControl.propTypes = SplashPageNavControlPropTypes;

export default SplashPageNavControl;
