import React, { useEffect, useState } from 'react';
import InputField from 'common/atoms/inputField/InputField';
import { useFeatureFlagsContext } from 'providers/featureflags-provider';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import ProfileInputPage from 'pages/wholeme/molecules/inputPage/ProfileInputPage';
import { firstValueOf } from '../../../util/profile_util';

const ProfileIdentityIntakeZipPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [zipState, setZipState] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(async () => {
    const state = await wholeMeClient.fetchZipState();
    setZipState(firstValueOf(state?.zip_code) || '');
  }, []);

  const { isSaveAndContinueAlwaysEnabled } = useFeatureFlagsContext() || {};

  const saveZip = () => {
    setErrorMessage('');
    wholeMeClient
      .saveZipState([
        {
          category_id: 'identity',
          question_id: 'zip_code',
          value: zipState,
        },
      ])
      .then((res) => {
        const { error } = res || {};
        if (error) setErrorMessage('Invalid Zip Code');
        else nextOnClick();
      });
  };

  const mainImageData = {
    imageSrc: './assets/profile/identity/zip-code-page-2.svg',
    imageAlt: 'a red house',
  };

  const isCtaDisabled = isSaveAndContinueAlwaysEnabled && zipState?.length !== 5;

  return (
    <ProfileInputPage
      paginationMeta={paginationMeta}
      nextOnClick={saveZip}
      prevOnClick={prevOnClick}
      wholeMeClient={wholeMeClient}
      isCtaDisabled={isCtaDisabled}
      title="City or Region"
      subtitle="Your zip code helps us connect you with location-based networks or backing opportunities."
      backgroundOverlay="/assets/profile/identity/zip-code-overlay-3.svg"
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      <InputField
        onChange={(event) => setZipState(event.target.value)}
        placeholder="#####"
        fieldValue={zipState}
        icon="location"
        maxValueLength={5}
        enableNumpad
        theme="light"
        error={!!errorMessage && { message: errorMessage }}
      />
      <MainImage
        imageSrc={mainImageData.imageSrc}
        imageAlt={mainImageData.imageAlt}
      />
    </ProfileInputPage>
  );
};

export default ProfileIdentityIntakeZipPage;
