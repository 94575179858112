import React from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import UnderlineTextField from '../atoms/inputs/UnderlineTextField';
import { UnderlinedTextField } from '../atoms/inputs/themes';
import { states } from '../../pages/states';

const CheckrForm = ({
  control,
  errors,
  showCheckBox,
  checked,
  handleMiddleNameCheckChange,
}) => {
  const dismissKeyboardOnEnter = (e) => {
    if (e.key === 'Enter' && Capacitor.isNativePlatform()) {
      Keyboard.hide();
    }
  };
  return (
    <Box sx={{ px: 4 }}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
        columnSpacing={{ xs: 2 }}
      >
        <Grid item xs={12}>
          <UnderlineTextField
            name="first_name"
            control={control}
            placeholder="first"
            error={errors?.first_name}
            InputProps={{
              inputProps: { enterKeyHint: 'done' },
            }}
            onKeyUp={dismissKeyboardOnEnter}
            hideErrorHelper
          />
          {errors?.first_name && (
            <Grid item xs={12} sx={{ pt: '8px !important' }}>
              <Typography component="p" color="error" sx={{ fontSize: 12 }}>
                {errors?.first_name?.message}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={showCheckBox ? 10 : 12}>
          <UnderlineTextField
            name="middle_name"
            control={control}
            placeholder="middle"
            placeholderColor="#f66324"
            error={errors?.middle_name}
            InputProps={{
              inputProps: { enterKeyHint: 'done' },
            }}
            onKeyUp={dismissKeyboardOnEnter}
            hideErrorHelper
            checked={checked}
            handleMiddleNameCheckChange={handleMiddleNameCheckChange}
            disabled={checked}
          />
          {errors?.middle_name && (
            <Grid item xs={12} sx={{ pt: '8px !important' }}>
              <Typography component="p" color="error" sx={{ fontSize: 12 }}>
                {errors?.middle_name?.message}
              </Typography>
            </Grid>
          )}
        </Grid>
        {showCheckBox && (
          <Grid item xs={2}>
            <FormControlLabel
              label="NA"
              control={(
                <Checkbox
                  sx={{ p: 0 }}
                  checked={checked}
                  size="small"
                  onClick={handleMiddleNameCheckChange}
                  label="NA"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <UnderlineTextField
            name="last_name"
            control={control}
            placeholder="last"
            error={errors?.last_name}
            InputProps={{
              inputProps: { enterKeyHint: 'done' },
            }}
            onKeyUp={dismissKeyboardOnEnter}
            hideErrorHelper
          />
        </Grid>
        {errors?.last_name && (
          <Grid item xs={12} sx={{ pt: '8px !important' }}>
            <Typography component="p" color="error" sx={{ fontSize: 12 }}>
              {errors?.last_name?.message}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            name="birthday"
            control={control}
            rules={{
              required: true,
              pattern: /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/,
            }}
            render={({ field: { ref, onChange, ...rest } }) => (
              <NumberFormat
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                allowNegative={false}
                inputRef={ref}
                onValueChange={(values) => onChange(values.formattedValue)}
                format="##/##/####"
                placeholder="dob"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                customInput={UnderlinedTextField}
                error={!!errors?.birthday}
                InputProps={{
                  inputProps: { enterKeyHint: 'done', inputMode: 'numeric' },
                }}
                fullWidth
                pattern="[0-9]*"
                type="text"
                variant="standard"
                onKeyUp={dismissKeyboardOnEnter}
              />
            )}
          />
          {errors?.birthday && (
            <Grid item xs={12} sx={{ pt: '5px !important' }}>
              <Typography component="p" color="error" sx={{ fontSize: 12 }}>
                {errors?.birthday?.message}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <UnderlineTextField
            name="zip_code"
            control={control}
            placeholder="zip code"
            error={errors?.zip_code}
            InputProps={{
              inputProps: { enterKeyHint: 'done', inputMode: 'numeric' },
            }}
            onKeyUp={dismissKeyboardOnEnter}
            hideErrorHelper
          />
          {errors?.zip_code && (
            <Grid item xs={12} sx={{ pt: '2px !important' }}>
              <Typography component="p" color="error" sx={{ fontSize: 12 }}>
                {errors?.zip_code?.message}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={8}>
          <UnderlineTextField
            name="city"
            control={control}
            placeholder="city"
            error={errors?.city}
            InputProps={{
              inputProps: { enterKeyHint: 'done' },
            }}
            onKeyUp={dismissKeyboardOnEnter}
            hideErrorHelper
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="state"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <UnderlinedTextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                value={value || ''}
                size="small"
                fullWidth
                select
                error={errors?.state}
                variant="standard"
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: 250 },
                  },
                }}
                sx={{
                  '& .MuiSelect-select .notranslate::after': {
                    content: '"STATE"',
                    opacity: 0.5,
                    letterSpacing: 2,
                  },
                }}
              >
                {states.map((state) => (
                  <MenuItem
                    key={state.value}
                    value={state.value}
                  >
                    {state.label}
                  </MenuItem>
                ))}
              </UnderlinedTextField>
            )}
          />
        </Grid>
        {errors?.city && (
          <Grid item xs={12} sx={{ pt: '8px !important' }}>
            <Typography component="p" color="error" sx={{ fontSize: 12 }}>
              {errors?.city?.message}
            </Typography>
          </Grid>
        )}
        {errors?.state && (
          <Grid item xs={12} sx={{ pt: '8px !important' }}>
            <Typography component="p" color="error" sx={{ fontSize: 12 }}>
              {errors?.state?.message}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="ssn"
            control={control}
            rules={{
              required: true,
              pattern: /\b[0-9]{9}$\b/,
            }}
            render={({ field: { ref, onChange, ...rest } }) => (
              <NumberFormat
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                allowNegative={false}
                inputRef={ref}
                onValueChange={(values) => onChange(values.value)}
                format="###-##-####"
                placeholder="ssn"
                mask="_"
                customInput={UnderlinedTextField}
                error={!!errors?.ssn}
                InputProps={{
                  inputProps: { enterKeyHint: 'done', inputMode: 'numeric' },
                }}
                fullWidth
                pattern="[0-9]*"
                type="text"
                variant="standard"
                sx={{ '--placeholderClr': '#F66324' }}
                onKeyUp={dismissKeyboardOnEnter}
              />
            )}
          />
        </Grid>
        {errors?.ssn && (
          <Grid item xs={12} sx={{ pt: '8px !important' }}>
            <Typography component="p" color="error" sx={{ fontSize: 12 }}>
              {errors?.ssn?.message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

CheckrForm.propTypes = {
  control: PropTypes.oneOfType([PropTypes.any]),
  errors: PropTypes.oneOfType([PropTypes.object]),
  showCheckBox: PropTypes.bool,
  checked: PropTypes.bool,
  handleMiddleNameCheckChange: PropTypes.func,
};

CheckrForm.defaultProps = {
  control: null,
  errors: null,
  showCheckBox: false,
  checked: false,
  handleMiddleNameCheckChange: () => { },
};

export default CheckrForm;
