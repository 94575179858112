import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  portraitFrameStyles,
  portraitImageWrapperStyles,
  portraitImageStyles,
  portraitLabelStyles,
} from '../profileIntakePortraitStyles';

const CategoryPortrait = ({
  category,
  imageSrc,
  portraitStylesOverride,
  hasCompleted,
  handlePortraitClick,
}) => {
  let imageStyles = { ...portraitImageStyles, ...portraitStylesOverride };
  if (!hasCompleted) {
    imageStyles = { ...imageStyles, opacity: 0.3 };
  }
  return (
    <ButtonBase disableRipple disableTouchRipple onClick={handlePortraitClick}>
      <Box sx={portraitFrameStyles}>
        <Box sx={portraitImageWrapperStyles}>
          <Box
            component="img"
            src={imageSrc}
            sx={imageStyles}
          />
        </Box>
        <Typography sx={portraitLabelStyles}>
          {category}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

const CategoryPortraitPropTypes = {
  category: PropTypes.string,
  imageSrc: PropTypes.string,
  portraitStylesOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasCompleted: PropTypes.bool,
  handlePortraitClick: PropTypes.func,
};

const CategoryPortraitDefaultProps = {
  category: 'health',
  imageSrc: 'assets/meditation.svg',
  portraitStylesOverride: null,
  hasCompleted: false,
  handlePortraitClick: () => console.log('clicked on a portrait'),
};

CategoryPortrait.propTypes = CategoryPortraitPropTypes;
CategoryPortrait.defaultProps = CategoryPortraitDefaultProps;

export default CategoryPortrait;
