export const createEditHandler = (onEdit, categoryId, stepId) => {
  if (onEdit) {
    return () => onEdit(categoryId, stepId);
  }
  return undefined;
};

// eslint-disable-next-line
export const firstOf = (items) => (items ? items[0] : undefined);

export const firstValueOf = (item) => {
  if (!item || !item[0]) {
    return undefined;
  }

  return firstOf(item)?.value;
};

export const firstOptionOf = (item) => {
  if (!item || !item[0]) {
    return undefined;
  }

  return firstOf(item)?.option_id;
};

export const multiSelectDataFetchTransformer = (state) => Object.keys(state)
  // filter out team & group count to prevent malformed data
  .filter((key) => !['teamCount', 'groupCount', 'user_sponsors'].includes(key))
  .reduce(
    (acc, key) => ({
      ...acc,
      [key]: state[key].map((next) => ({
        ...next,
        value: next.option_id,
      })),
    }),
    {},
  );

export const multiSelectDataFetcherAdapter = (dataFetcher) => async () => {
  const state = await dataFetcher();
  return multiSelectDataFetchTransformer(state);
};

export const multiSelectDataSaverTransformer = (data) => {
  const transformed = Object.keys(data).map((key) => ({
    ...data[key],
    option_id: key,
    value: 'selected',
  }));
  return transformed;
};

export const multiSelectDataSaverAdapter = (dataSaver) => async (data) => {
  const transformed = multiSelectDataSaverTransformer(data);
  return dataSaver(transformed);
};

export const resolveDefaultIcons = (section, value) => {
  if (section === 'identity') {
    if (value === 'christianity') return '/assets/icons/identity-affiliation/cross.svg';
    if (value === 'judaism') return '/assets/icons/identity-affiliation/menorah.svg';
    if (value === 'islam') return '/assets/icons/identity-affiliation/islam.svg';
    if (value === 'hinduism') return '/assets/icons/identity-affiliation/hinduism.svg';
    if (value === 'buddhism') return '/assets/icons/identity-affiliation/buddhism.svg';
    return '/assets/icons/identity-affiliation/pastafarianism.svg';
  }
  if (section === 'education') {
    if (value === 'PhD in Backing') {
      return 'assets/icons/education-career/higher-education.svg';
    }
    return 'assets/icons/education-career/books-blue.svg';
  }
  if (section === 'health_credentials') {
    if (value === 'Ketogenic') {
      return 'assets/profile/coachLubav/ketogenic-leaf.svg';
    }
    return 'assets/icons/health/health.svg';
  }
  if (section === 'relationships_family') {
    if (value === 'Backs All Proteges & Backrs') return 'assets/logo_v2.svg';
    if (value === 'parent-guardian') return '/assets/icons/relationships/parent-guardian.svg';
    if (value === 'married-partner') return '/assets/icons/relationships/ring.svg';
    if (value === 'grandparent') return '/assets/icons/relationships/glasses.svg';
    if (value === 'step-parent') return '/assets/icons/relationships/foot.svg';
    if (value === 'foster-parent') return '/assets/icons/relationships/parent-guardian.svg';
    return 'assets/icons/relationships/family.svg';
  }
  if (section === 'childhood') {
    if (value === 'First Born') return '/assets/icons/relationships/family.svg';
    if (value === 'Youngest Child') return '/assets/icons/relationships/stroller.svg';
    if (value === 'Only Child') return '/assets/icons/relationships/only-child.svg';
    if (value === 'Middle Child') return '/assets/icons/relationships/middle-child.svg';
    if (value === 'Adopted') return '/assets/icons/identity-affiliation/teddy-bear.svg';
    if (value === 'Multigenerational Household') return '/assets/icons/relationships/multigen-house.svg';
    if (value === 'Twin +') return '/assets/icons/relationships/twin.svg';
    return 'assets/icons/relationships/family.svg';
  }
  if (section === 'finances_credentials') {
    if (value === 'Scholarship Sourcer') {
      return 'assets/profile/finances/money-chat-bold.svg';
    }
    return 'assets/icons/financial/fluent_certificate-24-filled.svg';
  }
  if (section === 'user_match_code') {
    return 'assets/icons/identity-affiliation/match-code.svg';
  }
  if (section === 'linkedIn') {
    if (value === 'education') {
      return 'assets/icons/education-career/graduation-cap-blue.svg';
    }
    if (value === 'job') {
      return 'assets/icons/education-career/occupation-blue.svg';
    }
    return 'assets/profile/wholeMe/linkedin-small.svg';
  }
  return null;
};

export const defaultIdentity = (questionId, optionId) => ({
  category_id: 'identity',
  question_id: questionId,
  value: 'selected',
  option_id: optionId,
});

export const preferNot = 'Prefer not to say';
