import React from 'react';
import {
  IonPage,
  IonContent,
  IonCard,
  IonRow,
  IonCol,
  IonCardContent,
  IonGrid,
} from '@ionic/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './GoalsPlaceholder.css';

const GoalsPlaceholder = () => (
  <IonPage>
    <Header />
    <IonContent style={{ '--background': '#03295F' }} fullscreen>
      <IonGrid className="goals-grid">
        <IonRow>
          <IonCol>
            <img
              src="/assets/takeoff.svg"
              alt="rocketship taking off"
              className="goals-rocket-img"
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCard
            className="goals-card-margin"
            style={{ '--background': '#063D8F' }}
          >
            <IonCardContent>
              <div className="goals-card-container">
                <img src="/assets/bullseye.svg" alt="goals bullseye" />
                <div className="goals-card-message">
                  <h2 className="goals-card-message-header">
                    Goals Coming Soon!
                  </h2>
                  <p>
                    We are working on a way for proteges to track goals within
                    the app. Stay tuned for updates.
                  </p>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </IonRow>
      </IonGrid>
    </IonContent>
    <Footer />
  </IonPage>
);

export default GoalsPlaceholder;
