import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const GeneralTextAtom = ({
  body,
  bodyStyles,
}) => {
  if (!body) return null;
  return (
    <Stack>
      {body && (
        <Typography
          sx={{
            color: '#2D2D2D',
            opacity: '.6',
            fontWeight: 400,
            textAlign: 'left',
            fontSize: 16,
            lineHeight: 1.3125,
            ...bodyStyles,
          }}
        >
          {body}
        </Typography>
      )}
    </Stack>
  );
};

GeneralTextAtom.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bodyStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

GeneralTextAtom.defaultProps = {
  bodyStyles: null,
};

export default GeneralTextAtom;
