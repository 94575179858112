export const inputFieldStyles = {
  input: {
    fontSize: '18px',
    fontFamily: 'Sarabun, arial, sans-serif',
    lineHeight: '18px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '12px',
  },
};

export const lightThemeStyles = {
  '& .MuiFilledInput-root': {
    background: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    '& fieldset': {
      borderColor: '#c3c3b9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
};

export const darkThemeStyles = {
  '& .MuiFilledInput-root': {
    background: '#f4f4ee',
  },
  '& .MuiSvgIcon-root': {
    color: '#C3C3B9',
  },
  '& .MuiOutlinedInput-root': {
    background: '#f4f4ee',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: '#063d8f',
    },
  },
};

export const labelStyles = {
  color: '#2d2d2d',
  lineHeight: 1.25,
  fontFamily: 'Sarabun, arial, sans-serif',
};

export const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
};

export const exitSvgStyles = {
  height: 'min-content',
  width: 'min-content',
  cursor: 'pointer',
  position: 'absolute',
  top: '16px',
  right: '20px',
};
