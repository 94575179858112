import React, { useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';

export const LeftArrowFilterButton = () => {
  const visibility = useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible('first', true);
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '16px',
        left: '6px',
        zIndex: 1,
        opacity: 1,
        visibility: 'visible',
        transition: 'opacity 0.3s',
        ...(isFirstItemVisible && {
          opacity: 0,
          visibility: 'hidden',
          transition: 'visibility 0s 0.3s, opacity 0.3s',
        }),
      }}
    >
      <IconButton
        disabled={isFirstItemVisible}
        onClick={() => visibility.scrollPrev()}
        disableRipple
        size="small"
        sx={{
          background: '#fff',
          height: 'min-content',
          mixBlendMode: 'lighten',
        }}
      >
        <ArrowBackIosNewRounded fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export const RightArrowFilterButton = () => {
  const visibility = useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible('last', false);
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '16px',
        right: '6px',
        opacity: 1,
        visibility: 'visible',
        transition: 'opacity 0.3s',
        ...(isLastItemVisible && {
          opacity: 0,
          visibility: 'hidden',
          transition: 'visibility 0s 0.3s, opacity 0.3s',
        }),
      }}
    >
      <IconButton
        disabled={isLastItemVisible}
        onClick={() => visibility.scrollNext()}
        disableRipple
        size="small"
        sx={{
          background: '#fff',
          height: 'min-content',
          mixBlendMode: 'lighten',
        }}
      >
        <ArrowForwardIosRounded fontSize="inherit" />
      </IconButton>
    </Box>
  );
};
