import React from 'react';
import {
  Box,
  ButtonBase,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import './SelectableSquareItem.css';

const TitleContainer = styled('div')({
  maxWidth: 'calc(100% - 20px)',
  textAlign: 'right',
  position: 'absolute',
  top: 7,
  right: 10,
});

const StyledSpan = styled('span')({
  fontFamily: 'Sarabun',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '16.8px',
  letterSpacing: '0em',
  color: 'white',
  whiteSpace: 'normal',
  overflowWrap: 'break-word',
});

const SelectableSquareItem = ({
  title,
  onClickCallBack,
  image,
  itemContainerStyle,
  showPlusIcon,
  stylesOverrides,
}) => {
  const titles = title.split('/');
  return (
    <ButtonBase
      onClick={() => { onClickCallBack(); }}
    >
      <Box spacing={0.375} sx={{ ...itemContainerStyle, ...stylesOverrides?.containerStyleOverride }}>
        {image && (
          <img alt="item" src={image} style={{ position: 'absolute', bottom: '4px', height: '40px' }} />
        )}

        {title && (
          <TitleContainer>
            {showPlusIcon && (
              <span className="whole-me-plus-icon-span" style={{ marginRight: '-4px' }}>
                <img alt="plus icon" src="/assets/profile/wholeMe/experiences/white-plus-icon.svg" />
              </span>
            )}
            {
              titles.map((t, i) => (
                <>
                  <StyledSpan>{t}</StyledSpan>
                  {titles.length > 1 && i < (titles.length - 1) && <StyledSpan> / </StyledSpan>}
                  <br />
                </>
              ))
            }
          </TitleContainer>
        )}
      </Box>
    </ButtonBase>
  );
};

SelectableSquareItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClickCallBack: PropTypes.func.isRequired,
  showPlusIcon: PropTypes.bool,
  image: PropTypes.string,
  itemContainerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SelectableSquareItem.defaultProps = {
  showPlusIcon: false,
  image: '',
  itemContainerStyle: {},
};

export default SelectableSquareItem;
