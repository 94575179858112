import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputField from 'common/atoms/inputField/InputField';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import {
  Typography,
} from '@mui/material';
import { Browser } from '@capacitor/browser';
import ProfileIntakeStepPage from '../../../molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import BottomDrawer from '../../../../../common/molecules/BottomDrawer/BottomDrawer';
import isValidUrl from '../../../../../helpers/isValidUrl';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';
import appStrings from '../../../../../common/app_strings';

const mainImageData = {
  imageSrc: './assets/profile/network/calendar.svg',
  imageAlt: 'a calendar',
};

const iconOverrideStyles = {
  position: 'relative',
  fontSize: '24px',
};

const imageStylesOverride = {
  margin: '0',
  paddingLeft: '20px',
  marginBottom: '-1.5rem',
};

const linkStyle = { color: '#5981D1', paddingLeft: '40px' };

const calendlyRegex = /^https?:\/\/(?:www\.)?calendly\.com\/[a-zA-Z0-9-]+(?:\/\d+(?:min|hr))?(?:\?.*)?$/i;

const ProfileCalendlyIntakePage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [calendlyUrl, setCalendlyUrl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { platform } = useAppState();
  const {
    calendlyPage: {
      drawerData,
      errMsg,
      calendlySignupUrl,
      title,
      subtitle,
      inputPlaceholder,
      dontHave,
      drawerTitle,
    },
  } = appStrings;

  const saveCalendlyState = (val) => {
    wholeMeClient.saveCalendlyState([
      {
        category_id: 'calendly',
        question_id: 'calendly_url',
        value: val,
      },
    ]).then(() => {
      nextOnClick();
    });
  };

  const saveCalendly = () => {
    setErrorMessage('');
    if (calendlyUrl === null || calendlyUrl.trim() === '') {
      saveCalendlyState(null);
    } else if (!isValidUrl(calendlyUrl, calendlyRegex).isValid) {
      setErrorMessage(errMsg);
    } else {
      saveCalendlyState(isValidUrl(calendlyUrl, calendlyRegex).url);
    }
  };

  const handleCalendlyClick = async () => {
    if (platform?.isNativePlatform) {
      const url = `https://${calendlySignupUrl}`;
      await Browser.open({ url });
    }
  };

  const RenderCalendlyText = (
    platform?.isNativePlatform ? (
      <span
        onClick={handleCalendlyClick}
        style={{ ...linkStyle, textDecoration: 'underline' }}
      >
        {calendlySignupUrl}
      </span>
    ) : (
      <a
        style={linkStyle}
        href={`https://${calendlySignupUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        {calendlySignupUrl}
      </a>
    )
  );

  const DrawerText = (
    <>
      <ol
        style={{
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        {drawerData.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ol>
      {RenderCalendlyText}
    </>
  );

  useEffect(async () => {
    const state = (await wholeMeClient.fetchCalendlyState())?.calendly_url || [];
    setCalendlyUrl(state[0]?.value || '');
  }, []);
  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      nextButton={{ onClick: saveCalendly }}
      prevButton={{ onClick: prevOnClick }}
      title={title}
      subtitle={subtitle}
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      <InputField
        onChange={(e) => setCalendlyUrl(e.target.value)}
        placeholder={inputPlaceholder}
        fieldValue={calendlyUrl}
        icon="occupation"
        iconStyleOverride={iconOverrideStyles}
        theme="light"
        error={!!errorMessage && { message: errorMessage }}
      />
      <Typography
        style={{
          fontSize: '13px',
          color: '#063D8F',
          fontWeight: 700,
          textAlign: 'center',
          marginTop: '-35px',
          cursor: 'pointer',
        }}
        onClick={() => setIsDrawerOpen(true)}
      >
        {dontHave}
      </Typography>
      <MainImage
        imageSrc={mainImageData.imageSrc}
        imageAlt={mainImageData.imageAlt}
        imageStylesOverride={imageStylesOverride}
      />
      <BottomDrawer
        title={drawerTitle}
        subTitle={DrawerText}
        showBackdrop={false}
        open={isDrawerOpen}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
        hideCta
        containerStylesOverride={{ justifyContent: 'center' }}
      />
    </ProfileIntakeStepPage>
  );
};

export const ProfileCalendlyIntakePagePropTypes = {
  nextButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  prevButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  paginationMeta: PropTypes.shape({ current: PropTypes.number, total: PropTypes.number }),
};

export const ProfileCalendlyIntakePageDefaultProps = {
  nextButton: { onClick: () => { } },
  prevButton: { label: '', onClick: () => { } },
  paginationMeta: { current: 0, total: 0 },
};

ProfileCalendlyIntakePage.propTypes = ProfileCalendlyIntakePagePropTypes;
ProfileCalendlyIntakePage.defaultProps = ProfileCalendlyIntakePageDefaultProps;

export default ProfileCalendlyIntakePage;
