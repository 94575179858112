import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';

const splashImage = {
  src: './assets/profile/network/network-splash.svg',
  alt: 'two friends smiling',
  styles: {
    width: '60%',
  },
};

const profileNetworkIntakeSplashPage = ({ nextOnClick, prevOnClick }) => (
  <ProfileIntakeSplashPage
    title="Who do you know?"
    subtitle="Sharing the breadth and depth of your network can help proteges make better use of your time!"
    splashImage={splashImage}
    nextButton={{ label: "Let's Go", onClick: nextOnClick }}
    prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    backgroundOverlay="/assets/profile/network/whodoyouknow-splash-overlay.svg"
  />
);

export default profileNetworkIntakeSplashPage;
