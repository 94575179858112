export const hobbiesData = [
  {
    label: 'Creating',
    value: 'Creating',
    labelImage: 'assets/profile/interests/interests-visual-arts.svg',
    subQuestions: [
      {
        label: '3D Printing',
        value: '3D Printing',
        infoUrl: 'https://en.wikipedia.org/wiki/3D_printing',
      },
      {
        label: 'Animation',
        value: 'Animation',
        infoUrl: 'https://en.wikipedia.org/wiki/Animation',
      },
      {
        label: 'Calligraphy',
        value: 'Calligraphy',
        infoUrl: 'https://en.wikipedia.org/wiki/Calligraphy',
      },
      {
        label: 'Candle Making',
        value: 'Candle Making',
        infoUrl: 'https://en.wikipedia.org/wiki/History_of_candle_making',
      },
      {
        label: 'Crafts',
        value: 'Crafts',
        infoUrl: 'https://en.wikipedia.org/wiki/Craft',
      },
      {
        label: 'Crocheting',
        value: 'Crocheting',
        infoUrl: 'https://en.wikipedia.org/wiki/Crochet',
      },
      {
        label: 'Cross-stitch',
        value: 'Cross-stitch',
        infoUrl: 'https://en.wikipedia.org/wiki/Cross-stitch',
      },
      {
        label: 'Design',
        value: 'Design',
        infoUrl: 'https://en.wikipedia.org/wiki/Design',
      },
      {
        label: 'Drawing',
        value: 'Drawing',
        infoUrl: 'https://en.wikipedia.org/wiki/Drawing',
      },
      {
        label: 'Drones',
        value: 'Drones',
        infoUrl: 'https://en.wikipedia.org/wiki/Unmanned_aerial_vehicle',
      },
      {
        label: 'Flower Arranging',
        value: 'Flower Arranging',
        infoUrl: 'https://en.wikipedia.org/wiki/Floral_design',
      },
      {
        label: 'Furniture making',
        value: 'Furniture making',
        infoUrl: 'https://www.instructables.com/How-To-Make-Furniture/',
      },
      {
        label: 'Jewelry Making',
        value: 'Jewelry Making',
        infoUrl: 'https://en.wikipedia.org/wiki/Jewellery_design',
      },
      {
        label: 'Knitting',
        value: 'Knitting',
        infoUrl: 'https://en.wikipedia.org/wiki/Knitting',
      },
      {
        label: 'Make Up',
        value: 'Make Up',
        infoUrl: 'https://en.wikipedia.org/wiki/Cosmetics',
      },
      {
        label: 'Metalworking',
        value: 'Metalworking',
        infoUrl: 'https://en.wikipedia.org/wiki/Metalworking',
      },
      {
        label: 'Nail Art',
        value: 'Nail Art',
        infoUrl: 'https://en.wikipedia.org/wiki/Nail_art',
      },
      {
        label: 'Origami',
        value: 'Origami',
        infoUrl: 'https://en.wikipedia.org/wiki/Origami',
      },
      {
        label: 'Painting',
        value: 'Painting',
        infoUrl: 'https://en.wikipedia.org/wiki/Painting',
      },
      {
        label: 'Paper Mache',
        value: 'Paper Mache',
        infoUrl: 'https://en.wikipedia.org/wiki/Papier-m%C3%A2ch%C3%A9',
      },
      {
        label: 'Photography',
        value: 'Photography',
        infoUrl: 'https://en.wikipedia.org/wiki/Photography',
      },
      {
        label: 'Scrapbooking',
        value: 'Scrapbooking',
        infoUrl: 'https://en.wikipedia.org/wiki/Scrapbooking',
      },
      {
        label: 'Sculpture',
        value: 'Sculpture',
        infoUrl: 'https://en.wikipedia.org/wiki/Sculpture',
      },
      {
        label: 'Videography',
        value: 'Videography',
        infoUrl: 'https://en.wikipedia.org/wiki/Videography',
      },
      {
        label: 'Weaving',
        value: 'Weaving',
        infoUrl: 'https://en.wikipedia.org/wiki/Weaving',
      },
      {
        label: 'Welding',
        value: 'Welding',
        infoUrl: 'https://en.wikipedia.org/wiki/Welding',
      },
      {
        label: 'Woodcarving',
        value: 'Woodcarving',
        infoUrl: 'https://en.wikipedia.org/wiki/Wood_carving',
      },
    ],
  },
  {
    label: 'Performing arts',
    value: 'Performing arts',
    labelImage: 'assets/profile/interests/interests-performing-arts.svg',
    subQuestions: [
      {
        label: 'Acrobatics',
        value: 'Acrobatics',
        infoUrl: 'https://en.wikipedia.org/wiki/Acrobatics',
      },
      {
        label: 'Acting / theatre',
        value: 'Acting / theatre',
        infoUrl: 'https://en.wikipedia.org/wiki/Acting',
      },
      {
        label: 'Ballet',
        value: 'Ballet',
        infoUrl: 'https://en.wikipedia.org/wiki/Ballet',
      },
      {
        label: 'Ballroom dance',
        value: 'Ballroom dance',
        infoUrl: 'https://en.wikipedia.org/wiki/Ballroom_dance',
      },
      {
        label: 'Choreography',
        value: 'Choreography',
        infoUrl: 'https://en.wikipedia.org/wiki/Choreography',
      },
      {
        label: 'Dancing',
        value: 'Dancing',
        infoUrl: 'https://en.wikipedia.org/wiki/Dance',
      },
      {
        label: 'Hip-hop dance',
        value: 'Hip-hop dance',
        infoUrl: 'https://en.wikipedia.org/wiki/Hip_hop_dance',
      },
      {
        label: 'Improv',
        value: 'Improv',
        infoUrl: 'https://en.wikipedia.org/wiki/Improvisational_theatre',
      },
      {
        label: 'Jazz dance',
        value: 'Jazz dance',
        infoUrl: 'https://en.wikipedia.org/wiki/Jazz_dance',
      },
      {
        label: 'Line dancing',
        value: 'Line dancing',
        infoUrl: 'https://en.wikipedia.org/wiki/Line_dance',
      },
      {
        label: 'Stand up comedy',
        value: 'Stand up comedy',
        infoUrl: 'https://en.wikipedia.org/wiki/Stand-up_comedy',
      },
      {
        label: 'Magic',
        value: 'Magic',
        infoUrl: 'https://en.wikipedia.org/wiki/Magic_(supernatural)',
      },
      {
        label: 'Poetry',
        value: 'Poetry',
        infoUrl: 'https://en.wikipedia.org/wiki/Poetry',
      },
      {
        label: 'Script writing',
        value: 'Script writing',
        infoUrl: 'https://en.wikipedia.org/wiki/Screenwriting',
      },
      {
        label: 'Song writing',
        value: 'Song writing',
        infoUrl: 'https://en.wikipedia.org/wiki/Songwriter',
      },
      {
        label: 'Stagecrew',
        value: 'Stagecrew',
        infoUrl: 'https://en.wikipedia.org/wiki/Running_crew',
      },
      {
        label: 'Swing dancing',
        value: 'Swing dancing',
        infoUrl: 'https://en.wikipedia.org/wiki/Swing_(dance)',
      },
      {
        label: 'Tap dance',
        value: 'Tap dance',
        infoUrl: 'https://en.wikipedia.org/wiki/Tap_dance',
      },
    ],
  },
  {
    label: 'Playing music',
    value: 'Playing music',
    infoUrl: '',
    labelImage: 'assets/profile/interests/interests-musical-instruments.svg',
    subQuestions: [
      {
        label: 'Banjo',
        value: 'Banjo',
        infoUrl: 'https://en.wikipedia.org/wiki/Banjo',
      },
      {
        label: 'Bass guitar',
        value: 'Bass guitar',
        infoUrl: 'https://en.wikipedia.org/wiki/Bass_guitar',
      },
      {
        label: 'Cello',
        value: 'Cello',
        infoUrl: 'https://en.wikipedia.org/wiki/Cello',
      },
      {
        label: 'Choir',
        value: 'Choir',
        infoUrl: 'https://en.wikipedia.org/wiki/Choir',
      },
      {
        label: 'Drums',
        value: 'Drums',
        infoUrl: 'https://en.wikipedia.org/wiki/Drum',
      },
      {
        label: 'French Horn',
        value: 'French Horn',
        infoUrl: 'https://en.wikipedia.org/wiki/French_horn',
      },
      {
        label: 'Guitar',
        value: 'Guitar',
        infoUrl: 'https://en.wikipedia.org/wiki/Guitar',
      },
      {
        label: 'Harp',
        value: 'Harp',
        infoUrl: 'https://en.wikipedia.org/wiki/Harp',
      },
      {
        label: 'Mandolin',
        value: 'Mandolin',
        infoUrl: 'https://en.wikipedia.org/wiki/Mandolin',
      },
      {
        label: 'Oboe',
        value: 'Oboe',
        infoUrl: 'https://en.wikipedia.org/wiki/Oboe',
      },
      {
        label: 'Piano / organ',
        value: 'Piano / organ',
        infoUrl: 'https://en.wikipedia.org/wiki/Piano',
      },
      {
        label: 'Sax',
        value: 'Sax',
        infoUrl: 'https://en.wikipedia.org/wiki/Saxophone',
      },
      {
        label: 'Singing',
        value: 'Singing',
        infoUrl: 'https://en.wikipedia.org/wiki/Singing',
      },
      {
        label: 'Trombone',
        value: 'Trombone',
        infoUrl: 'https://en.wikipedia.org/wiki/Trombone',
      },
      {
        label: 'Trumpet',
        value: 'Trumpet',
        infoUrl: 'https://en.wikipedia.org/wiki/Trumpet',
      },
      {
        label: 'Tuba',
        value: 'Tuba',
        infoUrl: 'https://en.wikipedia.org/wiki/Tuba',
      },
      {
        label: 'Viola',
        value: 'Viola',
        infoUrl: 'https://en.wikipedia.org/wiki/Viola',
      },
      {
        label: 'Violin',
        value: 'Violin',
        infoUrl: 'https://en.wikipedia.org/wiki/Violin',
      },
    ],
  },
  {
    label: 'Pets',
    value: 'Pets',
    labelImage: 'assets/profile/interests/interests-animals.svg',
    subQuestions: [
      {
        label: 'Bees',
        value: 'Bees',
        infoUrl: 'https://en.wikipedia.org/wiki/Bee',
      },
      {
        label: 'Birds',
        value: 'Birds',
        infoUrl: 'https://en.wikipedia.org/wiki/Bird',
      },
      {
        label: 'Cats',
        value: 'Cats',
        infoUrl: 'https://en.wikipedia.org/wiki/Cat',
      },
      {
        label: 'Chickens',
        value: 'Chickens',
        infoUrl: 'https://en.wikipedia.org/wiki/Chicken',
      },
      {
        label: 'Cows',
        value: 'Cows',
        infoUrl: 'https://en.wikipedia.org/wiki/Cattle',
      },
      {
        label: 'Dogs',
        value: 'Dogs',
        infoUrl: 'https://en.wikipedia.org/wiki/Dog',
      },
      {
        label: 'Ferret',
        value: 'Ferret',
        infoUrl: 'https://en.wikipedia.org/wiki/Ferret',
      },
      {
        label: 'Fish',
        value: 'Fish',
        infoUrl: 'https://en.wikipedia.org/wiki/Fish',
      },
      {
        label: 'Gerbil',
        value: 'Gerbil',
        infoUrl: 'https://en.wikipedia.org/wiki/Mongolian_gerbil',
      },
      {
        label: 'Horses',
        value: 'Horses',
        infoUrl: 'https://en.wikipedia.org/wiki/Horse',
      },
      {
        label: 'Insects',
        value: 'Insects',
        infoUrl: 'https://en.wikipedia.org/wiki/Insect',
      },
      {
        label: 'Pigs',
        value: 'Pigs',
        infoUrl: 'https://en.wikipedia.org/wiki/Pig',
      },
      {
        label: 'Reptiles',
        value: 'Reptiles',
        infoUrl: 'https://en.wikipedia.org/wiki/Reptile',
      },
    ],
  },
  {
    label: 'Games',
    value: 'Games',
    labelImage: 'assets/profile/interests/interests-games.svg',
    subQuestions: [
      {
        label: 'Board Games',
        value: 'Board Games',
        infoUrl: 'https://en.wikipedia.org/wiki/Board_game',
      },
      {
        label: 'Bridge',
        value: 'Bridge',
        infoUrl: 'https://en.wikipedia.org/wiki/Contract_bridge',
      },
      {
        label: 'Card Games',
        value: 'Card Games',
        infoUrl: 'https://en.wikipedia.org/wiki/Card_game',
      },
      {
        label: 'Chess',
        value: 'Chess',
        infoUrl: 'https://en.wikipedia.org/wiki/Chess',
      },
      {
        label: 'D&D',
        value: 'D&D',
        infoUrl: 'https://en.wikipedia.org/wiki/Dungeons_%26_Dragons',
      },
      {
        label: 'Dominos',
        value: 'Dominos',
        infoUrl: 'https://en.wikipedia.org/wiki/Dominoes',
      },
      {
        label: 'Go',
        value: 'Go',
        infoUrl: 'https://en.wikipedia.org/wiki/Go_(game)',
      },
      {
        label: 'Nightmare chess',
        value: 'Nightmare chess',
        infoUrl: 'https://en.wikipedia.org/wiki/Knightmare_Chess',
      },
      {
        label: 'Poker',
        value: 'Poker',
        infoUrl: 'https://en.wikipedia.org/wiki/Poker',
      },
      {
        label: 'Scrabble',
        value: 'Scrabble',
        infoUrl: 'https://en.wikipedia.org/wiki/Scrabble',
      },
      {
        label: 'Sports betting',
        value: 'Sports betting',
        infoUrl: 'https://en.wikipedia.org/wiki/Sports_betting',
      },
      {
        label: 'Word Games',
        value: 'Word Games',
        infoUrl: 'https://en.wikipedia.org/wiki/Word_game',
      },
    ],
  },
  {
    label: 'Gaming',
    value: 'Gaming',
    labelImage: 'assets/profile/interests/interests-video-gaming.svg',
    subQuestions: [
      {
        label: 'Fighting games',
        value: 'Fighting games',
      },
      {
        label: 'First-person shooter',
        value: 'First-person shooter',
      },
      {
        label: 'MOBA',
        value: 'MOBA',
      },
      {
        label: 'Racing games',
        value: 'Racing games',
      },
      {
        label: 'Real-time strategy',
        value: 'Real-time strategy',
      },
      {
        label: 'Sports games',
        value: 'Sports games',
      },
      {
        label: 'Third-person shooter',
        value: 'Third-person shooter',
      },
    ],
  },
  {
    label: 'Movies & TV',
    value: 'Movies & TV',
    labelImage: 'assets/profile/interests/interests-movies.svg',
    subQuestions: [
      {
        label: 'Action',
        value: 'Action',
      },
      {
        label: 'Anime',
        value: 'Anime',
      },
      {
        label: 'Comedies',
        value: 'Comedies',
      },
      {
        label: 'DCU',
        value: 'DCU',
      },
      {
        label: 'Documentaries',
        value: 'Documentaries',
      },
      {
        label: 'Drama',
        value: 'Drama',
      },
      {
        label: 'Horror',
        value: 'Horror',
      },
      {
        label: 'MCU',
        value: 'MCU',
      },
      {
        label: 'Musicals',
        value: 'Musicals',
      },
      {
        label: 'Podcasts',
        value: 'Podcasts',
      },
      {
        label: 'RomComs',
        value: 'RomComs',
      },
      {
        label: 'Short films',
        value: 'Short films',
      },
      {
        label: 'TV',
        value: 'TV',
      },
      {
        label: 'YouTube',
        value: 'YouTube',
      },
    ],
  },
  {
    label: 'Food',
    value: 'Food',
    labelImage: 'assets/profile/interests/interests-food.svg',
    subQuestions: [
      {
        label: 'Baking',
        value: 'Baking',
      },
      {
        label: 'Coffee roasting',
        value: 'Coffee roasting',
      },
      {
        label: 'Cooking',
        value: 'Cooking',
      },
      {
        label: 'Brewing',
        value: 'Brewing',
      },
      {
        label: 'Distilling',
        value: 'Distilling',
      },
      {
        label: 'Gardening',
        value: 'Gardening',
      },
      {
        label: 'Grilling',
        value: 'Grilling',
      },
      {
        label: 'Pickling',
        value: 'Pickling',
      },
    ],
  },
  {
    label: 'Causes',
    value: 'Causes',
    labelImage: 'assets/profile/interests/interests-passions.svg',
    subQuestions: [
      {
        label: 'Addiction',
        value: 'Addiction',
      },
      {
        label: 'Animal treatment',
        value: 'Animal treatment',
      },
      {
        label: 'Anti-discrimination',
        value: 'Anti-discrimination',
      },
      {
        label: 'Economic development',
        value: 'Economic development',
      },
      {
        label: 'Education',
        value: 'Education',
      },
      {
        label: 'Environment',
        value: 'Environment',
      },
      {
        label: 'Healthcare access',
        value: 'Healthcare access',
      },
      {
        label: 'Human rights',
        value: 'Human rights',
      },
      {
        label: 'Innovation',
        value: 'Innovation',
      },

      {
        label: 'Liberty',
        value: 'Liberty',
      },
      {
        label: 'Medical research',
        value: 'Medical research',
      },
      {
        label: 'Poverty-fighting',
        value: 'Poverty-fighting',
      },
      {
        label: 'Mental health',
        value: 'Mental health',
      },
      {
        label: 'Physical safety',
        value: 'Physical safety',
      },
    ],
  },
  {
    label: 'Music',
    value: 'Music',
    labelImage: 'assets/profile/interests/interests-music-genres.svg',
    subQuestions: [
      {
        label: 'Alternative',
        value: 'Alternative',
      },
      {
        label: 'Blues',
        value: 'Blues',
      },
      {
        label: 'Classical',
        value: 'Classical',
      },
      {
        label: 'Country',
        value: 'Country',
      },
      {
        label: 'EDM',
        value: 'EDM',
      },
      {
        label: 'Folk',
        value: 'Folk',
      },
      {
        label: 'Gospel',
        value: 'Gospel',
      },
      {
        label: 'Hip hop',
        value: 'Hip hop',
      },
      {
        label: 'Jazz',
        value: 'Jazz',
      },
      {
        label: 'Latin',
        value: 'Latin',
      },
      {
        label: 'Metal',
        value: 'Metal',
      },
      {
        label: 'Pop',
        value: 'Pop',
      },
      {
        label: 'Punk',
        value: 'Punk',
      },
      {
        label: 'R&B',
        value: 'R&B',
      },
      {
        label: 'Rock',
        value: 'Rock',
      },
      {
        label: 'Soul',
        value: 'Soul',
      },
    ],
  },
  {
    label: 'Reading',
    value: 'Reading',
    labelImage: 'assets/profile/interests/interests-reading.svg',
    subQuestions: [
      {
        label: 'Biographies',
        value: 'Biographies',
      },
      {
        label: 'Books',
        value: 'Books',
      },
      {
        label: 'Business',
        value: 'Business',
      },
      {
        label: 'Comics',
        value: 'Comics',
      },
      {
        label: 'Current events',
        value: 'Current events',
      },
      {
        label: 'Fiction',
        value: 'Fiction',
      },
      {
        label: 'Health',
        value: 'Health',
      },
      {
        label: 'History',
        value: 'History',
      },
      {
        label: 'Literature',
        value: 'Literature',
      },
      {
        label: 'Magazines',
        value: 'Magazines',
      },
      {
        label: 'Manga',
        value: 'Manga',
      },
      {
        label: 'Mystery',
        value: 'Mystery',
      },
      {
        label: 'Newspapers',
        value: 'Newspapers',
      },
      {
        label: 'Non-fiction books',
        value: 'Non-fiction books',
      },
      {
        label: 'Philosophy',
        value: 'Philosophy',
      },
      {
        label: 'Political science',
        value: 'Political science',
      },
      {
        label: 'Romance',
        value: 'Romance',
      },
      {
        label: 'Science',
        value: 'Science',
        infoUrl: '',
      },
      {
        label: 'Sociology',
        value: 'Sociology',
        infoUrl: '',
      },
      {
        label: 'Technology',
        value: 'Technology',
      },
      {
        label: 'Theology',
        value: 'Theology',
      },
    ],
  },
];
