import React from 'react';
import Adornment from './Adornment';
import {
  EmailSvg,
  PasswordSvg,
  PhoneSvg,
  LocalePinSvg,
  BlueBriefcaseSvg,
  LightCalendarSvg,
  AvatarSvg,
  EducationSvg,
  TextboxSvg,
  MatchCodeSvg,
  HexagonSvg,
  WingSvg,
  MonsterSvg,
  SearchSvg,
  GreekSvg,
  OtherReligionSvg,
  High5Svg,
  StrongArmSvg,
  ZodiacSvg,
  HeadThinkingSvg,
  GoldDropdownSvg,
} from './InputIcons';

const adornments = {
  email: <EmailSvg />,
  password: <PasswordSvg />,
  phone: <PhoneSvg />,
  location: <LocalePinSvg />,
  occupation: <BlueBriefcaseSvg />,
  dob: <LightCalendarSvg />,
  identity: <AvatarSvg />,
  education: <EducationSvg />,
  textbox: <TextboxSvg />,
  matchCode: <MatchCodeSvg />,
  hexagon: <HexagonSvg />,
  wing: <WingSvg />,
  monster: <MonsterSvg />,
  search: <SearchSvg />,
  greek: <GreekSvg />,
  religion: <OtherReligionSvg />,
  strength: <StrongArmSvg />,
  high5: <High5Svg />,
  zodiac: <ZodiacSvg />,
  meyersBriggs: <HeadThinkingSvg />,
  dropDown: <GoldDropdownSvg />,
};

const renderInputAdornment = (type, position, style = { fontSize: 24 }, viewBox = null) => (
  <Adornment position={position} iconStyles={style} viewBox={viewBox}>
    {adornments[type]}
  </Adornment>
);

export default renderInputAdornment;
