/* eslint-disable max-len */
export default {
  common: {
    teams: 'Teams',
    proteges: 'Proteges',
    explore: 'Home',
    goals: 'Goals',
    addGoal: 'Add a goal',
    addPost: 'Add a post',
    tasks: 'Tasks',
    updates: 'Updates',
    dismiss: 'Dismiss',
    sponsoredBy: 'Sponsored by',
  },
  login: {
    useEmailAndPw: 'Please login using email and password',
    phoneLoginUnavailable: 'Phone login is unavailable',
    greeting: 'Two ways to log in:',
    prompt: {
      beginning: 'Log in or',
      middle: ' sign up ',
      end: 'to get started',
    },
    placeholders: {
      email: 'Email',
      password: 'Password',
      phone: 'Mobile Number',
    },
    forgotPassword: 'Forgot password?',
    decorativeDivider: 'OR',
    tryLogin: 'Log in',
    resetPassword: 'If you have an account, you will receive an email to reset your password.',
  },
  onboarding: {
    register: 'Register Here',
    login: 'Login',
    joinTeam: 'Join a team',
    getStarted: 'Get Started',
    whatIsBackrs: 'What is backrs?',
    learn: 'Learn more',
    noAccount: "Don't have an account?",
    registerHeader: 'Join the community.',
    textBtn: 'Text Verifcation Code',
    explore: 'Skip and keep exploring',
    tsAndCs: ' I certify that I am 14 years of age or older.',
    youreInPage: {
      youreIn: "You're In!",
      getStarted: 'We’re so glad you’re here. Answer a few quick questions so we can connect you with personalized opportunities to support proteges.',
      mentor: 'Mentor a student',
      startBacking: 'Start backing a protege based on your common interests, profile, and preferences.',
      backOnDemand: 'Back on-demand',
      aboutYourself: 'Tell us about yourself, and we’ll send relevant backing opportunities based on your experiences.',
      exploreApp: 'Explore the app',
      checkCommunity: 'Check out the community feed, read backing resources, and contribute where you can.',
      protegeApply: 'Apply to be a protege',
      cta: "Let's do it!",
    },
    readyToBack: {
      youreReady: 'Choose how you’d like to get started:',
      backProtege: 'Back a protege',
      startBacking: 'Get matched with a protege whose goals you can champion over time',
      backInNetwork: 'Back in networks',
      affinityNetwork: 'Share insights, resources, and opportunities with proteges in your Affinity Networks.',
    },
    tac: {
      acceptBtac: 'By registering, I accept',
      btac: 'Backrs Terms & Conditions',
    },
    errors: {
      registrationFailed: (showPartial) => `An error has occurred.${showPartial && ' Try again or '}`,
    },
    aboutBackrs: 'About Backrs',
  },
  welcome: {
    greeting: 'Welcome to Backrs!',
    explore: 'Keep exploring',
    generalDescription:
      'We are a platform that helps young people achieve their aspirations by building a team of adults who have their back.',
    protegeDescription: 'A protege is a committed student looking for a team of supporters.',
    protegeInterest: "I'd like to be a protege",
    backrDescription:
      'A backr is a well-intended person looking to support a protege on their journey.',
    backrInterest: "I'd like to be a backr",
  },
  workflows: {
    firstTimeUser: {
      welcomeMessage: 'Hello First Time User',
      ctaLabel: "Let's go!",
      errors: {
        getFailed: 'Fetch Workflow Error',
      },
    },
    meetTheProteges: {
      purposeStatement: 'Purpose Statement',
    },
    meetTheBackrs: {
      aboutHeader: 'About',
      interestsHeader: 'Interests',
    },
  },
  feed: {
    errors: {
      feedUnavailable: "Uh oh! Your feed isn't loading.",
      filtersUnavailable: 'Feed Filters Unavailable',
      contactSupport: 'Contact Support',
    },
  },
  post: {
    actions: {
      copyLink: 'Copied link!',
      moreComments: 'More Comments',
      editPost: 'Edit Post',
      editGoal: 'Edit Goal',
      deletePost: 'Delete Post',
      deleteGoal: 'Delete Goal',
      reportPost: 'Report Post',
    },
    managing: {
      state: {
        uploadingMedia: 'Uploading Media...',
        mediaSelected: 'Media Selected',
      },
      errors: {
        exceedPdfSize: 'The pdf you tried to upload was too large. Try uploading a pdf with a file size less than 1GB.',
        exceedPdfMaxSum: "You've exceeded the total pdf storage that is allowed.",
        failedUpload: 'Failed to upload media. Please try again.',
      },
    },
  },
  report: {
    backButtonText: {
      posts: 'Report Post',
      comments: 'Report Comment',
      users: 'Report User',
      conversations: 'Report Conversation',
    },
    reportType: {
      posts: 'post',
      comments: 'comment',
      users: 'user',
      conversations: 'conversation',
    },
    categories: {
      posts: {
        offensive: 'I found it offensive',
        spam: "It's spam",
        copyright: 'Copyrighted material',
      },
      comments: {
        offensive: 'I found it offensive',
        spam: "It's spam",
        copyright: 'Copyrighted material',
      },
      users: {
        offensive: 'Offensive or abusive behavior',
        spam: 'Spam',
        copyright: 'Copyrighted Material',
        inappropriate: 'Inappropriate Direct Messaging Content',
      },
      conversations: {
        offensive: 'Offensive or abusive behavior',
        spam: 'Spam',
        copyright: 'Copyrighted Material',
        inappropriate: 'Inappropriate Direct Messaging Content',
      },
    },
    submitButton: {
      posts: 'Submit Report',
      comments: 'Submit Report',
      users: 'Report User',
      conversations: 'Report Conversation',
    },
    placeholders: {
      reason: 'Let us know the details behind your report.',
    },
  },
  teams: {
    multiSelect: 'Select a protege to view',
    unlockTeams: {
      title: 'Unlock Proteges',
      body: 'Want to play a more involved role in a student’s journey? Answer some quick Q’s so you can back a protege!',
      button: 'Back a Protege',
      verifyButton: 'Check my status',
    },
  },
  invest: {
    fail: {
      noDonorbox: 'Your protege does not yet have an invest page. Check back for updates!',
      otherWays: 'Other ways to invest',
      additionalOptions:
        'Backrs offers additional options to financially invest in one or more proteges.',
    },
  },
  hamburgerMenu: {
    tourNavigation: 'Tour the app',
    myProfile: 'My Profile',
    learnAndExplore: 'Learn and explore',
    financiallyBack: 'Financially Back',
    sendInvite: 'Refer a Friend',
    sendInviteWeb: "mailto:?subject=Convenient, Rewarding Volunteer Opportunity&body=I'm using the Backrs app to share my knowledge, connections, and extra funds with high-performing but under-resourced students. The time commitment is minimal (10 mins or less a week), but the impact is truly meaningful.%0D%0A%0D%0AYou'd love being a backr as much as I do. Click this link to download the app and try it out!%0D%0A%0D%0Ahttps://onelink.to/backrs%0D%0A%0D%0A",
    logOut: 'Log Out',
    joinATeam: 'Back a Protege',
    getVerified: 'Background Check',
    contactSupport: 'Get Support',
    resourceLibrary: 'Resource Library',
  },
  tour: {
    youreIn: "Let's Explore",
    takeOurTour: 'Take Our Tour',
    videoError: 'Unable to load backrs tour at this time',
    tourCtaScreen: {
      verifiedMsg:
        "You're one step closer to backing a protege. Before getting matched, explore our community!",
      exploreLink: "I'll explore on my own",
    },
    tourAlertCard: {
      takeTourCta: 'Take a tour of the app!',
      verifiedHoldMsg:
        'While you wait to be verified, explore the features that Backrs has to offer.',
      imgFailedToLoad: 'Image Unavailable',
      altImageText: 'Take tutorial asset',
    },
  },
  wholeMe: {
    wholeMeAlertCard: {
      header: 'Complete your profile!',
      body: 'Proteges are more likely to select backrs with a robust profile.',
    },
  },
  wholeMeProfileAvatarBusinessRoleText: {
    protege: 'protege',
    admin: 'friendly cyborg helper',
    backr: 'backr',
  },
  wholeMeProfilePurposeVideo: {
    cardTitle: 'Add a short video explaining who you are and what brought you to Backrs!',
    intakeTitle: 'Upload an Intro Video',
    intakeSubtitle: 'It’s easier to connect when people can put a face to the name 😁',
  },
  otpValidation: {
    resendCode: 'Enter your phone number to resend your verification code.',
    sendBtn: 'Send',
    contactSupport: 'Still not working? ',
    contactBtn: 'Contact support.',
    verifyLoginHeader: 'Enter the verification code',
    verifyLoginSubHeader: 'we just texted to you.',
    invalidCode: 'Verification not successful',
    failed: 'Code Request Failed',
    verifyRegisterHeader: 'Enter the verification code',
    verifyRegisterHeader2: 'we just texted to you.',
    register: 'Save & Continue',
    otp: {
      prompt: "Type in your phone number and we'll text you a verification code.",
      verify: 'We just sent a verification code to your phone number ending in',
      noCode: 'Don’t have a verification code?',
    },
  },
  goals: {
    noPublicGoals: "This protege doesn't have any public goals",
    seeUpdates: 'See Updates',
    achieved: 'Goal Achieved!',
    confirmDelete: 'Do you want to delete this goal?',
    yesDelete: 'Yes, delete it',
    noDelete: 'No, keep it',
    accomplishMore: 'Accomplish even more with Backrs.',
    addNewGoal: 'Add a new goal',
    labels: {
      allLabel: 'All Goals',
      academicLabel: 'Academic',
      passionLabel: 'Passion',
      wellnessLabel: 'Wellness',
      careerLabel: 'Career',
    },
    unlockGoals: {
      title: 'Unlock Goals',
      body: "Your proteges' goals will load soon",
      button: 'Back a Protege',
      verifyButton: 'Check my status',
    },
  },
  validationErrors: {
    cityReq: 'City is required.',
    stateReq: 'State is required.',
    excitedAgeGroupReq: 'Invalid value',
    excitedReq: 'Reason for excitement is required',
  },
  learnExplore: {
    header: 'Learn and Explore',
    meetOurBackrs: 'Meet our Backrs',
    meetOurProteges: 'Meet our Proteges',
    backingInAction: 'Backing in Action',
    whatIsBacking: 'What is backing?',
    primaryButton: "Let's Go!",
    aboutBackrs: {
      aboutHeader: 'About Backrs',
      cards: [
        {
          header: 'Backrs is a movement',
          img: 'assets/handmegaphone.svg',
          alt: 'hand holding megaphone',
          body: 'We hope to catalyze a global movement that drives more effective and inclusive backing. Everyone is worthy of a diverse, committed, and well-resourced group of people who have their back.',
        },
        {
          header: 'Backrs is a community',
          alt: 'hands holding up a globe',
          img: 'assets/icon/holdingworld.svg',
          body: 'We are a diverse group excited to help each other back and be backed. Backrs is also a community of institutions - school, businesses, nonprofits and health care providers - all committed to backing.',
        },
        {
          header: 'Backrs is a platform',
          img: 'assets/icon/platformicon.svg',
          alt: 'hand holding up a phone',
          body: 'We create digital experiences that are inclusive, supportive, trustworthy, and rewarding so that a diverse set of individuals and institutions can back and be backed. ',
        },
      ],
    },
  },
  whatIsBackrs: {
    header1: 'Backrs: A Social App for',
    header2: 'Social Mobility',
    subheader: 'We connect high-performing, under-resourced students with adults (like you) who empower them to realize their potential.',
    backingdata: {
      linedGroup: [
        {
          title: 'Students share:',
          body: 'Goals, questions, challenges, and requests for support.',
          icon: 'assets/icon/green-square.svg',
          iconClass: 'WIB-icon1',
        },
        {
          title: 'You’ll share:',
          body: 'Knowledge, connections, funds, and encouragement.',
          icon: 'assets/icon/blue-triangle.svg',
          iconClass: 'WIB-icon2',
        },
        {
          title: 'Students get:',
          body: 'The help they need to succeed in school and secure fulfilling, well-paying jobs.',
          icon: 'assets/icon/yellow-circle-half.svg',
          iconClass: 'WIB-icon3',
        },
        {
          title: 'You’ll get:',
          body: 'The chance to give back meaningfully and on your terms.',
          icon: 'assets/icon/grey-circle.svg',
          iconClass: 'WIB-icon4',
        },
        {
          title: 'Everyone grows.',
          body: 'By connecting with people outside of their bubble.',
          icon: 'assets/icon/orange-diamond.svg',
          iconClass: 'WIB-icon5',
        },
      ],
    },
    primaryButton: 'Let’s go!',
  },
  unsureModal: {
    header: "We'd love to hear from you!",
    subheader:
      'If our protege suggestions were not a good fit, let us know how we can support in your match.',
    submit: 'Submit',
    textarea: {
      name: 'reason',
      label: 'What are you looking for?',
      placeholder: 'Let us know in detail your backing preferences.',
    },
    dropdown: {
      name: 'preferred_contact',
      label: 'Preferred Contact',
      choices: [
        { label: 'Email', value: 'email' },
        { label: 'Text', value: 'text' },
      ],
      defaultValue: 'email',
    },
  },
  matchingQuiz: {
    header: 'Protege Preferences',
    subheader:
    'Let us know what age you would like to support! We promise to honor your preferences. ',
    submit: 'Create Profile',
    demographicInfo: {
      header: 'Demographic Information',
      body: 'This is the information that is most useful to create a diverse team that aligns with a protege’s needs.',
      identityHeader: 'Identity Affiliation',
      identityBody:
        'This information helps us build diverse teams and address our protege’s needs and preferences Info  will be visible to the Backrs community.',
    },
    sharedIdentity: {
      header: ' Shared Identity',
      body: 'We strive to build diverse teams, and many backrs and proteges prefer to have at least a few members of their team that share particular aspects of identity. If you’d like us to try to find a protege that shares one or more of the aspects you identified, check yes. If you have no preference, leave blank.',
    },
    matchingResults: {
      header: 'You’ll be a great backr!',
      subHeader:
        'Here are some proteges who would benefit from your support. Want to back any of them?',
      buttonLabel: 'Start Backing Them',
      unsure: "I'm not ready.",
      noResults: {
        header: 'Uh Oh!',
        button: 'Search again',
      },
      noResultsBody: {
        para1:
          'No protégés match your criteria right now. If you used a cohort code, protégés may not have completed onboarding yet.',
        para2:
          'We’ve put you on our waiting list and will email or text you when someone that meets your criteria is ready for backing.',
        para3:
          "If you'd like to back available proteges, please try your search again, and omit your Cohort code.",
      },
    },
    splashPage: {
      title: 'Profile Created!',
      subtitle: 'Woohoo! Now, you can answer a few questions about the protege you’d like to back.',
      noPreferences: 'Skip it, I have no preferences',
    },
    cohortCodePage: {
      title: 'Cohort Code',
      subtitle: "If you're hoping to back 1-3 proteges from a specific cohort, type their cohort code here.",
      noCode: 'Don’t have a cohort code?',
      placeholder: '123456',
    },
  },
  yourInScreen: {
    header: 'Get verified!',
    body: 'Backing works best when you can communicate with proteges directly.  To maintain a safe community, you\'ve got to pass a quick background check first.',
    checkrInfo: [
      'Background checks are free',
      'All information is confidential',
      'There’s no impact to credit',
    ],
    buttonLabel: 'Start Background Check',
    exploreTeam: 'Explore the team',
    maybeLater: 'I\'ll do this later',
  },
  checkrStatusScreen: {
    header: 'We’ll take it from here.',
    body: 'Your background check is now in flight and will take between 10 mins to 48 hours to clear. Once it does, you’ll be able to connect with your protege!',
    bodyNoBG:
      "Your background check is essential and typically takes 10 min to 48 hours to clear. Once complete, you'll be able to fully engage with your team soon! In the meantime, proceed to the link below to complete your background check.",
    lubavCard: {
      title: 'Meet Coach Lubav',
      body: 'Learn all about our cyborg super-backr and how Lubav makes it easier and more rewarding for you to back.',
    },
    noBgCard: {
      title: 'Background Check',
      body: 'Please click here to complete your background check so you can be matched with a protege as soon as possible!',
    },
    headToFeedCard: {
      title: 'Head to your feed',
      body: 'There, you can respond to some Backing Opportunities while you wait for your background check to clear.',
    },
  },
  checkrFormScreen: {
    body: 'Backrs is committed to safety. As part of that commitment, we partner securely with Checkr, the industry leader in consumer reports, to ensure the safety of our proteges (many of whom are minors). In order to communicate with a proteges, you must undergo a background check. These checks are private and confidential, guaranteeing that your information is not shared with the Backrs team or greater Backrs community.',
    termViewButton: 'checkr terms',
    termAccept: 'I accept ',
    disclosureLabel: 'I accept this disclosure regarding background investigation',
    rightsLabel:
      'I acknowledge this receipt of the Summary of Your Rights Under the Fair Credit Act',
    reportLabel: 'Send me my checkr report',
    submit: 'submit',
    continueExploring: 'Continue exploring',
    payPage: {
      header: 'Will you cover the cost?',
      body: 'Each background check cost Backrs $35. If you can pay for your own, you enable us to use those funds to support more students.',
      noThanks: 'No thank you',
      yes: 'Yes',
    },
  },
  chat: {
    beginning: 'This is the start of the conversation',
    convoReported: 'This conversation has been reported',
    loadingError: 'There was an error loading messages',
    chatHeader: {
      messageLubav: 'Message Coach Lubav',
      reportUser: 'Report User',
      reportConvo: 'Report Conversation',
    },
  },
  profileCompleted: {
    header: 'Profile Complete!',
    subheader: "Wow, you did it! You're a star! Feel free to edit any of your answers in your profile mode.",
    navText: 'View your whole profile',
    imgAltText: 'badge signifying profile completion',
    mainFeed: 'Take me to the main feed',
  },
  profileMessageButton: {
    label: 'Message',
  },
  faq: {
    header: 'Need help? We’ve got your back.',
    subheader1: 'Check out our FAQs below or',
    subheader2: 'for support.',
    message: 'message Coach Lubav',
  },
  toasts: {
    photoDenied: 'To upload a photo from your camera roll, please allow access to your photos via app settings.',
    cameraDenied: 'To take a photo, please allow access to your camera via app settings.',
    failedUpload: 'Failed to upload photo. Please try again.',
    goToSettings: 'Go To Settings',
    maybeLater: 'Maybe Later',
    dismiss: 'Dismiss',
  },
  share: {
    title: 'Take Part in the Backrs Community!',
    body: "I'm using the Backrs app to share my knowledge, connections, and extra funds with high-performing but under-resourced students. The time commitment is minimal (10 mins or less a week), but the impact is truly meaningful. You'd love being a backr as much as I do. Click this link to download the app and try it out!",
    url: 'https://onelink.to/backrs',
    dialogueTitle: 'Take Part in the Backrs Community!',
  },
  profile: {
    actionButtons: {
      messageAdmin: 'Send a Message',
      meet: 'Meet',
      message: 'Message',
      invest: 'Invest',
      back: 'Back',
      backing: 'Backing',
      proposed: 'Proposed',
    },
    unlockProfile: {
      title: 'Profile Locked!',
      body: 'In order to view profiles of proteges under the age of 18, you’ll need to pass a one-time background check.',
      buttonLabel: 'Background Check',
    },
    blockedProfile: {
      title: 'Profile Restricted!',
      body: 'This profile has been blocked from view for safety reasons. Please reach out to Coach Lubav for more information.',
    },
    unavailableProfile: {
      title: 'Uh Oh!',
      body: 'This profile is unavailable. If you think this is an error, contact Coach Lubav.',
      cta: 'Contact Coach Lubav',
    },
    backingModal: {
      ctaLabelBuilder: (protegeName) => `Join ${protegeName}'s Panel`,
      modalTitleBuilder: (protegeName) => `Champion ${protegeName}'s goals.`,
      topBody: 'Backing a protege enables you to view their goals, stay up-to-date on their journey, and build a relationship with them over time. It involves:',
      modalListData:
        [
          'A supported intro over Zoom.',
          'Reading their updates.',
          'Responding to their questions.',
          'Talking 1x a quarter.',
        ],
      errorState: {
        topBodyError: 'Oops, something went wrong!',
        errorModalListData: [
          'There was an problem matching you with this protege',
          'Please contact Coach Lubav for more information',
        ],
        contactLubavLabel: 'Contact Coach Lubav',
      },
      pendingState: {
        backgroundCheckPendingList: [
          'Check your background check status.',
          'Follow instructions and try again later.',
        ],
        topBodyPending: 'Looks like you require a background check to support this protege. Please do the following:',
        statusButtonLabel: 'View Status',
      },
    },
  },
  ageRestricted: {
    title: 'Whoa there!',
    body1: 'It looks like you are under 18, which means that you’re probably a protege, not a backr.',
    body2: 'Proteges are guided through the account creation process by our community team. Please message Coach Lubav to get your account set up!',
    ctaLabel: 'Message Coach Lubav',
  },
  linkedinPage: {
    drawerDataNative: [
      'Tap your profile photo.',
      'Tap View profile.',
      'Tap on the More icon next to Add section button.',
      'Tap Contact info.',
      'www.linkedin.com/in.',
    ],
    drawerDataWeb: [
      'Tap your LinkedIn profile photo.',
      'Locate the “Public profile & URL” box in the upper left of your screen.',
      'Copy the link in this box.',
    ],
    errMsg: 'Invalid URL, Expected Format: www.linkedin.com/in/john-doe',
    locateUrl: "Under the Your Profile section, locate your public profile URL. It'll be an address that starts with:",
    title: "What's your LinkedIn?",
    subtitle: 'If you can share your profile, it will help us know more about your professional & academic background.',
    inputPlaceholder: 'LinkedIn Profile Url',
    howToFindUrl: 'How do I find my LinkedIn URL?',
  },
  calendlyPage: {
    drawerData: [
      'Create an account at calendly.com.',
      'Tap “Availability.”',
      'Set your availability for 30 min calls.',
      'Tap your 30 min meeting event.',
      'Tap “Event details.”',
      'Set your meeting location for Zoom, a phone call, Google Meet, etc.',
      'Copy the event link, share here.',
    ],
    errMsg: 'Invalid URL, Expected Format: www.calendly.com/calendly-name',
    calendlySignupUrl: 'www.calendly.com/signup',
    title: 'Add your calendar!',
    subtitle: 'Help members of the community schedule time with you by sharing a link to your calendar.',
    inputPlaceholder: 'Calendar/Scheduling Link',
    dontHave: 'Don’t have one?',
    drawerTitle: 'Create a free scheduling link through Calendly:',
  },
  affinityNetwork: {
    howDidIJoinNetwork: 'How did I join this network?',
    affinityModal: {
      title: 'Affinity Networks',
      body: 'We added you to this network because your profile responses indicated it would be a good fit. If you think you’re in the wrong place or you’d like to opt out, send Coach Lubav a DM.',
      message: 'Message Coach Lubav',
    },
    affinityMember: {
      viewProfile: 'View Profile',
      sendMessage: 'Send Message',
      scheduleTime: 'Schedule Time',
      loading: 'Loading...',
    },
    affinityMemberList: {
      uhOh: 'Uh Oh!',
      listNotFound: 'List not found.',
      tryRefresh: 'Try refreshing the page or',
      contactSupport: 'contact support.',
    },
  },
  comments: {
    postUnavailable: 'Post unavailable',
    photo: 'photo',
    addPhoto: 'Add Photo',
    video: 'video',
    addVideo: 'Add Video',
    file: 'file',
    addFile: 'Add File',
    me: 'Me ',
    coachLubav: 'Coach Lubav',
    replyingTo: 'Replying to',
    post: 'Post',
    mediaUploadError: 'Failed to upload media. Please try again.',
    user: 'User',
    sharedPhoto: 'shared a photo',
    sharedVideo: 'shared a video',
    sharedFile: 'shared a file',
  },
  activate: {
    hey: 'Hey ',
    activateAccount: 'Activate your account!',
    tsAndCs: ' I certify that I am 14 years of age or older.',
    textVerification: 'Text Verification Code',
    tac: {
      acceptBtac: 'By registering, I accept',
      btac: 'Backrs Terms & Conditions',
    },
  },
  errorScreen: {
    title: 'Yikes! Something went wrong.',
    body: 'Try logging in again or contact ',
    body2: 'support to get more information.',
    contactSupport: 'Contact Support',
    login: 'Login',
  },
  version: {
    updateAppScreen: {
      header1: "It's been a while!",
      header2: 'Time to update your app.',
      body: "We've added some cool new features that you just have to see 😁",
      button: {
        label: 'Update the Backrs App',
      },
    },
  },
  blocking: {
    admin: {
      modal: {
        title: 'Are you sure?',
        body: "Blocking Coach Lubav will prevent you from accessing Backrs content and the backrs platform, effectively disabling your account. If you're having second thoughts, contact our admin first.",
        button: { confirm: 'Confirm and Block' },
      },
    },
    users: {
      modal: {
        blockBody: (name) => `You & ${name} will no longer be able to communicate or view each other's posts and comments.`,
        unBlockBody: (name) => `You & ${name} will be able to communicate and view each other's posts and comments.`,
        title: 'Are you sure?',
        button: { confirm: 'Confirm & Block', cancel: 'cancel', unblock: 'Unblock' },
      },
    },
  },
};
