import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';

import './Carousel.css';

const Carousel = ({
  slides,
  pagination,
}) => {
  const [showPagination, setShowPagination] = React.useState(!!pagination);

  const handlePagination = (swiper) => {
    if (!pagination) return;

    if (pagination.hideLast) {
      if ((swiper.realIndex + 1) === slides.length) {
        setShowPagination(false);
      } else {
        setShowPagination(true);
      }
    }
  };

  return (
    <Swiper
      className="carousel"
      modules={[Pagination]}
      pagination={showPagination && pagination}
      init={(swiper) => swiper.pagination.init()}
      onSlideChange={(swiper) => {
        handlePagination(swiper);
      }}
    >
      {slides.map((slide) => (
        <SwiperSlide key={`card-${slide.key}`}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

Carousel.propTypes = {
  slides: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  pagination: PropTypes.shape({
    hideLast: PropTypes.bool,
    dynamicBullets: PropTypes.bool,
    dynamicMainBullets: PropTypes.number,
  }),
};

Carousel.defaultProps = {
  pagination: null,
};
export default Carousel;
