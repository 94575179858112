import React from 'react';
import ChapteredMultiselectPage from 'pages/wholeme/molecules/chapteredMultiselectPage/ChapteredMultiselectPage';
import { hobbiesData } from './hobbiesData';

const bgOverlay = '/assets/profile/interests/interests-overlay.svg';

const ProfileInterestsIntakeHobbiesPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <ChapteredMultiselectPage
    fields={hobbiesData}
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Interests & Hobbies"
    subtitle="By sharing interests you can help others know you better & introduce them to new ideas and experiences."
    categoryId="interests"
    questionId="hobbies"
    dataFetcher={async () => (await wholeMeClient.fetchHobbiesState())?.hobbies || []}
    dataSaver={wholeMeClient.saveHobbiesState}
    backgroundOverlay={bgOverlay}
    showMoreEnabled
  />
);
export default ProfileInterestsIntakeHobbiesPage;
