/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  IonPage,
  IonFooter,
  IonContent,
} from '@ionic/react';
import {
  Typography,
  Stack,
  Box,
} from '@mui/material';
import Header from '../components/Header';
import appStrings from '../common/app_strings';
import AccordionCard from '../common/atoms/card/AccordionCard';
import { useAuthContext } from '../providers/auth-provider';
import WholeMeprofileMessageButton from './wholeme/molecules/wholeMeProfileMessageButton/WholeMeProfileMessageButton';

const {
  faq:
  {
    header,
    subheader1,
    subheader2,
    message,
  },
} = appStrings;

const headerStyle = {
  color: '#03295F',
  fontSize: '28px',
  fontWeight: '700',
};

const subheaderStyle = {
  color: '#2D2D2D',
  fontSize: '16px',
  fontWeight: '500',
  display: 'flex',
  justifyContent: 'center',
  gap: '4px',
};

const FAQ = () => {
  const { user } = useAuthContext();
  const [isMessageButtonLoading, setIsMessageButtonLoading] = useState(false);

  const data = [
    {
      question: "I'm new. Where do I go to back students?",
      answer: "First place to go is your feed. Scroll to find a 'Backing Opportunity' post, then follow the instructions to back proteges. <br><br>Want more opportunities? Complete your profile so we can add you to Networks. There, proteges who share your interests or experiences can post requests for support. <br><br>If you already finished your profile, sit tight! It can take a few days for us to add you to Networks. <br><br>When you’re ready to play a more involved role in a young person’s journey, become a mentor! Mentors are matched with proteges they can back on an ongoing basis. Tap 'Proteges' beneath your feed to get started.",
    },
    {
      question: "Who's Coach Lubav?",
      answer: "Coach Lubav is part AI assistant, part human support specialist. You can reach out with any questions you have by tapping the chat bubble in your top nav, then tapping Coach Lubav's name. You can also tap “Message Coach Lubav” below. <br><br>Here are some things Coach Lubav has helped backrs with in the past:<br><br><li>Using the app.</li><li>Scheduling a Zoom call with a protege they mentor.</li><li>Technical issues.</li><li>Being a great backr.</li><li>Navigating mentorship challenges.</li><li>Distributing information to groups of proteges.</li><li>Understanding the meaning of life. (Kidding! You're on your own with that one.)</li>",
    },
    {
      question: 'I just matched with a protege. What now? ',
      answer: "Congratulations! Take a moment to get to know your protege by visiting their profile and reviewing their goals. Once you've read through the information they've shared and watched their purpose video, introduce yourself! <br><br>Tap the red plus button at the bottom of your screen. After making sure that the drop down above the textbox is set to your protege's feed, say hi! Tell them what you do for work or how you like to spend your time, and share why you’re excited to be their backr.",
      // NOTE: will add the article link when it's added to resource library
      // answer: "Congratulations! Take a moment to get to know your protege by visiting their profile and reviewing their goals. Once you've read through the information they've shared and watched their purpose video, introduce yourself! <br><br>Tap the red plus button at the bottom of your screen. After making sure that the drop down above the textbox is set to your protege's feed, say hi! Tell them what you do for work or how you like to spend your time, and share why you’re excited to be their backr. <br><br>For more ideas on ways to connect with your protege, read <a href='#'>14 Ways to Be a Great Virtual Mentor</a>.",
    },
    {
      question: 'How do I share an opportunity with proteges?',
      answer: "Networks are great places to share internship, scholarship, or job opportunities with proteges. Tap the red plus button at the bottom of your screen. After making sure the drop down above the textbox is set to one of your Networks, enter a description of the opportunity there. <br><br>If you'd like to broadcast an opportunity more widely, send Coach Lubav a message with information about it. Our community managers can then pass that info along to all proteges who might be interested.",
    },
    {
      question: "My protege's been inactive. What should I do?",
      answer: "First, try reaching out with a 'check in' post. Tap the red plus button at the bottom of your screen. After making sure that the drop down above the textbox is set to your protege's feed, provide a quick update on yourself, then ask your protege how things are going for them. <br><br>If you still don't hear from them after that, message Coach Lubav below. Our community team will contact your protege to find out why they've stopped engaging and work to reconnect you.",
    },
    {
      question: "How do I help my protege if they're not sure what help they need?",
      answer: "Regularly engaging with a protege is often the most useful way to back them until they have a specific need. Feel free to ask questions about their life and goals and share updates about yourself, too. Knowing you're in their corner will encourage them to reach out when something does come up.",
    },
  ];
  return (
    <IonPage style={{ backgroundColor: '#F4F4EE' }}>
      <Header />
      <IonContent>
        <Stack sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '40px 60px',
        }}
        >
          <Typography style={headerStyle}>{header}</Typography>
          <Stack sx={{
            pt: 1,
          }}
          >
            <Typography style={subheaderStyle}>{subheader1}</Typography>
            <Box style={subheaderStyle}>
              <WholeMeprofileMessageButton
                isText
                isViewingAdmin
                userId={user?.id}
                label={message}
                setIsLoading={setIsMessageButtonLoading}
                isMessageButtonLoading={isMessageButtonLoading}
                buttonStyleOverride={{
                  color: '#5981D1',
                  textDecoration: 'underline',
                }}
              />
              {' '}
              <Typography>{subheader2}</Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack sx={{
          margin: '0 30px',
          display: 'flex',
          gap: '14px',
          paddingBottom: '20px',
        }}
        >
          {data.map((item) => (
            <AccordionCard
              key={item.question}
              title={item.question}
              subtitle={item.answer}
            />
          ))}
        </Stack>
      </IonContent>
      <IonFooter
        class="ion-no-border"
      >
        <div
          style={{
            background: '#F4F4EE !important',
            padding: '0 20px',
            height: '90px',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '10px',
          }}
        >
          <WholeMeprofileMessageButton
            isViewingAdmin
            userId={user?.id}
            label={message.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}
            setIsLoading={setIsMessageButtonLoading}
            isMessageButtonLoading={isMessageButtonLoading}
            buttonStyleOverride={{
              width: '100%',
              background: 'var(--Navy, #03295F)',
            }}
          />
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default FAQ;
