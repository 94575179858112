export const ENVIRONMENTS = {
  dev: 'development',
  staging: 'staging',
  prod: 'production',
};

export const computeEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || ENVIRONMENTS.dev;

  return env;
};
