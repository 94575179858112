import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import {
  categoryHeaderImageStyles,
  categoryHeaderStyles,
  sectionMargin,
} from './chapteredMultiselectPageStyles';

const ChapteredMultiselectPage = ({
  fields,
  paginationMeta,
  nextOnClick,
  prevOnClick,
  title,
  subtitle,
  categoryId,
  questionId,
  dataFetcher,
  dataSaver,
  backgroundOverlay,
  showMoreEnabled,
}) => {
  const [selectedFieldState, setSelectedFieldState] = useState();

  useEffect(async () => {
    const state = (await dataFetcher()) || [];
    const formattedFetchedData = state?.reduce((acc, next) => {
      const existingData = acc?.[next?.option_id] || [];
      return {
        ...acc,
        [next.option_id]: [...existingData, next?.suboption_id],
      };
    }, {});
    setSelectedFieldState(formattedFetchedData || {});
  }, []);

  const handlePillClick = (option, subOption) => {
    const oldOptionValues = selectedFieldState?.[option] || [];
    const newOptionValues = oldOptionValues?.includes(subOption)
      ? oldOptionValues?.filter((value) => value !== subOption)
      : [...oldOptionValues, subOption];

    setSelectedFieldState({
      ...selectedFieldState,
      [option]: newOptionValues,
    });
  };

  const saveField = () => {
    const dbFormattedData = Object.keys(selectedFieldState)
      .reduce((acc, optionId) => {
        const optionValuesToSave = selectedFieldState?.[optionId];
        if (!optionValuesToSave?.length) return acc;

        const values = optionValuesToSave?.map((subOption) => ({
          category_id: categoryId,
          question_id: questionId,
          option_id: optionId,
          suboption_id: subOption,
        }));
        return [...acc, ...values];
      }, []);

    dataSaver(dbFormattedData).then(() => nextOnClick());
  };

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={title}
      subtitle={subtitle}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: saveField }}
      backgroundOverlay={backgroundOverlay}
    >
      <div>
        {
        !selectedFieldState ? <WholeMeLoadingCircle />
          : (
            <div style={sectionMargin}>
              {fields?.map(({
                value, label, labelImage, subQuestions = [],
              }) => (
                <CategoryPillSection
                  value={value}
                  label={label}
                  labelImage={labelImage}
                  subQuestions={subQuestions}
                  activeItems={selectedFieldState?.[value] || []}
                  handlePillClick={handlePillClick}
                  showMoreEnabled={showMoreEnabled}
                />
              ))}
            </div>
          )
        }
      </div>
    </ProfileIntakeStepPage>
  );
};
export default ChapteredMultiselectPage;

const CategoryHeader = ({ label, image }) => (
  <div style={categoryHeaderStyles}>
    <div>{label}</div>
    <img src={image} alt={label} style={categoryHeaderImageStyles} />
  </div>
);

const CategoryPillSection = ({
  value,
  label,
  labelImage,
  subQuestions,
  activeItems,
  handlePillClick,
  showMoreEnabled,
}) => (
  <div key={value} style={sectionMargin}>
    <CategoryHeader label={label} image={labelImage} />
    <PillRenderer
      items={subQuestions}
      activeItems={activeItems}
      handlePillClick={(subOption) => handlePillClick(value, subOption)}
      pillStyles={{ backgroundColor: '#FFFFFF' }}
      showMoreEnabled={showMoreEnabled}
    />
  </div>
);
