import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Divider = ({ content }) => (
  <Typography
    sx={{
      color: '#C3C3B9',
      fontSize: 20,
      fontWeight: 700,
      mb: 1,
      overflow: 'hidden',
      textAlign: 'center',
      '&:before, &:after': {
        backgroundColor: '#C3C3B9',
        content: '""',
        display: 'inline-block',
        height: '1px',
        position: 'relative',
        verticalAlign: 'middle',
        width: '50%',
      },
      '&:before': {
        right: '0.5em',
        ml: '-50%',
      },
      '&:after': {
        left: '0.5em',
        mr: '-50%',
      },
    }}
  >
    {content && content}
  </Typography>
);

Divider.propTypes = {
  content: PropTypes.string,
};

Divider.defaultProps = {
  content: undefined,
};

export default Divider;
