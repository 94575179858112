import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const PostIndicator = ({
  type, text, sx, onClick,
}) => {
  const styles = {
    recommendation: {
      backgroundColor: '#03295f',
    },
    connection: {
      backgroundColor: '#063d8f',
    },
    resources: {
      backgroundColor: '#03295f',
    },
    affirmation: {
      backgroundColor: '#2cb669',
    },
    celebration: {
      backgroundColor: '#f7ae41',
    },
    meet: {
      backgroundColor: '#2d2d2d',
    },
    share: {
      backgroundColor: '#F7AE41',
    },
    health: {
      backgroundColor: '#063D8F',
    },
    passion: {
      backgroundColor: '#f76324',
    },
    academic: {
      backgroundColor: '#2cb669',
    },
    career: {
      backgroundColor: '#2d2d2d',
    },
  };

  const typographyStyles = {
    color: '#fff',
    fontSize: '0.625rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontFamily: '"Sarabun", arial, sans-serif',
    height: '24px',
    '& .MuiChip-label': {
      px: 1.25,
    },
  };

  return <Chip label={text || type} onClick={onClick} sx={{ ...styles[type], ...typographyStyles, ...sx }} />;
};

PostIndicator.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
};

PostIndicator.defaultProps = {
  text: '',
  sx: {},
  onClick: undefined,
};

export default PostIndicator;
