import React from 'react';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const BaseModal = ({
  isOpen, handleClose, children, showExit = true, modalOverrideStyles,
}) => (
  <Dialog
    PaperProps={{
      style: { borderRadius: 8, ...modalOverrideStyles },
    }}
    open={isOpen}
    onClose={handleClose}
  >
    {showExit && (
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
    )}
    <DialogContent sx={{ px: 2, pb: 2 }}>
      {children}
    </DialogContent>
  </Dialog>
);

BaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BaseModal;
