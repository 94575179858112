/* eslint-disable import/prefer-default-export */

/**
 * The values for these options are directly inform mappings that drive the
 * automated grouping of users by their Whole Me profile selections.
 *
 * Changes made to the value property or options of these objects may require corresponding changes in the listed files, and potentially others.
 * See:
 *  - backrs_api/src/services/eventBridge/eventHandlers/eventHelpers/profileEvents/groups/maps.js
 */
export const careerFieldData = [
  {
    label: 'Arts, Entertainment, Media',
    value: 'Arts, Media, & Entertainment',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Architecture',
          'Creator / Artist',
          'Design',
          'Entertainment / Sports',
          'Fashion / Textiles',
          'Film / Music / Photography',
          'Media / Journalism',
          'Performing Arts',
          'Writing / Copywriting',
        ],
      },
    ],
  },
  {
    label: 'Business',
    value: 'Business',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Accounting',
          'Administrative Support',
          'Entrepreneurship',
          'Finance',
          'Human Capital',
          'Investment Banking',
          'General Management',
          'Management Consulting',
          'Marketing / Public Relations',
          'Operations',
          'Product Management',
          'Real Estate',
          'Sales',
          'Supply Chain and Logistics',
        ],
      },
    ],
  },
  {
    label: 'Education / Children',
    value: 'Education',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Academia',
          'Administration',
          'Adult Training',
          'Early Childhood',
          'Social Work',
          'Teaching',
        ],
      },
    ],
  },
  {
    label: 'Patient Care',
    value: 'Patient Care',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Dentistry',
          'Healthcare Technician',
          'Medical Research',
          'Medicine',
          'Mental Health',
          'Nursing',
          'Nutrition',
          'Occupational Therapy',
          'Pharmacy',
          'Physical Therapy',
          'Physician Assistance',
          'Public Health',
          'Speech Pathology',
        ],
      },
    ],
  },
  {
    label: 'Science / Engineering',
    value: 'STEM',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Aerospace Engineering',
          'Biomedical Engineering',
          'Chemical Engineering',
          'Civil Engineering',
          'Data Science / Analytics',
          'Digital Consulting',
          'Digital Security',
          'Electrical Engineering',
          'Environmental Engineering',
          'Mechanical Engineering',
          'Natural Sciences',
          'Network Systems',
          'Software Development',
        ],
      },
    ],
  },
  {
    label: 'Law / Government',
    value: 'Law & Government',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Armed Services',
          'Criminal Justice',
          'Law',
          'Public / Civil Service',
        ],
      },
    ],
  },
  {
    label: 'Trades',
    value: 'Trades & Services',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Agricultural Trades',
          'Construction Trades',
          'Manufacturing Trades',
          'Service Trades',
          'Transportation Trades',
        ],
      },
    ],
  },
  {
    label: 'Agriculture & Animals',
    value: 'Environmental & Agricultural',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Animal Health',
          'Farming',
          'Food Systems',
        ],
      },
    ],
  },
  {
    label: 'Social Sector',
    value: 'Social Sector',
    subQuestions: [
      {
        label: 'What subcategory?',
        value: 'What subcategory?',
        multiselect: true,
        options: [
          'Advocacy',
          'Non-profit',
          'Religious',
        ],
      },
    ],
  },
];
