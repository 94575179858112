/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as jose from 'jose';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  InvalidCredentialsError,
  ValidationError,
  validateBackrPostRegistrationFields,
  CustomException,
} from '../helpers/errors';
import {
  getStoredToken,
  getTokenExpirationDate,
  removeToken,
  storeToken,
  getRegistrationStatusToken,
  setRegistrationCompleteToken,
  removeRegistrationStatusToken,
  REGISTRATION_COMPLETE_TOKEN,
  getCompletedProfileStatusToken,
} from '../helpers/tokenHelpers';
import { getDeviceUuid, registerNotifications } from '../notifications';
import axiosInstance from '../helpers/axios';
import useAppListener from '../hooks/useAppListener';

// this is just to get autocompletes - none of these will be the value in practice
const AuthContext = createContext({
  token: null,
  user: null,
  twilioJwt: null,
  isAuthenticated: false,
  isLoading: false,
  setToken: () => {},
  setUser: () => {},
  register: async () => {},
  handleBackrPreRegistration: async () => {},
  sendPasswordResetEmail: async () => {},
  changePassword: async () => {},
  login: async () => {},
  logout: () => {},
  doLogout: () => {},
  refreshUser: async () => {},
  tryAutoLogin: async () => {},
  refreshTwilioJwt: async () => {},
  preUser: null,
  setPreUser: () => {},
  handleVerifyRegistration: async () => {},
  handleOtpLoginRequest: () => {},
  handleVerifyLogin: async () => {},
  handleResendRegistrationRequest: () => {},
  handleUserActivation: async () => {},
  handleVerifyActivation: async () => {},
  handleResendOtpActivationCode: async () => {},
  handleUserActivationRequest: async () => {},
  handleResendUserActivationRequest: async () => {},
  handleVerifyUserActivation: async () => {},
  handleUpdateUserLastAccess: async () => {},
});

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [preUser, setPreUser] = useState(null);
  const [twilioJwt, setTwilioJwt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRegistrationCompleteToken, setHasRegistrationCompleteToken] = useState(
    getRegistrationStatusToken() === REGISTRATION_COMPLETE_TOKEN,
  );
  const [reported, setReported] = useState();
  const [hasGivenFeedback, setHasGivenFeedback] = useState(false);
  const [hasCompletedProfile, setHasCompletedProfile] = useState(
    getCompletedProfileStatusToken() === 'true',
  );
  const [fetchingJwt, setFetchingJwt] = useState(false);

  const history = useHistory();
  const { isActive } = useAppListener('auth-provider');

  const {
    post, put, get, response,
  } = useFetch();

  const handleRegisterNotifications = async () => {
    if (!Capacitor.isPluginAvailable('PushNotifications')) {
      return;
    }

    const uuid = await getDeviceUuid();
    const device_id = uuid?.identifier;
    let push_token = null;
    try {
      push_token = await registerNotifications(history);
    } catch (e) {
      console.error(e);
    }

    if (!!device_id && !!push_token) {
      const toPost = {
        push_token,
        device_id,
      };
      try {
        await post('/devices', toPost);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const refreshTwilioJwt = async (hardRefresh = false) => {
    (async () => {
      if (!user) {
        return;
      }

      setFetchingJwt(true);
      const storedTwilioJwt = localStorage.getItem('twilio_jwt');
      const shouldHardRefreshTwilioJwt = hardRefresh || !storedTwilioJwt;

      if (!shouldHardRefreshTwilioJwt) {
        try {
          const decodedTwilioJwt = jose.decodeJwt(storedTwilioJwt);
          const nowTimestamp = moment().unix();
          const jwtUserId = parseInt(decodedTwilioJwt.grants.identity, 10);

          if (nowTimestamp < decodedTwilioJwt.exp && jwtUserId === user.id) {
            setTwilioJwt(storedTwilioJwt);
            setFetchingJwt(false);
            return;
          }
        } catch (e) {
          if (e.code === 'ERR_JWE_INVALID') {
            localStorage.removeItem('twilio_jwt');
          }
        }
      }

      const jwtResponse = await get(`/users/${user.id}/twilio-jwt`);
      const newTwilioJwt = jwtResponse.twilio_jwt;
      localStorage.setItem('twilio_jwt', newTwilioJwt);
      setTwilioJwt(newTwilioJwt);
      setFetchingJwt(false);
    })();
  };

  useEffect(() => {
    if (isActive) {
      (async () => {
        if (user) {
          try {
            Sentry.setUser({
              id: user.id,
            });
          } catch (e) {
            console.error(e);
          }

          try {
            await refreshTwilioJwt(true);
          } catch (e) {
            console.error(e);
          }

          try {
            await handleRegisterNotifications();
          } catch (e) {
            console.error(e);
          }
        }
      })();
    }
  }, [user, isActive]);

  const handleUpdateUserLastAccess = async () => {
    try {
      await post('/users/activity/access');
    } catch (e) {
      console.error(e);
    }
  };

  const setUserPostRegistration = async (registrationRes) => {
    setToken(registrationRes.token);
    storeToken(registrationRes.token);
    setUser(registrationRes.user);
    setPreUser(null);
    setRegistrationCompleteToken();
  };

  const handleBackrPreRegistration = async ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    phone,
    isFourteen,
  }) => {
    setIsLoading(true);

    try {
      const resp = await post('/otp/registration', {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        confirmPassword,
        phone,
        role: 'backr',
        is_fourteen_plus: isFourteen,
        accepted_terms: true,
        excited_age_group: 'No Preference',
      });

      if (resp.status_code === 200 && resp.authExempt) {
        await setUserPostRegistration(resp);
        return resp;
      }

      if (resp.status_code === 200) {
        setPreUser({
          ...resp.user,
          password,
          confirmPassword,
        });
        return resp;
      }

      if (response.status === 400 && resp?.errors) {
        throw new ValidationError('Invalid inputs!', resp.errors);
      } else if (response.status !== 200) {
        const newE = new Error('preregistation_workflow_failed');
        newE.status_code = resp.status_code;
        throw newE;
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserActivationRequest = async ({
    phone,
    email,
    activationToken,
    password,
    userId,
  }) => {
    setIsLoading(true);

    try {
      const resp = await post('/users/activation', {
        phone,
        email,
        token: activationToken,
        password,
        userId,
      });

      if (resp.status_code === 200) {
        setPreUser({
          ...resp.user,
          password,
          email,
          phone,
          activationToken,
        });
        return resp;
      }

      if (resp?.errors?.some((e) => e.msg === 'User not found')) {
        throw CustomException('activation_request_failed', 404);
      }

      if (response.status === 400 && resp?.errors) {
        throw new ValidationError('Invalid inputs!', resp.errors);
      }

      if (response.status !== 200) {
        throw CustomException('activation_request_failed', resp.status);
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendRegistrationRequest = async (phone) => {
    if (!preUser) {
      throw new Error('preRegistration_data_unavailable');
    }

    const {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      confirmPassword,
      is_fourteen_plus: isFourteen,
    } = preUser;

    try {
      setIsLoading(true);
      const resp = await post('/otp/registration', {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        confirmPassword,
        phone,
        role: 'backr',
        is_fourteen_plus: isFourteen,
        accepted_terms: true,
        excited_age_group: 'No Preference',
      });

      if (resp.status_code === 200) {
        setPreUser({
          ...resp.user,
          password,
          confirmPassword,
        });
        return resp;
      }

      if (response.status === 400) {
        setIsLoading(false);
        throw new ValidationError('Invalid inputs!', resp.errors);
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendUserActivationRequest = async (phone) => {
    if (!preUser) {
      throw new Error('activation_request_data_unavailable');
    }

    const {
      email,
      password,
      activationToken,
      id: userId,
    } = preUser;

    try {
      setIsLoading(true);
      const resp = await post('/users/activation', {
        email,
        password,
        phone,
        token: activationToken,
        userId,
      });

      if (resp.status_code === 200) {
        setPreUser({
          ...resp.user,
          password,
          email,
          phone,
          activationToken,
        });
        return resp;
      }

      if (response.status === 400) {
        setIsLoading(false);
        throw new ValidationError('Invalid inputs!', resp.errors);
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyRegistration = async (otpCode) => {
    setIsLoading(true);
    try {
      const resp = await post('/otp/registration/verify', {
        phone: preUser.phone,
        code: otpCode,
        userId: preUser.id,
      });

      if (response.status === 400) {
        if (response?.data === 'authentication_failed') {
          throw new Error(response?.data);
        } else {
          throw new ValidationError('Invalid input!', resp.errors);
        }
      }

      if (resp?.status_code === 200) {
        await setUserPostRegistration(resp);
      }
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyUserActivation = async (otpCode) => {
    setIsLoading(true);
    try {
      const resp = await post('/users/activation/verify', {
        phone: preUser.phone,
        code: otpCode,
        userId: preUser.id,
        token: preUser.activationToken,
      });

      if (response.status === 400) {
        if (response?.data === 'authentication_failed') {
          throw new Error(response?.data);
        } else {
          throw new ValidationError('Invalid input!', resp.errors);
        }
      }

      if (resp?.status_code === 200) {
        await setUserPostRegistration(resp);
      }
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpLoginRequest = async (phone) => {
    try {
      setIsLoading(true);
      const resp = await post('/otp/send-code', { phone });

      if (response.ok) {
        setPreUser({ phone });
        return resp;
      }

      if (response.status === 400) {
        if (response?.data === 'otp_unavailable') {
          throw new Error(response?.data);
        } else {
          throw new ValidationError('Invalid input!', resp.errors);
        }
      } else if (response.status !== 200) {
        throw new Error(response?.data);
      }
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyLogin = async (code) => {
    try {
      setIsLoading(true);
      localStorage.removeItem('TWILIO_JWT');

      const resp = await post('/otp/login', {
        phone: preUser.phone,
        code,
      });

      if (resp?.status_code === 200) {
        setToken(resp.token);
        storeToken(resp.token);
        setUser(resp.user);
        setPreUser(null);

        if (!hasRegistrationCompleteToken) {
          setRegistrationCompleteToken();
        }

        const { isFlagged, email_verified: emailVerified } = resp.user;
        setReported(isFlagged);
        localStorage.setItem('isFlagged', isFlagged ? 'true' : 'false');

        if (!emailVerified) {
          toast('Please check your email to verify your email!');
        }
      }

      if (response.status === 401) {
        throw new InvalidCredentialsError();
      }

      if (response.status === 400) {
        if (response?.data === 'authentication_failed') {
          throw new Error(response.data);
        }

        throw new ValidationError('Invalid input!', resp.errors);
      }

      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    school,
    schoolType,
    grade,
    phone,
    role,
    registrationCode = null,
    isFourteen = false,
  ) => {
    setIsLoading(true);

    if (schoolType === 'default') {
      /* eslint-disable no-param-reassign */
      schoolType = null;
    }

    if (grade === 'default') {
      /* eslint-disable no-param-reassign */
      grade = null;
    }

    let userRequestBody = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      confirmPassword,
      role,
      is_fourteen_plus: isFourteen,
      accepted_terms: true,
      phone,
    };

    const protegeFields = {
      school,
      school_type: schoolType,
      grade,
      registrationCode,
    };

    const backrFields = {
      excited_age_group: 'No Preference',
    };

    if (role === 'protege') {
      userRequestBody = { ...userRequestBody, ...protegeFields };
    }

    if (role === 'backr') {
      userRequestBody = { ...userRequestBody, ...backrFields };
    }

    const registerResponse = await post('/users', userRequestBody);
    if (response.ok) {
      setToken(registerResponse.access_token);
      storeToken(registerResponse.access_token);
      setUser(registerResponse);
      setRegistrationCompleteToken();
    } else if (response.status === 400) {
      setIsLoading(false);
      throw new ValidationError('Invalid inputs!', registerResponse.errors);
    } else {
      setIsLoading(false);
      throw new Error('Unknown error!');
    }
    setIsLoading(false);
  };

  const registerBackrProfile = async (
    city,
    state,
    excitedAgeGroup,
    excited,
    optInPushNotifications,
  ) => {
    // client side field validation
    const fieldErrors = validateBackrPostRegistrationFields({
      city,
      state,
      excited,
      excitedAgeGroup,
    });

    if (fieldErrors.length) {
      throw new ValidationError('Invalid inputs!', fieldErrors);
    }

    setIsLoading(true);

    await put('/users/current-user/preferences/notifications', {
      push_comments: optInPushNotifications,
      push_likes: optInPushNotifications,
      push_team_updates: optInPushNotifications,
      push_community_updates: optInPushNotifications,
    });

    const updatedUserResponse = await put(`/users/backr/post-registration/${user.id}`, {
      city,
      state,
      excited_age_group: excitedAgeGroup,
      excited,
    });

    if (response.ok) {
      const userResponse = await get('/users/current-user');
      setUser(userResponse);
    } else if (response.status === 400) {
      setIsLoading(false);
      throw new ValidationError('Invalid inputs!', updatedUserResponse.errors);
    } else {
      setIsLoading(false);
      throw new Error('Unknown error!');
    }

    setIsLoading(false);
  };

  const login = async (email, password) => {
    setIsLoading(true);
    localStorage.removeItem('TWILIO_JWT');
    let userResponse = null;
    const loginResponse = await post('/users/login', { email, password });
    if (response.ok) {
      setToken(loginResponse.access_token);
      storeToken(loginResponse.access_token);
      userResponse = await get('/users/current-user');
      setUser(userResponse);

      if (!hasRegistrationCompleteToken) {
        setRegistrationCompleteToken();
      }

      if (!userResponse.isFlagged) {
        setReported(false);
        localStorage.setItem('isFlagged', 'false');
      }

      if (userResponse.isFlagged) {
        setReported(true);
        localStorage.setItem('isFlagged', 'true');
      }

      if (!userResponse.email_verified) {
        toast('Please check your email to verify your email!');
      }
    } else if (response.status === 401) {
      setIsLoading(false);
      throw new InvalidCredentialsError();
    } else if (response.status === 400) {
      setIsLoading(false);
      throw new ValidationError('Invalid input!', loginResponse.errors);
    } else {
      setIsLoading(false);
      throw new Error('Unknown error!');
    }
    setIsLoading(false);

    return userResponse;
  };

  const acceptBackrPledge = async () => {
    setIsLoading(true);

    const updatedUser = await axiosInstance.put(
      `/users/${user.id}`,
      { backr_pledge_taken: true },
      {
        headers: {
          Authorization: `Bearer ${getStoredToken()}`,
        },
      },
    );

    if (updatedUser.data) {
      setUser(updatedUser.data);
    }

    setIsLoading(false);
  };

  const sendPasswordResetEmail = async (email) => {
    const sendEmailResponse = await post('/users/send-password-reset-email', { email });
    if (!response.ok) {
      if (response.status === 400) {
        throw new ValidationError('Invalid input!', sendEmailResponse.errors);
      } else {
        throw new Error('Unknown error!');
      }
    }
  };

  // can be used in the main app or coming from PW reset email
  const changePassword = async (password, confirmPassword, changePasswordToken = null) => {
    try {
      await axiosInstance.post(
        '/users/reset-password',
        { password, confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${
              changePasswordToken === null ? getStoredToken() : changePasswordToken
            }`,
          },
        },
      );
    } catch (e) {
      if (e.response.status === 400) {
        throw new ValidationError('Invalid input!', e.response.data.errors);
      } else {
        throw new Error('Unknown error!');
      }
    }
  };

  const refreshUser = async () => {
    setIsLoading(true);
    const currentUser = await get('/users/current-user');
    if (response.ok) {
      setUser(currentUser);
    }
    setIsLoading(false);
  };

  const doLogout = () => {
    setToken(null);
    setUser(null);
    setTwilioJwt(null);
    removeToken();
  };

  const logout = () => {
    doLogout();
  };

  const tryAutoLogin = async () => {
    const storedToken = getStoredToken();
    if (!storedToken) {
      return false;
    }

    const expiration = getTokenExpirationDate(storedToken);
    if (expiration.getTime() - new Date().getTime() < 0) {
      logout();
      return false;
    }
    setIsLoading(true);
    const currentUser = await put('/users/auto-login');
    if (!response.ok) {
      logout();
      setIsLoading(false);
      return false;
    }
    setToken(storedToken);
    setUser(currentUser);

    const registrationStatusToken = getRegistrationStatusToken();
    if (!registrationStatusToken || registrationStatusToken !== REGISTRATION_COMPLETE_TOKEN) {
      setRegistrationCompleteToken();
      setHasRegistrationCompleteToken(true);
    }

    if (!currentUser.isFlagged) {
      setReported(false);
      localStorage.setItem('isFlagged', 'false');
    }

    if (currentUser.isFlagged) {
      setReported(true);
      localStorage.setItem('isFlagged', 'true');
    }

    setIsLoading(false);
    if (!currentUser.email_verified) {
      toast('Please check your email to verify your email!');
    }
    return true;
  };

  const handleUserActivation = async ({ email, phone, password }) => {
    try {
      const res = await Promise.resolve({
        email, phone, password, status_code: 500,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyActivation = async ({ code, phone }) => {
    try {
      // this will go to new endpoint that'll actually activate the user
      const payload = {
        phone,
        code,
      };
      await Promise.resolve({ payload, status_code: 200 });
    } catch (error) {
      console.error(error);
    }
  };

  const value = useMemo(() => ({
    token,
    user,
    twilioJwt,
    register,
    registerBackrProfile,
    login,
    logout,
    doLogout,
    setToken,
    setUser,
    tryAutoLogin,
    refreshTwilioJwt,
    refreshUser,
    sendPasswordResetEmail,
    changePassword,
    acceptBackrPledge,
    isAuthenticated: !!token,
    isLoading,
    hasRegistrationCompleteToken,
    setRegistrationCompleteToken,
    removeRegistrationStatusToken,
    reported,
    setReported,
    hasGivenFeedback,
    setHasGivenFeedback,
    handleBackrPreRegistration,
    handleVerifyRegistration,
    preUser,
    handleOtpLoginRequest,
    handleVerifyLogin,
    handleResendRegistrationRequest,
    hasCompletedProfile,
    setHasCompletedProfile,
    fetchingJwt,
    handleUserActivation,
    handleVerifyActivation,
    handleUserActivationRequest,
    handleResendUserActivationRequest,
    handleVerifyUserActivation,
    handleUpdateUserLastAccess,
  }));

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
