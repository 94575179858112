// eslint-disable-next-line import/prefer-default-export
export const states = [
  {
    name: 'Alabama',
    value: 'AL',
    label: 'Alabama - AL',
  },
  {
    name: 'Alaska',
    value: 'AK',
    label: 'Alaska - AK',
  },
  {
    name: 'Arizona',
    value: 'AZ',
    label: 'Arizona - AZ',
  },
  {
    name: 'Arkansas',
    value: 'AR',
    label: 'Arkansas - AR',
  },
  {
    name: 'California',
    value: 'CA',
    label: 'California - CA',
  },
  {
    name: 'Colorado',
    value: 'CO',
    label: 'Colorado - CO',
  },
  {
    name: 'Connecticut',
    value: 'CT',
    label: 'Connecticut - CT',
  },
  {
    name: 'Delaware',
    value: 'DE',
    label: 'Delaware - DE',
  },
  {
    name: 'District Of Columbia',
    value: 'DC',
    label: 'District Of Columbia - DC',
  },
  {
    name: 'Florida',
    value: 'FL',
    label: 'Florida - FL',
  },
  {
    name: 'Georgia',
    value: 'GA',
    label: 'Georgia - GA',
  },
  {
    name: 'Hawaii',
    value: 'HI',
    label: 'Hawaii - HI',
  },
  {
    name: 'Idaho',
    value: 'ID',
    label: 'Idaho - ID',
  },
  {
    name: 'Illinois',
    value: 'IL',
    label: 'Illinois - IL',
  },
  {
    name: 'Indiana',
    value: 'IN',
    label: 'Indiana - IN',
  },
  {
    name: 'Iowa',
    value: 'IA',
    label: 'Iowa - IA',
  },
  {
    name: 'Kansas',
    value: 'KS',
    label: 'Kansas - KS',
  },
  {
    name: 'Kentucky',
    value: 'KY',
    label: 'Kentucky - KY',
  },
  {
    name: 'Louisiana',
    value: 'LA',
    label: 'Louisiana - LA',
  },
  {
    name: 'Maine',
    value: 'ME',
    label: 'Maine - ME',
  },
  {
    name: 'Maryland',
    value: 'MD',
    label: 'Maryland - MD',
  },
  {
    name: 'Massachusetts',
    value: 'MA',
    label: 'Massachusetts - MA',
  },
  {
    name: 'Michigan',
    value: 'MI',
    label: 'Michigan - MI',
  },
  {
    name: 'Minnesota',
    value: 'MN',
    label: 'Minnesota - MN',
  },
  {
    name: 'Mississippi',
    value: 'MS',
    label: 'Mississippi - MS',
  },
  {
    name: 'Missouri',
    value: 'MO',
    label: 'Missouri - MO',
  },
  {
    name: 'Montana',
    value: 'MT',
    label: 'Montana - MT',
  },
  {
    name: 'Nebraska',
    value: 'NE',
    label: 'Nebraska - NE',
  },
  {
    name: 'Nevada',
    value: 'NV',
    label: 'Nevada - NV',
  },
  {
    name: 'New Hampshire',
    value: 'NH',
    label: 'New Hampshire - NH',
  },
  {
    name: 'New Jersey',
    value: 'NJ',
    label: 'New Jersey - NJ',
  },
  {
    name: 'New Mexico',
    value: 'NM',
    label: 'New Mexico - NM',
  },
  {
    name: 'New York',
    value: 'NY',
    label: 'New York - NY',
  },
  {
    name: 'North Carolina',
    value: 'NC',
    label: 'North Carolina - NC',
  },
  {
    name: 'North Dakota',
    value: 'ND',
    label: 'North Dakota - ND',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP',
    label: 'Northern Mariana Islands - MP',
  },
  {
    name: 'Ohio',
    value: 'OH',
    label: 'Ohio - OH',
  },
  {
    name: 'Oklahoma',
    value: 'OK',
    label: 'Oklahoma - OK',
  },
  {
    name: 'Oregon',
    value: 'OR',
    label: 'Oregon - OR',
  },
  {
    name: 'Pennsylvania',
    value: 'PA',
    label: 'Pennsylvania - PA',
  },
  {
    name: 'Rhode Island',
    value: 'RI',
    label: 'Rhode Island - RI',
  },
  {
    name: 'South Carolina',
    value: 'SC',
    label: 'South Carolina - SC',
  },
  {
    name: 'South Dakota',
    value: 'SD',
    label: 'South Dakota - SD',
  },
  {
    name: 'Tennessee',
    value: 'TN',
    label: 'Tennessee - TN',
  },
  {
    name: 'Texas',
    value: 'TX',
    label: 'Texas - TX',
  },
  {
    name: 'Utah',
    value: 'UT',
    label: 'Utah - UT',
  },
  {
    name: 'Vermont',
    value: 'VT',
    label: 'Vermont - VT',
  },
  {
    name: 'Virginia',
    value: 'VA',
    label: 'Virginia - VA',
  },
  {
    name: 'Washington',
    value: 'WA',
    label: 'Washington - WA',
  },
  {
    name: 'West Virginia',
    value: 'WV',
    label: 'West Virginia - WV',
  },
  {
    name: 'Wisconsin',
    value: 'WI',
    label: 'Wisconsin - WI',
  },
  {
    name: 'Wyoming',
    value: 'WY',
    label: 'Wyoming - WY',
  },
];
