import React, { useState } from 'react';
import ProfileIntakeStepPageContainer from 'pages/wholeme/molecules/profileIntakeStepPageContainer/ProfileIntakeStepPageContainer';
import { computeContainer } from 'pages/wholeme/util/compute_container';

const ProfileIntakeWizard = ({
  wholeMeClient,
  onSubmitBack,
  onSubmitFinal,
  stepCategoryKeys = [],
  ...componentProps
}) => {
  const [paginationMeta, setPaginationMeta] = useState({
    current: 0,
    total: 0,
  });

  if (stepCategoryKeys.length < 1) {
    return <div />;
  }

  const categoryMeta = stepCategoryKeys[paginationMeta.current];
  const components = computeContainer(categoryMeta) || [];

  const nextOnClick = () => {
    let { current } = paginationMeta;

    if (current === stepCategoryKeys.length - 1) {
      onSubmitFinal();
      return;
    }

    current++;

    if (current > stepCategoryKeys.length - 1) {
      current = 0;
    }

    setPaginationMeta({
      ...paginationMeta,
      current,
    });
  };

  return (
    <ProfileIntakeStepPageContainer
      key={`category-${paginationMeta.current}`}
      components={components}
      onSubmitFinal={nextOnClick}
      onSubmitBack={onSubmitBack}
      wholeMeClient={wholeMeClient}
      {...componentProps}
    />
  );
};

export default ProfileIntakeWizard;
