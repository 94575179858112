/* eslint-disable max-len */
import React from 'react';
import {
  IonContent,
  IonButton,
} from '@ionic/react';
import {
  Stack,
  Typography,
  Box,
} from '@mui/material';
import appStrings from 'common/app_strings';
import './AboutBackrs.css';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../providers/auth-provider';
import PageWithBackButton from '../components/generic/PageWithBackButton';
import WhatIsBackingList from '../common/atoms/WhatIsBackingList';
import useWindowDimensions from '../helpers/hooks';

const {
  whatIsBackrs: {
    header1,
    header2,
    subheader,
    backingdata,
    primaryButton,
  },
} = appStrings;

const headerStyle = {
  color: '#063D8F',
  fontSize: '28px',
  fontWeight: 700,
  pt: 4,
};
const subheaderStyle = {
  color: '#2D2D2D',
  fontSize: '16px',
  fontWeight: 500,
  pt: 2,
  mx: 4,
};

const WhatIsBackrs = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const { width } = useWindowDimensions();

  const handleNavigation = () => {
    if (!user) {
      history.push('/register/register/backr');
      return;
    }
    history.push('/tabs/backr/teams');
  };

  return (
    <PageWithBackButton showLogInButton={!user} backButtonText="">
      <IonContent>
        <Stack
          sx={{
            minHeight: 'calc(100vh - 160px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom))',
            justifyContent: 'space-between',
          }}
        >
          <Stack alignItems="center" sx={{ mx: 3, textAlign: 'center' }}>
            <Typography sx={headerStyle}>{header1}</Typography>
            <Typography sx={{ ...headerStyle, pt: 0 }}>{header2}</Typography>
            <Typography sx={subheaderStyle}>{subheader}</Typography>
          </Stack>
          <Stack sx={{ pt: 4, pb: 4 }}>
            <Box>
              <WhatIsBackingList boxStyle={{ marginRight: width <= 375 ? '60px' : '90px' }} meta={backingdata} showIcons isAbout />
            </Box>
          </Stack>
          <IonButton
            size="large"
            expand="block"
            shape="round"
            style={{
              '--background-hover': '#063D8F',
              '--background-activated': '#063D8F',
              '--background-focused': '#063D8F',
              marginLeft: '24px',
              marginRight: '24px',
              fontWeight: 700,
              paddingBottom: '24px',
            }}
            mode="ios"
            onClick={handleNavigation}
          >
            {primaryButton}
          </IonButton>
        </Stack>
      </IonContent>
    </PageWithBackButton>
  );
};

export default WhatIsBackrs;
