import {
  useEffect,
  useRef,
  useCallback,
} from 'react';

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);
  const intervalRef = useRef(null);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    let id;
    if (delay !== null) {
      id = setInterval(() => savedCallback.current(), delay);
      intervalRef.current = id;
    }
    return () => clearInterval(id);
  }, [delay]);

  useEffect(() => () => clearInterval(intervalRef), []);

  const reset = useCallback(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(savedCallback.current, delay);
    }
  });

  const stop = useCallback(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
  });

  return {
    reset,
    stop,
  };
}

export default useInterval;
