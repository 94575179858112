export const sectionStyle = {
  background: '#FFFFFF',
  borderRadius: 2,
  padding: '9px 15px',
  minHeight: '67px',
  position: 'relative',
};

export const miniSectionStyle = {
  ...sectionStyle,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
  color: '#2D2D2D',
};
export const pillRendererOverrideStyles = {
  display: 'block',
};

export const pillOverrideStyles = {
  margin: '4px',
  maxWidth: '66%',
};
