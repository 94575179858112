import React from 'react';
import { Typography, Stack, Box } from '@mui/material';
import { useFetch } from 'use-http';
import PropTypes from 'prop-types';
import BackrsButton from 'common/atoms/backrsButton/BackrsButton';
import { displayName } from 'helpers/textManipulation';
import { useHistory } from 'react-router';
import BaseModal from '../../../../common/atoms/BaseModal/BaseModal';
import appStrings from '../../../../common/app_strings';
import styles from './BlockModal.styles';

const ClickableText = ({ onClick, children }) => (
  <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={onClick}>
    {children}
  </span>
);

const BlockModal = ({
  isOpen,
  handleClose,
  id,
  user,
  logout,
  profileType,
  setUserBlocked,
  firstName,
  lastName,
  showPresent,
  userBlocked,
}) => {
  const {
    blocking: { admin, users },
  } = appStrings;
  const {
    post: doPost, put: doPut, delete: doDelete, response,
  } = useFetch();
  const history = useHistory();

  const { post: postCommunitySid, response: communitySidRes } = useFetch(`/conversations/request-sid/${user?.Id}/community`);

  const handleAdminSupportRoute = async () => {
    try {
      const res = await postCommunitySid();
      if (communitySidRes.ok && res?.sid) {
        history.push(`/chat/${res.sid}`);
      } else {
        throw new Error('Community conversation request fail');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
    }
  };

  const handleBlockAdminRequest = async (
    idNum,
    userObj,
    logoutFunc,
  ) => {
    try {
      await doPost(`/users/current-user/blocked-users/${idNum}`, {});
      const disabledUser = await doPut(`/users/${userObj?.id}`, { email: userObj?.email, is_disabled: true });
      if (disabledUser && response.ok) {
        console.log('Logging out');
        handleClose();
        setTimeout(async () => {
          await logoutFunc();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockUserRequest = async (idNum) => {
    try {
      await doPost(`/users/current-user/blocked-users/${idNum}`, {});
      if (response.status === 201) {
        showPresent(`You have blocked ${displayName(firstName, lastName)}`);
        setUserBlocked(true);
      }
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const handleUnblockUserRequest = async (idNum) => {
    try {
      await doDelete(`/users/current-user/blocked-users/${idNum}`);
      if (response.status === 200) {
        showPresent(`You have unblocked ${displayName(firstName, lastName)}`);
        setUserBlocked(false);
      }
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const onAdminSubmit = async () => {
    try {
      await handleBlockAdminRequest(id, user, logout);
    } catch (e) {
      console.error(e);
    }
  };

  const onUserSubmit = async () => {
    try {
      await handleBlockUserRequest(id);
    } catch (e) {
      console.error(e);
    }
  };

  const onUserUnblockSubmit = async () => {
    try {
      await handleUnblockUserRequest(id);
    } catch (e) {
      console.error(e);
    }
  };

  const content = {
    admin: {
      title: admin.modal.title,
      body: (
        <>
          {/* eslint-disable-next-line max-len */}
          Blocking Coach Lubav will prevent you from accessing Backrs content and the Backrs platform, effectively diabling your account. If you&apos;re having second thoughts,
          {' '}
          <ClickableText onClick={handleAdminSupportRoute}>contact our admin first</ClickableText>
          .
        </>
      ),
      button: {
        confirm: admin.modal.button.confirm,
      },
    },
    user: {
      title: users.modal.title,
      blockBody: users.modal.blockBody(displayName(firstName, lastName)),
      unblockBody: users.modal.unBlockBody(displayName(firstName, lastName)),
      button: {
        confirm: users.modal.button.confirm,
        unblock: users.modal.button.unblock,
      },
    },
  };

  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose}>
      <Box sx={styles.modalContainer}>
        <Stack>
          <Typography sx={styles.modalTitle}>
            {content[profileType].title}
          </Typography>
        </Stack>
        <Box sx={styles.modalContent}>
          <Typography sx={styles.modalText}>
            {profileType === 'admin' ? content.admin.body : !userBlocked ? content[profileType].blockBody : content[profileType].unblockBody}
          </Typography>
        </Box>
        <Stack sx={styles.stack}>
          <BackrsButton
            buttonStylesOverride={{
              height: '42px',
              fontSize: '16px',
              fontWeight: 700,
              cursor: 'pointer',
              backgroundColor: styles.button.color,
              '&:hover': {
                backgroundColor: styles.button.color,
              },
              '&:focus': {
                backgroundColor: styles.button.color,
              },
            }}
            onClick={profileType === 'admin' ? onAdminSubmit : userBlocked ? onUserUnblockSubmit : onUserSubmit}
            label={!userBlocked ? content[profileType].button.confirm : content[profileType].button.unblock}
          />
        </Stack>
      </Box>
    </BaseModal>
  );
};

BlockModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  user: PropTypes.shape.isRequired,
  logout: PropTypes.func.isRequired,
  profileType: PropTypes.string.isRequired,
  setUserBlocked: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  showPresent: PropTypes.func.isRequired,
  userBlocked: PropTypes.bool.isRequired,
};

export default BlockModal;
