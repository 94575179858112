import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
} from '@ionic/react';
import {
  Button, Menu, MenuItem, Stack, Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { ellipsisVertical } from 'ionicons/icons';
import PropTypes from 'prop-types';
import useFetch from 'use-http';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { deriveUserRole } from './utils';
import { displayName } from '../../helpers/textManipulation';
import appStrings from '../../common/app_strings';
import { useAuthContext } from '../../providers/auth-provider';
import { buttonNoSelectStyles } from '../../theme/styles';

const { chat: { chatHeader } } = appStrings;

const shouldShowMenuButton = (otherParticipant) => {
  if (otherParticipant?.role === 'admin' || otherParticipant?.role === 'system') {
    return false;
  } return true;
};

const linkStyle = {
  textDecoration: 'none',
  color: '#03295F',
};

const IonIconBase = React.forwardRef(({ className, slot, icon }, ref) => (
  <IonIcon className={className} slot={slot} icon={icon} ref={ref} />
));

const MotionIonIcon = motion(IonIconBase);

const variants = {
  rotate: { rotate: -90, transition: { duration: 0.3 } },
  initial: { rotate: 0, transition: { duration: 0.3 } },
};

const ChatHeader = ({ otherParticipant, conversationMeta }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportLink, setReportLink] = useState('');
  const [reportConversationLink, setReportConversationLink] = useState('');
  const [username, setUsername] = useState('');

  const { user } = useAuthContext();
  const { post, response } = useFetch();
  const { conversationId } = useParams();
  const history = useHistory();

  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getConvoSidForLubavMessage = async () => {
    let conversationSid = null;
    try {
      const res = await post(`/conversations/request-sid/${user?.id}/community`);
      if (response.ok && res?.sid) {
        conversationSid = res.sid;
      } else {
        throw new Error('request failed');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
      throw error;
    }
    return conversationSid;
  };

  useEffect(() => {
    if (!otherParticipant?.id && !otherParticipant?.role) {
      return;
    }
    const derivedRole = deriveUserRole({ role: otherParticipant.role, aliasAdminAsSystem: true });
    if (derivedRole === 'admin' && !otherParticipant?.id) {
      setUsername('Coach Lubav');
    } else {
      setReportLink(`/users/${otherParticipant.id}/report`);
      const fullname = displayName(otherParticipant?.first_name, otherParticipant?.last_name, true);
      setUsername(fullname);
    }
  }, [otherParticipant, conversationId]);

  useEffect(() => {
    if (conversationMeta?.id) {
      setReportConversationLink(`/conversations/${conversationMeta.id}/report`);
    }
  }, [conversationMeta, conversationId]);

  const handleMessageLubavClick = async () => {
    try {
      const convoSid = await getConvoSidForLubavMessage();
      if (convoSid) {
        history.push(convoSid);
      }
    } catch (error) {
      toast.error('Direct Messaging Unavailable. Try again later.');
    }
    handleMenuClose();
  };

  const showMenuButton = shouldShowMenuButton(otherParticipant && otherParticipant);

  return (
    <IonHeader class="ion-no-border">
      <IonToolbar className="back-button-toolbar" mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            text={username ? `Conversation with ${username}` : ''}
            icon="/assets/back-arrow.svg"
            style={{
              '--icon-font-size': '1.25rem',
              '--icon-padding-start': '8px',
              '--icon-padding-end': '12px',
              '--icon-padding-top': '4px',
              '--icon-margin-end': '6px',
            }}
            className="back-button-custom"
            mode="ios"
            defaultHref="/"
          />
        </IonButtons>
        {showMenuButton && (
          <IonButtons slot="end">
            <Stack>
              <Menu
                aria-label="Header Menu"
                open={open}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
                anchorReference="anchorEl"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {conversationMeta?.isReported ? (
                  <MenuItem key="message-lubav" disableRipple>
                    <Typography style={linkStyle} onClick={handleMessageLubavClick}>
                      {chatHeader.messageLubav}
                    </Typography>
                  </MenuItem>
                ) : (
                  <Stack>
                    <MenuItem key="my-profile" disableRipple>
                      <Link style={linkStyle} to={reportLink}>{chatHeader.reportUser}</Link>
                    </MenuItem>
                    <MenuItem key="report-convo" disableRipple>
                      <Link style={linkStyle} to={reportConversationLink}>
                        {chatHeader.reportConvo}
                      </Link>
                    </MenuItem>
                  </Stack>
                )}
              </Menu>
              <Button
                disableRipple
                style={buttonNoSelectStyles}
                onClick={handleMenuOpen}
              >
                <MotionIonIcon
                  variants={variants}
                  className="header-menu-btn"
                  slot="icon-only"
                  icon={ellipsisVertical}
                  animate={anchorEl ? 'rotate' : 'initial'}
                />
              </Button>
            </Stack>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

ChatHeader.propTypes = {
  conversationContext: PropTypes.shape({
    otherParticipant: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      role: PropTypes.string,
    }),
    conversationMeta: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      external_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isReported: PropTypes.bool,
      isCommunityConversation: PropTypes.bool,
    }),
  }),
};

ChatHeader.defaultProps = {
  conversationContext: {},
};

export default ChatHeader;
