import React from 'react';
import {
  Box, IconButton, Typography, Stack,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { IonIcon } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import PropTypes from 'prop-types';
// Has dismissable icon
const CardAtom = ({
  img,
  body,
  header,
  backgroundImage,
  containerStyles,
  imgStyles,
  headerStyles,
  bodyStyles,
  callback,
  hasDismissableIcon,
  dismissCallback,
}) => (
  <Box
    sx={{
      p: 2,
      bgcolor: !backgroundImage && '#063D8F',
      color: '#fff',
      borderRadius: 2,
      ...(backgroundImage && {
        background: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }),
      ...containerStyles,
    }}
    onClick={callback}
  >
    {dismissCallback && (
      <Box sx={{
        display: 'flex', justifyContent: 'end', mt: -2, mr: -2,
      }}
      >
        {hasDismissableIcon ? (
          <IconButton size="small" onClick={dismissCallback}>
            <Close />
          </IconButton>
        ) : null }
      </Box>
    )}
    <Stack spacing={2} direction="row" alignItems="center">
      {img && (
        <img
          className="card-leading-icon"
          src={img}
          style={imgStyles}
          alt="happy chat icons"
        />
      )}
      <div>
        {header && (
          <Typography
            component="h2"
            sx={{
              position: 'relative',
              fontSize: 16,
              fontWeight: 700,
              whiteSpace: 'nowrap',
              ...headerStyles,
            }}
          >
            {header}
            {callback && (
              <IonIcon
                style={{
                  position: 'relative',
                  top: '3px',
                  fontSize: '18px',
                  marginLeft: '8px',
                }}
                src={arrowForwardOutline}
              />
            )}
          </Typography>
        )}
        {body && (
          <Typography
            sx={{
              fontSize: 13,
              lineHeight: 1.25,
              fontWeight: 500,
              ...bodyStyles,
            }}
          >
            {body}
          </Typography>
        )}
      </div>
    </Stack>
  </Box>
);

CardAtom.propTypes = {
  img: PropTypes.string.isRequired,
  header: PropTypes.string,
  body: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  containerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  imgStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  headerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bodyStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  callback: PropTypes.func,
  hasDismissableIcon: PropTypes.bool,
  dismissCallback: PropTypes.func,
};

CardAtom.defaultProps = {
  header: null,
  backgroundImage: null,
  containerStyles: null,
  imgStyles: null,
  headerStyles: null,
  bodyStyles: null,
  callback: null,
  dismissCallback: null,
  hasDismissableIcon: true,
};

export default CardAtom;
