import React from 'react';
import ResourceLookupPage from 'pages/wholeme/molecules/resourceLookupPage/ResourceLookupPage';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';

const sponsorIntakePage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => (
  <ResourceLookupPage
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    wholeMeClient={wholeMeClient}
    categoryId="sponsors"
    questionId="sponsors"
    title="What brought you here?"
    subtitle={'An employer like McKinsey? An org like TFA? A site like Career Village? If you\'re not sure, type "other."'}
    dataFetcher={async () => (await wholeMeClient.fetchSponsorState())?.sponsors}
    dataSaver={wholeMeClient.saveSponsorState}
    resourceType="sponsors"
    resultAttributeId="sponsor"
    backgroundOverlay="/assets/profile/identity/ethnicity-overlay.svg"
    isSingleValue
    exitIconOnClickOverride={exitIconOnClickOverride}
    minQueryLength={0}
    selectionRequired
    freeFormDisabled
    queryOnInputFocus
  >
    <MainImage
      imageSrc="./assets/profile/sponsor/blue-building.svg"
      imageAlt="blue building"
    />
  </ResourceLookupPage>
);

export default sponsorIntakePage;
