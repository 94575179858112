// note - this has to be the same as pages/register/gender.js, as the backend expects these values!
// eslint-disable-next-line import/prefer-default-export
export const genderData = [
  {
    label: 'Male',
    value: 'Male',
  },
  { label: 'Female', value: 'Female' },
  { label: 'Non-binary', value: 'Non-binary' },
  { label: 'Other', value: 'Other' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
];
