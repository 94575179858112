import React from 'react';
import PropTypes from 'prop-types';
import Post from './Post';
import './Feed.css';

const Feed = ({
  posts, setPosts, onChangeCallBack, setSelectedFilter, filterMeta,
}) => (
  <div className="posts-container">
    {posts && posts.map((post) => (
      <Post
        key={post.id}
        post={post}
        setPosts={setPosts}
        navigateToGroup={onChangeCallBack}
        setSelectedFilter={setSelectedFilter}
        filterMeta={filterMeta}
      />
    ))}
  </div>
);

Feed.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  setPosts: PropTypes.func.isRequired,
};

export default Feed;
