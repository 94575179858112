export const itemContainerStyle = (type) => ({
  textAlign: 'center',
  alignItems: 'flex-start',
  borderRadius: '10px',
  maxWidth: '115px',
  width: '115px',
  maxHeight: '95px',
  height: '95px',
  px: '10px',
  pt: '10px',
  pb: '0px',
  display: 'flex',
  position: 'relative',
  backgroundColor: '#FFFFF',
  ...(type === 'finances_xp' && {
    backgroundColor: '#F7AE41',
  }),
  ...(type === 'education_xp' && {
    backgroundColor: '#063D8F',
  }),
  ...(type === 'career_xp' && {
    backgroundColor: '#367FFF',
  }),
  ...(type === 'relationships_xp' && {
    backgroundColor: '#F66324',
  }),
  ...(type === 'health_xp' && {
    backgroundColor: '#2CB669',
  }),
});

export const imageContainerStyle = {
  width: '15px',
  height: '15px',
  position: 'absolute',
  left: 5,
  bottom: 38,
  flexGrow: 1,
};
export const titleContainerStyle = {
  whiteSpace: 'normal',
  position: 'absolute',
  textAlign: 'right',
  right: 5,
  top: 10,
  wordBreak: 'normal',
  flexGrow: 3,
};
export const titleStyle = {
  fontSize: '0.9rem',
  color: '#ffff',
  fontWeight: 700,
  lineHeight: 1,
  pr: 0.5,
  flexGrow: 1,
};
export const plusIconStyle = {
  mr: '5px',
};
