export const gridItemStyle = {
  display: 'flex',
  justifyContent: 'center',
};

export const proposedButtonStyles = {
  backgroundColor: '#F7AE41',
  color: 'white',
  cursor: 'default',
  '&:hover': {
    backgroundColor: '#F7AE41',
  },
  '&:focus': {
    backgroundColor: '#F7AE41',
  },
};

export const backingButtonStyles = {
  backgroundColor: '#2CB669',
  color: 'white',
  cursor: 'default',
  '&:hover': {
    backgroundColor: '#2CB669',
  },
  '&:focus': {
    backgroundColor: '#2CB669',
  },
};

export const hoverButtonStyle = {
  '&:hover': {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
  },
};
