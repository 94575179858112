import React, { useState } from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import BottomDrawer from 'common/molecules/BottomDrawer/BottomDrawer';
import InputField from 'common/atoms/inputField/InputField';
import {
  multiSelectDataFetchTransformer,
  multiSelectDataSaverTransformer,
} from '../../util/profile_util';
import ResourceFetchingSearchInputField from '../searchInputField/ResourceFetchingSearchInputField';

const MultiSelectDrawerPage = ({
  fieldData,
  paginationMeta,
  nextOnClick,
  prevOnClick,
  pageTitle,
  pageSubtitle,
  categoryId,
  questionId,
  selectableItemVariant,
  fetcher,
  saver,
  selectionType,
  backgroundOverlay,
  drawerTriggerValue,
  drawerTitle,
  inputFieldPlaceholder,
  inputIcon,
  isSearchInputField,
  searchOptionsFetcher,
  searchOptionsResourceType,
  searchOptionsResultAttributeId,
  isSearchFieldSingleValue,
  searchIconOverride,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const [drawerValue, setDrawerValue] = useState('');

  const dataFetcher = async () => {
    const state = await fetcher();
    const xpState = state[questionId] || [];
    const fetchedDrawerState = xpState.find((n) => n.suboption_id === drawerTriggerValue);
    state[questionId] = xpState.filter((n) => n.suboption_id !== drawerTriggerValue);

    const transformed = multiSelectDataFetchTransformer(state);
    setDrawerValue(fetchedDrawerState?.value);
    return transformed;
  };

  const dataSaver = async (data) => {
    const transformed = multiSelectDataSaverTransformer(data);
    const drawerTriggerSelected = !!transformed.find((n) => n.option_id === drawerTriggerValue);
    if (drawerTriggerSelected) {
      transformed.push({
        category_id: categoryId,
        question_id: questionId,
        option_id: drawerTriggerValue,
        suboption_id: drawerTriggerValue,
        value: drawerValue,
      });
    }
    return saver(transformed);
  };

  const onSelection = (value, isSelected) => {
    if (value === drawerTriggerValue) {
      setIsDrawerOpen(isSelected);
    }
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      setIsDrawerOpen(false);
    }
  };

  return (
    <SimpleMultiSelectPage
      fields={fieldData}
      itemVariant={selectableItemVariant}
      paginationMeta={paginationMeta}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      title={pageTitle}
      subtitle={pageSubtitle}
      categoryId={categoryId}
      questionId={questionId}
      dataFetcher={dataFetcher}
      dataSaver={dataSaver}
      onSelection={onSelection}
      backgroundOverlay={backgroundOverlay}
      selectionType={selectionType}
    >
      <BottomDrawer
        title={drawerTitle}
        ctaLabel="Save"
        showBackdrop={false}
        open={isDrawerOpen}
        handleCTAClick={() => setIsDrawerOpen(false)}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <div style={{ height: '30vh' }}>
          {isSearchInputField
            ? (
              <ResourceFetchingSearchInputField
                isSingleValue={isSearchFieldSingleValue}
                iconOverride={searchIconOverride}
                selectedOptions={drawerValue ? [drawerValue] : []}
                setSelectedOptions={setDrawerValue}
                searchOptionsFetcher={searchOptionsFetcher}
                resourceType={searchOptionsResourceType}
                resultAttributeId={searchOptionsResultAttributeId}
              />
            )
            : (
              <InputField
                placeholder={inputFieldPlaceholder}
                icon={inputIcon}
                fieldValue={drawerValue}
                onChange={(e) => setDrawerValue(e.target.value)}
                onKeyUp={onKeyUp}
              />
            )}
        </div>
      </BottomDrawer>
    </SimpleMultiSelectPage>
  );
};

export default MultiSelectDrawerPage;
