import React, { useState, useEffect } from 'react';
import {
  Stack, Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import { IonIcon } from '@ionic/react';
import PropTypes from 'prop-types';
import GeneralTextCardAtom from '../../atoms/generalTextCardAtom/GeneralTextCardAtom';
import ProtegeInfo from '../../atoms/meetProteges/ProtegeInfo';
import { displayName, displayLocation } from '../../../helpers/textManipulation';
import { buildProtegeSchool } from './preview-utils';
import './MatchingProtegePreview.css';
import TruncateText from '../../../components/TruncateText';

const IonIconBase = React.forwardRef(({
  className, slot, icon, onClick,
}, ref) => (
  <IonIcon className={className} slot={slot} icon={icon} ref={ref} onClick={onClick} />
));

const infoIcon = 'assets/info-icon-navy.svg';
const infoIconWhite = 'assets/info-icon-navy-outline.svg';

const MatchingProtegePreview = ({
  user,
  aboutMe,
  selectedId,
  setSelectedProtege,
  isSelected: initializedSelectedState,
  handleAboutMeExpand,
}) => {
  const [isSelected, setIsSelected] = useState(!!initializedSelectedState);

  const handleClick = (e) => {
    e.stopPropagation();
    setIsSelected(!isSelected);
    handleAboutMeExpand(user?.user_id, isSelected);
  };

  useEffect(() => {
    setIsSelected(!!initializedSelectedState);
  }, [initializedSelectedState]);

  return (
    <Accordion
      expanded={isSelected}
      sx={{
        position: 'relative',
        bgcolor: '#fff',
        borderRadius: '8px !important',
        border: selectedId === true ? '3px solid #2CB669' : null,
      }}
      onClick={() => {
        setSelectedProtege(user?.match_result_id);
      }}
    >
      <AccordionSummary
        sx={{
          padding: '2px 14px',
          borderRadius: '8px !important',
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto !important',
          },
        }}
      >
        <IonIconBase
          slot="icon-only"
          className="preview-arrow"
          icon={isSelected ? infoIcon : infoIconWhite}
          onClick={(e) => handleClick(e)}
        />
        <Stack spacing={2}>
          <ProtegeInfo
            avatar={user?.profile_image}
            education={buildProtegeSchool(user?.grade, user?.school_type)}
            isCollegeStudent={user?.school_type === 'CO'}
            location={displayLocation(user?.city, user?.state)}
            name={displayName(user?.first_name, user?.last_name)}
            interests={user?.interests}
            small
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        style={{ borderRadius: '8px !important' }}
      >
        {isSelected && (
          <GeneralTextCardAtom
            title={aboutMe && 'About Me'}
            body={<TruncateText text={aboutMe !== null ? aboutMe : ''} maxChars={160} />}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

MatchingProtegePreview.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profile_image: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    school: PropTypes.string,
    school_type: PropTypes.string,
    grade: PropTypes.string,
    interests: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    match_result_id: PropTypes.number,
  }),
  goalBreakdown: PropTypes.shape({
    academic: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    passion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    career: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    health: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  aboutMe: PropTypes.string,
  selectedId: PropTypes.bool,
  setSelectedProtege: PropTypes.func,
};

MatchingProtegePreview.defaultProps = {
  user: null,
  aboutMe: '',
  goalBreakdown: null,
  selectedId: false,
  setSelectedProtege: () => { },
};

IonIconBase.propTypes = {
  className: PropTypes.string,
  slot: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

IonIconBase.defaultProps = {
  className: null,
  slot: null,
  onClick: () => { },
};

export default MatchingProtegePreview;
