import React from 'react';
import PropTypes from 'prop-types';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';

const ProfileIdentityIntakeSplashPage = ({ nextOnClick, prevOnClick }) => {
  const { platform } = useAppState();
  const splashImage = {
    src: './assets/profile/identity/big-mirror.svg',
    alt: 'big mirror',
    styles: {
      width: platform?.isNativePlatform ? '50%' : '35%',
    },
  };
  return (
    <ProfileIntakeSplashPage
      title="Identity"
      subtitle="Who are you? Share your personality with the community!"
      backgroundOverlay="/assets/profile/identity/splash-page-background-overlay.svg"
      splashImage={splashImage}
      nextButton={{ label: "Let's Go", onClick: nextOnClick }}
      prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    />
  );
};

export const ProfileIdentityIntakeSplashPagePropTypes = {
  nextOnClick: PropTypes.func.isRequired,
  prevOnClick: PropTypes.func.isRequired,
};

ProfileIdentityIntakeSplashPage.propTypes = ProfileIdentityIntakeSplashPagePropTypes;

export default ProfileIdentityIntakeSplashPage;
