import {
  body, bonfire, briefcase, calendarNumber, add, link,
  desktop, extensionPuzzle, football, library, people, person, phonePortrait, rocket, timer,
} from 'ionicons/icons';

export const possiblePublicInfo = {
  city: {
    icon: '/assets/bx_bxs-map.svg',
  },
  pronouns: {
    icon: person,
  },
  ethnicity: {
    icon: body,
  },
  age_group: {
    icon: timer,
  },
  job_title: {
    icon: briefcase,
  },
  school: {
    icon: library,
  },
  school_type: {
    icon: '/assets/ic_round-school.svg',
  },
  grade: {
    icon: timer,
  },
  user_bio: {
    icon: null,
  },
  first_name: {
    icon: person,
  },
  birthday: {
    icon: calendarNumber,
  },
  phone: {
    icon: phonePortrait,
  },
  email: {
    icon: desktop,
  },
  activities: {
    icon: football,
  },
  excited_age_group: {
    icon: people,
  },
  passions: {
    icon: bonfire,
  },
  aspirations: {
    icon: rocket,
  },
  excited: {
    icon: person,
  },
  job_experience: {
    icon: extensionPuzzle,
  },
  activations: {
    icon: extensionPuzzle,
  },
  anything_else: {
    icon: add,
  },
  social_media: {
    icon: link,
  },
};
