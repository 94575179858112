import React from 'react';
import { PurposeVideoIcon } from 'pages/wholeme/icons/Icons';
import WholeMeProfileSection from 'pages/wholeme/molecules/wholeMeProfileSection/WholeMeProfileSection';
import ShowView from './ShowView';
import EditView from './EditView';

const WholeMeProfilePurposeVideo = ({ editable, profile, wholeMeClient }) => {
  if (!editable) {
    return (
      <ShowView profile={profile} />
    );
  }
  return (
    <WholeMeProfileSection
      editable
      title="Intro Video"
      titleIcon={<PurposeVideoIcon />}
    >
      <EditView profile={profile} wholeMeClient={wholeMeClient} />
    </WholeMeProfileSection>
  );
};

export default WholeMeProfilePurposeVideo;
