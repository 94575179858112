import React, { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonTextarea,
  IonButton,
  IonCardSubtitle,
  IonItem,
  IonIcon,
  IonThumbnail,
  IonLabel,
} from '@ionic/react';
import {
  Box,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  Chip,
  MenuItem,
  Alert,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { close, closeOutline, videocam } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import useFetch from 'use-http';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import './CreatePost.css';
import { useAuthContext } from '../providers/auth-provider';
import { useAppState } from '../providers/app-state/app-state-provider';
import { displayErrorText, displayAttachmentLabel } from '../helpers/media';

/**
 * TODO: revisit targetDropdown.
 * Either totally remove it, or lock/disable the input with
 * the pre-selected target to match CREATE Post UI
 */
const hideTargetDropdown = true;

const possibleAsks = [
  {
    id: 1,
    name: 'recommendation',
    phrase: 'I need a recommendation',
  },
  {
    id: 2,
    name: 'connection',
    phrase: 'I need a connection',
  },
  {
    id: 3,
    name: 'resources',
    phrase: 'I need resources',
  },
  {
    id: 4,
    name: 'affirmation',
    phrase: 'I need affirmation',
  },
  {
    id: 5,
    name: 'celebration',
    phrase: 'I want to celebrate',
  },
  {
    id: 6,
    name: 'meeting',
    phrase: 'I need to meet with my team',
  },
];

const determineAttachmentType = (key) => {
  if (key?.startsWith('images')) {
    return 'image';
  }
  if (key?.startsWith('videos')) {
    return 'video';
  }
  if (key?.startsWith('pdfs')) {
    return 'pdf';
  }
  return null;
};

const initializeAttachmentState = (location) => {
  const {
    uri: webviewPath, id, s3_key: s3Key, name,
  } = location?.state?.attachments[0] || {};
  if (!id || !s3Key) {
    return null;
  }
  const type = determineAttachmentType(s3Key);
  return {
    webviewPath,
    id,
    type,
    name,
  };
};

const EditPost = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const { platform: { isNativePlatform } } = useAppState();
  const {
    checkPermissions,
    requestPermissions,
    takePhoto,
    takeVideo,
    takeWebVideo,
    takePdf,
    loading,
    error,
    controller,
  } = usePhotoGallery();
  const [attachment, setAttachment] = useState(initializeAttachmentState(location));
  const [open, setOpen] = useState(false);
  const [permissionsError, setPermissionsError] = useState(false);
  const [showPermissionsAlert, setShowPermissionsAlert] = useState(false);
  const [relationships, setRelationships] = useState([]);
  const [postVisibility, setPostVisibility] = useState(location.state?.user_id);
  const [currentAsk, setAsk] = useState(location.state?.question_id || null);
  const [confirmedAsk, setConfirmedAsk] = useState(
    location.state?.question_id || null,
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm();
  const history = useHistory();
  const { put, get, delete: del } = useFetch();

  const fetchRelationships = async () => {
    if (user.role === 'backr') {
      const userRelationships = await get(`/users/${user.id}/relationships`);
      setRelationships(userRelationships);
    } else {
      setRelationships(null);
    }
  };

  const handleAsk = (event) => {
    setAsk(event.target.value);
  };

  const resetAsk = () => {
    setAsk(null);
    setConfirmedAsk(null);
  };

  const handleAskConfirm = () => {
    setConfirmedAsk(currentAsk);
    setOpen(false);
  };

  const handleClose = () => {
    if (confirmedAsk !== currentAsk) {
      setAsk(null);
    }
    setOpen(false);
  };

  const handlePhoto = async () => {
    if (loading) return;
    const permissions = await checkPermissions();
    if (!permissions) {
      const updatedPermissions = await requestPermissions();
      if (
        (updatedPermissions.photos === 'denied'
          || updatedPermissions.camera === 'denied')
        && permissionsError
      ) {
        setShowPermissionsAlert(true);
        return;
      }
      if (
        updatedPermissions.photos === 'denied'
        || updatedPermissions.camera === 'denied'
      ) {
        setPermissionsError(true);
        return;
      }
    }
    try {
      const { media, webviewPath } = await takePhoto();
      if (media) {
        setAttachment({ webviewPath, id: media.id, type: 'photo' });
      }
      setPermissionsError(false);
    } catch (e) {
      //
    }
  };

  const handleWebVideo = async () => {
    const { media } = await takeWebVideo();
    if (media) {
      setAttachment({ webviewPath: media.uri, id: media.id, type: 'video' });
    }
  };

  const handleMobileVideo = async () => {
    const { media, webviewPath } = await takeVideo();
    if (media) {
      setAttachment({ webviewPath, id: media.id, type: 'video' });
    }
  };

  const handleVideo = async () => {
    if (loading) return;
    const permissions = await checkPermissions();
    if (!permissions) {
      const updatedPermissions = await requestPermissions();
      if (
        (updatedPermissions.photos === 'denied'
          || updatedPermissions.camera === 'denied')
        && permissionsError
      ) {
        setShowPermissionsAlert(true);
        return;
      }
      if (
        updatedPermissions.photos === 'denied'
        || updatedPermissions.camera === 'denied'
      ) {
        setPermissionsError(true);
        return;
      }
    }

    const uploader = isNativePlatform ? handleMobileVideo : handleWebVideo;
    try {
      await uploader();
      setPermissionsError(false);
    } catch (e) {
      //
    }
  };

  const handlePdf = async () => {
    if (loading) {
      return;
    }
    try {
      const { webviewPath, media } = await takePdf();
      if (media) {
        setAttachment({ webviewPath, id: media.id, type: 'pdf' });
      }
    } catch (e) {
      //
    }
  };

  const handleMediaClose = () => {
    if (loading) controller.abort();
    setAttachment(null);
  };

  const handleVisibility = (event) => {
    setPostVisibility(event.target.value || null);
  };

  const onSubmit = async (data) => {
    if (isUpdating) return;
    let formData;

    if (attachment?.id !== location?.state?.attachments[0]?.id) {
      if (location?.state?.attachments[0]?.id) {
        await del(
          `/posts/${location.state.id}/attachments/${location.state.attachments[0].id}`,
        );
      }
      formData = {
        ...data,
        user_id: postVisibility,
        author_id: user.id,
        visibility: location.state.visibility,
        attachments: attachment?.id ? [attachment?.id] : [],
        question_id: confirmedAsk,
        group_ids: location.state.group_ids,
      };
    } else {
      formData = {
        ...data,
        user_id: postVisibility,
        author_id: user.id,
        visibility: location.state.visibility,
        question_id: confirmedAsk,
        group_ids: location.state.group_ids,
      };
    }

    setIsUpdating(true);
    await put(`/posts/${location.state.id}`, formData);
    history.goBack();
  };

  useEffect(() => {
    fetchRelationships();
    const fetchInitialPermissions = async () => {
      const permissions = await checkPermissions();
      setPermissionsError(!permissions);
    };
    fetchInitialPermissions();
  }, []);

  const renderTargetDropdown = user.role === 'backr' && relationships.length > 1 && !hideTargetDropdown;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          style={{
            '--background': 'var(--ion-background-color)',
            '--border-width': 0,
            paddingTop: '2rem',
            '--padding-start': '24px',
            '--padding-end': '24px',
            '--padding-top': '16px',
          }}
          className="create-post-toolbar"
        >
          <IonButtons slot="start">
            <IonBackButton
              text=""
              icon="/assets/back-arrow.svg"
              style={{
                '--icon-font-size': '1.25rem',
                '--icon-padding-start': '8px',
                '--icon-padding-end': '12px',
                '--icon-padding-top': '4px',
              }}
            />
          </IonButtons>
          {renderTargetDropdown && (
            <Select
              sx={{
                backgroundColor: 'white',
                height: '30px',
                width: '170px',
              }}
              className="team-selector"
              slot="end"
              value={postVisibility || ''}
              onChange={handleVisibility}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {relationships.map((relation) => (
                <MenuItem
                  key={relation.id}
                  value={relation.related_user_id}
                  disableRipple
                >
                  {`${relation.related_profile.first_name}'s Team`}
                </MenuItem>
              ))}
            </Select>
          )}
          <Chip
            slot="end"
            label="Update"
            sx={{
              height: 28,
              fontSize: 13,
              fontWeight: 700,
              background: '#03295f',
              color: '#fff',
              px: 1,
              ml: 1.5,
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={
              isUpdating
              || ((!isDirty || !isValid)
                && (attachment?.webviewPath === location?.state?.attachments[0]?.uri
                  || (!attachment?.webviewPath
                    && !location?.state?.attachments[0]?.uri))
                && location?.state?.question_id === confirmedAsk)
            }
          />
        </IonToolbar>
      </IonHeader>
      <Dialog
        open={showPermissionsAlert}
        onClose={() => setShowPermissionsAlert(false)}
      >
        <DialogTitle>Permissions Needed</DialogTitle>
        <DialogContent>
          Whoops! In order to upload a photo or video, you need to allow access
          to your photos and camera. Navigate to system settings to change photo
          and camera permissions.
        </DialogContent>
      </Dialog>
      <IonContent>
        <Box mx={3} mt={2}>
          <IonCard color="light" className="ion-card-margin">
            <form>
              <IonCardContent>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <IonTextarea
                      placeholder={`What's happening, @${user.first_name}`}
                      onIonChange={onChange}
                      value={value}
                      rows={17}
                      style={{
                        '--placeholder-color': '#727272',
                        '--placeholder-font-weight': 'bold',
                        fontSize: '1.125rem',
                      }}
                      autocapitalize="on"
                    />
                  )}
                  control={control}
                  name="body"
                  defaultValue={location.state?.body}
                  rules={{ required: !attachment?.webviewPath }}
                />
                {error && (
                  <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
                    {displayErrorText(error)}
                  </Alert>
                )}
                {(attachment?.webviewPath || loading) && !error && (
                  <IonItem
                    detail={false}
                    lines="none"
                    className="post-option-item post-media-preview"
                  >
                    {loading && <CircularProgress size={40} sx={{ mr: 2.5 }} />}
                    {!loading && attachment?.type === 'photo' && attachment?.webviewPath && (
                      <IonThumbnail
                        slot="start"
                        className="post-photo-thumbnail"
                      >
                        <img src={attachment?.webviewPath} alt="current post" />
                      </IonThumbnail>
                    )}
                    {!loading && attachment?.type === 'video' && attachment?.webviewPath && (
                      <IonIcon
                        style={{ marginRight: '1rem', color: 'inherit' }}
                        icon={videocam}
                      />
                    )}
                    <IonLabel>{displayAttachmentLabel(loading, attachment)}</IonLabel>
                    <IonButton slot="end" onClick={() => handleMediaClose()}>
                      <IonIcon slot="icon-only" icon={closeOutline} />
                    </IonButton>
                  </IonItem>
                )}
                <Stack direction="row" spacing={2}>
                  <Chip
                    label="+ Add Ask"
                    onClick={() => setOpen(true)}
                    sx={{
                      fontSize: '0.625rem',
                      fontWeight: 700,
                      backgroundColor: '#03295F',
                      color: '#fff',
                      textTransform: 'capitalize',
                      fontFamily: '"Sarabun", arial, sans-serif',
                    }}
                  />
                  {confirmedAsk ? (
                    <Chip
                      key={currentAsk}
                      label={possibleAsks[currentAsk - 1].name}
                      onDelete={resetAsk}
                      sx={{
                        fontSize: '0.625rem',
                        fontWeight: 700,
                        backgroundColor: '#e8e8dc',
                        color: '#2d2d2d',
                        textTransform: 'capitalize',
                        fontFamily: '"Sarabun", arial, sans-serif',
                      }}
                    />
                  ) : null}
                </Stack>
                <hr className="form-divider" />
                <Stack spacing={1}>
                  <IonCardSubtitle className="add-to-post-title">
                    Add to post
                  </IonCardSubtitle>
                  <IonItem
                    button
                    detail={false}
                    lines="none"
                    className="post-option-item"
                    onClick={handlePhoto}
                  >
                    <IonIcon
                      className="post-option-icon"
                      src="assets/icon/addPhoto.svg"
                    />
                    <h2 className="post-option-text">Photo</h2>
                  </IonItem>
                  <IonItem
                    button
                    detail={false}
                    lines="none"
                    className="post-option-item"
                    onClick={handleVideo}
                  >
                    <IonIcon
                      className="post-option-icon"
                      src="assets/icon/file-video.svg"
                    />
                    <h2 className="post-option-text">Video</h2>
                  </IonItem>
                  <IonItem
                    button
                    detail={false}
                    lines="none"
                    className="post-option-item"
                    onClick={handlePdf}
                  >
                    <IonIcon className="post-option-icon" src="assets/icon/file-pdf.svg" />
                    <h2 className="post-option-text">Share a file</h2>
                  </IonItem>
                </Stack>
              </IonCardContent>
            </form>
          </IonCard>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '0.5rem',
              right: '0.5rem',
            }}
          >
            <IonIcon slot="icon-only" icon={close} />
          </IconButton>
          <DialogTitle sx={{ fontSize: 18, mt: 4 }}>
            What do you need help with?
          </DialogTitle>
          <DialogContent>
            <RadioGroup value={currentAsk} onChange={handleAsk} sx={{ mb: 2 }}>
              {possibleAsks.map((ask) => (
                <FormControlLabel
                  key={ask.id}
                  value={ask.id}
                  control={<Radio />}
                  label={(
                    <Typography sx={{ fontWeight: 500 }}>
                      {ask.phrase}
                    </Typography>
                  )}
                />
              ))}
            </RadioGroup>
            <IonButton onClick={handleAskConfirm} expand="block" shape="round">
              Add to Post
            </IonButton>
          </DialogContent>
        </Dialog>
      </IonContent>
    </IonPage>
  );
};

export default EditPost;
