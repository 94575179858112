import React from 'react';
import { useHistory } from 'react-router-dom';
import VideoPlayer from 'components/VideoPlayer';
import {
  Stack,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { IonButton } from '@ionic/react';
import { useAuthContext } from '../../../providers/auth-provider';

const BackrVideoAtom = ({ videoURI, videoThumbnail, btnLabel }) => {
  const { user } = useAuthContext();
  const history = useHistory();

  const goToAccountCreation = () => {
    history.push('/register/register/backr');
  };

  return (

    <Box
      className="video-player"
      style={{
        width: '90%',
        paddingLeft: '2vw',
        marginLeft: '4vw',
      }}
    >
      <Stack>
        <VideoPlayer
          style={{ paddingBottom: '1rem' }}
          className="video-player"
          videos={[
            {
              uri: videoURI,
              thumbnail: videoThumbnail,
            },
          ]}
        />
        {!user
          ? (
            <IonButton
              onClick={goToAccountCreation}
              style={{
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                fontWeight: 700,
                fontSize: '20px',
                marginTop: '20px',
              }}
              shape="round"
              size="large"
              expand="block"
              mode="ios"
            >
              {btnLabel}
            </IonButton>
          ) : null }
      </Stack>
    </Box>
  );
};

BackrVideoAtom.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  videoURI: PropTypes.string.isRequired,
  videoThumbnail: PropTypes.string.isRequired,
};

export default BackrVideoAtom;
