import {
  backingButtonStyles,
  proposedButtonStyles,
} from './ActionsGridStyles';
import { elementButtonStyleOverride } from '../../molecules/Profile/ProfileActionButtonStyles';
import appStrings from '../../app_strings';

const {
  profile:
  {
    actionButtons: {
      back, backing, proposed,
    },
  },
} = appStrings;

export const getBackButtonLabel = (subjectProfile) => {
  if (subjectProfile?.role === 'backr') {
    return null;
  }
  switch (subjectProfile?.role === 'protege' && subjectProfile?.relationshipStatus) {
    case 'backing':
      return backing;
    case 'proposed':
      return proposed;
    default:
      return back;
  }
};

export const getButtonStyles = (label) => {
  switch (label) {
    case 'Backing':
      return backingButtonStyles;
    case 'Proposed':
      return proposedButtonStyles;
    case null:
      return elementButtonStyleOverride;
    default:
      return {};
  }
};
