import React, { useState, useEffect } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { TextField } from '@mui/material';
import { arrowForwardOutline } from 'ionicons/icons';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { useAuthContext } from '../../providers/auth-provider';
import { ValidationError } from '../../helpers/errors';
import { storeToken } from '../../helpers/tokenHelpers';

const ResetPasswordPage = () => {
  const query = useQuery();
  const history = useHistory();
  const { changePassword, tryAutoLogin, isAuthenticated } = useAuthContext();

  const [password, setPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [
    confirmPasswordErrorMessage,
    setConfirmPasswordErrorMessage,
  ] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/tabs/feed');
    }
  }, [isAuthenticated]);

  const doReset = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await changePassword(password, confirmPassword, query.get('token'));
      setLoading(false);
      toast('Password has been reset!');
      storeToken(query.get('token'));

      await tryAutoLogin();
    } catch (e) {
      if (e instanceof ValidationError) {
        if (e.errors.password) {
          setPasswordErrorMessage(e.errors.password);
        }
        if (e.errors.confirmPassword) {
          setConfirmPasswordErrorMessage(e.errors.confirmPassword);
        }
        setLoading(false);
      }
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <img
                className="centered logo main-logo"
                src="/assets/logo_circle.png"
                alt="Backrs Logo"
                width="74"
                height="78  "
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h3 style={{ textAlign: 'center' }} className="login-greeting">
                Change your password
              </h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                fullWidth
                placeholder="New Password"
                value={password}
                size="small"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => e.key === 'Enter' && doReset()}
                error={!!passwordErrorMessage}
                helperText={passwordErrorMessage}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                fullWidth
                placeholder="Confirm New Password"
                value={confirmPassword}
                size="small"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyUp={(e) => e.key === 'Enter' && doReset()}
                error={!!confirmPasswordErrorMessage}
                helperText={confirmPasswordErrorMessage}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <img
                src="/assets/login.svg"
                alt="Login"
                className="centered logo"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                size="large"
                expand="block"
                shape="round"
                onClick={doReset}
              >
                {loading ? (
                  <IonSpinner name="crescent" />
                ) : (
                  <>
                    Reset
                    <IonIcon icon={arrowForwardOutline} />
                  </>
                )}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPasswordPage;
