import React, { useEffect, useState } from 'react';
import InputField from 'common/atoms/inputField/InputField';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import ProfileInputPage from 'pages/wholeme/molecules/inputPage/ProfileInputPage';

const iconOverrideStyles = {
  position: 'relative',
  fontSize: '24px',
};

const imageStylesOverride = {
  margin: '0',
  width: '100%',
  paddingLeft: '20px',
};

const ProfileCareerIntakeOccupationPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => {
  const [occupationState, setOccupationState] = useState();

  useEffect(async () => {
    const state = (await wholeMeClient.fetchOccupationState())?.job_title || [];
    setOccupationState(state[0]?.value || '');
  }, []);

  const saveOccupation = () => {
    wholeMeClient
      .saveOccupationState([
        {
          category_id: 'career',
          question_id: 'job_title',
          value: occupationState,
        },
      ])
      .then(() => {
        nextOnClick();
      });
  };
  const mainImageData = {
    imageSrc: './assets/profile/career/occupation-page-2.svg',
    imageAlt: 'a stapler and a lamp',
  };

  return (
    <ProfileInputPage
      paginationMeta={paginationMeta}
      nextOnClick={saveOccupation}
      prevOnClick={prevOnClick}
      wholeMeClient={wholeMeClient}
      title="Occupation"
      subtitle="Share your current role or job to help proteges match with backrs in their fields of interest."
      backgroundOverlay="assets/profile/education/occupation-overlay.svg"
    >
      <InputField
        onChange={(e) => setOccupationState(e.target.value)}
        placeholder="Job or role"
        fieldValue={occupationState}
        icon="occupation"
        iconStyleOverride={iconOverrideStyles}
        theme="light"
      />
      <MainImage
        imageSrc={mainImageData.imageSrc}
        imageAlt={mainImageData.imageAlt}
        imageStylesOverride={imageStylesOverride}
      />
    </ProfileInputPage>
  );
};

export default ProfileCareerIntakeOccupationPage;
