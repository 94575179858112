export const carouselContainerStyles = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  gap: '20px',
  padding: '56px',
  marginBottom: '48px',
  boxSizing: 'border-box',
};

export const navBtnStyles = {
  background: '#fff',
  borderRadius: '18px',
  '&:hover': {
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
};

export const navLeftStyles = {
  position: 'relative',
  left: '8px',
  fontSize: '40px',
};

export const navRightStyles = {
  fontSize: '40px',
};

export const swiperSlideStyles = {
  padding: '24px',
};
