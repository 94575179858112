/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  deeplinkUrl: null,
  oneTimeScreen: null,
  initialLocation: null,
  hasSeenYoureIn: null,
  hasSeenReadyToBack: null,
  appStoreUrl: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateDeeplinkUrl: (state, { payload }) => {
      state.deeplinkUrl = payload;
    },
    setOneTimeScreen: (state, { payload }) => {
      state.oneTimeScreen = payload;
    },
    setInitialLocation: (state, { payload }) => {
      state.initialLocation = payload;
    },
    setHasSeenYoureIn: (state, { payload }) => {
      state.hasSeenYoureIn = payload;
    },
    setHasSeenReadyToBack: (state, { payload }) => {
      state.hasSeenReadyToBack = payload;
    },
    setAppStoreUrl: (state, { payload }) => {
      state.appStoreUrl = payload;
    },
    handleSessionLogoutState: (state) => {
      state.oneTimeScreen = null;
      state.initialLocation = null;
      state.hasSeenYoureIn = null;
      state.hasSeenReadyToBack = null;
    },
  },
});

export const {
  updateDeeplinkUrl,
  setOneTimeScreen,
  setInitialLocation,
  setHasSeenReadyToBack,
  setHasSeenYoureIn,
  setAppStoreUrl,
  handleSessionLogoutState,
} = sessionSlice.actions;

export const sessionSelector = (state) => state.session;

export default sessionSlice.reducer;
