import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import appStateReducer from './app-state-reducer';
import * as actionCreators from './app-state-actions';
import { useAuthContext } from '../auth-provider';
import useFetchFromPath from '../../hooks/useFetchFromPath';
import useInterval from '../../hooks/useInterval';
import { convertToMills } from '../../helpers/dateTime';
import useTwilio from '../../hooks/useTwilio';

export const AppStateContext = createContext(null);
export const AppStateDispatchContext = createContext(null);
export const actions = actionCreators;
const POLL_DELAY = convertToMills(10, 'minutes');
const UNREAD_MESSAGES_POLL_DELAY = convertToMills(5, 'minutes');

const initialAppState = {
  tour: {
    isOpen: false,
  },
  user: {
    postTargets: null,
    matchRun: null,
  },
  conversations: {
    unreadMessagesCount: null,
  },
  platform: {
    isNativePlatform: Capacitor.isNativePlatform(),
  },
};

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    appStateReducer,
    initialAppState,
  );
  const { user } = useAuthContext();

  const {
    results: postTargets,
    loading: targetsLoading,
    refresh: refreshTargets,
  } = useFetchFromPath({ user, route: '/posts/targets' });

  const {
    results: unreadMessagesCount,
    loading: unreadMessagesCountLoading,
    refresh: refreshUnreadMessagesCount,
  } = useFetchFromPath({ user, route: '/conversations/unread-count' });

  const [pollDelay, setPollDelay] = useState(null); // value is a number in milliseconds
  const [unreadMessagesPollDelay, setUnreadMessagesPollDelay] = useState(null); // value is a number in milliseconds
  const unreadMessagesLoadingRef = useRef(null);

  useTwilio({
    onParticipantUpdated: () => refreshUnreadMessagesCount(),
    tag: 'app-state-provider',
    disabled: user?.role === 'admin',
  });

  const location = useLocation();
  useEffect(() => {
    if (location?.pathname === '/tabs/feed') {
      refreshUnreadMessagesCount();
    }
  }, [location]);

  useEffect(() => {
    unreadMessagesLoadingRef.current = unreadMessagesCountLoading;
  }, [unreadMessagesCountLoading]);

  const pollTargets = useCallback(async () => {
    if (!targetsLoading) {
      refreshTargets();
    }
  }, [user]);
  useInterval(pollTargets, pollDelay);

  const pollUnreadMessageCount = useCallback(async () => {
    if (!unreadMessagesLoadingRef.current) {
      console.log('refreshing unread messages count');
      refreshUnreadMessagesCount();
    }
  }, [user, refreshUnreadMessagesCount]);
  useInterval(pollUnreadMessageCount, unreadMessagesPollDelay);

  React.useEffect(() => {
    if (postTargets) {
      dispatch(actions.setPostTargets(postTargets));
    }
  }, [postTargets]);

  React.useEffect(() => {
    if (unreadMessagesCount && !unreadMessagesCountLoading) {
      dispatch(actions.setUnreadMessagesCount(unreadMessagesCount.unreadCount));
    }
  }, [unreadMessagesCount, unreadMessagesCountLoading]);

  React.useEffect(() => {
    if (user) {
      setPollDelay(POLL_DELAY);
      setUnreadMessagesPollDelay(UNREAD_MESSAGES_POLL_DELAY);
    } else {
      dispatch(actions.resetUserState(initialAppState.user));
    }

    return () => {
      dispatch(actions.resetUserState(initialAppState.user));
      setPollDelay(null);
      setUnreadMessagesPollDelay(null);
    };
  }, [user]);

  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export function useAppState() {
  return useContext(AppStateContext);
}

export function useAppStateDispatch() {
  return useContext(AppStateDispatchContext);
}
