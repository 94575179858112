/* eslint-disable no-await-in-loop */
import { useState, useRef } from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import useFetch from 'use-http';
import axios from 'axios';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import * as Sentry from '@sentry/capacitor';
import userServiceLogger from './userServiceLogger';
import { handleErrorSideEffects, shouldSetError } from './helpers/usePhotoGallery_helpers';

const maxImageAxisPixelValue = 4000;
const maxPdfFileSize = 1073741824;

const dataURItoBlob = (dataURI, type = 'application/pdf') => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type });
  return blob;
};

export function usePhotoGallery() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { post } = useFetch();
  const controllerRef = useRef(new AbortController());
  const { serviceLogger } = userServiceLogger();

  const saveBlobAsMedia = async (blob, type = 'image') => {
    let slicedBlob;
    setLoading(true);
    setError(false);
    const timeStamp = new Date().getTime();

    if (type) {
      slicedBlob = blob.slice(0, blob.size);
    }

    try {
      const maxAttempts = 3;
      let attempts = 1;
      let mediaResponse = null;

      while (attempts <= maxAttempts) {
        try {
          if (type === 'pdf' && blob?.size > maxPdfFileSize) {
            throw new Error('Pdf file size too large');
          }
          const mediaPayload = type === 'pdf' ? { type, name: blob?.name, size: blob?.size } : { type };
          const contentType = type === 'pdf' ? 'application/pdf' : `${type}/*`;
          mediaResponse = await post('/media', mediaPayload);
          if (mediaResponse?.message === 'User has exceeded total pdf storage') {
            throw new Error(mediaResponse.message);
          }
          await axios.put(mediaResponse.upload_url, slicedBlob, {
            headers: {
              'Content-Type': contentType,
            },
            signal: controllerRef.current.signal,
          });

          break;
        } catch (e) {
          if (attempts === maxAttempts) {
            Sentry.captureMessage(`Failed @ ${timeStamp} - Attempts: ${attempts}. ${type} upload aborted.`);
            throw e;
          }

          Sentry.captureMessage(`Failed ${type} upload attempt @ ${timeStamp} - Attempt: ${attempts}.`);
          attempts += 1;
        }
      }

      setLoading(false);

      // return the webview path to display the image from memory
      // also return the media object so whatever is calling this code
      // can decide what to do with it (ie update user profile media id, attach it to posts... etc)
      return {
        media: mediaResponse,
      };
    } catch (e) {
      Sentry.captureException(e);
      await serviceLogger({
        type: 'error',
        header: `saveBlobAsMedia: @ ${timeStamp} FAILED`,
        key: 'usePhotoGallery',
        error: e,
      });
      if (e.message === 'User has exceeded total pdf storage' || e.message === 'Pdf file size too large') {
        setError(e);
      } else if (e.message !== 'canceled') {
        setError(true);
      }

      setLoading(false);
      return null;
    }
  };

  const saveMedia = async (p, type) => {
    let blob;

    // NOTE: type File also satisfies this condition
    if (p instanceof Blob) {
      blob = p;
    } else if (type === 'pdf') {
      // when attaching pdfs on native devices, create blob from dataURI
      blob = dataURItoBlob(p.data);
      blob.name = p?.name;
    } else {
      blob = await fetch(p).then((r) => r.blob());
    }

    const response = await saveBlobAsMedia(blob, type);

    return {
      webviewPath: p,
      media: response.media,
    };
  };

  const checkPermissions = async () => {
    const permissionStatus = await Camera.checkPermissions();
    return permissionStatus;
  };

  const requestPermissions = async () => {
    if (!Capacitor.isNativePlatform()) {
      return checkPermissions();
    }

    const permissionStatus = await Camera.requestPermissions();
    return permissionStatus;
  };

  const takePhoto = async (options = {
    suppressToasts: false,
    setMaxImageAxisPixelValue: false,
  }) => {
    setError(false);
    const imageAxisPixelValue = options?.setMaxImageAxisPixelValue ? maxImageAxisPixelValue : undefined;

    try {
      const p = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        quality: 100,
        height: imageAxisPixelValue,
        width: imageAxisPixelValue,
      });

      const { webviewPath, media } = await saveMedia(p.webPath, 'image');
      return { webviewPath, media };
    } catch (e) {
      handleErrorSideEffects(e, options?.suppressToasts);

      if (shouldSetError(e)) {
        Sentry.captureException(e);
        await serviceLogger({
          type: 'error',
          header: 'takePhoto: FAILED',
          key: 'usePhotoGallery',
          error: e,
        });
        setError(true);
      }
      throw e;
    }
  };

  const takeWebVideo = async () => {
    try {
      const result = await FilePicker.pickVideos({
        multiple: false,
      });
      const blob = result.files[0]?.blob;
      if (blob) {
        return await saveMedia(blob, 'video');
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  const takeVideo = async () => new Promise((resolve, reject) => {
    navigator.camera.getPicture(
      async (data) => {
        try {
          const url = Capacitor.convertFileSrc(data);
          const out = await saveMedia(url, 'video');
          resolve(out);
        } catch (e) {
          reject(e);
        }
      },
      (err) => {
        reject(err);
      },
      {
        destinationType: navigator.camera.DestinationType.NATIVE_URI,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
        mediaType: navigator.camera.MediaType.VIDEO,
      },
    );
  });

  const takePdf = async () => {
    const result = await FilePicker.pickFiles({
      types: ['application/pdf'],
      multiple: false,
      readData: true,
    });
    const file = result.files[0]?.blob || result.files[0];
    const uploadedMedia = await saveMedia(file, 'pdf');
    return uploadedMedia;
  };

  return {
    checkPermissions,
    requestPermissions,
    saveBlobAsMedia,
    takePhoto,
    takeVideo,
    loading,
    error,
    controller: controllerRef.current,
    takeWebVideo,
    takePdf,
  };
}

export async function base64FromPath(path) {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('method did not return a string');
      }
    };
    reader.readAsDataURL(blob);
  });
}
