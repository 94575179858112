import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';

export const registerNotifications = (history) => new Promise((resolve, reject) => {
  PushNotifications.removeAllListeners();
  PushNotifications.addListener('registration', (token) => {
    console.info('Registration token: ', token.value);
    resolve(token.value);
  });

  PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
    reject(err);
  });

  // fires only when notification is received while app is open
  // notification will not show in this case
  PushNotifications.addListener('pushNotificationReceived', () => {
    if (window.refreshNotificationCount) {
      window.refreshNotificationCount();
    }
  });

  // fires when the user taps on the notification
  PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    if (notification?.notification?.data?.to) {
      history.push(notification.notification.data.to);
    }
  });

  (async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      reject(new Error('User denied permissions!'));
    }
    await PushNotifications.register();
  })();
});

export const getDeviceUuid = async () => {
  const info = await Device.getId();
  return info;
};
