/* eslint-disable camelcase */
import React from 'react';
import {
  Avatar,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import {
  ProtegeIcon, BackrIcon, AdminRoleIcon, RoundEditIcon,
} from 'pages/wholeme/icons/Icons';
import {
  childrenContainerStyle,
  avatarStyle,
  profileContainerStyle,
  BPIconStyle,
  imageContainerStyle,
  AdminIconStyle,
} from './WholeMeAvatarStyles';
import ActionsGrid from '../../../../common/atoms/actionsGrid/ActionsGrid';
import ProfileOptionsMenu from '../profileOptionsMenu/ProfileOptionsMenu';

const RenderIcon = ({ role, editMeta }) => {
  if (editMeta?.onEdit) {
    return (
      <Box sx={{ cursor: 'pointer' }} onClick={() => editMeta?.setIsEditMode(true)}>
        <RoundEditIcon {...BPIconStyle} />
      </Box>
    );
  }
  if (role === 'protege') {
    return <ProtegeIcon {...BPIconStyle} />;
  }
  if (role === 'admin') {
    return <AdminRoleIcon {...AdminIconStyle} />;
  }
  return <BackrIcon {...BPIconStyle} />;
};

const WholeMeProfileAvatar = ({
  profile,
  permissions,
  editMeta,
  refreshProfile,
}) => {
  const {
    last_name: lastName,
    first_name: firstName,
    profile_image: profileImage,
    role: subjectRole,
  } = profile;
  const subjectIsAdmin = subjectRole === 'admin';
  const showProfileOptionsMenu = !editMeta?.onEdit;
  const defaultProfileImage = '/assets/profile/default-profile-image.svg';

  const RenderName = () => {
    if (subjectIsAdmin) { return `${firstName} ${lastName}`; }
    if (firstName && lastName) {
      return `${firstName} ${lastName.charAt(0)}.`;
    }
    return 'Me';
  };

  const nameStyle = {
    color: '#03295F',
    fontSize: '1.3rem',
    fontWeight: 700,
    paddingRight: showProfileOptionsMenu && '1rem',
  };

  return (
    <Stack sx={profileContainerStyle}>
      <Box sx={{ ...imageContainerStyle, ...childrenContainerStyle }}>
        <Box style={{ position: 'relative' }}>
          {subjectIsAdmin
            ? (
              <object
                type="image/svg+xml"
                data={profileImage}
                style={avatarStyle}
              >
                <img src={profileImage} alt="coach lubav hat" />
              </object>
            ) : (
              <Avatar aria-label="profile picture" src={profileImage} sx={avatarStyle}>
                <img style={avatarStyle} src={defaultProfileImage} alt="profile" />
              </Avatar>
            )}
          <RenderIcon role={subjectRole} editMeta={editMeta} />
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Box>
            <Typography style={nameStyle}>
              {RenderName()}
            </Typography>
            {showProfileOptionsMenu && <ProfileOptionsMenu profile={profile} />}
          </Box>
          <ActionsGrid
            profile={profile}
            permissions={permissions}
            refreshProfile={refreshProfile}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default WholeMeProfileAvatar;
