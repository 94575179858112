import React, { useEffect } from 'react';
import {
  IonContent, IonPage, IonSpinner,
} from '@ionic/react';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import useQuery from '../../hooks/useQuery';
import { useAuthContext } from '../../providers/auth-provider';

const VerifyEmailPage = () => {
  const query = useQuery();
  const history = useHistory();
  const { post, response } = useFetch();
  const { user, setUser } = useAuthContext();

  useEffect(() => {
    (async () => {
      await post(`/users/verify-email?${query.toString()}`);
      if (response.ok) {
        if (user) {
          const newUser = { ...user, email_verified: true };
          setUser(newUser);
          toast('Your email has been verified!');
          history.push('/tabs/feed');
        } else {
          toast('Your email has been verified! Please login to continue');
          history.push('/');
        }
      }
    })();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
        >
          <div>
            <div>
              Verifying email...
            </div>
            <div style={{ textAlign: 'center', margin: '10px' }}>
              <IonSpinner name="crescent" />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmailPage;
