import React from 'react';
import useFetch from 'use-http';

const useFetchFromPath = ({
  user = null,
  route = null,
  disabled = false,
}) => {
  const {
    get,
    error,
    loading,
    abort,
  } = useFetch();
  const [results, setResults] = React.useState(null);

  const fetchResults = async (path) => {
    const targets = await get(path);
    setResults(targets);
  };

  const refresh = () => fetchResults(route);

  React.useEffect(() => {
    if (user && route && !disabled) {
      fetchResults(route);
    }
    return () => abort();
  }, [user, route]);

  return {
    results,
    error,
    refresh,
    loading,
  };
};

export default useFetchFromPath;
