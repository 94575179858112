/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import SelectableItemsList from 'common/molecules/selectableItemsList/SelectableItemsList';
import BottomDrawer from 'common/molecules/BottomDrawer/BottomDrawer';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import SearchableDropdownInputField from 'pages/wholeme/molecules/searchInputField/SearchableDropdownInputField';
import { quizData } from './quizData';

const fieldsByValue = quizData.reduce((acc, next) => ({ ...acc, [next.value]: next }), {});

const ProfileIdentityIntakeQuizPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => {
  const [currentTopLevelCategory, setCurrentTopLevelCategory] = useState();
  const [quizFieldChoices, setQuizFieldChoices] = useState({});
  const [searchQuery, setSearchQuery] = useState({});
  const [selectedSubOptions, setSelectedSubOptions] = useState({});
  const [selectedTopLevelOptions, setSelectedTopLevelOptions] = useState([]);

  const keyStringSeparator = '##@@$$%%';
  const makeLabelsKey = (topLevelLabel, secondaryLabel) => `${topLevelLabel}${keyStringSeparator}${secondaryLabel}`;
  const getLabelsFromKey = (key) => key.split(keyStringSeparator);

  const currentTopLevelCategoryData = fieldsByValue[currentTopLevelCategory];

  useEffect(async () => {
    const fetchedData = await wholeMeClient.fetchQuizState();
    const fetchedPreviouslySelectedData = fetchedData?.quiz || [];
    const formattedPreviouslySelectedData = fetchedPreviouslySelectedData?.reduce((acc, option) => {
      const { option_id, suboption_id, value } = option;
      if (value) {
        const key = makeLabelsKey(option_id, suboption_id);
        acc[key] = acc[key] ? [...acc[key], value] : [value];
      }
      return acc;
    }, {});
    setQuizFieldChoices(formattedPreviouslySelectedData);
  }, []);

  useEffect(() => {
    if (quizFieldChoices) {
      const selectedTopLevelLabels = Object.entries(quizFieldChoices)
        .filter(([, selections]) => selections?.[0] !== '')
        .map((entry) => getLabelsFromKey(entry?.[0])?.[0]);
      setSelectedTopLevelOptions(selectedTopLevelLabels);
    }
  }, [quizFieldChoices]);

  const makeNewSelectedSubOptions = () => Object.entries(quizFieldChoices)
    .filter((choice) => choice?.[0]?.includes(currentTopLevelCategory))
    .reduce((acc, [label, choices]) => {
      const [, secondaryLabel] = getLabelsFromKey(label);
      acc[secondaryLabel] = choices;
      return acc;
    }, {});

  useEffect(() => {
    if (currentTopLevelCategory) {
      const newSelectedSubOptions = makeNewSelectedSubOptions();
      setSelectedSubOptions(newSelectedSubOptions);
    } else setSelectedSubOptions({});
  }, [quizFieldChoices, currentTopLevelCategory]);

  const handleSaveAndContinueClick = () => {
    const dbFormattedQuizData = Object.entries(quizFieldChoices)
      .map(([labelsKey, selections]) => selections.map((selection) => {
        if (!selection) return null;
        const [topLevelLabel, secondLevelLabel] = getLabelsFromKey(labelsKey);
        return ({
          category_id: 'identity',
          question_id: 'quiz',
          option_id: topLevelLabel,
          suboption_id: secondLevelLabel,
          value: selection,
        });
      }))
      .flat()
      .filter((i) => !!i);
    wholeMeClient.saveQuizState(dbFormattedQuizData).then(nextOnClick);
  };

  const createNewQuizFieldSubOptionChoices = () => Object.entries(selectedSubOptions)
    .reduce((acc, option) => {
      const [secondaryLabel, choices] = option;
      const key = makeLabelsKey(currentTopLevelCategory, secondaryLabel);
      acc[key] = choices?.length ? choices : null;
      return acc;
    }, {});

  const conditionallyDeleteCurrentTopLevelCategoryData = (choices) => (
    Object.fromEntries(
      Object.entries(choices).filter(([, options]) => options?.length),
    )
  );

  const handleDrawerSave = () => {
    const newQuizFieldSubOptionChoices = createNewQuizFieldSubOptionChoices();
    const newQuizFieldData = conditionallyDeleteCurrentTopLevelCategoryData({
      ...quizFieldChoices,
      ...newQuizFieldSubOptionChoices,
    });
    setQuizFieldChoices(newQuizFieldData);
    setCurrentTopLevelCategory('');
  };

  return (
    <>
      <ProfileIntakeStepPage
        paginationMeta={paginationMeta}
        title="We love a good quiz."
        subtitle="Have you taken any of these self-assessments at work or for fun? Let us know your results!"
        prevButton={{ onClick: prevOnClick }}
        nextButton={{ onClick: handleSaveAndContinueClick }}
        backgroundOverlay="/assets/profile/identity/personality-types-overlay.svg"
      >
        {quizFieldChoices === undefined
          ? <WholeMeLoadingCircle />
          : (
            <>
              <SelectableItemsList
                items={quizData}
                activeItems={selectedTopLevelOptions}
                activeItemsOverridesInternalSelection
                itemVariant="rectangle"
                isTwoColumns
                selectionType="multi"
                onSelection={(value) => setCurrentTopLevelCategory(value)}
              />
              <MainImage
                imageSrc="./assets/profile/identity/quiz-picture.svg"
                imageAlt="drama faces"
              />
            </>
          )}
      </ProfileIntakeStepPage>
      <BottomDrawer
        open={!!currentTopLevelCategory}
        title={currentTopLevelCategoryData?.drawerHeader}
        ctaLabel="Save"
        handleCTAClick={handleDrawerSave}
      >
        {
          currentTopLevelCategory ? (
            currentTopLevelCategoryData?.subQuestions.map((subQuestion) => {
              const secondaryLabel = subQuestion.label;
              const labelsKey = makeLabelsKey(currentTopLevelCategory, secondaryLabel);
              return (
                <SearchableDropdownInputField
                  options={subQuestion.options}
                  maxNumberOfChoices={subQuestion.maxNumberOfChoices}
                  iconOverride={subQuestion.inputIconImage}
                  inputPlaceholder={subQuestion.inputPlaceholder}
                  searchQuery={searchQuery[labelsKey] || ''}
                  setSearchQuery={(query) => setSearchQuery({
                    ...searchQuery,
                    [labelsKey]: query,
                  })}
                  selectedOptions={selectedSubOptions[secondaryLabel] || []}
                  setSelectedOptions={(options) => setSelectedSubOptions({
                    ...selectedSubOptions,
                    [secondaryLabel]: options,
                  })}
                />
              );
            }))
            : null
          }
      </BottomDrawer>
    </>
  );
};

export default ProfileIdentityIntakeQuizPage;
