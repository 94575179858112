import React, {
  useState,
  useEffect,
} from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FilterItem from '../filterItem/FilterItem';
import { LeftArrowFilterButton, RightArrowFilterButton } from './DesktopFilterArrows';
import { buildFilterItems } from '../filterBar/utils';
import { feedSelector } from '../../../redux/slices/feed';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './DesktopFilterBar.css';

const DesktopFilterBar = ({
  filterMeta,
  onChangeCallBack,
  disableClickEventsOverrideRef,
}) => {
  const { activeFilter } = useSelector(feedSelector);
  const [filterItems, setFilterItems] = useState([]);

  const handleOnChange = (filter) => {
    onChangeCallBack(filter);
  };

  useEffect(() => {
    const filters = buildFilterItems(
      filterMeta,
      handleOnChange,
      activeFilter.value,
    );
    setFilterItems(filters);
  }, [filterMeta, activeFilter.value]);

  return (
    <ScrollMenu LeftArrow={LeftArrowFilterButton} RightArrow={RightArrowFilterButton}>
      {filterItems.length && filterItems.map((f) => (
        <FilterItem
          key={f.filterMeta.value}
          filterMetaItem={f.filterMeta}
          isSelected={f.isSelected}
          onClickCallBack={f.onClickCallBack}
          size={f.size}
          disableClickEventsOverrideRef={disableClickEventsOverrideRef}
          buttonStyleOverride={{ mx: 1 }}
        />
      ))}
    </ScrollMenu>
  );
};

DesktopFilterBar.propTypes = {
  filterMeta: PropTypes.oneOfType([PropTypes.array]),
  onChangeCallBack: PropTypes.func,
  disableClickEventsOverrideRef: PropTypes.oneOfType([PropTypes.object]),
};

DesktopFilterBar.defaultProps = {
  filterMeta: null,
  onChangeCallBack: () => { },
  disableClickEventsOverrideRef: null,
};

export default DesktopFilterBar;
