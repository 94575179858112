/* eslint-disable no-nested-ternary */
import React from 'react';
import { styled } from '@mui/material/styles';
import { createEditHandler } from 'pages/wholeme/util/profile_util';
import { EditIcon, PlusIcon } from 'pages/wholeme/icons/Icons';

const FlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
});

const defaultStyles = {
  paddingBottom: '4px',
};

const StyledSpan = styled('span')({
  marginLeft: '4px',
  fontSize: '14px',
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '20px',
  width: '20px',
  minWidth: '20px',
});

const WholeMeProfileSectionRow = ({
  isCreate,
  onEdit,
  onPost,
  label,
  infoUrl,
  iconSrc,
  navigationMeta,
  labelStyle,
  styleOverrides = { ...defaultStyles },
  onClickOverride = null,
}) => {
  const normalOnClick = navigationMeta
    ? createEditHandler(onEdit, navigationMeta.categoryId, navigationMeta.stepId)
    : onPost
      ? () => onPost(label, infoUrl)
      : undefined;
  return (
    <FlexContainer
      onClick={onClickOverride || normalOnClick}
      style={styleOverrides}
    >
      {
      iconSrc
      && (
      <IconContainer>
        <object className="no-pointer-events" type="image/svg+xml" style={{ height: '14px', width: '14px', marginTop: '2px' }} data={iconSrc}>
          <img src={iconSrc} alt={iconSrc} />
        </object>
      </IconContainer>
      )
    }
      {iconSrc ? undefined : isCreate ? (
        <PlusIcon height={17} width={17} marginTop={2} />
      ) : (
        <EditIcon height={17} width={17} />
      )}
      {!!label && <StyledSpan style={{ ...labelStyle, color: !onEdit && '#5B5B5B' }}>{label}</StyledSpan>}
    </FlexContainer>
  );
};

export default WholeMeProfileSectionRow;
