import React from 'react';
import MultiSelectDrawerPage from 'pages/wholeme/molecules/multiSelectDrawerPage/MultiSelectDrawerPage';
import { fieldsFor } from '../../../util/profile_field_data';

const ProfileEducationIntakeXpPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => {
  const educationXPData = fieldsFor('education-education_xp');

  return (
    <MultiSelectDrawerPage
      fieldData={educationXPData}
      paginationMeta={paginationMeta}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      pageTitle="Education Experiences"
      pageSubtitle="Select any that apply to you."
      selectableItemVariant="rectangle"
      categoryId="education"
      questionId="education_xp"
      fetcher={wholeMeClient.fetchEducationXpState}
      saver={wholeMeClient.saveEducationXpState}
      backgroundOverlay="/assets/profile/education/education-experiences-overlay.svg"
      drawerTriggerValue="Greek system"
      drawerTitle="Enter Greek Organization"
      inputFieldPlaceholder="Type to add"
      inputIcon="greek"
      isSearchInputField
      searchOptionsFetcher={wholeMeClient.fetchResourceList}
      searchOptionsResourceType="greeks"
      searchOptionsResultAttributeId="greek"
      isSearchFieldSingleValue
      searchIconOverride="greek"
    />
  );
};

export default ProfileEducationIntakeXpPage;
