import React, { useState, useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Box, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import appStrings from '../../common/app_strings';
import GeneralTextCardAtom from '../../common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import CardAtom from '../../common/atoms/card/CardAtom';
import './ReadyToBack.css';
import { useAppState } from '../../providers/app-state/app-state-provider';
import { setHasSeenReadyToBack } from '../../redux/slices/session';

const {
  onboarding: { readyToBack },
} = appStrings;

const cardTitleStyle = () => {
  const style = {
    mb: '5px',
  };
  return style;
};

const cardBodyStyle = () => {
  const style = {
    fontWeight: 500,
    fontSize: '12.6px',
    width: '185px',
  };
  return style;
};

const ReadyToBack = ({ nextOnClick }) => {
  const history = useHistory();
  const [selectedCard, setSelectedCard] = useState(null);
  const { platform } = useAppState();
  const dispatch = useDispatch();

  const cardContainerStyle = (isSelected) => {
    const style = {
      background: '#03295F',
      p: '25px',
      border: isSelected ? '4px solid #F4F4EE' : 'none',
    };
    return style;
  };

  const backAProtege = () => {
    nextOnClick();
  };
  const backToFeed = () => {
    history.push('/tabs/feed');
  };

  const handleClick = (key) => {
    setSelectedCard(() => {
      const newSelectedCard = key;

      setTimeout(() => {
        if (newSelectedCard === readyToBack.backProtege) {
          backAProtege();
        } else {
          backToFeed();
        }
      }, 300);

      return newSelectedCard;
    });
  };

  useEffect(() => {
    dispatch(setHasSeenReadyToBack(true));
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="readyToBackCont">
        <Box
          sx={{
            px: '24px',
            pt: platform?.isNativePlatform ? '25%' : '10%',
          }}
        >
          <Stack
            sx={{
              px: '24px',
              mb: '60px',
              pt: '20px',
            }}
          >
            <GeneralTextCardAtom
              title={readyToBack.youreReady}
              titleStyles={{
                textAlign: 'center',
                color: '#FFFFFF',
                fontSize: '28px',
                fontWeight: 700,
              }}
            />
          </Stack>
          <Stack spacing={2.5}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pb: '2rem',
              }}
            >
              <img alt="three people" src="/assets/register/circle-three-people.svg" />
            </Box>
            <CardAtom
              img="/assets/register/mentor-a-student.svg"
              header={readyToBack.backProtege}
              body={readyToBack.startBacking}
              callback={() => handleClick(readyToBack.backProtege)}
              containerStyles={cardContainerStyle(selectedCard === readyToBack.backProtege)}
              bodyStyles={cardBodyStyle()}
              headerStyles={cardTitleStyle()}
            />
            <CardAtom
              img="/assets/register/question-answer-text-bubbles.svg"
              header={readyToBack.backInNetwork}
              body={readyToBack.affinityNetwork}
              callback={() => handleClick(readyToBack.backInNetwork)}
              containerStyles={cardContainerStyle(selectedCard === readyToBack.backInNetwork)}
              bodyStyles={cardBodyStyle()}
              headerStyles={cardTitleStyle()}
            />
          </Stack>
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default ReadyToBack;
