import React, { useEffect } from 'react';
// import Header from '../../components/Header';
import {
  IonContent, IonButton, IonIcon, IonLabel,
} from '@ionic/react';
import {
  Box, Stack, MenuItem, Typography,
} from '@mui/material';
import { arrowForwardOutline } from 'ionicons/icons';
import { useForm, Controller } from 'react-hook-form';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import { ValidationError } from '../../helpers/errors';
import { useAuthContext } from '../../providers/auth-provider';
import { excitedAgeGroups } from '../excited_age_groups';
import PageWithBackButton from '../../components/generic/PageWithBackButton';
import StyledInput, { TaupeTextField } from '../../components/StyledInput';
import './EditProfileInfo.css';

const EditAdditionalInfo = () => {
  const { user, refreshUser } = useAuthContext();
  const { put: editAdditionalInfo, response } = useFetch(`/users/${user?.id}`);
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const cleanedData = data;
      delete cleanedData.email_verified;
      const res = await editAdditionalInfo(cleanedData);
      if (!response.ok) {
        if (response.status === 400) {
          throw new ValidationError('Invalid inputs', res.errors);
        } else {
          throw new Error('Unknown Error');
        }
      }
      await refreshUser();
      history.push('/tabs/profile');
    } catch (e) {
      if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      }
    }
  };

  useEffect(() => {
    reset(user);
  }, [user]);

  return (
    <PageWithBackButton backButtonText="">
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            sx={{
              color: '#000',
              fontSize: 30,
              fontWeight: 700,
              textAlign: 'center',
              mt: 2,
            }}
          >
            Additional Info
          </Typography>
          <Box
            sx={{
              background: '#fff',
              p: 2,
              mx: 3,
              my: 3.5,
              borderRadius: 2,
            }}
          >
            <Stack spacing={1.5}>
              {user?.role === 'protege' ? (
                <>
                  <StyledInput
                    theme="taupe"
                    title="What activities, clubs, sports, or extracurriculars are you involved in?"
                    placeholder="Basketball, NHS, Band, etc."
                    control={control}
                    name="activities"
                    error={errors?.activities}
                    type="text"
                    textarea
                    position="stacked"
                    capitalize
                  />
                  <StyledInput
                    theme="taupe"
                    title="What activates you? What issues in the world are you passionate about?"
                    placeholder="Please enter what activates you."
                    control={control}
                    name="activations"
                    error={errors?.activations}
                    textarea
                    position="stacked"
                    capitalize
                  />
                </>
              ) : (
                <>
                  <div>
                    <IonLabel position="stacked" className="clr-charcoal">
                      What age group are you the most excited to back? *
                    </IonLabel>
                    <Controller
                      name="excited_age_group"
                      control={control}
                      defaultValue="No Preference"
                      render={({ field: { value, ...rest } }) => (
                        <TaupeTextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                          placeholder="Choose an option"
                          value={value || ''}
                          error={!!errors?.excited_age_group?.message}
                          helperText={errors?.excited_age_group?.message}
                          size="small"
                          fullWidth
                          select
                        >
                          {excitedAgeGroups.map((ageGroup) => (
                            <MenuItem
                              key={ageGroup.label}
                              value={ageGroup.value}
                            >
                              {ageGroup.label}
                            </MenuItem>
                          ))}
                        </TaupeTextField>
                      )}
                    />
                  </div>
                  <StyledInput
                    theme="taupe"
                    title="What job or career experience can you share?"
                    // eslint-disable-next-line max-len
                    placeholder="I was a copywriter for 12 years, and now I sell nutritional supplements for diabetic poodles."
                    control={control}
                    name="job_experience"
                    error={errors?.job_experience}
                    textarea
                    position="stacked"
                    capitalize
                  />
                </>
              )}
              <StyledInput
                theme="taupe"
                title="What are your interests, passions, and hobbies?"
                // eslint-disable-next-line max-len
                placeholder="I love tap dancing, cave diving, and spalunking, and I'm obsessed with the Real Housewives of Atlanta."
                control={control}
                name="passions"
                error={errors?.passions}
                textarea
                position="stacked"
                capitalize
              />
              <StyledInput
                theme="taupe"
                title={
                  user?.role === 'backr'
                    ? 'What are some of your personal and professional aspirations and goals?'
                    : 'What is a long term goal you are working towards, or would like to work towards?'
                }
                placeholder="I hope to have my own scuba outfitting business, eventually."
                control={control}
                name="aspirations"
                error={errors?.aspirations}
                textarea
                position="stacked"
                capitalize
              />
              {user?.role === 'protege' && (
                <StyledInput
                  theme="taupe"
                  title="Why are you excited to be part of this community?"
                  placeholder="I'm excited to join this community because..."
                  control={control}
                  name="excited"
                  error={errors?.excited}
                  textarea
                  position="stacked"
                  capitalize
                />
              )}
              <StyledInput
                theme="taupe"
                title={user?.role === 'protege' ? 'LinkedIn' : 'Social Media'}
                placeholder="copy and paste your social media URL here"
                control={control}
                name="social_media"
                error={errors?.social_media}
                position="stacked"
              />
            </Stack>
          </Box>
          <IonButton
            size="large"
            expand="block"
            shape="round"
            className="edit-info-submit-btn"
            type="submit"
          >
            Save Profile
            <IonIcon
              style={{
                position: 'relative',
                top: '2px',
                fontSize: '1.25rem',
                marginLeft: '4px',
              }}
              src={arrowForwardOutline}
            />
          </IonButton>
        </form>
      </IonContent>
    </PageWithBackButton>
  );
};

export default EditAdditionalInfo;
