import React from 'react';
import PropTypes from 'prop-types';
import NavControl from 'common/molecules/navControl/NavControl';
import ProfileIntakePage from '../profileIntakePage/ProfileIntakePage';

const ProfileIntakeStepPage = ({
  title,
  subtitle,
  nextButton,
  prevButton,
  paginationMeta,
  backgroundOverlay,
  children,
  NavComponent,
  hasLargeSubheader,
  titleStyleOverride,
  hasPersistentDrawer,
  noAutoMarginOnChildren,
  linkedTextUnderNavControl,
  childrenOverrideStyles,
  exitIconOnClickOverride,
}) => (
  <ProfileIntakePage
    paginationMeta={paginationMeta}
    backgroundColor="#F4F4EECC"
    backgroundOverlay={backgroundOverlay}
    title={title}
    subtitle={subtitle}
    hasLargeSubheader={hasLargeSubheader}
    topBackArrowOnClickHandler={prevButton.onClick}
    noAutoMarginOnChildren={noAutoMarginOnChildren}
    exitIconOnClickOverride={exitIconOnClickOverride}
    NavComponent={
      !hasPersistentDrawer && (NavComponent || (
        <NavControl
          mainButton={{ label: 'Save & Continue', ...nextButton, iconSrc: 'ion:arrow-forward' }}
        />
      ))
    }
    titleStyleOverride={titleStyleOverride}
    linkedTextUnderNavControl={linkedTextUnderNavControl}
    childrenOverrideStyles={childrenOverrideStyles}
  >
    {children}
  </ProfileIntakePage>
);

export const ProfileIntakeStepPagePropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  nextButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  prevButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  paginationMeta: PropTypes.shape({ current: PropTypes.number, total: PropTypes.number }),
  backgroundOverlay: PropTypes.string,
  children: PropTypes.node.isRequired,
  titleStyleOverride: PropTypes.oneOfType([PropTypes.object]),
};

export const ProfileIntakeStepPageDefaultProps = {
  title: '',
  subtitle: '',
  nextButton: { onClick: () => {} },
  prevButton: { label: '', onClick: () => {} },
  paginationMeta: { current: 0, total: 0 },
  backgroundOverlay: '',
  titleStyleOverride: {},
};

ProfileIntakeStepPage.propTypes = ProfileIntakeStepPagePropTypes;
ProfileIntakeStepPage.defaultProps = ProfileIntakeStepPageDefaultProps;

export default ProfileIntakeStepPage;
