import React from 'react';
import {
  Box, Avatar, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import UserInterests from '../../molecules/UserInterests';
import './ProtegeInfo.css';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const ProtegeInfo = ({
  avatar,
  name,
  isCollegeStudent,
  education,
  location,
  interests,
  small,
}) => (
  <Box>
    <Stack direction="row" spacing={2} alignItems={small && 'center'}>
      <Avatar
        sx={{ width: small ? 87 : 106, height: small ? 87 : 107 }}
        src={avatar}
      >
        <img src={defaultProfileImage} alt="default" />
      </Avatar>
      <Stack>
        <div className="info name">
          <img
            src="assets/icon/ProtegeBadge.svg"
            style={{ paddingTop: '5px', width: small ? '17px' : '25px' }}
            alt="protege badge"
          />
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: small ? 18 : 24,
              color: '#063D8F',
            }}
          >
            {name}
          </Typography>
        </div>
        <div className="info education">
          {isCollegeStudent ? (
            <img
              className="badge college "
              src="assets/icon/edu.svg"
              alt="college badge"
            />
          ) : (
            <img
              className="badge highschool"
              src="assets/Book-badge.svg"
              alt="Highschool badge"
            />
          )}
          <Typography
            sx={{ color: '#5B5B5B', fontSize: small ? 12 : 14, pl: 1 }}
          >
            {education}
          </Typography>
        </div>
        <div className="info location">
          <img
            className="badge locale-marker"
            src="assets/location.svg"
            alt="location badge"
          />
          <Typography
            sx={{ color: '#5B5B5B', fontSize: small ? 12 : 14, pl: 1 }}
          >
            {location}
          </Typography>
        </div>
        <UserInterests interests={interests} />
      </Stack>
    </Stack>
  </Box>
);

ProtegeInfo.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  isCollegeStudent: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  education: PropTypes.string.isRequired,
  interests: PropTypes.arrayOf(PropTypes.string),
  small: PropTypes.bool,
};

ProtegeInfo.defaultProps = {
  interests: null,
  small: false,
  avatar: null,
};

export default ProtegeInfo;
