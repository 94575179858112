import React from 'react';
import { Box, Stack } from '@mui/material';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import { IonPage, IonContent } from '@ionic/react';

const imageOverride = {
  width: '5rem',
  height: 'auto',
  maxWidth: '74px',
};

const contStyleOverride = {
  mb: '60px',
};

const boxStyleOverride = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '400px',
};

const BootstrapLoadingScreen = () => (
  <IonPage>
    <IonContent fullscreen>
      <Box sx={boxStyleOverride}>
        <Stack sx={contStyleOverride}>
          <MainImage imageSrc="/assets/logo_v2.svg" imagealt="backrs logo" imageStylesOverride={imageOverride} />
        </Stack>
        <LoadingSpinner />
      </Box>
    </IonContent>
  </IonPage>
);

export default BootstrapLoadingScreen;
