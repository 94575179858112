import React, { useState } from 'react';

const computePaginationState = ({
  components,
  stepMeta,
  paginationMeta,
}) => {
  const componentsIncludeSplash = components.some((c) => c.id === 'splash');
  const stepsLength = componentsIncludeSplash ? components.length - 1 : components.length;
  const paginate = (!componentsIncludeSplash || stepMeta.id !== 'splash') && stepsLength > 1;
  const paginationTotal = componentsIncludeSplash ? paginationMeta.total - 1 : paginationMeta.total;
  const paginationCurrent = componentsIncludeSplash ? paginationMeta.current - 1 : paginationMeta.current;

  return {
    paginate,
    paginationTotal,
    paginationCurrent,
  };
};

const profileIntakeStepPageContainer = ({
  components = [],
  onSubmitBack,
  onSubmitFinal,
  wholeMeClient,
  ...componentProps
}) => {
  const [paginationMeta, setPaginationMeta] = useState({
    current: 0,
    total: components.length,
  });

  const stepMeta = components[paginationMeta.current];
  const Component = stepMeta.component;

  const nextOnClick = () => {
    let { current } = paginationMeta;
    current++;

    if (current > components.length - 1) {
      current = 0;
    }

    setPaginationMeta({
      ...paginationMeta,
      current,
    });
  };

  const submit = () => {
    if (paginationMeta.current === components.length - 1) {
      onSubmitFinal();
    } else {
      nextOnClick();
    }
  };

  const prevOnClick = () => {
    let { current } = paginationMeta;
    if (current === 0) {
      onSubmitBack();
      return;
    }

    current--;
    if (current < 0) {
      current = components.length - 1;
    }

    setPaginationMeta({
      ...paginationMeta,
      current,
    });
  };

  const { paginate, paginationTotal, paginationCurrent } = computePaginationState({
    components,
    stepMeta,
    paginationMeta,
  });

  return (
    <Component
      key={`${stepMeta.id}-${stepMeta.key}`}
      wholeMeClient={wholeMeClient}
      stepKey={{
        id: stepMeta.id,
        category: stepMeta.key,
      }}
      paginationMeta={
        paginate
          ? {
            current: paginationCurrent,
            total: paginationTotal,
          }
          : undefined
      }
      stepIndex={paginationMeta?.current || 0}
      prevOnClick={prevOnClick}
      nextOnClick={submit}
      {...componentProps}
    />
  );
};

export default profileIntakeStepPageContainer;
