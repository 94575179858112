import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputField from 'common/atoms/inputField/InputField';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import {
  Typography,
} from '@mui/material';
import ProfileIntakeStepPage from '../../../molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import BottomDrawer from '../../../../../common/molecules/BottomDrawer/BottomDrawer';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';
import isValidUrl from '../../../../../helpers/isValidUrl';
import appStrings from '../../../../../common/app_strings';

const mainImageData = {
  imageSrc: './assets/profile/career/occupation-page-2.svg',
  imageAlt: 'a stapler and a lamp',
};

const iconOverrideStyles = {
  position: 'relative',
  fontSize: '24px',
};

const imageStylesOverride = {
  margin: '0',
  width: '100%',
  paddingLeft: '20px',
  marginBottom: '-1.5rem',
};

const linkedInRegex = /^https?:\/\/(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+(?:\?.*)?$/i;

const ProfileLinkedInPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [linkedinurl, setlinkedinurl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { linkedinPage } = appStrings;
  const { platform: { isNativePlatform } } = useAppState();

  const saveLinkedInState = (val) => {
    wholeMeClient.saveLinkedInState([
      {
        category_id: 'linkedIn',
        question_id: 'linkedIn_url',
        value: val,
      },
    ]).then(() => {
      nextOnClick();
    });
  };

  const saveLinkedIn = () => {
    setErrorMessage('');
    if (linkedinurl === null || linkedinurl.trim() === '') {
      saveLinkedInState(null);
    } else if (!isValidUrl(linkedinurl, linkedInRegex).isValid) {
      setErrorMessage(linkedinPage.errMsg);
    } else {
      saveLinkedInState(isValidUrl(linkedinurl, linkedInRegex).url);
    }
  };

  const renderDrawerText = (isNative) => {
    const drawerData = isNative ? linkedinPage.drawerDataNative : linkedinPage.drawerDataWeb;
    return (
      <ol
        style={{
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        {drawerData.map((item, index) => (
          <li key={item}>
            {(isNativePlatform && index === drawerData.length - 1) ? (
              <>
                {linkedinPage.locateUrl}
                {' '}
                <span style={{ color: '#5981D1' }}>{item}</span>
              </>
            ) : (
              item
            )}
          </li>
        ))}
      </ol>
    );
  };

  useEffect(async () => {
    const state = (await wholeMeClient.fetchLinkedInState())?.linkedIn_url || [];
    setlinkedinurl(state[0]?.value || '');
  }, []);
  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      nextButton={{ onClick: saveLinkedIn }}
      prevButton={{ onClick: prevOnClick }}
      title={linkedinPage.title}
      subtitle={linkedinPage.subtitle}
      backgroundOverlay="assets/profile/education/occupation-overlay.svg"
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      <InputField
        onChange={(e) => setlinkedinurl(e.target.value)}
        placeholder={linkedinPage.inputPlaceholder}
        fieldValue={linkedinurl}
        icon="occupation"
        iconStyleOverride={iconOverrideStyles}
        theme="light"
        error={!!errorMessage && { message: errorMessage }}
      />
      <Typography
        style={{
          fontSize: '13px',
          color: '#063D8F',
          fontWeight: 700,
          textAlign: 'center',
          marginTop: '-35px',
          cursor: 'pointer',
        }}
        onClick={() => setIsDrawerOpen(true)}
      >
        {linkedinPage.howToFindUrl}
      </Typography>
      <MainImage
        imageSrc={mainImageData.imageSrc}
        imageAlt={mainImageData.imageAlt}
        imageStylesOverride={imageStylesOverride}
      />
      <BottomDrawer
        title={linkedinPage.howToFindUrl}
        subTitle={renderDrawerText(isNativePlatform)}
        showBackdrop={false}
        open={isDrawerOpen}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
        hideCta
        containerStylesOverride={{ justifyContent: 'center' }}
      />
    </ProfileIntakeStepPage>
  );
};

export const ProfileLinkedInPagePropTypes = {
  nextButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  prevButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  paginationMeta: PropTypes.shape({ current: PropTypes.number, total: PropTypes.number }),
};

export const ProfileLinkedInpageDefaultProps = {
  nextButton: { onClick: () => { } },
  prevButton: { label: '', onClick: () => { } },
  paginationMeta: { current: 0, total: 0 },
};

ProfileLinkedInPage.propTypes = ProfileLinkedInPagePropTypes;
ProfileLinkedInPage.defaultProps = ProfileLinkedInpageDefaultProps;

export default ProfileLinkedInPage;
