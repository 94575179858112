import { React } from 'react';
import {
  Select,
  MenuItem,
  Divider,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';
import { truncateText } from '../../../helpers/transformers';
import useWindowDimensions from '../../../helpers/hooks';
import { findTargetObject, buildMenuItemListMeta } from './utils';

const renderMenuItems = (targetMeta, width) => {
  const listItemMeta = buildMenuItemListMeta(targetMeta);
  const items = listItemMeta.map((item) => {
    const truncatedLabel = truncateText(item.label, 25, width, 18);

    if (item?.isDivider) {
      return item?.render ? (<Divider key={item.label} textAlign="left">{item.label}</Divider>) : null;
    }
    return (
      <MenuItem
        key={item.targetKey}
        value={item.targetKey}
        disableRipple
      >
        <ListItemText primary={truncatedLabel} />
      </MenuItem>
    );
  });

  return items;
};

const PostTargetDropdown = ({
  handleVisibility,
  targetListMeta,
  selectedTarget,
  isDisabled,
}) => {
  const { width } = useWindowDimensions();
  const selectedValue = selectedTarget || '';

  const onChange = (event) => {
    const { target: { value } } = event;
    const groupType = value.split('-')[0];
    const foundTarget = findTargetObject({
      targetKey: value,
      targets: targetListMeta,
      groupType,
    });
    return handleVisibility(foundTarget);
  };

  return (
    <Select
      sx={{
        backgroundColor: 'white',
        height: '30px',
        width: 'fit-content',
        maxWidth: width <= 375 ? '225px' : '300px',
      }}
      slot="end"
      value={selectedValue}
      onChange={onChange}
      displayEmpty
      disabled={isDisabled}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {renderMenuItems(targetListMeta, width)}
    </Select>
  );
};

PostTargetDropdown.propTypes = {
  targetListMeta: PropTypes.oneOfType([PropTypes.object]),
  handleVisibility: PropTypes.func,
  selectedTarget: PropTypes.string,
  isDisabled: PropTypes.bool,
};

PostTargetDropdown.defaultProps = {
  targetListMeta: {},
  handleVisibility: () => { },
  selectedTarget: null,
  isDisabled: false,
};

export default PostTargetDropdown;
