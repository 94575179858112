export const childhoodData = [
  {
    label: 'First Born',
    value: 'First Born',
  },
  {
    label: 'Youngest Child',
    value: 'Youngest Child',
  },
  {
    label: 'Middle Child',
    value: 'Middle Child',
  },
  {
    label: 'Only Child',
    value: 'Only Child',
  },
  {
    label: 'Adopted',
    value: 'Adopted',
  },
  {
    label: 'Step Sibling',
    value: 'Step Sibling',
  },
  {
    label: 'Foster Child',
    value: 'Foster Child',
  },
  {
    label: 'Multigenerational Household',
    value: 'Multigenerational Household',
  },
  {
    label: 'Twin +',
    value: 'Twin +',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
