import React from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import BottomDrawer from 'common/molecules/BottomDrawer/BottomDrawer';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import { styled } from '@mui/material';

const ImgContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const PersistentDrawerPage = ({
  pageTitle,
  pageSubtitle,
  drawerTitle,
  drawerSubTitle,
  isLoading,
  prevButton,
  nextButton,
  paginationMeta,
  handleInfoButtonClick,
  backgroundOverlay,
  mainImageData,
  children,
}) => (
  <>
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={pageTitle}
      subtitle={pageSubtitle}
      prevButton={prevButton}
      nextButton={nextButton}
      backgroundOverlay={backgroundOverlay}
      hasPersistentDrawer
      noAutoMarginOnChildren
    >
      {isLoading
        ? <WholeMeLoadingCircle />
        : (mainImageData ? <ImgContainer><MainImage {...mainImageData} /></ImgContainer> : null)}
    </ProfileIntakeStepPage>
    {!isLoading && (
    <BottomDrawer
      title={drawerTitle}
      subTitle={drawerSubTitle}
      ctaLabel="Save"
      handleCTAClick={nextButton.onClick}
      isCtaDisabled={nextButton.isDisabled}
      variant="permanent"
      onInfoButtonClick={handleInfoButtonClick}
    >
      {children}
    </BottomDrawer>
    )}
  </>
);

export default PersistentDrawerPage;
