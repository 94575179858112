/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/capacitor';
import {
  IonBackButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonTextarea,
  IonButton,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonIcon,
} from '@ionic/react';
import {
  Dialog,
  DialogTitle,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  Stack,
  Alert,
  CircularProgress,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import { close, closeOutline, videocam } from 'ionicons/icons';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import { Browser } from '@capacitor/browser';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import './CreatePost.css';
import { useAuthContext } from '../providers/auth-provider';
import { useAppState } from '../providers/app-state/app-state-provider';
import PostTargetDropdown from '../common/atoms/dropDown/CreatePostDropdown';
import { transformTargetMeta, getInitialTarget } from '../common/atoms/dropDown/utils';
import { displayErrorText, displayAttachmentLabel } from '../helpers/media';

/**
 * TODO:
 *  App Strings
 */

const possibleAsks = [
  {
    id: 1,
    name: 'recommendation',
    phrase: 'I need a recommendation',
  },
  {
    id: 2,
    name: 'connection',
    phrase: 'I need a connection',
  },
  {
    id: 3,
    name: 'resources',
    phrase: 'I need resources',
  },
  {
    id: 4,
    name: 'affirmation',
    phrase: 'I need affirmation',
  },
  {
    id: 5,
    name: 'celebration',
    phrase: 'I want to celebrate',
  },
  {
    id: 6,
    name: 'meeting',
    phrase: 'I need to meet with my team',
  },
];

const CreatePost = ({ location }) => {
  const { user } = useAuthContext();
  const {
    takeVideo, takePhoto, takeWebVideo, takePdf, loading, error, controller,
  } = usePhotoGallery();
  const [attachment, setAttachment] = useState(null);
  const [handledPostTargetMeta, setHandledPostTargetMeta] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [currentAsk, setAsk] = useState(null);
  const [confirmedAsk, setConfirmedAsk] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPermissionsAlert, setShowPermissionsAlert] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' });
  const history = useHistory();
  const {
    user: { postTargets: targets },
    platform: { isNativePlatform },
  } = useAppState();
  const { post: doPost } = useFetch();

  const subject = location?.state?.subject;
  const targetTeamId = location?.state?.targetTeamId;
  const postSubmitToast = location?.state?.postSubmitToast;
  const infoUrl = location?.state?.infoUrl;

  const filterTargetListMeta = (postTargets, teamId) => {
    if (!postTargets) {
      return postTargets;
    }

    if (!teamId) {
      return postTargets;
    }

    const teams = postTargets?.teams;
    if (teams) {
      return {
        ...postTargets,
        teams: teams.filter((team) => team.id === teamId),
      };
    }

    return postTargets;
  };

  useEffect(() => {
    if (targets) {
      const handledTargets = filterTargetListMeta(transformTargetMeta(targets), targetTeamId);
      setHandledPostTargetMeta(handledTargets);

      // pre-select corresponding target if coming from team/group feed
      if (location?.state?.type && location?.state?.value) {
        const targetType = handledTargets[`${location.state.type}s`];
        const foundTarget = targetType.find((item) => item.id === location.state.value);
        setSelectedTarget(foundTarget);
        return;
      }

      if (location?.state?.id) {
        setSelectedTarget({
          id: Number(location?.state?.id),
          label: '',
          targetType: 'team',
          targetKey: `team-${location?.state?.id}`,
        });
        return;
      }

      const initialTarget = getInitialTarget({
        userRole: user.role,
        targets: handledTargets,
      });
      if (initialTarget) {
        setSelectedTarget(initialTarget);
      }
    }
  }, [targets]);

  const handleAsk = (event) => {
    setAsk(event.target.value);
  };

  const resetAsk = () => {
    setAsk(null);
    setConfirmedAsk(null);
  };

  const handleAskConfirm = () => {
    setConfirmedAsk(currentAsk);
    setOpen(false);
  };

  const handleClose = () => {
    if (confirmedAsk !== currentAsk) {
      setAsk(null);
    }
    setOpen(false);
  };

  // todo: combine handlePhoto and handleVideo into one function
  const handlePhoto = async () => {
    if (loading) return;

    try {
      const { media, webviewPath } = await takePhoto();
      console.log('Create Post ~ takePhoto response: ', { media, webviewPath });
      if (media) {
        setAttachment({ webviewPath, id: media.id, type: 'photo' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleWebVideo = async () => {
    const { media } = await takeWebVideo();
    if (media) {
      setAttachment({ webviewPath: media.uri, id: media.id, type: 'video' });
    }
  };

  const handleMobileVideo = async () => {
    const { media, webviewPath } = await takeVideo();
    if (media) {
      setAttachment({ webviewPath, id: media.id, type: 'video' });
    }
  };

  // todo: verify handleVideo still works
  const handleVideo = async () => {
    if (loading) return;
    const uploader = isNativePlatform ? handleMobileVideo : handleWebVideo;

    try {
      await uploader();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handlePdf = async () => {
    if (loading) {
      return;
    }
    try {
      const { webviewPath, media } = await takePdf();
      if (media) {
        setAttachment({ webviewPath, id: media.id, type: 'pdf' });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleMediaClose = () => {
    if (loading) controller.abort();
    setAttachment(null);
  };

  const onSubmit = async (data) => {
    if (isPosting) return;
    const visibility = selectedTarget?.targetType === 'team' ? 'team' : 'public';
    const user_id = selectedTarget?.targetType === 'team' ? selectedTarget?.id : user.id;

    const formData = {
      ...data,
      user_id,
      author_id: user.id,
      visibility,
      attachments: attachment?.id ? [attachment?.id] : [],
      group_ids: selectedTarget.targetType === 'group' ? [selectedTarget.id] : undefined,
    };

    // May be null if user doesn't select an ask
    if (confirmedAsk) {
      formData.question_id = confirmedAsk;
    }

    setIsPosting(true);
    await doPost('/posts', formData);
    history.goBack();
    if (postSubmitToast) {
      toast.success(
        postSubmitToast,
        { duration: 1500 },
      );
    }
  };

  const onTargetChange = (target) => {
    setSelectedTarget(target);
  };

  const prompt = location?.state?.postPrompt
    ? location?.state?.postPrompt
    : `What's happening, @${user.first_name}`;

  return (
    <IonPage>
      <IonHeader className="create-post-toolbar-header">
        <IonToolbar
          style={{
            '--background': 'var(--ion-background-color)',
            '--border-width': 0,
            paddingTop: '2rem',
          }}
          className="create-post-toolbar"
        >
          <IonButtons slot="start">
            <IonBackButton
              text=""
              icon="/assets/back-arrow.svg"
              style={{
                '--icon-font-size': '1.25rem',
                '--icon-padding-start': '8px',
                '--icon-padding-end': '12px',
                '--icon-padding-top': '4px',
              }}
            />
          </IonButtons>
          {handledPostTargetMeta && selectedTarget && !location?.state?.id && (
            <PostTargetDropdown
              targetListMeta={handledPostTargetMeta}
              handleVisibility={onTargetChange}
              selectedTarget={selectedTarget?.targetKey}
              isDisabled={!!location?.state?.id}
            />
          )}
          <Chip
            slot="end"
            label="Post"
            sx={{
              height: 28,
              fontSize: 13,
              fontWeight: 700,
              background: '#03295f',
              color: '#fff',
              px: 1,
              ml: 1.5,
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={isPosting || ((!isDirty || !isValid) && !attachment?.webviewPath)}
          />
        </IonToolbar>
      </IonHeader>
      <Dialog open={showPermissionsAlert} onClose={() => setShowPermissionsAlert(false)}>
        <DialogTitle>Permissions Needed</DialogTitle>
        <DialogContent>
          Whoops! In order to upload a photo or video, you need to allow access to your photos and
          camera. Navigate to system settings to change photo and camera permissions.
        </DialogContent>
      </Dialog>
      <IonContent>
        <Box mx={3} mt={2}>
          <Stack spacing={1}>
            {subject && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="h5">{subject}</Typography>
                {infoUrl && (
                  <IonIcon
                    onClick={async () => {
                      await Browser.open({ url: infoUrl });
                    }}
                    style={{ marginTop: '2px', fontSize: '1.25rem', color: '#000000' }}
                    slot="icon-only"
                    src="/assets/icon/info-icon-dark.svg"
                  />
                )}
              </Stack>
            )}
            <IonCard color="light" className="ion-card-margin">
              <form>
                <IonCardContent>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <IonTextarea
                        placeholder={prompt}
                        onIonChange={onChange}
                        value={value}
                        rows={17}
                        style={{
                          '--placeholder-color': '#727272',
                          '--placeholder-font-weight': 'bold',
                          fontSize: '1.125rem',
                        }}
                        autocapitalize="on"
                      />
                    )}
                    control={control}
                    name="body"
                    defaultValue=""
                    rules={{ required: !attachment?.webviewPath }}
                  />
                  {error && (
                    <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
                      {displayErrorText(error)}
                    </Alert>
                  )}
                  {(attachment?.webviewPath || loading) && !error && (
                    <IonItem
                      detail={false}
                      lines="none"
                      className="post-option-item post-media-preview"
                    >
                      {loading && <CircularProgress size={40} sx={{ mr: 2.5 }} />}
                      {!loading && attachment?.type === 'photo' && attachment?.webviewPath && (
                        <IonThumbnail slot="start" className="post-photo-thumbnail">
                          <img src={attachment?.webviewPath} alt="current post" />
                        </IonThumbnail>
                      )}
                      {!loading && attachment?.type === 'video' && attachment?.webviewPath && (
                        <IonIcon
                          style={{ marginRight: '1rem', color: 'inherit' }}
                          icon={videocam}
                        />
                      )}
                      <IonLabel>{displayAttachmentLabel(loading, attachment)}</IonLabel>
                      <IonButton slot="end" onClick={() => handleMediaClose()}>
                        <IonIcon slot="icon-only" icon={closeOutline} />
                      </IonButton>
                    </IonItem>
                  )}
                  <Stack direction="row" spacing={2}>
                    <Chip
                      label="+ Add Ask"
                      onClick={() => setOpen(true)}
                      sx={{
                        fontSize: '0.625rem',
                        fontWeight: 700,
                        backgroundColor: '#03295F',
                        color: '#fff',
                        textTransform: 'capitalize',
                        fontFamily: '"Sarabun", arial, sans-serif',
                      }}
                    />
                    {confirmedAsk ? (
                      <Chip
                        key={confirmedAsk}
                        label={possibleAsks[confirmedAsk - 1].name}
                        onDelete={resetAsk}
                        sx={{
                          fontSize: '0.625rem',
                          fontWeight: 700,
                          backgroundColor: '#e8e8dc',
                          color: '#2d2d2d',
                          textTransform: 'capitalize',
                          fontFamily: '"Sarabun", arial, sans-serif',
                        }}
                      />
                    ) : null}
                  </Stack>
                  <hr className="form-divider" />
                  <Stack spacing={1}>
                    <IonCardSubtitle className="add-to-post-title">Add to post</IonCardSubtitle>
                    <IonItem
                      button
                      detail={false}
                      lines="none"
                      className="post-option-item"
                      onClick={handlePhoto}
                    >
                      <IonIcon className="post-option-icon" src="assets/icon/addPhoto.svg" />
                      <h2 className="post-option-text">Photo</h2>
                    </IonItem>
                    <IonItem
                      button
                      detail={false}
                      lines="none"
                      className="post-option-item"
                      onClick={handleVideo}
                    >
                      <IonIcon className="post-option-icon" src="assets/icon/file-video.svg" />
                      <h2 className="post-option-text">Video</h2>
                    </IonItem>
                    <IonItem
                      button
                      detail={false}
                      lines="none"
                      className="post-option-item"
                      onClick={handlePdf}
                    >
                      <IonIcon className="post-option-icon" src="assets/icon/file-pdf.svg" />
                      <h2 className="post-option-text">Share a file</h2>
                    </IonItem>
                  </Stack>
                </IonCardContent>
              </form>
            </IonCard>
          </Stack>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '0.5rem',
              right: '0.5rem',
            }}
          >
            <IonIcon slot="icon-only" icon={close} />
          </IconButton>
          <DialogTitle sx={{ fontSize: 18, mt: 4 }}>What do you need help with?</DialogTitle>
          <DialogContent>
            <RadioGroup value={currentAsk} onChange={handleAsk} sx={{ mb: 2 }}>
              {possibleAsks.map((ask) => (
                <FormControlLabel
                  key={ask.id}
                  value={ask.id}
                  control={<Radio />}
                  label={<Typography sx={{ fontWeight: 500 }}>{ask.phrase}</Typography>}
                />
              ))}
            </RadioGroup>
            <IonButton onClick={handleAskConfirm} expand="block" shape="round">
              Add to Post
            </IonButton>
          </DialogContent>
        </Dialog>
      </IonContent>
    </IonPage>
  );
};

CreatePost.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CreatePost;
