import React from 'react';
import PropTypes from 'prop-types';
import AvatarAdmin from '../../../components/AvatarAdmin';
import AvatarRole from '../../../components/AvatarRole';
import { deriveUserRole } from '../utils';

const AuthorAvatar = ({
  author,
  aliasAdminAsSystem,
}) => {
  const { role } = author;
  const style = { marginRight: 16 };
  const derivedRole = deriveUserRole({ role, aliasAdminAsSystem });

  return derivedRole === 'admin'
    ? (<AvatarAdmin style={style} />)
    : (<AvatarRole user={author} style={style} />);
};

AuthorAvatar.propTypes = {
  aliasAdminAsSystem: PropTypes.bool,
  author: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

AuthorAvatar.defaultProps = {
  aliasAdminAsSystem: true,
};

export default AuthorAvatar;
