import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { Controller } from 'react-hook-form';
import { IonTextarea, IonButton } from '@ionic/react';
import PropTypes from 'prop-types';
import SendIconAvatar from './SendIconAvatar';

const ChatInput = forwardRef(({
  control,
  isLoading,
  onFocus,
  onSubmit,
  isDisabled,
  user,
}, ref) => (
  <motion.form
    ref={ref}
    onSubmit={onSubmit}
    slot="fixed"
    className="chat-input-form"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: 0 }}
  >
    <div className="chat-input-container">
      <SendIconAvatar user={user} />
      <div className="chat-input-wrapper">
        <Controller
          render={({ field: { onChange, value } }) => (
            <IonTextarea
              onIonFocus={onFocus}
              className="comment-input"
              placeholder={isDisabled ? '' : 'Add your comment'}
              onIonChange={onChange}
              value={value}
              disabled={isDisabled || isLoading}
              rows="1"
              autoGrow
              required
              spellcheck
              maxlength={1000}
              style={{
                '--placeholder-color': '#727272',
                fontSize: '14px',
                maxHeight: '340px',
                marginRight: '3.25rem',
                overflowY: 'hidden',
                color: '#000',
                padding: '0 .25rem',
              }}
              autocapitalize="on"
            />
          )}
          control={control}
          name="body"
          defaultValue=""
        />
        <IonButton
          type="submit"
          size="small"
          fill="clear"
          style={{
            transform: 'translateY(-2px)',
            position: 'fixed',
          }}
          disabled={isDisabled || isLoading}
        >
          Send
        </IonButton>
      </div>
    </div>
  </motion.form>
));

ChatInput.propTypes = {
  control: PropTypes.oneOfType([PropTypes.any]),
  isLoading: PropTypes.bool,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    profile_image: PropTypes.string,
  }).isRequired,
};

ChatInput.defaultProps = {
  control: null,
  isLoading: false,
  onFocus: () => {},
  onSubmit: () => {},
};

export default ChatInput;
