import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';

const profileRelationshipsIntakeSplashPage = ({ nextOnClick, prevOnClick }) => {
  const { platform } = useAppState();

  const splashImage = {
    src: './assets/profile/relationships/relationships-splash.svg',
    alt: 'hugging',
    styles: {
      width: platform?.isNativePlatform ? '60%' : '45%',
    },
  }; return (
    <ProfileIntakeSplashPage
      title="Relationships"
      subtitle="Who do you know? Answer a few questions about your connections and your network!"
      splashImage={splashImage}
      nextButton={{ label: "Let's Go", onClick: nextOnClick }}
      prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
      backgroundOverlay="/assets/profile/relationships/relationships-splash-overlay.svg"
    />
  );
};

export default profileRelationshipsIntakeSplashPage;
