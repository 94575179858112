import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import BackrsButton from '../../atoms/backrsButton/BackrsButton';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import {
  imageStyles,
  labelElementStyles,
  defaultButtonStyles,
  elementButtonStyle,
} from './ProfileActionButtonStyles';

const ProfileActionButton = ({
  meta,
  isDisabled,
  isLoading,
  buttonStylesOverride,
  selectedMeta,
  isSelected,
  setIsSelected,
}) => {
  const { platform } = useAppState();
  const buttonStyles = {
    ...defaultButtonStyles,
    ...buttonStylesOverride,
    ...isSelected && selectedMeta?.style,
  };

  const handleClick = () => {
    if (setIsSelected) {
      setIsSelected();
    }
    if (meta?.callback) {
      meta.callback();
    }
  };

  const elementSrc = isSelected ? (selectedMeta?.iconSrc || meta?.iconSrc) : meta?.iconSrc;

  const buildButtonContent = () => {
    if (meta?.isElement) {
      return (
        <Box
          sx={labelElementStyles(platform?.isNativePlatform)}
        >
          <img
            style={imageStyles}
            src={elementSrc}
            alt="button icon"
          />
          <Typography sx={elementButtonStyle}>
            {meta?.elementContent}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <BackrsButton
      label={meta?.label}
      onClick={handleClick}
      buttonStylesOverride={buttonStyles}
      isDisabled={isDisabled}
      isLoading={isLoading}
      labelElement={buildButtonContent()}
      iconSrc={meta.iconSrc}
      disableRipple={meta.disableRipple}
    />
  );
};

ProfileActionButton.propTypes = {
  meta: PropTypes.shape({
    elementContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    callback: PropTypes.func,
    isElement: PropTypes.bool,
    label: PropTypes.string,
    iconSrc: PropTypes.string,
    disableRipple: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  buttonStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }), // Default button styles
  elementButtonStyleOverride: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }),
  selectedMeta: PropTypes.shape({
    iconSrc: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
};

ProfileActionButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  buttonStyles: {
    backgroundColor: '#E8E8DC',
    color: '#5B5B5B',
  },
  elementButtonStyleOverride: {},
  selectedMeta: {},
};

export default ProfileActionButton;
