import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useFetch, { CachePolicies } from 'use-http';
import { CapacitorVideoPlayer } from 'capacitor-video-player';
import { useAuthContext } from '../../../providers/auth-provider';
import appStrings from '../../app_strings';
import LoadingSpinner from '../../../components/generic/LoadingSpinner';

const TourOverlay = ({ isOpen, handleClose }) => {
  const { user, refreshUser } = useAuthContext();
  const [tour, setTour] = useState(null);
  const {
    tour: { videoError },
  } = appStrings;
  const tourPlayer = CapacitorVideoPlayer;
  const {
    get, loading, error, response,
  } = useFetch({
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const { put: updateUser } = useFetch(`/users/${user?.id}`);

  const updateUserTourStatus = async () => {
    if (user?.tour_status === 'completed') {
      return;
    }

    await updateUser({ tour_status: 'completed' });
    await refreshUser();
  };

  useEffect(() => {
    const fetchTourData = async () => {
      const data = await get('/workflow-meta/tour');
      if (response.ok && isOpen) {
        updateUserTourStatus();
        setTour(data.media[0]);
      }
    };
    if (isOpen) {
      fetchTourData();
    }
  }, [isOpen]);

  useEffect(() => {
    let playListener = null;
    let exitListener = null;
    let endListener = null;
    const setListeners = async () => {
      playListener = await tourPlayer.addListener('jeepCapVideoPlayerPlay', () => {});
      exitListener = await tourPlayer.addListener('jeepCapVideoPlayerExit', () => {
        handleClose();
      });
      endListener = await tourPlayer.addListener('jeepCapVideoPlayerEnded', () => {
        handleClose();
      });
    };

    setListeners();
    return () => {
      if (playListener) playListener.remove();
      if (endListener) endListener.remove();
      if (exitListener) exitListener.remove();
    };
  }, []);

  useEffect(() => {
    const playerNative = async () => {
      try {
        await tourPlayer.initPlayer({
          url: tour.video_url,
          mode: 'fullscreen',
          playerId: 'fullscreen',
          componentTag: 'div',
          pipEnabled: false,
          chromecast: false,
        });
        await tourPlayer.setMuted({ playerId: 'fullscreen', muted: true });
      } catch (e) {
        console.error(e);
      }
    };

    if (tour) {
      playerNative();
    }
  }, [tour]);

  return (
    <div style={{ zIndex: 1000000, display: !isOpen ? 'none' : 'initial' }}>
      {error ? (
        <Typography
          sx={{
            color: '#2d2d2d',
            fontSize: '1.125rem',
            px: 3,
            mt: 2,
            textAlign: 'center',
          }}
        >
          {videoError}
        </Typography>
      ) : null}
      {loading ? <LoadingSpinner /> : <div id="fullscreen" slot="fixed" />}
    </div>
  );
};

TourOverlay.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

TourOverlay.defaultProps = {
  isOpen: false,
  handleClose: () => {},
};

export default TourOverlay;
