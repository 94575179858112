import React from 'react';
import {
  IonPage, IonContent, IonCard, IonCardContent,
} from '@ionic/react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useAuthContext } from '../providers/auth-provider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './NoGoals.css';

const NoGoals = () => {
  const { user } = useAuthContext();
  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <Typography
            variant="h1"
            sx={{
              color: 'var(--ion-color-primary)',
              fontSize: 24,
              fontWeight: 700,
              lineHeight: 1.333,
              maxWidth: '20ch',
              textAlign: 'center',
              mt: 6,
              mb: 2,
              mx: 'auto',
            }}
          >
            You do not currently have goals to view
          </Typography>
          <div className="no-goals-decoration-wrapper">
            <img
              src="/assets/matching_shape_1.svg"
              alt=""
              role="presentation"
              className="no-goals-shape no-goals-shape-1"
            />
            <img
              src="/assets/matching_shape_2.svg"
              alt=""
              role="presentation"
              className="no-goals-shape no-goals-shape-2"
            />
            <img
              src="/assets/green-lines.svg"
              alt=""
              role="presentation"
              className="no-goals-shape no-goals-shape-3"
            />
            <img
              className="no-goals-bullseye-img"
              src="/assets/bullseye.svg"
              alt="goals bullseye"
            />
          </div>
          <Box mx={3}>
            <IonCard className="no-goals-card">
              <IonCardContent>
                <div className="no-goals-card-content">
                  <img
                    className="card-leading-icon"
                    src="/assets/magnet.svg"
                    alt="goals magnet"
                  />
                  <div className="no-goals-card-message">
                    {user?.role === 'protege' ? (
                      <>
                        <h2>
                          Write your first goal!
                        </h2>
                        <p>
                          Use the + sign to create a goal tied to your passions,
                          academics, career, and more
                        </p>
                      </>
                    ) : (
                      <p>
                        Your protege&apos;s goals will display here. If no goals
                        are displayed, return once you are matched to a team.
                      </p>
                    )}
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </Box>
        </motion.div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default NoGoals;
