/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { debounce } from '@mui/material/utils';
import PropTypes from 'prop-types';
import { format, parse } from 'date-fns';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { IMaskMixin } from 'react-imask';
import renderInputAdornment from 'common/atoms/adornments/inputIconUtils';
import { IonLabel } from '@ionic/react';
import {
  darkThemeStyles,
  inputFieldStyles,
  lightThemeStyles,
  labelStyles,
  containerStyles,
  exitSvgStyles,
} from './inputFieldStyles';

const ExitXSvg = ({ handleExitClick }) => (
  <div onClick={() => handleExitClick()} style={exitSvgStyles}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.616 0L12 8.85533L3.384 0L0 3.478L8.616 12.3333L0 21.1887L3.384 24.6667L12 15.8113L20.616 24.6667L24 21.1887L15.384 12.3333L24 3.478L20.616 0Z" fill="#9A9A9A" />
    </svg>
  </div>
);

const InputField = React.forwardRef(({
  icon,
  placeholder,
  theme,
  masker,
  error,
  onChange,
  onFocus,
  title,
  required,
  name,
  fieldValue,
  onKeyUp,
  onExitClick,
  debounceMilliSeconds,
  stylesOverride,
  forcePadding,
  isInputFieldContentCentered,
  maxValueLength,
  isMultiline,
  enableNumpad,
  iconStyleOverride,
  rows,
  enterKeyHint,
}, ref) => {
  const handleOnChange = debounceMilliSeconds === null ? onChange : debounce(onChange, debounceMilliSeconds);
  const lightTheme = theme === 'light';
  const themeStyles = lightTheme ? { ...lightThemeStyles } : { ...darkThemeStyles };

  const combinedStyles = {
    ...inputFieldStyles,
    ...themeStyles,
    ...(isInputFieldContentCentered && {
      input: {
        ...inputFieldStyles.input,
        textAlign: 'center',
      },
    }),
  };

  const Input = styled(TextField)(combinedStyles);

  const MaskedStyledInput = useMemo(
    () => IMaskMixin(({ ...props }) => <Input rows={rows} {...props} onKeyUp={onKeyUp} />),
    [theme],
  );

  const InputProps = {};
  if (icon) {
    InputProps.startAdornment = renderInputAdornment(icon, 'start', iconStyleOverride);
    InputProps.style = { paddingBlock: 5 };
  }
  if (!icon && forcePadding) {
    InputProps.style = { paddingBlock: 5 };
  }
  if (enableNumpad) {
    InputProps.inputProps = { enterKeyHint: 'done', inputMode: 'numeric' };
  }
  if (maxValueLength) {
    InputProps.inputProps = InputProps.inputProps
      ? { ...InputProps.inputProps, maxLength: maxValueLength }
      : { maxLength: maxValueLength };
  }
  if (enterKeyHint) {
    InputProps.inputProps = { ...InputProps.inputProps, enterKeyHint };
  }

  const inputProps = {
    InputProps,
    name,
    placeholder,
    helperText: error?.message,
    error: !!error,
    size: 'small',
    fullWidth: true,
    required,
    inputRef: ref,
    defaultValue: fieldValue,
    ...((fieldValue || fieldValue === '') && { value: fieldValue }),
    ...(!masker && {
      onChange: handleOnChange,
    }),
    multiline: isMultiline,
    onFocus,
  };

  const transformMasker = () => {
    if (masker === 'date') return Date;
    if (masker === 'number') return Number;
    return masker;
  };

  const transformedMask = transformMasker();

  const maskOptions = {
    mask: transformedMask,
    ...(transformedMask === Date && {
      pattern: 'm{/}`d{/}`Y',
      format: (date) => format(date, 'MM/dd/yyyy'),
      parse: (value) => parse(value, 'MM/dd/yyyy', new Date()),
    }),
    onAccept: (_value, mask) => onChange(mask.unmaskedValue),
  };

  // NOTE: When utilizing the masker,
  // the onChange callback will not receive the entire event,
  // rather it will receive the event.target.value.

  return (
    <div style={{ containerStyles, ...stylesOverride }}>
      <IonLabel style={labelStyles}>{title}</IonLabel>
      <MaskedStyledInput {...maskOptions} {...inputProps} />
      {onExitClick ? <ExitXSvg handleExitClick={onExitClick} /> : null}
    </div>
  );
});

export const InputFieldPropTypes = {
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  masker: PropTypes.string,
  theme: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onChange: PropTypes.func,
  title: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  debounceMilliSeconds: PropTypes.number,
  enableNumpad: PropTypes.bool,
  iconStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  enterKeyHint: PropTypes.string,
};

export const InputFieldDefaultProps = {
  icon: '',
  placeholder: '',
  masker: '',
  theme: '',
  error: null,
  onChange: () => {},
  title: '',
  required: false,
  name: '',
  debounceMilliSeconds: null,
  enableNumpad: false,
  iconStyleOverride: null,
  enterKeyHint: 'enter',
};

InputField.propTypes = InputFieldPropTypes;
InputField.defaultProps = InputFieldDefaultProps;

export default InputField;
