import React from 'react';
import ResourceLookupPage from 'pages/wholeme/molecules/resourceLookupPage/ResourceLookupPage';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';

const ProfileRelationshipsIntakeLanguagesPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <ResourceLookupPage
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    wholeMeClient={wholeMeClient}
    categoryId="relationships"
    questionId="languages"
    title="Languages"
    subtitle="What languages do you know, even just a little bit?"
    dataFetcher={async () => (await wholeMeClient.fetchLanguagesState())?.languages}
    dataSaver={wholeMeClient.saveLanguagesState}
    disableCustomInputs
    resourceType="languages"
    resultAttributeId="language"
    backgroundOverlay="/assets/profile/relationships/languages-overlay.svg"
  >
    <MainImage
      imageSrc="./assets/profile/relationships/languages.svg"
      imageAlt="thought bubbles"
    />
  </ResourceLookupPage>
);

export default ProfileRelationshipsIntakeLanguagesPage;
