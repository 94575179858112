import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';
import { financesCredentialsData } from './financesCredentialsData';

const backgroundoverlay = '/assets/profile/finances/financial-experiences-overlay.svg';

const ProfileFinancesIntakeCredentialsPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={financesCredentialsData}
    itemVariant="rectangle"
    isTwoColumns
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Financial Credentials"
    subtitle="Check any of the following that apply to you."
    categoryId="finances"
    questionId="finances_credentials"
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchFinancesCredentialsState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveFinancesCredentialsState)}
    backgroundOverlay={backgroundoverlay}
  />
);

export default ProfileFinancesIntakeCredentialsPage;
