/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import useFetch from 'use-http';
import { motion } from 'framer-motion';
import ConversationItem from './ConversationItem';
import { useAuthContext } from '../../providers/auth-provider';
import PageWithBackButton from '../../components/generic/PageWithBackButton';
import LoadingSpinner from '../../components/generic/LoadingSpinner';
import AdminIntervalWatcher from '../../common/atoms/unreadMessages/AdminIntervalWatcher';
import TwilioOnMessageAddedWatcher from '../../common/atoms/unreadMessages/TwilioOnMessageAddedWatcher';
import './Conversations.css';

const Conversations = () => {
  const { user } = useAuthContext();
  const { get: doGet, response } = useFetch();

  const loadingRef = React.useRef(false);

  const [isLoading, setIsLoading] = useState(false);
  const [conversationsList, setConversationsList] = useState([]);
  const [isError, setIsError] = useState(null);
  const [chatFilterString, setChatFilterString] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [isListWatcherDisabled, setListWatcherDisabled] = useState(false);

  const perPage = 30;

  const setLoadingRef = (loading) => {
    setLoadingRef.current = loading;
    setIsLoading(loadingRef.current);
  };

  const fetchConversations = async (value, isRefresh, page) => {
    try {
      const params = new URLSearchParams();
      params.append('asSystem', user.role === 'admin' ? 'true' : 'false');
      params.append('perPage', perPage);
      params.append('currentPage', isRefresh ? 0 : page);
      params.append('filterString', value || '');

      const res = await doGet(`/conversations?${params.toString()}`);

      if (response.ok) {
        return res;
      }
      return [];
    } catch (e) {
      console.error('Error fetching conversations: ', e);
      return [];
    }
  };

  const updateConversations = async (value, isRefresh = false, page = currentPage) => {
    try {
      setIsError(false);
      if (isRefresh) {
        setLoadingRef(true);
      }
      let total = 0;
      const newConversations = await fetchConversations(value, isRefresh, page);

      if (newConversations.length < perPage) {
        setInfiniteDisabled(true);
      }
      if (total >= response?.headers?.get('x-total-count')) {
        setInfiniteDisabled(true);
      }

      if (isRefresh) {
        setConversationsList(newConversations);
        setInfiniteDisabled(false);
        setListWatcherDisabled(false);
        total = newConversations.length;
      } else {
        // filter out duplicates
        const uniqueNewConversations = newConversations.filter((newConvo) => !conversationsList.some((oldConvo) => oldConvo.external_id === newConvo.external_id));
        setConversationsList([...conversationsList, ...uniqueNewConversations]);
        total = conversationsList.length + uniqueNewConversations.length;
      }
    } catch (e) {
      setIsError(e);
      console.error(e);
    } finally {
      setLoadingRef(false);
    }
  };

  const onScroll = async (e) => {
    if (currentPage >= 1) setListWatcherDisabled(true);
    try {
      if (!isInfiniteDisabled) {
        setCurrentPage((prevPage) => prevPage + 1);
        await updateConversations(chatFilterString, false);
      }
    } catch (error) {
      console.error('Error onScroll', error);
    } finally {
      e.target.complete();
    }
  };

  const updateConversationsList = useCallback((message) => {
    const { conversation } = message;
    const copy = [...conversationsList];
    const indexToRemove = copy.findIndex((c) => c.external_id === conversation.sid);
    const updatedConversation = {
      ...copy[indexToRemove],
      lastMessage: message.state,
      unreadMessagesCount: 1,
      last_message_at: message.state.timestamp,
    };

    if (indexToRemove !== -1) {
      copy.splice(indexToRemove, 1);
      copy.splice(0, 0, updatedConversation);
    }
    setConversationsList(copy);
  }, [conversationsList, setConversationsList]);

  const getConvosDelayed = useCallback(debounce(async (value, isRefresh) => {
    await updateConversations(value, isRefresh);
  }, 500), []);

  useEffect(() => {
    setCurrentPage((prev) => prev + 1);
    getConvosDelayed(chatFilterString, true);
  }, [chatFilterString]);

  const doRefresh = (ev) => {
    getConvosDelayed(chatFilterString, true);
    ev.detail.complete();
  };

  const listWatcher = (userRole) => (userRole === 'admin' ? (
    <AdminIntervalWatcher
      intervalCallback={async () => { await updateConversations(chatFilterString, true, 0); }}
      interval={10000}
    />
  ) : (
    <TwilioOnMessageAddedWatcher
      onMessageAdded={updateConversationsList}
      tag="conversations"
    />
  ));

  const handleScroll = (e) => {
    const { scrollTop } = e.detail;
    if (scrollTop <= 1) {
      setCurrentPage(1);
      updateConversations(chatFilterString, true, 0);
    }
    if (currentPage === 0) {
      setListWatcherDisabled(false);
    }
  };

  const renderContent = () => {
    if (isLoading === true) {
      return <LoadingSpinner />;
    }

    if (isError) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          mt={25}
        >
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            Conversations unavailable. Try again.
          </Typography>
        </Stack>
      );
    }

    return (
      <motion.div>
        <Stack mt={3} px={3} spacing={3} mb={3}>
          {conversationsList.map((conversation) => (
            <ConversationItem
              conversation={conversation}
              key={`${conversation.external_id}-${conversation.lastMessage?.body}}`}
            />
          ))}
        </Stack>
      </motion.div>
    );
  };

  return (
    <PageWithBackButton backButtonText="Conversations">
      <IonContent fullscreen scrollEvents onIonScroll={handleScroll}>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {user && !isListWatcherDisabled && listWatcher(user.role)}
        <Stack mt={3} px={3} spacing={3}>
          {user && user.role === 'admin' && (
            <div>
              Filter Community Chat by Name or Id:
              <TextField
                value={chatFilterString}
                onChange={(e) => setChatFilterString(e.target.value)}
                fullWidth
                placeholder=""
                size="small"
                minRows={1}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setChatFilterString('');
                        }}
                      >
                        Clear
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </Stack>
        {renderContent()}
        <IonInfiniteScroll
          onIonInfinite={onScroll}
          threshold="200px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent loadingSpinner="bubbles" />
        </IonInfiniteScroll>
      </IonContent>
    </PageWithBackButton>
  );
};

export default Conversations;
