import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  Box,
} from '@mui/material';

const Button = ({
  label,
  buttonStyles,
  labelStyles,
  onClickCallBack,
  disabled,
}) => (
  <Stack>
    <Box
      sx={{
        borderRadius: 100,
        px: 3.75,
        ...buttonStyles,
        ...(disabled && { background: '#c3c3b9' }),
      }}
      onClick={disabled ? () => {} : onClickCallBack}
    >
      <Typography
        sx={{
          color: 'white',
          fontWeight: 700,
          fontSize: 16,
          textAlign: 'center',
          pt: 1,
          ...labelStyles,
        }}
      >
        {label}
      </Typography>
    </Box>
  </Stack>
);

Button.propTypes = {
  label: PropTypes.string.isRequired,
  buttonStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClickCallBack: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
//   title: '',
  buttonStyles: null,
  labelStyles: null,
  disabled: false,
};

export default Button;
