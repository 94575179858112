const displayNameToken = '{displayName}';

const parseNotificationTemplate = ({
  user,
  template,
  fallbackDisplayName = 'Someone',
}) => {
  const token = displayNameToken;
  const parts = template.split(token);
  const body = (`${parts.length > 1 ? parts[1] : parts[0]}`).trim();

  if (!user) {
    return {
      raw: template.replace(token, fallbackDisplayName),
      parts: {
        displayName: fallbackDisplayName,
        body,
      },
    };
  }

  const abbreviated = user?.role === 'admin' ? '' : '.';
  const displayName = template.includes(token) ? `${user?.first_name} ${user?.last_name}${abbreviated}` : '';

  return {
    parts: {
      displayName,
      body,
    },
    raw: body ? (`${displayName} ${body}`).trim() : '',
  };
};

module.exports = {
  parseNotificationTemplate,
};
