import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setupIonicReact } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { App as CapApp } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
import { useAuthContext } from '../providers/auth-provider';
import {
  updateDeeplinkUrl,
  sessionSelector,
  setInitialLocation,
  setOneTimeScreen,
  setHasSeenReadyToBack,
  handleSessionLogoutState,
  setHasSeenYoureIn,
  setAppStoreUrl,
} from '../redux/slices/session';
import useAppActivityMonitor from './useAppActivityMonitor';
import { registrationRoutes } from '../routes/registrationRoutes';
import useVersionCompatibility from './useVersionCompatibility';
import useSetOneTimeScreen from './useSetOneTimeScreen';
import useIsFirstTimeUser from './useIsFirstTimeUser';
import { WholeMeClient } from '../pages/wholeme/util/wholeme_client';

export const BOOTSTRAP_ERROR_STATES = {
  VERSION_ERROR: 'Version Error',
  VERSION_INCOMPATIBLE: 'Version Incompatible',
  ERROR: 'Error',
};

const setStatusBarStyleLight = async () => {
  await StatusBar.setStyle({ style: Style.Light });
};

const checkTextZoom = async () => {
  const zoomLevel = await TextZoom.getPreferred();
  if (zoomLevel.value !== 1) {
    await TextZoom.set({ value: 1 });
  }
};

const defaultInitialLocations = ['/', '/tabs/feed'];
const registrationRoutesArray = registrationRoutes.map((route) => route.path);
const excludedInitialLocations = defaultInitialLocations.concat(registrationRoutesArray);

const resolveInitialLocation = ({
  oneTimeScreen,
  firstTimeUser,
  deepLink,
  viewCompletedProfile,
  user,
}) => {
  /**
   * Priority locations are listed descending order, so the highest priority will be the last one resolved
   */

  let initialLocation = '/';
  if (oneTimeScreen) {
    initialLocation = '/new-feature';
  }

  if (viewCompletedProfile) {
    initialLocation = '/profile-completed';
  }

  if (deepLink) {
    initialLocation = deepLink;
  }

  if (user?.role === 'backr' && firstTimeUser) {
    initialLocation = '/youre-in';
  }

  return initialLocation;
};

const handleIonBackButton = (ev) => {
  ev.detail.register(10, () => {
    // registering event to disable hardware back button/swipe back
  });
};

export const useBootStrap = () => {
  const {
    user,
    isLoading: isAuthLoading,
    tryAutoLogin,
    isAuthenticated,
    handleUpdateUserLastAccess,
    hasRegistrationCompleteToken,
    hasCompletedProfile,
    logout,
  } = useAuthContext();
  const [isBootstrapped, setIsBootstrapped] = useState(false);
  const { deeplinkUrl, initialLocation } = useSelector(sessionSelector);
  const [error, setError] = useState(null);
  const [landingRoute, setLandingRoute] = useState('/register/login');
  const [isBootstrapLoading, setIsBootstrapLoading] = useState(true);
  const [isPostUserBootstrapLoading, setIsPostUserBootstrapLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const checkVersionCompatibility = useVersionCompatibility();
  const getOneTimeScreen = useSetOneTimeScreen();
  const isFirstTimeUser = useIsFirstTimeUser();

  const clearLoadingState = () => {
    setIsBootstrapLoading(false);
    setIsPostUserBootstrapLoading(false);
  };

  const handleCompleteBootstrap = () => {
    dispatch(updateDeeplinkUrl(null));
    handleUpdateUserLastAccess();
    setIsBootstrapped(true);
    clearLoadingState();
  };

  const retryBootstrap = () => {
    clearLoadingState();
    setError(null);
    logout();
    dispatch(handleSessionLogoutState());
    setRetryCount(retryCount + 1);
  };

  const postUserBootstrap = async (_user) => {
    setIsPostUserBootstrapLoading(true);
    // QUESTION: should doPostUserStuff handle error routing from VerifyActivation?
    // one time screen
    try {
      const oneTimeScreen = await getOneTimeScreen();
      if (oneTimeScreen) {
        dispatch(setOneTimeScreen(oneTimeScreen));
      }

      // first time user
      const firstTimeUser = await isFirstTimeUser(_user.id);
      if (!firstTimeUser) {
        dispatch(setHasSeenReadyToBack(true));
        dispatch(setHasSeenYoureIn(true));
      }

      // profile completed
      let routeToProfileCompleted = false;
      const wholeMeClient = new WholeMeClient();
      const portraitResult = await wholeMeClient.fetchPortrait();
      if (portraitResult?.portrait?.completed && !hasCompletedProfile) {
        routeToProfileCompleted = true;
      }

      // deeplink
      let deepLink = null;
      if (deeplinkUrl && !excludedInitialLocations.includes(deeplinkUrl)) {
        deepLink = `${deeplinkUrl}`;
      }

      // resolve initial location
      const initial = resolveInitialLocation({
        oneTimeScreen,
        firstTimeUser,
        deepLink,
        viewCompletedProfile: routeToProfileCompleted,
        user,
      });

      console.log('Setting initial location to:', initial);
      dispatch(setInitialLocation(initial));
    } catch (e) {
      console.error(e);
      setError(BOOTSTRAP_ERROR_STATES.ERROR);
    } finally {
      setIsPostUserBootstrapLoading(false);
      setIsBootstrapLoading(false);
    }
  };

  useEffect(() => {
    const bootstrap = async () => {
      setIsBootstrapLoading(true);
      // Ionic config
      setupIonicReact({
        scrollAssist: true,
        scrollPadding: false,
        swipeBackEnabled: false,
      });

      document.addEventListener('ionBackButton', handleIonBackButton);

      // Capacitor config
      await SplashScreen.hide();

      if (Capacitor.isNativePlatform()) {
        Capacitor.addListener('appUrlOpen', ({ url }) => {
          // strip out path and query and go to that page
          const u = new URL(url);
          const pathQuery = u.pathname + u.search;
          dispatch(updateDeeplinkUrl(pathQuery));
        });

        setStatusBarStyleLight();
        checkTextZoom();
      } else {
        const pathQuery = location.pathname + location.search;
        dispatch(updateDeeplinkUrl(pathQuery));
      }

      // App config
      if (Capacitor.isNativePlatform()) {
        try {
          const { isCompatible, appStoreUrl } = await checkVersionCompatibility();
          dispatch(setAppStoreUrl(appStoreUrl));
          if (!isCompatible) {
            setError(BOOTSTRAP_ERROR_STATES.VERSION_INCOMPATIBLE);
            setIsBootstrapLoading(false);
            return;
          }
        } catch (e) {
          setError(BOOTSTRAP_ERROR_STATES.VERSION_ERROR);
          setIsBootstrapLoading(false);
          return;
        }
      }

      const didLogin = await tryAutoLogin();

      if (!didLogin) {
        if (!hasRegistrationCompleteToken && Capacitor.isNativePlatform()) {
          setLandingRoute('/new-user');
        }
        setIsBootstrapLoading(false);
      }
    };

    bootstrap();

    return () => {
      if (Capacitor.isNativePlatform()) {
        CapApp.removeAllListeners();
      }

      document.removeEventListener('ionBackButton', handleIonBackButton);
    };
  }, [retryCount]);

  useEffect(() => {
    const handlePostUserBootstrap = async () => {
      await postUserBootstrap(user);
    };

    if (!isAuthenticated || !user?.id) {
      dispatch(handleSessionLogoutState());
      setIsBootstrapped(false);
    } else {
      handlePostUserBootstrap(user);
    }
  }, [isAuthenticated, user?.id]);

  useEffect(() => {
    if (user && initialLocation && !isBootstrapped) {
      handleCompleteBootstrap();
    }
  }, [user, initialLocation, isBootstrapped]); // all 'required' bootstrap state should be listed as a dependency here

  useAppActivityMonitor(user);

  const isLoadingState = isAuthLoading || isPostUserBootstrapLoading;
  const isBootstrapLoadingState = isBootstrapLoading;

  return {
    isBootstrapped,
    isLoading: isLoadingState,
    isBootstrapLoading: isBootstrapLoadingState,
    error,
    landingRoute,
    retryBootstrap,
  };
};
