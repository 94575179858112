import React from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

const OtpImage = ({ imageUrl, imageWidth }) => {
  if (!imageUrl) return null;
  const isNormalDeviceSize = useMediaQuery('(min-width:376px)');
  return imageUrl.endsWith('.svg') ? (
    <object
      className="no-pointer-events"
      type="image/svg+xml"
      data={imageUrl}
      style={{
        width: imageWidth,
        marginTop: isNormalDeviceSize ? '60px' : '20px',
        paddingLeft: '24px',
        paddingRight: '24px',
        boxSizing: 'border-box',
      }}
      aria-label="decoration"
    />
  ) : (
    <img
      src={imageUrl}
      alt="decorative img"
      style={{ display: 'block', width: imageWidth, marginTop: '20px' }}
    />
  );
};

// todo: Possible refinement:
// handle the special subheader case `...number has been sent to ###` in the component.
// Possibly keyed off whether or not a phone number is provided.

const OtpContent = ({
  header, subHeader, phone, imageUrl, imageWidth, children,
}) => {
  const isNormalDeviceSize = useMediaQuery('(min-width:376px)');

  return (
    <Stack alignItems="center" mt={isNormalDeviceSize ? 3 : 0}>
      {React.isValidElement(header)
        ? header
        : (
          <Typography
            sx={{
              color: '#03295F',
              maxWidth: '28ch',
              fontSize: 20,
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            {header}
          </Typography>
        )}
      {subHeader && (
        <Typography
          sx={{
            color: '#2d2d2d',
            maxWidth: '28ch',
            lineHeight: 1.3125,
            textAlign: 'center',
            mt: 1.5,
          }}
        >
          {subHeader}
          {phone && (
            <Typography
              component="span"
              sx={{ color: '#063D8F', fontWeight: 700 }}
            >
              {` ${phone.slice(-4)}`}
            </Typography>
          )}
        </Typography>
      )}
      <Stack alignItems="center" sx={{ mt: isNormalDeviceSize ? 4 : 1.5 }}>
        {children}
      </Stack>
      <OtpImage imageUrl={imageUrl} imageWidth={imageWidth} />
    </Stack>
  );
};

OtpImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
};

OtpImage.defaultProps = {
  imageWidth: '100%',
};

OtpContent.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subHeader: PropTypes.string,
  phone: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

OtpContent.defaultProps = {
  subHeader: null,
  phone: null,
  imageUrl: '',
  imageWidth: '100%',
};

export default OtpContent;
