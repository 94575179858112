import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isAfter, subDays, parseISO } from 'date-fns';
import {
  Grid, Box, Avatar, Stack, Typography,
} from '@mui/material';
import './TeamDetailsBackrPair.css';
import shareBackrsInvite from '../../common/shareBackrsUtil';
import { useAppState } from '../../providers/app-state/app-state-provider';
import appStrings from '../../common/app_strings';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const TeamDetailsBackrPair = ({ protegeName, backrPair, first }) => {
  const firstBackr = backrPair[0] || backrPair;
  const secondBackr = backrPair[1] || null;
  const history = useHistory();

  const { platform } = useAppState();
  const { hamburgerMenu: { sendInviteWeb } } = appStrings;

  const linkToProfile = (backr) => {
    if (!backr) return;
    const role = backr?.related_profile?.role === 'admin' ? '?role=admin' : '';
    history.push(`/profile/${backr?.related_user_id}${role}`);
  };

  const linkToFirst = () => linkToProfile(firstBackr);
  const linkToSecond = () => linkToProfile(secondBackr);

  const handleShareInvite = async () => {
    await shareBackrsInvite();
  };

  const ReferAFriend = `Refer a friend to ${protegeName}'s team`;
  const ReferFriendAvatarBox = (
    <Box
      sx={{
        position: 'relative',
        '&::after': {
          position: 'absolute',
          display: 'inline-block',
          content: '""',
          backgroundColor: '#c3c3b9',
          width: '1px',
          height: '125%',
          top: '-25%',
          left: 0,
          transform: 'rotate(90deg)',
          zIndex: -2,
        },
      }}
    >
      <Avatar
        sx={{
          color: '#2d2d2d',
          bgcolor: '#e8e8dc',
          width: 108,
          height: 108,
          fontSize: 11,
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {platform?.isNativePlatform ? ReferAFriend : <a style={{ textDecoration: 'none', color: '#2D2D2D' }} href={sendInviteWeb}>{ReferAFriend}</a>}
      </Avatar>
      <Box
        sx={{
          position: 'absolute',
          top: '-5px',
          left: '-5px',
          width: 118,
          height: 118,
          bgcolor: '#f4f4ee',
          border: '2px dashed #c3c3b9',
          zIndex: -1,
          borderRadius: '50%',
        }}
      />
    </Box>
  );

  if (first && firstBackr) {
    return (
      <>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              position: 'relative',
              '&::before': {
                position: 'absolute',
                display: 'inline-block',
                content: '""',
                backgroundColor: '#03295F',
                width: '1px',
                height: '125%',
                top: '-50%',
                left: '75%',
                transform: 'rotate(30deg)',
                zIndex: -2,
              },
            }}
            onClick={linkToFirst}
          >
            {isAfter(
              parseISO(firstBackr?.updated_at),
              subDays(new Date(), 5),
            ) && firstBackr?.relationship === 'backing' ? (
              <img
                className="new-tag"
                alt="new badge"
                src="./assets/NewBadge.svg"
              />
              ) : null}
            <Avatar
              src={firstBackr?.related_profile?.profile_image}
              sx={{
                width: 108,
                height: 108,
                fontSize: 60,
              }}
            >
              <img src={defaultProfileImage} alt="profile" />
            </Avatar>
            {firstBackr?.relationship === 'proposed_match' ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '-5px',
                  left: '-5px',
                  width: 118,
                  height: 118,
                  bgcolor: '#f4f4ee',
                  border: '2px dashed #fd5c36',
                  zIndex: -1,
                  borderRadius: '50%',
                }}
              />
            ) : null}
            <Box
              sx={{
                position: 'relative',
                top: -12,
                width: '100%',
                maxWidth: 108,
                background:
                  firstBackr?.relationship === 'proposed_match'
                    ? '#fd5c36'
                    : '#03295f',
                py: 0.35,
                borderRadius: 2,
                zIndex: 10,
              }}
            >
              <Stack spacing={-0.5} px={0.5}>
                <Typography
                  className="name-truncate"
                  align="center"
                  sx={{ color: '#fff', fontSize: 13, fontWeight: 700 }}
                >
                  {firstBackr?.related_profile?.first_name
                  && firstBackr?.related_profile?.last_name
                    ? `${
                      firstBackr?.related_profile?.first_name
                    } ${firstBackr?.related_profile?.last_name.charAt(0)}.`
                    : 'Backr'}
                </Typography>
                <Typography
                  align="center"
                  sx={{
                    color: '#fff',
                    fontSize: 10,
                    fontWeight: 700,
                    textTransform: 'capitalize',
                  }}
                >
                  {firstBackr?.relationship === 'proposed_match'
                    ? 'Pending Match'
                    : firstBackr?.related_profile?.role}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        {secondBackr ? (
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                position: 'relative',

                '&::before': {
                  position: 'absolute',
                  display: 'inline-block',
                  content: '""',
                  backgroundColor: '#03295F',
                  width: '1px',
                  height: '125%',
                  top: '-50%',
                  right: '75%',
                  transform: 'rotate(-30deg)',
                  zIndex: -2,
                },
                '&::after': {
                  position: 'absolute',
                  display: 'inline-block',
                  content: '""',
                  backgroundColor: '#03295F',
                  width: '1px',
                  height: '125%',
                  top: '-25%',
                  left: 0,
                  transform: 'rotate(90deg)',
                  zIndex: -2,
                },
              }}
              onClick={linkToSecond}
            >
              {isAfter(
                parseISO(secondBackr?.updated_at),
                subDays(new Date(), 5),
              ) && secondBackr?.relationship === 'backing' ? (
                <img
                  className="new-tag"
                  alt="new badge"
                  src="./assets/NewBadge.svg"
                />
                ) : null}
              <Avatar
                src={secondBackr?.related_profile?.profile_image}
                sx={{
                  width: 108,
                  height: 108,
                  fontSize: 60,
                }}
              >
                <img src={defaultProfileImage} alt="profile" />
              </Avatar>
              {secondBackr?.relationship === 'proposed_match' ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-5px',
                    left: '-5px',
                    width: 118,
                    height: 118,
                    bgcolor: '#f4f4ee',
                    border: '2px dashed #fd5c36',
                    zIndex: -1,
                    borderRadius: '50%',
                  }}
                />
              ) : null}
              <Box
                sx={{
                  position: 'relative',
                  top: -12,
                  width: '100%',
                  maxWidth: 108,
                  background:
                    secondBackr?.relationship === 'proposed_match'
                      ? '#fd5c36'
                      : '#03295f',
                  py: 0.35,
                  borderRadius: 2,
                  zIndex: 10,
                }}
              >
                <Stack spacing={-0.5} px={0.5}>
                  <Typography
                    className="name-truncate"
                    align="center"
                    sx={{ color: '#fff', fontSize: 13, fontWeight: 700 }}
                  >
                    {secondBackr?.related_profile?.first_name
                    && secondBackr?.related_profile?.last_name
                      ? `${
                        secondBackr?.related_profile?.first_name
                      } ${secondBackr?.related_profile?.last_name.charAt(0)}.`
                      : 'Backr'}
                  </Typography>
                  <Typography
                    align="center"
                    sx={{
                      color: '#fff',
                      fontSize: 10,
                      fontWeight: 700,
                      textTransform: 'capitalize',
                    }}
                  >
                    {secondBackr?.relationship === 'proposed_match'
                      ? 'Pending Match'
                      : secondBackr?.related_profile?.role}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'center' }}
            onClick={platform?.isNativePlatform ? handleShareInvite : null}
          >
            {ReferFriendAvatarBox}
          </Grid>
        )}
      </>
    );
  }
  return (
    <>
      <Grid
        item
        xs={6}
        sx={{ display: 'flex', justifyContent: 'center', mt: 3.5 }}
      >
        <Box
          sx={{
            position: 'relative',
            '&::before': {
              position: 'absolute',
              display: 'inline-block',
              content: '""',
              backgroundColor: '#03295F',
              width: '1px',
              height: '120%',
              top: '-50%',
              left: '50%',
              zIndex: -2,
            },
          }}
          onClick={linkToFirst}
        >
          {isAfter(parseISO(firstBackr?.updated_at), subDays(new Date(), 5))
          && firstBackr?.relationship === 'backing' ? (
            <img
              className="new-tag"
              alt="new badge"
              src="./assets/NewBadge.svg"
            />
            ) : null}
          <Avatar
            src={firstBackr?.related_profile?.profile_image}
            sx={{
              width: 108,
              height: 108,
              fontSize: 60,
            }}
          >
            <img src={defaultProfileImage} alt="profile" />
          </Avatar>
          {firstBackr?.relationship === 'proposed_match' ? (
            <Box
              sx={{
                position: 'absolute',
                top: '-5px',
                left: '-5px',
                width: 118,
                height: 118,
                bgcolor: '#f4f4ee',
                border: '2px dashed #fd5c36',
                zIndex: -1,
                borderRadius: '50%',
              }}
            />
          ) : null}
          <Box
            sx={{
              position: 'relative',
              top: -12,
              width: '100%',
              maxWidth: 108,
              background:
                firstBackr?.relationship === 'proposed_match'
                  ? '#fd5c36'
                  : '#03295f',
              py: 0.35,
              borderRadius: 2,
              zIndex: 10,
            }}
          >
            <Stack spacing={-0.5} px={0.5}>
              <Typography
                className="name-truncate"
                align="center"
                sx={{
                  color: '#fff',
                  fontSize: 13,
                  fontWeight: 700,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {firstBackr?.related_profile?.first_name
                && firstBackr?.related_profile?.last_name
                  ? `${
                    firstBackr?.related_profile?.first_name
                  } ${firstBackr?.related_profile?.last_name.charAt(0)}.`
                  : 'Backr'}
              </Typography>
              <Typography
                align="center"
                sx={{
                  color: '#fff',
                  fontSize: 10,
                  fontWeight: 700,
                  textTransform: 'capitalize',
                }}
              >
                {firstBackr?.relationship === 'proposed_match'
                  ? 'Pending Match'
                  : firstBackr?.related_profile?.role}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      {secondBackr ? (
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'center', mt: 3.5 }}
        >
          <Box
            sx={{
              position: 'relative',

              '&::before': {
                position: 'absolute',
                display: 'inline-block',
                content: '""',
                backgroundColor: '#03295F',
                width: '1px',
                height: '120%',
                top: '-50%',
                right: '50%',
                zIndex: -2,
              },
              '&::after': {
                position: 'absolute',
                display: 'inline-block',
                content: '""',
                backgroundColor: '#03295F',
                width: '1px',
                height: '125%',
                top: '-25%',
                left: 0,
                transform: 'rotate(90deg)',
                zIndex: -2,
              },
            }}
            onClick={linkToSecond}
          >
            {isAfter(
              parseISO(secondBackr?.updated_at),
              subDays(new Date(), 5),
            ) && secondBackr?.relationship === 'backing' ? (
              <img
                className="new-tag"
                alt="new badge"
                src="./assets/NewBadge.svg"
              />
              ) : null}
            <Avatar
              src={secondBackr?.related_profile?.profile_image}
              sx={{
                width: 108,
                height: 108,
                fontSize: 60,
              }}
            >
              <img src={defaultProfileImage} alt="profile" />
            </Avatar>
            {secondBackr?.relationship === 'proposed_match' ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '-5px',
                  left: '-5px',
                  width: 118,
                  height: 118,
                  bgcolor: '#f4f4ee',
                  border: '2px dashed #fd5c36',
                  zIndex: -1,
                  borderRadius: '50%',
                }}
              />
            ) : null}
            <Box
              sx={{
                position: 'relative',
                top: -12,
                width: '100%',
                maxWidth: 108,
                background:
                  secondBackr?.relationship === 'proposed_match'
                    ? '#fd5c36'
                    : '#03295f',
                py: 0.35,
                borderRadius: 2,
                zIndex: 10,
              }}
            >
              <Stack spacing={-0.5} px={0.5}>
                <Typography
                  className="name-truncate"
                  align="center"
                  sx={{ color: '#fff', fontSize: 13, fontWeight: 700 }}
                >
                  {secondBackr?.related_profile?.first_name
                  && secondBackr?.related_profile?.last_name
                    ? `${
                      secondBackr?.related_profile?.first_name
                    } ${secondBackr?.related_profile?.last_name.charAt(0)}.`
                    : 'Backr'}
                </Typography>
                <Typography
                  align="center"
                  sx={{
                    color: '#fff',
                    fontSize: 10,
                    fontWeight: 700,
                    textTransform: 'capitalize',
                  }}
                >
                  {secondBackr?.relationship === 'proposed_match'
                    ? 'Pending Match'
                    : secondBackr?.related_profile?.role}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'center', mt: 3.5 }}
          onClick={platform?.isNativePlatform ? handleShareInvite : null}
        >
          {ReferFriendAvatarBox}
        </Grid>
      )}
    </>
  );
};

TeamDetailsBackrPair.propTypes = {
  protegeName: PropTypes.string,
  backrPair: PropTypes.arrayOf(
    PropTypes.shape({
      relationship: PropTypes.string,
      updated_at: PropTypes.string,
      related_user_id: PropTypes.number,
      related_profile: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        profile_image: PropTypes.string,
      }),
    }),
  ),
  first: PropTypes.bool,
};

TeamDetailsBackrPair.defaultProps = {
  protegeName: 'this protege',
  backrPair: null,
  first: false,
};

export default TeamDetailsBackrPair;
