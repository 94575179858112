import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import './TermsAndConditionsContent.css';

const TermsAndConditionsContent = ({ whiteBgModal }) => (
  <div className={whiteBgModal ? 'white-terms-content' : 'terms-content'}>
    <Stack spacing={2}>
      <p>LAST UPDATE: [7/20/22]</p>
      <p>
        THIS WEBSITE IS OWNED AND OPERATED BY BACKRS, LLC (&quot;BACKRS&quot;,
        &quot;WE&quot;, &quot;OUR&quot; AND &quot;US&quot;). ACCESS AND USE OF
        THIS WEBSITE AND ITS RELATED SERVICES AND WEB PAGES (COLLECTIVELY, THE
        &quot;SITE&quot;) ARE PROVIDED BY BACKRS TO YOU ON CONDITION THAT YOU
        ACCEPT THESE TERMS OF USE, AND BY ACCESSING OR USING THIS PLATFORM, YOU
        AGREE TO THESE TERMS OF USE.&nbsp; IF YOU DO NOT AGREE TO ACCEPT AND
        ABIDE BY THESE TERMS OF USE YOU SHOULD NOT ACCESS OR USE THIS PLATFORM.
      </p>
      <p>
        These Terms of Use, the&nbsp;
        <a
          href="https://backrs.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        &nbsp;and all other documents referenced herein, including Our
        Commitment to Community Safety govern the relationship between you, the
        Platform visitor (&quot;you&quot;) and Backrs with respect to your use
        of the Platform.&nbsp; You agree that the agreement formed by these
        Terms of Use is like any written negotiated agreement signed by you, and
        you agree to be bound by, and fully comply with, its terms.&nbsp; You
        represent and warrant that you have all necessary right, power and
        authority to enter into this agreement and to perform and otherwise
        discharge all of your obligations hereunder.&nbsp; You must review and
        accept these Terms of Use before you can use the Platform.&nbsp; To use
        the Platform, and to create an account on the Platform, you must be 18
        or older (or be 13 or older and have your parent or guardian’s
        consent).&nbsp; If you are under 13, you are not permitted to use the
        Platform or create an account, and your parent or guardian must control
        and manage any account created on your behalf.
      </p>
      <p>
        We reserve the right at any time to change: (i) these Terms of Use; (ii)
        this Platform, including terminating, eliminating, supplementing,
        modifying, adding or discontinuing any content or feature or data or
        service on or available through this Platform or the hours that they are
        available; (iii) the equipment, hardware or software required to use and
        access this Platform, and (iv) prices for our products and
        services.&nbsp; We shall not be liable to you or any third party for any
        modification, price change, suspension, or discontinuance of the
        Platform, or any products or services available on the Platform.
      </p>
      <p>
        Any changes we make to these Terms of Use will be effective immediately
        upon notice, which we may provide by any means including, without
        limitation, posting on this Platform.&nbsp; Your continued use of this
        Platform after such notice will be deemed acceptance of such
        changes.&nbsp; Be sure to return to this Platform periodically to ensure
        you are familiar with the most current version of these Terms of Use.
      </p>
      <p>
        You may not interfere with the security of, or otherwise abuse this
        Platform or any system resources, services or networks connected to or
        accessible through this Platform.&nbsp; You may only use this Platform
        for lawful purposes.
      </p>
      <p>
        <strong>1. JURISDICTION</strong>
      </p>
      <p>
        If you are residing in a jurisdiction that restricts the ability to
        enter into agreements such as set out in these Terms of Use, you may not
        enter into this agreement or use this Platform.
      </p>
      <p>
        Furthermore, if you are residing in a jurisdiction where it is forbidden
        by law to participate in the activities or services offered by this
        Platform, you may not enter into this agreement or use this
        Platform.&nbsp; By using this Platform you are explicitly stating that
        you have verified in your own jurisdiction if your use of this Platform
        is allowed.
      </p>
      <p>
        We make no representation that materials in this Platform are
        appropriate or available for use in all locations.&nbsp; Those who
        choose to access the Platform do so on their own initiative and at their
        own risk, and are responsible for compliance with local laws, if and to
        the extent local laws are applicable.
      </p>
      <p>
        We reserve the right to limit the availability of the Platform,
        materials, or other products described or offered thereon to any person,
        geographic area, or jurisdiction we so desire, at any time and in our
        sole discretion, and to limit the quantities of any such services,
        materials, or other product provided.
      </p>
      <p>
        <strong>2. GENERAL CONDITIONS AND DISCLAIMERS</strong>
      </p>
      <p>
        We reserve the right to refuse service to anyone at any
        time.&nbsp;&nbsp;
      </p>
      <p>
        Backrs shall have no liability for interruptions or omissions in
        Internet, network or hosting services and does not warrant that the
        Platform or the services which make this Platform available or
        electronic communications sent by Backrs are free from viruses or any
        other harmful elements.&nbsp;&nbsp;
      </p>
      <p>
        Any material downloaded or otherwise obtained through the use of this
        Platform is done at your own discretion and risk.&nbsp; You will be
        solely responsible for any damage to your computer system or loss of
        data that results from the download of any such material.
      </p>
      <p>
        Occasionally there may be information on our Platform that contains
        typographical errors, inaccuracies or omissions. We reserve the right to
        correct any errors, inaccuracies or omissions, and to change or update
        if any information in the Platform or on any related website is
        inaccurate at any time without prior notice.
      </p>

      <p>
        We undertake no obligation to update, amend or clarify information in
        the Platform or on any related website except as required by law. No
        specified update or refresh date applied in the Platform or on any
        related website, should be taken to indicate that all information in the
        Platform or on any related website has been modified or updated.
      </p>
      <p>
        <strong>3. ACCESSING THE PLATFORM AND CONTENT ON THE PLATFORM</strong>
      </p>
      <p>
        You may access the Platform from an internet browser. Although we are
        working to ensure that the Platform is compatible across various
        devices, we cannot guarantee that the&nbsp; Platform will work with all
        devices.&nbsp;
      </p>
      <p>
        It is your responsibility to ensure that you are able to comply with the
        relevant system requirements. We accept no responsibility for any lack
        of functionality that is due to your equipment (including your device,
        internet connection, operating system or settings and software). Access
        to parts or all of the Platform and any or all content on the Platform
        may be restricted from time to time to allow for repairs, maintenance or
        updating.
      </p>
      <p>
        It is your responsibility to pay for all costs and expenses (including
        all telephone call or line charges or Internet service data access) that
        you may incur using the Platform.
      </p>
      <p>
        Your use of the&nbsp; Platform and enjoyment of its features and content
        hosted or made available through the Platform may vary in functionality,
        availability and quality depending on the type of device and operating
        system and any restrictions imposed by our content providers.
      </p>
      <p>
        <strong>4. PRIVACY</strong>
      </p>
      <p>
        Backrs is committed to respecting the privacy of the personal
        information of the individuals with whom we interact.&nbsp; We have
        developed a Privacy Policy to describe our privacy practices and how we
        collect, use and disclose the personal information of those individuals
        who visit this Platform.&nbsp; Please see our&nbsp;
        <a
          href="https://backrs.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        &nbsp;for further information.
      </p>
      <p>
        <strong>5. USER ACCOUNTS AND ACCURACY OF INFORMATION</strong>
      </p>
      <p>
        In order to access and use certain services available on this Platform,
        you may be required to open and maintain an account
        (&quot;Account&quot;) with us. You must be 18 or older (or be 13 or
        older and have your parent or guardian’s permission) to open an
        Account.&nbsp; If you are under 13, you are not permitted to use the
        Platform or create an Account, and your parent or guardian must control
        and manage any Account created on your behalf.&nbsp; During the
        registration process, we will collect personal information that you
        provide.&nbsp; All of the information you provide to us or that we
        collect from you in connection with your use of the Platform will be
        governed by these Terms of Use and our&nbsp;
        <a
          href="https://backrs.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy.
        </a>
        &nbsp;You agree at all times to: (i) provide accurate, current and
        complete information about yourself as prompted by our registration form
        or otherwise; and (ii) maintain and update your information (including
        your e-mail address) to keep it accurate, current and complete.&nbsp;
        You acknowledge that, if any information provided by you is untrue,
        inaccurate, not current or incomplete, we may terminate your Account and
        your ability to use this Platform and, in our sole discretion, to report
        you to the appropriate authorities.
      </p>
      <p>
        You understand and agree that, in addition to the foregoing
        restrictions, we reserve the right to change, remove, alter or delete
        any Account at any time and for any reason in our sole discretion.&nbsp;
        You will be responsible for the confidentiality and use of your username
        and password and agree not to transfer your right to use or access this
        Platform via your username or password to any third person.
      </p>
      <p>
        YOU ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR
        USERNAME AND PASSWORD AND FOR ANY AND ALL ACTIVITIES THAT ARE CONDUCTED
        THROUGH YOUR ACCOUNT.
      </p>
      <p>
        You agree to notify us immediately of any unauthorized use, theft or
        misappropriation of your Account, username, or password.&nbsp; We shall
        not be liable for any loss that you incur as a result of someone else
        using your username or password, either with or without your knowledge.
      </p>
      <p>
        Information submitted by users will be transmitted to and shared with
        third parties that may be located in other countries, in order to
        provide services to such users, including but not limited to transaction
        processing and fraud prevention.
      </p>
      <p>
        <strong>6. USER RESPONSIBILITIES</strong>
      </p>
      <p>You agree:&nbsp;</p>
      <p>
        (a) that you will not use the Platform for any illegal purpose or in any
        way that interrupts, damages, or impairs the service provided by the
        Platform,&nbsp;
      </p>
      <p>
        (b) to comply with our&nbsp;
        <a
          href="https://backrs.com/community-standards"
          rel="noreferrer"
          target="_blank"
        >
          Commitment to Community Safety,
        </a>
      </p>
      <p>
        (c) that you will not access or attempt to access the Accounts of other
        users of the Platform,&nbsp;
      </p>
      <p>
        (d) that you will not impersonate any person, or misrepresent your
        identity or affiliation with any person,&nbsp;
      </p>
      <p>
        (e) not to harass, bully, stalk, intimidate, assault, defame, harm, or
        otherwise mistreat any person,
      </p>
      <p>
        (f) use the Platform in relation to fraud, a pyramid scheme, or other
        similar practice,
      </p>
      <p>
        (g) not to post or transmit through the Platform any User Content which
        is or could reasonably be viewed as (i) hate speech, obscene, harassing,
        threatening, pornographic, abusive or defamatory to an individual or
        group of individuals on the basis of religious belief, race, gender,
        age, disability or otherwise, (ii) inciting violence, or containing
        nudity or graphic or gratuitous violence, (iii) an unauthorized
        commercial communication of any kind (including, without limitation,
        spam), (iv) fraudulent, inaccurate or misleading, or otherwise
        objectionable content of any kind, (v) infringing, or violating someone
        else’s rights or otherwise violating the law, (vi) identifying any
        person without their consent or disclosing anyone else’s personal
        contact details or invading their privacy, or (viii) containing software
        viruses or any other computer code, files, or programs designed to
        interrupt, destroy, or limit the functionality of the Platform, or any
        computer software or hardware or telecommunications equipment,&nbsp;
      </p>
      <p>
        (h)&nbsp;that any content you post or upload to the Platform or
        otherwise make available via the Platform is owned by you and does not
        breach the requirements set out in subsections (e)(i)-(viii)
        above,&nbsp;
      </p>
      <p>
        (i)&nbsp;to refrain from doing anything which is defamatory, offensive,
        damaging or which we believe might damage our reputation, or that of the
        Platform, or a provider of services accessed through the Platform,&nbsp;
      </p>
      <p>
        (j)&nbsp;not to copy the Platform or copy or share any User Content on
        the Platform (which includes, without limitation, sharing User Content
        outside the particular group on the Platform where the User Content is
        shared) except where such copying is incidental to the normal
        non-commercial use of the Platform, or where it is necessary for the
        purpose of back-up or operational security,&nbsp;
      </p>
      <p>
        (k)&nbsp;not to make alterations to, or modifications of, the whole or
        any part of the Platform or any content on the Platform, or permit the
        Platform or any part of it to be combined with, or become incorporated
        in, any other programs,&nbsp;
      </p>
      <p>
        (l)&nbsp;not to infringe our intellectual property or our licensors’
        intellectual property in relation to your use of the Platform,
      </p>
      <p>
        (m)&nbsp;that you are solely responsible for your interactions with
        other users and users through the Platform,&nbsp;
      </p>
      <p>
        (n)&nbsp;that you will not collect other users’ content or information,
        or otherwise access the Platform, using automated means (such as
        harvesting bots, robots, spiders or scraping techniques) or otherwise,
        without our prior written permission,&nbsp;
      </p>
      <p>
        (o) that any User Content you upload or post to the Platform (with the
        exception of private messages, which are confidential in nature) will be
        considered non-confidential and non-proprietary and that such content
        may be viewable by any users of the Platform (whether registered or
        unregistered),&nbsp;
      </p>
      <p>
        (p) to compensate and defend us fully against any claims or legal
        proceedings brought against us by any person as a result of your breach
        of these Terms,&nbsp;
      </p>
      <p>
        (q)&nbsp;to keep your password secure at all times and not to disclose
        your password to any other person,&nbsp;
      </p>
      <p>
        (r)&nbsp;not to allow any other person to use or access your Account;
        and&nbsp;
      </p>
      <p>(s)&nbsp;to comply with all laws applicable to you.</p>
      <p>
        Our community may flag content that is copyrighted, offensive, or
        otherwise in violation of the foregoing responsibilities. We reserve the
        right to terminate or suspend your account in the event you fail to
        comply with any of the foregoing responsibilities.
      </p>
      <p>
        <strong>7. USER CONTRIBUTIONS</strong>
      </p>
      <p>
        The Platform may allow you to submit, communicate, upload, or otherwise
        make available ideas, suggestions, messages, documents, proposals,
        photographs, audio, video, and other content (&quot;User Content&quot;),
        which may be accessible and viewable by other users of the Platform and
        used by Backrs in accordance with our&nbsp;
        <a
          href="https://backrs.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy.
        </a>
        &nbsp;You alone are responsible for your User Content, and once
        published, it cannot always be withdrawn. You assume all risks
        associated with your User Content, including anyone’s reliance on its
        accuracy and your right to publish it. You represent that you own, or
        have the necessary permissions to use and authorize the use of your User
        Content as described herein. You may not imply that your User Content is
        in any way sponsored or endorsed by Backrs.
      </p>
      <p>
        We may use your User Content in a number of different ways, including
        publicly displaying and reformatting it. As such, you hereby irrevocably
        grant us, our licensees and any third parties and other users of the
        Platform (each as we determine) a world-wide, perpetual, irrevocable,
        non-exclusive, royalty-free, transferable, sublicensable license to use,
        store and copy your User Content and to distribute it and make it
        available to third parties via any and all media, including without
        limitation, the right for us to upload and make available and to
        authorize third parties to upload and make available, such content on
        third party Platform and services, including social media applications
        and channels such as Facebook, Twitter, Pinterest, YouTube, and
        Instagram without limit as to time, manner, and frequency of use,
        without further notice to you, with or without attribution, and without
        the requirement of permission from or payment to you or any other person
        or entity. You also irrevocably granter the users of the Platform the
        right to access your User Content in connection with their use of the
        Platform. Finally, you irrevocably waive, and cause to be waived,
        against Backrs and its users, any claims and assertions of moral rights
        or attribution with respect to User Content. By &quot;use&quot; we mean
        use, copy, publicly perform or display, distribute, modify, translate,
        and create derivative works of User Content.
      </p>
      <p>
        We reserve the right to moderate, remove, screen, edit, or reinstate
        your User Content from time to time in accordance with Our Commitment to
        Community Safety and otherwise at our sole discretion and without notice
        to you, including, without limitation, when your User Content is
        factually inaccurate. We also reserve the right to terminate an Account
        if we believe a user is posting infringing material or has violated Our
        Commitment to Community Safety or these Terms.&nbsp;&nbsp;
      </p>
      <p>
        By submitting User Content to Backrs through its suggestion or feedback
        webpages, via email, or otherwise, you acknowledge and agree that: (a)
        User Content does not contain confidential or proprietary information;
        (b) Backrs may have something similar to the User Content already under
        consideration or in development; (c) We have no obligation to retain or
        provide you with copies of user Content, nor do we guarantee any
        confidentiality with respect to your User Content.
      </p>
      <p>
        You understand that User Content (not including credit card information)
        and account information may be transferred unencrypted and involve (a)
        transmissions over various networks, and (b) changes to conform and
        adapt to technical requirements of connecting networks or devices.&nbsp;
        Credit card information is always encrypted during transfer over
        networks.
      </p>
      <p>
        <strong>7. PERSONAL USE ONLY</strong>
      </p>
      <p>
        This Platform is only for personal or internal business use.&nbsp; You
        may not use this Platform in any way that is unlawful, or harms us or
        any other person or entity, as determined in our sole discretion.
      </p>
      <p>
        <strong>8. CODE OF CONDUCT</strong>
      </p>
      <p>
        You agree that you will not attempt to, nor permit any third party to,
        enter restricted areas of Backrs’ computer systems or perform functions
        that you are not authorized to perform pursuant to these Terms of
        Use.&nbsp; While using this Platform you agree to comply with all
        applicable laws, rules and regulations (including, but not limited to,
        laws relating to privacy, obscenity, copyright, trademark, confidential
        information, trade secret, libel, slander or defamation). &nbsp; You
        shall not interfere with or disrupt the Platform, our servers or our
        networks, or take any action that imposes unreasonably or
        disproportionately large load on our infrastructure.
      </p>
      <p>
        We do not tolerate inappropriate content or behavior on the
        Platform.&nbsp; We encourage you to report any inappropriate User
        Content or misconduct by other users.&nbsp; You can report a user
        directly through the &quot;Report a Concern&quot; link on a user’s
        profile or by contacting us using the Contact Us form on the Platform.
      </p>
      <p>
        Though we strive to encourage a respectful user experience, we are not
        responsible for the conduct of any user on or off the Platform.&nbsp;
        You agree to use caution in all interactions with other users,
        particularly if you decide to communicate off the Platform or meet in
        person.
      </p>
      <p>
        <strong>9. COPYRIGHT INFRINGEMENT</strong>
      </p>
      <p>
        If you know or suspect that any of the materials on the Platform have
        been used or copied in a way that constitutes copyright infringement,
        please send notice to our designated agent identified below.&nbsp;
        According to the U.S. Digital Millennium Copyright Act, 17 U.S.C. §
        512(c)(3), your notice must comply with the following requirements:
      </p>
      <ul data-rte-list="default">
        <li>
          <p>
            A physical or electronic signature of the copyright owner or person
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed;
          </p>
        </li>
        <li>
          <p>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works at a single online
            Platform are covered by a notification, a representative list of
            such works at that Platform;
          </p>
        </li>
        <li>
          <p>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit us to locate the material;
          </p>
        </li>
        <li>
          <p>
            Information reasonably sufficient to permit us to contact you, such
            as address, telephone number, and, if available, an electronic mail
            address at which you may be contacted;
          </p>
        </li>
        <li>
          <p>
            A statement by you that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owners, its agent or the law; and
          </p>
        </li>
        <li>
          <p>
            A statement that the information in the notification is accurate and
            under penalty of perjury, that you are the copyright owner or
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
          </p>
        </li>
      </ul>
      <p>
        The designated agent for notice of copyright infringement claims may be
        reached by sending an email to marni.pastor@backrs.com.
      </p>
      <p>
        The preceding information is provided exclusively for notifying us that
        your copyrighted material may have been infringed.&nbsp; All other
        inquiries, such as product related questions and requests or concerns
        regarding improper postings and/or content, will not receive a response
        through this process.
      </p>
      <p>
        <strong>10 AVAILABILITY</strong>
      </p>
      <p>
        While we endeavor to keep downtime to a minimum, we cannot promise that
        this Platform will be uninterrupted, secure or error-free.&nbsp; We
        reserve the right to interrupt/suspend this Platform, or any part
        thereof, with or without prior notice for any reason and you shall not
        be entitled to any refunds of fees for interruption of this Platform.
      </p>
      <p>
        <strong>11. CURRENCY OF WEBSITE</strong>
      </p>
      <p>
        Backrs updates the information on this Platform periodically.&nbsp;
        However, Backrs cannot guarantee or accept any responsibility or
        liability for the accuracy, currency or completeness of the information
        on this Platform.&nbsp; Backrs may revise, supplement or delete
        information, services and/or the resources contained in this Platform
        and reserves the right to make such changes without prior notification
        to past, current or prospective visitors.
      </p>
      <p>
        <strong>12. LINKED WEBSITES&nbsp;</strong>
      </p>
      <p>
        This Platform may provide links to third party websites for your
        convenience only.&nbsp; The inclusion of these links does not imply that
        Backrs monitors or endorses these websites or their respective
        practices.&nbsp; Backrs does not accept any responsibility for such
        websites.&nbsp; Backrs shall not be responsible or liable, directly or
        indirectly, for any damage or loss, caused or alleged to be caused by or
        in connection with the use of or the reliance upon any information,
        content, goods or services available on or through any third party
        websites or linked resources.&nbsp; These Terms of Use and our&nbsp;
        <a
          href="https://backrs.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        &nbsp;do not apply to your use of any third party websites, so be sure
        to review any applicable terms and policies of third party Platforms.
      </p>
      <p>
        <strong>13. INTERNET SOFTWARE OR COMPUTER VIRUSES</strong>
      </p>
      <p>
        Due to technical difficulties with the Internet, Internet software or
        transmission problems could produce inaccurate or incomplete copies of
        information contained on this Platform.&nbsp; Due to the ability to
        share certain content and materials, computer viruses or other
        destructive programs may also be inadvertently downloaded from this
        Platform.
      </p>
      <p>
        Backrs shall not be responsible or liable for any software, computer
        viruses or other destructive, harmful or disruptive files or programs
        that may infect or otherwise impact your use of your computer equipment
        or other property on account of your access to, use of, or browsing on
        this Platform or your downloading of any user materials or other content
        from this Platform.&nbsp; Backrs recommends that you install appropriate
        anti-virus or other protective software.
      </p>
      <p>
        <strong>14. OUR MATERIALS</strong>
      </p>
      <p>
        This Platform is owned and operated by Backrs in conjunction with others
        pursuant to contractual arrangements.
      </p>
      <p>
        Unless otherwise specified, all materials appearing on this Platform,
        including the text, Platform design, logos, graphics, icons, and images,
        as well as the selection, assembly and arrangement thereof, are the sole
        property of Backrs and its licensors.&nbsp; ALL RIGHTS RESERVED. &nbsp;
        To the best of our knowledge, the Platform does not include any third
        party intellectual property.
      </p>
      <p>
        Backrs may make certain content, software or other electronic materials
        (including all files, text, URLs, video, audio and images contained in
        or generated by such materials, and accompanying data) (collectively
        &quot;Backrs Materials&quot;) available to you from this Platform, from
        time to time.&nbsp; If you download or use Backrs Materials, you agree
        that such materials are licensed for your limited personal use only and
        not sold or assigned and: (i) may only be used for your personal and
        non-commercial use, (ii) may not be modified, used to create a
        derivative work, incorporated into any other work or otherwise exploited
        without Backrs’ prior written permission, and (iii) shall be only be
        used in compliance with any additional license terms accompanying such
        materials.&nbsp; Backrs does not transfer either the title or the
        intellectual property rights to the Backrs Materials, and retains full
        and complete title to the Backrs Materials as well as all intellectual
        property rights therein.&nbsp; You agree not to sell, share,
        redistribute, or reproduce the Backrs Materials.&nbsp; You further agree
        not to decompile, reverse-engineer, disassemble, or otherwise convert
        any compiled Backrs Materials into a human-perceivable form.&nbsp;
        Backrs or its licensors own all related trademarks and logos, and you
        agree not to copy or use them in any manner.
      </p>
      <p>
        All rights not expressly granted herein are reserved by Backrs.&nbsp;
        Any unauthorized use of the materials appearing on this Platform may
        violate copyright, trademark and other applicable laws and could result
        in criminal or civil penalties.
      </p>
      <p>
        This Platform, (including its organization and presentation and Backrs
        Materials) is the property of Backrs and its licensors and may be
        protected by intellectual property laws including laws relating to
        copyrights, trade-marks, trade-names, internet domain names and other
        similar rights.
      </p>
      <p>
        <strong>15.&nbsp; DISCLAIMERS</strong>
      </p>
      <p>
        WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT, IDENTITY,
        INTENTIONS, LEGITIMACY, OR VERACITY OF PLATFORM USERS.&nbsp; BACKRS
        RESERVES THE RIGHT TO CONDUCT - AND YOU AUTHORIZE BACKRS TO CONDUCT -
        ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENING (INCLUDING SEX OFFENDER
        REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS AND
        BACKRS’ THIRD PARTY BACKGROUND CHECK SERVICES PROVIDER, AND YOU AGREE
        THAT ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT PURPOSE.&nbsp; IF
        WE DECIDE TO CONDUCT ANY SCREENING THROUGH A CONSUMER REPORTING AGENCY,
        YOU HEREBY AUTHORIZE US TO OBTAIN AND USE A CONSUMER REPORT ABOUT YOU TO
        DETERMINE YOUR ELIGIBILITY UNDER THESE TERMS.
      </p>
      <p>
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.&nbsp;
        BACKGROUND CHECKS AND OTHER SCREENINGS DO NOT GUARANTEE YOUR SAFETY AND
        ARE NOT A SUBSTITUTE FOR FOLLOWING OUR&nbsp;
        <a
          href="https://backrs.com/community-standards"
          rel="noreferrer"
          target="_blank"
        >
          COMMITMENT TO COMMUNITY SAFETY
        </a>
        &nbsp;AND OTHER SENSIBLE SAFETY PRECAUTIONS.&nbsp; ALWAYS USE YOUR BEST
        JUDGMENT AND TAKE APPROPRIATE SAFETY PRECAUTIONS WHEN COMMUNICATING WITH
        OR MEETING NEW PEOPLE.&nbsp; COMMUNICATIONS RECEIVED THROUGH THE
        PLATFORM MAY RESULT FROM USERS ENGAGING WITH THE PLATFORM FOR IMPROPER
        PURPOSES, INCLUDING FRAUD, ABUSE, HARASSMENT, OR OTHER SUCH IMPROPER
        BEHAVIOR.
      </p>
      <p>
        BACKRS TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER
        OR THIRD PARTY POSTS, SENDS, OR RECEIVES THROUGH THE PLATFORM, NOR DOES
        BACKRS TAKE ANY RESPONSIBILITY FOR THE IDENTITY, INTENTIONS, LEGITIMACY,
        OR VERACITY OF ANY USERS WITH WHOM YOU MAY COMMUNICATE THROUGH THE
        PLATFORM.
      </p>
      <p>
        THIS PLATFORM, THE SERVICES BACKRS PROVIDES, AND THE BACKRS MATERIALS
        ARE PROVIDED &quot;AS IS&quot; WITHOUT REPRESENTATION, WARRANTY OR
        CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.&nbsp; BACKRS DOES
        NOT REPRESENT OR WARRANT THAT THIS PLATFORM, THE SERVICES, OR THE BACKRS
        MATERIALS WILL MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE SECURE,
        UNINTERRUPTED OR ERROR FREE.&nbsp; SOME STATES AND JURISDICTIONS DO NOT
        ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY
        NOT APPLY TO YOU.&nbsp; WHEN THE IMPLIED WARRANTIES ARE NOT ALLOWED TO
        BE EXCLUDED IN THEIR ENTIRETY, THEY WILL BE LIMITED TO THE SHORTEST
        DURATION PERMITTED BY LAW.&nbsp; YOU MAY ALSO HAVE OTHER RIGHTS WHICH
        VARY FROM STATE-TO-STATE.
      </p>
      <p>
        <strong>16. LIMITATION OF LIABILITY</strong>
      </p>
      <p>
        YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS PLATFORM AND THE
        BACKRS MATERIALS INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION
        CONTAINED THEREIN.
      </p>
      <p>
        IN NO EVENT SHALL BACKRS OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES,
        SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY DIRECT, INCIDENTAL,
        INDIRECT, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR OTHER SIMILAR DAMAGES
        WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF
        BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH
        ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER
        OR OUT OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF,
        RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THIS PLATFORM, THE BACKRS
        MATERIALS OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER,
        OR, IN THE EVENT YOU MAKE A FINANCIAL CONTRIBUTION TO A PROTEGE, THE
        PROTEGE’S USE OF YOUR FINANCIAL CONTRIBUTION, EVEN IF WE HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED
        ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL
        PROPERTY RIGHTS OR OTHERWISE. SOME STATES AND JURISDICTIONS DO NOT ALLOW
        FOR THE LIMITATION OR EXCLUSION OF LIABILITY, SO THE ABOVE LIMITATION OR
        EXCLUSION MAY NOT APPLY TO YOU.
      </p>
      <p>
        <strong>17. INDEMNIFICATION&nbsp;</strong>
      </p>
      <p>
        You agree to, upon request, defend, indemnify and hold us harmless from
        any and all liabilities, claims, losses and expenses, including
        attorneys’ fees, which arise directly or indirectly from, or relate to:
        (1) any breach of these Terms of Use for which you are responsible; (2)
        your use of this Platform; (3) your violation, infringement, or
        misappropriation of the intellectual property, proprietary or other
        rights of any third party, or (4) your User Content or your conduct
        towards other users, whether on or off the Platform.&nbsp; Backrs
        reserves the right, at its own expense, to assume the exclusive defense
        and control of any matter otherwise subject to indemnification by you,
        and you shall not in any event settle any such matter without the prior
        written consent of Backrs.&nbsp; This indemnification section will
        survive any termination or expiration of these Terms of Use.
      </p>
      <p>
        <strong>18. CONTACTING BACKRS</strong>
      </p>
      <p>
        If you need to contact us regarding this Platform or these Terms of Use,
        please e-mail us at support@backrs.com. You can also contact us via
        telephone at 347-931-6293.
      </p>
      <p>
        <strong>19. MISCELLANEOUS</strong>
      </p>
      <p>
        These Terms of Use are governed by and construed in accordance with the
        laws of the State of Ohio and the United States of America, without
        regards to their respective principles of conflicts of law.&nbsp; You
        agree to the exclusive personal jurisdiction by the federal and state
        courts located in the State of Ohio, United States of America, in any
        matter arising from or related to these Terms of Use and your use of the
        Platform and you waive any jurisdictional, venue, or inconvenient forum
        objections to such courts.
      </p>
      <p>
        If any provision of these Terms of Use is found to be unlawful, void, or
        for any reason unenforceable, then that provision shall be deemed
        severable from these Terms of Use and shall not affect the validity and
        enforceability of any remaining provisions.
      </p>
      <p>
        These Terms of Use set out the entire agreement between us relating to
        the subject matter herein and supersede any and all prior or
        contemporaneous written or oral agreements between us.&nbsp; These Terms
        of Use are not assignable, transferable or sub-licensable by you except
        with Backrs’ prior written consent.&nbsp; These Terms of Use provide
        only for the use of this Platform. These Terms of Use shall not be
        construed as a teaming, joint venture, or other such arrangement, unless
        the parties expressly and explicitly execute an agreement to that
        effect.&nbsp; No waiver by either party of any breach or default
        hereunder shall be deemed to be a waiver of any preceding or subsequent
        breach or default.&nbsp; Any heading, caption or section title contained
        in these Terms of Use is inserted only as a matter of convenience and in
        no way defines or explains any section or provision hereof.
      </p>
      <p>15915348 v1</p>
    </Stack>
  </div>
);

TermsAndConditionsContent.propTypes = {
  whiteBgModal: PropTypes.bool,
};

TermsAndConditionsContent.defaultProps = {
  whiteBgModal: false,
};

export default TermsAndConditionsContent;
