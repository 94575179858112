import NewUserLanding from '../pages/NewUserLanding';
import LoginPage from '../pages/register/Login';
import VerifyLogin from '../pages/register/VerifyLogin';
import Activation from '../pages/register/activation/Activation';
import VerifyActivation from '../pages/register/activation/VerifyActivation';
import ErrorScreen from '../pages/ErrorScreen';
import BackrRegistrationPage from '../pages/register/BackrRegistration';
import VerifyRegistration from '../pages/register/VerifyRegistration';
import ResetPasswordPage from '../pages/register/reset-password';
import VerifyEmailPage from '../pages/register/VerifyEmail';
import FirstTimeUserWorkflow from '../workflows/firstTimeUser/FirstTimeUserWorkFlow';

export const registrationRoutes = [
  {
    path: '/new-user',
    component: NewUserLanding,
    protected: false,
  },
  {
    path: '/register/login',
    component: LoginPage,
    protected: false,
  },
  {
    path: '/register/login/verify',
    component: VerifyLogin,
    protected: false,
  },
  {
    path: '/activation',
    component: Activation,
    protected: false,
  },
  {
    path: '/activation/verify',
    component: VerifyActivation,
    protected: false,
  },
  {
    path: '/register/error',
    component: ErrorScreen,
    protected: false,
  },
  {
    path: '/register/register/backr',
    component: BackrRegistrationPage,
    protected: false,
  },
  {
    path: '/register/register/verify',
    component: VerifyRegistration,
    protected: false,
  },
  {
    path: '/register/password-reset',
    component: ResetPasswordPage,
    protected: false,
  },
  {
    path: '/register/verify-email',
    component: VerifyEmailPage,
    protected: false,
  },
  {
    path: '/what-is-backrs',
    component: FirstTimeUserWorkflow,
    protected: false,
  },
];
