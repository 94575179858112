/* eslint-disable camelcase */
import React from 'react';
import WholeMeProfileSectionDetail from 'pages/wholeme/molecules/wholeMeProfileSectionDetail/WholeMeProfileSectionDetail';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import { InterestsIcon } from 'pages/wholeme/icons/Icons';
import WholeMeProfileSection from '../wholeMeProfileSection/WholeMeProfileSection';
import { fieldInfoUrlFor, fieldLabelFor } from '../../util/profile_field_data';
import {
  pillOverrideStyles,
  pillRendererOverrideStyles,
} from '../wholeMeProfileSection/wholeMeProfileSectionStyles';
import { resolveReligionValue } from '../wholeMeProfileIdentity/utils';

const WholeMeProfileInterests = ({
  profile, onEdit, onPost, isViewOnly,
}) => {
  const pillContainerOverrideStyles = isViewOnly
    ? pillRendererOverrideStyles
    : {
      ...pillRendererOverrideStyles,
      marginLeft: '22px',
    };
  const { hobbies = [], clubs = [], religion = [] } = profile;

  if (!onEdit && hobbies.length < 1 && clubs.length < 1 && religion.length < 1) {
    return null;
  }

  const renderEdit = () => (
    <>
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Interests & Hobbies"
        items={hobbies.map((n) => fieldLabelFor(n.option_id, 'interests', 'hobbies', n.suboption_id))}
        categoryId="interests"
        stepId="hobbies"
        isViewOnly={isViewOnly}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Non-profit & Org Affiliations"
        items={clubs.map((n) => n.value)}
        categoryId="interests"
        stepId="clubs"
        isViewOnly={isViewOnly}
        pillStyles={{ backgroundColor: '#c4c4c4' }}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Religion"
        items={resolveReligionValue(religion)}
        categoryId="identity"
        stepId="religion"
        isViewOnly={isViewOnly}
      />
    </>
  );

  const renderView = () => {
    const blocks = [];
    if (hobbies.length > 0) {
      blocks.push({
        label: 'hobbies',
        items: hobbies.map((n) => ({
          value: fieldLabelFor(n.option_id, n.category_id, n.question_id, n.suboption_id),
          clickContext: {
            infoUrl: fieldInfoUrlFor(n.option_id, n.category_id, n.question_id, n.suboption_id),
          },
        })),
      });
    }
    if (clubs.length > 0) {
      blocks.push({
        label: 'clubs',
        items: clubs.map((n) => ({
          value: n.value,
          clickContext: {
            infoUrl: fieldInfoUrlFor(n.option_id, n.category_id, n.question_id, n.suboption_id),
          },
        })),
      });
    }
    if (religion.length > 0) {
      blocks.push({
        label: 'religion',
        items: resolveReligionValue(religion),
      });
    }

    return blocks.map((block, i) => (
      <PillRenderer
        key={block?.label}
        pillStyles={{ ...pillOverrideStyles, backgroundColor: i % 2 === 0 ? '#E8E8DC' : '#C4C4C4' }}
        containerStyleOverrides={pillContainerOverrideStyles}
        isSelectable={false}
        handlePillClick={
          onPost ? (item, clickContext) => onPost(item, clickContext?.infoUrl) : undefined
        }
        items={block?.items}
      />
    ));
  };

  return (
    <WholeMeProfileSection
      title="Interests"
      editable={!!onEdit}
      titleIcon={<InterestsIcon marginRight={6} height={67} />}
    >
      {onEdit ? renderEdit() : renderView()}
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileInterests;
