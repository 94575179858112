import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { careerXPData } from './careerXPData';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';

const ProfileCareerIntakeXpPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={careerXPData}
    itemVariant="rectangle"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Career Experiences"
    subtitle="Which of these apply to your career path? You can include part-time work!"
    categoryId="career"
    questionId="career_xp"
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchCareerXpState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveCareerXpState)}
    backgroundOverlay="/assets/profile/education/career-experiences-overlay.svg"
  />
);

export default ProfileCareerIntakeXpPage;
