import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/capacitor';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import { usePhotoGallery } from 'hooks/usePhotoGallery';
import WholeMeProfilePurposeVideoThumbnail from 'pages/wholeme/molecules/wholeMeProfilePurposeVideoThumbnail/wholeMeProfilePurposeVideoThumbnail';
import { Stack } from '@mui/material';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';
import { firstOf } from '../../../util/profile_util';
import appStrings from '../../../../../common/app_strings';

const {
  wholeMeProfilePurposeVideo: {
    intakeTitle,
    intakeSubtitle,
  },
} = appStrings;

const ProfileIdentityIntakePurposeVideoPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [previewMeta, setPreviewMeta] = useState(null);
  const [video, setVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    platform: { isNativePlatform },
  } = useAppState();
  const { takeVideo, takeWebVideo } = usePhotoGallery();

  useEffect(() => {
    wholeMeClient.fetchPurposeVideoState().then((state) => {
      setPreviewMeta({
        uri: firstOf(state?.purpose_video)?.uri,
      });
    });
  }, []);

  const handleWebVideoUpload = async () => {
    const { media } = await takeWebVideo();
    if (media) {
      setVideo({ id: media.id });
      setPreviewMeta({ uri: media.uri });
    }
  };

  const handleMobileVideoUpload = async () => {
    const { media, webviewPath } = await takeVideo();
    if (media) {
      setVideo({ webviewPath, id: media.id });
      setPreviewMeta({ uri: webviewPath });
    }
  };

  const handleVideoUpload = async () => {
    if (isLoading) {
      return;
    }
    const uploader = isNativePlatform ? handleMobileVideoUpload : handleWebVideoUpload;
    try {
      setIsLoading(true);
      await uploader();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveVideo = async () => {
    if (isLoading) {
      return;
    }
    if (!video?.id) {
      nextOnClick();
      return;
    }
    await wholeMeClient.savePurposeVideoState(video.id);
    nextOnClick();
  };

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={intakeTitle}
      subtitle={intakeSubtitle}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: handleSaveVideo }}
      backgroundOverlay="/assets/profile/identity/profile-picture-overlay.svg"
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      {(!previewMeta || isLoading) ? (
        <WholeMeLoadingCircle />
      ) : (
        <Stack alignItems="center">
          <WholeMeProfilePurposeVideoThumbnail videoUrl={previewMeta?.uri} onClick={handleVideoUpload} />
        </Stack>
      )}
    </ProfileIntakeStepPage>
  );
};
export default ProfileIdentityIntakePurposeVideoPage;
