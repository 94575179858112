import React, { useEffect, useState } from 'react';
import {
  IonButton, IonRouterLink, IonSpinner,
} from '@ionic/react';
import {
  Box,
  Stack,
  TextField,
  Typography,
  ButtonBase,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { Close, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import appStrings from 'common/app_strings';
import DecorativeDivider from 'common/atoms/DecorativeDivider';
import ToastContent from 'common/molecules/ToastContent';
import renderInputAdornment from 'common/atoms/adornments/inputIconUtils';
import { useAuthContext } from '../../providers/auth-provider';
import { InvalidCredentialsError, ValidationError } from '../../helpers/errors';
import { TaupeTextField } from '../../components/StyledInput';
import MainLogoLayout from '../../components/layout/MainLogoLayout';
import './Login.css';

const {
  login: loginStrings,
  common: { dismiss },
  onboarding: { errors },
  otpValidation: { contactBtn },
} = appStrings;

const CssTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  // input adornment
  '& .MuiSvgIcon-root': {
    color: '#C3C3B9',
  },
  '& .MuiFilledInput-root': {
    background: '#fff',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    borderRadius: 8,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: '#063d8f',
    },
  },
});

const CustomLinkAttempt = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <IonRouterLink ref={ref} {...props} />
));

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordEmailError, setForgotPasswordEmailError] = useState(null);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(null);
  const [unknownError, setUnknownError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const {
    login,
    sendPasswordResetEmail,
    handleOtpLoginRequest,
  } = useAuthContext();

  const isIosPlatform = Capacitor.getPlatform === 'ios';

  if (isIosPlatform) {
    Keyboard.setResizeMode({ mode: KeyboardResize.None });
  }

  const resetKeyboardResize = async () => {
    if (isIosPlatform) {
      await Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  };

  const clearFieldErrors = () => {
    setEmailErrorMessage(null);
    setPasswordErrorMessage(null);
    setPhoneErrorMessage(null);
    setUnknownError(null);
  };

  const handleFieldInput = (callback, value) => {
    setUnknownError(null);
    callback(value);
  };

  const doLogin = async () => {
    clearFieldErrors();

    if (phone) {
      try {
        const resp = await handleOtpLoginRequest(phone);
        if (resp?.status_code === 200) {
          history.push('/register/login/verify');
        }
      } catch (e) {
        if (e.message === 'otp_unavailable') {
          setPhone('');
          toast(
            (t) => (
              <ToastContent
                header={loginStrings.phoneLoginUnavailable}
                body={loginStrings.useEmailAndPw}
                dismissMeta={{
                  label: dismiss,
                  callback: () => {
                    toast.dismiss(t.id);
                  },
                }}
              />
            ),
            {
              duration: 4000,
              position: 'top-center',
              id: 'otp_unavailable',
            },
          );

          return;
        }

        if (e instanceof ValidationError) {
          if (e.errors.phone) {
            setPhoneErrorMessage(e.errors.phone);
          }
        } else {
          setUnknownError(e.message || 'unknown_error');
        }
      }

      return;
    }

    try {
      await login(email, password);
    } catch (e) {
      // todo: clean up error state.
      // can just be errors object, with fields reading from the object directly.
      if (e instanceof InvalidCredentialsError) {
        setPasswordErrorMessage('Invalid credentials!');
      } else if (e instanceof ValidationError) {
        if (e.errors.email) {
          setEmailErrorMessage(e.errors.email);
        }
        if (e.errors.password) {
          setPasswordErrorMessage(e.errors.password);
        }
        if (e.errors.phone) {
          setPhoneErrorMessage(e.errors.phone);
        }
      } else {
        // eslint-disable-next-line no-console
        setUnknownError(e.message);
      }
    }
  };

  const sendPwResetEmail = async () => {
    setForgotPasswordEmailError(null);
    setForgotPasswordLoading(true);
    try {
      await sendPasswordResetEmail(forgotPasswordEmail);
      setForgotPasswordModalOpen(false);
      setForgotPasswordEmail('');
      toast(`A password reset email was sent to ${forgotPasswordEmail}`);
    } catch (e) {
      if (e instanceof ValidationError) {
        if (e.errors.email) {
          setForgotPasswordEmailError(e.errors.email);
        }
      }
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  const closeForgotPasswordModal = () => {
    setForgotPasswordModalOpen(false);
    setForgotPasswordEmail('');
  };

  const handleRegisterOnClick = () => {
    history.push('/what-is-backrs', { redirectTo: '/register/register/backr' });
  };

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => () => {
    resetKeyboardResize();
  }, []);

  return (
    <MainLogoLayout backButtonText="">
      <Stack
        spacing={3.5}
        sx={{
          alignItems: 'center',
          mx: 5,
          mb: 5,
        }}
      >
        <Box width={1} px={3}>
          <Typography align="center" sx={{ fontWeight: 700, fontSize: 20 }}>
            {loginStrings.greeting}
          </Typography>
          <Typography align="center" sx={{ color: '#2d2d2d', mt: 3.5 }}>
            {loginStrings.prompt.beginning}
            <Typography
              sx={{
                color: '#063D8F',
                fontSize: 'inherit',
                fontWeight: 700,
              }}
              component={CustomLinkAttempt}
              onClick={handleRegisterOnClick}
            >
              {loginStrings.prompt.middle}
            </Typography>
            {loginStrings.prompt.end}
          </Typography>
          <NumberFormat
            format="###-###-####"
            mask="#"
            error={!!phoneErrorMessage}
            helperText={phoneErrorMessage}
            onValueChange={(v) => {
              handleFieldInput(setPhone, v.value);
            }}
            value={phone}
            placeholder={loginStrings.placeholders.phone}
            customInput={CssTextField}
            fullWidth
            size="small"
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            disabled={!!email || !!password}
            InputProps={{
              style: {
                paddingBlock: 5,
                marginBottom: '8px',
                marginTop: '24px',
              },
              startAdornment: renderInputAdornment('phone', 'start'),
            }}
          />
          <DecorativeDivider content={loginStrings.decorativeDivider} />
          <Stack spacing={1.5} sx={{ mt: 1, alignItems: 'flex-end' }}>
            <CssTextField
              fullWidth
              placeholder={loginStrings.placeholders.email}
              value={email}
              type="email"
              size="small"
              onChange={(e) => handleFieldInput(setEmail, e.target.value)}
              onKeyUp={(e) => {
                e.preventDefault();
                const passwordInput = document.getElementById('password-input');
                if (e.key === 'Enter' && passwordInput) passwordInput.focus();
              }}
              error={!!emailErrorMessage}
              helperText={emailErrorMessage}
              disabled={!!phone}
              InputProps={{
                style: {
                  paddingBlock: 5,
                },
                startAdornment: renderInputAdornment('email', 'start', { position: 'relative', top: 1, fontSize: 24 }),
              }}
            />
            <CssTextField
              id="password-input"
              fullWidth
              placeholder={loginStrings.placeholders.password}
              value={password}
              size="small"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => handleFieldInput(setPassword, e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && doLogin()}
              error={!!passwordErrorMessage}
              helperText={passwordErrorMessage}
              disabled={!!phone}
              InputProps={{
                style: {
                  paddingBlock: 5,
                },
                startAdornment: renderInputAdornment('password', 'start'),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton style={{ zIndex: 10 }} onClick={handlePasswordShow}>
                      {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack alignItems="flex-end" mt={1.25}>
            <ButtonBase onClick={() => setForgotPasswordModalOpen(true)}>
              <Typography
                sx={{ color: '#063D8F', fontSize: 13, fontWeight: 700 }}
              >
                {loginStrings.forgotPassword}
              </Typography>
            </ButtonBase>
          </Stack>
          {unknownError && (
          <Box
            display="flex"
            justifyContent="center"
          >
            <FormHelperText error>
              {errors.registrationFailed(true)}
              <a
                className="red"
                href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
              >
                {(contactBtn).toLowerCase()}
              </a>
            </FormHelperText>
          </Box>
          )}
        </Box>
        <Stack spacing={3} sx={{ width: 1, alignItems: 'center' }}>
          <object
            className="no-pointer-events"
            type="image/svg+xml"
            data="/assets/login-people.svg"
          >
            <img src="/assets/login-people.svg" alt="connection login" />
          </object>
          <IonButton
            size="large"
            expand="block"
            shape="round"
            className="btn-bold"
            onClick={doLogin}
            style={{
              '--background-hover': '#063D8F',
              '--background-activated': '#063D8F',
              '--background-focused': '#063D8F',
              width: '90%',
            }}
            mode="ios"
          >
            {loginStrings.tryLogin}
          </IonButton>
        </Stack>
      </Stack>
      <Dialog
        PaperProps={{
          style: { borderRadius: 8 },
        }}
        open={forgotPasswordModalOpen}
        onClose={closeForgotPasswordModal}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeForgotPasswordModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 2, pb: 2 }}>
          <Typography sx={{ fontSize: 14, color: '#2d2d2d', mt: 2 }}>
            {loginStrings.resetPassword}
          </Typography>
          <Stack spacing={2} alignItems="center" mt={2}>
            <TaupeTextField
              fullWidth
              placeholder={loginStrings.placeholders.email}
              value={forgotPasswordEmail}
              size="small"
              type="email"
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && sendPwResetEmail()}
              error={!!forgotPasswordEmailError}
              helperText={forgotPasswordEmailError}
            />
            <IonButton
              shape="round"
              onClick={sendPwResetEmail}
              style={{
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                '--padding-start': '30px',
                '--padding-end': '30px',
                '--padding-top': '4px',
                '--padding-bottom': '4px',
                fontWeight: 700,
                maxHeight: 42,
              }}
            >
              {forgotPasswordLoading ? <IonSpinner name="crescent" /> : 'Send'}
            </IonButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </MainLogoLayout>
  );
};
export default LoginPage;
