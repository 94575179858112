// eslint-disable-next-line import/prefer-default-export
export const buildFilterItems = (
  filterMeta,
  onClickCallBack,
  initialIndexValue,
) => {
  const filterItems = filterMeta.map((item) => ({
    filterMeta: item,
    isSelected: !!(initialIndexValue && initialIndexValue === item.value),
    onClickCallBack,
  }));

  return filterItems;
};
