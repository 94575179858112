import React from 'react';
import ResourceLookupPage from 'pages/wholeme/molecules/resourceLookupPage/ResourceLookupPage';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';

const profileEducationIntakeSchoolsPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <ResourceLookupPage
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    wholeMeClient={wholeMeClient}
    categoryId="education"
    questionId="schools"
    title="Educational Institutions"
    subtitle="Where did you go to school? Add as many schools that you (or your family!) are alumnus of, from high school to post-secondary."
    dataFetcher={async () => (await wholeMeClient.fetchSchoolsState())?.schools}
    dataSaver={wholeMeClient.saveSchoolsState}
    resourceType="schools"
    resultAttributeId="school"
    backgroundOverlay="/assets/profile/education/educational-institutions-overlay.svg"
  >
    <MainImage
      imageSrc="./assets/profile/education/schools-page.svg"
      imageAlt="school building"
    />
  </ResourceLookupPage>
);

export default profileEducationIntakeSchoolsPage;
