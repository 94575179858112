import React from 'react';
import { Box } from '@mui/material';

const wholeMeProfilePurposeVideoThumbnail = ({ videoUrl, onClick }) => (!videoUrl ? (
  <Box
    sx={{
      display: 'grid',
      placeContent: 'center',
      background: '#E8E8DC',
      width: '100%',
      height: '100%',
      minHeight: '180px',
      maxHeight: '200px',
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '15px',
    }}
    onClick={onClick}
  >
    <object
      type="image/svg+xml"
      data="/assets/plus-circle-grey.svg"
      className="no-pointer-events"
    >
      <img
        src="/assets/plus-circle-grey.svg"
        alt="people connected"
      />
    </object>
  </Box>
) : (
  <Box
    sx={{
      background: '#E8E8DC',
      width: '100%',
      height: '215px',
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '15px',
      overflow: 'hidden',
      '& > div': {
        width: 1,
        height: 1,
      },
      '& img': {
        objectFit: 'contain',
        objectPosition: 'center',
      },
      '& video': {
        width: 'inherit',
        height: 'inherit',
        objectFit: 'cover',
        objectPosition: 'center',
      },
      position: 'relative',
    }}
    onClick={onClick}
  >
    <div style={{ position: 'relative' }}>
      <video style={{ pointerEvents: 'none', width: '100%' }} preload="metadata">
        <source src={`${videoUrl}#t=0.1`} type="video/mp4" />
        <track kind="captions" srcLang="en" label="English" />
      </video>
    </div>
    <object
      type="image/svg+xml"
      data="/assets/plus-circle-grey.svg"
      className="no-pointer-events"
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      <img
        src="/assets/plus-circle-grey.svg"
        alt="people connected"
      />
    </object>
  </Box>
));

export default wholeMeProfilePurposeVideoThumbnail;
