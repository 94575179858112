import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import useWindowDimensions from '../../../helpers/hooks';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const PdfDocument = ({
  pdfDocument,
  onDocumentLoadSuccess,
  handleDocumentLoadError,
  pageNumber,
}) => {
  const { platform } = useAppState();
  const { width } = useWindowDimensions();

  return (
    <Document
      file={pdfDocument}
      // eslint-disable-next-line react/jsx-no-bind
      onLoadSuccess={onDocumentLoadSuccess}
      renderMode="svg"
      className="doc-wrapper"
      onLoadError={async (error) => handleDocumentLoadError({ error })}
      onSourceError={async (error) => handleDocumentLoadError({ error })}
    >
      <Page
        width={platform?.isNativePlatform ? (width - 48) : 0}
        pageNumber={pageNumber}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    </Document>
  );
};

export default PdfDocument;
