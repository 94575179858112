/* eslint-disable no-param-reassign */
import React from 'react';
import SimpleSingleSelectPage from 'pages/wholeme/molecules/simpleSingleSelectPage/SimpleSingleSelectPage';
import { fieldsFor } from '../../../util/profile_field_data';

const ProfileIdentityIntakeGenderPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const dataFetcher = wholeMeClient.fetchGenderState;
  const dataSaver = wholeMeClient.saveGenderState;

  return (
    <SimpleSingleSelectPage
      fields={fieldsFor('identity-gender')}
      paginationMeta={paginationMeta}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      title="Gender"
      subtitle="Some proteges prefer same-gender teams, while others would like a mixed group of backrs."
      backgroundOverlay="/assets/profile/identity/gender-page-background-overlay.svg"
      categoryId="identity"
      questionId="gender"
      dataFetcher={dataFetcher}
      dataSaver={dataSaver}
      allowForDisabledButtonState
      exitIconOnClickOverride={exitIconOnClickOverride}
    />
  );
};

export default ProfileIdentityIntakeGenderPage;
