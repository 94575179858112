export const defaultButtonStyles = {
  borderRadius: '10px',
  height: '85%',
  fontSize: '0.8rem',
  backgroundColor: '#E8E8DC',
  color: '#5B5B5B',
  boxSizing: 'border-box',
  border: '1px solid transparent',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#E6E6DC',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
  },
};

const mergeStyles = (defaultStyles, customStyles) => ({ ...defaultStyles, ...customStyles });

export const elementButtonStyleOverride = mergeStyles(defaultButtonStyles, {
  cursor: 'default',
  '&:hover': {
    backgroundColor: '#E8E8DC',
  },
  '&:focus': {
    backgroundColor: '#E8E8DC',
  },
  justifyContent: 'start',
});

export const imageStyles = {
  width: '19px',
  height: '20px',
  marginRight: '4px',
};

export const labelElementStyles = (isNativePlatform) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  minWidth: '20%',
  ...(!isNativePlatform ? {
    maxWidth: '60%',
    minWidth: '30%',
  } : null),
});

export const elementButtonStyle = {
  fontSize: '0.85rem',
  fontWeight: 700,
};
