import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  defaultPillStyles,
  selectablePillIconStyles,
  boxStyles,
  pillTextStyles,
  selectablePillTextStyles,
} from './pillStyles';

const Pill = ({
  label,
  count,
  className,
  onClick,
  pillStylesOverride,
  isSelectable,
  isSelected,
}) => {
  const styles = {
    ...defaultPillStyles,
    ...pillStylesOverride,
  };
  if (onClick) styles.cursor = 'pointer';
  const labelStyles = isSelectable ? { ...pillTextStyles, ...selectablePillTextStyles } : pillTextStyles;

  return (
    <span
      className={className}
      style={styles}
      onClick={onClick ? () => onClick() : () => {}}
    >
      <div style={labelStyles}>
        {label}
      </div>
      {!isSelectable && count ? <PillCount count={count} /> : null}
      {isSelectable ? <SelectablePillIcon isSelected={isSelected} /> : null}
    </span>
  );
};

const SelectablePillIcon = ({ isSelected }) => {
  const selectedIcon = isSelected ? 'x' : '+';
  return <div style={selectablePillIconStyles}>{selectedIcon}</div>;
};

const PillCount = ({ count }) => <StyledCount>{count}</StyledCount>;

const StyledCount = styled(Box)(boxStyles);

Pill.propTypes = {
  label: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pillStylesOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
};

PillCount.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Pill.defaultProps = {
  label: '',
  count: null,
  pillStylesOverride: null,
  className: null,
  onClick: () => {},
  isSelectable: false,
  isSelected: false,
};

export default Pill;
