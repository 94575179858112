import
React,
{
  useRef,
} from 'react';
import {
  IonContent,
  IonIcon,
} from '@ionic/react';
import {
  Stack,
  Typography,
  Box,
} from '@mui/material';
import {
  arrowForwardOutline,
} from 'ionicons/icons';
import { Browser } from '@capacitor/browser';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../providers/auth-provider';
import PageWithBackButton from '../../components/generic/PageWithBackButton';
import Footer from '../../components/Footer';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.3 } },
};

const GenrealInvestLanding = ({ history }) => {
  const { user } = useAuthContext();

  const footerRef = useRef(null);

  const goToGeneralInvest = () => {
    history.push('/tabs/backr/general-invest');
  };

  return (
    <PageWithBackButton backButtonText="Explore">
      <IonContent>
        {user.role === 'backr'
          ? (
            <motion.div
              variants={variants}
              initial="hidden"
              animate="visible"
            >
              <Stack
                sx={{
                  width: 1,
                  mb: 2,
                }}
                alignItems="center"
              >
                <img
                  className="general-invest-bag"
                  src="/assets/invest-bags.svg"
                  alt="invest bag cycle"
                  style={{
                    maxWidth: '208px', width: '100%', objectFit: 'contain', objectPosition: 'center',
                  }}
                />
              </Stack>
              <Stack
                spacing={3}
                sx={{
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    'padding-left': '25px',
                    'padding-right': '25px',
                    '@media screen and (max-height: 668px)': {
                      'padding-left': '45px',
                      'padding-right': '25px',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '25px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    Become a financial backr. Invest in greatness!
                  </Typography>
                </Box>
              </Stack>
              <Stack>
                <Box
                  sx={{
                    'padding-left': '50px',
                    'padding-right': '50px',
                    '@media screen and (max-height: 668px)': {
                      'padding-left': '45px',
                      'padding-right': '45px',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      lineHeight: '21px',
                      color: '#2d2d2d',
                      // textAlign: 'center',
                    }}
                  >
                    Financial investment is essential to the power of the Backrs model.
                    Every protege has set holistic goals,
                    and extra cash puts these goals
                    more into reach and helps a young person
                    persist through challenges.
                    Financial backrs share cash with Backrs
                    for Backrs to distribute directly to a protege
                    or group of proteges.
                    Make a one time investment of up to
                    $500 below or
                    <Typography
                      onClick={() => Browser.open({ url: 'https://share.hsforms.com/1v_Dg6D4ZTAGc7vF_SjNnYAce704' })}
                      sx={{
                        display: 'inline-flex',
                        fontSize: 13,
                        lineHeight: 1.25,
                        ml: '2.5px',
                        mr: '2.5px',
                        color: '#063D8F',
                        fontWeight: '600',
                        'text-decoration': 'underline',
                      }}
                    >
                      click here
                    </Typography>
                    if you are interested in investing more
                    than $500 to speak with a member of our team.
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  p: 2,
                  mx: 3,
                  mb: 2,
                  mt: 2,
                  bgcolor: '#063D8F',
                  color: '#fff',
                  borderRadius: 2,
                  display: 'flex',
                }}
                onClick={() => goToGeneralInvest()}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      width: 'fit-content',
                    }}
                  >
                    I want to invest
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '25px',
                      fontWeight: '700',
                      width: 'fit-content',
                    }}
                  >
                    $10 - $500
                  </Typography>
                </Box>
                <Box
                  style={{
                    position: 'relative',
                    left: '-60px',
                    fontSize: '1.5rem',
                    bottom: '-33px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      width: 'fit-content',
                    }}
                  >
                    /mo
                  </Typography>
                </Box>
                <Box
                  style={{
                    paddingTop: '20px',
                    height: '25px',
                  }}
                >
                  <IonIcon
                    style={{
                      fontSize: '1.5rem',
                    }}
                    src={arrowForwardOutline}
                  />
                </Box>
              </Stack>
              {/* end first card */}
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  p: 2,
                  mx: 3,
                  mb: 2,
                  mt: 2,
                  bgcolor: '#2CB669',
                  color: '#fff',
                  borderRadius: 2,
                  display: 'flex',
                }}
                onClick={() => Browser.open({ url: 'https://share.hsforms.com/1v_Dg6D4ZTAGc7vF_SjNnYAce704' })}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      width: 'fit-content',
                    }}
                  >
                    I want to invest
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '25px',
                      fontWeight: '700',
                      width: 'fit-content',
                    }}
                  >
                    $500 or more
                  </Typography>
                </Box>
                <Box
                  style={{
                    position: 'relative',
                    left: '-46px',
                    fontSize: '1.5rem',
                    bottom: '-34px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      width: 'fit-content',
                    }}
                  >
                    /mo
                  </Typography>
                </Box>
                <Box
                  style={{
                    paddingTop: '20px',
                    height: '25px',
                  }}
                >
                  <IonIcon
                    style={{
                      fontSize: '1.5rem',
                    }}
                    src={arrowForwardOutline}
                  />
                </Box>
              </Stack>
            </motion.div>
          ) : (
            null
          )}
      </IonContent>
      <Footer ref={footerRef} />
    </PageWithBackButton>
  );
};

GenrealInvestLanding.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default GenrealInvestLanding;
