import React, { useEffect, useState } from 'react';
import IonicProviderPageShell from 'common/molecules/ionicProviderPageShell/IonicProviderPageShell';
import { useParams, useLocation } from 'react-router';
import { useAuthContext } from 'providers/auth-provider';
import useFetch, { CachePolicies } from 'use-http';
import Header from 'components/Header';
import { WholeMeClient } from '../util/wholeme_client';
import WholeMeProfile from '../pages/profile/wholeme/WholeMeProfile';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import { CoachLubavProfileData } from '../util/coachLubav_Profile';

const WholeMeProfileRoute = () => {
  const params = useParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const userRole = searchParams.get('role');
  const { user } = useAuthContext();
  const {
    user: { postTargets: targets },
  } = useAppState();

  const { get: doGet } = useFetch();
  const pageLoadedRef = React.useRef(false);
  const [userPublicData, setUserDataRequested] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState(null);

  const userId = params?.userId || params?.id || user?.id;
  const { get: getPermissions } = useFetch(`/users/profile/permissions/${userId}`, {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const isViewingAdmin = userRole === 'admin';

  const fetchPublicProfile = async () => {
    const userData = isViewingAdmin
      ? CoachLubavProfileData(userId).publicProfileData
      : await doGet(`/users/profile/${userId}`); // todo: update this endpoint to only return data scoped to user permissions.
    setUserDataRequested(userData);
    return { profile: userData };
  };

  const fetchPermissions = async () => {
    try {
      const res = await getPermissions();
      setPermissions(res);
      return res;
    } catch (error) {
      console.error('conversation permission error:', error);
    }
    return null;
  };

  const refreshProfile = async ({ loadingSpinner = true } = {}) => {
    if (loadingSpinner) {
      setIsLoading(true);
    }

    try {
      const refreshedProfile = await fetchPublicProfile();
      setIsLoading(false);
      return refreshedProfile;
    } catch (e) {
      console.error('Error refreshing profile:', e);
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  useEffect(async () => {
    setIsLoading(true);
    if (userId && !pageLoadedRef.current) {
      await fetchPermissions();
      await fetchPublicProfile();
      pageLoadedRef.current = true;
      setIsLoading(false);
    }

    return () => {
      pageLoadedRef.current = false;
    };
  }, [userId]);

  if (!userPublicData) {
    return null;
  }

  return (
    <IonicProviderPageShell header={<Header />}>
      <WholeMeProfile
        userId={Number(userId)}
        viewingUser={user}
        wholeMeClient={isViewingAdmin ? new WholeMeClient({ admin: true }) : new WholeMeClient()}
        postTargets={targets}
        legacyProfileData={userPublicData}
        isViewingAdmin={isViewingAdmin}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        permissions={permissions}
        refreshProfile={refreshProfile}
      />
    </IonicProviderPageShell>
  );
};

export default WholeMeProfileRoute;
