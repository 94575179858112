import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import useFetch from 'use-http';
import { useAuthContext } from './auth-provider';

// a "store" context for sticking any data shared across the application in
// ideally this would be abstracted into something like redux but just to get
// up and running quickly I am using a vanilla react context
const StoreContext = createContext({
  notificationCount: 0,
  refreshNotificationCount: async () => {},
});

// eslint-disable-next-line react/prop-types
export const StoreProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  const { isAuthenticated, isLoading } = useAuthContext();

  const { get, response: unreadCountResponse } = useFetch({ cachePolicy: 'no-cache' });

  const refreshNotificationCount = async () => {
    try {
      const res = await get('/notifications/unread-count');
      if (unreadCountResponse.ok) {
        setNotificationCount((res?.count || 0));
      }
    } catch (e) {
      console.error('Error in refreshNotificationCount: ', e);
    }
  };

  window.refreshNotificationCount = refreshNotificationCount;

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      refreshNotificationCount();
    }
  }, [isAuthenticated, isLoading]);

  const value = useMemo(() => ({
    notificationCount,
    refreshNotificationCount,
  }));

  return (
    <StoreContext.Provider value={value}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
