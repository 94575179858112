const styles = {
  modalContainer: {
    width: '18rem',
    height: '14rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    color: '#03295F',
    fontSize: 22,
    fontWeight: 800,
    textAlign: 'center',
  },
  modalContent: {
    padding: '15px',
    lineHeight: 1.4,
  },
  modalText: {
    fontSize: 13,
  },
  button: {
    color: '#FF0000',
  },
};

export default styles;
