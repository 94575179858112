import React from 'react';
import PropTypes from 'prop-types';
import './CollapsibleCard.css';
import { useCollapse } from 'react-collapsed';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import { IonCard, IonCardContent, IonIcon } from '@ionic/react';

const CollapsibleCard = ({ title, duration, children }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: false,
    duration: 300,
  });

  return (
    <IonCard color="light" className="collapsible-card">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getToggleProps()}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <h3 className="collapsible-card-title">{title}</h3>
        <div className="d-flex flex-row align-items-center">
          {duration ? (
            <div className="collapsible-card-duration">{duration}</div>
          ) : null}
          <IonIcon
            className={`collapsible-card-collapse collapsible-card-collapse-${
              isExpanded ? 'open' : 'closed'
            }`}
            icon={arrowForwardCircleOutline}
          />
        </div>
      </div>
      <IonCardContent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getCollapseProps()}
        className="collapsible-card-content-container"
      >
        <div className="collapsible-card-content">{children}</div>
      </IonCardContent>
    </IonCard>
  );
};

CollapsibleCard.propTypes = {
  title: PropTypes.string.isRequired,
  duration: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CollapsibleCard.defaultProps = {
  duration: '',
};

export default CollapsibleCard;
