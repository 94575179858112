/* eslint-disable max-len */
import React, { useState } from 'react';
import { IonRouterLink } from '@ionic/react';
import {
  Grid, Stack, Typography, IconButton, SvgIcon,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useAuthContext } from '../providers/auth-provider';
import { displayName } from '../helpers/textManipulation';
import RoleIndicator from './RoleIndicator';
import './PreviewComment.css';
import TruncateText from './TruncateText';
import { renderCommentBody } from '../helpers/media';

const PreviewComment = ({
  comment,
  handlePreviewCommentLike,
  handleNavigation,
  isSubcomment,
}) => {
  const [likeId, setLikeId] = useState(comment?.like_id);
  const { user } = useAuthContext();

  const userRoute = `/profile/${comment.author_id}`;
  const adminRoute = `/profile/${comment.author_id}?role=admin`;
  const isViewingAdmin = comment?.author?.role === 'admin';

  const computeDisplayName = () => {
    if (isViewingAdmin) {
      return 'Coach Lubav';
    }
    if (comment?.author?.first_name) {
      return displayName(
        comment?.author?.first_name,
        comment?.author?.last_name,
        true,
      );
    }
    if (comment?.author_id === user?.id && !comment?.author?.first_name) {
      return 'Me ';
    }
    return `User ${comment?.author_id} `;
  };

  return (
    <Stack sx={isSubcomment ? { pl: 2.5 } : null}>
      <Grid container>
        <Grid item xs sx={{ height: 'fit-content' }}>
          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <IonRouterLink
              routerLink={isViewingAdmin ? adminRoute : userRoute}
              style={{ display: 'inline-block', marginRight: 4 }}
            >
              <RoleIndicator userRole={comment?.author?.role} />
            </IonRouterLink>
            <p className="preview-comment-text">
              <IonRouterLink
                routerLink={isViewingAdmin ? adminRoute : userRoute}
                style={{ display: 'inline-block', marginRight: 4 }}
                onClick={() => false}
              >
                <Typography
                  component="span"
                  sx={{ color: '#063d8f', fontSize: 13, fontWeight: 700 }}
                >
                  {computeDisplayName()}
                </Typography>
              </IonRouterLink>
              <TruncateText
                text={renderCommentBody(comment, true)}
                maxChars={75}
                handleNavigation={handleNavigation}
              />
            </p>
          </Stack>
        </Grid>
        {!isSubcomment && (
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: 'fit-content',
              flexBasis: 'fit-content',
              ml: 1,
            }}
          >
            <IconButton
              size="small"
              sx={{
                mr: '-5px',
                pb: 0,
                color: likeId
                  ? 'var(--ion-color-warning)'
                  : 'rgba(45, 45, 45, 0.42)',
              }}
              onClick={() => handlePreviewCommentLike(comment?.id, likeId, setLikeId)}
              disabled={user?.isFlagged && user?.role !== 'admin'}
            >
              {likeId ? (
                <SvgIcon sx={{ fontSize: 14, fill: 'currentcolor' }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.0587 4.44659C17.9181 2.6224 14.7346 2.95052 12.7698 4.97783L12.0003 5.77078L11.2308 4.97783C9.26987 2.95052 6.08243 2.6224 3.94185 4.44659C1.48877 6.5403 1.35987 10.298 3.55514 12.5675L11.1136 20.3721C11.6019 20.876 12.3948 20.876 12.8831 20.3721L20.4415 12.5675C22.6407 10.298 22.5118 6.5403 20.0587 4.44659Z" />
                  </svg>
                </SvgIcon>
              ) : (
                <SvgIcon sx={{ fontSize: 14, fill: 'currentcolor' }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19.9062 4.51019C17.6484 2.61176 14.1602 2.89691 12 5.09613C9.83984 2.89691 6.35156 2.60785 4.09375 4.51019C1.15626 6.98285 1.58594 11.0141 3.67969 13.1508L10.5312 20.1313C10.9219 20.5297 11.4453 20.7524 12 20.7524C12.5586 20.7524 13.0781 20.5336 13.4687 20.1352L20.3203 13.1547C22.4101 11.018 22.8476 6.98675 19.9062 4.51019ZM18.9844 11.8344L12.1328 18.8149C12.0391 18.9086 11.9609 18.9086 11.8672 18.8149L5.01563 11.8344C3.58985 10.3813 3.30078 7.63128 5.30078 5.94769C6.82031 4.67035 9.16406 4.86176 10.6328 6.35785L12 7.75238L13.3672 6.35785C14.8437 4.85394 17.1875 4.67035 18.6992 5.94379C20.6953 7.62738 20.3984 10.393 18.9844 11.8344Z" />
                  </svg>
                </SvgIcon>
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

PreviewComment.propTypes = {
  comment: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handlePreviewCommentLike: PropTypes.func.isRequired,
  handleNavigation: PropTypes.func,
  isSubcomment: PropTypes.bool,
};

PreviewComment.defaultProps = {
  handleNavigation: null,
  isSubcomment: false,
};

export default PreviewComment;
