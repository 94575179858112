import React from 'react';
import SimpleSingleSelectPage from 'pages/wholeme/molecules/simpleSingleSelectPage/SimpleSingleSelectPage';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import { styled } from '@mui/material';
import { stageData } from './stageData';

const ImgContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const ProfileCareerIntakeStagePage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => {
  const dataFetcher = wholeMeClient.fetchCareerStageState;
  const dataSaver = wholeMeClient.saveCareerStageState;

  return (
    <SimpleSingleSelectPage
      fields={stageData}
      paginationMeta={paginationMeta}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      title="Career Stage"
      subtitle="How would you categorize where you are in your professional journey?"
      backgroundOverlay="/assets/profile/education/career-stage-overlay.svg"
      categoryId="career"
      questionId="career_stage"
      dataFetcher={dataFetcher}
      dataSaver={dataSaver}
    >
      <ImgContainer>
        <MainImage
          imageSrc="./assets/profile/career/career-stage.svg"
          imageAlt="fork lift"
          imageStylesOverride={{ maxWidth: '12rem', marginBottom: 0, maxHeight: '10rem' }}
        />
      </ImgContainer>
    </SimpleSingleSelectPage>
  );
};

export default ProfileCareerIntakeStagePage;
