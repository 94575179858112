import { Share } from '@capacitor/share';
import appStrings from './app_strings';

const shareBackrsInvite = async () => {
  const { share } = appStrings;
  try {
    await Share.share({
      title: share.title,
      text: share.body,
      url: share.url,
      dialogTitle: share.dialogueTitle,
    });
  } catch (error) {
    console.error('Share Error: ', error);
  }
};

export default shareBackrsInvite;
