import React, { useEffect, useState } from 'react';
import {
  IonPage, IonContent, IonHeader, IonToolbar,
} from '@ionic/react';
import {
  Typography, Stack, Box, Button,
} from '@mui/material';
import { useHistory } from 'react-router';
import appStrings from '../../../common/app_strings';
import DonorboxModal from '../../../common/molecules/DonorboxModal';

const { checkrFormScreen: { payPage } } = appStrings;

const Pay = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const handleNext = () => history.push('/background-status-meter');
  const handleClose = () => {
    setIsOpen(false);
    handleNext();
  };

  const donorboxMeta = {
    isOpen,
    widgetTitle: 'bg-check-funding donorbox campaign',
    widgetSrc: 'https://donorbox.org/embed/bg-check-funding?default_interval=m&hide_donation_meter=true&enable_auto_scroll=false',
    handleClose,
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://donorbox.org/widget.js';
    script.async = true;
    script.paypalExpress = false;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="" style={{ '--background': '#1CB7A4' }}>
          <Box
            sx={{
              bgcolor: '#1CB7A4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              pt: 3,
            }}
          >
            <img src="/assets/checkr-logo.png" alt="Checkr logo" />
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Stack
          sx={{
            bgcolor: '#fff',
            alignItems: 'center',
            pt: 2,
            pb: 4,
            px: 4,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Stack
            spacing={2}
            sx={{
              pb: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                color: '#1CB7A4',
                fontSize: '28px',
                fontWeight: 800,
                textAlign: 'center',
              }}
            >
              {payPage.header}
            </Typography>
            <Typography
              style={{
                color: '#2D2D2D',
                fontSize: '16px',
                fontWeight: 400,
                textAlign: 'justify',
                width: '85%',
              }}
            >
              {payPage.body}
            </Typography>
            <Box>
              <DonorboxModal {...donorboxMeta} />
              <img src="/assets/money-apple-exchange.svg" alt="Money apple exchange" />
            </Box>
            <Stack
              sx={{
                marginTop: '2rem !important',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Button
                color="green"
                variant="contained"
                disableElevation
                disableRipple
                sx={{
                  color: '#fff',
                  fontWeight: 700,
                  letterSpacing: 2,
                  borderRadius: '100px',
                  display: 'inline-block',
                  mx: 'auto',
                  px: '60px',
                }}
                onClick={() => setIsOpen(true)}
              >
                {payPage.yes}
              </Button>
              <Typography onClick={handleNext} sx={{ fontSize: '12px', cursor: 'pointer' }}>
                {payPage.noThanks}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </IonContent>
    </IonPage>
  );
};

export default Pay;
