/* eslint-disable import/prefer-default-export */

/**
 * The values for these options are directly inform mappings that drive the
 * automated grouping of users by their Whole Me profile selections.
 *
 * Changes made to the values of these options will require corresponding changes in the listed files, and potentially others.
 * See:
 *  - backrs_api/src/services/eventBridge/eventHandlers/eventHelpers/profileEvents/groups/maps.js
 */
export const ethnicityData = [
  {
    label: 'Black / African American',
    value: 'Black or African American',
  },
  {
    label: 'Biracial / Multiracial',
    value: 'Biracial or Multiracial',
  },
  {
    label: 'East Asian',
    value: 'East Asian',
  },
  {
    label: 'Hispanic / Latino',
    value: 'Hispanic or Latino',
  },
  {
    label: 'Indigenous',
    value: 'Indigenous',
  },
  {
    label: 'Middle Eastern / North African',
    value: 'Middle Eastern or North African',
  },
  {
    label: 'South Asian',
    value: 'South Asian',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Prefer not to say',
    value: 'Prefer not to say',
  },
];
