import React, { useState, useEffect } from 'react';
import {
  IonButton,
} from '@ionic/react';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { Browser } from '@capacitor/browser';
import { LightbulbIcon } from '../pages/wholeme/icons/Icons';
import { computeEnvironment } from '../helpers/environment';
import appStrings from '../common/app_strings';

const { hamburgerMenu: { resourceLibrary } } = appStrings;

const bulbStyle = {
  width: '30px',
  height: '30px',
};

const ResourceLibrary = ({ isMenuItem }) => {
  const [url, setUrl] = useState('');
  const prodUrl = 'https://resources.backrs.com';
  const stagingDevUrl = 'https://backrs-resources.webflow.io/';

  const checkEnvironmentSetUrl = () => {
    const env = computeEnvironment();
    if (env === 'development' || env === 'staging') {
      setUrl(stagingDevUrl);
    } else { setUrl(prodUrl); }
  };

  const openInBrowser = async () => {
    await Browser.open({ url });
  };

  useEffect(() => {
    checkEnvironmentSetUrl();
  }, []);

  if (isMenuItem) {
    return (
      <MenuItem
        disableRipple
        onClick={openInBrowser}
      >
        {resourceLibrary}
      </MenuItem>
    );
  }

  return (
    <IonButton onClick={openInBrowser}>
      <LightbulbIcon {...bulbStyle} />
    </IonButton>
  );
};

ResourceLibrary.propTypes = {
  isMenuItem: PropTypes.bool,
};

ResourceLibrary.defaultProps = {
  isMenuItem: false,
};

export default ResourceLibrary;
