import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import GeneralTextCardAtom from '../../../common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import StatusMeter from '../../../common/atoms/StatusMeter';
import CardAtom from '../../../common/atoms/card/CardAtom';

const headerStylesCard = {
  color: '#03295F',
  fontSize: '17px',
};
const bodyStylesCard = {
  color: '#2D2D2D',
  fontSize: '13px',
  mt: '5px',
  lineHeight: 1.3,
};
const imgStylesCard = {
  width: 90,
  height: 90,
  maxWidth: 100,
};
const cardBgColor = {
  backgroundColor: '#E5E5DE',
};

const BackrgroundMeterContent = ({
  header,
  body,
  statusMeter,
  contentMeta,
  showNoBgCard,
  noBgMeta,
  completeProfileMeta,
}) => (
  <Stack spacing={2.5}>
    <Stack>
      <GeneralTextCardAtom
        title={header}
        body={body}
        titleStyles={{
          textAlign: 'center',
          color: '#03295F',
          fontSize: '28px',
          fontWeight: 800,
        }}
        bodyStyles={{
          fontWeight: 500,
          fontSize: '17px',
          textAlign: 'center',
          color: '#2D2D2D',
          opacity: 1,
          pt: '10px',
          lineHeight: 1.5,
        }}
      />
    </Stack>
    {statusMeter && (
      <Stack>
        <StatusMeter stage={statusMeter} />
      </Stack>
    )}
    <Stack spacing={2.75}>
      {!showNoBgCard
        && (
          <CardAtom
            img="/assets/wholeMeCtaImg.svg"
            header={completeProfileMeta.title}
            body={completeProfileMeta.body}
            callback={completeProfileMeta.callback}
            containerStyles={cardBgColor}
            headerStyles={headerStylesCard}
            bodyStyles={bodyStylesCard}
            imgStyles={{
              ...imgStylesCard,
              padding: '0 11.5px',
            }}
          />
        )}
      <CardAtom
        img={showNoBgCard ? '/assets/tools-card.svg' : '/assets/meet-lubav.svg'}
        header={showNoBgCard ? noBgMeta.title : contentMeta.title}
        body={showNoBgCard ? noBgMeta.body : contentMeta.body}
        callback={showNoBgCard ? noBgMeta.callback : contentMeta.callback}
        containerStyles={cardBgColor}
        headerStyles={headerStylesCard}
        bodyStyles={bodyStylesCard}
        imgStyles={imgStylesCard}
      />
    </Stack>
  </Stack>
);

BackrgroundMeterContent.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  statusMeter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentMeta: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    callback: PropTypes.func,
  }).isRequired,
  noBgMeta: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    callback: PropTypes.func,
  }).isRequired,
  completeProfileMeta: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    callback: PropTypes.func,
  }).isRequired,
  showNoBgCard: PropTypes.bool.isRequired,
};

BackrgroundMeterContent.defaultProps = {
  statusMeter: 20,
};

export default BackrgroundMeterContent;
