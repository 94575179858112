import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonButton,
  IonIcon,
  IonLabel,
  IonAlert,
} from '@ionic/react';
import {
  Stack,
  Grid,
  Typography,
  ButtonBase,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import { arrowForwardOutline, close } from 'ionicons/icons';
import { useForm, Controller } from 'react-hook-form';
import useFetch from 'use-http';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { ValidationError } from '../../helpers/errors';
import { useAuthContext } from '../../providers/auth-provider';
import PageWithBackButton from '../../components/generic/PageWithBackButton';
import StyledInput, { TaupeTextField } from '../../components/StyledInput';
import TermsAndConditionsContent from '../TermsAndConditionsContent';
import './EditProfileInfo.css';

const EditAccountInfo = () => {
  const {
    user,
    refreshUser,
    logout,
    removeRegistrationStatusToken,
  } = useAuthContext();
  const {
    get, put, del, response,
  } = useFetch();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();

  const [notifChanges, setNotifChanges] = useState();
  const [notifPrefs, setNotifPrefs] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isAlerted, setIsAlerted] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const falsePrefs = {
    push_comments: false,
    push_likes: false,
    push_team_updates: false,
    push_community_updates: false,
  };

  const truePrefs = {
    push_comments: true,
    push_likes: true,
    push_team_updates: true,
    push_community_updates: true,
  };

  const handleNotifChange = (event) => {
    console.log('notif checkbox', event.target.checked);
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setNotifChanges(truePrefs);
    } else {
      setNotifChanges(falsePrefs);
    }
  };

  const getUserNotifPrefs = async () => {
    const res = await get('/users/current-user/preferences/notifications');
    setNotifPrefs(res);
    console.log(res?.push_comments);
    if (res?.push_comments) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const updateUserNotifPrefs = async () => {
    try {
      if (
        notifChanges
        && notifPrefs?.push_comments !== notifChanges?.push_comments
      ) {
        put('/users/current-user/preferences/notifications', notifChanges);
        getUserNotifPrefs();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccountDelete = async () => {
    await del('/users/me');

    if (!response.ok) {
      throw new Error('Unknown Error');
    }
    removeRegistrationStatusToken();
    logout();
  };
  const onSubmit = async (data) => {
    try {
      const cleanedData = data;
      delete cleanedData.email_verified;

      if (cleanedData.password === '') delete cleanedData.password;
      if (cleanedData.confirmPassword === '') {
        delete cleanedData.confirmPassword;
      }

      if (cleanedData.birthday === '') cleanedData.birthday = null;
      if (cleanedData.phone === '' && user?.role !== 'protege') {
        cleanedData.phone = null;
      }
      const res = await put(`/users/${user?.id}`, cleanedData);
      if (!response.ok) {
        if (response.status === 400) {
          throw new ValidationError('Invalid inputs', res.errors);
        } else {
          throw new Error('Unknown Error');
        }
      }
      await updateUserNotifPrefs();
      await refreshUser();
      history.push('/tabs/profile');
    } catch (e) {
      if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      }
    }
  };

  useEffect(() => {
    getUserNotifPrefs();
    reset(user);
  }, [user]);

  return (
    <PageWithBackButton backButtonText="">
      <IonContent>
        <Dialog fullScreen open={isOpen} onClose={() => setOpen(false)}>
          <Toolbar sx={{ mt: 6 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <IonIcon aria-label="Close" icon={close} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: 'center' }}
              variant="h6"
              component="div"
            >
              Terms & Conditions
            </Typography>
            <IconButton sx={{ visibility: 'hidden' }}>
              <IonIcon aria-label="Close" icon={close} />
            </IconButton>
          </Toolbar>
          <DialogContent>
            <TermsAndConditionsContent />
          </DialogContent>
        </Dialog>
        <IonAlert
          isOpen={isAlerted}
          onDidDismiss={() => setIsAlerted(false)}
          header="Delete Account"
          message="Are you sure you want to delete your account? This action cannot be undone."
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              id: 'cancel-button',
            },
            {
              text: 'Delete',
              role: 'destructive',
              id: 'delete-button',
              handler: () => {
                handleAccountDelete();
              },
            },
          ]}
        />
        <Typography
          sx={{
            color: '#2d2d2d',
            fontSize: 28,
            fontWeight: 700,
            textAlign: 'center',
            mt: 2,
          }}
        >
          Account Info
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              background: '#fff',
              p: 2,
              mx: 3,
              my: 3.5,
              borderRadius: 2,
            }}
          >
            <Stack spacing={1.5}>
              <div>
                <IonLabel style={{ color: '#2d2d2d' }} position="stacked">Full name *</IonLabel>
                <Grid container spacing={1} py={0}>
                  <Grid item xs={6}>
                    <StyledInput
                      theme="taupe"
                      placeholder="First"
                      control={control}
                      name="first_name"
                      capitalize
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledInput
                      theme="taupe"
                      placeholder="Last"
                      control={control}
                      name="last_name"
                      capitalize
                    />
                  </Grid>
                </Grid>
                <Typography color="error" fontSize="12px !important">
                  {errors?.first_name?.message}
                </Typography>
                <Typography color="error" fontSize="12px !important">
                  {errors?.last_name?.message}
                </Typography>
              </div>
              {user?.role === 'protege' && (
                <StyledInput
                  theme="taupe"
                  placeholder="01/23/2005"
                  title="Birthday "
                  control={control}
                  name="birthday"
                  error={errors?.birthday}
                  position="stacked"
                  type="date"
                />
              )}
              <StyledInput
                theme="taupe"
                title="Email *"
                placeholder="jane.smith@gmail.com"
                control={control}
                name="email"
                error={errors?.email}
                position="stacked"
                type="email"
              />
              <StyledInput
                theme="taupe"
                title="New Password"
                placeholder="New Password"
                control={control}
                name="password"
                error={errors?.password}
                position="stacked"
                type="password"
              />
              <StyledInput
                theme="taupe"
                title="Confirm New Password"
                placeholder="Confirm New Password"
                control={control}
                name="confirmPassword"
                error={errors?.confirmPassword}
                position="stacked"
                type="password"
              />
              <div>
                <IonLabel style={{ color: '#2d2d2d' }} position="stacked">
                  {user?.role === 'protege' ? 'Phone *' : 'Phone'}
                </IonLabel>
                <Controller
                  control={control}
                  name="phone"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      format="###-###-####"
                      mask="_"
                      onValueChange={(v) => onChange(v.value)}
                      value={value}
                      customInput={TaupeTextField}
                      fullWidth
                      size="small"
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      type="tel"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      disabled
                    />
                  )}
                />
              </div>
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{
                      color: '#063D8F',
                      '&.Mui-checked': {
                        color: '#063D8F',
                      },
                      pr: 1.75,
                      pl: 0,
                    }}
                    checked={isChecked}
                    onChange={handleNotifChange}
                  />
                )}
                label={(
                  <Typography
                    sx={{
                      color: '#2d2d2d',
                    }}
                  >
                    Enable In-App Notifications
                  </Typography>
                )}
              />
              <ButtonBase onClick={() => setIsAlerted(true)}>
                <Typography
                  sx={{
                    color: '#03295f',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  Delete My Account
                </Typography>
              </ButtonBase>
              <ButtonBase onClick={() => setOpen(true)}>
                <Typography
                  sx={{
                    color: '#03295f',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  View Terms & Conditions
                </Typography>
              </ButtonBase>
            </Stack>
          </Box>
          <IonButton
            size="large"
            expand="block"
            shape="round"
            className="edit-info-submit-btn"
            type="submit"
          >
            Save Profile
            <IonIcon
              style={{
                position: 'relative',
                top: '2px',
                fontSize: '1.25rem',
                marginLeft: '4px',
              }}
              src={arrowForwardOutline}
            />
          </IonButton>
        </form>
      </IonContent>
    </PageWithBackButton>
  );
};

export default EditAccountInfo;
