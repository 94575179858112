import React, { useState, useEffect, useRef } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { Stack } from '@mui/material';
import useFetch, { CachePolicies } from 'use-http';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveFilter, feedSelector } from '../redux/slices/feed';
import ReportCueCard from '../components/ReportCueCard';

import Feed from '../components/Feed';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useAuthContext } from '../providers/auth-provider';
import { useAppState } from '../providers/app-state/app-state-provider';
import FilterBar from '../common/atoms/filterBar/FilterBar';
import DesktopFilterBar from '../common/atoms/desktopFilterBar/DesktopFilterBar';
import appStrings from '../common/app_strings';
import FeedError from '../components/FeedError';
import AlertCard from '../common/atoms/alertCard/AlertCard';
import CardAtom from '../common/atoms/card/CardAtom';
import AffinityNetwork from './feed/affinityNetwork/AffinityNetwork';
import AffinityMemberList from './feed/affinityNetwork/AffinityMemberList';

const {
  feed: { errors },
  wholeMe: { wholeMeAlertCard },
} = appStrings;

const allPostFilter = {
  type: null,
  value: 'all',
  label: 'All Posts',
  icon: '/assets/all-post-filter-icon.svg',
};

const UnifiedUserFeed = () => {
  const loadingRef = useRef(false);
  const { activeFilter } = useSelector(feedSelector);
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [filterMeta, setFilterMeta] = useState([]);
  const [showFeedError, setShowFeedError] = useState(false);
  const [showWholeMeAlert, setShowWholeMeAlert] = useState(false);
  const [affinityListRole, setAffinityListRole] = useState(null);
  const [isAffinityListLoaded, setIsAffinityListLoaded] = useState(false);
  const { get, response, abort } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const PER_PAGE = 10;
  const {
    user,
    reported,
    setReported,
    hasGivenFeedback,
    setHasGivenFeedback,
  } = useAuthContext();
  const contentRef = useRef();
  const timeoutRef = useRef(null);
  const { platform: { isNativePlatform } } = useAppState();

  const showNativeFeed = filterMeta.length > 0 && !showFeedError && isNativePlatform;
  const showWebFeed = filterMeta.length > 0 && !showFeedError && !isNativePlatform;
  const showAffinityNetwork = activeFilter?.type === 'group';
  const showAffinityMemberList = showAffinityNetwork && affinityListRole;

  const setLoadingRef = (isLoading) => {
    loadingRef.current = isLoading;
    setIsLoading(loadingRef.current);
  };

  const buildFilterItemMeta = () => {
    const itemMeta = async () => {
      const filterData = await get('/user-feed/filters');

      if (filterData) {
        const filterList = filterData.length ? [allPostFilter, ...filterData] : filterData;
        setFilterMeta(filterList);
      } else {
        toast(errors.filtersUnavailable);
      }
    };
    itemMeta();
  };

  const history = useHistory();

  const fetchPosts = async ({ filter = undefined, min, max }) => {
    try {
      const params = new URLSearchParams();
      params.append('range', `[${min},${max}]`);
      params.append('sort', '["created_at","DESC"]');

      if (filter && filter.value !== 'all') {
        params.append('feed_filter', `["${filter.type}",${filter.value}]`);
      }

      const res = await get(`/user-feed?${params.toString()}`);

      if (!res || res.error) {
        setShowFeedError(true);
        console.error('Error fetching posts: ', res.error || 'Unknown error');
        return [];
      }

      return res;
    } catch (e) {
      setShowFeedError(true);
      console.error('Error fetching posts: ', e);
      return [];
    }
  };

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(100);
  };

  const updateFeed = async ({ filter = undefined, refresh = false } = {}) => {
    if (refresh) {
      setPosts([]);
    }
    try {
      if (!loadingRef.current) {
        const max = refresh ? PER_PAGE - 1 : posts.length + PER_PAGE - 1;
        const min = refresh ? 0 : posts.length;
        let total = 0;

        timeoutRef.current = setTimeout(() => {
          setLoadingRef(true);
        }, 200);
        const newPosts = await fetchPosts({ filter, min, max });

        clearTimeout(timeoutRef.current);
        if (refresh) {
          setPosts(newPosts);
          scrollToTop();
          setInfiniteDisabled(false);
          total = newPosts.length;
        } else {
          setPosts([...posts, ...newPosts]);
          total = posts.length + newPosts.length;
        }
        if (total >= response?.headers?.get('x-total-count')) setInfiniteDisabled(true);
      }
    } catch (e) {
      console.error('Error in updateFeed: ', e);
    } finally {
      setLoadingRef(false);
      clearTimeout(timeoutRef.current);
    }
  };

  const onScroll = async (ev) => {
    await updateFeed({ filter: activeFilter });
    ev.target.complete();
    if (posts.length === 1000) {
      setInfiniteDisabled(true);
    }
  };

  const onIonRefresh = async (event) => {
    await updateFeed({ filter: activeFilter, refresh: true });
    event.detail.complete();
  };

  const filterCallBack = async (filter) => {
    dispatch(updateActiveFilter(filter));
    await updateFeed({ filter, refresh: true });
  };

  const handleReportedAlertClose = () => {
    localStorage.setItem('isFlagged', 'false');
    setReported(false);
  };

  const handleFeedbackClose = () => {
    setHasGivenFeedback(false);
  };

  const handleWholeMeAlertClose = () => {
    setShowWholeMeAlert(false);
  };

  const goToWholeMe = () => {
    history.push('/wholeme/portrait');
  };

  const renderDefaultFeedContent = () => (
    <IonContent ref={contentRef}>
      {hasGivenFeedback && (
        <CardAtom
          img="assets/bulb-feedback.svg"
          header="Thanks for your feedback!"
          body="Our support team will be contact soon. In the meantime, explore our community!"
          containerStyles={{
            p: '20px',
            mx: 3,
            my: 1,
            bgcolor: '#E5E5DE',
          }}
          headerStyles={{ color: '#03295F' }}
          bodyStyles={{ color: '#2d2d2d', fontSize: 13 }}
          imgStyles={{ maxWidth: '85px', paddingTop: '16px' }}
          hasDismissableIcon={false}
          dismissCallback={handleFeedbackClose}
        />
      )}
      <IonRefresher slot="fixed" onIonRefresh={onIonRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {loading && posts.length < 1 ? (
        <LoadingSpinner />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            key={activeFilter.value}
          >
            {showWholeMeAlert && !showFeedError && (
              <Stack
                sx={{
                  width: 'fit-content',
                  mt: 1,
                  ml: '25px',
                }}
              >
                <AlertCard
                  header={wholeMeAlertCard.header}
                  body={wholeMeAlertCard.body}
                  imageSrc="/assets/wholeMeCtaImg.svg"
                  altImgText="Complete your profile!"
                  onCardClickCallBack={goToWholeMe}
                  onCloseCallBack={handleWholeMeAlertClose}
                />
              </Stack>
            )}
            {showFeedError && posts.length < 1 && (
              <Stack
                sx={{
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  paddingTop: '40px',
                }}
              >
                <FeedError />
              </Stack>
            )}
            {posts.length > 0 && (
              <Feed
                posts={posts}
                setPosts={setPosts}
                onChangeCallBack={filterCallBack}
                filterMeta={filterMeta}
              />
            )}
          </motion.div>
          <IonInfiniteScroll
            onIonInfinite={onScroll}
            threshold="300px"
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent loadingSpinner="crescent" />
          </IonInfiniteScroll>
        </>
      )}
    </IonContent>
  );

  useEffect(async () => {
    if (user) {
      buildFilterItemMeta();
      await updateFeed({ filter: activeFilter, refresh: true });
    }
  }, [user]);

  useEffect(
    () => () => {
      if (hasGivenFeedback) {
        setHasGivenFeedback(false);
      }
    },
    [],
  );

  useEffect(() => () => abort(), []);

  return (
    <IonPage style={{ background: '#f3f3ed' }}>
      <Header />
      {showNativeFeed && (
        <Stack
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'auto',
            mt: '15px',
            pb: '6px',
            ml: '24px',
            mr: '24px',
          }}
        >
          <FilterBar
            disableClickEventsOverrideRef={loadingRef}
            filterMeta={filterMeta}
            onChangeCallBack={filterCallBack}
          />
        </Stack>
      )}
      {showWebFeed && (
        <DesktopFilterBar
          disableClickEventsOverrideRef={loadingRef}
          filterMeta={filterMeta}
          onChangeCallBack={filterCallBack}
        />
      )}
      {showAffinityNetwork && (
        <div style={{ borderBottom: !affinityListRole && 'solid 2px #E5E5DD' }}>
          <AffinityNetwork
            role={affinityListRole}
            setRole={setAffinityListRole}
            isLoadComplete={isAffinityListLoaded}
          />
        </div>
      )}
      {showAffinityMemberList ? (
        <AffinityMemberList
          role={affinityListRole}
          setIsLoadComplete={setIsAffinityListLoaded}
        />
      ) : renderDefaultFeedContent()}
      {reported && !showFeedError && user?.role !== 'admin' && (
        <ReportCueCard userRole={user.role} onClickCallBack={handleReportedAlertClose} />
      )}
      <Footer />
    </IonPage>
  );
};

export default UnifiedUserFeed;
