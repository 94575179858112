const TOKEN_KEY = 'access_token';
const REGISTRATION_STATUS_KEY = 'registration_status';
export const REGISTRATION_COMPLETE_TOKEN = 'completed';
const COMPLETED_PROFILE_STATUS_KEY = 'completed_profile_status';
const YOURE_IN_STATUS_KEY = 'youre_in_userId';
const READY_TO_BACK_STATUS_KEY = 'ready_to_back_userId';

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const decodeToken = (tok) => {
  const base64Url = tok.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window.atob(base64)
      .split('')
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getTokenExpirationDate = (tok) => new Date(decodeToken(tok).exp * 1000);

export const getStoredToken = () => {
  const stored = localStorage.getItem(TOKEN_KEY);
  try {
    decodeToken(stored);
    return stored;
  } catch (e) {
    //
  }
  return undefined;
};

export const storeToken = (tok) => {
  try {
    // make sure we can decode the token before we go storing it
    decodeToken(tok);
    localStorage.setItem(TOKEN_KEY, tok);
  } catch (e) {
    //
  }
};

export const setRegistrationCompleteToken = () => {
  localStorage.setItem(REGISTRATION_STATUS_KEY, REGISTRATION_COMPLETE_TOKEN);
};

export const getRegistrationStatusToken = () => localStorage.getItem(REGISTRATION_STATUS_KEY);

export const removeRegistrationStatusToken = () => {
  localStorage.removeItem(REGISTRATION_STATUS_KEY);
};

export const getCompletedProfileStatusToken = () => localStorage.getItem(COMPLETED_PROFILE_STATUS_KEY);

export const setCompleteProfileToken = () => {
  localStorage.setItem(COMPLETED_PROFILE_STATUS_KEY, 'true');
};

export const setYoureInToken = (userId) => {
  localStorage.setItem(`${YOURE_IN_STATUS_KEY}:${userId}`, 'true');
};

export const setReadyToBackToken = (userId) => {
  localStorage.setItem(`${READY_TO_BACK_STATUS_KEY}:${userId}`, 'true');
};

export const getHasSeenYoureInToken = (userId) => localStorage.getItem(`${YOURE_IN_STATUS_KEY}:${userId}`);
