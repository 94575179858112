const isValidUrl = (url, regex) => {
  let inputUrl;
  // Prepend 'http://' if the URL doesn't start with a protocol
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    inputUrl = `http://${url}`;
  } else {
    inputUrl = url;
  }
  if (!inputUrl.match(regex)) {
    return false;
  }
  try {
    const isValid = new URL(inputUrl);
    if (isValid) {
      return { url: inputUrl, isValid: true };
    }
    return { url: inputUrl, isValid: false };
  } catch (e) {
    return { url: inputUrl, isValid: false };
  }
};

export default isValidUrl;
