/* eslint-disable max-len */
const identityIntakeContentByRole = {
  backr: {
    pageTitle: 'Introduce Yourself',
    pageSubtitle: 'Proteges really want to know about you in your own words. This  shows up on your profile!',
    drawerTitle: 'Why do you back?',
    inputPlaceholder: 'Share a bit about who you are, what\'s important to you, and why you are interested in backing.',
    exampleHeader: 'Need an example?',
    exampleContent: 'I am interested in backing because I believe in the power of mentorship and investing in the future generation. I find fulfillment in witnessing their growth and being a part of their development, and am passionate about supporting personal and academic goals.',
  },
  protege: {
    pageTitle: 'Introduce Yourself',
    pageSubtitle: 'Community members really want to know about you in your own words. This shows up on your profile!',
    drawerTitle: 'Share a bit about yourself',
    inputPlaceholder: 'Share a bit about who you are and what\'s important to you.',
    exampleHeader: 'Need an example?',
    exampleContent: 'I\'m a high school senior and live in Atlanta, GA. I come from a big family and am really close with two of my sisters. I love math and science and hope to one day be in the medical field. I\'m also on my schools Debate Team (I\'m the captain!) and in Model UN. Excited to be a part of this community.',
  },
};

const getStringContentByRole = (role) => {
  switch (role) {
    case 'backr':
    case 'protege':
      return identityIntakeContentByRole[role];
    default:
      return identityIntakeContentByRole.backr;
  }
};

export {
  getStringContentByRole,
};
