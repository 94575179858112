const pageContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
};

export const stepPageContainerStyles = {
  ...pageContainerStyles,
  padding: '40px 20px 20px 20px',
  height: 'calc(100vh - 60px)',
};

export const splashPageContainerStyles = {
  ...pageContainerStyles,
  padding: '20px',
  height: 'calc(100vh - 40px)',
};

export const contentStyles = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'none',
  overflowY: 'scroll',
  marginTop: '20px',
};

export const childrenStyles = {
  display: 'flex',
  flexDirection: 'column',
  padding: '1.2rem 0 1.3rem 0',
};

export const navContainerStyles = {
  height: 'fit-content',
  width: '100%',
  marginTop: '20px',
};
