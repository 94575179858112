import React from 'react';
import LoadingSpinner from 'components/generic/LoadingSpinner';

const WholeMeLoadingCircle = () => (
  <LoadingSpinner
    size="50vw"
    sx={{
      color: '#F7AE41',
      rotate: '-90deg',
      maxWidth: 350,
      maxHeight: 350,
    }}
    thickness={5}
  />
);

export default WholeMeLoadingCircle;
