import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IonIcon } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import appStrings from '../../common/app_strings';

const NoDonorbox = ({ onClickCallback }) => {
  const {
    invest: {
      fail: { noDonorbox, otherWays, additionalOptions },
    },
  } = appStrings;
  return (
    <motion.div
      key="investTabPending"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.5 }}
    >
      <Stack alignItems="center" spacing={4}>
        <Typography
          sx={{
            maxWidth: '24ch',
            color: '#03295F',
            fontWeight: 700,
            fontSize: 24,
            lineHeight: 1.25,
            textAlign: 'center',
          }}
        >
          {noDonorbox}
        </Typography>
        <Stack
          sx={{
            position: 'relative',
            width: 1,
            alignItems: 'center',
          }}
        >
          <object type="image/svg+xml" data="/assets/piggybank.svg">
            <img src="/assets/piggybank.svg" alt="piggybank" />
          </object>
          <img
            src="/assets/matching_shape_1.svg"
            alt=""
            role="presentation"
            className="invest-decoration invest-decoration-orange"
          />
          <img
            src="/assets/matching_shape_2.svg"
            alt=""
            role="presentation"
            className="invest-decoration invest-decoration-blue"
          />
          <img
            src="/assets/matching_shape_3.svg"
            alt=""
            role="presentation"
            className="invest-decoration invest-decoration-yellow"
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          p: 2,
          mt: 8,
          mb: 3,
          mx: 3,
          bgcolor: '#063D8F',
          color: '#fff',
          borderRadius: 2,
        }}
        onClick={onClickCallback}
      >
        <div className="goals-create-card">
          <img
            className="card-leading-icon"
            src="/assets/invest-coin.svg"
            alt="giving out coin"
          />
          <div className="goals-create-card-message">
            <Typography
              component="h2"
              sx={{
                position: 'relative',
                fontSize: '1rem !important',
                fontWeight: '700 !important',
              }}
            >
              {otherWays}
              <IonIcon
                style={{
                  position: 'relative',
                  top: '5px',
                  fontSize: '1.25rem',
                  marginLeft: '4px',
                }}
                src={arrowForwardOutline}
              />
            </Typography>
            <Typography
              sx={{ fontSize: 13, lineHeight: 1.25 }}
              mt="4px !important"
            >
              {additionalOptions}
            </Typography>
          </div>
        </div>
      </Box>
    </motion.div>
  );
};

NoDonorbox.propTypes = {
  onClickCallback: PropTypes.func,
};

NoDonorbox.defaultProps = {
  onClickCallback: () => {},
};

export default NoDonorbox;
