import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink } from '@ionic/react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Stack,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useFetch } from 'use-http';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import InputField from 'common/atoms/inputField/InputField';
import Header from '../components/Header';
import Footer from '../components/Footer';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const TeamList = () => {
  const [proteges, setProteges] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showFooter, setShowFooter] = useState(true);
  const isNative = Capacitor.isNativePlatform();

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const dismissKeyboardOnEnter = (e) => {
    if (e.key === 'Enter' && isNative) {
      Keyboard.hide();
      e.target.blur();
    }
  };

  if (isNative) {
    Keyboard.addListener('keyboardWillShow', () => {
      setShowFooter(false);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      setShowFooter(true);
    });
  }

  const params = new URLSearchParams();
  params.set('sort', '["first_name","ASC"]');
  const { loading, error, request } = useFetch();
  useEffect(() => {
    params.set('filter', `{"fullname":"${searchText}","role":"protege"}`);
    request.get(`/users?${params.toString()}`).then((data) => {
      setProteges(data);
    });
    return () => {
      request.abort();
    };
  }, [searchText]);

  useEffect(() => () => {
    if (isNative) {
      Keyboard.removeAllListeners();
    }
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <Typography variant="h4" sx={{ ml: 2 }}>Team List</Typography>
        <InputField
          onChange={handleSearchTextChange}
          placeholder="Enter name"
          fieldValue={searchText}
          stylesOverride={{
            marginTop: '8px',
            marginLeft: '16px',
            marginRight: '16px',
          }}
          debounceMilliSeconds={1000}
          theme="light"
          enterKeyHint="done"
          onKeyUp={dismissKeyboardOnEnter}
        />
        {loading && (
          <Stack alignItems="center" sx={{ mt: 3 }}>
            <CircularProgress size={60} />
          </Stack>
        )}
        {error && <Typography sx={{ ml: 2, mt: 2 }}>Failed to fetch proteges</Typography>}
        {!loading && !error && (
          <List>
            {proteges.map((protege, index) => (
              <React.Fragment key={protege.id}>
                <IonRouterLink routerLink={`/tabs/teams/admin/${protege.id}`}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img style={{ width: '3rem' }} src={defaultProfileImage} alt="profile" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        protege?.first_name
                        && protege?.last_name
                          ? `${protege?.first_name} ${protege?.last_name}'s Team`
                          : `User ${protege?.id}'s Team`
                      }
                    />
                  </ListItem>
                </IonRouterLink>
                {proteges[index + 1] ? <Divider /> : null}
              </React.Fragment>
            ))}
          </List>
        )}
      </IonContent>
      {showFooter && <Footer />}
    </IonPage>
  );
};

export default TeamList;
