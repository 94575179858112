import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box, Button, Stack,
} from '@mui/material';
import { IonButton } from '@ionic/react';
import PhoneNumberInput from '../common/atoms/input/PhoneNumberInput';
import BaseModal from '../common/atoms/BaseModal/BaseModal';
import appStrings from '../common/app_strings';

// todo: onModalClose: clear state values
// todo: onSubmit: clear state values
// todo: styles: space between input and the submit button
// todo: enable 'contact support' functionality

const PhoneInputModal = ({
  phone,
  setPhone,
  handleOnClose,
  isOpen,
  handleSubmit,
  showContactLink = false,
}) => {
  // todo: deconstruct strings out of the component
  const {
    otpValidation: {
      resendCode, sendBtn, contactSupport, contactBtn,
    },
  } = appStrings;

  const [disabled, setDisabled] = React.useState(true); // set disabled to button from condition

  const passValidation = (val) => {
    const { value } = val;

    setDisabled(value.length < 10);
    setPhone(value);
  };

  return (
    <BaseModal isOpen={isOpen} handleClose={handleOnClose}>
      <Typography
        sx={{
          color: '#2d2d2d',
          fontSize: 18,
          fontWeight: 500,
          lineHeight: 1.3125,
          mb: 2,
        }}
      >
        {resendCode}
      </Typography>
      <Stack>
        <PhoneNumberInput value={phone} onChange={passValidation} />
        {showContactLink ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <Typography
              sx={{
                position: 'relative',
                left: '5px',
                fontSize: '13px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {contactSupport}
            </Typography>
            <IonButton
              style={{
                position: 'relative',
                right: '6px',
                fontSize: '13px',
                fontWeight: 'bold',
                textTransform: 'none',
                color: '#063d8f',
              }}
              fill="clear"
            >
              <a
                id="contact-us-link"
                href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
              >
                {contactBtn}
              </a>
            </IonButton>
          </Box>
        ) : null}
        <Button
          variant="contained"
          disableElevation
          disableRipple
          fullWidth
          onClick={handleSubmit}
          disabled={disabled}
          sx={{
            maxWidth: 125,
            fontWeight: 700,
            textTransform: 'capitalize',
            py: 0.875,
            borderRadius: 100,
            alignSelf: 'center',
          }}
        >
          {sendBtn}
        </Button>
      </Stack>
    </BaseModal>
  );
};

PhoneInputModal.propTypes = {
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  showContactLink: PropTypes.bool,
};
PhoneInputModal.defaultProps = {
  handleSubmit: null,
  showContactLink: false,
};

export default PhoneInputModal;
