import React from 'react';
import PropTypes from 'prop-types';
import './RoleIndicator.css';

const RoleIndicator = ({ userRole, style }) => {
  if (userRole === 'protege') {
    return (
      <div className="protege-indicator" style={style}>
        P
      </div>
    );
  }
  if (userRole === 'admin') {
    return (
      <div className="admin-indicator" style={style}>
        A
      </div>
    );
  }
  return (
    <div className="backr-indicator" style={style}>
      B
    </div>
  );
};

RoleIndicator.propTypes = {
  userRole: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RoleIndicator.defaultProps = {
  style: {},
};

export default RoleIndicator;
