import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFetch, CachePolicies } from 'use-http';
import clsx from 'clsx';
import Header from 'components/Header';
import Footer from 'components/Footer';
import HeaderAtom from 'common/atoms/header/HeaderAtom';
import Carousel from 'common/atoms/carousel/Carousel';
import { useAuthContext } from 'providers/auth-provider';
import { buildBackingInActionCards } from './utils';
import './BackingInAction.css';

const BackingInAction = () => {
  const { user } = useAuthContext();
  const [workflow, setWorkflow] = useState(null);
  const history = useHistory();
  const {
    get, response, error, abort,
  } = useFetch(
    '/workflow-meta/backing-in-action',
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
  );

  const goToAccountCreation = () => {
    history.push('/register/register/backr');
  };

  const fetchWorkflowMeta = async () => {
    const data = await get();
    if (response.ok) setWorkflow(data);
    else console.log(error.message);
  };

  useEffect(() => {
    fetchWorkflowMeta();
    return () => {
      abort();
    };
  }, []);

  const cards = workflow ? buildBackingInActionCards(workflow) : [];
  const paginationOffset = user ? '130px' : '40px';

  return (
    <IonPage>
      {!user ? (
        <HeaderAtom
          backButtonLabel="Learn"
          actionLabel="Start Backing"
          actionCallback={goToAccountCreation}
        />
      ) : (
        <Header />
      )}
      <IonContent>
        <Box
          sx={{
            width: 1,
            height: 1,
            minHeight: `calc(100vh - ${paginationOffset} - var(--ion-safe-area-top))`,
            pb: 6,
          }}
          className={clsx('backing-in-action-wrapper', user && 'backing-in-action-wrapper-alt')}
        >
          <Carousel
            slides={cards}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 2,
              hideLast: true,
            }}
          />
        </Box>
      </IonContent>
      {user && <Footer />}
    </IonPage>
  );
};

export default BackingInAction;
