export const networkData = [
  {
    label: '25+ College Graduates',
    value: '25+ College Graduates',
    imgSrc: '/assets/profile/network/25college-grads.svg',
  },
  {
    label: '5+ Adults across 10+ Cities',
    value: '5+ Adults across 10+ Cities',
    imgSrc: '/assets/profile/network/adults-across-10cities.svg',
  },
  {
    label: '10+ Business Leaders',
    value: '10+ Business Leaders',
    imgSrc: '/assets/profile/network/10business-leaders.svg',
  },
  {
    label: '5+ Physicians',
    value: '5+ Physicians',
    imgSrc: '/assets/profile/network/5healthcare-pros.svg',
  },
  {
    label: '5+ Government Officials',
    value: '5+ Governement Officials',
    imgSrc: '/assets/profile/network/5government-officials.svg',
  },
  {
    label: '10+ Engineers',
    value: '10+ Engineers',
    imgSrc: '/assets/profile/network/5engineers.svg',
  },
  {
    label: '5+ PhD Graduates',
    value: '5+ PhD Graduates',
    imgSrc: '/assets/profile/network/5phd-grads.svg',
  },
  {
    label: '25+ Adults living outside the USA',
    value: '25+ Adults living outside the USA',
    imgSrc: '/assets/profile/network/adults-outside-usa.svg',
  },
  {
    label: '5+ Professional Creatives',
    value: '5+ Professional Creatives',
    imgSrc: '/assets/profile/network/creatives.svg',
  },
  {
    label: '25+ Active Military Professionals',
    value: '25+ Active Military Professionals',
    imgSrc: '/assets/profile/network/active-military.svg',
  },
  {
    label: '5+ Business Owners',
    value: '5+ Business Owners',
    imgSrc: '/assets/profile/network/business-owners.svg',
  },
];
