/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import GeneralTextCardAtom from 'common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import BackingMiddle from 'common/atoms/backingCard/BackingMiddle';
import InfoCardAtom from 'common/atoms/card/InfoCardAtom';
import CardContentAtom from 'common/atoms/whatIsBackingCards/CardContentAtom';
import BackrVideoAtom from 'common/atoms/BackrVideo/BackrVideoAtom';
import WhatIsBackingList from 'common/atoms/WhatIsBackingList';

const MiddleCardSection = ({ meta, spacing }) =>
  meta && (
    <Box mt={spacing}>
      {meta?.about_me && (
        <GeneralTextCardAtom
          title={meta?.about_me?.title}
          body={meta?.about_me?.body}
          titleStyles={meta?.about_me?.title_styles}
          bodyStyles={meta?.about_me?.body_styles}
        />
      )}
      {meta?.backing_animation && (
        <BackingMiddle
          videoUrl={meta?.backing_animation?.video_url}
          videoPreviewUrl={meta?.backing_animation?.video_preview_url}
        />
      )}
      {meta?.intermission_caption && (
        <GeneralTextCardAtom
          body={convertHtmlToReact(meta?.intermission_caption?.body)}
          bodyStyles={meta?.intermission_caption?.body_styles}
        />
      )}
      {meta?.infocard_1 && (
        <InfoCardAtom
          body={meta?.infocard_1.body}
          imageSrc={meta?.infocard_1.img}
          altImgText={meta?.infocard_1.altImg}
        />
      )}
      {meta?.backingCards && (
        <Box sx={meta?.containerStyle}>
          {meta?.backingCards.map((c) => (
            <CardContentAtom image={c.img} body={convertHtmlToReact(c.body)} />
          ))}
        </Box>
      )}
      {meta?.video && (
        <BackrVideoAtom
          videoURI={meta?.video.uri}
          videoThumbnail={meta?.video.thumbnail}
          btnLabel={meta?.video.label}
        />
      )}
      <Box>{meta?.backingdata && <WhatIsBackingList meta={meta?.backingdata} showIcons />}</Box>
    </Box>
  );

MiddleCardSection.propTypes = {
  meta: PropTypes.shape({
    about_me: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      title_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      body_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
    intermission_caption: PropTypes.shape({
      body: PropTypes.string,
      body_styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
    backing_animation: PropTypes.shape({
      video_url: PropTypes.string,
      video_preview_url: PropTypes.string,
    }),
    infocard_1: PropTypes.shape({
      body: PropTypes.string,
      img: PropTypes.string,
      altImg: PropTypes.string,
    }),
    backingCards: PropTypes.arrayOf(PropTypes.string),
    containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    video: PropTypes.shape({
      uri: PropTypes.string,
      thumbnail: PropTypes.string,
      label: PropTypes.string,
    }),
    backingdata: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

MiddleCardSection.defaultProps = {
  meta: null,
};

export default MiddleCardSection;
