import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';

const splashImage = {
  src: './assets/profile/career/career-splash.svg',
  alt: 'big barber shop',
  styles: {
    width: '62%',
  },
};

const profileCareerIntakeSplashPage = ({ nextOnClick, prevOnClick }) => (
  <ProfileIntakeSplashPage
    title="Career"
    subtitle="Thanks for sharing! Now we’ll ask you a couple of questions about your career experience."
    splashImage={splashImage}
    nextButton={{ label: "Let's Go", onClick: nextOnClick }}
    prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    backgroundOverlay="assets/profile/education/career-splash-overlay.svg"
  />
);

export default profileCareerIntakeSplashPage;
