import React from 'react';
import { Box } from '@mui/material';
import VideoPlayer from '../../../../components/VideoPlayer';

const WholeMeProfileVideoPlayer = ({ videoUrl }) => (
  <Box
    sx={{
      width: 1,
      height: 215,
      overflow: 'hidden',
      '& > div': {
        width: 1,
        height: 1,
      },
      '& img': {
        objectFit: 'contain',
        objectPosition: 'center',
      },
      '& video': {
        width: 'inherit',
        height: 'inherit',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    }}
  >
    <VideoPlayer
      videos={[
        {
          uri: videoUrl,
        },
      ]}
      hasFrameThumbnail
    />
  </Box>
);

export default WholeMeProfileVideoPlayer;
