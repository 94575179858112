/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

/**
 * The values for these options are directly inform mappings that drive the
 * automated grouping of users by their Whole Me profile selections.
 *
 * Changes made to the values of these options will require corresponding changes in the listed files, and potentially others.
 * See:
 *  - backrs_api/src/services/eventBridge/eventHandlers/eventHelpers/profileEvents/groups/maps.js
 */
export const religionData = [
  {
    label: 'Atheism',
    value: 'atheism',
    variant: 'oval',
  },
  {
    label: 'Agnostic',
    value: 'agnostic',
    variant: 'oval',
    infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjOgvvA6az_AhV_mGoFHcexCNgQFnoECAgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FAtheism&usg=AOvVaw3ZfdlACw4RcpryeF-ZDsIH',
  },
  {
    label: 'Buddhism',
    value: 'buddhism',
    variant: 'oval',
    infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwim_ca26az_AhVak2oFHRi0BaYQFnoECAgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBuddhism&usg=AOvVaw1q8HY6n8_9G6ZJ45Xe86j_',
  },
  {
    label: 'Christianity',
    value: 'christianity',
    variant: 'oval',
    infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjyn7OW6az_AhUclWoFHbO2B6kQFnoECAgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FChristianity&usg=AOvVaw1aeLJMnpYKSQRDvUWa_YSZ',
  },
  {
    label: 'Hinduism',
    value: 'hinduism',
    variant: 'oval',
    infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjMnoi76az_AhUhnGoFHTFYAy0QFnoECAgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHinduism&usg=AOvVaw3D5EZDP6Pn6Cg3Ene6JtxC',
  },
  {
    label: 'Islam',
    value: 'islam',
    variant: 'oval',
    infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiJ_JGr6az_AhW4kWoFHVP6AkoQFnoECAsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FIslam&usg=AOvVaw1keY7V3MWE_TN34LapdfIQ',
  },
  {
    label: 'Judaism',
    value: 'judaism',
    variant: 'oval',
    infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiA1uOw6az_AhX6lmoFHZ-uA-AQFnoECA8QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FJudaism&usg=AOvVaw3yWsqFsrlr8TcZRCLweDrV',
  },
  {
    label: 'Other',
    value: 'other',
    variant: 'oval',
    infoUrl: '',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer-not-to-say',
    variant: 'oval',
    infoUrl: '',
  },
];
