/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { isPlatform } from '@ionic/react';
import { Clipboard } from '@awesome-cordova-plugins/clipboard';
import toast from 'react-hot-toast';
import ColumnRenderer from 'common/molecules/columnRenderer/ColumnRenderer';
import { createEditHandler } from 'pages/wholeme/util/profile_util';
import {
  Avatar, Box, IconButton, styled, Stack,
} from '@mui/material';
import WholeMeProfileSection from 'pages/wholeme/molecules/wholeMeProfileSection/WholeMeProfileSection';
import WholeMeProfileSectionRow from 'pages/wholeme/molecules/wholeMeProfileSectionRow/WholeMeProfileSectionRow';
import WholeMeProfileAvatar from 'pages/wholeme/molecules/wholeMeProfileAvatar/WholeMeProfileAvatar';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import { containerStyles } from 'common/molecules/pillRenderer/pillRendererStyles';
import { firstOptionOf, firstValueOf, resolveDefaultIcons } from '../../util/profile_util';
import { previewAvatar } from './wholeMeProfileIdentityStyles';
import { RoundEditIcon } from '../../icons/Icons';
import {
  columnContainerStyle,
  childrenContainerStyle,
} from '../wholeMeProfileAvatar/WholeMeAvatarStyles';
import {
  affinitiesFromProfile, filteredQuizItems, mapQuizValues, resolveQuizIcon,
} from './utils';
import ProfileSponsorshipRender from '../profileSponsorshipRender/ProfileSponsorshipRender';
import { openInBrowser } from '../../../../helpers/broswer';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const copyCode = (string) => {
  if (isPlatform('web') || isPlatform('mobileweb')) {
    navigator.clipboard.writeText(string);
  } else {
    Clipboard.copy(string);
  }
  toast('Copied Code!', { id: `mc-${string}` });
};

const ImageContainer = styled('div')({ margin: '10px 9px 0 0' });

const titleStyles = {
  color: '#2D2D2D',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: 'normal',
};

const RenderView = ({
  profile, onEdit, onPost, setIsEditMode, isViewingAdmin, permissions, refreshProfile,
}) => {
  const {
    city,
    state,
    job_title,
    quiz = [],
    user_match_code,
    user_sponsors,
    linkedIn_url,
  } = profile;

  const affinities = affinitiesFromProfile(profile);
  const quizItems = filteredQuizItems(quiz);

  const avatarProfile = {
    profile_image: profile.profile_image,
    first_name: profile.first_name,
    last_name: profile.last_name,
    role: profile.role,
    calendly_url: firstValueOf(profile.calendly_url),
    relationshipStatus: profile.relationshipStatus,
    teamCount: profile.teamCount,
    groupCount: profile.groupCount,
    donorboxId: profile.donorboxId,
  };

  return (
    <Stack sx={childrenContainerStyle}>
      <Box sx={columnContainerStyle}>
        <WholeMeProfileAvatar
          profile={avatarProfile}
          permissions={permissions}
          editMeta={{ onEdit, setIsEditMode }}
          refreshProfile={refreshProfile}
        />
        <ColumnRenderer>
          {
            firstValueOf(city) && firstValueOf(state)
            && (
              <WholeMeProfileSectionRow
                styleOverrides={{ paddingBottom: '0' }}
                iconSrc="assets/icons/identity-affiliation/location-blue.svg"
                onPost={onPost}
                label={isViewingAdmin ? `${firstValueOf(city)}` : `${firstValueOf(city)}, ${firstValueOf(state)}`}
                labelStyle={{ color: '#5B5B5B' }}
              />
            )
          }
          {affinities.map((next) => (
            <WholeMeProfileSectionRow
              styleOverrides={{ paddingBottom: '0' }}
              key={next.label}
              iconSrc={next.iconSrc}
              onPost={onPost}
              label={next.label}
              labelStyle={{ color: '#5B5B5B' }}
            />
          ))}
          {quizItems.map((response) => (
            <WholeMeProfileSectionRow
              styleOverrides={{ paddingBottom: '0' }}
              key={response.value}
              iconSrc={resolveQuizIcon(response.option_id, response.suboption_id, response.value)}
              onPost={onPost}
              label={`${response.value}`}
              labelStyle={{ color: '#5B5B5B' }}
            />
          ))}
          {firstValueOf(job_title) && (
            <WholeMeProfileSectionRow
              styleOverrides={{ paddingBottom: '0' }}
              iconSrc="assets/profile/career/blue-briefcase.svg"
              onPost={onPost}
              label={`${firstValueOf(job_title)}`}
              labelStyle={{ color: '#5B5B5B' }}
            />
          )}
          {firstValueOf(linkedIn_url) && (
            <WholeMeProfileSectionRow
              styleOverrides={{ paddingBottom: '0', cursor: 'pointer' }}
              iconSrc={resolveDefaultIcons('linkedIn')}
              onClickOverride={() => openInBrowser(firstValueOf(linkedIn_url) || '')}
              label="LinkedIn"
              labelStyle={{ color: '#5B5B5B', textDecoration: 'underline' }}
            />
          )}
          {firstValueOf(user_match_code) && (
            <WholeMeProfileSectionRow
              styleOverrides={{ paddingBottom: '0' }}
              iconSrc={resolveDefaultIcons('user_match_code')}
              onClickOverride={() => copyCode(firstValueOf(user_match_code))}
              label={`${firstValueOf(user_match_code)}`}
              labelStyle={{ color: '#5B5B5B' }}
            />
          )}
        </ColumnRenderer>
        {user_sponsors?.length > 0 && (
        <Stack sx={{ marginTop: '10px', paddingLeft: '4px' }}>
          <ProfileSponsorshipRender meta={user_sponsors} />
        </Stack>
        )}
      </Box>
    </Stack>
  );
};

const RenderEdit = ({ profile, onEdit, setIsEditMode }) => {
  const {
    profile_image, gender, ethnicity, city, state, job_title, quiz, birthday, calendly_url,
  } = profile;
  const affinities = affinitiesFromProfile(profile);
  const [
    jobTitle,
    genderVal,
    cityVal,
    stateVal,
    ethnicityVal,
    birthdayVal,
    calendlyVal,
  ] = [firstValueOf(job_title), firstOptionOf(gender), firstValueOf(city), firstValueOf(state), firstOptionOf(ethnicity), firstValueOf(birthday), firstValueOf(calendly_url)];
  const quizValues = mapQuizValues(quiz);

  return (
    <WholeMeProfileSection
      expanded
      title="Identity"
      titleSx={titleStyles}
      headerSx={{ marginBottom: '12px' }}
      titleIcon={(
        <ImageContainer>
          <Avatar
            sx={previewAvatar}
            src={profile_image}
            onClick={createEditHandler(onEdit, 'identity', 'photo')}
          >
            <img style={previewAvatar} src={defaultProfileImage} alt="profile" />
          </Avatar>
          <IconButton sx={{ padding: 0 }} onClick={createEditHandler(onEdit, 'identity', 'photo')}>
            <RoundEditIcon
              width={37}
              height={37}
              position="absolute"
              left={65}
              bottom={16}
            />
          </IconButton>
        </ImageContainer>
      )}
      onTitleClick={() => setIsEditMode(false)}
      expandedBorder={false}
    >
      <RenderEditSection
        text="Zip Code"
        onClick={onEdit}
        category="identity"
        step="zip"
        isCreate={!cityVal && !stateVal}
        items={cityVal && [`${cityVal}, ${stateVal}`]}
      />
      <RenderEditSection
        text="Gender"
        onClick={onEdit}
        category="identity"
        step="gender"
        isCreate={!genderVal}
        items={genderVal && [genderVal]}
      />
      <RenderEditSection
        text="Affinity"
        onClick={onEdit}
        category="identity"
        step="affinity"
        isCreate={affinities.length < 1}
        items={affinities.map((a) => a.label)}
      />
      <RenderEditSection
        text="Ethnicity"
        onClick={onEdit}
        category="identity"
        step="ethnicity"
        isCreate={!ethnicityVal}
        items={ethnicityVal && [ethnicityVal]}
      />
      <RenderEditSection
        text="Occupation"
        onClick={onEdit}
        category="career"
        step="occupation"
        isCreate={!jobTitle}
        items={jobTitle && [jobTitle]}
      />
      {
        Object.keys(quizValues).map((key) => (
          <RenderEditSection
            text={key}
            key={key}
            onClick={onEdit}
            category="identity"
            step="quiz"
            isCreate={quizValues[key].length < 1}
            items={quizValues[key]}
          />
        ))
      }
      <RenderEditSection
        text="Birth Date"
        onClick={onEdit}
        category="identity"
        step="birthday"
        isCreate={!birthdayVal}
        items={birthdayVal && [birthdayVal]}
      />
      <RenderEditSection
        text="Calendly"
        onClick={onEdit}
        category="identity"
        step="calendly"
        isCreate={!calendlyVal}
        items={calendlyVal && [calendlyVal]}
      />
    </WholeMeProfileSection>
  );
};

const RenderEditSection = ({
  onClick, text, category, step, isCreate, items,
}) => (
  <div style={{ marginBottom: '12px' }}>
    <WholeMeProfileSectionRow
      styleOverrides={{ paddingBottom: '0' }}
      label={text}
      onEdit={onClick}
      navigationMeta={{ categoryId: category, stepId: step }}
      isCreate={isCreate}
      labelStyle={{ fontWeight: 700, lineHeight: 'normal', color: '#063D8F' }}
    />
    <PillRenderer items={items || []} containerStyleOverrides={{ ...containerStyles, margin: '8px 0 0 22px' }} />
  </div>
);

const WholeMeProfileIdentity = ({
  profile, onEdit, onPost, isViewingAdmin, permissions, refreshProfile,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  if (!profile) {
    return null;
  }

  return (
    <div>
      {isEditMode && <RenderEdit profile={profile} onEdit={onEdit} setIsEditMode={setIsEditMode} />}
      {!isEditMode && (
        <RenderView
          profile={profile}
          onEdit={onEdit}
          onPost={onPost}
          setIsEditMode={setIsEditMode}
          isViewingAdmin={isViewingAdmin}
          permissions={permissions}
          refreshProfile={refreshProfile}
        />
      )}
    </div>
  );
};

export default WholeMeProfileIdentity;
