import React, { useState } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import MainImage from '../../wholeme/molecules/mainImage/MainImage';
import {
  noListContainerStyle,
} from './AffinityNetworkStyles';
import appStrings from '../../../common/app_strings';
import WholeMeProfileMessageButton from '../../wholeme/molecules/wholeMeProfileMessageButton/WholeMeProfileMessageButton';

const { affinityNetwork: { affinityMemberList } } = appStrings;

const AffinityMemberEmptyList = ({ userId }) => {
  const [isMessageButtonLoading, setIsMessageButtonLoading] = useState(false);
  return (
    <Box sx={noListContainerStyle}>
      <Stack sx={{ paddingTop: '3rem', gap: '2rem' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
          {affinityMemberList.uhOh}
          <br />
          {affinityMemberList.listNotFound}
        </Typography>
        <Stack sx={{ justifyContent: 'center', flexDirection: 'row' }}>
          <Typography>{affinityMemberList.tryRefresh}</Typography>
          &nbsp;
          <WholeMeProfileMessageButton
            isText
            isViewingAdmin
            userId={userId}
            label={affinityMemberList.contactSupport}
            setIsLoading={setIsMessageButtonLoading}
            isMessageButtonLoading={isMessageButtonLoading}
            buttonStyleOverride={{
              color: '#5981D1',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          />
        </Stack>
      </Stack>
      <MainImage
        imageSrc="assets/profile/career/career-stage.svg"
        imageAlt="forklift"
        imageStylesOverride={{ width: '70%' }}
      />
    </Box>
  );
};

export default AffinityMemberEmptyList;
