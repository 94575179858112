import React from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonCard,
  IonCardContent,
  IonButton,
} from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './SmartGoals.css';

const SmartGoals = ({ history }) => (
  <IonPage>
    <IonHeader className="ion-no-border">
      <IonToolbar
        className="back-button-toolbar"
        style={{ '--background': '#03295F' }}
      >
        <IonButtons slot="start">
          <IonBackButton
            icon="/assets/back-arrow-white.svg"
            style={{
              '--icon-font-size': '1.25rem',
              '--icon-padding-start': '8px',
              '--icon-padding-end': '12px',
              '--icon-padding-top': '4px',
              '--icon-margin-end': '6px',
              '--color': '#fff',
            }}
            className="back-button-custom"
          />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent
      style={{
        '--background': '#03295F',
        '--padding-start': '24px',
        '--padding-end': '24px',
      }}
      fullscreen
    >
      <Typography
        mt={3}
        mb={2}
        color="#fff"
        fontSize={20}
        fontWeight={700}
        sx={{ textAlign: 'center' }}
      >
        What is a
        <Typography
          component="span"
          sx={{
            color: '#f7ae41',
            mb: 2,
            fontSize: 24,
            fontWeight: 700,
            verticalAlign: 'baseline',
          }}
        >
          {' '}
          SMART
          {' '}
        </Typography>
        goal?
      </Typography>
      <Typography
        color="#fff"
        mb={3.5}
        px={2}
        sx={{ textAlign: 'center', lineHeight: 1.25 }}
      >
        When designing your goal, keep the following in mind:
      </Typography>
      <Stack spacing={3} mb={6}>
        <div className="goals-smart-item">
          <Typography
            color="#f7ae41"
            fontSize={50}
            width="22%"
            textAlign="center"
            lineHeight={0.9}
            mr={1}
          >
            S
          </Typography>
          <div className="goals-smart-description">
            <Typography color="#f7ae41" fontSize={20} fontWeight={700}>
              Specific
            </Typography>
            <Typography color="#fff" fontSize={14} lineHeight={1.25}>
              Your goal is direct, detailed, and meaningful.
            </Typography>
          </div>
        </div>
        <div className="goals-smart-item">
          <Typography
            color="#f7ae41"
            fontSize={50}
            width="22%"
            textAlign="center"
            lineHeight={0.9}
            mr={1}
          >
            M
          </Typography>
          <div className="goals-smart-description">
            <Typography color="#f7ae41" fontSize={20} fontWeight={700}>
              Measureable
            </Typography>
            <Typography color="#fff" fontSize={14} lineHeight={1.25}>
              Your goal is quantifiable to track progress or success.
            </Typography>
          </div>
        </div>
        <div className="goals-smart-item">
          <Typography
            color="#f7ae41"
            fontSize={50}
            width="22%"
            textAlign="center"
            lineHeight={0.9}
            mr={1}
          >
            A
          </Typography>
          <div className="goals-smart-description">
            <Typography color="#f7ae41" fontSize={20} fontWeight={700}>
              Attainable
            </Typography>
            <Typography color="#fff" fontSize={14} lineHeight={1.25}>
              Your goal is realistic and you have the tools and/or resources to
              attain it.
            </Typography>
          </div>
        </div>
        <div className="goals-smart-item">
          <Typography
            color="#f7ae41"
            fontSize={50}
            width="22%"
            textAlign="center"
            lineHeight={0.9}
            mr={1}
          >
            R
          </Typography>
          <div className="goals-smart-description">
            <Typography color="#f7ae41" fontSize={20} fontWeight={700}>
              Relevant
            </Typography>
            <Typography color="#fff" fontSize={14} lineHeight={1.25}>
              Your goal aligns with your personal mission.
            </Typography>
          </div>
        </div>
        <div className="goals-smart-item">
          <Typography
            color="#f7ae41"
            fontSize={50}
            width="22%"
            textAlign="center"
            lineHeight={0.9}
            mr={1}
          >
            T
          </Typography>
          <div className="goals-smart-description">
            <Typography color="#f7ae41" fontSize={20} fontWeight={700}>
              Time-Based
            </Typography>
            <Typography color="#fff" fontSize={14} lineHeight={1.25}>
              Your goal has a deadline.
            </Typography>
          </div>
        </div>
      </Stack>
      <IonCard className="goals-smart-card">
        <IonCardContent className="goals-smart-card-content">
          <div className="goals-card-container">
            <img src="/assets/bullseye.svg" alt="goals bullseye" />
            <div className="goals-card-message">
              <h2 className="goals-card-message-header">
                An example SMART goal:
              </h2>
              <p>
                &quot;I want to improve my physical health by being active for
                30 minutes, 3 times per week.&quot;
              </p>
            </div>
          </div>
        </IonCardContent>
      </IonCard>
      <IonButton
        style={{
          '--color': '#fff',
          '--border-color': '#fff',
          marginBottom: '24px',
          fontWeight: 700,
        }}
        size="large"
        expand="block"
        shape="round"
        fill="outline"
        onClick={() => history.goBack()}
      >
        Got it!
      </IonButton>
    </IonContent>
  </IonPage>
);

SmartGoals.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default SmartGoals;
