import React from 'react';
import {
  searchOptionsSuggestionsListStyles,
  searchSuggestionStyles,
  webSearchOptionsSuggestionsListStyles,
} from './searchInputStyles';
import { useAppState } from '../../../../providers/app-state/app-state-provider';

const SearchInputResultsList = ({ searchOptions, handleSelect, resultAttributeId }) => {
  const {
    platform: { isNativePlatform },
  } = useAppState();

  const optionsStyles = isNativePlatform
    ? searchOptionsSuggestionsListStyles
    : webSearchOptionsSuggestionsListStyles;

  return (
    <div style={optionsStyles}>
      {searchOptions.map((option, i) => {
        const key = `${resultAttributeId ? option?.[resultAttributeId] : option}-${i}`;

        return (
          <div style={searchSuggestionStyles} onClick={() => handleSelect(option)} key={key}>
            {resultAttributeId ? option?.[resultAttributeId] : option}
          </div>
        );
      })}
    </div>
  );
};

export default SearchInputResultsList;
