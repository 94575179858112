import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import useFetch from 'use-http';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import Button from 'common/atoms/button/Button';
import { useAuthContext } from 'providers/auth-provider';
import { useAppStateDispatch, actions } from 'providers/app-state/app-state-provider';
import { sortTeamsByRelationship } from 'helpers/transformers';
import appStrings from '../../common/app_strings';
import TeamSummaryCard from './molecules/TeamSummaryCard';
import UnlockScreen from '../../common/molecules/UnlockScreen';
import './BackrTeamView.css';

const BackrTeamDetails = ({ history }) => {
  const { reported } = useAuthContext();
  const appStateDispatch = useAppStateDispatch();
  const {
    teams: { multiSelect, unlockTeams },
  } = appStrings;
  const [relationships, setRelationships] = useState([]);
  const [showUnlock, setShowUnlock] = useState(false);
  const [requiresBgCheck, setRequiresBgCheck] = useState(false);
  const {
    get: getRelationships,
    loading,
    response,
    abort,
  } = useFetch(
    '/users/teams',
  );
  const { post: postMatchRun, response: matchRunResponse } = useFetch('/matching/match-run');

  const goToTeamView = (protegeId) => {
    history.push(`/tabs/backr/teams/details/${protegeId}`);
  };

  const loadRelationships = async () => {
    const data = await getRelationships();
    const bgCheckRequired = data && data.some((r) => r?.bg_check_status === 'incomplete');
    setRequiresBgCheck(bgCheckRequired);
    if (data.length < 1) {
      setShowUnlock(true);
    }
    if (response.ok && data.length === 1) {
      history.push(`/tabs/backr/teams/details/${data[0].related_user_id}`, {
        isOneTeam: true,
      });
      return;
    }
    if (response.ok) {
      setRelationships(sortTeamsByRelationship(data));
    }
  };

  const goToBgCheck = () => {
    history.push('/background-status-meter');
  };

  const handleJoinATeamOnClick = async () => {
    try {
      const matchRun = await postMatchRun();
      if (!matchRunResponse.ok) {
        throw new Error(matchRunResponse.data);
      }
      appStateDispatch(actions.setMatchRun(matchRun));

      if (matchRun?.id) {
        history.push('/match-preview-screen');
      } else {
        history.push('/wholeme/pre-matching');
      }
    } catch (e) {
      console.error(e);
      toast.error(`Unable to Join a Team: ${e.message}`, { id: 'forbidden' });
      history.push('/tabs/feed');
    }
  };

  const unlockTeamsButtonMeta = {
    label: unlockTeams.button,
    callback: handleJoinATeamOnClick,
  };

  const showBgCheckButton = !showUnlock && requiresBgCheck;

  const unlockTeamsMeta = {
    variant: 'teams',
    title: unlockTeams.title,
    body: unlockTeams.body,
    image: 'assets/tri-group-smiley.svg',
    buttonMeta: unlockTeamsButtonMeta,
    userReported: reported,
  };

  useEffect(() => {
    loadRelationships();
    return () => {
      abort();
      setRelationships([]);
    };
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {showUnlock ? (
              <Stack
                sx={{
                  pt: '4rem',
                }}
              >
                <UnlockScreen meta={unlockTeamsMeta} />
              </Stack>
            ) : (
              <>
                <Typography
                  sx={{
                    color: '#2d2d2d',
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    textAlign: 'center',
                    mt: 2.5,
                    mb: 3,
                  }}
                >
                  {multiSelect}
                </Typography>
                <Stack spacing={2.5} sx={{ mx: 3 }}>
                  {relationships?.map((relationship) => (
                    <TeamSummaryCard
                      key={relationship?.id}
                      relationship={relationship}
                      navigateToTeam={goToTeamView}
                      isPending={relationship?.relationship === 'proposed_match'}
                    />
                  ))}
                </Stack>
              </>
            )}
            {showBgCheckButton && (
            <Button
              label="Check my status"
              onClickCallBack={() => goToBgCheck()}
              buttonStyles={{
                background: '#03295F',
                fontSize: 18,
                width: '50%',
                fontWeight: 500,
                alignSelf: 'center',
                p: 0,
                mt: 4,
                mr: 2,
              }}
              labelStyles={{
                pb: '8px',
              }}
            />
            )}
          </motion.div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

BackrTeamDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default BackrTeamDetails;
