import React, { useState } from 'react';
import {
  IonContent,
  IonButton,
  IonRouterLink,
} from '@ionic/react';
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
  Radio,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import useFetch from 'use-http';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PageWithBackButton from 'components/generic/PageWithBackButton';
import './GoalsCreate.css';
import { useAuthContext } from 'providers/auth-provider';

const GoalsCreate = ({ history }) => {
  const { user } = useAuthContext();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' });
  const { post } = useFetch();
  const [selectedGoalType, setSelectedGoalType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    const goalFormData = {
      ...data,
      status: 'in_progress',
      type: selectedGoalType,
      user_id: user.id,
    };
    setIsSubmitting(true);
    await post('/goals', goalFormData);
    history.push('/tabs/protege/goals');
  };

  const buttons = [
    {
      key: 'academic',
      title: 'Academic',
      icon: '/assets/academic-goal.svg',
      classname: 'goals-type-academic',
      selectedClassname: {
        'goals-type-academic-selected': selectedGoalType === 'academic',
      },
    },
    {
      key: 'passion',
      title: 'Passion',
      icon: '/assets/passion-goal.svg',
      classname: 'goals-type-passion',
      selectedClassname: {
        'goals-type-passion-selected': selectedGoalType === 'passion',
      },
    },
    {
      key: 'health',
      title: 'Health & Wellness',
      icon: '/assets/health-goal.svg',
      classname: 'goals-type-health',
      selectedClassname: {
        'goals-type-health-selected': selectedGoalType === 'health',
      },
    },
    {
      key: 'career',
      title: 'Career',
      icon: '/assets/career-goal.svg',
      classname: 'goals-type-career',
      selectedClassname: {
        'goals-type-career-selected': selectedGoalType === 'career',
      },
    },
  ];

  const bodyInputRef = React.useRef(null);

  return (
    <PageWithBackButton backButtonText="New Goal">
      <IonContent fullscreen>
        <form>
          <Box mx={3}>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={700}
              sx={{ textAlign: 'center', mt: 3.5, mb: 4.5 }}
            >
              Choose a type of goal:
            </Typography>
            <Stack spacing={2} mb={5}>
              {buttons.map((goalType) => (
                <button
                  key={goalType.key}
                  type="button"
                  className={clsx(
                    'goals-type-button',
                    goalType.classname,
                    goalType.selectedClassname,
                  )}
                  onClick={() => setSelectedGoalType(goalType.key)}
                >
                  <img
                    src={goalType.icon}
                    className="goals-type-button-icon"
                    alt={`${goalType.key} goal icon`}
                  />
                  <Typography
                    variant="h4"
                    fontWeight={700}
                    color="#fff"
                    fontSize={24}
                    width="100%"
                  >
                    {goalType.title}
                  </Typography>
                </button>
              ))}
            </Stack>
            <div className="goals-smart-wrapper">
              <Typography
                variant="h4"
                fontWeight={700}
                fontSize={20}
                sx={{ textAlign: 'center', mr: 1, mb: 5 }}
              >
                Choose your SMART goal
              </Typography>
              <IonRouterLink routerLink="/protege/goals-create/smart">
                <div className="goals-smart-link">?</div>
              </IonRouterLink>
            </div>
            <Stack spacing={3} mb={6}>
              <Box
                sx={{
                  bgcolor: '#fff',
                  p: 2,
                  borderRadius: 2,
                }}
              >
                <Controller
                  control={control}
                  name="content"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      ref={bodyInputRef}
                      variant="standard"
                      multiline
                      minRows={4}
                      maxRows={5}
                      onFocus={() => bodyInputRef.current.scrollIntoView({ behavior: 'smooth' })}
                      fullWidth
                      placeholder="I want to improve my mental health by meditating 3 times per week."
                      inputProps={{
                        maxLength: 200,
                        autoCapitalize: 'on',
                        inputMode: 'text',
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{ disableUnderline: true }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  bgcolor: '#fff',
                  p: 2,
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: '#2d2d2d',
                    fontSize: 16,
                    fontWeight: 500,
                    mb: 1.5,
                  }}
                >
                  Share With
                </Typography>
                <Controller
                  control={control}
                  name="visibility"
                  defaultValue="team"
                  rules={{ required: true }}
                  render={({ field }) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="team"
                        control={(
                          <Radio
                            sx={{
                              '&, &.Mui-checked': {
                                color: '#063D8F',
                              },
                            }}
                          />
                        )}
                        label={(
                          <Typography sx={{ color: '#2d2d2d' }}>
                            My Team
                          </Typography>
                        )}
                      />
                      <FormControlLabel
                        value="private"
                        control={(
                          <Radio
                            sx={{
                              '&, &.Mui-checked': {
                                color: '#063D8F',
                              },
                            }}
                          />
                        )}
                        label={(
                          <Typography sx={{ color: '#2d2d2d' }}>
                            Keep Private
                          </Typography>
                        )}
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
            </Stack>
            <IonButton
              size="large"
              expand="block"
              shape="round"
              className="goals-create-submit-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting || !isDirty || !isValid || !selectedGoalType}
            >
              Submit
            </IonButton>
          </Box>
        </form>
      </IonContent>
    </PageWithBackButton>
  );
};

GoalsCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default GoalsCreate;
