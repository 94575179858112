import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { Capacitor } from '@capacitor/core';

// eslint-disable-next-line no-unused-vars
import { Router } from 'react-router-dom'; // leaving here as Sentry docs recommend that it is in score
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { computeEnvironment } from './helpers/environment';

// Enable Sentry for native platforms on all environments
const environment = computeEnvironment();
const sentryEnabled = Capacitor.isNativePlatform();

// monitor/trace as much activity as possible in dev/staging, and scale back in production
const tracesSampleRate = environment !== 'production' ? 1.0 : 0.1;
const replaysSessionSampleRate = environment === 'development' ? 1.0 : 0.1;
const replaysOnErrorSampleRate = 1.0;

const history = createBrowserHistory();

const initializeSentry = () => {
  if (!sentryEnabled) {
    return;
  }

  Sentry.init({
    dsn: 'https://65f9101e857d6c08a7c8c2175bcdceea@o4505750486450176.ingest.sentry.io/4505755924889600',
    environment,
    integrations: [
      new SentryReact.Replay(),
      new SentryReact.BrowserTracing({
        routingInstrumentation: SentryReact.reactRouterV5Instrumentation(history),
      }),
    ],
    tracePropagationTargets: ['all'], // todo: possible target for refinement

    // Performance Monitoring
    tracesSampleRate,

    // Session Replay
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  }, SentryReact.init);
};

export { initializeSentry };
