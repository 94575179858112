export default (state, action) => {
  switch (action.type) {
    case 'tour/setIsOpen': {
      return {
        ...state,
        tour: {
          ...state.tour,
          isOpen: action.payload,
        },
      };
    }
    case 'user/setPostTargets': {
      return {
        ...state,
        user: {
          ...state.user,
          postTargets: action.payload,
        },
      };
    }
    case 'user/setMatchRun': {
      return {
        ...state,
        user: {
          ...state.user,
          matchRun: action.payload,
        },
      };
    }
    case 'app/setUnreadMessagesCount': {
      return {
        ...state,
        conversations: {
          ...state.conversations,
          unreadMessagesCount: action.payload,
        },
      };
    }
    case 'resetUserAppState': {
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    }
    case 'resetAppState': {
      return {
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
