import Linkify from 'linkify-react';
import React from 'react';
import appStrings from '../common/app_strings';
import { truncateText } from './transformers';
import { linkifyOpts, toTitleCase } from './textManipulation';

const { post: { managing }, comments } = appStrings;

export const displayErrorText = (error) => {
  switch (error?.message) {
    case 'Pdf file size too large':
      return managing.errors.exceedPdfSize;
    case 'User has exceeded total pdf storage':
      return managing.errors.exceedPdfMaxSum;
    default:
      return managing.errors.failedUpload;
  }
};

// eslint-disable-next-line default-param-last
export const displayAttachmentLabel = (loading, attachment = {}, truncate = false, width) => {
  if (!loading && !attachment?.type) {
    return null;
  }
  if (loading) {
    return managing.state.uploadingMedia;
  }
  if (attachment.type === 'pdf') {
    if (truncate) {
      return truncateText(attachment?.webviewPath?.name || attachment?.name, 30, width, 15);
    }
    return attachment?.webviewPath?.name || attachment?.name;
  }
  return managing.state.mediaSelected;
};

const getCommentText = (comm, isUppercase = false) => {
  let text = null;
  if (comm?.body || comm?.goal?.content) {
    return comm?.body || comm?.goal?.content;
  }
  if (comm?.attachments[0]?.s3_key) {
    const s3Key = comm.attachments[0].s3_key;
    if (s3Key.startsWith('images')) {
      text = comments.sharedPhoto;
    }
    if (s3Key.startsWith('videos')) {
      text = comments.sharedVideo;
    }
    if (s3Key.startsWith('pdfs')) {
      text = comments.sharedFile;
    }
  }
  if (isUppercase) {
    return `${toTitleCase(text)}:`;
  }
  return text;
};

// eslint-disable-next-line default-param-last
export const renderCommentBody = (comm, isTruncate = false, isUppercase) => {
  const text = getCommentText(comm, isUppercase);
  if (isTruncate) {
    return text;
  }
  return (
    <Linkify options={linkifyOpts}>
      {text}
    </Linkify>
  );
};
