import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import BackrsButton from 'common/atoms/backrsButton/BackrsButton';

const navControlStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
};

const mainButtonStyles = {
  minWidth: '231px',
  backgroundColor: '#03295F',
};

const NavControl = ({ mainButton }) => (
  <Box as="nav-control" style={navControlStyles}>
    <BackrsButton
      label={mainButton.label}
      onClick={() => mainButton.onClick()}
      buttonStylesOverride={mainButtonStyles}
      isDisabled={mainButton.isDisabled}
      isLoading={mainButton.isLoading}
      iconSrc={mainButton.iconSrc}
      iconStyles={{ fontSize: '25px', marginTop: '.45vh' }}
    />
  </Box>
);

const navButtonPropTypes = PropTypes.shape({
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
});

export const NavControlPropTypes = {
  mainButton: navButtonPropTypes.isRequired,
};

export const NavControlDefaultPropTypes = {
  mainButton: { label: 'Save & Continue', isDisabled: false },
};

NavControl.propTypes = NavControlPropTypes;

export default NavControl;
