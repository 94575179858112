import React from 'react';
import ChapteredMultiselectPage from 'pages/wholeme/molecules/chapteredMultiselectPage/ChapteredMultiselectPage';
import { healthInterestsData } from './healthInterestsData';

const bgOverlay = '/assets/profile/health/health-interests-overlay.svg';

const ProfileHealthInterestsPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <ChapteredMultiselectPage
    fields={healthInterestsData}
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Health Interests"
    subtitle="Proteges are always interested in the health areas you are working on, too!"
    categoryId="health"
    questionId="health_interests"
    dataFetcher={async () => (await wholeMeClient.fetchHealthInterestsState())?.health_interests || []}
    dataSaver={wholeMeClient.saveHealthInterestsState}
    backgroundOverlay={bgOverlay}
    showMoreEnabled
  />
);
export default ProfileHealthInterestsPage;
