import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';
import { familyData } from './familyData';

const ProfileRelationshipsIntakeFamilyPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={familyData}
    itemVariant="shortImageBelow"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Family"
    subtitle="Select any of the following that apply to you."
    categoryId="relationships"
    questionId="family"
    isTwoColumns
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchFamilyState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveFamilyState)}
    backgroundOverlay="/assets/profile/relationships/family-overlay.svg"
  />
);

export default ProfileRelationshipsIntakeFamilyPage;
