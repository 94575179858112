import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

const BackrBadge = ({ imageSrc, style }) => (
  <Avatar
    className="backr-indicator"
    src={imageSrc}
    style={style}
  />
);

BackrBadge.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

BackrBadge.defaultProps = {
  style: {},
};

export default BackrBadge;
