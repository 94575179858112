import React from 'react';
import Linkify from 'linkify-react';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import AuthorAvatar from './AuthorAvatar';
import AuthorName from './AuthorName';
import { linkifyOpts } from '../../../helpers/textManipulation';

const LeftAlignedMessage = ({ aliasAdminAsSystem, author, message }) => (
  <div className="chat-msg-wrapper">
    <AuthorAvatar author={author} aliasAdminAsSystem={aliasAdminAsSystem} />
    <Stack spacing={0.5}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <AuthorName author={author} aliasAdminAsSystem={aliasAdminAsSystem} />
        {false && <div className="convos-item-unread" />}
        <Typography
          sx={{
            position: 'relative',
            top: 1,
            color: false
              ? 'hsl(0 0% 18% / 0.5)'
              : 'hsl(0 0% 18%)',
            fontSize: 10,
            fontWeight: 600,
          }}
        >
          {moment(
            message.state.timestamp.toLocaleString(),
          ).fromNow()}
        </Typography>
      </Stack>
      <div className="talk-bubble bubble-white tri-right round left-top">
        <div className="chat-text">
          <Linkify tagName="p" options={linkifyOpts}>
            {message.state.body}
          </Linkify>
        </div>
      </div>
    </Stack>
  </div>
);

LeftAlignedMessage.propTypes = {
  aliasAdminAsSystem: PropTypes.bool,
  author: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  message: PropTypes.shape({
    state: PropTypes.shape({
      timestamp: PropTypes.instanceOf(Date),
      body: PropTypes.string,
    }),
  }).isRequired,
};

LeftAlignedMessage.defaultProps = {
  aliasAdminAsSystem: true,
};

export default LeftAlignedMessage;
