import React, { useState } from 'react';
import {
  IonPage, IonContent, IonHeader, IonToolbar,
} from '@ionic/react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import userServiceLogger from '../../../hooks/userServiceLogger';
import appStrings from '../../../common/app_strings';
import pdfPaths from '../../../common/pdf_paths';
import './Pdfs.css';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import PdfDocument from './PdfDocument';

const { checkrFormScreen } = appStrings;

const Disclosure = () => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { serviceLogger } = userServiceLogger();
  const disclosurePdf = pdfPaths('checkrDisclosure');
  const { platform } = useAppState();

  const { consents: currentConsents, formData, pdfs } = location?.state || {};
  const disclosureInfo = pdfs.find(
    (userConsent) => userConsent.consentFormName === 'Disclosure',
  );

  const handleAcceptedTerms = (event) => setHasAcceptedTerms(event.target.checked);

  const goToFeed = () => {
    history.push('/tabs/feed');
  };

  const handleSubmit = async () => {
    // todo: pass consent state when integrating
    const routeProps = {
      pdfs,
      consents: {
        ...currentConsents,
        disclosure: disclosureInfo?.userConsentFormId,
      },
    };

    await serviceLogger({
      key: 'checkr',
      header: 'Checkr Disclosure Screen',
      message: `User Accepted Checkr Disclosure PDF: ${JSON.stringify(routeProps)}`,
    });

    history.push('/checkr-agreement', { ...routeProps, formData });
  };

  const handleDocumentLoadError = async ({
    error,
  }) => {
    Sentry.captureException(error);
    await serviceLogger({
      key: 'checkr',
      type: 'error',
      header: 'Checkr Disclosure Consent Screen',
      error: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : null,
    });
  };

  const RenderDocument = (
    <PdfDocument
      pdfDocument={disclosurePdf}
      handleDocumentLoadError={handleDocumentLoadError}
      pageNumber={1}
    />
  );

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="" style={{ '--background': '#1CB7A4' }}>
          <Box
            sx={{
              bgcolor: '#1CB7A4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              pt: 3,
            }}
          >
            <img src="/assets/checkr-logo.png" alt="Checkr logo" />
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Stack
          sx={{
            bgcolor: '#fff',
            alignItems: 'center',
            pt: 2,
            pb: 4,
            px: 4,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          {platform?.isNativePlatform ? (
            <TransformWrapper>
              <TransformComponent>
                {RenderDocument}
              </TransformComponent>
            </TransformWrapper>
          ) : RenderDocument}
          <Stack alignItems="center" spacing={2} mt={-4} zIndex={10}>
            <FormControlLabel
              label={(
                <Typography
                  sx={{
                    color: '#9c9c9c',
                    textTransform: 'uppercase',
                    letterSpacing: 2,
                  }}
                >
                  {checkrFormScreen.disclosureLabel}
                </Typography>
              )}
              control={(
                <Checkbox
                  checked={hasAcceptedTerms}
                  onChange={handleAcceptedTerms}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <Button
              color="green"
              variant="contained"
              disableElevation
              disableRipple
              disabled={!hasAcceptedTerms}
              sx={{
                color: '#fff',
                fontWeight: 700,
                letterSpacing: 2,
                borderRadius: '100px',
                display: 'inline-block',
                mx: 'auto',
              }}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
        <Button
          disableRipple
          sx={{
            color: '#9c9c9c',
            display: 'block',
            textTransform: 'initial',
            mt: 1,
            mb: 5,
            mx: 'auto',
          }}
          onClick={goToFeed}
        >
          {checkrFormScreen.continueExploring}
        </Button>
      </IonContent>
    </IonPage>
  );
};

export default Disclosure;
