import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IonButton } from '@ionic/react';
import convertHtmlToReact from '@hedgedoc/html-to-react';

const buildCta = (callback, label) => (
  <IonButton
    color="primary"
    onClick={callback}
    style={{
      height: '56px',
      display: 'block',
      marginLeft: '10px',
      marginRight: '10px',
      '--border-radius': '3em',
      '--background-hover': '#063D8F',
      '--background-activated': '#063D8F',
      '--background-focused': '#063D8F',
    }}
    mode="ios"
  >
    <Typography
      sx={{
        fontSize: '20px',
        fontWeight: '600',
        padding: '35px 25px',
      }}
      mode="ios"
    >
      {label}
    </Typography>
  </IonButton>
);

const CardMolecule = ({
  media, text, spacing, topStyles, bottomStyles, containerStyles, cta,
}) => (
  <Stack
    spacing={spacing}
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 4,
      ...containerStyles,
    }}
  >
    <Box
      className="media-container"
      sx={{
        width: '100%',
        height: '55%',
        '@media screen and (min-height: 669px) and (max-height: 737px)': {
          position: 'absolute',
          marginTop: '5px',
          height: '40%',
          width: '85%',
          top: '5px',
        },
        '@media screen and (max-height: 668px)': {
          position: 'absolute',
          height: '40%',
          width: '85%',
          top: '2%',
          left: '8%',
        },
        '@media screen and (min-device-width: 1024px)': {
          maxWidth: '540px',
        },
        ...topStyles,
      }}
    >
      {media}
    </Box>
    <Box
      className="text-container"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '45%',
        '@media screen and (min-height: 730px) and (max-height: 737px)': {
          position: 'absolute',
          marginTop: '5px',
          height: '40%',
          width: '90%',
          bottom: '40px',
        },
        '@media screen and (max-height: 668px)': {
          position: 'absolute',
          height: '40%',
          width: '90%',
          bottom: '40px',
        },
        ...bottomStyles,
      }}
    >
      <Typography
        sx={{
          color: '#2d2d2d',
          maxWidth: '29ch',
          fontSize: 22,
          fontWeight: 500,
          lineHeight: 1.4,
          textAlign: 'center',
          '@media screen and (min-height: 730px) and (max-height: 737px)': {
            fontSize: 22,
          },
          '@media screen and (max-height: 668px)': {
            fontSize: 19,
          },
        }}
      >
        {convertHtmlToReact(text)}
      </Typography>
    </Box>
    {cta
    && (
    <Box
      className="cta-container"
      sx={{
        height: '56px',
        width: '70%',
        position: 'absolute',
        bottom: '45px',
        '@media screen and (max-height: 668px)': {
          bottom: '20px',
        },
        '@media screen and (min-device-width: 1024px)': {
          position: 'revert',
        },
      }}
    >
      {cta && buildCta(cta.callback, cta?.label)}
    </Box>
    )}
  </Stack>
);

export default CardMolecule;

CardMolecule.propTypes = {
  text: PropTypes.string,
  media: PropTypes.node,
  spacing: PropTypes.number,
  topStyles: PropTypes.oneOfType([PropTypes.object]),
  bottomStyles: PropTypes.oneOfType([PropTypes.object]),
  containerStyles: PropTypes.oneOfType([PropTypes.object]),
  cta: PropTypes.oneOfType([PropTypes.object]),
};

CardMolecule.defaultProps = {
  media: null,
  text: null,
  spacing: 3,
  topStyles: {},
  bottomStyles: {},
  containerStyles: {},
  cta: null,
};
