import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { financesXpData } from './financesXpData';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';

const ProfileFinancesIntakeXpPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={financesXpData}
    itemVariant="longImageBelow"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Financial Experiences"
    subtitle="Check any of the following that apply to you."
    categoryId="finances"
    questionId="finances_xp"
    isTwoColumns
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchFinancesXpState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveFinancesXpState)}
    backgroundOverlay="/assets/profile/finances/financial-experiences-overlay.svg"
  />
);

export default ProfileFinancesIntakeXpPage;
