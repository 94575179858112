import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  IonContent, IonPage, IonHeader,
} from '@ionic/react';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import { sessionSelector } from '../../redux/slices/session';
import GeneralTextCardAtom from '../../common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import BackrsButton from '../../common/atoms/backrsButton/BackrsButton';
import './newFeature.css';

const youreInBtnStyleOverride = {
  width: '100%',
  height: '57px',
  fontSize: '20px',
  fontWeight: 700,
  borderRadius: '40px',
  color: '#063D8F',
  backgroundColor: '#FFFFFF',
  border: 'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
};

const fixedButtonContOverride = {
  px: '24px',
  position: 'fixed',
  bottom: '1px',
  width: '90%',
  backgroundColor: '#063D8F',
  height: '110px',
};

const NewFeature = () => {
  const { post } = useFetch();
  const history = useHistory();
  const { oneTimeScreen } = useSelector(sessionSelector);

  const navigateToCta = () => {
    history.replace(oneTimeScreen?.cta?.to || '/tabs/feed');
  };

  const markAsSeen = async () => {
    const url = `users/one-time-screen/${oneTimeScreen?.id}`;
    try {
      await post(url);
    } catch (error) {
      console.error('NewFeature error: ', error);
    }
  };

  useEffect(async () => {
    await markAsSeen();
  }, []);
  return (
    <IonPage>
      <IonHeader className="ion-no-border" />
      <IonContent fullscreen className="newFeatureSplash">
        <Stack
          sx={{
            pt: 10,
            mb: 8,
            mt: 2,
            alignItems: 'center',
          }}
        >
          <Stack
            sx={{
              px: '24px',
              mb: '45px',
            }}
          >
            <GeneralTextCardAtom
              title={oneTimeScreen?.title}
              body={oneTimeScreen?.body}
              titleStyles={{
                textAlign: 'center',
                color: '#FFFFFF',
                fontSize: '28px',
                fontWeight: 700,
                paddingBottom: '2rem',
              }}
              bodyStyles={{
                fontSize: '20px',
                fontWeight: 400,
                textAlign: 'center',
                color: '#FFFFFF',
                opacity: 1,
              }}
            />
          </Stack>
          <Stack
            spacing={2.5}
            sx={{
              mb: '1px', textAlign: 'center', width: '20rem', height: '20rem', mt: '3rem',
            }}
          >
            {oneTimeScreen?.media?.media_type === 'images' && (
              <img src={oneTimeScreen.media.media_url} alt="new feature" />
            )}
          </Stack>
        </Stack>
        <Stack
          sx={fixedButtonContOverride}
        >
          <BackrsButton
            label={oneTimeScreen?.cta?.label}
            onClick={navigateToCta}
            buttonStylesOverride={youreInBtnStyleOverride}
          />
        </Stack>
      </IonContent>
    </IonPage>
  );
};

export default NewFeature;
