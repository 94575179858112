import React from 'react';
import IonicProviderPageShell from 'common/molecules/ionicProviderPageShell/IonicProviderPageShell';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import ProfileIntakePortrait from '../pages/portrait/ProfileIntakePortrait';
import { WholeMeClient } from '../util/wholeme_client';

const ProfileIntakePortaitRoute = () => {
  const history = useHistory();
  const onSubmitBack = () => {
    history.push('/');
  };
  const onSubmitFinal = () => {
    history.push('/');
  };

  return (
    <IonicProviderPageShell header={<Header />}>
      <ProfileIntakePortrait
        onSubmitBack={onSubmitBack}
        onSubmitFinal={onSubmitFinal}
        wholeMeClient={new WholeMeClient()}
      />
    </IonicProviderPageShell>
  );
};
export default ProfileIntakePortaitRoute;
