export const textAreaStyles = {
  '--placeholder-color': '#727272',
  fontSize: '14px',
  maxHeight: '340px',
  marginRight: '2.5rem',
  padding: '0 .25rem',
  overflowY: 'hidden',
  color: '#000',
  alignItems: 'center',
};

export const imgStyle = {
  borderRadius: '10px !important',
  padding: 0,
  margin: '1rem 0 0 0',
};

export const postBtnStyle = {
  width: '4.5rem',
  height: '1.875rem',
  fontSize: '13px',
  padding: '10px',
  color: '#fff',
  '&:disabled': {
    backgroundColor: '#C3C3B9',
    color: '#fff',
  },
};

export const iconContainer = {
  flexDirection: 'row',
  flexShrink: 0,
  gap: '1rem',
  paddingRight: '1rem',
};

export const mediaIcon = {
  '--inner-padding-end': 0,
  '&.inputWrapper': {
    justifyContent: 'center',
  },
};
