import React from 'react';
import {
  Avatar, Box, Grid, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import TeamDetailsBackrPair from './TeamDetailsBackrPair';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const TeamDetailsView = ({ protege, backrs }) => {
  const history = useHistory();
  const backrPairs = backrs.reduce((result, value, index, array) => {
    if (index % 2 === 0) { result.push(array.slice(index, index + 2)); }
    return result;
  }, []);
  const [firstPair, ...otherPairs] = backrPairs;

  const linkToProtege = (user) => {
    history.push(`/profile/${user.id}`);
  };

  return (
    <Box sx={{ width: 1, maxWidth: 375, mx: 'auto' }}>
      <Stack alignItems="center" mb={1.5} onClick={() => linkToProtege(protege)}>
        <Avatar
          src={protege?.profile_image}
          sx={{
            width: 108,
            height: 108,
            fontSize: 60,
          }}
        >
          <img src={defaultProfileImage} alt="profile" />
        </Avatar>
        <Box
          sx={{
            position: 'relative',
            top: -12,
            width: '100%',
            maxWidth: 108,
            background: '#2CB669',
            py: 0.35,
            borderRadius: 2,
            zIndex: 10,
          }}
        >
          <Stack spacing={-0.5}>
            <Typography
              align="center"
              sx={{ color: '#fff', fontSize: 13, fontWeight: 700 }}
            >
              {protege?.first_name && protege?.last_name
                ? `${protege?.first_name} ${protege?.last_name.charAt(0)}.`
                : 'Me'}
            </Typography>
            <Typography
              align="center"
              sx={{ color: '#fff', fontSize: 10, fontWeight: 700 }}
            >
              {protege?.role
                ? `${protege?.role
                  ?.charAt(0)
                  .toUpperCase()}${protege?.role?.slice(1)}`
                : null}
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Grid container>
        {firstPair ? (
          <>
            <TeamDetailsBackrPair protegeName={protege?.first_name} backrPair={firstPair} first />
            {otherPairs.length > 0
              ? otherPairs?.map((pair) => (
                <TeamDetailsBackrPair
                  key={
                      pair[0] ? `${pair[0].id}&${pair[1]?.id}` : `${pair.id}`
                    }
                  protegeName={protege?.first_name}
                  backrPair={pair}
                />
              ))
              : null}
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

TeamDetailsView.propTypes = {
  protege: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    profile_image: PropTypes.string,
  }),
  backrs: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      role: PropTypes.string,
      profile_image: PropTypes.string,
    }),
  ).isRequired,
};

TeamDetailsView.defaultProps = {
  protege: {
    first_name: '',
    last_name: '',
    role: '',
    profile_image: null,
  },
};

export default TeamDetailsView;
