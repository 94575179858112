import React from 'react';
import SimpleSingleSelectPage from 'pages/wholeme/molecules/simpleSingleSelectPage/SimpleSingleSelectPage';
import { degreeData } from './degreeData';

const ProfileEducationIntakeDegreePage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => {
  const dataFetcher = wholeMeClient.fetchDegreeState;
  const dataSaver = wholeMeClient.saveDegreeState;

  return (
    <SimpleSingleSelectPage
      fields={degreeData}
      paginationMeta={paginationMeta}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      title="Education Degree"
      subtitle="What’s the highest degree you have earned?"
      backgroundOverlay="/assets/profile/education/degree-overlay.svg"
      categoryId="education"
      questionId="degree"
      isDataNested
      dataFetcher={dataFetcher}
      dataSaver={dataSaver}
    />
  );
};

export default ProfileEducationIntakeDegreePage;
