/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import RoleIndicator from './RoleIndicator';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const AvatarRole = ({
  user,
  small,
  subComment,
  style,
  unsetTopMargin,
}) => (
  <div
    className="avatar-role-wrapper"
    style={style}
    role="button"
    tabIndex={0}
  >
    <Avatar
      src={user?.profile_image}
      sx={{
        fontFamily: "'Sarabun', arial, sans-serif",
        ...(small && { width: 30, height: 30 }),
        ...(subComment && { width: 25, height: 25 }),
        ...(subComment && { fontSize: '12px' }),
        mt: unsetTopMargin ? 0 : '16px',
      }}
    >
      <img
        src={defaultProfileImage}
        alt="profile"
        style={{
          ...(small && { width: 30, height: 30 }),
          ...(subComment && { width: 25, height: 25 }),
          ...(subComment && { fontSize: '12px' }),
          mt: unsetTopMargin ? 0 : '16px',
          width: 40,
          height: 40,
        }}
      />
    </Avatar>
    {
      user?.role && (
        <RoleIndicator
          userRole={user.role}
          style={{
            position: 'absolute',
            ...(small
              ? {
                bottom: '-4px', right: '-1px', width: 14, height: 14, fontSize: '0.5625rem',
              }
              : { bottom: '-2px', right: 0 }),
            ...(subComment
              ? {
                bottom: '-4px', right: '-1px', width: 12, height: 12, fontSize: '0.5625rem',
              }
              : { bottom: '-2px', right: 0 }),
          }}
        />
      )
    }
  </div>
);

AvatarRole.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profile_image: PropTypes.string,
    role: PropTypes.string,
  }),
  small: PropTypes.bool,
  subComment: PropTypes.bool,
  unsetTopMargin: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AvatarRole.defaultProps = {
  small: false,
  subComment: false,
  style: {},
  unsetTopMargin: false,
  user: null,
};

export default AvatarRole;
