// action creators

export const setTourIsOpen = (isOpen) => ({
  type: 'tour/setIsOpen',
  payload: isOpen,
});

export const setPostTargets = (postTargets) => ({
  type: 'user/setPostTargets',
  payload: postTargets,
});

export const resetState = (initialState) => ({
  type: 'resetAppState',
  payload: initialState,
});

export const resetUserState = (userState) => ({
  type: 'resetUserAppState',
  payload: userState,
});

export const setMatchRun = (matchRun) => ({
  type: 'user/setMatchRun',
  payload: matchRun,
});

export const setUnreadMessagesCount = (c) => ({
  type: 'app/setUnreadMessagesCount',
  payload: c,
});
