import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';

const ProfileInterestsIntakeSplashPage = ({ nextOnClick, prevOnClick }) => {
  const { platform } = useAppState();
  const splashImage = {
    src: './assets/profile/interests/interests-splash.svg',
    alt: 'hand holding mic',
    styles: {
      width: platform?.isNativePlatform ? '70%' : '55%',
    },
  };
  return (
    <ProfileIntakeSplashPage
      title="Interests"
      subtitle="The stuff relationships are made out of. Let’s help your team get to know you better."
      splashImage={splashImage}
      nextButton={{ label: "Let's Go", onClick: nextOnClick }}
      prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
      backgroundOverlay="/assets/profile/interests/interests-splash-overlay.svg"
      splashPictureAlignsBottom
    />
  );
};

export default ProfileInterestsIntakeSplashPage;
