import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  sharedStyles,
  variantStylesLookup,
  rectangleSubVariantStylesLookup,
  selectedStylesLookup,
  contentStyles,
} from './selectableItemStyles';

export const selectableItemVariants = [
  'oval',
  'rectangle',
  'shortImageBelow',
  'longImageBelow',
];

const SelectableItem = ({
  variant,
  rectangleSubVariant,
  label,
  imageSrc,
  imageAlt,
  value,
  onClick,
  itemStylesOverride,
  selectedStylesOverride,
  isSelected: initializedSelectedState,
  parentOverridesSelectedState,
  contentStylesOverride = {},
  imgStylesOverride = {},
}) => {
  const [isSelected, setIsSelected] = useState(!!initializedSelectedState);
  useEffect(() => {
    setIsSelected(!!initializedSelectedState);
  }, [initializedSelectedState]);

  const variantStyles = {
    ...sharedStyles,
    ...variantStylesLookup[variant],
    ...rectangleSubVariantStylesLookup[rectangleSubVariant],
    ...itemStylesOverride,
  };
  const selectedVariantStyles = {
    ...selectedStylesLookup[variant],
    ...selectedStylesOverride,
  };
  const styles = isSelected ? { ...variantStyles, ...selectedVariantStyles } : variantStyles;

  contentStyles.justifyContent = imageSrc ? 'space-between' : 'center';
  contentStyles.flexDirection = rectangleSubVariant ? 'column' : 'row';
  contentStyles.margin = rectangleSubVariant ? '' : '0 20px';

  const doesImageHaveLeftMargin = imageSrc && !rectangleSubVariant;
  const imageStyles = doesImageHaveLeftMargin ? { marginLeft: '10px' } : {};
  const labelTextAlign = !rectangleSubVariant && imageSrc ? 'left' : 'center';

  const handleClick = () => {
    if (!parentOverridesSelectedState) setIsSelected(!isSelected);
    onClick(value);
  };

  return (
    <Box as="selectable-tile" style={styles} onClick={() => handleClick()}>
      <Box sx={{ ...contentStyles, ...contentStylesOverride }}>
        <Box as="label" sx={{ textAlign: labelTextAlign }}>
          {label}
        </Box>
        {imageSrc && <img src={imageSrc} alt={imageAlt} style={{ ...imageStyles, ...imgStylesOverride }} />}
      </Box>
    </Box>
  );
};

export const SelectableItemPropTypes = {
  variant: PropTypes.string,
  rectangleSubVariant: PropTypes.string,
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  itemStylesOverride: PropTypes.oneOfType([PropTypes.object]),
  selectedStylesOverride: PropTypes.oneOfType([PropTypes.object]),
  isSelected: PropTypes.bool,
};

export const SelectableItemDefaultProps = {
  variant: '',
  rectangleSubVariant: '',
  label: '',
  imageSrc: '',
  imageAlt: '',
  itemStylesOverride: {},
  selectedStylesOverride: {},
  isSelected: false,
};

SelectableItem.propTypes = SelectableItemPropTypes;
SelectableItem.defaultProps = SelectableItemDefaultProps;

export default SelectableItem;
