// TODO: PRUNE: NO LONGER IN USE
import { React, useState, useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useAuthContext } from 'providers/auth-provider';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Carousel from 'common/atoms/carousel/Carousel';
import HeaderAtom from 'common/atoms/header/HeaderAtom';
import useFetch, { CachePolicies } from 'use-http';
import { buildWhatIsBackingCards } from './utils';
import './whatIsBacking.css';

const WhatIsBackrs = () => {
  const { user } = useAuthContext();
  const [workflow, setWorkflow] = useState(null);
  const history = useHistory();
  const {
    get, response, error, abort,
  } = useFetch(
    '/workflow-meta/what-is-backing',
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
  );

  const fetchWorkflowMeta = async () => {
    const data = await get();
    if (response.ok) setWorkflow(data);
    else console.log(error.message);
  };

  useEffect(() => {
    fetchWorkflowMeta();
    return () => {
      abort();
    };
  }, []);

  const cards = workflow ? buildWhatIsBackingCards(workflow) : [];
  console.log('workflow: ', workflow);
  console.log('cards: ', cards);
  const paginationOffset = user ? '130px' : '40px';

  const goToAccountCreation = () => {
    history.push('/register/register/backr');
  };

  return (
    <IonPage>
      {user ? (
        <Header />
      ) : (
        <HeaderAtom
          backButtonLabel="Learn"
          actionLabel="Start Backing"
          actionCallback={goToAccountCreation}
        />
      )}
      <IonContent>
        <Box
          sx={{
            width: 1,
            height: 1,
            minHeight: `calc(100vh - ${paginationOffset} - var(--ion-safe-area-top))`,
            pb: 6,
          }}
          className={clsx(
            'what-is-backing-wrapper',
            user && 'what-is-backing-wrapper-alt',
          )}
        >
          <Carousel
            slides={cards}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 2,
              hideLast: true,
            }}
          />
        </Box>
      </IonContent>
      {user && <Footer />}
    </IonPage>
  );
};

export default WhatIsBackrs;
