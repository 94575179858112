import React from 'react';
import PropTypes from 'prop-types';

const dotsStyle = {
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'fit-content',
  gap: '10px',
  marginTop: '20px',
};

const ProgressDots = ({ totalNumber, activeNumber }) => (
  <div style={dotsStyle}>
    {[...Array(totalNumber).keys()].map((dot) => {
      const isActiveDot = dot === activeNumber;
      return (
        <div
          key={dot}
          style={{
            borderRadius: '50%',
            width: isActiveDot ? '19px' : '11px',
            height: isActiveDot ? '19px' : '11px',
            backgroundColor: '#F7AE41',
          }}
        />
      );
    })}
  </div>
);

export const ProgressDotsPropTypes = {
  totalNumber: PropTypes.number.isRequired,
  activeNumber: PropTypes.number.isRequired,
};

ProgressDots.propTypes = ProgressDotsPropTypes;

export default ProgressDots;
