import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import HyperLinkText from '../../../../common/atoms/hyperLinkText/HyperLinkText';
import { ProfileSponsorshipRenderStyles } from './ProfileSponsorshipRenderStyles';

const { containerStyle, textStyle, imageStyle } = ProfileSponsorshipRenderStyles;

const ProfileSponsorshipRender = ({
  meta, textStyleOverride, containerStyleOverride, imageStyleOverride,
}) => (
  <>
    {meta.map((sponsor) => (
      <Stack
        key={sponsor.name}
        direction="row"
        spacing={1}
        sx={{ ...containerStyle, ...containerStyleOverride }}
      >
        {sponsor.icon && (<img src={sponsor.icon} alt="icon" style={{ ...imageStyle, ...imageStyleOverride }} />)}
        <HyperLinkText
          text={sponsor.name}
          url={sponsor.target_url}
          styleOverride={{ ...textStyle, ...textStyleOverride }}
        />
      </Stack>
    ))}
  </>
);

ProfileSponsorshipRender.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    target_url: PropTypes.string,
    icon: PropTypes.string,
  })),
  textStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  imageStyleOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProfileSponsorshipRender.defaultProps = {
  meta: [],
  textStyleOverride: {},
  containerStyleOverride: {},
  imageStyleOverride: {},
};

export default ProfileSponsorshipRender;
