/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';
import * as linkify from 'linkifyjs';
import { linkifyOpts } from '../helpers/textManipulation';

const chunkSentence = (str, lineLength) => {
  const arr = [''];

  str.split(' ').forEach((word) => {
    if (arr[arr.length - 1].length + word.length > lineLength) arr.push('');
    arr[arr.length - 1] += `${word} `;
  });

  return arr.map((v) => v.trim());
};

const TruncateText = ({ text, maxChars, handleNavigation }) => {
  const [clamped, setClamped] = useState(true);

  const switchClampedState = (e) => {
    e.stopPropagation();
    setClamped(!clamped);
  };

  if (text.length <= maxChars) {
    return (
      <Linkify options={linkifyOpts}>
        <span role="button" tabIndex={0} onClick={handleNavigation}>
          {text}
        </span>
      </Linkify>
    );
  }

  const truncatedText = chunkSentence(text, maxChars);

  if (!truncatedText[0] && truncatedText[1] && linkify.test(truncatedText[1])) {
    return (
      <ReadMoreHandler
        truncatedText={text}
        wholeText={text}
        clampedState={clamped}
        handleClampedState={switchClampedState}
        truncatedLinkifyOpts={{ ...linkifyOpts, truncate: maxChars - 10 }}
        removeEllipsis
        handleNavigation={handleNavigation}
      />
    );
  }
  if (!truncatedText[0] && truncatedText[1]) {
    return (
      <ReadMoreHandler
        truncatedText={text.substr(0, maxChars)}
        wholeText={text}
        clampedState={clamped}
        handleClampedState={switchClampedState}
        handleNavigation={handleNavigation}
      />
    );
  }

  return (
    <ReadMoreHandler
      truncatedText={truncatedText[0]}
      wholeText={text}
      clampedState={clamped}
      handleClampedState={switchClampedState}
      handleNavigation={handleNavigation}
    />
  );
};

const ReadMoreHandler = ({
  truncatedText,
  wholeText,
  clampedState,
  handleClampedState,
  truncatedLinkifyOpts,
  removeEllipsis,
  handleNavigation,
}) => (clampedState ? (
  <Linkify options={truncatedLinkifyOpts}>
    <span onClick={handleNavigation} role="button" tabIndex={0}>
      {`${truncatedText}${!removeEllipsis ? '...' : ''} `}
    </span>
    <ButtonBase
      sx={{
        color: '#063D8F',
        fontSize: 13,
        fontWeight: 700,
        fontFamily: 'inherit',
        verticalAlign: 'baseline',
      }}
      disableRipple
      onClick={handleClampedState}
    >
      Read more
    </ButtonBase>
  </Linkify>
) : (
  <Linkify options={linkifyOpts}>
    <span
      onClick={handleNavigation}
      role="button"
      tabIndex={0}
    >
      {`${wholeText} `}
    </span>
    <ButtonBase
      sx={{
        display: 'block',
        color: '#063D8F',
        fontSize: 13,
        fontWeight: 700,
        fontFamily: 'inherit',
      }}
      disableRipple
      onClick={handleClampedState}
    >
      Show less
    </ButtonBase>
  </Linkify>
));

TruncateText.propTypes = {
  text: PropTypes.string.isRequired,
  maxChars: PropTypes.number,
  handleNavigation: PropTypes.func,
};

TruncateText.defaultProps = {
  maxChars: Infinity,
  handleNavigation: null,
};

ReadMoreHandler.propTypes = {
  truncatedText: PropTypes.string.isRequired,
  wholeText: PropTypes.string.isRequired,
  clampedState: PropTypes.bool,
  handleClampedState: PropTypes.func,
  truncatedLinkifyOpts: PropTypes.shape({
    target: PropTypes.string,
    truncate: PropTypes.number,
  }),
  removeEllipsis: PropTypes.bool,
  handleNavigation: PropTypes.func,
};

ReadMoreHandler.defaultProps = {
  clampedState: true,
  handleClampedState: () => {},
  truncatedLinkifyOpts: linkifyOpts,
  removeEllipsis: false,
  handleNavigation: null,
};

export default TruncateText;
