import React from 'react';
import { IonRouterOutlet, IonLoading } from '@ionic/react';
import {
  Route, Redirect, Switch, useHistory,
} from 'react-router-dom';
import ErrorScreen from 'pages/ErrorScreen';
import BootstrapLoadingScreen from 'pages/bootstrap/BootstrapLoadingScreen';
import UpdateUserApp from '../pages/version/UpdateUserApp';
import { registrationRoutes } from '../routes/registrationRoutes';
import { useBootStrap, BOOTSTRAP_ERROR_STATES } from '../hooks/useBootstrap';

const errorComponents = {
  [BOOTSTRAP_ERROR_STATES.VERSION_ERROR]: ErrorScreen,
  [BOOTSTRAP_ERROR_STATES.VERSION_INCOMPATIBLE]: UpdateUserApp,
  default: ErrorScreen,
};

const AuthShell = ({ children }) => {
  const {
    isBootstrapped,
    isLoading,
    error,
    landingRoute,
    isBootstrapLoading,
    retryBootstrap,
  } = useBootStrap();
  const history = useHistory();

  React.useEffect(() => {
    if (landingRoute !== '/register/login') {
      history.replace(landingRoute);
    }
  }, [landingRoute]);

  if (error) {
    const ErrorComponent = errorComponents[error] || errorComponents.default;
    console.log('AuthShell error:::', error);
    return <ErrorComponent onLogin={retryBootstrap} />;
  }

  const authStack = (
    <>
      <IonLoading isOpen={isLoading} message="Loading..." cssClass="ion-loading-custom" />
      <IonRouterOutlet>
        <Switch>
          {registrationRoutes.map((route) => (
            <Route exact path={route.path} component={route.component} key={route.path} />
          ))}
          <Redirect to="/register/login" />
        </Switch>
      </IonRouterOutlet>
    </>
  );

  const renderContent = () => {
    if (isBootstrapped) {
      return children;
    }

    if (isBootstrapLoading) {
      return <BootstrapLoadingScreen />;
    }

    return authStack;
  };

  return renderContent();
};

export default AuthShell;
