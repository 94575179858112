import React from 'react';
import PropTypes from 'prop-types';
import ProfileIntakePage from '../profileIntakePage/ProfileIntakePage';
import SplashPageNavControl from '../splashPageNavControl/SplashPageNavControl';
import MainImage from '../mainImage/MainImage';

const ProfileIntakeSplashPage = ({
  title,
  subtitle,
  backgroundOverlay,
  splashImage,
  nextButton,
  prevButton,
  splashPictureAlignsBottom,
}) => (
  <ProfileIntakePage
    variant="splash"
    backgroundColor="#F7AE41"
    backgroundOverlay={backgroundOverlay}
    title={title}
    subtitle={subtitle}
    isTitleTextWhite
    NavComponent={<SplashPageNavControl nextButton={nextButton} prevButton={prevButton} />}
    splashPictureAlignsBottom={splashPictureAlignsBottom}
  >
    <MainImage
      imageSrc={splashImage?.src}
      imageAlt={splashImage?.alt}
      imageStylesOverride={splashImage?.styles}
    />
  </ProfileIntakePage>
);

export const ProfileIntakeSplashPagePropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundOverlay: PropTypes.string,
  splashImage: PropTypes.shape(
    {
      src: PropTypes.string,
      alt: PropTypes.string,
      styles: PropTypes.oneOfType([PropTypes.object]),
    },
  ),
  nextButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  prevButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
};

export const ProfileIntakeSplashPageDefaultProps = {
  title: '',
  subtitle: '',
  backgroundOverlay: '',
  splashImage: {
    src: '',
    alt: '',
    styles: {},
  },
  nextButton: { label: '', onClick: () => {} },
  prevButton: { label: '', onClick: () => {} },
};

ProfileIntakeSplashPage.propTypes = ProfileIntakeSplashPagePropTypes;
ProfileIntakeSplashPage.defaultProps = ProfileIntakeSplashPageDefaultProps;

export default ProfileIntakeSplashPage;
