import React from 'react';
import { IonContent } from '@ionic/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import PageWithBackButton from '../generic/PageWithBackButton';

const MainLogoLayout = ({ backButtonText, children, showLogInButton }) => (
  <PageWithBackButton
    backButtonText={backButtonText}
    showLogInButton={showLogInButton}
  >
    <IonContent fullscreen>
      <Box mt={0.25} mb={3.5}>
        <img
          style={{ width: '100%', objectFit: 'contain', maxWidth: 74 }}
          className="centered"
          src="/assets/logo_circle.png"
          alt="Backrs Logo"
        />
      </Box>
      {children}
    </IonContent>
  </PageWithBackButton>
);

MainLogoLayout.propTypes = {
  backButtonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showLogInButton: PropTypes.bool,
};

MainLogoLayout.defaultProps = {
  backButtonText: '',
  showLogInButton: false,
};

export default MainLogoLayout;
