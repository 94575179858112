const getMillisecondsFactor = (unit) => {
  switch (unit) {
    case 'seconds': return 1000;
    case 'minutes': return 60000;

    default: return 1;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const convertToMills = (number, unit = 'seconds') => number * getMillisecondsFactor(unit);
