import { Directus } from '@directus/sdk';

export const DirectusApi = new Directus(process.env.REACT_APP_DIRECTUS_BASEURL, {
  auth: {
    staticToken: process.env.REACT_APP_DIRECTUS_KEY,
  },
});

export const apiBase = process.env.REACT_APP_API_BASEURL;

const usersRoot = '/users';
export const users = `${apiBase}${usersRoot}`;
export const usersDetail = `${apiBase}${usersRoot}/:id`;
export const usersCurrent = `${apiBase}${usersRoot}/current-user`;
export const usersCurrentBackgroundCheck = `${apiBase}${usersRoot}/current-user/background-check`;

const postsRoot = '/posts';
export const postsRequest = `${apiBase}${postsRoot}`;
export const postsDetailRequest = `${apiBase}${postsRoot}/:id`;

const likesRoot = '/likes';
export const likesRequest = `${apiBase}${likesRoot}`;

const commentsRoot = '/comments';
export const commentsRequest = `${apiBase}${commentsRoot}`;

const relationshipsRoot = '/relationships';
export const relationshipsRequest = `${apiBase}${relationshipsRoot}`;

const notificationsRoot = '/notifications';
export const notificationsRequest = `${apiBase}${notificationsRoot}`;
