/* eslint-disable import/prefer-default-export */

/**
 * The values for these options are directly inform mappings that drive the
 * automated grouping of users by their Whole Me profile selections.
 *
 * Changes made to the values of these options will require corresponding changes in the listed files, and potentially others.
 * See:
 *  - backrs_api/src/services/eventBridge/eventHandlers/eventHelpers/profileEvents/groups/maps.js
 */
export const affinityData = [
  {
    label: 'First-gen college student',
    value: 'first_gen_college',
  },
  {
    label: 'Disability',
    value: 'disability',
  },
  {
    label: 'Immigrant',
    value: 'immigrant',
  },
  {
    label: 'LGBTQ+',
    value: 'lgbtq',
  },
  {
    label: 'College Athletics',
    value: 'college_athletics',
  },
  {
    label: 'Military',
    value: 'military',
  },
  {
    label: 'Rural',
    value: 'rural',
  },
  {
    label: 'Adopted/Foster',
    value: 'adopted_foster',
  },
];
