import React from 'react';
import { Controller } from 'react-hook-form';
import { IonLabel } from '@ionic/react';
import PropTypes from 'prop-types';
import { LightTextField, TaupeTextField } from './themes';

const StyledTextarea = ({
  control,
  error,
  minRows,
  maxRows,
  name,
  placeholder,
  position,
  required,
  theme,
  title,
  defaultValue,
  InputProps,
}) => (
  <div>
    {title && (
      <IonLabel style={{ color: '#2d2d2d', lineHeight: 1.25 }} position={position}>
        {title}
      </IonLabel>
    )}
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field }) => (theme === 'light' ? (
        <LightTextField
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          placeholder={placeholder}
          error={!!error?.message}
          helperText={error?.message}
          InputProps={{
            inputProps: { autoCapitalize: 'on', inputMode: 'text' },
            ...InputProps,
          }}
          type="text"
          multiline
          fullWidth
          minRows={minRows}
          maxRows={maxRows}
        />
      ) : (
        <TaupeTextField
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          placeholder={placeholder}
          error={!!error?.message}
          helperText={error?.message}
          InputProps={{
            inputProps: { autoCapitalize: 'on', inputMode: 'text' },
            ...InputProps,
          }}
          type="text"
          multiline
          fullWidth
          minRows={minRows}
          maxRows={maxRows}
        />
      ))}
    />
  </div>
);

StyledTextarea.propTypes = {
  control: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  position: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.string,
  title: PropTypes.string,
  InputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

StyledTextarea.defaultProps = {
  control: null,
  defaultValue: '',
  error: null,
  minRows: 2,
  maxRows: 4,
  placeholder: null,
  position: 'stacked',
  required: false,
  theme: 'taupe',
  title: null,
  InputProps: null,
};

export default StyledTextarea;
