/* eslint-disable import/prefer-default-export */
export const stageData = [
  {
    label: 'Student',
    value: 'student',
  },
  {
    label: 'Early Career',
    value: 'early-career',
  },
  {
    label: 'Mid Career',
    value: 'mid-career',
  },
  {
    label: 'Late Career',
    value: 'late-career',
  },
  {
    label: 'Post Career',
    value: 'post-career',
  },
];
