import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@mui/material';
import GeneralTextCardAtom from './generalTextCardAtom/GeneralTextCardAtom';
import './WhatIsBacking.css';

const buildCards = (meta, showIcons, isAbout) => meta.linedGroup.map((metaData, index) => (
  <Stack
    key={metaData.title}
    sx={{
      paddingLeft: '30px',
      borderLeft: !(index === meta.linedGroup.length - 1) && 'solid #03295F 1px',
      marginLeft: '-30px',
    }}
  >
    {showIcons && <img src={metaData.icon} alt="icon" className={metaData.iconClass} />}
    <Box
      sx={{
        marginBottom: '45px',
        marginTop: '-5px',
      }}
    >
      <GeneralTextCardAtom
        title={metaData.title}
        body={metaData.body}
        titleStyles={{
          color: '#03295F',
          fontSize: '20px',
          fontWeight: isAbout && 700,
        }}
        bodyStyles={{
          fontSize: '16px',
          color: '#2D2D2D',
          opacity: 1,
          fontWeight: isAbout && 500,
        }}
      />
    </Box>
  </Stack>
));
const WhatIsBackingList = ({
  meta,
  boxStyle,
  showIcons,
  isAbout,
}) => (
  <>
    {meta?.titleBox && (
      <Box>
        <Typography
          sx={{
            color: '#03295F',
            fontWeight: 700,
            fontSize: '22px',
            textAlign: 'center',
          }}
        >
          {meta?.titleBox.title}
        </Typography>
      </Box>
    )}
    <Box
      sx={{
        ...(showIcons && { paddingLeft: '30px' }),
        ...(showIcons && { marginLeft: '30px' }),
        height: 'auto',
        ...boxStyle,
      }}
    >
      {buildCards(meta, showIcons, isAbout)}
    </Box>
    {meta?.lastBox && (
      <Box
        sx={{
          paddingLeft: '30px',
          marginLeft: '30px',
          marginTop: '-8px',
        }}
      >
        <img src={meta.lastBox.icon} alt="icon" className={meta.lastBox.iconClass} />
        <Box>
          <GeneralTextCardAtom
            title={meta.lastBox.title}
            body={meta.lastBox.body}
            titleStyles={{
              color: '#03295F',
              fontSize: 20,
            }}
            bodyStyles={{
              fontSize: 16,
              color: '#2D2D2D',
              opacity: 1,
            }}
          />
        </Box>
      </Box>
    )}
  </>
);

WhatIsBackingList.propTypes = {
  meta: PropTypes.shape({
    linedGroup: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          body: PropTypes.string,
          icon: PropTypes.string,
          iconClass: PropTypes.string,
        }),
      ),
    ]),
    lastBox: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      icon: PropTypes.string,
      iconClass: PropTypes.string,
    }),
    titleBox: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  boxStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showIcons: PropTypes.bool,
  isAbout: PropTypes.bool,
};

WhatIsBackingList.defaultProps = {
  meta: null,
  boxStyle: {},
  showIcons: false,
  isAbout: false,
};

export default WhatIsBackingList;
