import React from 'react';
import PropTypes from 'prop-types';
import PageShell from 'common/molecules/pageShell/PageShell';
import GeneralTextCardAtom from 'common/atoms/generalTextCardAtom/GeneralTextCardAtom';

const padding = 20;
const viewportHeight = window.innerHeight;
const specifedSmallScreenHeight = 568; // specified smaller screens, current: iphone SE
const specifedLargeScreenHeight = 854; // specified larger screens, current: Galaxy s20+

const pageContainerStyle = {
  height: `calc(100vh - ${padding * 2}px)`,
  display: 'flex',
  flexDirection: 'column',
  padding: `${padding}px`,
  'padding-top': '45%',
  ...(viewportHeight === specifedSmallScreenHeight && {
    'padding-top': '35%',
  }),
  ...(viewportHeight === specifedLargeScreenHeight && {
    'padding-top': '40%',
  }),
};

const childrenStyles = {
  display: 'flex',
  flexDirection: 'column',
  padding: '1.2rem 0 1.3rem 0',
};

const SplashLoadingContent = ({
  backgroundColor,
  backgroundOverlay,
  title,
  children,
  titleStylesOverride,
}) => {
  const backgroundImage = backgroundOverlay ? `url(${backgroundOverlay})` : '';

  const renderContent = (
    <>
      <GeneralTextCardAtom
        title={title}
        titleStyles={titleStylesOverride}
      />
      <div style={{ ...childrenStyles }}>{children}</div>
    </>
  );
  return (
    <PageShell styles={{ backgroundColor, backgroundImage }}>
      <div style={pageContainerStyle}>
        {renderContent}
      </div>
    </PageShell>
  );
};

export const SplashLoadingContentPropTypes = {
  backgroundColor: PropTypes.string.isRequired,
  backgroundOverlay: PropTypes.string,
  title: PropTypes.string,
  titleStylesOverride: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node.isRequired,
};

export const SplashLoadingContentDefaultProps = {
  backgroundOverlay: '',
  title: '',
  titleStylesOverride: {},
};

SplashLoadingContent.propTypes = SplashLoadingContentPropTypes;
SplashLoadingContent.defaultProps = SplashLoadingContentDefaultProps;

export default SplashLoadingContent;
