/* eslint-disable camelcase */
import { createEditHandler } from 'pages/wholeme/util/profile_util';
import React from 'react';
import { firstValueOf } from '../../util/profile_util';
import WholeMeProfileQuotationTemplate from './WholeMeProfileQuotationTemplate';

const WholeMeProfileQuotation = ({ profile = {}, onEdit, onPost }) => {
  const { excited } = profile;
  const excitedLabel = firstValueOf(excited);
  return (
    <WholeMeProfileQuotationTemplate
      label={excitedLabel}
      postHandler={onPost ? () => onPost(excitedLabel) : undefined}
      editHandler={onEdit ? createEditHandler(onEdit, 'identity', 'introduce') : undefined}
    />
  );
};

export default WholeMeProfileQuotation;
