import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const GeneralTextCardAtom = ({
  title,
  body,
  showQuoteIcon,
  titleStyles,
  bodyStyles,
  quoteColor,
}) => {
  if (!title && !body) return null;
  return (
    <Stack>
      {title && (
        <Typography
          sx={{
            color: '#2d2d2d',
            fontWeight: 600,
            fontSize: 16,
            textAlign: 'left',
            mb: '5px',
            ...titleStyles,
          }}
        >
          {title}
        </Typography>
      )}
      {showQuoteIcon && (
        <Typography
          component="span"
          sx={{
            color: quoteColor,
            fontSize: 48,
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: '-20px',
            marginTop: '.5px',
          }}
        >
          “
        </Typography>
      )}
      {body && (
        <Typography
          sx={{
            color: '#2D2D2D',
            opacity: '.6',
            fontWeight: 400,
            textAlign: 'left',
            fontSize: 16,
            lineHeight: 1.3125,
            ...bodyStyles,
          }}
        >
          {body}
        </Typography>
      )}
    </Stack>
  );
};

export const GeneralTextCardAtomPropTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showQuoteIcon: PropTypes.bool,
  quoteColor: PropTypes.string,
  titleStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bodyStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const GeneralTextCardAtomDefaultProps = {
  title: '',
  body: '',
  showQuoteIcon: false,
  quoteColor: '#2CB669',
  titleStyles: null,
  bodyStyles: null,
};

GeneralTextCardAtom.propTypes = GeneralTextCardAtomPropTypes;
GeneralTextCardAtom.defaultProps = GeneralTextCardAtomDefaultProps;
export default GeneralTextCardAtom;
