import React, { useEffect, useState } from 'react';
import {
  IonContent, IonPage, IonToolbar, IonHeader,
} from '@ionic/react';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as Sentry from '@sentry/capacitor';
import { ValidationError } from '../../helpers/errors';
import appStrings from '../../common/app_strings';
import CheckrForm from '../../common/molecules/CheckrForm';
import { useAuthContext } from '../../providers/auth-provider';
import useServiceLogger from '../../hooks/userServiceLogger';
import isUserUnderage from '../../helpers/isUnderage';
import AgeRestrictionModal from '../../components/AgeRestrictionModal';

const schema = yup
  .object({
    first_name: yup.string().required('First name is required'),
    middle_name: yup.string(),
    last_name: yup.string().required('Last name is required'),
    birthday: yup.string().required('Birthday is required'),
    zip_code: yup.string().required('Zip code is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    ssn: yup
      .string()
      .min(9, 'Social Security Number must be 9 digits')
      .required('Social Security Number is required'),
  })
  .required();

const { checkrFormScreen: checkrForm } = appStrings;

const CheckrBGCheck = () => {
  const { user } = useAuthContext();
  const [hasMiddleName, setHasMiddleName] = useState(false);
  const [hasNoMiddleNameBoxChecked, setHasNoMiddleNameBoxChecked] = useState(false);
  const [isAgeRestricted, setIsAgeRestricted] = useState(false);

  const history = useHistory();
  const {
    control,
    handleSubmit,
    setError,
    formState: {
      errors,
      isValid,
    },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user?.first_name || '',
      middle_name: '',
      last_name: user?.last_name || '',
      birthday: user?.birthday || '',
      zip_code: user?.zip_code || '',
      city: user?.city || '',
      state: user?.state || '',
      ssn: '',
    },
  });

  const { put, response } = useFetch('/users/current-user/start-report');
  const { serviceLogger } = useServiceLogger();

  const goToFeed = () => {
    history.push('/tabs/feed');
  };

  const middleName = useWatch({
    control,
    name: 'middle_name',
  });

  const handleMiddleNameCheckChange = (event) => {
    setHasNoMiddleNameBoxChecked(event.target.checked);
    setHasMiddleName(!!hasNoMiddleNameBoxChecked);
  };

  useEffect(() => {
    setHasMiddleName(hasNoMiddleNameBoxChecked || !!middleName);
  }, [middleName, hasNoMiddleNameBoxChecked]);

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      no_middle_name: hasNoMiddleNameBoxChecked,
    };
    try {
      const isUnderage = isUserUnderage(data?.birthday);
      if (isUnderage) {
        setIsAgeRestricted(true);
        return;
      }
    } catch (error) {
      console.error('Error: ', error);
      toast.error('Background Check Unavailable: Message Coach Lubav or try again later', { id: 'error' });
      return;
    }

    try {
      const res = await put(newData);
      if (response.ok) {
        const routeProps = { pdfs: res };
        await serviceLogger({
          key: 'checkr',
          header: 'Checkr Consent Forms Flow: STARTED',
          message: `Users Checkr Form Submission Response: ${JSON.stringify(routeProps)}`,
        });

        history.push('/checkr-fair-credit', { ...routeProps, formData: newData });
      }
      if (!response.ok) {
        if (response.data.errors) {
          throw new ValidationError('Errors:', response.data.errors);
        } else {
          toast.error('Unknown error. Please try again later.', {
            id: 'checkr-error',
          });
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      }
    }
  };

  const disabledSubmit = (!isValid || !hasMiddleName);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="" style={{ '--background': '#1CB7A4' }}>
          <Box
            sx={{
              bgcolor: '#1CB7A4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              pt: 3,
            }}
          >
            <img src="/assets/checkr-logo.png" alt="Checkr logo" />
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isAgeRestricted && <AgeRestrictionModal />}
        <Stack
          spacing={2.5}
          sx={{
            bgcolor: '#fff',
            alignItems: 'center',
            py: 4,
            px: 4,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Typography sx={{ color: '#2d2d2d' }}>{checkrForm.body}</Typography>
          <CheckrForm
            control={control}
            errors={errors}
            showCheckBox={!middleName}
            handleMiddleNameCheckChange={handleMiddleNameCheckChange}
            checked={hasNoMiddleNameBoxChecked}
          />
          <Button
            color="green"
            variant="contained"
            disableElevation
            disableRipple
            disabled={disabledSubmit}
            sx={{
              color: '#fff',
              fontWeight: 700,
              letterSpacing: 2,
              borderRadius: '100px',
              display: 'inline-block',
              mx: 'auto',
            }}
            onClick={handleSubmit(onSubmit)}
          >
            {checkrForm.submit}
          </Button>
        </Stack>
        <Button
          disableRipple
          sx={{
            color: '#9c9c9c',
            display: 'block',
            textTransform: 'initial',
            mt: 1,
            mb: 5,
            mx: 'auto',
          }}
          onClick={goToFeed}
        >
          {checkrForm.continueExploring}
        </Button>
      </IonContent>
    </IonPage>
  );
};

export default CheckrBGCheck;
