import { patchData, getDetail } from 'helpers/requests';
import { ProfileMock } from './profile_mock';
import { CoachLubavProfileData } from './coachLubav_Profile';
import { firstOf, defaultIdentity, preferNot } from './profile_util';
import { greekData } from './greekData';

const profileBaseUrl = '/profile';

// todo this needs to be dried with the list in the UI!
const affinityFieldIds = [
  'first_gen_college',
  'immigrant',
  'military',
  'disability',
  'lgbtq',
  'rural',
  'college_athletics',
  'adopted_foster',
];

export class WholeMeClient {
  mock;

  admin;

  constructor(props) {
    this.mock = props?.mock;
    this.admin = props?.admin;
  }

  photoState = {};

  mockState = {
    zip_code: {
      zip_code: [],
    },
    birthday: {
      birthday: [],
    },
    gender: {
      gender: [],
    },
    ethnicity: {
      ethnicity: [],
    },
    affinity: {
      affinity: [],
    },
    excited: {
      excited: [],
    },
    religion: {
      religion: [],
    },
    quiz: {
      quiz: [],
    },
    hobbies: {
      hobbies: [],
    },
    clubs: {
      clubs: [],
    },
    degree: {
      degree: [],
    },
    schools: {
      schools: [],
    },
    education_xp: {
      education_xp: [],
    },
    career_stage: {
      career_stage: [],
    },
    career_xp: {
      career_xp: [],
    },
    career_fields: {
      career_fields: [],
    },
    employers: {
      employers: [],
    },
    health_xp: {
      health_xp: [],
    },
    health_credentials: {
      health_credentials: [],
    },
    relationships_xp: {
      relationships_xp: [],
    },
    family: {
      family: [],
    },
    childhood: {
      childhood: [],
    },
    languages: {
      languages: [],
    },
    network: {
      network: [],
    },
    finances_profile: {
      finances_profile: [],
    },
    finances_xp: {
      finances_xp: [],
    },
    finances_credentials: {
      finances_credentials: [],
    },
    linkedIn: {
      linkedIn: [],
    },
    sponsor: {
      sponsor: [],
    },
    calendly: {
      calendly: [],
    },
  };

  createSaveMock = (stateKey, state) => new Promise((resolve) => {
    setTimeout(() => {
      this.mockState[stateKey] = { [stateKey]: state };
      console.log('set', stateKey, 'state', this.mockState[stateKey]);
      resolve();
    }, 500);
  });

  createFetchMock = (stateKey) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(this.mockState[stateKey]);
    }, 500);
  });

  baseProfileFetch = async (fieldId) => {
    if (this.mock) {
      return this.createFetchMock(fieldId);
    }

    const result = await getDetail(`${profileBaseUrl}/?fields=${fieldId}`);
    return result?.profile;
  };

  baseProfileSave = async (fieldId, state) => {
    if (this.mock) {
      return this.createSaveMock(fieldId, state);
    }

    const toSave = Array.isArray(state) ? state : [state];
    const result = await patchData(profileBaseUrl, null, { [fieldId]: toSave });
    return result;
  };

  fetchProfileFields = async (fieldIds = []) => {
    const result = await getDetail(`${profileBaseUrl}/?fields=${fieldIds.join(',')}`);
    return result?.profile;
  };

  fetchPhotoState = async () => this.baseProfileFetch('profile_image');

  savePhotoState = async (state) => {
    if (this.mock) {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.photoState = {
            profile_image: state.uri,
          };
          resolve();
        }, 250);
      });
    }

    const result = await patchData(profileBaseUrl, null, {
      profile_media_id: [
        {
          category_id: 'identity',
          question_id: 'profile_media_id',
          value: state,
        },
      ],
    });
    return result;
  };

  fetchPortrait = async () => {
    if (this.mock) {
      return {
        portrait: {
          completed: false,
          detail: {
            health: true,
            education: false,
            relationships: false,
            identity: false,
            interests: true,
            career: false,
            finances: true,
          },
        },
      };
    }

    return getDetail(`${profileBaseUrl}/portrait`);
  };

  fetchZipState = async () => this.baseProfileFetch('zip_code');

  saveZipState = async (state) => this.baseProfileSave('zip_code', state);

  fetchBirthdayState = async () => this.baseProfileFetch('birthday');

  saveBirthdayState = async (state) => this.baseProfileSave('birthday', state);

  fetchGenderState = async () => this.baseProfileFetch('gender');

  saveGenderState = async (state) => {
    if (!firstOf(state)) {
      return this.baseProfileSave('gender', defaultIdentity('gender', preferNot));
    }
    return this.baseProfileSave('gender', state);
  };

  fetchEthnicityState = async () => this.baseProfileFetch('ethnicity');

  saveEthnicityState = async (state) => {
    if (!firstOf(state)) {
      return this.baseProfileSave('ethnicity', [defaultIdentity('ethnicity', preferNot)]);
    }
    return this.baseProfileSave('ethnicity', state);
  };

  fetchAffinityState = async () => {
    if (this.mock) {
      return this.createFetchMock('affinity');
    }

    const result = await getDetail(`${profileBaseUrl}/?fields=${affinityFieldIds.join(',')}`);
    const affinityAnswers = [];
    Object.keys(result.profile).filter((key) => !['teamCount', 'groupCount', 'user_sponsors'].includes(key)).forEach((key) => {
      const dbValue = firstOf(result.profile[key]);
      if (dbValue.value) {
        dbValue.value = dbValue.option_id;
        affinityAnswers.push(dbValue);
      }
    });

    return {
      affinity: affinityAnswers,
    };
  };

  saveAffinityState = async (state) => {
    if (this.mock) {
      return this.createSaveMock('affinity', state);
    }
    const converted = affinityFieldIds.reduce(
      (acc, affinity) => ({
        ...acc,
        [affinity]: [
          {
            category_id: 'affinity',
            question_id: affinity,
            value: !!state[affinity],
          },
        ],
      }),
      {},
    );

    const result = await patchData(profileBaseUrl, null, converted);
    return result;
  };

  fetchProfile = async (userId) => {
    if (this.mock) {
      // const profile = {};
      // return Object.keys(this.mockState).map((fieldId) => {
      //   profile[fieldId] = this.mockState[fieldId];
      //   if (this.mockState[fieldId][fieldId]) {
      //     profile[fieldId] = this.mockState[fieldId][fieldId];
      //     return Promise.resolve(profile);
      //   }
      //   return Promise.resolve({});
      // });
      return Promise.resolve(ProfileMock);
    }
    if (this.admin) {
      return Promise.resolve(CoachLubavProfileData(userId));
    }

    const result = await getDetail(
      userId ? `${profileBaseUrl}/public/${userId}` : `${profileBaseUrl}`,
    );
    return result?.profile;
  };

  fetchIntroduceState = async () => this.baseProfileFetch('excited');

  saveIntroduceState = async (state) => this.baseProfileSave('excited', state);

  fetchReligionState = async () => this.baseProfileFetch('religion');

  saveReligionState = async (state) => this.baseProfileSave('religion', state);

  fetchQuizState = async () => this.baseProfileFetch('quiz');

  saveQuizState = async (state) => this.baseProfileSave('quiz', state);

  fetchHobbiesState = async () => this.baseProfileFetch('hobbies');

  saveHobbiesState = async (state) => this.baseProfileSave('hobbies', state);

  fetchClubsState = async () => this.baseProfileFetch('clubs');

  saveClubsState = async (state) => this.baseProfileSave('clubs', state);

  fetchDegreeState = async () => this.baseProfileFetch('degree');

  saveDegreeState = async (state) => this.baseProfileSave('degree', state);

  fetchSchoolsState = async () => this.baseProfileFetch('schools');

  saveSchoolsState = async (state) => this.baseProfileSave('schools', state);

  fetchEducationXpState = async () => this.baseProfileFetch('education_xp');

  saveEducationXpState = async (state) => this.baseProfileSave('education_xp', state);

  fetchOccupationState = async () => this.baseProfileFetch('job_title');

  saveOccupationState = async (state) => this.baseProfileSave('job_title', state);

  fetchCareerStageState = async () => this.baseProfileFetch('career_stage');

  saveCareerStageState = async (state) => this.baseProfileSave('career_stage', state);

  fetchCareerXpState = async () => this.baseProfileFetch('career_xp');

  saveCareerXpState = async (state) => this.baseProfileSave('career_xp', state);

  fetchCareerFieldsState = async () => this.baseProfileFetch('career_fields');

  saveCareerFieldsState = async (state) => this.baseProfileSave('career_fields', state);

  fetchEmployersState = async () => this.baseProfileFetch('employers');

  saveEmployersState = async (state) => this.baseProfileSave('employers', state);

  fetchHealthXpState = async () => this.baseProfileFetch('health_xp');

  saveHealthXpState = async (state) => this.baseProfileSave('health_xp', state);

  fetchHealthInterestsState = async () => this.baseProfileFetch('health_interests');

  saveHealthInterestsState = async (state) => this.baseProfileSave('health_interests', state);

  fetchHealthCredentialsState = async () => this.baseProfileFetch('health_credentials');

  saveHealthCredentialsState = async (state) => this.baseProfileSave('health_credentials', state);

  fetchRelationshipsXpState = async () => this.baseProfileFetch('relationships_xp');

  saveRelationshipsXpState = async (state) => this.baseProfileSave('relationships_xp', state);

  fetchFamilyState = async () => this.baseProfileFetch('family');

  saveFamilyState = async (state) => this.baseProfileSave('family', state);

  fetchLanguagesState = async () => this.baseProfileFetch('languages');

  saveLanguagesState = async (state) => this.baseProfileSave('languages', state);

  fetchChildhoodState = async () => this.baseProfileFetch('childhood');

  saveChildhoodState = async (state) => this.baseProfileSave('childhood', state);

  fetchNetworkState = async () => this.baseProfileFetch('network');

  saveNetworkState = async (state) => this.baseProfileSave('network', state);

  fetchFinancesProfileState = async () => this.baseProfileFetch('finances_profile');

  saveFinancesProfileState = async (state) => this.baseProfileSave('finances_profile', state);

  fetchFinancesXpState = async () => this.baseProfileFetch('finances_xp');

  saveFinancesXpState = async (state) => this.baseProfileSave('finances_xp', state);

  fetchFinancesCredentialsState = async () => this.baseProfileFetch('finances_credentials');

  saveFinancesCredentialsState = async (state) => this.baseProfileSave('finances_credentials', state);

  fetchLinkedInState = async () => this.baseProfileFetch('linkedIn_url');

  saveLinkedInState = async (state) => this.baseProfileSave('linkedIn_url', state);

  fetchCalendlyState = async () => this.baseProfileFetch('calendly_url');

  saveCalendlyState = async (state) => this.baseProfileSave('calendly_url', state);

  fetchSponsorState = async () => this.baseProfileFetch('sponsors');

  saveSponsorState = async (state) => this.baseProfileSave('sponsors', state);

  fetchPurposeVideoState = async () => this.baseProfileFetch('purpose_video');

  savePurposeVideoState = async (state) => {
    if (this.mock) {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.photoState = {
            profile_image: state.uri,
          };
          resolve();
        }, 250);
      });
    }

    const result = await patchData(profileBaseUrl, null, {
      purpose_video: [
        {
          category_id: 'video',
          question_id: 'video',
          value: state,
        },
      ],
    });
    return result;
  };

  fetchResourceList = async (type, query = '') => {
    if (this.mock) {
      const search = (list) => list
        .filter((item) => item.toLowerCase().includes(query.toLowerCase()))
        .map((item) => ({ [type.replace(/.$/, '')]: item }));

      if (type === 'languages') {
        return { results: search(['English', 'Spanish', 'Chinese', 'Farsi', 'Indonesia']) };
      }
      if (type === 'schools') {
        return {
          results: search(['Harvard', 'Princeton', 'Duke', 'Grinnell', 'Hogwarts']),
        };
      }
      if (type === 'employers') {
        return {
          results: search(['IBM', 'Backrs', 'Olio Apps', 'Accenture', 'Apple', 'Google']),
        };
      }
      if (type === 'clubs') {
        return {
          results: search(['Boy Scouts', 'Girl Scouts', 'AARP', 'NAACP', 'NBA', 'NFL']),
        };
      }
      if (type === 'greeks') {
        return {
          results: search(greekData),
        };
      }

      return { results: [] };
    }

    const result = await getDetail(`${profileBaseUrl}/lookups/${type}?q=${query}`);
    return result;
  };

  fetchProfilePostTarget = async (targetUserId) => {
    if (this.mock) {
      return { targetUserId };
    }

    const result = await getDetail(`${profileBaseUrl}/postTarget?targetUserId=${targetUserId}`);
    return result;
  };

  fetchBadges = async (badgesId) => {
    if (this.mock) {
      return [
        {
          id: 1,
          title: 'Backr for 6 months',
          description: 'has been backing for six whole months! We can add some other stuff here! Skee-ba-dee-bop-boo-bah!',
          icon: 'assets/profile/wholeMe/badges/months.svg',
        },
        {
          id: 2,
          title: 'Multiple Proteges',
          description: 'backs multiple proteges! lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
          icon: 'assets/profile/wholeMe/badges/multiple-proteges.svg',
        },
        {
          id: 3,
          title: 'Mental Health First Aid',
          description: 'is a certified mental health lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
          icon: 'assets/profile/wholeMe/badges/first-aid.svg',
        },
        {
          id: 4,
          title: 'Super Backr',
          description: 'is a super backr! lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
          icon: 'assets/profile/wholeMe/badges/super-backr.svg',
        },
      ];
    }

    const filter = {
      id: badgesId,
    };
    const filterStr = encodeURIComponent(JSON.stringify(filter));
    const result = await getDetail(`/badges?filter=${filterStr}`);
    return result;
  };
}
