import React from 'react';
import { MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';
import { IonLabel } from '@ionic/react';
import PropTypes from 'prop-types';
import { LightTextField, TaupeTextField } from './themes';

const StyledDropdown = ({
  choices,
  control,
  error,
  name,
  placeholder,
  position,
  required,
  theme,
  title,
  defaultValue,
  sx,
  InputProps,
}) => (
  <div>
    {title && (
      <IonLabel style={{ color: '#2d2d2d', lineHeight: 1.25 }} position={position}>
        {title}
      </IonLabel>
    )}
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { value, ...rest } }) => (theme !== 'light' ? (
        <TaupeTextField
          placeholder={placeholder}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          value={value || ''}
          error={!!error?.message}
          helperText={error?.message}
          size="small"
          fullWidth
          select
          SelectProps={{
            MenuProps: {
              sx: { maxHeight: 250 },
            },
          }}
          sx={{
            ...sx,
            '& .MuiSelect-select .notranslate::after': placeholder
              ? {
                content: `"${placeholder}"`,
                opacity: 0.5,
              }
              : {},
          }}
          InputProps={InputProps}
        >
          {choices.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ textTransform: 'capitalize', ...option?.sx }}
              disabled={option?.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </TaupeTextField>
      ) : (
        <LightTextField
          placeholder={placeholder}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          value={value || ''}
          error={!!error?.message}
          helperText={error?.message}
          size="small"
          fullWidth
          select
          SelectProps={{
            MenuProps: {
              sx: { maxHeight: 250 },
            },
          }}
          InputProps={InputProps}
        >
          {choices.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ textTransform: 'capitalize', ...option?.sx }}
              disabled={option?.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </LightTextField>
      ))}
    />
  </div>
);

StyledDropdown.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  control: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  position: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.string,
  title: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  InputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

StyledDropdown.defaultProps = {
  control: null,
  defaultValue: '',
  error: null,
  placeholder: null,
  position: 'stacked',
  required: false,
  theme: 'taupe',
  title: null,
  sx: null,
  InputProps: null,
};

export default StyledDropdown;
