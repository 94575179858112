// eslint-disable-next-line import/prefer-default-export
export const sortTeamsByRelationship = (teams) => {
  if (!teams) {
    return [];
  }
  const sorted = [...teams];

  // sort teams by created_at
  sorted.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  // sort teams by relationship -- backing first, then proposed_match
  sorted.sort((a, b) => {
    if (a.relationship === 'backing' && b.relationship === 'backing') {
      return 0;
    }
    if (a.relationship === 'backing') {
      return -1;
    }
    if (b.relationship === 'proposed_match') {
      return 1;
    }

    return 0;
  });

  return sorted;
};

export const truncateText = (str, maxLen, screenWidth, minLen) => {
  if (screenWidth < 415) {
    if (str.length > minLen) {
      return `${str.substr(0, minLen)}...`;
    }
    return str;
  }
  if (str.length > maxLen) {
    return `${str.substr(0, maxLen)}...`;
  }
  return str;
};
