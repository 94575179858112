import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  '& .MuiFilledInput-root': {
    background: '#fff',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    borderRadius: 8,
    '& fieldset': {
      borderColor: '#c3c3b9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
});

const TaupeTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  // input adornment
  '& .MuiSvgIcon-root': {
    color: '#C3C3B9',
  },
  '& .MuiFilledInput-root': {
    background: '#f4f4ee',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#f4f4ee',
    borderRadius: 8,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: '#063d8f',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#2d2d2d',
    '&::placeholder': {
      opacity: 0.5,
      textTransform: 'initial',
    },
  },
});

const UnderlinedTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  '& .MuiFilledInput-root': {
    background: '#fff',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000',
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottomColor: '#d32f2f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    borderRadius: 8,
    '& fieldset': {
      borderColor: '#c3c3b9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
  '& .MuiInput-input::placeholder': {
    color: 'var(--placeholderClr, #9c9c9c)',
    opacity: 1,
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
});

export { LightTextField, TaupeTextField, UnderlinedTextField };
