import React from 'react';
import {
  Avatar,
  Typography,
  Stack,
  ButtonBase,
} from '@mui/material';
import PropTypes from 'prop-types';

const defaultIcon = '/assets/profile/default-network.png';
const defaultProfileImage = '/assets/profile/default-profile-image-square.png';

const FilterItem = ({
  filterMetaItem,
  isSelected,
  onClickCallBack,
  size,
  disableClickEventsOverrideRef,
  buttonStyleOverride,
}) => {
  const {
    icon,
    label,
    type,
    avatarStyles,
    imgProps,
  } = filterMetaItem;

  return (
    <ButtonBase
      onClick={() => onClickCallBack(filterMetaItem)}
      disabled={disableClickEventsOverrideRef?.current}
      sx={buttonStyleOverride}
    >
      <Stack
        spacing={0.375}
        sx={{
          color: '#2d2d2d',
          textAlign: 'center',
          opacity:
            isSelected === true
              ? 1
              : 0.75,
        }}
      >
        <Avatar
          src={icon}
          imgProps={imgProps}
          sx={{
            width: size,
            height: size,
            borderRadius: 2,
            fontWeight: 400,
            fontSize: 30,
            ...avatarStyles,
          }}
        >
          {type === 'group'
            ? <img style={{ width: size, height: 'size' }} src={defaultIcon} alt="profile" />
            : <img style={{ width: size, height: 'size' }} src={defaultProfileImage} alt="profile" />}
        </Avatar>
        {label && (
          <Typography
            component="span"
            sx={{
              marginTop: '5px !important',
              fontSize:
                isSelected === true
                  ? '11px'
                  : '12px',
              fontWeight:
                isSelected === true
                  ? 700
                  : 400,
              lineHeight: 'normal',
              maxWidth: size,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {label}
          </Typography>
        )}
      </Stack>
    </ButtonBase>
  );
};

FilterItem.propTypes = {
  filterMetaItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClickCallBack: PropTypes.func.isRequired,
  size: PropTypes.number,
  disableClickEventsOverrideRef: PropTypes.oneOfType([PropTypes.object]),
  buttonStyleOverride: PropTypes.oneOfType([PropTypes.object]),
};

FilterItem.defaultProps = {
  size: 60,
  disableClickEventsOverrideRef: null,
  buttonStyleOverride: null,
};

export default FilterItem;
