import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import PageShell from 'common/molecules/pageShell/PageShell';
import TitleAtom from 'common/atoms/title/TitleAtom';
import BackrsButton from 'common/atoms/backrsButton/BackrsButton';
import appStrings from 'common/app_strings';
import { setCompleteProfileToken } from 'helpers/tokenHelpers';
import { useAuthContext } from 'providers/auth-provider';
import {
  pageContainerStyles,
  navContainerStyles,
  titleStyles,
  imageStyles,
  buttonOverrideStyles,
  pageShellStyles,
} from './profileCompletedStyles';

const mainFeedLinkStyle = {
  ...titleStyles.styles,
  fontSize: '18px',
  fontWeight: 500,
  marginTop: '14px',
};

const navContainer = {
  ...navContainerStyles,
  display: 'flex',
  alignItems: 'center',
};

const {
  profileCompleted: {
    header, subheader, navText, imgAltText, mainFeed,
  },
} = appStrings;

const ProfileCompleted = () => {
  const history = useHistory();
  const { setHasCompletedProfile } = useAuthContext();
  const goToProfile = () => {
    history.push('/tabs/profile');
  };
  const goToMainFeed = () => {
    history.push('/tabs/main-feed');
  };

  useEffect(() => () => {
    setCompleteProfileToken();
    setHasCompletedProfile(true);
  });
  return (
    <PageShell styles={pageShellStyles}>
      <Stack style={pageContainerStyles}>
        <TitleAtom
          header={header}
          subHeader={subheader}
          stylesOverride={titleStyles}
        />
        <img src="assets/profile-completed-badge.svg" alt={imgAltText} style={imageStyles} />
        <Stack style={navContainer}>
          <BackrsButton label={navText} buttonStylesOverride={buttonOverrideStyles} onClick={goToProfile} />
          <Typography style={mainFeedLinkStyle} onClick={goToMainFeed}>{mainFeed}</Typography>
        </Stack>
      </Stack>
    </PageShell>
  );
};

export default ProfileCompleted;
