import React from 'react';
import {
  IonContent,
  IonGrid,
  IonButton,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonRouterLink,
} from '@ionic/react';
import {
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
  Radio,
  TextField,
  MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import useFetch from 'use-http';
import PropTypes from 'prop-types';
import { TaupeTextField } from 'components/StyledInput';
import PageWithBackButton from 'components/generic/PageWithBackButton';
import './GoalsCreate.css';
import { useAuthContext } from 'providers/auth-provider';

const GoalsEdit = ({ history, location }) => {
  const { user } = useAuthContext();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' });
  const { post, patch, del } = useFetch();
  const onSubmit = async (data) => {
    const editedGoal = await patch(`/goals/${location?.state?.id}`, data);

    if (editedGoal.visibility === 'team' && !location?.state?.post?.id) {
      const postFormData = {
        body: '',
        user_id: user.id,
        author_id: user.id,
        goal_id: editedGoal.id,
        attachments: [],
        visibility: editedGoal.visibility,
      };

      await post('/posts', postFormData);
    }

    if (editedGoal.visibility === 'private' && location?.state?.post?.id) {
      await del(`/posts/${location?.state?.post?.id}`);
    }

    history.push('/tabs/protege/goals');
  };

  return (
    <PageWithBackButton backButtonText="Edit Goal">
      <IonContent fullscreen>
        <form>
          <IonGrid>
            <IonRow>
              <IonCol>
                <Stack spacing={2} mb={5}>
                  <button
                    type="button"
                    className={`goals-type-button goals-type-${location?.state?.type}`}
                  >
                    <img
                      src={`/assets/${location?.state?.type}-goal.svg`}
                      className="goals-type-button-icon"
                      alt={`${location?.state?.type} goal icon`}
                    />
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      color="#fff"
                      fontSize={24}
                      width="100%"
                      textTransform="capitalize"
                    >
                      {location?.state?.type}
                    </Typography>
                  </button>
                </Stack>
              </IonCol>
            </IonRow>
            <div className="goals-smart-wrapper">
              <Typography
                variant="h4"
                fontWeight={700}
                fontSize={20}
                sx={{ textAlign: 'center', mr: 1, mb: 1.5 }}
              >
                Edit your SMART goal
              </Typography>
              <IonRouterLink routerLink="/protege/goals-create/smart">
                <div className="goals-smart-link">?</div>
              </IonRouterLink>
            </div>
            <IonRow>
              <IonCol>
                <IonCard
                  color="light"
                  style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                >
                  <IonCardContent>
                    <Controller
                      control={control}
                      name="content"
                      defaultValue={location?.state?.content}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          variant="standard"
                          multiline
                          minRows={3}
                          maxRows={5}
                          fullWidth
                          inputProps={{
                            maxLength: 200,
                            autoCapitalize: 'on',
                            inputMode: 'text',
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          InputProps={{ disableUnderline: true }}
                        />
                      )}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard
                  color="light"
                  style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                >
                  <IonCardContent>
                    <Typography
                      variant="h2"
                      color="#000"
                      fontWeight="500 !important"
                      mb="12px !important"
                    >
                      Share With
                    </Typography>
                    <Controller
                      control={control}
                      name="visibility"
                      defaultValue={
                        location?.state?.post?.id ? 'team' : 'private'
                      }
                      rules={{ required: true }}
                      render={({ field }) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value="team"
                            control={(
                              <Radio
                                sx={{
                                  '&, &.Mui-checked': {
                                    color: '#063D8F',
                                  },
                                }}
                              />
                            )}
                            label="My Team"
                          />
                          <FormControlLabel
                            value="private"
                            control={(
                              <Radio
                                sx={{
                                  '&, &.Mui-checked': {
                                    color: '#063D8F',
                                  },
                                }}
                              />
                            )}
                            label="Keep Private"
                          />
                        </RadioGroup>
                      )}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard
                  color="light"
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  <IonCardContent>
                    <Typography
                      variant="h2"
                      color="#000"
                      fontWeight="500 !important"
                      mb="12px !important"
                    >
                      Goal Progress
                    </Typography>
                    <Controller
                      control={control}
                      name="status"
                      defaultValue={location?.state?.status}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TaupeTextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          select
                          size="small"
                          fullWidth
                        >
                          <MenuItem value="in_progress">In Progress</MenuItem>
                          <MenuItem value="completed">Completed</MenuItem>
                        </TaupeTextField>
                      )}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  size="large"
                  expand="block"
                  shape="round"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty || !isValid}
                >
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </PageWithBackButton>
  );
};

GoalsEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default GoalsEdit;
