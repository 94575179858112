/* eslint-disable camelcase */
import ProfileIdentityIntakeZipPage from 'pages/wholeme/pages/identity/zip/ProfileIdentityIntakeZipPage';
import ProfileIdentityIntakeBirthdayPage from 'pages/wholeme/pages/identity/birthday/ProfileIdentityIntakeBirthdayPage';
import ProfileIdentityIntakeGenderPage from 'pages/wholeme/pages/identity/gender/ProfileIdentityIntakeGenderPage';
import ProfileIdentityIntakePhotoPage from 'pages/wholeme/pages/identity/photo/ProfileIdentityIntakePhotoPage';
import ProfileIdentityIntakePurposeVideoPage from 'pages/wholeme/pages/identity/purposeVideo/ProfileIdentityIntakePurposeVideoPage';
import ProfileIdentityIntakeSplashPage from 'pages/wholeme/pages/identity/splash/ProfileIdentityIntakeSplashPage';
import ProfileIdentityIntakeEthnicityPage from 'pages/wholeme/pages/identity/ethnicity/ProfileIdentityIntakeEthnicityPage';
import ProfileIdentityIntakeAffinityPage from 'pages/wholeme/pages/identity/affinity/ProfileIdentityIntakeAffinityPage';
import ProfileIdentityIntakeIntroducePage from 'pages/wholeme/pages/identity/introduce/ProfileIdentityIntakeIntroducePage';
import ProfileIdentityIntakeReligionPage from 'pages/wholeme/pages/identity/religion/ProfileIdentityIntakeReligionPage';
import ProfileIdentityIntakeQuizPage from 'pages/wholeme/pages/identity/quiz/ProfileIdentityIntakeQuizPage';
import ProfileInterestsIntakeHobbiesPage from 'pages/wholeme/pages/interests/hobbies/ProfileInterestsIntakeHobbiesPage';
import ProfileInterestsIntakeClubsPage from 'pages/wholeme/pages/interests/clubs/ProfileInterestsIntakeClubsPage';
import ProfileInterestsIntakeSplashPage from 'pages/wholeme/pages/interests/splash/ProfileInterestsIntakeSplashPage';
import ProfileEducationIntakeSplashPage from 'pages/wholeme/pages/education/splash/ProfileEducationIntakeSplashPage';
import ProfileEducationIntakeDegreePage from 'pages/wholeme/pages/education/degree/ProfileEducationIntakeDegreePage';
import ProfileEducationIntakeSchoolsPage from 'pages/wholeme/pages/education/schools/ProfileEducationIntakeSchoolsPage';
import ProfileEducationIntakeXpPage from 'pages/wholeme/pages/education/education_xp/ProfileEducationIntakeXpPage';
import ProfileCareerIntakeSplashPage from 'pages/wholeme/pages/career/splash/ProfileCareerIntakeSplashPage';
import ProfileCareerIntakeOccupationPage from 'pages/wholeme/pages/career/occupation/ProfileCareerIntakeOccupationPage';
import ProfileCareerIntakeStagePage from 'pages/wholeme/pages/career/career_stage/ProfileCareerIntakeStagePage';
import ProfileCareerIntakeXpPage from 'pages/wholeme/pages/career/career_xp/ProfileCareerIntakeXpPage';
import ProfileCareerIntakeFieldsPage from 'pages/wholeme/pages/career/career_fields/ProfileCareerIntakeFieldsPage';
import ProfileCareerIntakeEmployersPage from 'pages/wholeme/pages/career/employers/ProfileCareerIntakeEmployersPage';
import ProfileHealthIntakeSplashPage from 'pages/wholeme/pages/health/splash/ProfileHealthIntakeSplashPage';
import ProfileHealthIntakeXpPage from 'pages/wholeme/pages/health/health_xp/ProfileHealthIntakeXpPage';
import ProfileRelationshipsIntakeSplashPage from 'pages/wholeme/pages/relationships/splash/ProfileRelationshipsIntakeSplashPage';
import ProfileRelationshipsIntakeXpPage from 'pages/wholeme/pages/relationships/relationships_xp/ProfileRelationshipsIntakeXpPage';
import ProfileRelationshipsIntakeFamilyPage from 'pages/wholeme/pages/relationships/family/ProfileRelationshipsIntakeFamilyPage';
import ProfileRelationshipsIntakeChildhoodPage from 'pages/wholeme/pages/relationships/childhood/ProfileRelationshipsIntakeChildhoodPage';
import ProfileNetworkIntakeSplashPage from 'pages/wholeme/pages/network/splash/ProfileNetworkIntakeSplashPage';
import ProfileNetworkIntakeNetworkPage from 'pages/wholeme/pages/network/network/ProfileNetworkIntakeNetworkPage';
import ProfileFinancesIntakeSplashPage from 'pages/wholeme/pages/finances/splash/ProfileFinancesIntakeSplashPage';
import ProfileExperiencesIntakeSplashPage from 'pages/wholeme/pages/experiences/splash/ProfileExperiencesIntakeSplashPage';
import ProfileFinancesIntakeProfilePage from 'pages/wholeme/pages/finances/profile/ProfileFinancesIntakeProfilePage';
import ProfileFinancesIntakeXpPage from 'pages/wholeme/pages/finances/finances_xp/ProfileFinancesIntakeXpPage';
import ProfileFinancesIntakeCredentialsPage from 'pages/wholeme/pages/finances/finances_credentials/ProfileFinancesIntakeCredentialsPage';
import ProfileLinkedInPage from 'pages/wholeme/pages/identity/linkedin/ProfileLinkedInPage';
import SponsorIntakePage from 'pages/wholeme/pages/identity/sponsor/SponsorIntakePage';
import ReadyToBack from 'pages/register/ReadyToBack';
import ProfileCalendlyIntakePage from '../pages/identity/calendly/ProfileCalendlyIntakePage';
import ProtegePreferencesPage from '../../../workflows/matching/protegePreferencesPage/ProtegePreferencesPage';
import CohortCodePage from '../../../workflows/matching/cohortCodePage/CohortCodePage';
import profileHealthInterestsPage from '../pages/health/health_interests/ProfileHealthIntakeInterestsPage';
import profileHealthCredentialsPage from '../pages/health/health_credentials/ProfileHealthIntakeCredentialsPage';
import ProfileRelationshipsIntakeLanguagesPage from '../pages/relationships/languages/ProfileRelationshipsIntakeLanguagesPage';
import { WholeMeClient } from './wholeme_client';

export const stepMeta = {
  identity: [
    {
      id: 'splash',
      category: 'identity',
      component: ProfileIdentityIntakeSplashPage,
    },
    {
      id: 'sponsor',
      category: 'identity',
      component: SponsorIntakePage,
    },
    {
      id: 'photo',
      category: 'identity',
      component: ProfileIdentityIntakePhotoPage,
    },
    {
      id: 'zip',
      category: 'identity',
      component: ProfileIdentityIntakeZipPage,
    },
    {
      id: 'birthday',
      category: 'identity',
      component: ProfileIdentityIntakeBirthdayPage,
    },
    {
      id: 'occupation',
      category: 'identity',
      component: ProfileCareerIntakeOccupationPage,
    },
    {
      id: 'gender',
      category: 'identity',
      component: ProfileIdentityIntakeGenderPage,
    },
    {
      id: 'ethnicity',
      category: 'identity',
      component: ProfileIdentityIntakeEthnicityPage,
    },
    {
      id: 'affinity',
      category: 'identity',
      component: ProfileIdentityIntakeAffinityPage,
    },
    {
      id: 'introduce',
      category: 'identity',
      component: ProfileIdentityIntakeIntroducePage,
    },
    {
      id: 'religion',
      category: 'identity',
      component: ProfileIdentityIntakeReligionPage,
    },
    {
      id: 'quiz',
      category: 'identity',
      component: ProfileIdentityIntakeQuizPage,
    },
    {
      id: 'linkedIn',
      category: 'identity',
      component: ProfileLinkedInPage,
    },
    {
      id: 'calendly',
      category: 'identity',
      component: ProfileCalendlyIntakePage,
    },
    {
      id: 'career_fields',
      category: 'career',
      component: ProfileCareerIntakeFieldsPage,
    },
    {
      id: 'purpose_video',
      category: 'identity',
      component: ProfileIdentityIntakePurposeVideoPage,
    },
  ],
  interests: [
    {
      id: 'splash',
      category: 'interests',
      component: ProfileInterestsIntakeSplashPage,
    },
    {
      id: 'hobbies',
      category: 'interests',
      component: ProfileInterestsIntakeHobbiesPage,
    },
    {
      id: 'clubs',
      category: 'interests',
      component: ProfileInterestsIntakeClubsPage,
    },
  ],
  education: [
    {
      id: 'splash',
      category: 'education',
      component: ProfileEducationIntakeSplashPage,
    },
    {
      id: 'degree',
      category: 'education',
      component: ProfileEducationIntakeDegreePage,
    },
    {
      id: 'schools',
      category: 'education',
      component: ProfileEducationIntakeSchoolsPage,
    },
    {
      id: 'education_xp',
      category: 'education',
      component: ProfileEducationIntakeXpPage,
    },
  ],
  career: [
    {
      id: 'splash',
      category: 'career',
      component: ProfileCareerIntakeSplashPage,
    },
    {
      id: 'occupation',
      category: 'career',
      component: ProfileCareerIntakeOccupationPage,
    },
    {
      id: 'career_stage',
      category: 'career',
      component: ProfileCareerIntakeStagePage,
    },
    {
      id: 'career_xp',
      category: 'career',
      component: ProfileCareerIntakeXpPage,
    },
    {
      id: 'career_fields',
      category: 'career',
      component: ProfileCareerIntakeFieldsPage,
    },
    {
      id: 'employers',
      category: 'career',
      component: ProfileCareerIntakeEmployersPage,
    },
  ],
  health: [
    {
      id: 'splash',
      category: 'health',
      component: ProfileHealthIntakeSplashPage,
    },
    {
      id: 'health_xp',
      category: 'health',
      component: ProfileHealthIntakeXpPage,
    },
    {
      id: 'health_interests',
      category: 'health',
      component: profileHealthInterestsPage,
    },
    {
      id: 'health_credentials',
      category: 'health',
      component: profileHealthCredentialsPage,
    },
  ],
  relationships: [
    {
      id: 'splash',
      category: 'relationships',
      component: ProfileRelationshipsIntakeSplashPage,
    },
    {
      id: 'relationships_xp',
      category: 'relationships',
      component: ProfileRelationshipsIntakeXpPage,
    },
    {
      id: 'family',
      category: 'relationships',
      component: ProfileRelationshipsIntakeFamilyPage,
    },
    {
      id: 'childhood',
      category: 'relationships',
      component: ProfileRelationshipsIntakeChildhoodPage,
    },
    {
      id: 'languages',
      category: 'relationships',
      component: ProfileRelationshipsIntakeLanguagesPage,
    },
  ],
  network: [
    {
      id: 'splash',
      category: 'network',
      component: ProfileNetworkIntakeSplashPage,
    },
    {
      id: 'network',
      category: 'network',
      component: ProfileNetworkIntakeNetworkPage,
    },
  ],
  finances: [
    {
      id: 'splash',
      category: 'finances',
      component: ProfileFinancesIntakeSplashPage,
    },
    {
      id: 'finances_profile',
      category: 'finances_profile',
      component: ProfileFinancesIntakeProfilePage,
    },
    {
      id: 'finances_xp',
      category: 'finances_profile',
      component: ProfileFinancesIntakeXpPage,
    },
    {
      id: 'finances_credentials',
      category: 'finances_profile',
      component: ProfileFinancesIntakeCredentialsPage,
    },
  ],
  experiences: [
    {
      id: 'splash',
      category: 'experiences',
      component: ProfileExperiencesIntakeSplashPage,
    },
  ],
  matching: [
    {
      id: 'splash',
      category: 'matching',
      component: ReadyToBack,
    },
    {
      id: 'protege_preferences',
      category: 'matching',
      component: ProtegePreferencesPage,
    },
    {
      id: 'match_code',
      category: 'matching',
      component: CohortCodePage,
    },
    {
      id: 'birthday',
      category: 'identity', // Question: Should this remain 'identity' or be 'matching'?
      component: ProfileIdentityIntakeBirthdayPage,
    },
  ],
};

export const computeContainer = ({ step, stepKeys }) => stepKeys.map((key) => stepMeta[step].find((meta) => meta.id === key));

export const stepKeysMap = {
  identity: {
    step: 'identity',
    stepKeys: [
      'splash',
      'photo',
      'zip',
      'birthday',
      'gender',
      'ethnicity',
      'affinity',
      'introduce',
      'religion',
      'quiz',
      'linkedIn',
      'calendly',
      'purpose_video',
    ],
  },
  interests: {
    step: 'interests',
    stepKeys: ['splash', 'hobbies', 'clubs'],
  },
  education: {
    step: 'education',
    stepKeys: ['splash', 'degree', 'schools', 'education_xp'],
  },
  career: {
    step: 'career',
    stepKeys: ['splash', 'occupation', 'career_stage', 'career_xp', 'career_fields', 'employers'],
  },
  health: {
    step: 'health',
    stepKeys: ['splash', 'health_xp', 'health_interests', 'health_credentials'],
  },
  relationships: {
    step: 'relationships',
    stepKeys: ['splash', 'relationships_xp', 'family', 'childhood', 'languages'],
  },
  network: {
    step: 'network',
    stepKeys: ['splash', 'network'],
  },
  finances: {
    step: 'finances',
    stepKeys: ['splash', 'finances_profile', 'finances_xp', 'finances_credentials'],
  },
};

export const stepKeysPreMatch = [
  {
    step: 'identity',
    stepKeys: [
      'sponsor',
      'gender',
      'ethnicity',
      'affinity',
      'career_fields',
      'linkedIn',
      'calendly',
      'zip',
      'photo',
    ],
  },
];

export const stepKeysPostMatch = [
  {
    step: 'identity',
    stepKeys: ['religion', 'quiz'],
  },
  stepKeysMap.interests,
  stepKeysMap.education,
  stepKeysMap.career,
  stepKeysMap.health,
  stepKeysMap.relationships,
  stepKeysMap.network,
  stepKeysMap.finances,
];

export const stepKeysAll = [
  stepKeysMap.identity,
  stepKeysMap.interests,
  stepKeysMap.education,
  stepKeysMap.career,
  stepKeysMap.health,
  stepKeysMap.relationships,
  stepKeysMap.network,
  stepKeysMap.finances,
];

export const stepsEducationCareer = [stepKeysMap.education, stepKeysMap.career];

export const stepsExperiencesAll = [
  {
    step: 'experiences',
    stepKeys: ['splash'],
  },
  {
    step: 'education',
    stepKeys: ['education_xp'],
  },
  {
    step: 'career',
    stepKeys: ['career_xp'],
  },
  {
    step: 'finances',
    stepKeys: ['finances_xp'],
  },
  {
    step: 'health',
    stepKeys: ['health_xp'],
  },
  {
    step: 'relationships',
    stepKeys: ['relationships_xp'],
  },
];

export const educationCareer = [
  stepKeysMap.education,
  stepKeysMap.career,
];

export const relationshipsNetwork = [
  stepKeysMap.relationships,
  stepKeysMap.network,
];

const identityFields = [
  'profile_image',
  'zip_code',
  'birthday',
  'gender',
  'ethnicity',
  'religion',
  'first_gen_college',
  'immigrant',
  'military',
  'disability',
  'lgbtq',
  'excited',
  'rural',
  'college_athletics',
  'adopted_foster',
];

const genderEthnicityFields = ['gender', 'ethnicity'];
const affinityFields = [
  'first_gen_college',
  'immigrant',
  'military',
  'disability',
  'lgbtq',
  'rural',
  'college_athletics',
  'adopted_foster',
];

export const computeSteps = async (categoryId, stepId) => {
  if (categoryId === 'education') {
    return stepsEducationCareer;
  }

  if (categoryId === 'experiences_all') {
    return stepsExperiencesAll;
  }

  if (categoryId === 'education') {
    return educationCareer;
  }

  if (categoryId === 'relationships') {
    return relationshipsNetwork;
  }

  if (categoryId === 'identity' && !stepId) {
    const client = new WholeMeClient();
    const profile = await client.fetchProfileFields(identityFields);
    const omitFields = Object.entries(profile).filter(([key, value]) => {
      if (!value[0]?.value) {
        return false;
      }
      if (genderEthnicityFields.includes(key) && !value[0]?.option_id) {
        return false;
      }
      return true;
    }).map(([key]) => {
      if (key === 'profile_image') {
        return 'photo';
      }
      if (key === 'zip_code') {
        return 'zip';
      }
      if (key === 'excited') {
        return 'introduce';
      }
      if (affinityFields.includes(key)) {
        return 'affinity';
      }
      return key;
    });
    const categorySteps = stepKeysMap[categoryId];
    const filteredKeys = categorySteps.stepKeys.filter((key) => !omitFields.includes(key));
    return [{ step: categorySteps.step, stepKeys: filteredKeys }];
  }

  if (!categoryId || !(categoryId in stepKeysMap)) {
    return stepKeysAll;
  }

  const categorySteps = stepKeysMap[categoryId];

  if (stepId && categorySteps.stepKeys.includes(stepId)) {
    const filteredKeys = categorySteps.stepKeys.filter((next) => next === stepId);
    return [{ step: categorySteps.step, stepKeys: filteredKeys }];
  }
  return [categorySteps];
};

const matchQuizSteps = {
  step: 'matching',
  stepKeys: ['splash', 'protege_preferences', 'birthday', 'match_code'],
};

const validateMatchingData = (data = {}) => {
  const {
    state,
    zip_code,
    photo,
  } = data || {};
  if (!state || !zip_code || !photo) {
    return false;
  }
  return true;
};

export const computeMatchingWizardSteps = ({
  hasSeenReadyToBack,
  matchData,
  dataIsLoaded,
}) => {
  if (!dataIsLoaded) {
    return [];
  }

  const steps = [];
  const preMatchQuizDataPresent = validateMatchingData(matchData);
  const filteredMatchQuizSteps = {
    ...matchQuizSteps,
    stepKeys: [...matchQuizSteps.stepKeys],
  };
  const matchQuizStepsToSkip = [];

  if (!preMatchQuizDataPresent) {
    steps.push(stepKeysPreMatch[0]);
  }

  if (hasSeenReadyToBack) {
    matchQuizStepsToSkip.push('splash');
  }

  if (matchData.birthday) {
    matchQuizStepsToSkip.push('birthday');
  }

  filteredMatchQuizSteps.stepKeys = filteredMatchQuizSteps.stepKeys.filter((key) => !matchQuizStepsToSkip.includes(key));

  steps.push(filteredMatchQuizSteps);
  return steps;
};
