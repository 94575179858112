/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import ProfileIntakeStepPageContainer from 'pages/wholeme/molecules/profileIntakeStepPageContainer/ProfileIntakeStepPageContainer';
import PageShell from 'common/molecules/pageShell/PageShell';
import YupNopeNavigation from '../../../molecules/yupNopeNavigation/YupNopeNavigation';
import { networkData } from './networkData';

const bgOverlay = '/assets/profile/network/question-overlay.svg';

const imgStyle = {
  width: '246px',
  height: '246px',
  margin: 'auto',
};

const ProfileNetworkIntakeNetworkPage = ({ nextOnClick, prevOnClick, wholeMeClient }) => {
  const [fieldState, setFieldState] = useState();
  useEffect(async () => {
    const state = (await wholeMeClient.fetchNetworkState())?.network || [];
    console.log(state);
    const updatedState = networkData.reduce(
      (acc, next) => ({
        ...acc,
        [next.value]: state.find((option) => option.option_id === next.value)?.value,
      }),
      {},
    );
    console.log(updatedState);
    setFieldState(updatedState);
  }, []);

  const saveField = async () => {
    const converted = Object.keys(fieldState)
      .map((key) => ({
        category_id: 'network',
        question_id: 'network',
        option_id: key,
        value: fieldState[key],
      }))
      .filter((n) => !!n);
    await wholeMeClient.saveNetworkState(converted);
  };

  const nopeYupNav = (field, next) => {
    const handleNext = (yes) => {
      fieldState[field.value] = yes ? 'yes' : 'no';
      setFieldState(fieldState);

      if (next.stepIndex === networkData.length - 1) {
        // if we're at the end of the last one,
        // be done with this step
        saveField().then(() => {
          nextOnClick();
        });
      } else {
        saveField().then(() => {
          next?.nextOnClick();
        });
      }
    };
    return (
      <YupNopeNavigation
        handleNext={handleNext}
        selected={
          fieldState === undefined
          || field?.value === undefined
          || !fieldState[field?.value]
            ? undefined
            : fieldState[field?.value] === 'yes'
        }
      />
    );
  };

  const slides = networkData.map((field) => ({
    component: (arg) => (
      <ProfileIntakeStepPage
        title="Do you know..."
        subtitle={`${field.label}?`}
        NavComponent={nopeYupNav(field, arg)}
        backgroundOverlay={bgOverlay}
        hasLargeSubheader
      >
        <img style={imgStyle} alt="random" src={field.imgSrc} />
      </ProfileIntakeStepPage>
    ),
  }));

  return (
    <PageShell>
      <ProfileIntakeStepPageContainer
        key={`category-${Date.now()}`}
        components={slides}
        onSubmitFinal={nextOnClick}
        onSubmitBack={prevOnClick}
        wholeMeClient={wholeMeClient}
      />
    </PageShell>
  );
};

export default ProfileNetworkIntakeNetworkPage;
