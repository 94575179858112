import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonButton,
} from '@ionic/react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../providers/auth-provider';

const HeaderAtom = ({
  backButtonLabel, actionLabel, actionCallback, backgroundColor,
}) => {
  const { user } = useAuthContext();

  return (
    <IonHeader className="ion-no-border">
      <IonToolbar className="back-button-toolbar" style={{ '--background': backgroundColor }}>
        <IonButtons slot="start">
          <IonBackButton
            text={backButtonLabel}
            icon="/assets/back-arrow.svg"
            style={{
              '--icon-font-size': '1.25rem',
              '--icon-padding-start': '8px',
              '--icon-padding-end': '12px',
              '--icon-padding-top': '4px',
              '--icon-margin-end': '6px',
            }}
            className="back-button-custom"
            mode="ios"
          />
        </IonButtons>
        {!user ? (
          <IonButtons slot="end">
            <IonButton
              mode="ios"
              shape="round"
              fill="outline"
              style={{
                fontSize: 14,
                fontWeight: 700,
                '--padding-start': '26px',
                '--padding-end': '26px',
              }}
              onClick={actionCallback}
            >
              {actionLabel}
            </IonButton>
          </IonButtons>
        ) : null}
      </IonToolbar>
    </IonHeader>
  );
};

HeaderAtom.propTypes = {
  backButtonLabel: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionCallback: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
};

HeaderAtom.defaultProps = {
  backgroundColor: null,
};

export default HeaderAtom;
