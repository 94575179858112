/* eslint-disable camelcase */
import React from 'react';
import { LinkedInIcon } from 'pages/wholeme/icons/Icons';
import { resolveDefaultIcons } from 'pages/wholeme/util/profile_util';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import { Stack } from '@mui/material';
import WholeMeProfileSectionRowLinkedIn from '../wholeMeProfileSectionRow/WholeMeProfileSectionLinkedInRow';
import { pillRendererOverrideStyles } from '../wholeMeProfileSection/wholeMeProfileSectionStyles';
import WholeMeProfileSection from '../wholeMeProfileSection/WholeMeProfileSection';

const WholeMeProfileLinkedIn = ({ profile, isViewOnly }) => {
  const pillContainerOverrideStyles = isViewOnly
    ? pillRendererOverrideStyles
    : {
      ...pillRendererOverrideStyles,
      marginLeft: '22px',
    };

  const { linkedIn_skills, linkedIn_education, linkedIn_jobs } = profile;

  if (!linkedIn_jobs?.length && !linkedIn_skills?.length && !linkedIn_education?.length) {
    return null;
  }

  const buildLinkedInJobItems = (items) => items.map((item) => ({
    name: item?.company_name,
    job_title: item?.job_title,
    dates: item?.dates,
    icon: resolveDefaultIcons('linkedIn', 'job'),
  }));

  const buildLinkedInEducationItems = (items) => items.map((item) => ({
    name: item?.school_name,
    degree: item?.certification_received,
    dates: item?.dates,
    icon: resolveDefaultIcons('linkedIn', 'education'),
  }));

  const buildPillItems = (items) => items?.map((item) => item?.skill);

  const jobItems = buildLinkedInJobItems(linkedIn_jobs);
  const educationItems = buildLinkedInEducationItems(linkedIn_education);
  const pillItems = buildPillItems(linkedIn_skills);

  const renderView = () => {
    const allItems = [...jobItems, ...educationItems];
    return (
      <>
        <Stack>
          {allItems?.map((item) => (
            <WholeMeProfileSectionRowLinkedIn
              key={item?.name}
              iconSrc={item?.icon}
              label={item?.name}
              meta={item}
            />
          ))}
        </Stack>
        <Stack sx={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
          <PillRenderer items={pillItems} containerOverrideStyles={pillContainerOverrideStyles} />
        </Stack>
      </>
    );
  };

  return (
    <WholeMeProfileSection title="LinkedIn" editable={false} titleIcon={<LinkedInIcon />}>
      {renderView()}
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileLinkedIn;
