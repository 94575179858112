export const searchOptionsSuggestionsListStyles = {
  position: 'absolute',
  top: '53px',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 48px)',
  maxHeight: '100px',
  padding: '10px',
  marginLeft: '4px',
  backgroundColor: '#FFFFFF',
  WebkitBoxShadow: '0.5px 1px 4px 0px rgba(0,0,0,0.25)',
  MozBoxShadow: '0.5px 1px 4px 0px rgba(0,0,0,0.25)',
  boxShadow: '0.5px 1px 4px 0px rgba(0,0,0,0.25)',
  borderRadius: '8px',
  overflowY: 'scroll',
  zIndex: '10',
  fontFamily: 'Sarabun, arial, sans-serif',
};

export const webSearchOptionsSuggestionsListStyles = {
  ...searchOptionsSuggestionsListStyles,
  maxHeight: '300px',
};

export const searchSuggestionStyles = {
  fontSize: '15px',
  cursor: 'pointer',
  color: '#000000',
  padding: '10px 0',
};
