const fontSizes = '0.8rem';
const fontColor = '#5B5B5B';

const containerStyle = {
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '15px',
  color: fontColor,
};

const textStyle = {
  fontSize: fontSizes,
  color: fontColor,
  whiteSpace: 'nowrap',
  paddingRight: '5px',
};

const imageStyle = {
  width: '2rem',
  height: '2rem',
  maxWidth: '2rem',
  maxHeight: '2rem',
  minWidth: '1.5rem',
  minHeight: '1.5rem',
};

const nameStyle = {
  fontSize: fontSizes,
  color: fontColor,
  whiteSpace: 'nowrap',
};

const sponsorBoxStyle = {
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '15px',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const NetworkSponsorshipRenderStyle = {
  containerStyle,
  textStyle,
  imageStyle,
  nameStyle,
  sponsorBoxStyle,
};
