/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
export const educationXPData = [
  {
    label: 'Study Abroad',
    value: 'Study abroad',
    iconSrc: 'assets/profile/wholeMe/experiences/world-plane.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/world-plane.svg',
    imageAlt: 'plane over a world',
    infoUrl: 'https://www.bestcolleges.com/blog/how-to-study-abroad/',
  },
  {
    label: 'Greek System',
    value: 'Greek system',
    iconSrc: 'assets/profile/wholeMe/experiences/roman-arch.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/roman-arch.svg',
    imageAlt: 'roman arches',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=54b3e67445134d47JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIwNQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=greek+life&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvRnJhdGVybml0aWVzX2FuZF9zb3Jvcml0aWVz&ntb=1',
  },
  {
    label: 'Residential Advisor',
    value: 'Residential Advisor',
    iconSrc: 'assets/profile/wholeMe/experiences/check-list.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/check-list.svg',
    imageAlt: 'check list',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=dae2c01186858ad4JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTQ4Nw&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=Residential+advisor+college&u=a1aHR0cHM6Ly93d3cudGhvdWdodGNvLmNvbS9kb3JtLWxpZmUtd2hhdC1pcy1hbi1yYS0zNTcwMjU4Izp-OnRleHQ9QSUyMHJlc2lkZW50JTIwYWR2aXNlciUyMCVFMiU4MCU5NG9yJTIwJTIyUkElMjIlRTIlODAlOTRpcyUyMGFuJTIwdXBwZXJjbGFzc21hbiUyMHdobyxwZWVyLXRvLXBlZXIlMjBndWlkYW5jZSUyMGNhbiUyMGJlJTIwdmFsdWFibGUlMjBmb3IlMjBpbmNvbWluZyUyMGZyZXNobWVuLg&ntb=1',
  },
  {
    label: 'Student Ambassador',
    value: 'Student ambassador',
    iconSrc: 'assets/profile/wholeMe/experiences/astronaut.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/astronaut.svg',
    imageAlt: 'astronaut',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=6e95a7de58b0b795JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTQ4Ng&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=student+ambassador&u=a1aHR0cHM6Ly93d3cuZmluYWxzaXRlLmNvbS9ibG9nL3AvfmJvYXJkL2IvcG9zdC9zdHVkZW50LWFtYmFzc2Fkb3JzLXdoYXQtdGhleS1kby13aHkteW91LW5lZWQtdGhlbSM6fjp0ZXh0PUFuJTIwYWRtaXNzaW9ucyUyMGFtYmFzc2Fkb3IlMjBpcyUyMGElMjBzdHVkZW50JTIwJTI4b3IlMjBwYXJlbnQlMjElMjksYW5kJTIwY3VycmVudCUyMHN0dWRlbnQlMjBib2R5JTJDJTIwYW5kJTIwdGhvc2UlMjBjb25zaWRlcmluZyUyMGF0dGVuZGluZy4&ntb=1',
  },
  {
    label: 'Research',
    value: 'Research',
    iconSrc: 'assets/profile/wholeMe/experiences/microscope.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/microscope.svg',
    imageAlt: 'microscope',
    infoUrl: '',
  },
  {
    label: 'Athlete',
    value: 'Athlete',
    iconSrc: 'assets/profile/wholeMe/experiences/soccer-man.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/soccer-man.svg',
    imageAlt: 'soccer player',
    infoUrl: '',
  },
  {
    label: 'ROTC',
    value: 'ROTC',
    iconSrc: 'assets/profile/wholeMe/experiences/swiss-knife.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/swiss-knife.svg',
    imageAlt: 'swiss army knife',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=8b81f743b8ac61d4JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxMg&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=rotc&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvUmVzZXJ2ZV9PZmZpY2VycyUyN19UcmFpbmluZ19Db3Jwcw&ntb=1',
  },
  {
    label: "Teacher's Assistant",
    value: "Teacher's assistant",
    iconSrc: 'assets/profile/wholeMe/experiences/map-tool.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/map-tool.svg',
    imageAlt: 'map tool',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=baeec9926c4e4efcJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxNQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=teachers+assisstant&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvVGVhY2hpbmdfYXNzaXN0YW50&ntb=1',
  },
  {
    label: 'Transfer Student',
    value: 'Transfer student',
    iconSrc: 'assets/profile/wholeMe/experiences/pink-van-luggage.svg',
    imageSrc: 'assets/profile/wholeMe/experiences/pink-van-luggage.svg',
    imageAlt: 'pink van with luggage',
    infoUrl: 'https://www.bing.com/ck/a?!&&p=07c37592e6c5e1feJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxOA&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=trasnfer+student&u=a1aHR0cHM6Ly93d3cuY291cnNlcmEub3JnL2FydGljbGVzL3RyYW5zZmVyLXN0dWRlbnQ&ntb=1',
  },
];
