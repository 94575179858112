import React from 'react';
import ResourceLookupPage from 'pages/wholeme/molecules/resourceLookupPage/ResourceLookupPage';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';

const ProfileInterestsIntakeClubsPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <ResourceLookupPage
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    wholeMeClient={wholeMeClient}
    categoryId="interests"
    questionId="clubs"
    title="Clubs & Organizations"
    subtitle="Tag any affiliations you currently have or have had in the past with clubs, orgs, or non-profits. "
    dataFetcher={async () => (await wholeMeClient.fetchClubsState())?.clubs}
    dataSaver={wholeMeClient.saveClubsState}
    resourceType="clubs"
    resultAttributeId="club"
    backgroundOverlay="/assets/profile/interests/clubs&orgs-overlay.svg"
  >
    <MainImage
      imageSrc="./assets/profile/interests/clubs-image.svg"
      imageAlt="mountain"
    />
  </ResourceLookupPage>
);

export default ProfileInterestsIntakeClubsPage;
