const targetMetaKeyMap = {
  group: 'groups',
  team: 'teams',
  visibility: 'visibility',
};

export const findTargetObject = ({
  targetKey,
  targets,
  groupType,
}) => {
  const key = targetMetaKeyMap[groupType];
  return targets[key].find((item) => targetKey === item.targetKey);
};

export const buildMenuItemListMeta = (m) => {
  const { groups, teams, visibility } = m;
  const itemList = [
    ...visibility,
    { isDivider: true, label: 'Teams', render: !!teams.length },
    ...teams,
    { isDivider: true, label: 'Groups', render: !!groups.length },
    ...groups,
  ];
  return itemList;
};

export const transformTargetMeta = (targetMetaList) => {
  const groups = targetMetaList?.groups ? targetMetaList.groups.map((group) => ({
    id: group.id,
    label: group.name,
    targetType: 'group',
    targetKey: `group-${group.id}`,
  })) : [];

  const teams = targetMetaList?.teams ? targetMetaList.teams.map((team) => ({
    id: team.related_profile.id,
    label: `${team.related_profile.first_name}'s Feed`,
    targetType: 'team',
    targetKey: `team-${team.related_profile.id}`,
  })) : [];

  const visibility = targetMetaList?.visibility ? targetMetaList.visibility.map((scope) => ({
    id: scope,
    label: scope === 'all' ? 'Everyone' : scope,
    targetType: 'visibility',
    targetKey: `visibility-${scope}`,
  })) : [];

  return {
    groups,
    visibility,
    teams,
  };
};

export const getInitialTarget = ({
  targets,
  userRole = null,
}) => {
  let target = null;

  if (!userRole) {
    return target;
  }

  const { teams, groups, visibility } = targets;

  if (userRole === 'admin') {
    const found = visibility.find((v) => v.id === 'all');
    if (found) {
      target = found;
    }
  }

  if (userRole === 'backr') {
    // eslint-disable-next-line no-nested-ternary
    const found = teams.length ? teams[0] : groups.length ? groups[0] : null;
    if (found) {
      target = found;
    }
  }

  if (userRole === 'protege') {
    const found = teams[0];
    if (found) {
      target = found;
    }
  }

  return target;
};
