import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { UnderlinedTextField } from './themes';

const UnderlineTextField = ({
  control,
  defaultValue,
  error,
  name,
  placeholder,
  placeholderColor,
  required,
  rules,
  type,
  InputProps,
  onKeyUp,
  emptyFallback,
  hideErrorHelper,
  disabled,
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={{ required, ...rules }}
    render={({ field: { value, ...rest } }) => (
      <UnderlinedTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        value={!emptyFallback ? value : value || ''}
        placeholder={placeholder}
        error={!!error?.message}
        helperText={!hideErrorHelper && error?.message}
        disabled={disabled}
        InputProps={{
          inputProps: {
            autoCapitalize: type !== 'password' && 'on',
            inputMode: type,
          },
          ...InputProps,
        }}
        type={type === 'password' ? 'password' : 'text'}
        size="small"
        sx={{ '--placeholderClr': placeholderColor }}
        variant="standard"
        fullWidth
        onKeyUp={onKeyUp}
      />
    )}
  />
);

UnderlineTextField.propTypes = {
  control: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  position: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  InputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  emptyFallback: PropTypes.bool,
  hideErrorHelper: PropTypes.bool,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
};

UnderlineTextField.defaultProps = {
  control: null,
  defaultValue: '',
  error: null,
  placeholder: null,
  placeholderColor: null,
  position: 'stacked',
  required: false,
  theme: 'taupe',
  title: null,
  type: 'text',
  InputProps: null,
  rules: null,
  emptyFallback: false,
  hideErrorHelper: false,
  onKeyUp: () => {},
  disabled: false,
};

export default UnderlineTextField;
