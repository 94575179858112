import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@mui/material';
import Pill from '../Pill/Pill';

const BackrInterestsAtom = ({
  meta,
  header,
}) => (
  <>
    <Typography
      sx={{
        color: '#2d2d2d',
        fontWeight: 600,
        fontSize: '1rem',
        textAlign: 'left',
      }}
    >
      {header}
    </Typography>
    <Box
      sx={{
        '--gap': '8px',
        display: 'flex',
        gap: 'var(--gap)',
        mt: 1.5,
      }}
    >
      {meta.map((data) => (
        <Pill key={data} label={data} pillStylesOverride={{ backgroundColor: '#E8E8DC', color: '#848484', fontSize: '10px' }} />
      ))}
    </Box>
  </>
);

BackrInterestsAtom.propTypes = {
  header: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.string),
};

BackrInterestsAtom.defaultProps = {
  meta: [],
};

export default BackrInterestsAtom;
