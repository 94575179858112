import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { deriveUserRole } from '../utils';

const AuthorName = ({
  author,
  aliasAdminAsSystem,
}) => {
  const { role, first_name: firstName, last_name: lastName } = author;
  const style = { color: '#063d8f', fontWeight: 700 };
  const derivedRole = deriveUserRole({ role, aliasAdminAsSystem });

  const friendlyName = derivedRole === 'admin'
    ? 'Coach Lubav'
    : `${firstName} ${lastName.charAt(0)}.`;

  return (
    <Typography sx={style}>
      {friendlyName}
    </Typography>
  );
};

AuthorName.propTypes = {
  aliasAdminAsSystem: PropTypes.bool,
  author: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

AuthorName.defaultProps = {
  aliasAdminAsSystem: true,
};

export default AuthorName;
