import React from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import useFetch from 'use-http';
import UnlockScreen from '../../../../common/molecules/UnlockScreen';
import { useAuthContext } from '../../../../providers/auth-provider';
import appStrings from '../../../../common/app_strings';

const LockedProfile = ({ variant }) => {
  const history = useHistory();
  const { reported, user } = useAuthContext();
  const { post, response } = useFetch();
  const { profile: { unlockProfile, blockedProfile, unavailableProfile } } = appStrings;

  const backgroundCheckCallback = () => {
    history.push('/tabs/backr/background-check-v2');
  };

  const getConvoSidForLubavMessage = async () => {
    let conversationSid = null;
    try {
      const res = await post(`/conversations/request-sid/${user?.id}/community`);
      if (response.ok && res?.sid) {
        conversationSid = res.sid;
      } else {
        throw new Error('request failed');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
      throw error;
    }
    return conversationSid;
  };

  const handleMessageLubavClick = async () => {
    try {
      const convoSid = await getConvoSidForLubavMessage();
      if (convoSid) {
        history.push(`/chat/${convoSid}`);
      }
    } catch (error) {
      toast.error('Direct Messaging Unavailable. Try again later.');
    }
  };

  const variantLookup = {
    blocked: {
      variant: 'profile',
      title: blockedProfile.title,
      body: blockedProfile.body,
      image: 'assets/profile/wholeMe/locked-profile/locked-profile.svg',
      userReported: reported,
    },
    unlock: {
      variant: 'profile',
      title: unlockProfile.title,
      body: unlockProfile.body,
      image: 'assets/profile/wholeMe/locked-profile/locked-profile.svg',
      buttonMeta: {
        label: unlockProfile.buttonLabel,
        callback: backgroundCheckCallback,
      },
      userReported: reported,
    },
    unavailable: {
      variant: 'profile',
      title: unavailableProfile.title,
      body: unavailableProfile.body,
      image: 'assets/profile/wholeMe/locked-profile/locked-profile.svg',
      buttonMeta: {
        label: unavailableProfile.cta,
        callback: handleMessageLubavClick,
      },
    },
  };

  const meta = variantLookup[variant] || variantLookup.unlock;

  return (
    <UnlockScreen meta={meta} />
  );
};

export default LockedProfile;
