export const financesProfileData = [
  {
    label: 'Homeowner',
    value: 'Homeowner',
    imageSrc: 'assets/profile/finances/home-owner.svg',
    imageAlt: 'House',
    infoUrl: '',
  },
  {
    label: 'Family/Small Business Owner',
    value: 'Family/Small Business Owner',
    imageSrc: 'assets/profile/finances/business-owner.svg',
    imageAlt: 'Store',
    infoUrl: 'https://www.indeed.com/career-advice/starting-new-job/how-to-become-small-business-owner',
  },
  {
    label: 'Retirement Account(s)',
    value: 'Retirement Account(s)',
    imageSrc: 'assets/profile/finances/retirement-account.svg',
    imageAlt: 'Dollar Bill',
    infoUrl: 'https://www.investopedia.com/articles/personal-finance/091114/build-your-own-retirement-plan.asp',
  },
  {
    label: 'Multiple Income Streams',
    value: 'Multiple Income Streams',
    imageSrc: 'assets/profile/finances/multiple-income-streams.svg',
    imageAlt: 'Coin',
    infoUrl: 'https://www.forbes.com/sites/stephanieburns/2020/06/06/5-ways-to-create-multiple-streams-of-income/?sh=7bdca1d56869',
  },
  {
    label: 'Real Estate Investor',
    value: 'Real Estate Investor',
    imageSrc: 'assets/profile/finances/real-estate-investor.svg',
    imageAlt: 'House',
    infoUrl: 'https://www.forbes.com/advisor/investing/how-to-invest-in-real-estate/',
  },
];
