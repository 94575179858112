import React from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonContent,
} from '@ionic/react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './Celebration.css';

const Celebration = ({ history, location }) => (
  <IonPage>
    <IonHeader className="ion-no-border">
      <IonToolbar
        className="back-button-toolbar"
        style={{ '--background': '#03295F' }}
      >
        <IonButtons slot="start">
          <IonBackButton
            icon="/assets/back-arrow-white.svg"
            style={{
              '--icon-font-size': '1.25rem',
              '--icon-padding-start': '8px',
              '--icon-padding-end': '12px',
              '--icon-padding-top': '4px',
              '--icon-margin-end': '6px',
              '--color': '#fff',
            }}
            className="back-button-custom"
            mode="ios"
          />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent
      style={{
        '--background': '#03295F',
        '--padding-start': '32px',
        '--padding-end': '32px',
      }}
      fullscreen
    >
      <Typography
        color="#fff"
        fontSize={24}
        fontWeight={700}
        sx={{ textAlign: 'center' }}
      >
        Congratulations!
      </Typography>
      <Typography sx={{ color: '#fff', textAlign: 'center', mt: 1 }}>
        You achieved your goal!
      </Typography>
      <Typography sx={{ color: '#fff', textAlign: 'center' }}>
        Great job on your hard work and dedication.
      </Typography>
      <div className="goals-celebration-img-wrapper">
        <img
          src="/assets/takeoff.svg"
          alt="rocketship taking off"
          className="goals-celebration-img"
        />
      </div>
      <Typography
        sx={{
          color: '#F7AE41',
          fontSize: 22,
          fontWeight: 700,
          textAlign: 'center',
          px: 2,
          mt: 6,
        }}
      >
        {location?.state?.type === 'health' && 'I improved my physical health!'}
        {location?.state?.type === 'academic' && 'I achieved my academic goal!'}
        {location?.state?.type === 'passion' && 'I achieved my passion goal!'}
        {location?.state?.type === 'career' && 'I improved my career outlook!'}
      </Typography>
      <Typography
        color="#fff"
        fontSize={14}
        sx={{ textAlign: 'center', px: 2, mt: 3 }}
      >
        Consider sharing an update with your teamates to let them know about
        this incredible achievement!
      </Typography>
      {location?.state?.post?.id ? (
        <IonButton
          className="goals-celebration-share"
          style={{ '--color': '#fff', '--border-color': '#fff' }}
          size="large"
          expand="block"
          shape="round"
          fill="outline"
          onClick={() => history.push(`/posts/${location?.state?.post?.id}`)}
        >
          Share an Update
        </IonButton>
      ) : (
        <IonButton
          className="goals-celebration-share"
          style={{ '--color': '#fff', '--border-color': '#fff' }}
          size="large"
          expand="block"
          shape="round"
          fill="outline"
          onClick={() => history.goBack()}
        >
          Return to Goals
        </IonButton>
      )}
    </IonContent>
  </IonPage>
);

Celebration.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default Celebration;
