export const relationshipXpData = [
  {
    label: 'Mentored or coached others',
    value: 'Mentored or coached others',
    imageAlt: 'Baseball cap',
    imageSrc: 'assets/profile/wholeMe/experiences/orange-cap.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/orange-cap.svg',
  },
  {
    label: 'Moderated conflict',
    value: 'Moderated conflict',
    imageAlt: 'Two hands inside of a heart',
    imageSrc: 'assets/profile/wholeMe/experiences/crossed-heart.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/crossed-heart.svg',
  },
  {
    label: 'Had a long-term relationship',
    value: 'Had a long-term relationship',
    imageAlt: 'Blossoming Heart',
    imageSrc: 'assets/profile/wholeMe/experiences/tri-heart.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/tri-heart.svg',
  },
  {
    label: 'Difficult break-up',
    value: 'Difficult break-up',
    imageAlt: 'Sad face inside heart',
    imageSrc: 'assets/profile/wholeMe/experiences/sad-orange-heart.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/sad-orange-heart.svg',
  },
  {
    label: 'Sustained a friendship',
    value: 'Sustained a friendship',
    imageAlt: 'Two friends smiling',
    imageSrc: 'assets/profile/wholeMe/experiences/embrace-friend.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/embrace-friend.svg',
  },
  {
    label: 'Bullying/Abuse',
    value: 'Bullying/Abuse',
    imageAlt: 'Cracked Heart',
    imageSrc: 'assets/profile/wholeMe/experiences/real-heart.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/real-heart.svg',
  },
  {
    label: 'Death of a loved one',
    value: 'Death of a loved one',
    imageAlt: 'Tomb Stone',
    imageSrc: 'assets/profile/wholeMe/experiences/gravestone.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/gravestone.svg',
  },
  {
    label: 'Been a parent/guardian',
    value: 'Been a parent/guardian',
    imageSrc: 'assets/profile/wholeMe/experiences/baby-lift.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/baby-lift.svg',
  },
  {
    label: 'Been an Aunt/Uncle/Godparent',
    value: 'Been an Aunt/Uncle/God-parent',
    imageSrc: 'assets/profile/wholeMe/experiences/embrace.svg',
    iconSrc: 'assets/profile/wholeMe/experiences/embrace.svg',
  },
];
