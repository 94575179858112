/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import appStrings from 'common/app_strings';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import { excitedAgeGroups } from '../../../pages/excited_age_groups';
import SelectableItemsList from '../../../common/molecules/selectableItemsList/SelectableItemsList';

const {
  matchingQuiz: {
    header, subheader,
  },
} = appStrings;

const childrenStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const ProtegePreferencesPage = ({
  nextOnClick,
  prevOnClick,
  preferences,
  onSubFormSubmit,
  paginationMeta,
  exitIconOnClickOverride,
}) => {
  const [selectedFieldsState, setSelectedFieldsState] = useState(preferences?.age_group);

  const mainImageData = {
    imageSrc: './assets/profile/wholeMe/matching/grad-cap.svg',
    imageAlt: 'grad cap',
  };

  const mainImage = (
    <img
      src={mainImageData.imageSrc}
      alt={mainImageData.imageAlt}
      style={{
        width: '200px',
        objectFit: 'contain',
      }}
    />
  );

  const saveField = () => {
    onSubFormSubmit('age_group', selectedFieldsState.length === 0 ? ['No Preference'] : selectedFieldsState);
    nextOnClick();
  };

  const onListChange = (fieldValue) => {
    if (fieldValue) {
      setSelectedFieldsState(fieldValue);
    } else {
      setSelectedFieldsState(preferences?.age_group);
    }
  };

  useEffect(() => {
    if (preferences?.age_group.length > 0) {
      setSelectedFieldsState(preferences.age_group);
    }
  }, [preferences]);

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={header}
      subtitle={subheader}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: saveField }}
      backgroundOverlay="/assets/profile/matching/protege-preferences-overlay.svg"
      isSplashPage
      splashPictureAlignsBottom
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      {!selectedFieldsState ? (
        <WholeMeLoadingCircle />
      ) : (
        <>
          <SelectableItemsList
            activeItems={selectedFieldsState}
            items={excitedAgeGroups}
            itemVariant="oval"
            onChange={(fieldValue) => onListChange(fieldValue)}
            selectionType="single"
            listStylesOverride={{
              width: '100%',
              display: 'inline-grid',
              gap: '15px',
            }}
          />
          <div style={childrenStyles}>
            {mainImage}
          </div>
        </>
      )}
    </ProfileIntakeStepPage>
  );
};

export default ProtegePreferencesPage;
