import React, { useState } from 'react';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import {
  Typography, styled, TextField, Stack,
} from '@mui/material';
import {
  inputFieldStyles,
  lightThemeStyles,
} from 'common/atoms/inputField/inputFieldStyles';
import appStrings from '../../../common/app_strings';

const mainImageData = {
  imageSrc: './assets/profile/matching/connected-people-bubbles-2.svg',
  imageAlt: 'connected people',
};

const Input = styled(TextField)({
  ...inputFieldStyles,
  ...lightThemeStyles,
  '& .MuiInputBase-input::placeholder': {
    textAlign: 'center',
    color: '#9C9C9C',
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
  width: '75%',
  fontWeight: 400,
  letterSpacing: '24px',
  color: '#2D2D2D',
  fontSize: '24px',
});

const noCodeStyle = {
  color: '#063D8F',
  textAlign: 'center',
  fontWeight: 700,
  paddingTop: '10px',
};

const MainImageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const {
  matchingQuiz: {
    cohortCodePage: {
      title, subtitle, noCode, placeholder,
    },
  },
} = appStrings;

const CohortCodePage = ({
  prevOnClick,
  onSubmitFinal,
  nextOnClick,
  onSubFormSubmit,
  paginationMeta,
  errors,
  clearErrors,
  exitIconOnClickOverride,
}) => {
  const [cohortCode, setCohortCode] = useState('');

  const handleChange = (e) => {
    if (errors?.preferences?.match_code?.message) {
      clearErrors();
    }
    onSubFormSubmit('match_code', e.target.value);
    setCohortCode(e.target.value);
  };

  const handleBackButton = () => {
    if (errors?.preferences?.match_code?.message) {
      clearErrors();
    }
    prevOnClick();
  };

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title={title}
      subtitle={subtitle}
      prevButton={{ onClick: handleBackButton }}
      nextButton={{ onClick: nextOnClick }}
      backgroundOverlay="assets/profile/matching/cohort-code-overlay.svg"
      styles
      exitIconOnClickOverride={exitIconOnClickOverride}
      titleStyleOverride={{
        subHeaderStyles: {
          fontSize: '23px',
        },
      }}
    >
      <Stack style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Input
          placeholder={placeholder}
          value={cohortCode}
          onChange={(e) => handleChange(e)}
          inputProps={{ maxLength: 6 }}
          error={!!errors?.preferences?.match_code.message}
          helperText={(
            <div style={{
              textAlign: 'center',
              letterSpacing: 'normal',
              fontWeight: 700,
            }}
            >
              {errors?.preferences?.match_code.message}
            </div>
          )}
        />
        <Typography onClick={onSubmitFinal} style={noCodeStyle}>{noCode}</Typography>
      </Stack>
      <MainImageContainer>
        <img src={mainImageData.imageSrc} alt={mainImageData.imageAlt} />
      </MainImageContainer>

    </ProfileIntakeStepPage>
  );
};

export default CohortCodePage;
