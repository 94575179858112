import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';

const splashImage = {
  src: './assets/profile/education/education-splash.svg',
  alt: 'big book',
  styles: {
    width: '60%',
  },
};

const profileEducationIntakeSplashPage = ({ nextOnClick, prevOnClick }) => (
  <ProfileIntakeSplashPage
    title="Education"
    subtitle="Share your professional journey so that proteges can directly benefit from your experience."
    splashImage={splashImage}
    nextButton={{ label: "Let's Go", onClick: nextOnClick }}
    prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    backgroundOverlay="/assets/profile/education/education-splash-overlay.svg"
  />
);

export default profileEducationIntakeSplashPage;
