import React, { useState } from 'react';
import {
  Box, Stack, Typography, styled,
} from '@mui/material';
import { EditIcon, PlusIcon } from 'pages/wholeme/icons/Icons';
import { sectionStyle } from './wholeMeProfileSectionStyles';

const Spacer = styled('span')({
  float: 'right',
  height: '32px',
  width: '79px',
});

const headerStyles = {
  width: '100%',
  height: '40px',
  position: 'relative',
};

const iconStyles = {
  position: 'absolute',
  top: 9,
  right: 15,
  minHeight: '67px',
  display: 'flex',
};

const expandedHeaderStyle = {
  color: '#2D2D2D',
  fontSize: '18px',
  fontWeight: '700',
};

const RenderExpanded = ({
  title, onTitleClick, setIsExpanded, children, titleStyle, headerStyle,
}) => (
  <>
    {title && (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{ ...headerStyles, ...headerStyle }}
    >
      <Box
        onClick={() => {
          if (onTitleClick) {
            onTitleClick();
          } else {
            setIsExpanded(false);
          }
        }}
      >
        <Typography variant="h6" style={{ ...titleStyle, ...expandedHeaderStyle }}>{title}</Typography>
      </Box>
    </Stack>
    )}
    {children}
  </>
);

const RenderCollapsed = ({
  editable, isEmpty, title,
}) => (
  <Stack direction="row" alignItems="center">
    {editable && (
      isEmpty ? <PlusIcon marginTop={2} /> : <EditIcon marginTop={2} />
    )}
    <Typography sx={{ color: '#2d2d2d', fontWeight: 600 }} marginLeft="12px" variant="h5">{title}</Typography>
  </Stack>
);

const WholeMeProfileSection = ({
  title,
  titleSx,
  headerSx,
  titleIcon,
  children,
  expanded,
  onTitleClick,
  editable,
  isEmpty,
  expandedBorder = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const handleClick = () => {
    if (!isExpanded) {
      if (onTitleClick) {
        onTitleClick();
      }
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Box
      sx={{
        ...sectionStyle,
        ...(!isExpanded && { display: 'flex' }),
        ...(isExpanded && expandedBorder && editable && { outline: '3px solid #FD5C36' }),
      }}
      onClick={handleClick}
    >
      { isExpanded ? (
        <RenderExpanded
          title={title}
          titleStyle={titleSx}
          headerStyle={headerSx}
          onTitleClick={onTitleClick}
          setIsExpanded={setIsExpanded}
        >
          {!expanded && <Spacer />}
          {children}
        </RenderExpanded>
      ) : (
        <RenderCollapsed
          title={title}
          editable={editable}
          isEmpty={isEmpty}
        />
      )}
      <span onClick={() => setIsExpanded(!isExpanded)} style={iconStyles}>
        {titleIcon}
      </span>
    </Box>
  );
};

export default WholeMeProfileSection;
