import React from 'react';
import PropTypes from 'prop-types';
import SplashLoadingContent from 'pages/matching/MatchOptimization/SplashLoadingContent';

const titleStyle = {
  color: 'white',
  fontSize: '32px',
  fontWeight: 700,
  textAlign: 'center',
};

const SplashLoadingPage = ({
  title,
  backgroundOverlay,
  splashImage,
}) => (
  <SplashLoadingContent
    title={title}
    titleStylesOverride={titleStyle}
    backgroundOverlay={backgroundOverlay}
    backgroundColor="#063D8F"
  >
    <div style={{ textAlign: 'center' }}>
      {splashImage?.src && (
      <img src={splashImage?.src} alt={splashImage?.alt} style={splashImage?.styles} />
      )}
    </div>
  </SplashLoadingContent>
);

export const SplashLoadingContentPropTypes = {
  title: PropTypes.string,
  backgroundOverlay: PropTypes.string,
  splashImage: PropTypes.shape(
    {
      src: PropTypes.string,
      alt: PropTypes.string,
      styles: PropTypes.oneOfType([PropTypes.object]),
    },
  ),
};

export const SplashLoadingContentDefaultProps = {
  title: '',
  backgroundOverlay: '',
  splashImage: {
    src: '',
    alt: '',
    styles: {},
  },
};

SplashLoadingPage.propTypes = SplashLoadingContentPropTypes;
SplashLoadingPage.defaultProps = SplashLoadingContentDefaultProps;

export default SplashLoadingPage;
