import React, { useState } from 'react';
import {
  IonPage, IonContent, IonHeader, IonToolbar,
} from '@ionic/react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import appStrings from '../../../common/app_strings';
import useServiceLogger from '../../../hooks/userServiceLogger';
import pdfPaths from '../../../common/pdf_paths';
import './Pdfs.css';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import PdfDocument from './PdfDocument';

const { checkrFormScreen } = appStrings;

const FairCredit = () => {
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { platform } = useAppState();

  const { serviceLogger } = useServiceLogger();
  const fairCreditPdf = pdfPaths('checkrFairCredit');

  const { formData, pdfs } = location?.state || {};
  const fairCreditInfo = pdfs.find(
    (userConsent) => userConsent.consentFormName === 'Fair Credit',
  );

  const goToFeed = () => {
    history.push('/tabs/feed');
  };

  async function onDocumentLoadSuccess({ numPages }) {
    // eslint-disable-next-line no-underscore-dangle
    setTotalPages(numPages?._pdfInfo?.numPages);
    await serviceLogger({
      key: 'checkr',
      header: 'Checkr Fair Credit Consent Screen',
      message: 'React-Pdf Document Loaded Checkr Fair Credit PDF',
    });
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleAcceptedTerms = (event) => setHasAcceptedTerms(event.target.checked);

  const handleSubmit = async () => {
    const routeProps = {
      pdfs,
      consents: { fair_credit: fairCreditInfo?.userConsentFormId },
    };

    await serviceLogger({
      key: 'checkr',
      header: 'Checkr Fair Credit Consent Screen',
      message: `User Accepted Checkr Fair Credit PDF: ${JSON.stringify(routeProps)}`,
    });
    history.push('/checkr-disclosure', { ...routeProps, formData });
  };

  const handleDocumentLoadError = async ({
    error,
  }) => {
    Sentry.captureException(error);
    await serviceLogger({
      key: 'checkr',
      type: 'error',
      header: 'Checkr Fair Credit Consent Screen',
      error: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : null,
    });
  };

  const RenderDocument = (
    <PdfDocument
      pdfDocument={fairCreditPdf}
      onDocumentLoadSuccess={async (numPages) => onDocumentLoadSuccess({ numPages })}
      handleDocumentLoadError={handleDocumentLoadError}
      pageNumber={pageNumber}
    />
  );

  const RenderNav = (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      bgcolor="#fff"
      py={1}
      px={1.5}
      borderRadius={2}
      zIndex={10}
    >
      <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
        <ArrowBack />
      </IconButton>
      <Typography>
        {`Page ${pageNumber || (totalPages ? 1 : '--')} of ${totalPages || '--'
        }`}
      </Typography>
      <IconButton disabled={pageNumber >= totalPages} onClick={nextPage}>
        <ArrowForward />
      </IconButton>
    </Stack>
  );

  const RenderStickyFooter = (
    <footer style={{
      color: 'gray',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
    >
      {RenderNav}
    </footer>
  );

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="" style={{ '--background': '#1CB7A4' }}>
          <Box
            sx={{
              bgcolor: '#1CB7A4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              pt: 3,
            }}
          >
            <img src="/assets/checkr-logo.png" alt="Checkr logo" />
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Stack
          sx={{
            bgcolor: '#fff',
            alignItems: 'center',
            pt: 2,
            pb: 4,
            px: 4,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          {platform?.isNativePlatform ? (
            <>
              <TransformWrapper>
                <TransformComponent>
                  {RenderDocument}
                </TransformComponent>
              </TransformWrapper>
              {RenderNav}
            </>
          ) : RenderDocument}
          <Stack spacing={2} alignItems="center" mt={2} zIndex={10}>
            <FormControlLabel
              label={(
                <Typography
                  sx={{
                    color: '#9c9c9c',
                    textTransform: 'uppercase',
                    letterSpacing: 2,
                  }}
                >
                  {checkrFormScreen.rightsLabel}
                </Typography>
              )}
              control={(
                <Checkbox
                  checked={hasAcceptedTerms}
                  onChange={handleAcceptedTerms}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <Button
              color="green"
              variant="contained"
              disableElevation
              disableRipple
              disabled={!hasAcceptedTerms}
              sx={{
                color: '#fff',
                fontWeight: 700,
                letterSpacing: 2,
                borderRadius: '100px',
                display: 'inline-block',
                mx: 'auto',
              }}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
        <Button
          disableRipple
          sx={{
            color: '#9c9c9c',
            display: 'block',
            textTransform: 'initial',
            mt: 1,
            mb: platform?.isNativePlatform ? 5 : 10,
            mx: 'auto',
          }}
          onClick={goToFeed}
        >
          {checkrFormScreen.continueExploring}
        </Button>
      </IonContent>
      {!platform?.isNativePlatform && RenderStickyFooter}
    </IonPage>
  );
};

export default FairCredit;
