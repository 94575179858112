import useFetch from 'use-http';
import version from 'version/version';
import { Capacitor } from '@capacitor/core';

const useVersionCompatibility = () => {
  const { get, response } = useFetch();
  const appVersion = version;

  const checkVersionCompatibility = async () => {
    try {
      const supportedVersionData = await get('/version/supported-version');
      if (response.status !== 200) {
        throw new Error('Error getting supported version');
      }

      const supportedVersions = supportedVersionData?.supportedVersions.map((v) => v.toLowerCase());
      const isAnyVersion = supportedVersions.includes('any');
      const isVersionCompatible = supportedVersions.includes(appVersion);

      // set app store url for mobile
      const currentPlatform = Capacitor.getPlatform();
      const appStoreUrl = supportedVersionData[currentPlatform];

      if (isAnyVersion || isVersionCompatible) {
        return { isCompatible: true, appStoreUrl };
      }

      console.log(`Client version: ${appVersion} is not compatible with server versions: ${supportedVersions}`);
      return { isCompatible: false, appStoreUrl };
    } catch (e) {
      console.error('Error checking version compatibility:', e);
      throw e;
    }
  };

  return checkVersionCompatibility;
};

export default useVersionCompatibility;
