import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import { IonLabel } from '@ionic/react';

export const LightTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  '& .MuiFilledInput-root': {
    background: '#fff',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    borderRadius: 8,
    '& fieldset': {
      borderColor: '#c3c3b9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
});

export const TaupeTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  '& .MuiFilledInput-root': {
    background: '#f4f4ee',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#f4f4ee',
    borderRadius: 8,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#2d2d2d',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
});

const StyledInput = ({
  textarea,
  name,
  control,
  placeholder,
  title,
  error,
  position,
  type,
  defaultValue,
  required,
  theme,
  capitalize,
}) => (
  <div>
    <IonLabel style={{ color: '#2d2d2d', lineHeight: 1.25 }} position={position}>
      {title}
    </IonLabel>
    {textarea ? (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field }) => (theme === 'light' ? (
          <LightTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            placeholder={placeholder}
            error={!!error.message}
            helperText={error?.message}
            inputProps={{ autoCapitalize: capitalize ? 'on' : 'off', inputMode: type }}
            type={type === 'password' ? 'password' : 'text'}
            multiline
            fullWidth
            minRows={2}
            maxRows={4}
          />
        ) : (
          <TaupeTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            placeholder={placeholder}
            error={!!error.message}
            helperText={error?.message}
            inputProps={{ autoCapitalize: capitalize ? 'on' : 'off', inputMode: type }}
            type={type === 'password' ? 'password' : 'text'}
            multiline
            fullWidth
            minRows={2}
            maxRows={4}
          />
        ))}
      />
    ) : (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field }) => (theme === 'light' ? (
          <LightTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            placeholder={placeholder}
            error={!!error.message}
            helperText={error?.message}
            inputProps={{ autoCapitalize: capitalize ? 'on' : 'off', inputMode: type }}
            type={type === 'password' ? 'password' : 'text'}
            size="small"
            fullWidth
          />
        ) : (
          <TaupeTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            placeholder={placeholder}
            error={!!error.message}
            helperText={error?.message}
            inputProps={{ autoCapitalize: capitalize ? 'on' : 'off', inputMode: type }}
            type={type === 'password' ? 'password' : 'text'}
            size="small"
            fullWidth
          />
        ))}
      />
    )}
  </div>
);

StyledInput.propTypes = {
  textarea: PropTypes.bool,
  name: PropTypes.string.isRequired,
  control: PropTypes.oneOfType([PropTypes.any]),
  placeholder: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.any]),
  position: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.string,
  capitalize: PropTypes.bool,
};

StyledInput.defaultProps = {
  textarea: false,
  control: null,
  placeholder: '',
  title: '',
  error: {},
  position: 'stacked',
  type: null,
  defaultValue: '',
  required: false,
  theme: 'light',
  capitalize: false,
};

export default StyledInput;
