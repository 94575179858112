import React, { useState } from 'react';
import { Box } from '@mui/material';
import Pill from 'common/atoms/Pill/Pill';
import {
  selectableStyles,
  selectedStyles,
  containerStyles,
  showMoreButtonStyles,
} from './pillRendererStyles';

const getSelectableStyles = (isSelected) => (isSelected ? { ...selectableStyles, ...selectedStyles } : selectableStyles);

const PillRenderer = ({
  items,
  activeItems,
  handlePillClick,
  pillStyles,
  isSelectable,
  containerStyleOverrides,
  showMoreEnabled,
}) => {
  const [showAllOptions, setShowAllOptions] = useState(false);

  if (items?.length < 1) return null;

  const selectable = isSelectable !== undefined ? isSelectable : !!handlePillClick;
  const renderContainerStyles = containerStyleOverrides || containerStyles;

  const isShowMoreEnabled = showMoreEnabled && items.length > 9;
  const renderableItems = (isShowMoreEnabled && !showAllOptions) ? items.slice(0, 9) : items;
  const showMoreButtonText = showAllOptions ? 'show less' : 'show more';

  return (
    <Box sx={renderContainerStyles}>
      {renderableItems.map((item) => {
        const { value: itemValue, clickContext } = item || {};
        const value = itemValue || item;
        const isSelected = selectable && activeItems?.includes(value);
        const pillStylesOverride = selectable
          ? { ...pillStyles, ...getSelectableStyles(isSelected) }
          : pillStyles;

        return (
          <Pill
            key={value}
            label={value}
            pillStylesOverride={pillStylesOverride}
            onClick={handlePillClick ? () => handlePillClick(value, clickContext) : null}
            isSelectable={selectable}
            isSelected={isSelected}
          />
        );
      })}
      {
        isShowMoreEnabled
        && (
        <button
          style={showMoreButtonStyles}
          onClick={() => setShowAllOptions(!showAllOptions)}
          type="button"
        >
          {showMoreButtonText}
        </button>
        )
      }
    </Box>
  );
};

export default PillRenderer;
