/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import RoleIndicator from './RoleIndicator';

const AvatarAdmin = ({ small, style, subComment }) => (
  <div
    className="avatar-role-wrapper"
    style={style}
    role="button"
    tabIndex={0}
  >
    <Avatar
      src="/assets/backr-logo-white-blue-bckground.png"
      sx={{
        fontFamily: "'Sarabun', arial, sans-serif",
        width: 40,
        height: 40,
        mx: 'auto',
        ...(small && { width: 30, height: 30 }),
        ...(subComment && { width: 25, height: 25, fontSize: '12px' }),
      }}
    >
      CL
    </Avatar>
    <RoleIndicator
      userRole="admin"
      style={{
        position: 'absolute',
        ...(small
          ? {
            bottom: '-4px', right: '-1px', width: 14, height: 14, fontSize: '0.5625rem',
          }
          : { bottom: '-2px', right: '-5px' }),
        ...(subComment
          ? {
            bottom: '-4px', right: '-1px', width: 12, height: 12, fontSize: '0.5rem',
          }
          : { bottom: '-2px', right: 0 }),
      }}
    />
  </div>
);

AvatarAdmin.propTypes = {
  small: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  subComment: PropTypes.bool,
};

AvatarAdmin.defaultProps = {
  small: false,
  style: {},
  subComment: false,
};

export default AvatarAdmin;
