import { useEffect } from 'react';
import useInterval from '../../../hooks/useInterval';

const AdminIntervalWatcher = ({
  callback = () => {},
  intervalCallback = () => {},
  interval = 30000,
}) => {
  useEffect(() => {
    callback();
  }, []);
  useInterval(() => {
    intervalCallback();
  }, interval);
  return null;
};

export default AdminIntervalWatcher;
