import React from 'react';
import {
  IonCol, IonGrid, IonRow, IonPage, IonContent,
} from '@ionic/react';

// v1 of webview shell will render children inside of the center column of a 3 column layout
const WebViewShell = ({ children }) => (
  <IonPage>
    <IonContent fullscreen>
      <IonGrid className="ion-no-padding" style={{ height: '100%', padding: 0 }}>
        <IonRow style={{ height: '100%' }}>
          <IonCol style={{ height: '100%' }}>{/* Left sidebar content can go here */}</IonCol>
          <IonCol size="6" className="primary-web-content">
            {/* This is where children/primary content will be rendered, with max-width */}
            {children}
          </IonCol>
          <IonCol style={{ height: '100%' }}>{/* Right sidebar content can go here */}</IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>
);

export default WebViewShell;
