import React from 'react';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { Box, Stack, Typography } from '@mui/material';
import { createOutline } from 'ionicons/icons';
import { possiblePublicInfo } from './accountData';

const AccountSettings = ({ user }) => (
  <Box
    sx={{
      backgroundColor: '#fff',
      p: 2,
      borderRadius: 2,
    }}
  >
    <IonRouterLink routerLink="/tabs/profile/edit-account-info">
      <Stack spacing={1} direction="row" alignItems="center">
        <h2 className="profile-category-header">Account Settings</h2>
        <IonIcon
          className="edit-profile-svg"
          color="warning"
          slot="start"
          icon={createOutline}
        />
      </Stack>
    </IonRouterLink>
    <Typography sx={{ fontSize: 12, color: '#03295f', fontWeight: 500 }}>
      Info used for backrs comms, not shared with any other community members.
    </Typography>
    <Stack spacing={0.5} sx={{ ml: 1, mt: 1 }}>
      {Object.keys(possiblePublicInfo).map((info) => {
        if (info === 'first_name') {
          return (
            user[info] && (
              <Stack key={info} direction="row" spacing={1} alignItems="baseline">
                <IonIcon
                  className="clr-dark-taupe"
                  style={{
                    flexShrink: 0,
                    position: 'relative',
                    top: 3,
                  }}
                  icon={possiblePublicInfo[info].icon}
                />
                <Typography
                  className="clr-charcoal"
                  sx={{
                    rowGap: 2,
                    fontSize: 12,
                  }}
                >
                  {`${user[info]} ${user.last_name}`}
                </Typography>
              </Stack>
            )
          );
        }
        if (info === 'birthday') {
          return (
            user[info] && (
              <Stack key={info} direction="row" spacing={1} alignItems="baseline">
                <IonIcon
                  className="clr-dark-taupe"
                  style={{
                    flexShrink: 0,
                    position: 'relative',
                    top: 3,
                  }}
                  icon={possiblePublicInfo[info].icon}
                />
                <Typography
                  className="clr-charcoal"
                  sx={{
                    rowGap: 2,
                    fontSize: 12,
                  }}
                >
                  {user[info]}
                </Typography>
              </Stack>
            )
          );
        }
        if (info === 'phone') {
          return (
            user[info] && (
              <Stack key={info} direction="row" spacing={1} alignItems="baseline">
                <IonIcon
                  className="clr-dark-taupe"
                  style={{
                    flexShrink: 0,
                    position: 'relative',
                    top: 3,
                  }}
                  icon={possiblePublicInfo[info].icon}
                />
                <Typography
                  className="clr-charcoal"
                  sx={{
                    rowGap: 2,
                    fontSize: 12,
                  }}
                >
                  {`${user[info]}`}
                </Typography>
              </Stack>
            )
          );
        }
        if (info === 'email') {
          return (
            user[info] && (
              <Stack key={info} direction="row" spacing={1} alignItems="baseline">
                <IonIcon
                  className="clr-dark-taupe"
                  style={{
                    flexShrink: 0,
                    position: 'relative',
                    top: 3,
                  }}
                  icon={possiblePublicInfo[info].icon}
                />
                <Typography
                  className="clr-charcoal"
                  sx={{
                    rowGap: 2,
                    fontSize: 12,
                  }}
                >
                  {`${user[info]}`}
                </Typography>
              </Stack>
            )
          );
        }
        return [];
      })}
    </Stack>
  </Box>
);

export default AccountSettings;
