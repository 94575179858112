import React from 'react';
import PropTypes from 'prop-types';
import { IonPage, IonContent } from '@ionic/react';

const IonicProviderPageShell = ({ children, header, footer }) => (
  <IonPage>
    {header}
    <IonContent>{children}</IonContent>
    {footer}
  </IonPage>
);

export const IonicProviderPageShellPropTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export const IonicProviderPageShellDefaultProps = {
  header: null,
  footer: null,
};

IonicProviderPageShell.propTypes = IonicProviderPageShellPropTypes;
IonicProviderPageShell.defaultProps = IonicProviderPageShellDefaultProps;

export default IonicProviderPageShell;
