import React from 'react';
import WholeMeProfileVideoPlayer from 'pages/wholeme/molecules/wholeMeProfileVideoPlayer/WholeMeProfileVideoPlayer';
import { firstOf } from 'pages/wholeme/util/profile_util';

const ShowView = ({ profile }) => {
  if (!profile) {
    return null;
  }
  const purposeVideo = firstOf(profile?.purpose_video);
  if (!purposeVideo?.uri) {
    return null;
  }
  return (
    <WholeMeProfileVideoPlayer
      videoUrl={purposeVideo.uri}
    />
  );
};

export default ShowView;
