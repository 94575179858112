/* eslint-disable camelcase */
// eslint-disable-next-line import/prefer-default-export
import { fieldLabelFor, fieldIconSrcFor } from '../../util/profile_field_data';

export const buildBarItems = (itemMeta, onClickCallBack) => {
  // filtering out dupe greek system
  const filteredArr = itemMeta.filter((item) => {
    if (item?.option_id === 'Greek system' && item?.suboption_id) {
      return false;
    }
    return true;
  });
  const shuffledArr = filteredArr
    .map((i) => ({ i, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ i }) => i);
  const getExp = (item) => ({
    option_id: item.option_id,
    text: fieldLabelFor(item.option_id, item.category_id, item.question_id, item.suboption_id),
    value: item.option_id,
    type: item.question_id,
    icon:
        `/${
          fieldIconSrcFor(item.option_id, item.category_id, item.question_id, item.suboption_id)}`,
  });
  const barItems = shuffledArr.map((item) => ({
    item: getExp(item),
    onClick: onClickCallBack
      ? () => onClickCallBack(
        fieldLabelFor(item.option_id, item.category_id, item.question_id, item.suboption_id),
      )
      : undefined,
  }));

  return barItems;
};
