import React from 'react';
import {
  Box,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import Close from '@mui/icons-material/Close';

const AlertCard = ({
  header,
  body,
  imageSrc,
  onCloseCallBack,
  onCardClickCallBack,
  altImgText,
}) => (
  <Box
    sx={{
      width: '93%',
      background: '#063D8F',
      height: '137px',
      borderRadius: '8px',
    }}
  >
    <IconButton
      sx={{
        position: 'relative',
        left: '90%',
        color: '#FFFFFF',
        top: '5px',
        opacity: '50%',
        padding: 0,
      }}
      onClick={onCloseCallBack}
    >
      <Close />
    </IconButton>
    <Stack
      direction="row"
      sx={{
        justifyContent: 'center',
        color: '#fff',
        display: 'flex',
      }}
      onClick={onCardClickCallBack}
    >
      <Box
        sx={{
          pl: '15px',
        }}
      >
        <img
          src={imageSrc}
          alt={altImgText}
        />
      </Box>
      <Box
        sx={{
          pl: '10px',
          mt: '10px',
          pr: '13px',
        }}
      >
        <Typography
          sx={{
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: 16,
          }}
        >
          {header}
        </Typography>
        <Typography
          sx={{
            color: '#FFFFFF',
            fontSize: 13,
          }}
        >
          {body}
        </Typography>
      </Box>
    </Stack>
  </Box>
);

AlertCard.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altImgText: PropTypes.string.isRequired,
  onCloseCallBack: PropTypes.func,
  onCardClickCallBack: PropTypes.func,
};

AlertCard.defaultProps = {
  onCloseCallBack: () => { },
  onCardClickCallBack: null,
};

export default AlertCard;
