import React from 'react';
import NumberFormat from 'react-number-format';
import { Box } from '@mui/material';
import { TaupeTextField } from '../../../components/StyledInput';

const PhoneNumberInput = (input) => {
  const {
    value, onChange, isError,
  } = input;

  return (
    <Box>
      <NumberFormat
        format="###-###-####"
        mask="#"
        error={isError}
        onValueChange={(v) => onChange(v)}
        value={value}
        allowEmptyFormatting
        customInput={TaupeTextField}
        fullWidth
        size="small"
        type="tel"
        pattern="[0-9]*"
        inputMode="numeric"
      />
    </Box>
  );
};

export default PhoneNumberInput;
