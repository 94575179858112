import React from 'react';
import ReactDOM from 'react-dom';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonReactRouter } from '@ionic/react-router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { ThemeProvider } from '@mui/material/styles';
import { Provider as HttpProvider } from 'use-http';
import { Provider as ReduxProvider } from 'react-redux';
import useTheme from 'hooks/useTheme';
import { AuthProvider } from './providers/auth-provider';
import { AppStateProvider } from './providers/app-state/app-state-provider';
import { FeatureFlagsProvider } from './providers/featureflags-provider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getStoredToken } from './helpers/tokenHelpers';
import { initializeSentry } from './sentry';
import PlatformView from './common/molecules/shells/platformView/PlatformView';
import { store } from './redux/store';
// eslint-disable-next-line import/no-named-as-default
import AuthShell from './providers/AuthShell';
import IonAppShell from './providers/IonAppShell';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

const theme = useTheme();

const defaultFetchOptions = {
  cachePolicy: 'no-cache',
  interceptors: {
    request: ({ options }) => {
      const accessToken = getStoredToken();
      // eslint-disable-next-line no-param-reassign
      options.headers = {
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      // eslint-disable-next-line no-param-reassign
      options.cachePolicy = 'no-cache';
      return options;
    },
  },
};

document.addEventListener(
  'deviceready',
  () => {
    ScreenOrientation.lock('portrait');
  },
  false,
);

initializeSentry();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <HttpProvider options={defaultFetchOptions} url={process.env.REACT_APP_API_BASEURL}>
        <IonReactRouter>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <FeatureFlagsProvider>
                <AppStateProvider>
                  <PlatformView>
                    <IonAppShell>
                      <AuthShell>
                        <App />
                      </AuthShell>
                    </IonAppShell>
                  </PlatformView>
                </AppStateProvider>
              </FeatureFlagsProvider>
            </ThemeProvider>
          </AuthProvider>
        </IonReactRouter>
      </HttpProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
