/* eslint-disable import/prefer-default-export */
export const degreeData = [
  {
    label: 'High School/GED',
    value: 'high-school-GED',
    infoUrl: 'https://intl.seattlecolleges.edu/docs/high-school-diploma-vs-ged.pdf',
  },
  {
    label: 'Associates',
    value: 'associates',
    infoUrl: 'https://www.coursera.org/articles/what-is-an-associate-degree-key-information-to-know',
  },
  {
    label: 'Vocational/Trade',
    value: 'vocational-trade',
    infoUrl: 'https://www.investopedia.com/terms/v/vocational-degree.asp',
  },
  {
    label: 'Bachelors',
    value: 'bachelors',
    infoUrl: 'https://www.coursera.org/articles/what-is-a-bachelors-degree',
  },
  {
    label: 'Masters',
    value: 'masters',
    infoUrl: 'https://en.wikipedia.org/wiki/Master%27s_degree',
  },
  {
    label: 'Doctoral',
    value: 'doctoral',
    infoUrl: 'https://www.waldenu.edu/online-doctoral-programs/resource/what-is-a-doctoral-degree',
  },
];
