import React, { useState, useEffect } from 'react';
import { IonPage, IonContent, IonRouterLink } from '@ionic/react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'use-http';
import appStrings from '../../common/app_strings';
import GeneralTextCardAtom from '../../common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import Button from '../../common/atoms/button/Button';

const { yourInScreen } = appStrings;

const SecondaryButton = ({ action, label }) => (
  <IonRouterLink onClick={action}>
    <Typography
      sx={{
        color: '#063D8F',
        fontSize: '16px',
        position: 'relative',
        textAlign: 'center',
        top: '40px',
        right: '4px',
      }}
    >
      {label}
    </Typography>
  </IonRouterLink>
);

const StartBackgroundCheckScreen = () => {
  const history = useHistory();
  const { get: getTeams, response: getTeamsResponse } = useFetch('/users/teams');
  const [secondaryAction, setSecondaryAction] = useState(null); // "exploreTeam", "maybeLater", "goToFeed"
  const goToBGCheck = () => {
    history.push('/checkr-form');
  };
  const goToTeamDetails = () => {
    history.push('/tabs/backr/teams');
  };

  const goToFeed = () => {
    history.push('/');
  };

  const goBack = () => {
    history.goBack();
  };

  const getSecondaryAction = async () => {
    const teams = await getTeams();
    if (getTeamsResponse.ok) {
      if (teams.length > 0) {
        setSecondaryAction('exploreTeam');
      } else {
        setSecondaryAction('maybeLater');
      }
      return;
    }
    setSecondaryAction('goToFeed');
  };

  useEffect(async () => {
    await getSecondaryAction();
  }, []);

  const secondaryButtonProps = () => {
    const goToFeedProps = {
      label: yourInScreen.maybeLater,
      action: goToFeed,
    };

    switch (secondaryAction) {
      case 'exploreTeam':
        return {
          label: yourInScreen.exploreTeam,
          action: goToTeamDetails,
        };
      case 'maybeLater':
        return {
          label: yourInScreen.maybeLater,
          action: goBack,
        };
      case 'goToFeed':
        return goToFeedProps;
      default:
        return goToFeedProps;
    }
  };

  return (
    <IonPage>
      <IonContent style={{ '--padding-top': '70px' }}>
        <Stack>
          <GeneralTextCardAtom
            title={yourInScreen.header}
            body={yourInScreen.body}
            titleStyles={{
              textAlign: 'center',
              color: '#03295F',
              fontSize: '28px',
              fontWeight: 700,
            }}
            bodyStyles={{
              padding: '16px',
              fontSize: '1rem',
              textAlign: 'center',
              color: '#2D2D2D',
              opacity: 1,
            }}
          />
          <img
            style={{ marginBottom: '-50px', width: '90%' }}
            src="/assets/bgCheckCelebration.svg"
            alt="Party favor and clipboard"
          />
          <Stack
            sx={{
              m: 1,
              p: 2,
              position: 'relative',
              top: '3.5rem',
              right: '0.5rem',
            }}
            alignItems="center"
          >
            <Stack
              alignContent="left"
              gap={1}
            >
              {yourInScreen.checkrInfo.map((info) => (
                <div
                  key={info}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    fontWeight: '600',
                  }}
                >
                  <ArrowForwardIcon sx={{ pr: '5px' }} />
                  <span>{info}</span>
                </div>
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Button
          label="Start Background Check"
          buttonStyles={{
            position: 'relative',
            top: '30px',
            background: '#03295F',
            width: '85%',
            fontWeight: 'bold',
            alignSelf: 'center',
            p: '5px',
            mt: 5,
            mr: 1,
          }}
          labelStyles={{
            pb: '10px',
            fontSize: '20px',
          }}
          onClickCallBack={goToBGCheck}
        />
        {secondaryAction !== null && <SecondaryButton {...secondaryButtonProps()} />}
      </IonContent>
    </IonPage>
  );
};

export default StartBackgroundCheckScreen;
