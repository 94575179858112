import React, { useEffect, useState } from 'react';
import InputField from 'common/atoms/inputField/InputField';
import BaseModal from 'common/atoms/BaseModal/BaseModal';
import PersistentDrawerPage from 'pages/wholeme/molecules/persistentDrawerPage/PersistentDrawerPage';
import { firstValueOf } from '../../../util/profile_util';
import { useAuthContext } from '../../../../../providers/auth-provider';
import { getStringContentByRole } from './profileIdentityIntakeIntroducePage_content';
import { useFeatureFlagsContext } from '../../../../../providers/featureflags-provider';

const mainImage = {
  imageSrc: 'assets/profile/identity/introduce-yourself.svg',
  imageAlt: 'hand holding a phone',
  imageStylesOverride: {
    maxWidth: '9rem',
    maxHeight: '12rem',
  },
};

const ProfileIdentityIntakeIntroducePage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => {
  const [introduceState, setIntroduceState] = useState();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const { isSaveAndContinueAlwaysEnabled } = useFeatureFlagsContext() || {};
  const { user } = useAuthContext();
  const content = getStringContentByRole(user?.role);

  useEffect(async () => {
    const state = await wholeMeClient.fetchIntroduceState();
    setIntroduceState(firstValueOf(state?.excited) || '');
  }, []);

  const saveIntroduce = () => {
    wholeMeClient.saveIntroduceState([
      {
        category_id: 'identity',
        question_id: 'introduce',
        value: introduceState,
      },
    ]).then(() => {
      nextOnClick();
    });
  };

  const isCtaDisabled = !isSaveAndContinueAlwaysEnabled && !introduceState?.length;

  return (
    <PersistentDrawerPage
      pageTitle={content.pageTitle}
      pageSubtitle={content.pageSubtitle}
      drawerTitle={content.drawerTitle}
      isLoading={introduceState === undefined}
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: saveIntroduce, isDisabled: isCtaDisabled }}
      handleInfoButtonClick={openModal}
      paginationMeta={paginationMeta}
      backgroundOverlay="/assets/profile/identity/introduction-overlay.svg"
      mainImageData={mainImage}
    >
      <InputField
        onChange={(e) => setIntroduceState(e.target.value)}
        placeholder={content.inputPlaceholder}
        fieldValue={introduceState}
        isMultiline
        rows={4}
      />
      <ExampleModal
        isOpen={showModal}
        handleClose={closeModal}
        header={content.exampleHeader}
        body={content.exampleContent}
      />
    </PersistentDrawerPage>
  );
};

const modalFontFamily = 'Sarabun, arial, sans-serif';

const modalHeaderStyles = {
  fontWeight: '700',
  fontSize: '22px',
  fontFamily: modalFontFamily,
  color: '#03295F',
};
const modalParagraphStyles = {
  fontWeight: '400',
  fontSize: '16px',
  fontFamily: modalFontFamily,
};

const ExampleModal = ({
  isOpen,
  handleClose,
  header,
  body,
}) => (
  <BaseModal isOpen={isOpen} handleClose={handleClose}>
    <h3 style={modalHeaderStyles}>{header}</h3>
    <p style={modalParagraphStyles}>{body}</p>
  </BaseModal>
);

export default ProfileIdentityIntakeIntroducePage;
