/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import SelectableItemsList from 'common/molecules/selectableItemsList/SelectableItemsList';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import BottomDrawer from 'common/molecules/BottomDrawer/BottomDrawer';
import { careerFieldData } from './careerFieldData';

const fieldsByValue = careerFieldData.reduce((acc, next) => ({ ...acc, [next.value]: next }), {});

const ProfileCareerIntakeFieldsPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [careerFieldChoices, setCareerFieldChoices] = useState();
  const [currentOption, setCurrentOption] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (careerFieldChoices) setSelectedOptions(Object.keys(careerFieldChoices));
  }, [careerFieldChoices]);

  useEffect(async () => {
    const fetchedSelectedData = (await wholeMeClient.fetchCareerFieldsState())?.career_fields || [];
    const formattedFetchedSelectedData = fetchedSelectedData.reduce((acc, option) => {
      const { option_id, value } = option;
      if (value !== 'selected') {
        acc[option_id] = acc[option_id] ? [...acc[option_id], value] : [value];
      } else {
        acc[option_id] = acc[option_id] || [];
      }
      return acc;
    }, {});
    setCareerFieldChoices(formattedFetchedSelectedData);
  }, []);

  const saveField = () => {
    const dbFormattedFieldData = Object.entries(careerFieldChoices)
      .map(([key, options]) => {
        const subOptionAnswers = options.map((option) => ({
          category_id: 'career',
          question_id: 'career_fields',
          option_id: key,
          suboption_id: 'What subcategory?',
          value: option,
        }));

        return [
          ...subOptionAnswers,
          {
            category_id: 'career',
            question_id: 'career_fields',
            option_id: key,
            value: 'selected',
          },
        ];
      })
      .flat();
    wholeMeClient.saveCareerFieldsState(dbFormattedFieldData).then(nextOnClick);
  };

  const handleListSelectionClick = (value) => {
    if (careerFieldChoices[value]?.length === 0) {
      const newcareerFieldChoices = Object.fromEntries(
        Object.entries(careerFieldChoices).filter(([key]) => key !== value),
      );
      setCareerFieldChoices(newcareerFieldChoices);
      return;
    }
    if (!careerFieldChoices[value]) {
      setCareerFieldChoices({
        ...careerFieldChoices,
        [value]: [],
      });
    }
    setCurrentOption(value);
  };

  const handlePillClick = (pillValue) => {
    const existingSelectedChoices = careerFieldChoices[currentOption];
    const newSelectedChoices = existingSelectedChoices.includes(pillValue)
      ? existingSelectedChoices.filter((choice) => choice !== pillValue)
      : [...existingSelectedChoices, pillValue];

    setCareerFieldChoices({
      ...careerFieldChoices,
      [currentOption]: newSelectedChoices,
    });
  };

  return (
    <>
      <ProfileIntakeStepPage
        paginationMeta={paginationMeta}
        title="Career Fields"
        subtitle="We'll add you to networks that match your career experiences."
        prevButton={{ onClick: prevOnClick }}
        nextButton={{ onClick: saveField }}
        backgroundOverlay="/assets/profile/education/career-field-overlay.svg"
        exitIconOnClickOverride={exitIconOnClickOverride}
      >
        {careerFieldChoices === undefined
          ? <WholeMeLoadingCircle />
          : (
            <SelectableItemsList
              items={careerFieldData}
              activeItems={selectedOptions}
              activeItemsOverridesInternalSelection
              itemVariant="rectangle"
              isTwoColumns
              selectionType="multi"
              onSelection={(value) => handleListSelectionClick(value)}
            />
          )}
      </ProfileIntakeStepPage>
      <BottomDrawer
        open={!!currentOption}
        title={fieldsByValue[currentOption]?.subQuestions[0].label}
        ctaLabel="Save"
        handleCTAClick={() => setCurrentOption('')}
      >
        {currentOption
        && (
          <PillRenderer
            items={fieldsByValue[currentOption]?.subQuestions[0].options}
            activeItems={careerFieldChoices[currentOption] || []}
            handlePillClick={(value) => handlePillClick(value)}
          />
        )}
      </BottomDrawer>
    </>
  );
};

export default ProfileCareerIntakeFieldsPage;
