import useFetch from 'use-http';

const useIsFirstTimeUser = () => {
  const { get, response } = useFetch({ cachePolicy: 'no-cache' });

  const isFirstTimeUser = async (userId) => {
    try {
      const res = await get(`/users/activity/${userId}`);

      if (response.status !== 200) {
        throw new Error('Error getting user activity');
      }

      if (res) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return isFirstTimeUser;
};

export default useIsFirstTimeUser;
