import React from 'react';
import WebViewShell from '../webView/WebViewShell';
import { useAppState } from '../../../../providers/app-state/app-state-provider';
import useWindowDimensions from '../../../../helpers/hooks';

const PlatformView = ({ children }) => {
  const { platform } = useAppState();
  const { width } = useWindowDimensions();

  if (width >= 800 && !platform?.isNativePlatform) {
    return (
      <WebViewShell>
        {children}
      </WebViewShell>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (<>{children}</>);
};

export default PlatformView;
