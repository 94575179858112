import React from 'react';
import FirstTimeUserMediaPlayer from './molecules/workflowCard/FirstTimeUserMediaPlayer';
import CardMolecule from './molecules/workflowCard/CardMolecule';

// eslint-disable-next-line import/prefer-default-export
export const buildFirstTimeUserWorkFlowCards = (cardMetas) => cardMetas.map((item) => (
  <CardMolecule
    key={item.id}
    cta={item?.cta}
    media={(
      <FirstTimeUserMediaPlayer
        media={item}
      />
      )}
    text={item?.body}
  />
));
