import React, {
  useState,
  useEffect,
} from 'react';
import {
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { feedSelector } from '../../../redux/slices/feed';
import FilterItem from '../filterItem/FilterItem';
import { buildFilterItems } from './utils';

const FilterBar = ({
  filterMeta,
  onChangeCallBack,
  disableClickEventsOverrideRef,
}) => {
  const { activeFilter } = useSelector(feedSelector);
  const [filterItems, setFilterItems] = useState([]);

  const handleOnChange = (filter) => {
    onChangeCallBack(filter);
  };

  useEffect(() => {
    const filters = buildFilterItems(
      filterMeta,
      handleOnChange,
      activeFilter.value,
    );
    setFilterItems(filters);
  }, [filterMeta, activeFilter.value]);

  return (
    <Stack
      direction="row"
      spacing={3}
    >
      {filterItems.length && filterItems.map((f) => (
        <FilterItem
          key={f.filterMeta.value}
          filterMetaItem={f.filterMeta}
          isSelected={f.isSelected}
          onClickCallBack={f.onClickCallBack}
          size={f.size}
          disableClickEventsOverrideRef={disableClickEventsOverrideRef}
        />
      ))}
    </Stack>
  );
};

FilterBar.propTypes = {
  filterMeta: PropTypes.oneOfType([PropTypes.array]),
  onChangeCallBack: PropTypes.func,
  disableClickEventsOverrideRef: PropTypes.oneOfType([PropTypes.object]),
};

FilterBar.defaultProps = {
  filterMeta: null,
  onChangeCallBack: () => { },
  disableClickEventsOverrideRef: null,
};

export default FilterBar;
