import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonIcon,
  IonCard,
  IonCardContent,
  IonButton,
  IonRouterLink,
} from '@ionic/react';
import { addCircle, sparkles, arrowForwardOutline } from 'ionicons/icons';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  Stack,
} from '@mui/material';
import { motion } from 'framer-motion';
import useFetch from 'use-http';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import { useAuthContext } from 'providers/auth-provider';
import { LightTextField } from 'components/StyledInput';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './Goals.css';
import appStrings from 'common/app_strings';
import FilterBar from 'common/atoms/filterBar/FilterBar';
import { filters } from './utils';

const { goals: strings } = appStrings;

const Goal = ({
  goal,
  handleEdit,
  handleStatus,
  handleVisibility,
  handleDelete,
  goToRelatedPost,
}) => {
  const [open, setOpen] = useState(false);
  const [isChangingVisibility, setIsChangingVisibility] = useState(false);
  const { control, handleSubmit, watch } = useForm();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteGoal = (userGoal) => {
    handleDelete(userGoal);
    handleClose();
  };

  const changeVisibility = async (userGoal, visibility) => {
    setIsChangingVisibility(true);
    await handleVisibility(userGoal, visibility);
    setIsChangingVisibility(false);
  };

  const onSubmit = async (data) => {
    handleStatus(goal, data);
  };

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle textAlign="center" px={8}>
          {strings.confirmDelete}
        </DialogTitle>
        <Stack spacing={2} mb={2} px={4}>
          <IonButton
            style={{ '--background': '#EB5757', '--color': '#fff' }}
            size="large"
            expand="block"
            shape="round"
            onClick={() => deleteGoal(goal)}
          >
            {strings.yesDelete}
          </IonButton>
          <IonButton size="large" expand="block" shape="round" fill="outline" onClick={handleClose}>
            {strings.noDelete}
          </IonButton>
        </Stack>
      </Dialog>
      <div className="goals-item">
        <div className="goals-item-description">
          <div className={`goals-type-wrapper goals-indicator-${goal?.type}`}>
            <img
              className="goals-type-icon"
              src={`/assets/${goal?.type}-goal.svg`}
              alt={`${goal?.type} goal icon`}
            />
          </div>
          <Typography className="goals-text" color="#000">
            <Linkify>{goal?.content}</Linkify>
          </Typography>
        </div>
        <div className="goals-item-status">
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '65%' }}>
            <Controller
              control={control}
              name="status"
              defaultValue={goal?.status || 'in_progress'}
              render={({ field }) => (
                <LightTextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  select
                  size="small"
                  fullWidth
                >
                  <MenuItem value="in_progress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </LightTextField>
              )}
            />
          </form>
          {goal?.post?.id && (
            <Chip
              label={strings.seeUpdates}
              sx={{
                backgroundColor: '#03295F',
                color: '#fff',
                fontWeight: 700,
              }}
              onClick={() => goToRelatedPost(goal?.post?.id)}
            />
          )}
        </div>
        <div className="goals-item-options">
          <div>
            <IconButton size="small" sx={{ pl: 0 }} onClick={() => handleEdit(goal)}>
              <IonIcon
                style={{ fontSize: '24px' }}
                color="warning"
                slot="icon-only"
                src="/assets/icon/edit.svg"
              />
            </IconButton>
            <IconButton
              size="small"
              disabled={isChangingVisibility}
              onClick={() => (goal?.visibility === 'team'
                ? changeVisibility(goal, 'private')
                : changeVisibility(goal, 'team'))}
            >
              <IonIcon
                style={{
                  fontSize: '24px',
                  stroke: 'var(--ion-color-warning)',
                }}
                color="warning"
                slot="icon-only"
                src={
                  goal?.visibility === 'team'
                    ? '/assets/icon/eye.svg'
                    : '/assets/icon/eye-closed.svg'
                }
              />
            </IconButton>
            <IconButton size="small" onClick={handleOpen}>
              <IonIcon
                style={{ fontSize: '24px' }}
                color="warning"
                slot="icon-only"
                src="/assets/icon/trash.svg"
              />
            </IconButton>
            {goal?.post?.id && (
              <IconButton size="small" onClick={() => goToRelatedPost(goal?.post?.id)}>
                <IonIcon
                  style={{ fontSize: '24px' }}
                  color="warning"
                  slot="icon-only"
                  icon={addCircle}
                />
              </IconButton>
            )}
          </div>
          {goal?.status === 'completed' && (
            <div>
              <Typography
                variant="span"
                sx={{
                  color: '#2CB669',
                  fontSize: 14,
                  fontWeight: 700,
                  mr: 0.75,
                }}
              >
                {strings.achieved}
              </Typography>
              <IonIcon style={{ color: '#2CB669' }} slot="icon-only" icon={sparkles} />
            </div>
          )}
        </div>
      </div>
      <Divider sx={{ mt: 2 }} />
    </>
  );
};

Goal.propTypes = {
  goal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    visibility: PropTypes.string.isRequired,
    post: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
  handleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  goToRelatedPost: PropTypes.func.isRequired,
};

const Goals = ({ history }) => {
  const { user } = useAuthContext();
  const [goals, setGoals] = useState([]);
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const urlParams = new URLSearchParams();
  urlParams.append('user_id', user?.id);
  const { del, patch } = useFetch();
  const { get: fetchGoals, loading, abort } = useFetch(`/goals?${urlParams.toString()}`);

  const handleFilteredGoals = (type) => {
    if (!selectedFilter) setFilteredGoals(goals);
    else {
      const updatedGoals = goals.filter((goal) => goal.type === type);
      setFilteredGoals(updatedGoals);
    }
  };

  const handleGoalDelete = (goal) => {
    del(`/goals/${goal.id}`);
    const updatedGoals = goals.filter((currentGoal) => currentGoal.id !== goal.id);
    setGoals(updatedGoals);
  };

  const handleGoalEdit = (goal) => {
    history.push('/protege/goals-edit', goal);
  };

  const handleGoalCelebration = (goal) => {
    history.push('/tabs/protege/goals/celebration', goal);
  };

  const handleGoalStatus = async (goal, status) => {
    const { status: progress } = status;
    await patch(`/goals/${goal?.id}`, status);
    if (progress === 'completed') {
      setGoals(
        goals.map((currentGoal) => (currentGoal.id === goal?.id ? { ...currentGoal, ...status } : currentGoal)),
      );
      const updatedGoal = { ...goal, ...status };
      handleGoalCelebration(updatedGoal);
    } else {
      setGoals(
        goals.map((currentGoal) => {
          if (currentGoal.id === goal?.id) {
            return { ...currentGoal, ...status };
          }
          return currentGoal;
        }),
      );
    }
  };

  const handleGoalVisibility = async (goal, visibility) => {
    const editedGoal = await patch(`/goals/${goal.id}`, { visibility });
    setGoals(
      goals.map((currentGoal) => {
        if (currentGoal.id === goal.id) {
          return editedGoal;
        }
        return currentGoal;
      }),
    );
  };

  const goToRelatedPost = (id) => {
    history.push(`/posts/${id}`);
  };

  useEffect(() => {
    handleFilteredGoals(selectedFilter);
  }, [selectedFilter, goals]);

  useEffect(() => {
    fetchGoals().then((response) => {
      if (response?.length < 1) {
        history.push('/tabs/protege/goals-empty');
      } else {
        setGoals(response);
        setFilteredGoals(response);
      }
    });
    return () => {
      abort();
    };
  }, []);

  const handleFilterChange = (filter) => {
    const value = filter.value === 'all' ? null : filter.value;
    setSelectedFilter(value);
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'auto',
              mt: '15px',
              pb: '6px',
              ml: '24px',
              mr: '24px',
            }}
          >
            <FilterBar
              filterMeta={filters}
              onChangeCallBack={handleFilterChange}
              initialSelectedFilterValue="all"
            />
          </Box>
        </motion.div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className="goals-list">
              {filteredGoals?.map((goal) => (
                <Goal
                  key={goal.id}
                  goal={goal}
                  handleEdit={handleGoalEdit}
                  handleStatus={handleGoalStatus}
                  handleDelete={handleGoalDelete}
                  handleVisibility={handleGoalVisibility}
                  goToRelatedPost={goToRelatedPost}
                />
              ))}
            </div>
            {!user?.isFlagged ? (
              <Box mx={3}>
                <IonCard
                  className="goals-create-card-wrapper"
                  style={{ '--background': '#063d8f' }}
                >
                  <IonRouterLink routerLink="/protege/goals-create">
                    <IonCardContent className="goals-create-card-content">
                      <div className="goals-create-card">
                        <img
                          className="goals-trophy"
                          src="/assets/goal-trophy.svg"
                          alt="goal trophy"
                        />
                        <div className="goals-create-card-message">
                          <Typography
                            variant="h2"
                            sx={{
                              position: 'relative',
                              color: '#fff',
                              fontSize: '1rem !important',
                              fontWeight: '700 !important',
                            }}
                          >
                            {strings.addNewGoal}
                            <IonIcon
                              style={{
                                position: 'relative',
                                top: '4px',
                                fontSize: '1.25rem',
                                marginLeft: '4px',
                              }}
                              src={arrowForwardOutline}
                            />
                          </Typography>
                          <Typography color="#fff">{strings.accomplishMore}</Typography>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonRouterLink>
                </IonCard>
              </Box>
            ) : null}
          </motion.div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

Goals.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Goals;
