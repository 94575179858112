import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
} from '@mui/material';

const chooseStatusPicture = (stage) => {
  const stage1 = '/assets/dynamic-meter/20-progress.svg';
  const stage2 = '/assets/dynamic-meter/40-progress.svg';
  const stage3 = '/assets/dynamic-meter/60-progress.svg';
  const stage4 = '/assets/dynamic-meter/80-progress.svg';
  const stage5 = '/assets/dynamic-meter/100-progress.svg';
  let image = '';

  if (stage === 20) {
    image = stage1;
  }
  if (stage === 40) {
    image = stage2;
  }
  if (stage === 60) {
    image = stage3;
  }
  if (stage === 80) {
    image = stage4;
  }
  if (stage === 100) {
    image = stage5;
  }

  return image;
};

const StatusMeter = ({
  stage,
}) => (
  <Stack
    sx={{
      width: '80%',
      height: '80%',
      px: '40px',
      py: '20px',
    }}
  >
    <img
      src={chooseStatusPicture(parseInt(stage, 10))}
      alt="Background status meter svg"
    />
  </Stack>
);

StatusMeter.propTypes = {
  stage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

StatusMeter.defaultProps = {
  stage: '',
};

export default StatusMeter;
