/* eslint-disable */
import React from 'react';
import WholeMeProfileSection from 'pages/wholeme/molecules/wholeMeProfileSection/WholeMeProfileSection';
import WholeMeProfileSectionDetail from 'pages/wholeme/molecules/wholeMeProfileSectionDetail/WholeMeProfileSectionDetail';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import { HealthIcon } from 'pages/wholeme/icons/Icons';
import { fieldInfoUrlFor, fieldLabelFor } from '../../util/profile_field_data';
import WholeMeProfileSectionRow from '../wholeMeProfileSectionRow/WholeMeProfileSectionRow';
import {
  pillOverrideStyles,
  pillRendererOverrideStyles,
} from '../wholeMeProfileSection/wholeMeProfileSectionStyles';
import { resolveDefaultIcons } from '../../util/profile_util';

const WholeMeProfileHealth = ({ profile, onEdit, onPost, isViewOnly }) => {
  const { health_interests = [], health_credentials = [] } = profile;
  if (!onEdit && health_interests.length < 1 && health_credentials.length < 1) {
    return null;
  }

  const renderEdit = () => (
    <>
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Health Credentials"
        items={health_credentials.map((n) =>
          fieldLabelFor(n.option_id, 'health', 'health_credentials'),
        )}
        categoryId="health"
        stepId="health_credentials"
        isViewOnly={isViewOnly}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Health Interests"
        items={health_interests.map((n) =>
          fieldLabelFor(n.suboption_id, 'health', 'health_interests'),
        )}
        categoryId="health"
        stepId="health_interests"
        isViewOnly={isViewOnly}
      />
    </>
  );

  const renderView = () => (
    <>
      {health_credentials.length > 0 &&
        health_credentials.map((n) => (
          <WholeMeProfileSectionRow
            onPost={onPost}
            iconSrc={resolveDefaultIcons('health_credentials', n.option_id)}
            infoUrl={fieldInfoUrlFor(n.option_id, n.category_id, n.question_id)}
            label={fieldLabelFor(n.option_id, n.category_id, n.question_id)}
          />
        ))}
      {health_interests.length > 0 && (
        <PillRenderer
          pillStyles={pillOverrideStyles}
          containerStyleOverrides={isViewOnly ? pillRendererOverrideStyles : {...pillRendererOverrideStyles, marginLeft: '22px' }}
          isSelectable={false}
          handlePillClick={
            onPost ? (item, clickContext) => onPost(item, clickContext?.infoUrl) : undefined
          }
          items={health_interests.map((n) => {
            return {
              value: fieldLabelFor(n.option_id, n.category_id, n.question_id, n.suboption_id),
              clickContext: {
                infoUrl: fieldInfoUrlFor(n.option_id, n.category_id, n.question_id, n.suboption_id),
              },
            };
          })}
        />
      )}
    </>
  );

  return (
    <WholeMeProfileSection
      title="Health"
      editable={!!onEdit}
      titleIcon={<HealthIcon marginRight={7} height={67} />}
    >
      {onEdit ? renderEdit() : renderView()}
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileHealth;
