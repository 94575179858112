import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import ProgressDots from 'common/atoms/progressDots/ProgressDots';

// this padding number needs to align with the padding in the ProfileIntakePage
const pagePadding = 20;

const stepPageHeaderStyles = {
  width: '100%',
  padding: `${pagePadding}px 0 20px 0`,
  marginBottom: 'auto 0',
};

const topIconContainerStyles = {
  width: '25px',
  height: '25px',
  position: 'fixed',
  top: '40px',
  cursor: 'pointer',
};

const exitIconContainerStyles = {
  ...topIconContainerStyles,
  right: `${pagePadding}px`,
  zIndex: 1,
};

const backIconContainerStyles = {
  ...topIconContainerStyles,
  left: `${pagePadding}px`,
  zIndex: 1,
};

const StepPageHeader = ({
  paginationMeta,
  showProgressDots,
  topBackArrowOnClickHandler,
  exitIconOnClickOverride,
}) => {
  const history = useHistory();
  const onExitIconClick = exitIconOnClickOverride || (() => history.goBack());

  return (
    <Box as="header" style={stepPageHeaderStyles}>
      {
        topBackArrowOnClickHandler
        && (
        <Box as="back-icon" style={backIconContainerStyles} onClick={() => topBackArrowOnClickHandler()}>
          <img src="assets/back-arrow-blue.svg" alt="back-button" />
        </Box>
        )
      }
      <Box as="exit-icon" style={exitIconContainerStyles} onClick={onExitIconClick}>
        <img src="assets/icon/exitX.svg" alt="exit-button" />
      </Box>
      { showProgressDots && (
      <ProgressDots
        totalNumber={paginationMeta.total}
        activeNumber={paginationMeta.current}
      />
      )}
    </Box>
  );
};

export const StepPageHeaderPropTypes = {
  paginationMeta: PropTypes.shape({ current: PropTypes.number, total: PropTypes.number }),
  showProgressDots: PropTypes.bool.isRequired,
};

export const StepPageHeaderDefaultProps = {
  paginationMeta: { current: 0, total: 0 },
};

StepPageHeader.propTypes = StepPageHeaderPropTypes;
StepPageHeader.defaultProps = StepPageHeaderDefaultProps;

export default StepPageHeader;
