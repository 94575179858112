import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import GeneralTextCardAtom from 'common/atoms/generalTextCardAtom/GeneralTextCardAtom';
import Button from 'common/atoms/button/Button';

const ctaContainerStyles = {
  mt: '45px',
  alignItems: 'center',
};

const ctaButtonStyles = {
  background: '#03295F',
  height: '42px',
  cursor: 'pointer',
  width: 'fit-content',
};

const UnlockScreen = ({
  meta,
}) => {
  const {
    variant, title, body, image, buttonMeta,
  } = meta;

  return (
    <Stack
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Stack
        sx={{
          height: '182px',
          width: '182px',
          ...(variant === 'teams' && { px: '25%' }),
          ...(variant === 'goals' && { px: '30%' }),
          ...(variant === 'profile' && {
            px: '25%', width: '150px', height: '150px', marginRight: '20px',
          }),
        }}
      >
        <img src={image} alt="unlock screen svg" />
      </Stack>
      <Stack
        sx={{
          ...(variant === 'goals' && {
            pt: '15px',
          }),
          ...(variant === 'teams' && {
            pt: '30px',
          }),
          ...(variant === 'profile' && {
            pb: '10px',
            pt: '5px',
          }),
        }}
      >
        <GeneralTextCardAtom
          title={title}
          body={body}
          titleStyles={{
            textAlign: 'center',
            color: '#03295F',
            fontSize: '28px',
            ...(variant === 'profile' && {
              fontWeight: '800',
            }),
          }}
          bodyStyles={{
            fontWeight: 500,
            fontSize: '17px',
            textAlign: 'center',
            color: '#2D2D2D',
            opacity: 1,
            px: '40px',
            pt: '20px',
            lineHeight: 1.5,
            maxWidth: '350px',
            ...(variant === 'teams' && {
              width: '80%',
            }),
            ...(variant === 'goals' && {
              px: '65px',
            }),
            ...(variant === 'profile' && {
              px: '15px',
              pt: '0px',
              fontSize: '16px',
            }),
          }}
        />
      </Stack>
      {buttonMeta && (
        <Stack
          sx={ctaContainerStyles}
        >
          <Button
            label={buttonMeta?.label}
            onClickCallBack={buttonMeta?.callback}
            buttonStyles={ctaButtonStyles}
          />
        </Stack>
      )}
    </Stack>
  );
};

UnlockScreen.propTypes = {
  meta: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    buttonMeta: PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
    }),
  }).isRequired,
};

export default UnlockScreen;
