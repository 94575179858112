export const protegeCountSelected = {
  backgroundColor: '#2CB669',
  '&:focus': {
    backgroundColor: '#2CB669',
  },
  '&:hover': {
    backgroundColor: '#2CB669',
  },
  color: '#FFF',
};

export const backrCountSelected = {
  backgroundColor: '#03295F',
  '&:focus': {
    backgroundColor: '#03295F',
  },
  '&:hover': {
    backgroundColor: '#03295F',
  },
  color: '#FFF',
};

export const defaultStyles = {
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: '#E8E8DC',
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: '#E8E8DC',
  },
  whiteSpace: 'nowrap',
  display: 'inline-block',
  paddingBottom: '.75rem',
  color: '#2D2D2D',
  fontWeight: 700,
  fontSize: '12px',
};

export const cardHeaderStyles = {
  textWrap: 'wrap',
  color: '#2D2D2D',
  fontWeight: 400,
  fontSize: '14px',
  margin: '-1.5rem 2.5rem 0 0',
};

export const underLinedTextStyles = {
  color: '#4A80CA',
  fontSize: '12px',
  textDecoration: 'underline',
  paddingTop: '1rem',
  cursor: 'pointer',
};

export const networkContainerStyle = {
  margin: '1rem 1rem 0 1rem',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  overflowY: 'hidden',
};

export const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  whiteSpace: 'nowrap',
  gap: '.5rem',
  height: '2.5rem',
};

export const noListContainerStyle = {
  borderRadius: '10px',
  backgroundColor: '#E8E8DC',
  height: '100vh',
  color: '#2D2D2D',
  textAlign: 'center',
};

export const affinityMemberContainer = {
  backgroundColor: '#E8E8DC',
  marginBottom: '.25rem',
  padding: '1rem',
};

export const pillStyles = {
  backgroundColor: '#F4F4EE',
  color: '#5B5B5B',
  fontWeight: 400,
  opacity: '.75',
};

export const triangleStyles = {
  position: 'relative',
  width: '1.5rem',
  height: '1.5rem',
  bottom: '-1rem',
};

export const affinityCountContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
