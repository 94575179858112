import React from 'react';
import MultiSelectDrawerPage from 'pages/wholeme/molecules/multiSelectDrawerPage/MultiSelectDrawerPage';
import { religionData } from './religionData';

const ProfileIdentityIntakeReligionPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <MultiSelectDrawerPage
    fieldData={religionData}
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    pageTitle="Religion & Spirituality"
    pageSubtitle="Do you identify with any religions?"
    selectableItemVariant="oval"
    categoryId="identity"
    questionId="religion"
    fetcher={wholeMeClient.fetchReligionState}
    saver={wholeMeClient.saveReligionState}
    selectionType="single"
    backgroundOverlay="/assets/profile/identity/spirituality-overlay.svg"
    drawerTriggerValue="other"
    drawerTitle="Other Religion"
    inputFieldPlaceholder="Other religion"
    inputIcon="religion"
  />
);

export default ProfileIdentityIntakeReligionPage;
