import React from 'react';
import { Stack, Typography } from '@mui/material';
import BackrsButton from 'common/atoms/backrsButton/BackrsButton';
import { portraitHeadingWrapperStyles, portraitHeadingStyles, portraitSubheadingStyles } from '../profileIntakePortraitStyles';

export const PortraitHeadingText = () => (
  <Stack spacing={2} sx={portraitHeadingWrapperStyles}>
    <Typography sx={portraitHeadingStyles}>The Whole Picture</Typography>
    <Typography sx={portraitSubheadingStyles}>
      Click a frame to add to or edit your full profile and help the community learn from your
      experiences.
    </Typography>
  </Stack>
);

export const PortraitDisplayWrapper = ({ children }) => (
  <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} my={3}>
    {children}
  </Stack>
);

export const LeftPortraitColumn = ({ children }) => (
  <Stack direction="column" alignItems="flex-end" spacing={2}>{children}</Stack>
);

export const RightPortraitColumn = ({ children }) => (
  <Stack direction="column" alignItems="flex-start" spacing={2}>{children}</Stack>
);

export const CompleteProfileButton = ({ handleProfileNavigation }) => (
  <BackrsButton label="Complete your profile" buttonStylesOverride={{ fontWeight: 700 }} onClick={handleProfileNavigation} />
);

export const ViewProfileButton = ({ handleProfileNavigation }) => (
  <BackrsButton label="View your whole profile" buttonStylesOverride={{ fontWeight: 700 }} onClick={handleProfileNavigation} />
);
