import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/capacitor';
import {
  IonFooter,
  IonContent,
  IonButton,
  IonIcon,
  IonTextarea,
  IonRouterLink,
  IonPage,
  IonThumbnail,
  IonItem,
  IonLabel,
  useIonActionSheet,
  IonImg,
  IonCard,
} from '@ionic/react';
import { closeCircle, videocam } from 'ionicons/icons';
import {
  CircularProgress, Alert, Stack, Typography, Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import useFetch, { CachePolicies } from 'use-http';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { displayName } from '../helpers/textManipulation';
import AvatarRole from '../components/AvatarRole';
import Comment from '../components/Comment';
import './Comments.css';
import { useAuthContext } from '../providers/auth-provider';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import AvatarAdmin from '../components/AvatarAdmin';
import PageWithBackButton from '../components/generic/PageWithBackButton';
import SendIconAvatar from './enhancedConversations/molecules/SendIconAvatar';
import { useAppState } from '../providers/app-state/app-state-provider';
import { getMediaActionButtons } from '../helpers/comments';
import { displayAttachmentLabel, displayErrorText, renderCommentBody } from '../helpers/media';
import appStrings from '../common/app_strings';
import BackrsButton from '../common/atoms/backrsButton/BackrsButton';
import VideoPlayer from '../components/VideoPlayer';
import PdfIndicator from '../common/atoms/pdfIndicator/PdfIndicator';
import useWindowDimensions from '../helpers/hooks';
import {
  textAreaStyles,
  imgStyle,
  postBtnStyle,
  iconContainer,
  mediaIcon,
} from './commentsStyles';

const { comments } = appStrings;

const Comments = () => {
  const { id } = useParams();
  const {
    control, handleSubmit, reset, watch,
  } = useForm();
  const watchBody = watch('body', '');
  const { user } = useAuthContext();
  const {
    takePhoto, takeVideo, takeWebVideo, loading, error, controller, takePdf,
  } = usePhotoGallery();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [loadingLike, setLoadingLike] = useState(false);
  const [post, setPost] = useState(null);
  const [replyTo, setReplyTo] = useState(null);
  const [media, setMedia] = useState(null);
  const [isVideo, setIsVideo] = useState(null);
  const [actionSheetResult, setActionSheetResult] = useState(null);
  const [isPosting, setIsPosting] = useState(false);

  const [present] = useIonActionSheet();

  const {
    get,
    post: postLike,
    delete: deleteLike,
    response: postResponse,
    abort,
  } = useFetch({
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const { platform } = useAppState();
  const userRoute = `/profile/${post?.author_id}`;
  const adminRoute = `/profile/${post?.author_id}?role=admin`;
  const postAuthorIsAdmin = post?.author?.role === 'admin';

  const computeDisplayName = () => {
    if (postAuthorIsAdmin) {
      return 'Coach Lubav';
    }
    if (post?.author?.first_name) {
      return displayName(
        post?.author?.first_name,
        post?.author?.last_name,
        true,
      );
    }
    if (post?.author_id === user?.id && !post?.author?.first_name) {
      return 'Me ';
    }
    return `User ${post?.author_id} `;
  };

  const {
    post: postComment,
    delete: deleteComment,
    loading: isLoadingCommentPost,
  } = useFetch('/comments', { cachePolicy: CachePolicies.NO_CACHE });

  const isDisabled = isPosting || isLoadingCommentPost || (!media?.webviewPath && (!watchBody || watchBody.trim() === ''));

  const fetchPost = async () => {
    const fetchedPost = await get(
      `/posts/${id}`,
    );
    if (postResponse.ok) {
      setPost(fetchedPost);
      setReplyTo({ data: fetchedPost, type: 'post' });
    } else {
      toast.error(comments.postUnavailable, { id: 'postError' });
      history.replace('/tabs/feed');
    }
  };

  const resetReplyTo = () => {
    setReplyTo({ data: post, type: 'post' });
  };

  useEffect(() => {
    fetchPost();
    return () => {
      abort();
    };
  }, [id]);

  const handlePostLike = async (likeStatus) => {
    if (loadingLike) {
      return;
    }
    setLoadingLike(true);
    try {
      if (!likeStatus) {
        const likePayload = {
          user_id: user.id,
          post_id: post.id,
        };
        const { id: likeId } = await postLike('/likes', likePayload);
        setPost({ ...post, like_id: likeId });
      } else {
        deleteLike(`/likes/${likeStatus}`);
        setPost({ ...post, like_id: null });
      }
    } catch (e) {
      //
    }
    setLoadingLike(false);
  };

  const reportComment = (commentId) => {
    history.replace(`/comments/${commentId}/report`, { id });
  };

  const deleteSingleComment = async (comment) => {
    const removedComment = await deleteComment(`/${comment?.id}`);

    if (removedComment?.comment_id) {
      const updatedComments = post?.comments?.map((oneComment) => {
        if (oneComment?.id === removedComment?.comment_id) {
          return {
            ...oneComment,
            comments: oneComment?.comments.filter(
              (singleComment) => singleComment?.id !== removedComment?.id,
            ),
          };
        }
        return oneComment;
      });
      setPost({ ...post, comments: updatedComments });
    } else {
      setPost({
        ...post,
        comments: post?.comments.filter(
          (singleComment) => singleComment?.id !== removedComment?.id,
        ),
      });
    }
  };

  const handleMediaClose = () => {
    if (loading) controller.abort();
    setMedia(null);
  };

  const handleResetMedia = () => {
    setMedia(null);
    setIsVideo(null);
    handleMediaClose();
    setActionSheetResult(null);
    setIsPosting(false);
  };

  const onSubmit = async (data) => {
    if (isPosting) {
      return;
    }
    reset({ body: '' });
    if (replyTo.type === 'post') {
      const commentPayload = {
        ...data,
        post_id: post.id,
        author_id: user.id,
        attachments: media?.id ? [media?.id] : [],
      };
      setIsPosting(true);
      const newComment = await postComment(commentPayload);
      setPost((prev) => ({
        ...prev,
        comments: [
          ...prev.comments,
          {
            ...newComment,
            numberOfLikes: 0,
            author: {
              first_name: user.first_name,
              last_name: user.last_name,
              role: user?.role,
              profile_image: user.profile_image,
            },
          },
        ],
      }));
    } else {
      const commentPayload = {
        ...data,
        comment_id: replyTo?.type === 'subComment' ? replyTo?.topLevelComment?.id : replyTo?.data?.id,
        author_id: user.id,
        attachments: media?.id ? [media?.id] : [],
      };
      const newComment = await postComment(commentPayload);
      const updatedComments = post.comments.map((comment) => {
        if (comment.id === newComment.comment_id) {
          const tempComment = { ...comment };
          if (tempComment?.comments) {
            tempComment.comments = [
              ...tempComment.comments,
              {
                ...newComment,
                numberOfLikes: 0,
                author: {
                  first_name: user?.first_name,
                  last_name: user.last_name,
                  role: user?.role,
                  profile_image: user?.profile_image,
                },
              },
            ];
          } else {
            tempComment.comments = [
              {
                ...newComment,
                numberOfLikes: 0,
                author: {
                  first_name: user?.first_name,
                  last_name: user.last_name,
                  role: user?.role,
                  profile_image: user?.profile_image,
                },
              },
            ];
          }
          return tempComment;
        }
        return comment;
      });
      setPost({ ...post, comments: updatedComments });
    }
    setReplyTo({ data: post, type: 'post' });
    handleResetMedia();
  };

  const handlePhoto = async () => {
    if (loading) {
      return;
    }

    try {
      const { media: photo, webviewPath } = await takePhoto();
      console.log('Create Comment ~ takePhoto response: ', { photo, webviewPath });
      if (photo) {
        setMedia({ webviewPath, id: photo.id, type: 'photo' });
      }
      setIsVideo(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleWebVideo = async () => {
    const { media: video } = await takeWebVideo();
    if (video) {
      setMedia({ webviewPath: video.uri, id: video.id, type: 'video' });
      setIsVideo(true);
    }
  };

  const handleMobileVideo = async () => {
    const { media: video, webviewPath } = await takeVideo();
    if (video) {
      setMedia({ webviewPath, id: video.id, type: 'video' });
      setIsVideo(true);
    }
  };

  const handleVideo = async () => {
    if (loading) {
      return;
    }
    const uploader = platform?.isNativePlatform ? handleMobileVideo : handleWebVideo;

    try {
      await uploader();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handlePdf = async () => {
    if (loading) {
      return;
    }
    try {
      const { webviewPath, media: pdf } = await takePdf();
      if (pdf) {
        setMedia({ webviewPath, id: pdf.id, type: 'pdf' });
        setActionSheetResult(null);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleAction = (action) => {
    switch (action) {
      case 'photo':
        handlePhoto();
        break;
      case 'video':
        handleVideo();
        break;
      default:
        resetReplyTo();
    }
  };

  const presentActionSheet = (buttons) => {
    present({
      buttons,
      onDidDismiss: ({ detail }) => setActionSheetResult(detail),
    });
  };

  const handlePresentActionSheet = () => {
    const actionSheetButtons = getMediaActionButtons();
    presentActionSheet(actionSheetButtons);
  };

  const renderReplyToName = () => {
    const author = replyTo?.data?.author;
    const authorId = replyTo?.data?.author_id;
    const userId = user?.id;

    if (author?.role === 'admin') {
      return comments.coachLubav;
    }
    if (!author?.first_name) {
      return authorId === userId ? comments.me : `${comments.user} ${authorId}`;
    }
    return displayName(author.first_name, author?.last_name);
  };

  const renderCommentMedia = () => {
    if (post?.attachments[0]?.s3_key.startsWith('images')) {
      return (
        <IonCard style={imgStyle}>
          <IonImg src={post?.attachments[0]?.uri} />
        </IonCard>
      );
    }
    if (post?.attachments[0]?.s3_key.startsWith('videos')) {
      return (
        <div className="post-image">
          <VideoPlayer videos={post?.attachments[0]?.transcoded_videos} />
        </div>
      );
    }
    if (post?.attachments[0]?.s3_key.startsWith('pdfs')) {
      return (
        <PdfIndicator attachment={post.attachments[0]} overrideStyle={{ margin: '.5rem 0 0 0' }} />
      );
    }
    return null;
  };

  useEffect(() => {
    if (actionSheetResult?.data) {
      const {
        data: { action },
      } = actionSheetResult;
      handleAction(action);
    }
  }, [actionSheetResult]);

  if (!post) {
    return (
      <IonPage>
        <IonContent />
      </IonPage>
    );
  }

  return (
    <PageWithBackButton backButtonText="Comments">
      <IonContent>
        <div className="comments-container">
          {post.author && (
            <div className="comment-container comment-post-caption d-flex">
              <IonRouterLink routerLink={postAuthorIsAdmin ? adminRoute : userRoute}>
                {postAuthorIsAdmin
                  ? <AvatarAdmin style={{ marginRight: '1rem' }} />
                  : (
                    <AvatarRole
                      unsetTopMargin
                      user={post?.author}
                      style={{ marginRight: '1rem', marginTop: '-6px' }}
                    />
                  )}
              </IonRouterLink>
              <div className="comment-caption-wrapper">
                <div className="comment-caption">
                  <IonRouterLink routerLink={postAuthorIsAdmin ? adminRoute : userRoute}>
                    <span className="comment-name">
                      {computeDisplayName()}
                    </span>
                  </IonRouterLink>
                  {renderCommentBody(post)}
                  <br />
                  {renderCommentMedia()}
                </div>
                <span className="comment-post-time">
                  {formatDistanceToNowStrict(parseISO(post?.created_at), {
                    addSuffix: true,
                  })}
                </span>
              </div>
              <IonButton
                style={{
                  position: 'relative',
                  bottom: '0.9rem',
                  '--padding-end': '0',
                }}
                fill="clear"
                size="small"
                onClick={() => handlePostLike(post.like_id)}
              >
                <IonIcon
                  style={{
                    color: `${post.like_id ? 'var(--ion-color-warning)' : 'rgba(45, 45, 45, 0.42)'}`,
                    minWidth: '13px',
                    minHeight: '13px',
                  }}
                  src={
                    post.like_id
                      ? 'assets/icon/heart.svg'
                      : 'assets/icon/heartOutline.svg'
                  }
                />
              </IonButton>
            </div>
          )}
          <div style={{ marginBottom: '16px' }}>
            {post?.comments?.length > 0
              && post?.comments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  setReplyTo={setReplyTo}
                  deleteComment={deleteSingleComment}
                  resetReplyTo={resetReplyTo}
                  reportComment={reportComment}
                  expand
                />
              ))}
          </div>
        </div>
      </IonContent>
      <IonFooter style={{ backgroundColor: '#fff' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="comment-reply">
            <Typography className="reply-to">
              {comments.replyingTo}
              {' '}
              {renderReplyToName()}
            </Typography>
            <BackrsButton
              label={comments.post}
              onClick={handleSubmit(onSubmit)}
              isDisabled={isDisabled}
              disableRipple
              buttonStylesOverride={postBtnStyle}
            />
          </div>
          <div className="comment-input-container">
            <SendIconAvatar user={user} />
            <div className="comment-input-wrapper">
              <Stack sx={{ flexDirection: 'column', flexGrow: 1 }}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <IonTextarea
                      className="comment-input"
                      placeholder="Add your comment"
                      onIonChange={onChange}
                      value={value}
                      rows="1"
                      autoGrow
                      required
                      spellcheck
                      maxlength={1000}
                      style={textAreaStyles}
                      autocapitalize="on"
                    />
                  )}
                  control={control}
                  name="body"
                />
                <Box>
                  {error && (
                    <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
                      {displayErrorText(error)}
                    </Alert>
                  )}
                  {(media?.webviewPath || loading) && !error && (
                    <IonItem
                      detail={false}
                      lines="none"
                      className="post-option-item post-media-preview"
                      style={{ marginLeft: '0.625rem', lineHeight: 'normal' }}
                    >
                      {loading && (
                        <Box sx={{ paddingRight: '.75rem' }}>
                          <CircularProgress size={20} />
                        </Box>
                      )}
                      {!loading && !isVideo && media?.webviewPath && media?.type !== 'pdf' && (
                        <IonThumbnail slot="start" className="post-photo-thumbnail">
                          <img src={media?.webviewPath} alt="current comment" style={{ borderRadius: '8px' }} />
                        </IonThumbnail>
                      )}
                      {!loading && isVideo && media?.webviewPath && (
                        <IonIcon
                          style={{ marginRight: '1rem', color: 'inherit' }}
                          icon={videocam}
                        />
                      )}
                      <IonLabel>
                        {displayAttachmentLabel(loading, media, true, width)}
                      </IonLabel>
                      <IonButton fill="clear" onClick={() => handleMediaClose()}>
                        <IonIcon style={{ color: 'grey' }} slot="icon-only" icon={closeCircle} />
                      </IonButton>
                    </IonItem>
                  )}
                </Box>
              </Stack>
              <Stack sx={iconContainer}>
                <IonItem
                  button
                  detail={false}
                  lines="none"
                  className="post-option-item"
                  onClick={handlePresentActionSheet}
                  style={mediaIcon}
                >
                  <IonIcon
                    style={{
                      color: '#063D8F',
                      fontSize: '20px',
                    }}
                    src="assets/icon/addPhoto.svg"
                  />
                </IonItem>
                <IonItem
                  button
                  detail={false}
                  lines="none"
                  className="post-option-item"
                  onClick={handlePdf}
                  style={mediaIcon}
                >
                  <IonIcon
                    style={{
                      color: '#063D8F',
                      fontSize: '24px',
                    }}
                    src="assets/icon/file-pdf.svg"
                  />
                </IonItem>
              </Stack>
            </div>
          </div>
        </form>
      </IonFooter>
    </PageWithBackButton>
  );
};

export default Comments;
