import React, { useState } from 'react';
import {
  Typography, Stack,
} from '@mui/material';
import BaseModal from '../common/atoms/BaseModal/BaseModal';
import WholeMeProfileMessageButton from '../pages/wholeme/molecules/wholeMeProfileMessageButton/WholeMeProfileMessageButton';
import { useAuthContext } from '../providers/auth-provider';
import appStrings from '../common/app_strings';

const AgeRestrictionModal = () => {
  const [isMessageButtonLoading, setIsMessageButtonLoading] = useState(false);
  const { user } = useAuthContext();
  const {
    ageRestricted: {
      title, body1, body2, ctaLabel,
    },
  } = appStrings;
  return (
    <BaseModal isOpen showExit={false} handleClose={() => { }}>
      <Stack
        sx={{
          padding: 2, justifyContent: 'center', alignItems: 'center', gap: 3,
        }}
      >
        <Typography sx={{ color: '#03295F', fontSize: '22px', fontWeight: 700 }}>{title}</Typography>
        <Typography>
          {body1}
          <br />
          <br />
          {body2}
        </Typography>
        <WholeMeProfileMessageButton
          isViewingAdmin
          userId={user?.id}
          label={ctaLabel}
          setIsLoading={setIsMessageButtonLoading}
          isMessageButtonLoading={isMessageButtonLoading}
          buttonStyleOverride={{ background: '#063D8F', marginBottom: 2 }}
        />
      </Stack>
    </BaseModal>
  );
};

export default AgeRestrictionModal;
