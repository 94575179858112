import React from 'react';
import { ethnicityData } from './ethnicityData';
import DropdownListPage from '../../../molecules/dropdownListPage/DropdownListPage';

const ProfileIdentityIntakeEthnicityPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const dataFetcher = wholeMeClient.fetchEthnicityState;
  const dataSaver = wholeMeClient.saveEthnicityState;

  return (
    <DropdownListPage
      fields={ethnicityData}
      paginationMeta={paginationMeta}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      title="Ethnicity"
      subtitle="This info helps us ensure our proteges are able to connect with a diversity of backrs."
      backgroundOverlay="/assets/profile/identity/ethnicity-overlay.svg"
      categoryId="identity"
      questionId="ethnicity"
      dataFetcher={dataFetcher}
      dataSaver={dataSaver}
      allowForDisabledButtonState
      mainImage="assets/profile/identity/affinity-hands-update.svg"
      titleStyleOverride={{
        subHeaderStyles: {
          padding: '0 40px 20px 40px',
        },
      }}
      exitIconOnClickOverride={exitIconOnClickOverride}
    />
  );
};

export default ProfileIdentityIntakeEthnicityPage;
