import React from 'react';
import SelectableSquareItem from 'common/atoms/selectableSquareItem/SelectableSquareItem';
import PropTypes from 'prop-types';
import {
  itemContainerStyle,
  imageContainerStyle,
  titleContainerStyle,
  titleStyle,
  plusIconStyle,
} from 'pages/wholeme/molecules/horizontalScrollItem/HorizontalScrollItemStyles';

const HorizontalScrollItem = ({
  icon,
  type,
  text,
  onClick,
  stylesOverrides,
}) => (
  <SelectableSquareItem
    showPlusIcon
    itemContainerStyle={itemContainerStyle(type)}
    title={text}
    onClickCallBack={onClick}
    image={icon}
    imageContainerStyle={imageContainerStyle}
    titleStyle={titleStyle}
    titleContainerStyle={titleContainerStyle}
    plusIconStyle={plusIconStyle}
    stylesOverrides={stylesOverrides}
  />
);
HorizontalScrollItem.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

HorizontalScrollItem.defaultProps = {
  icon: '',
  type: '',
  text: '',
  onClick: () => {},
};

export default HorizontalScrollItem;
