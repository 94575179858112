import React from 'react';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';

import WholeMeProfileSectionRow from '../wholeMeProfileSectionRow/WholeMeProfileSectionRow';
import {
  pillOverrideStyles,
  pillRendererOverrideStyles,
} from '../wholeMeProfileSection/wholeMeProfileSectionStyles';

const WholeMeProfileSectionDetail = ({
  onEdit, onPost, label, items = [], categoryId, stepId, labelStyle, isViewOnly, pillStyles,
}) => {
  const pillContainerStylesOverride = isViewOnly
    ? pillRendererOverrideStyles
    : {
      ...pillRendererOverrideStyles,
      marginLeft: '22px',
    };
  return (
    <>
      <WholeMeProfileSectionRow
        onPost={onPost}
        onEdit={onEdit}
        label={label}
        isCreate={items.length < 1}
        navigationMeta={{
          categoryId,
          stepId,
        }}
        labelStyle={labelStyle}
      />
      <PillRenderer
        isSelectable={false}
        pillStyles={{ ...pillOverrideStyles, ...pillStyles }}
        containerStyleOverrides={pillContainerStylesOverride}
        handlePillClick={
        onPost ? (item, clickContext) => onPost(item, clickContext?.infoUrl) : undefined
      }
        items={items.map((n) => ({ value: n }))}
      />
    </>
  );
};

export default WholeMeProfileSectionDetail;
