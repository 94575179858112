import React from 'react';
import {
  timer,
  bonfire,
  rocket,
  football,
  library,
  person,
  extensionPuzzle,
  school,
} from 'ionicons/icons';
import './LegacyProfile.css';
import { useHistory } from 'react-router';
import WholeMeProfileQuotationTemplate from '../wholeMeProfileQuotation/WholeMeProfileQuotationTemplate';

const LegacyProfile = ({
  legacyProfileData: userPublicData, onPost, onEdit, isViewOnly,
}) => {
  const history = useHistory();
  const possiblePublicInfo = {
    pronouns: {
      icon: person,
    },
    school: {
      icon: library,
    },
    school_type: {
      icon: school,
    },
    grade: {
      icon: timer,
    },
    user_bio: {
      icon: null,
    },
    activities: {
      icon: football,
    },
    passions: {
      icon: bonfire,
    },
    aspirations: {
      icon: rocket,
    },
    activations: {
      icon: extensionPuzzle,
    },
  };
  const handleEdit = (type = 'additional') => {
    if (!onEdit) {
      return undefined;
    }
    return history.push(`/tabs/profile/edit-${type}-info`);
  };
  return (
    <>
      {Object.keys(possiblePublicInfo).map((info) => {
        if (info === 'grade') {
          return (
            userPublicData?.[info] && (
              <WholeMeProfileQuotationTemplate
                key={info}
                label={`Grade: ${userPublicData?.[info]}`}
                postHandler={onPost ? () => onPost(`Grade: ${userPublicData?.[info]}`) : undefined}
                editHandler={() => handleEdit('profile')}
                isViewOnly={isViewOnly}
              />
            )
          );
        }
        if (info === 'school') {
          return (
            userPublicData?.[info] && (
              <WholeMeProfileQuotationTemplate
                key={info}
                label={`${userPublicData?.[info]}`}
                postHandler={onPost ? () => onPost(userPublicData?.[info]) : undefined}
                editHandler={() => handleEdit('profile')}
                isViewOnly={isViewOnly}
              />
            )
          );
        }
        if (info === 'school_type') {
          return (
            userPublicData?.[info] && (
            <WholeMeProfileQuotationTemplate
              key={info}
              label={`${userPublicData?.[info]}`}
              postHandler={onPost ? () => onPost(userPublicData?.[info]) : undefined}
              editHandler={() => handleEdit('profile')}
              isViewOnly={isViewOnly}
            />
            )
          );
        }
        if (info === 'user_bio') {
          return (
            userPublicData?.[info] && (
              <WholeMeProfileQuotationTemplate
                key={info}
                label={`${userPublicData?.[info]}`}
                postHandler={onPost ? () => onPost(userPublicData?.[info]) : undefined}
                editHandler={() => handleEdit()}
                isViewOnly={isViewOnly}
              />
            )
          );
        }
        return (
          userPublicData?.[info] && (
            <WholeMeProfileQuotationTemplate
              key={info}
              label={`${userPublicData?.[info]}`}
              postHandler={onPost ? () => onPost(userPublicData?.[info]) : undefined}
              editHandler={() => handleEdit()}
              isViewOnly={isViewOnly}
            />
          )
        );
      })}
    </>
  );
};

export default LegacyProfile;
