import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { healthIntakeXpData } from './healthIntakeXpData';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';

const ProfileHealthIntakeXpPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={healthIntakeXpData}
    itemVariant="rectangle"
    selectionType="multi"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Health Experiences"
    subtitle="Select any that apply to you."
    categoryId="health"
    questionId="health_xp"
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchHealthXpState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveHealthXpState)}
    backgroundOverlay="/assets/profile/health/health-experiences-overlay.svg"
  />
);

export default ProfileHealthIntakeXpPage;
