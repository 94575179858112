import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonRouterLink,
  IonIcon,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';
import useFetch, { CachePolicies } from 'use-http';
import { Box, Typography, Stack } from '@mui/material';
import appStrings from '../../common/app_strings';
import Carousel from '../../common/atoms/carousel/Carousel';
import DesktopCarousel from '../../common/atoms/desktopCarousel/DesktopCarousel';
import { buildFirstTimeUserWorkFlowCards } from './utils';
import { useAppState } from '../../providers/app-state/app-state-provider';

const { onboarding, workflows: { firstTimeUser: workflowStrings } } = appStrings;

const FirstTimeUserWorkflow = () => {
  const history = useHistory();
  const location = useLocation();
  const { get } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const [workFlow, setWorkFlow] = useState(null);
  const { platform } = useAppState();

  const goToBackrAcctCreation = () => history.push('/register/register/backr');

  useEffect(async () => {
    try {
      get('/workflow-meta/first-time-user').then((result) => {
        setWorkFlow(result);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(workflowStrings.errors.getFailed, e);

      if (location.state?.redirectTo) {
        history.replace(location.state.redirectTo);
        return;
      }

      history.push('/register/login');
    }
  }, []);

  if (workFlow?.media) {
    workFlow.media[workFlow.media.length - 1].cta = {
      label: workflowStrings.ctaLabel,
      callback: goToBackrAcctCreation,
    };
  }

  const cards = workFlow?.media
    ? buildFirstTimeUserWorkFlowCards(workFlow.media)
    : [];

  return (
    <IonPage>
      <IonContent className="teams-feed">
        <IonHeader className="ion-no-border" mode="ios">
          <IonToolbar
            className="login-button-toolbar"
            style={{ '--background': 'var(--ion-background-color)' }}
            mode="ios"
          >
            <IonButtons slot="end">
              <IonRouterLink routerLink="/register/login">
                <IonButton mode="ios">
                  <Stack spacing={1} direction="row" alignItems="center">
                    <IonIcon
                      style={{ fontSize: '18px' }}
                      slot="icon-only"
                      src="/assets/login-lock.svg"
                      mode="ios"
                    />
                    <Typography
                      sx={{ color: '#063D8F', fontSize: 16, fontWeight: 700 }}
                    >
                      {onboarding.login}
                    </Typography>
                  </Stack>
                </IonButton>
              </IonRouterLink>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <Box
          sx={{
            width: 1,
            height: 'calc(100vh - 44px - var(--ion-safe-area-top))',
          }}
        >
          {workFlow?.media.length && platform?.isNativePlatform && (
            <Carousel
              slides={cards}
              pagination={{
                dynamicBullets: true,
                dynamicMainBullets: 2,
                hideLast: true,
              }}
            />
          )}
          {workFlow?.media.length && !platform?.isNativePlatform && (
            <DesktopCarousel
              slides={cards}
            />
          )}
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default FirstTimeUserWorkflow;
