import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import useFetch from 'use-http';
import { toast } from 'react-hot-toast';
import ProfileActionButton from '../../molecules/Profile/ProfileActionButton';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import { useAuthContext } from '../../../providers/auth-provider';
import BackingModal from '../../../pages/wholeme/molecules/backingModal/BackingModal';
import DonorboxModal from '../../molecules/DonorboxModal';
import { openInBrowser } from '../../../helpers/broswer';
import { gridItemStyle } from './ActionsGridStyles';
import { getBackButtonLabel, getButtonStyles } from './utils';
import appStrings from '../../app_strings';

const ActionsGrid = ({
  permissions, profile, refreshProfile,
}) => {
  const params = useParams();
  const subjectUserId = params?.userId || params?.id;
  const { user } = useAuthContext();
  const { post, response } = useFetch();
  const [isMessageButtonLoading, setIsMessageButtonLoading] = useState(false);
  const [backingModalOpen, setbackingModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { role, calendly_url: calendlyUrl } = profile;
  const userRole = role;
  const { platform } = useAppState();
  const history = useHistory();

  const {
    'direct-message': directMessage, meet, invest, propose,
  } = permissions || {};
  const {
    profile:
    {
      actionButtons: {
        message, messageAdmin, meet: meetLabel, invest: investLabel,
      },
    },
  } = appStrings;

  const isSubjectBackr = userRole === 'backr';
  const isSubjectAdmin = userRole === 'admin';
  const isAccessorAdmin = user?.role === 'admin';
  const accessorUserId = user?.id;

  const backingModalMeta = {
    subjectUserId: parseInt(subjectUserId, 10),
    profile,
    accessorUserId,
  };

  const handleInvestClick = () => {
    if (user?.role === 'backr') {
      setIsOpen(true);
    }
  };
  const handleInvestClose = () => {
    setIsOpen(false);
  };

  const investModalMeta = {
    isOpen,
    handleClose: handleInvestClose,
    widgetSrc: `https://donorbox.org/embed/${profile?.donorboxId}?default_interval=m&hide_donation_meter=true&enable_auto_scroll=false`,
    widgetTitle: 'donorbox campaign',
    iframeOverrideStyle: {
      maxWidth: '500px',
      minWidth: '310px',
      height: '65vh',
      maxHeight: 'none! important',
    },
  };

  const handleOpenBackModal = () => {
    setbackingModalOpen(true);
  };

  const handleCloseBackModal = () => {
    setbackingModalOpen(false);
  };

  const handleOnSuccess = async () => {
    await refreshProfile({ loadingSpinner: false });
  };

  const handleCalendlyClick = async () => {
    if (platform?.isNativePlatform) {
      await openInBrowser(calendlyUrl);
    } else {
      window.open(calendlyUrl, '_blank');
    }
  };

  const handleMessageButtonClick = async () => {
    setIsMessageButtonLoading(true);
    let conversationSid = null;
    try {
      const type = (isSubjectAdmin || isAccessorAdmin) ? 'community' : 'p2p';
      const res = await post(`/conversations/request-sid/${subjectUserId}/${type}`);
      if (response.ok && res?.sid) {
        conversationSid = res.sid;
      } else {
        throw new Error('request failed');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
      toast.error('Direct Messaging Unavailable. Try again later.');
    } finally {
      if (conversationSid) {
        history.push(`/chat/${conversationSid}`);
      }
      setIsMessageButtonLoading(false);
    }
  };

  const getBackButtonMeta = (subjectProfile) => {
    const label = getBackButtonLabel(subjectProfile);
    const isBackDisabled = !isSubjectBackr && (!propose?.allowed && !profile?.relationshipStatus);
    const callback = label === 'Back' ? handleOpenBackModal : null;
    const isDisabled = subjectProfile?.role === 'protege' ? isBackDisabled : false;
    const itemMeta = {
      elementContent: subjectProfile?.teamCount || 0,
      iconSrc: 'assets/profile/wholeMe/button/p-role-button-icon.svg',
      isElement: true,
    };

    return {
      callback,
      isDisabled,
      label,
      ...subjectProfile?.role === 'backr' && itemMeta,
    };
  };

  const getAffinityButtonMeta = (subjectProfile) => {
    const isInvestDisabled = (!isSubjectBackr && !invest?.allowed) || !subjectProfile?.donorboxId;
    const label = subjectProfile?.role === 'protege' ? investLabel : null;
    const isDisabled = label === investLabel ? isInvestDisabled : false;
    const callback = label === investLabel ? handleInvestClick : null;
    const itemMeta = {
      elementContent: subjectProfile?.groupCount || 0,
      iconSrc: 'assets/profile/wholeMe/button/n-role-button-icon.svg',
      isElement: true,
    };

    return {
      callback,
      isDisabled,
      ...subjectProfile?.role === 'backr' && itemMeta,
      label,
    };
  };

  const backButtonMeta = getBackButtonMeta(profile);
  const affinityButtonMeta = getAffinityButtonMeta(profile);
  const isMeetDisabled = !meet?.allowed || !calendlyUrl;

  if (userRole === 'admin') {
    return (
      <Grid container spacing={0.2}>
        <Grid sx={gridItemStyle} item xs={12}>
          <ProfileActionButton
            meta={{ callback: handleMessageButtonClick, label: messageAdmin }}
          />
        </Grid>
        <Grid sx={gridItemStyle} item xs={12}>
          <ProfileActionButton
            meta={{ callback: handleCalendlyClick, label: meetLabel }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container rowSpacing={0.2} columnSpacing={1}>
        <Grid sx={gridItemStyle} item xs={6}>
          {/* this will be back/team count */}
          <ProfileActionButton
            buttonStylesOverride={getButtonStyles(backButtonMeta?.label)}
            meta={backButtonMeta}
            isDisabled={backButtonMeta?.isDisabled}
          />
        </Grid>
        <Grid sx={gridItemStyle} item xs={6}>
          {/* this will be message button */}
          <ProfileActionButton
            meta={{ callback: handleMessageButtonClick, label: message }}
            isDisabled={!directMessage?.allowed}
            isLoading={isMessageButtonLoading}
          />
        </Grid>
        <Grid sx={gridItemStyle} item xs={6}>
          {/* this will be invest/affinity count */}
          <ProfileActionButton
            buttonStylesOverride={getButtonStyles(affinityButtonMeta?.label)}
            meta={affinityButtonMeta}
            isDisabled={affinityButtonMeta?.isDisabled}
          />
        </Grid>
        <Grid sx={gridItemStyle} item xs={6}>
          {/* this will be meet button */}
          <ProfileActionButton
            meta={{ callback: handleCalendlyClick, label: meetLabel }}
            isDisabled={isMeetDisabled}
          />
        </Grid>
      </Grid>
      <DonorboxModal {...investModalMeta} />
      <BackingModal
        meta={backingModalMeta}
        isOpen={backingModalOpen}
        handleClose={handleCloseBackModal}
        onSuccess={handleOnSuccess}
      />
    </>
  );
};

export default ActionsGrid;
