const avatarWidthHeight = '8rem';

export const noWrapStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const profileContainerStyle = {
  alignItems: 'center',
  marginBottom: '15px',
};

export const nameContainerStyle = {
  position: 'relative',
  top: -30,
  maxWidth: avatarWidthHeight,
  maxHeight: '54px',
  background: '#063D8F',
  p: '10px',
  borderRadius: 2,
  zIndex: 10,
};
export const childrenContainerStyle = {
  position: 'relative',
  width: '100%',
  minHeight: '142px',
  background: '#FFFFFF',
  borderRadius: 2,
  zIndex: 1,
};
export const avatarContainerStyles = {
  px: '44px',
};
export const avatarStyle = {
  width: avatarWidthHeight,
  height: avatarWidthHeight,
  fontSize: 60,
};
export const nameStyle = {
  color: '#fff',
  fontSize: '22px',
  fontWeight: 700,
  lineHeight: '22px',
  maxWidth: '160px',
  ...noWrapStyles,
};
export const editButtonStyles = {
  padding: 0,
  position: 'absolute',
  top: '12px',
  left: '27px',
};
export const columnContainerStyle = {
  padding: '0 25px 25px 25px',
  marginTop: '25px',
};

export const businessStyle = {
  color: '#A7C0E6',
  textAlign: 'center',
  fontSize: '10px',
  fontWeight: '400',
  letterSpacing: '1.9px',
  textTransform: 'uppercase',
  justifyContent: 'center',
  alignItems: 'center',
};

export const BPIconStyle = {
  width: '2.4rem',
  height: '2.4rem',
  zIndex: '50',
  position: 'absolute',
  bottom: '0rem',
  left: '6rem',
};
export const AdminIconStyle = {
  ...BPIconStyle,
  bottom: '.5rem',
};

export const imageContainerStyle = {
  display: 'grid',
  gridTemplateColumns: '1.25fr 1.75fr',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'space-around',
};
