import React from 'react';
import { Stack } from '@mui/material';
import WholeMeProfileSectionDetail from 'pages/wholeme/molecules/wholeMeProfileSectionDetail/WholeMeProfileSectionDetail';
import { RelationshipsIcon, RoundPlusIcon } from 'pages/wholeme/icons/Icons';
import WholeMeProfileSection from '../wholeMeProfileSection/WholeMeProfileSection';
import WholeMeProfileSectionRow from '../wholeMeProfileSectionRow/WholeMeProfileSectionRow';
import { fieldInfoUrlFor, fieldLabelFor } from '../../util/profile_field_data';
import { resolveDefaultIcons } from '../../util/profile_util';

const WholeMeProfileRelationships = ({
  profile, onEdit, onPost, isViewOnly, isViewingAdmin,
}) => {
  const {
    family = [], childhood = [], languages = [], network = [],
  } = profile;

  const hasRelationships = family.length > 0 || childhood.length > 0 || languages.length > 0;
  if (!onEdit && !hasRelationships && network.length < 1) {
    return null;
  }

  const yesNetwork = network.filter((n) => n.value === 'yes');

  const viewStyle = {
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: 'Sarabun, arial, sans-serif',
    fontStyle: 'normal',
  };
  const relationshipsStyle = {
    color: '#5B5B5B',
    fontWeight: '400',
  };
  const networkStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: '800',
    color: '#8C8C8C',
    gap: '3px',
    marginTop: '2px',
  };
  const editLabel = {
    fontSize: '14px',
    fontFamily: 'Sarabun, arial, sans-serif',
    color: '#063D8F',
    fontWeight: '700',
  };

  const renderEdit = () => (
    <>
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Family"
        items={family.map((n) => fieldLabelFor(n.option_id, 'relationships', 'family'))}
        categoryId="relationships"
        stepId="family"
        labelStyle={editLabel}
        isViewOnly={isViewOnly}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Childhood"
        items={childhood.map((n) => fieldLabelFor(n.option_id, 'relationships', 'childhood'))}
        categoryId="relationships"
        stepId="childhood"
        labelStyle={editLabel}
        isViewOnly={isViewOnly}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Languages"
        items={languages.map((n) => n.value)}
        categoryId="relationships"
        stepId="languages"
        labelStyle={editLabel}
        isViewOnly={isViewOnly}
      />

      <Stack>
        {yesNetwork.length > 0 && (
          <div style={{ paddingTop: '12px' }}>
            {yesNetwork.map((n) => (
              <div key={n.option_id} style={networkStyle}>
                <RoundPlusIcon width={21} height={21} marginLeft={-3} marginRight={3} marginTop={2} />
                <span>{`I know ${fieldLabelFor(n.option_id, 'network', 'network')}`}</span>
              </div>
            ))}
          </div>
        )}
      </Stack>
    </>
  );
  const renderView = () => (
    <div style={viewStyle}>
      {childhood.length > 0
        && childhood.map((n) => (
          <WholeMeProfileSectionRow
            key={n.option_id}
            onPost={onPost}
            iconSrc={resolveDefaultIcons('childhood', n.option_id)}
            infoUrl={fieldInfoUrlFor(n.option_id, n.category_id, n.question_id)}
            label={fieldLabelFor(n.option_id, n.category_id, n.question_id)}
            labelStyle={relationshipsStyle}
          />
        ))}
      {family.length > 0
        && family.map((n) => (
          <WholeMeProfileSectionRow
            key={n.option_id}
            onPost={onPost}
            iconSrc={resolveDefaultIcons('relationships_family', n.option_id)}
            infoUrl={fieldInfoUrlFor(n.option_id, n.category_id, n.question_id)}
            label={fieldLabelFor(n.option_id, n.category_id, n.question_id)}
            labelStyle={relationshipsStyle}
          />
        ))}
      <Stack>
        {yesNetwork.length > 0 && (
          <div style={{ paddingTop: '12px' }}>
            {yesNetwork.map((n) => (
              // eslint-disable-next-line max-len
              <div key={n.option_id} style={networkStyle} onClick={() => !isViewingAdmin && onPost(`I know ${fieldLabelFor(n.option_id, 'network', 'network')}`)}>
                <RoundPlusIcon width={21} height={21} marginLeft={-3} marginRight={3} />
                <span>{`I know ${fieldLabelFor(n.option_id, 'network', 'network')}`}</span>
              </div>
            ))}
          </div>
        )}
      </Stack>
    </div>
  );
  return (
    <WholeMeProfileSection
      title="Relationships"
      editable={!!onEdit}
      titleIcon={<RelationshipsIcon width={67} />}
    >
      {onEdit ? renderEdit() : renderView()}
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileRelationships;
