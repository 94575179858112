import React, { useState, useEffect } from 'react';
import {
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ProfileActionButton from '../../../common/molecules/Profile/ProfileActionButton';
import {
  protegeCountSelected,
  backrCountSelected,
  defaultStyles,
  cardHeaderStyles,
  underLinedTextStyles,
  networkContainerStyle,
  containerStyle,
  triangleStyles,
  affinityCountContainer,
} from './AffinityNetworkStyles';
import CardAtom from '../../../common/atoms/card/CardAtom';
import NetworkSponsorshipRender from '../networkSponsorship/NetworkSponsorshipRender';
import appStrings from '../../../common/app_strings';
import BaseModal from '../../../common/atoms/BaseModal/BaseModal';
import WholeMeprofileMessageButton from '../../wholeme/molecules/wholeMeProfileMessageButton/WholeMeProfileMessageButton';
import { useAuthContext } from '../../../providers/auth-provider';
import useWindowDimensions from '../../../helpers/hooks';
import { feedSelector } from '../../../redux/slices/feed';

const { affinityNetwork: { howDidIJoinNetwork, affinityModal: { title, body, message } } } = appStrings;

const Triangle = ({ isLoadComplete }) => (
  <img
    style={{ ...triangleStyles, opacity: isLoadComplete ? 1 : 0 }}
    alt="triangle"
    src="/assets/affinity-list-triangle.svg"
  />
);

const AffinityModal = ({ isOpen, setOpen, userId }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={() => setOpen(false)}
      modalOverrideStyles={{ padding: 0 }}
    >
      <Stack sx={{
        alignItems: 'center', margin: '.5rem 2rem 1rem 2rem', gap: '1.5rem',
      }}
      >
        <Typography sx={{ color: '#03295F', fontSize: '22px', fontWeight: 700 }}>{title}</Typography>
        <Typography sx={{ color: '#5B5B5B' }}>{body}</Typography>
        <WholeMeprofileMessageButton
          isViewingAdmin
          userId={userId}
          label={message}
          setIsLoading={setIsLoading}
          isMessageButtonLoading={isLoading}
          buttonStyleOverride={{
            width: '100%',
            background: '#ED3049',
            '&:hover': {
              background: '#FF1838',
            },
          }}
        />
      </Stack>
    </BaseModal>
  );
};

const AffinityNetwork = ({
  role, setRole, isLoadComplete,
}) => {
  const { activeFilter } = useSelector(feedSelector);
  const [selected, setSelected] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const { user } = useAuthContext();
  const { width } = useWindowDimensions();

  const showProtegeButton = activeFilter?.protege_count && activeFilter.protege_count > 0;
  const showBackrButton = activeFilter?.backr_count && activeFilter.backr_count > 0;

  const handleClick = (memberRole) => {
    if (role === memberRole) {
      setRole(null);
      setSelected(false);
      return;
    }
    setRole(memberRole);
    setSelected(memberRole);
    setShowDescription(false);
  };

  const handleAffinityChange = () => {
    // if the affinity changes, so if the data is different, any buttons that are currently selected need to be unselected
    setRole(null);
    setSelected(false);
  };

  const handleDescriptionClick = () => {
    handleAffinityChange();
    setShowDescription(!showDescription);
  };

  const GroupDescription = (
    <Typography
      sx={underLinedTextStyles}
      onClick={() => setOpen(true)}
    >
      {howDidIJoinNetwork}
    </Typography>
  );
  const cardStyles = {
    opacity,
    transition: 'opacity 0.5s ease-in-out',
    p: width > 375 ? '1.5rem' : '.75rem',
    my: 1,
    bgcolor: '#E8E8DC',
    marginBottom: '2.5rem',
  };

  const groupNameMeta = {
    elementContent: activeFilter?.full_name,
    callback: handleDescriptionClick,
    isElement: true,
    iconSrc: '/assets/info-icon-grey.svg',
  };

  const protegeMeta = {
    elementContent: activeFilter?.protege_count,
    iconSrc: 'assets/profile/wholeMe/button/p-role-button-icon.svg',
    isElement: true,
  };

  const protegeSelectedMeta = {
    style: protegeCountSelected,
    iconSrc: 'assets/profile/wholeMe/button/p-role-button-icon-white.svg',
  };

  const backrMeta = {
    elementContent: activeFilter?.backr_count,
    iconSrc: 'assets/profile/wholeMe/button/b-role-button-icon.svg',
    isElement: true,
  };

  const backrSelectedMeta = {
    style: backrCountSelected,
    iconSrc: 'assets/profile/wholeMe/button/b-role-button-icon-white.svg',
  };

  useEffect(() => {
    if (showDescription) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  }, [showDescription]);

  useEffect(() => {
    handleAffinityChange();
  }, [activeFilter]);

  return (
    <Box sx={networkContainerStyle}>
      <Stack sx={{ ...containerStyle, paddingBottom: role && '1.5rem' }}>
        <Box>
          <ProfileActionButton
            meta={groupNameMeta}
            buttonStylesOverride={defaultStyles}
          />
        </Box>
        {showProtegeButton ? (
          <Box sx={affinityCountContainer}>
            <ProfileActionButton
              meta={protegeMeta}
              setIsSelected={() => handleClick('protege')}
              selectedMeta={protegeSelectedMeta}
              isSelected={selected === 'protege'}
              buttonStylesOverride={defaultStyles}
            />
            {role === 'protege' && <Triangle isLoadComplete={isLoadComplete} />}
          </Box>
        ) : null}
        {showBackrButton ? (
          <Box sx={affinityCountContainer}>
            <ProfileActionButton
              meta={backrMeta}
              selectedMeta={backrSelectedMeta}
              setIsSelected={() => handleClick('backr')}
              isSelected={selected === 'backr'}
              buttonStylesOverride={defaultStyles}
            />
            {role === 'backr' && <Triangle isLoadComplete={isLoadComplete} />}
          </Box>
        ) : null}
      </Stack>
      {
        showDescription && activeFilter?.description && (
          <CardAtom
            header={activeFilter.description}
            body={GroupDescription}
            hasDismissableIcon
            dismissCallback={() => setShowDescription(false)}
            containerStyles={cardStyles}
            headerStyles={cardHeaderStyles}
          />
        )
      }
      {activeFilter?.sponsors && activeFilter.sponsors.length > 0 && !role && (
        <Stack sx={{ px: 1 }}>
          <NetworkSponsorshipRender meta={activeFilter.sponsors} />
        </Stack>
      )}
      <AffinityModal isOpen={isOpen} setOpen={setOpen} userId={user?.id} />
    </Box>
  );
};

export default AffinityNetwork;
