import React,
{
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
} from '@ionic/react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import PropTypes from 'prop-types';
import {
  Box,
  Avatar,
  Stack,
  Typography,
} from '@mui/material';
import { displayName } from '../helpers/textManipulation';
import './MatchingCelebration.css';
import { buildCelebrationIcon } from './celebrations/celebration_icons';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const MatchingCelebration = ({ history }) => {
  const { id } = useParams();
  const [celebration, setCelebration] = useState(null);
  const [relatedUser, setRelatedUser] = useState(null);
  const { get: getCelebration, patch: patchCelebration } = useFetch();

  const goBack = () => {
    history.goBack();
  };

  const handleAvatarOnClick = useCallback(() => {
    history.push(`/profile/${relatedUser?.id}`);
  }, [relatedUser]);

  const handleCtaOnclick = useCallback(() => {
    if (celebration?.content.cta.action === 'post_to_target') {
      history.replace('/post', {
        postType: 'celebrations',
        postPrompt: celebration?.content?.cta?.postPrompt,
        id: celebration?.content?.postTarget,
      });
      return;
    }

    history.goBack();
  }, [celebration, history]);

  async function fetchCelebration(celebrationId) {
    const data = await getCelebration(`/celebrations/${celebrationId}?includeContent=true`);
    return data;
  }

  useEffect(() => {
    fetchCelebration(id).then(async (r) => {
      if (!r) {
        toast(
          'Celebration not found',
          {
            id: 'celebration-error',
            duration: 2500,
          },
        );
        goBack();
      } else {
        setCelebration(r);
        setRelatedUser(r.content.relatedProfile);
        await patchCelebration(`/celebrations/${id}`, { was_seen: true });
      }
    });
  }, []);

  const iconsToRender = celebration?.content?.icons;

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="back-button-toolbar"
          style={{ '--background': 'transparent' }}
        >
          <IonButtons slot="start">
            <IonBackButton
              icon="/assets/back-arrow-white.svg"
              style={{
                '--icon-font-size': '1.25rem',
                '--icon-padding-start': '8px',
                '--icon-padding-end': '12px',
                '--icon-padding-top': '4px',
                '--icon-margin-end': '6px',
                '--color': '#fff',
              }}
              className="back-button-custom"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="matching-celebration-content" fullscreen>
        {celebration && (
        <>
          <Box
            sx={{
              position: 'absolute',
              bgcolor: '#063d8f',
              width: 150,
              height: 150,
              top: -90,
              right: -25,
              zIndex: 10,
              borderRadius: '50%',
            }}
          />
          <img
            src="/assets/matching_shape_1.svg"
            alt=""
            role="presentation"
            className="matching-celebration-image-1"
          />
          <img
            src="/assets/matching_shape_2.svg"
            alt=""
            role="presentation"
            className="matching-celebration-image-2"
          />
          <img
            src="/assets/matching_shape_4.svg"
            alt=""
            role="presentation"
            className="matching-celebration-image-4"
          />
          <img
            src="/assets/matching_shape_5.svg"
            alt=""
            role="presentation"
            className="matching-celebration-image-5"
          />
          <Stack spacing={3} alignItems="center" sx={{ color: '#fff', mx: 3 }}>
            <Stack alignItems="center" spacing={2}>
              <Typography
                component="h1"
                sx={{
                  fontSize: 24,
                  fontWeight: 700,
                  textAlign: 'center',
                  marginTop: '5px',
                }}
              >
                {celebration.content.header}
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  lineHeight: 1.25,
                  maxWidth: '27ch',
                  fontSize: 16,
                }}
              >
                {celebration.content.subHeader}
              </Typography>
            </Stack>
            <Stack>
              <div className="match-avatar">
                <img
                  src="/assets/matching_shape_6.svg"
                  alt=""
                  role="presentation"
                  className="matching-celebration-image-6"
                />
                <Avatar
                  src={relatedUser?.profile_image}
                  sx={{ width: 160, height: 160, backgroundColor: 'transparent' }}
                  onClick={handleAvatarOnClick}
                >
                  <img sx={{ width: 160, height: 160 }} src={defaultProfileImage} alt="profile" />
                </Avatar>
              </div>
            </Stack>
            <Stack spacing={1}>
              {relatedUser?.first_name && relatedUser?.last_name ? (
                <Typography
                  component="h2"
                  sx={{
                    color: '#F7AE41', fontSize: 22, fontWeight: 700, textAlign: 'center',
                  }}
                >
                  {displayName(relatedUser?.first_name, relatedUser?.last_name, true)}
                </Typography>
              ) : null}
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {iconsToRender.length && iconsToRender.map(
                  (icon) => buildCelebrationIcon({
                    type: icon,
                    userData: relatedUser,
                  }),
                )}
              </Stack>
            </Stack>
            <Stack
              spacing={0.5}
              sx={{
                width: 0.9,
                position: 'absolute',
                bottom: 40,
                '@media screen and (max-height: 736px)': {
                  position: 'static',
                  marginTop: '5px',
                },
              }}
            >
              <IonButton
                size="large"
                expand="block"
                shape="round"
                fill="outline"
                style={{
                  width: '100%',
                  '--color': '#fff',
                  '--border-color': '#fff',
                }}
                onClick={handleCtaOnclick}
              >
                {celebration.content.cta.label}
              </IonButton>
            </Stack>
          </Stack>
        </>
        )}
      </IonContent>
    </IonPage>
  );
};

MatchingCelebration.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default MatchingCelebration;
