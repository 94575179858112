import React from 'react';
import MatchOptimization from 'pages/matching/MatchOptimization/SplashLoadingPage';

const bgOverlay = 'assets/profile/wholeMe/matching/optimize-match-bg-transparent.svg';
const splashImage = {
  src: 'assets/profile/wholeMe/matching/r-y-b.gif',
  alt: 'circle loader',
  styles: {
    width: '65vw',
    borderRadius: '100%',
  },
};

const OptimizeMatch = () => (
  <MatchOptimization
    title="Optimizing your matches..."
    splashImage={splashImage}
    backgroundOverlay={bgOverlay}
  />
);
export default OptimizeMatch;
