import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';
import { useAppState } from '../../../../../providers/app-state/app-state-provider';

const profileExperiencesIntakeSplashPage = ({ nextOnClick, prevOnClick }) => {
  const { platform } = useAppState();
  const splashImage = {
    src: './assets/profile/experiences/experiences-splash.svg',
    alt: 'hot air balloon',
    styles: {
      width: platform?.isNativePlatform ? '60%' : '45%',
    },
  };
  return (
    <ProfileIntakeSplashPage
      title="Life Experiences"
      subtitle="Have you ever traveled, found a doctor, or changed careers? Your team can learn from your journey."
      splashImage={splashImage}
      backgroundOverlay="./assets/profile/experiences/experiences-background-overlay.svg"
      nextButton={{ label: "Let's Go", onClick: nextOnClick }}
      prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    />
  );
};

export default profileExperiencesIntakeSplashPage;
