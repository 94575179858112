/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activeFilter: {
    type: null,
    value: 'all',
    label: 'All Posts',
    icon: '/assets/all-post-filter-icon.svg',
  },
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    updateActiveFilter: (state, { payload }) => {
      state.activeFilter = payload;
    },
  },
});

export const { updateActiveFilter } = feedSlice.actions;

export const feedSelector = (state) => state.feed;

export default feedSlice.reducer;
