import React, { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import CodeInput from '../../common/atoms/codeInput/codeInput';
import OtpContent from '../../common/molecules/OtpContent';
import appStrings from '../../common/app_strings';
import MainLogoLayout from '../../components/layout/MainLogoLayout';
import PhoneInputModal from '../../components/PhoneInputModal';
import { useAuthContext } from '../../providers/auth-provider';

const {
  otpValidation: {
    register,
    verifyRegisterHeader,
    verifyRegisterHeader2,
    failed,
    otp,
  },
} = appStrings;

const headerStyles = {
  color: '#03295F',
  fontSize: 20,
  fontWeight: 700,
  textAlign: 'center',
  display: 'block',
};

const Header = () => (
  <div>
    <Typography component="span" sx={headerStyles}>{verifyRegisterHeader}</Typography>
    <Typography component="span" sx={headerStyles}>{verifyRegisterHeader2}</Typography>
  </div>
);

const VerifyRegistration = () => {
  const [otpCode, setOtpCode] = useState('');
  const [otpError, setOtpError] = useState(null);
  const [showNoCodeModal, setShowNoCodeModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [phoneNum, setPhoneNum] = useState('');
  const [modalPhoneNum, setModalPhoneNum] = useState('');
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardIsOpen(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardIsOpen(false);
    });
  }

  useEffect(() => () => {
    if (isNative) {
      Keyboard.removeAllListeners();
    }
  });

  const {
    handleVerifyRegistration,
    handleResendRegistrationRequest,
  } = useAuthContext();

  const onChange = (val) => {
    if (val) {
      setOtpCode(val);
    } else {
      // prevent input from rendering NaN upon delete key entry
      setOtpCode('');
    }
  };

  const onClickCallBack = () => {
    setOtpCode('');
    setShowNoCodeModal(true);
  };

  const closeModal = () => {
    setShowNoCodeModal(false);
    setModalPhoneNum('');
  };

  const handleModalNumOnChange = (val) => {
    setModalPhoneNum(val);
  };

  const handleResendOtp = async () => {
    setPhoneNum(modalPhoneNum);

    try {
      await handleResendRegistrationRequest(modalPhoneNum);
    } catch (e) {
      setOtpError(failed);
    } finally {
      closeModal();
    }
  };

  const clearErrors = () => {
    setOtpError(null);
  };

  const doRegister = async () => {
    clearErrors();
    try {
      await handleVerifyRegistration(otpCode);
    } catch (e) {
      setOtpError(failed);
    }
  };

  return (
    <MainLogoLayout
      backButtonText=""
      showLogInButton
    >
      <Stack style={{
        minHeight: '75vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      >
        <OtpContent
          header={<Header />}
          phone="1234"
          imageUrl="/assets/register-code.svg"
          imageWidth="95%"
        >
          <CodeInput
            value={otpCode}
            onChange={onChange}
            onClickCallBack={onClickCallBack}
            numInputs={6}
            error={otpError}
            hasErrored={!!otpError}
            isRegister
          />
        </OtpContent>
        {!keyboardIsOpen && (
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              width: '90%',
              paddingLeft: '5%',
              paddingTop: '10px',
              paddingBottom: '56px',
            }}
          >
            <IonButton
              size="large"
              expand="block"
              shape="round"
              onClick={doRegister}
              disabled={otpCode.length < 1}
              style={{
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                width: '100%',
                fontWeight: 700,
              }}
              mode="ios"
            >
              {register}
            </IonButton>
            <Typography
              onClick={onClickCallBack}
              sx={{
                fontSize: '1.125rem',
                fontWeight: 500,
                color: '#063D8F',
              }}
            >
              {otp.noCode}
            </Typography>
          </Stack>
        )}
      </Stack>
      <PhoneInputModal
        isOpen={showNoCodeModal}
        handleOnClose={closeModal}
        phone={modalPhoneNum}
        setPhone={handleModalNumOnChange}
        handleSubmit={handleResendOtp}
        showContactLink
      />
    </MainLogoLayout>
  );
};

export default VerifyRegistration;
