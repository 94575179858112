export const financesCredentialsData = [
  {
    label: 'CFP',
    value: 'CFP',
    infoUrl: 'https://www.investopedia.com/terms/c/cfp.asp',
  },
  {
    label: 'ChFC',
    value: 'ChFC',
    infoUrl: 'https://www.forbes.com/advisor/investing/chartered-financial-consultant-chfc/',
  },
  {
    label: 'CFA',
    value: 'CFA',
    infoUrl: 'https://www.investopedia.com/terms/c/cfa.asp',
  },
  {
    label: 'CPA',
    value: 'CPA',
    infoUrl: 'https://www.investopedia.com/terms/c/cpa.asp',
  },
  {
    label: 'RICP',
    value: 'RICP',
    infoUrl: 'https://www.forbes.com/advisor/retirement/ricp-retirement-income-certified-professional/',
  },
  {
    label: 'Real estate license',
    value: 'Real estate license',
    infoUrl: 'https://en.wikipedia.org/wiki/Real_estate_license',
  },
  {
    label: 'Finance/accounting degree',
    value: 'Finance/accounting degree',
    infoUrl: 'https://www.uagc.edu/blog/accounting-vs-finance-whats-the-difference',
  },
];
