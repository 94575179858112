/* eslint-disable camelcase */
import React from 'react';
import WholeMeProfileSection from 'pages/wholeme/molecules/wholeMeProfileSection/WholeMeProfileSection';
import { createEditHandler, resolveDefaultIcons } from 'pages/wholeme/util/profile_util';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import WholeMeProfileSectionRow from 'pages/wholeme/molecules/wholeMeProfileSectionRow/WholeMeProfileSectionRow';
import WholeMeProfileSectionDetail from 'pages/wholeme/molecules/wholeMeProfileSectionDetail/WholeMeProfileSectionDetail';
import { EducationIcon } from 'pages/wholeme/icons/Icons';
import { fieldInfoUrlFor, fieldLabelFor } from '../../util/profile_field_data';
import { pillOverrideStyles, pillRendererOverrideStyles } from '../wholeMeProfileSection/wholeMeProfileSectionStyles';

const WholeMeProfileEducationCareer = ({
  profile, onEdit, onPost, isViewOnly,
}) => {
  const pillContainerOverrideStyles = isViewOnly
    ? pillRendererOverrideStyles
    : {
      ...pillRendererOverrideStyles,
      marginLeft: '22px',
    };
  const {
    degree = [], career_stage = [], schools = [], employers = [], career_fields = [],
  } = profile;

  if (
    degree?.length < 1
    && career_stage?.length < 1
    && schools?.length < 1
    && employers?.length < 1
    && !onEdit
  ) {
    if (!onEdit) {
      return null;
    }
    return (
      <WholeMeProfileSection
        title="Education/Career"
        editable={!!onEdit}
        onTitleClick={createEditHandler(onEdit, 'education')}
        isEmpty
      />
    );
  }

  const highestDegree = degree?.find((n) => n.question_id === 'degree')?.option_id;
  const careerStage = career_stage?.find((n) => n.question_id === 'career_stage')?.option_id;
  const careerFields = career_fields?.filter((n) => n.value !== 'selected');

  const renderEdit = () => (
    <>
      <WholeMeProfileSectionRow
        onEdit={onEdit}
        label={fieldLabelFor(highestDegree, 'education', 'degree') || 'Highest Degree'}
        isCreate={onEdit && !highestDegree}
        navigationMeta={{ categoryId: 'education', stepId: 'degree' }}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        onPost={onPost}
        label="Education Institutions"
        items={schools.map((n) => n.value)}
        categoryId="education"
        stepId="schools"
        isViewOnly={isViewOnly}
        pillStyles={{ backgroundColor: '#c4c4c4' }}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        label="Career Stage"
        items={careerStage && [fieldLabelFor(careerStage, 'career', 'career_stage')]}
        categoryId="career"
        stepId="career_stage"
        isViewOnly={isViewOnly}
        pillStyles={{ backgroundColor: '#c4c4c4' }}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        onPost={onPost}
        label="Employers"
        items={employers.map((n) => n.value)}
        categoryId="career"
        stepId="employers"
        isViewOnly={isViewOnly}
        pillStyles={{ backgroundColor: '#c4c4c4' }}
      />
      <WholeMeProfileSectionDetail
        onEdit={onEdit}
        onPost={onPost}
        label="Career Path"
        items={careerFields.map((n) => n.value)}
        categoryId="career"
        stepId="career_fields"
        isViewOnly={isViewOnly}
        pillStyles={{ backgroundColor: '#c4c4c4' }}
      />
    </>
  );

  const renderView = () => (
    <>
      {!!highestDegree && (
        <WholeMeProfileSectionRow
          iconSrc={resolveDefaultIcons('education', highestDegree)}
          onEdit={onEdit}
          onPost={onPost}
          infoUrl={fieldInfoUrlFor(highestDegree, 'education', 'degree')}
          label={fieldLabelFor(highestDegree, 'education', 'degree')}
        />
      )}
      {!!careerStage && (
        <WholeMeProfileSectionRow
          iconSrc="assets/icons/education-career/occupation-blue.svg"
          onEdit={onEdit}
          onPost={onPost}
          infoUrl={fieldInfoUrlFor(careerStage, 'career', 'career_stage')}
          label={fieldLabelFor(careerStage, 'career', 'career_stage')}
        />
      )}
      {careerFields.length > 0 && (
        <PillRenderer
          pillStyles={{ ...pillOverrideStyles, backgroundColor: '#C4C4C4' }}
          containerStyleOverrides={pillContainerOverrideStyles}
          isSelectable={false}
          handlePillClick={onPost ? (item) => onPost(item) : undefined}
          items={careerFields.map((n) => ({ value: n.value }))}
        />
      )}
      {schools.length > 0 && (
        <PillRenderer
          pillStyles={{ ...pillOverrideStyles, backgroundColor: '#C4C4C4' }}
          containerStyleOverrides={pillContainerOverrideStyles}
          isSelectable={false}
          handlePillClick={onPost ? (item) => onPost(item) : undefined}
          items={schools.map((n) => ({ value: n.value }))}
        />
      )}
      {employers.length > 0 && (
        <PillRenderer
          pillStyles={{ ...pillOverrideStyles, backgroundColor: '#C4C4C4' }}
          containerStyleOverrides={pillContainerOverrideStyles}
          isSelectable={false}
          handlePillClick={onPost ? (item) => onPost(item) : undefined}
          items={employers.map((n) => ({ value: n.value }))}
        />
      )}
    </>
  );

  return (
    <WholeMeProfileSection
      title="Education/Career"
      editable={!!onEdit}
      titleIcon={<EducationIcon />}
    >
      {!!onEdit && renderEdit()}
      {!onEdit && renderView()}
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileEducationCareer;
