import React, { useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import CodeInput from '../../common/atoms/codeInput/codeInput';
import OtpContent from '../../common/molecules/OtpContent';
import appStrings from '../../common/app_strings';
import MainLogoLayout from '../../components/layout/MainLogoLayout';
import PhoneInputModal from '../../components/PhoneInputModal';
import { useAuthContext } from '../../providers/auth-provider';

const {
  otpValidation: { verifyLoginHeader, verifyLoginSubHeader, otp },
  onboarding: { login },
} = appStrings;

const headerStyles = {
  color: '#03295F',
  fontSize: 20,
  fontWeight: 700,
  textAlign: 'center',
  display: 'block',
};

const Header = () => (
  <div>
    <Typography component="span" sx={headerStyles}>
      {verifyLoginHeader}
    </Typography>
    <Typography component="span" sx={headerStyles}>
      {verifyLoginSubHeader}
    </Typography>
  </div>
);

const VerifyLogin = () => {
  const { preUser, handleVerifyLogin, handleOtpLoginRequest } = useAuthContext();
  const [otpCode, setOtpCode] = useState('');
  const [otpError, setOtpError] = useState(null);
  const [showNoCodeModal, setShowNoCodeModal] = useState(false);
  const [phoneNum, setPhoneNum] = useState('');
  const [modalNum, setModalNum] = useState('');
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardIsOpen(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardIsOpen(false);
    });
  }

  useEffect(() => {
    if (preUser?.phone) {
      setPhoneNum(preUser.phone);
    }
    return () => {
      if (isNative) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);
  const onChange = (val) => {
    if (val) {
      setOtpCode(val);
    } else {
      // prevents input from rendering NaN upon delete key entry
      setOtpCode('');
    }
  };

  const onClickCallBack = () => {
    setOtpCode('');
    setShowNoCodeModal(true);
  };

  const closeModal = () => {
    setShowNoCodeModal(false);
    setModalNum('');
  };

  const handleModalNumOnChange = (val) => {
    setModalNum(val);
  };

  const handlePhoneSubmit = async () => {
    setPhoneNum(modalNum);
    try {
      await handleOtpLoginRequest(modalNum);
    } catch (e) {
      setOtpError('ERROR');
    } finally {
      closeModal();
    }
  };

  const clearErrors = () => {
    setOtpError(null);
  };

  const doLogin = async () => {
    try {
      clearErrors();
      await handleVerifyLogin(otpCode);
    } catch (e) {
      setOtpError('Verification Failed');
    }
  };

  return (
    <MainLogoLayout backButtonText="" showLogInButton>
      <Stack
        style={{
          minHeight: '75vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <OtpContent
          header={<Header />}
          phone={phoneNum && phoneNum}
          imageUrl="/assets/register-code.svg"
          imageWidth="95%"
        >
          <CodeInput
            value={otpCode}
            onChange={onChange}
            onClickCallBack={onClickCallBack}
            numInputs={6}
            error={otpError}
            hasErrored={!!otpError}
            isRegister
          />
        </OtpContent>
        {!keyboardIsOpen && (
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              width: '90%',
              paddingLeft: '5%',
              paddingTop: '10px',
              paddingBottom: '56px',
            }}
          >
            <IonButton
              size="large"
              expand="block"
              shape="round"
              onClick={doLogin}
              disabled={otpCode.length < 1}
              style={{
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                width: '100%',
                fontWeight: 700,
              }}
              mode="ios"
            >
              {login}
            </IonButton>
            <Typography
              onClick={onClickCallBack}
              sx={{
                fontSize: '1.125rem',
                fontWeight: 500,
                color: '#063D8F',
              }}
            >
              {otp.noCode}
            </Typography>
          </Stack>
        )}
      </Stack>
      <PhoneInputModal
        isOpen={showNoCodeModal}
        handleOnClose={closeModal}
        phone={modalNum}
        setPhone={handleModalNumOnChange}
        handleSubmit={handlePhoneSubmit}
        showContactLink
      />
    </MainLogoLayout>
  );
};

export default VerifyLogin;
