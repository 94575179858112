import React from 'react-imask';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import {
  multiSelectDataFetcherAdapter,
  multiSelectDataSaverAdapter,
} from '../../../util/profile_util';
import { financesProfileData } from './financesProfileData';

const ProfileFinancesIntakeProfilePage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={financesProfileData}
    itemVariant="rectangle"
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Financial Profile"
    subtitle="Select any that apply to you."
    categoryId="finances"
    questionId="finances_profile"
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchFinancesProfileState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveFinancesProfileState)}
    backgroundOverlay="/assets/profile/finances/financial-profile-overlay.svg"
  />
);

export default ProfileFinancesIntakeProfilePage;
