/* eslint-disable */
export const CoachLubavProfileData = (userId) => {
  return {
    id: userId,
    publicProfileData: {
      id: userId,
      first_name: 'Coach',
      last_name: 'Lubav',
      city: 'By Your Side',
      state: 'By Your Side',
      role: 'admin',
      profile_image: 'assets/profile/coachLubav/coach-lubav-profile-pic-blue-hat.svg',
      job_title: 'Friendly Cyborg Helper',
      // eslint-disable-next-line quotes
      excited: "I’m an omnipresent, non-corporeal being that creates opportunities for young people and helps folks become great backrs. I also love tacos.",
      social_media: 'Backrs.com',
      gender: 'Binary',
      is_verified: true,
    },
    first_name: [
      {
        category_id: 'identity',
        question_id: 'first_name',
        value: 'Coach',
      },
    ],
    last_name: [
      {
        category_id: 'identity',
        question_id: 'last_name',
        value: 'Lubav',
      },
    ],
    city: [
      {
        category_id: 'identity',
        question_id: 'city',
        value: 'By Your Side',
      },
    ],
    state: [
      {
        category_id: 'identity',
        question_id: 'state',
        value: 'By Your Side',
      },
    ],
    zip_code: [
      {
        category_id: 'identity',
        question_id: 'zip_code',
        value: 90310,
      },
    ],
    calendly_url: [{
      category_id: 'calendly',
      question_id: 'calendly_url',
      value: 'https://calendly.com/benjamin-maldonado/30min',
    }],
    birthday: [
      {
        category_id: 'identity',
        question_id: 'birthday',
        value: null,
      },
    ],
    excited: [
      {
        category_id: 'identity',
        question_id: 'excited',
        value: "I'm an omnipresent, non-corporeal being that creates opportunities for young people and helps folks become great backrs. I also love tacos.",
      },
    ],
    role: [
      {
        category_id: 'identity',
        question_id: 'role',
        value: 'admin',
      },
    ],
    profile_media_id: [
      {
        category_id: 'identity',
        question_id: 'profile_media_id',
        value: 846,
      },
    ],
    profile_image: [
      {
        user_id: userId,
        category_id: 'identity',
        question_id: 'profile_image',
        value:
          'assets/profile/coachLubav/coach-lubav-profile-pic-blue-hat.svg',
      },
    ],
    gender: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'gender',
        value: null,
      },
    ],
    ethnicity: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'ethnicity',
        value: null,
      },
    ],
    first_gen_college: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'first_gen_college',
        value: false,
      },
    ],
    immigrant: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'immigrant',
        value: false,
      },
    ],
    rural: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'rural',
        value: false,
      },
    ],
    military: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'military',
        value: false,
      },
    ],
    disability: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'disability',
        value: false,
      },
    ],
    lgbtq: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'lgbtq',
        value: false,
      },
    ],
    college_athletics: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'college_athletics',
        value: false,
      },
    ],
    adopted_foster: [
      {
        user_id: userId,
        category: 'identity',
        option_id: 'adopted_foster',
        value: false,
      },
    ],
    religion: [
      {
        id: 2422,
        user_id: userId,
        created_at: '2023-07-09T00:00:02.000Z',
        updated_at: '2023-07-09T00:00:02.000Z',
        category_id: 'identity',
        question_id: 'religion',
        option_id: 'Pastafarianism',
        suboption_id: 'pastafarianism',
        value: 'Pastafarianism',
      },
    ],
    quiz: [
      {
        id: 2393,
        user_id: userId,
        created_at: '2023-07-08T22:57:44.000Z',
        updated_at: '2023-07-08T22:57:44.000Z',
        category_id: 'identity',
        question_id: 'quiz',
        option_id: 'MBTI Myers-Briggs',
        suboption_id: 'Backrs',
        value: 'Backrs',
      },
      {
        id: 2392,
        user_id: userId,
        created_at: '2023-07-08T22:57:44.000Z',
        updated_at: '2023-07-08T22:57:44.000Z',
        category_id: 'identity',
        question_id: 'quiz',
        option_id: 'MBTI Myers-Briggs',
        suboption_id: 'Ask me for help!',
        value: 'Ask me for help!',
      },
    ],
    hobbies: [
      {
        id: 1051,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Causes',
        suboption_id: 'Education',
        value: 'selected',
      },
      {
        id: 1049,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Anti-Discrimination',
        value: 'selected',
      },
      {
        id: 1047,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Games',
        suboption_id: 'Chess',
        value: 'selected',
      },
      {
        id: 1053,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Human Rights',
        value: 'selected',
      },
      {
        id: 1054,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Mental Health',
        value: 'selected',
      },
      {
        id: 1055,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Current Events',
        value: 'selected',
      },
      {
        id: 1048,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Movies & TV',
        suboption_id: 'Musicals',
        value: 'selected',
      },
      {
        id: 1052,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Healthcare Access',
        value: 'selected',
      },
      {
        id: 1050,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Economic Development',
        value: 'selected',
      },
      {
        id: 1053,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'Spelunking',
        value: 'selected',
      },
      {
        id: 1054,
        user_id: userId,
        created_at: '2023-07-08T08:02:10.000Z',
        updated_at: '2023-07-08T08:02:10.000Z',
        category_id: 'interests',
        question_id: 'hobbies',
        option_id: 'SQLs',
        value: 'selected',
      },
    ],
    degree: [
      {
        id: 911,
        user_id: userId,
        created_at: '2023-07-08T02:55:56.000Z',
        updated_at: '2023-07-08T02:55:56.000Z',
        category_id: 'education',
        question_id: 'degree',
        option_id: 'PhD in Backing',
        suboption_id: null,
        label: 'PhD in Backing',
      },
      {
        id: 911,
        user_id: userId,
        created_at: '2023-07-08T02:55:56.000Z',
        updated_at: '2023-07-08T02:55:56.000Z',
        category_id: 'education',
        question_id: 'degree',
        option_id: 'Backrs',
        suboption_id: null,
        label: 'Backrs',
      },
    ],
    job_title: [
      {
        id: 1151,
        user_id: userId,
        created_at: '2023-07-08T08:10:35.000Z',
        updated_at: '2023-07-08T08:10:35.000Z',
        category_id: 'career',
        question_id: 'job_title',
        option_id: null,
        suboption_id: null,
        value: 'Friendly Cyborg Helper',
      },
    ],
    career_stage: [
      {
        id: 1150,
        user_id: userId,
        created_at: '2023-07-08T08:10:35.000Z',
        updated_at: '2023-07-08T08:10:35.000Z',
        category_id: 'career',
        question_id: 'career_stage',
        option_id: null,
        suboption_id: null,
        value: 'post-career',
      },
    ],
    career_xp: [],
    career_fields: [
      {
        id: 1164,
        user_id: userId,
        created_at: '2023-07-08T08:12:17.000Z',
        updated_at: '2023-07-08T08:12:17.000Z',
        category_id: 'career',
        question_id: 'career_fields',
        option_id: 'Arts, entertainment, media',
        suboption_id: 'What subcategory?',
        value: 'Artificial Intelligence',
      },
      {
        id: 1165,
        user_id: userId,
        created_at: '2023-07-08T08:12:17.000Z',
        updated_at: '2023-07-08T08:12:17.000Z',
        category_id: 'career',
        question_id: 'career_fields',
        option_id: 'Arts, entertainment, media',
        suboption_id: 'What subcategory?',
        value: 'Networking',
      },
      {
        id: 1166,
        user_id: userId,
        created_at: '2023-07-08T08:12:17.000Z',
        updated_at: '2023-07-08T08:12:17.000Z',
        category_id: 'career',
        question_id: 'career_fields',
        option_id: 'Arts, entertainment, media',
        suboption_id: 'What subcategory?',
        value: 'Finance',
      },
    ],
    employers: [
      {
        id: 1170,
        user_id: userId,
        created_at: '2023-07-08T08:12:18.000Z',
        updated_at: '2023-07-08T08:12:18.000Z',
        category_id: 'career',
        question_id: 'employers',
        option_id: null,
        suboption_id: null,
        value: 'Backrs',
      },
    ],
    health_interests: [
      {
        id: 846,
        user_id: 1019,
        created_at: '2023-07-08T01:45:18.000Z',
        updated_at: '2023-07-08T01:45:18.000Z',
        category_id: 'health',
        question_id: 'health_interests',
        option_id: 'Mindfulness',
        label: 'selected',
      },
      {
        id: 847,
        user_id: 1019,
        created_at: '2023-07-08T01:45:18.000Z',
        updated_at: '2023-07-08T01:45:18.000Z',
        category_id: 'health',
        question_id: 'health_interests',
        option_id: 'Small Bytes',
        label: 'Small Bytes',
      },
      {
        id: 848,
        user_id: 1019,
        created_at: '2023-07-08T01:45:18.000Z',
        updated_at: '2023-07-08T01:45:18.000Z',
        category_id: 'health',
        question_id: 'health_interests',
        option_id: 'Java',
        label: 'Java',
      },
    ],
    health_credentials: [
      {
        id: 868,
        user_id: 1019,
        created_at: '2023-07-08T01:45:19.000Z',
        updated_at: '2023-07-08T01:45:19.000Z',
        category_id: 'health',
        question_id: 'health_credentials',
        option_id: 'Ketogenic',
        suboption_id: null,
        label: 'Ketogenic',
      },
    ],
    family: [
      {
        id: 1194,
        user_id: userId,
        created_at: '2023-07-08T08:15:13.000Z',
        updated_at: '2023-07-08T08:15:13.000Z',
        category_id: 'relationships',
        question_id: 'family',
        option_id: 'Backs All Proteges & Backrs',
        value: 'selected',
      },
    ],
    languages: [
      {
        id: 1195,
        user_id: userId,
        created_at: '2023-07-08T08:15:17.000Z',
        updated_at: '2023-07-08T08:15:17.000Z',
        category_id: 'relationships',
        question_id: 'languages',
        option_id: null,
        suboption_id: null,
        value: 'Algonquin',
      },
      {
        id: 1196,
        user_id: userId,
        created_at: '2023-07-08T08:15:17.000Z',
        updated_at: '2023-07-08T08:15:17.000Z',
        category_id: 'relationships',
        question_id: 'languages',
        option_id: null,
        suboption_id: null,
        value: 'Rhaeto-Romance',
      },
      {
        id: 1197,
        user_id: userId,
        created_at: '2023-07-08T08:15:17.000Z',
        updated_at: '2023-07-08T08:15:17.000Z',
        category_id: 'relationships',
        question_id: 'languages',
        option_id: null,
        suboption_id: null,
        value: 'Macedo-Romanian',
      },
      {
        id: 1198,
        user_id: userId,
        created_at: '2023-07-08T08:15:17.000Z',
        updated_at: '2023-07-08T08:15:17.000Z',
        category_id: 'relationships',
        question_id: 'languages',
        option_id: null,
        suboption_id: null,
        value: 'Ancient Manipuri',
      },
    ],
    network: [
      {
        id: 2368,
        user_id: userId,
        created_at: '2023-07-08T22:54:21.000Z',
        updated_at: '2023-07-08T22:54:21.000Z',
        category_id: 'network',
        question_id: 'network',
        option_id: 'everyone and everything',
        suboption_id: null,
        value: 'yes',
      },
    ],
    finances_profile: [
      {
        id: 2409,
        user_id: userId,
        created_at: '2023-07-08T23:02:18.000Z',
        updated_at: '2023-07-08T23:02:18.000Z',
        category_id: 'finances',
        question_id: 'finances_profile',
        option_id: 'Financial Backr',
        value: 'selected',
      },
    ],
    finances_credentials: [
      {
        id: 2416,
        user_id: userId,
        created_at: '2023-07-08T23:02:19.000Z',
        updated_at: '2023-07-08T23:02:19.000Z',
        category_id: 'finances',
        question_id: 'finances_credentials',
        option_id: 'Scholarship Sourcer',
        suboption_id: null,
        value: 'selected',
      },
    ],
  }
};
