import React from 'react';
import ProfileIntakeSplashPage from 'pages/wholeme/molecules/profileIntakeSplashPage/ProfileIntakeSplashPage';

const splashImage = {
  src: './assets/profile/health/health-splash.svg',
  alt: 'first aid kit',
  styles: {
    width: '60%',
  },
};

const profileHealthIntakeSplashPage = ({ nextOnClick, prevOnClick }) => (
  <ProfileIntakeSplashPage
    title="Health"
    subtitle="We’re dedicated to improving the health and well-being of every member of our community."
    splashImage={splashImage}
    nextButton={{ label: "Let's Go", onClick: nextOnClick }}
    prevButton={{ label: 'Return to this later', onClick: prevOnClick }}
    backgroundOverlay="/assets/profile/health/health-splash-overlay.svg"
  />
);

export default profileHealthIntakeSplashPage;
