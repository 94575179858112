import React from 'react';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { EditIcon, PlusIcon } from 'pages/wholeme/icons/Icons';
import { miniSectionStyle } from './wholeMeProfileSectionStyles';
import useWindowDimensions from '../../../../helpers/hooks';

const iconStyles = {
  position: 'absolute',
};

const RenderMini = ({
  editable, isEmpty, title, isSmallScreen,
}) => (
  <Stack direction="row" alignItems="flex-start" padding="4px">
    {editable && (
      isEmpty ? <PlusIcon marginTop={2} /> : <EditIcon marginTop={2} />
    )}
    <Typography marginLeft="12px" variant="h5" fontSize={isSmallScreen ? '13px' : '16px'} fontWeight="700">{title}</Typography>
  </Stack>
);

const WholeMeProfileSectionMini = ({
  title,
  titleIcon,
  onTitleClick,
  editable,
  isEmpty,
}) => {
  const { width } = useWindowDimensions();
  const handleClick = () => {
    if (onTitleClick) {
      onTitleClick();
    }
  };
  const isSmallScreen = width <= 320;
  return (
    <Box
      sx={{
        ...miniSectionStyle,
      }}
      onClick={handleClick}
    >
      <RenderMini
        title={title}
        editable={editable}
        isEmpty={isEmpty}
        isSmallScreen={isSmallScreen}
      />
      <span style={iconStyles}>
        {titleIcon}
      </span>
    </Box>
  );
};

export default WholeMeProfileSectionMini;
