import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './VideoPlayer.css';

const VideoPlayer = ({
  videos, isAnimation, animationRadius, errorCallback, hasFrameThumbnail,
}) => {
  const playerRef = useRef(null);
  return (
    <div>
      <div ref={playerRef} />
      {(!videos || videos.length === 0) && !isAnimation && (
        <div className="processing-box">Processing Video...</div>
      )}
      {videos && videos.length > 0 && (
        <video
          controls={!isAnimation ? 'controls' : null}
          style={{ borderRadius: !isAnimation ? '8px' : animationRadius }}
          width="100%"
          poster={!isAnimation && !hasFrameThumbnail ? videos[0].thumbnail : undefined}
          onError={errorCallback}
          autoPlay={!!isAnimation}
          muted={!!isAnimation}
          loop={!!isAnimation}
          playsInline={!!isAnimation}
        >
          {videos.map((video) => (
            <source key={video.uri} src={hasFrameThumbnail ? `${video.uri}#t=0.1` : video.uri} type="video/mp4" />
          ))}
          {!isAnimation ? (
            <track kind="captions" srcLang="en" label="English" />
          ) : null}
        </video>
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      resolution: PropTypes.string,
      uri: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
  ).isRequired,
  isAnimation: PropTypes.bool,
  animationRadius: PropTypes.string,
  errorCallback: PropTypes.func,
  hasFrameThumbnail: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  isAnimation: false,
  errorCallback: () => {
    console.log('Video error');
  },
  animationRadius: null,
  hasFrameThumbnail: false,
};

export default VideoPlayer;
