import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButtons,
  IonToolbar,
  IonBackButton,
} from '@ionic/react';
import { motion } from 'framer-motion';
import { Chip } from '@mui/material';
import useFetch, { CachePolicies } from 'use-http';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import ReportCueCard from '../components/ReportCueCard';
import Feed from '../components/Feed';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useAuthContext } from '../providers/auth-provider';

const UserFeed = () => {
  const { id } = useParams();
  const history = useHistory();
  const [posts, setPosts] = useState([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, reported, setReported } = useAuthContext();
  const { get, response } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const PER_PAGE = 10;
  const location = useLocation();

  const postToSpecificFeed = () => {
    history.push('/post', { id });
  };

  useEffect(() => {
    (() => {
      if (
        user
        && user.role === 'backr'
        && (
          !user.backr_pledge_taken
        )
      ) {
        history.push('/tabs/backr/pledge', { redirectTo: location.pathname });
      }
    })();
  }, [user]);

  const pushData = async (refresh = false) => {
    if (!loading) {
      setLoading(true);
      const max = refresh ? PER_PAGE - 1 : posts.length + PER_PAGE - 1;
      const min = refresh ? 0 : posts.length;
      let data = [];
      if (id) {
        const params = new URLSearchParams();
        params.append('filter', `{"user_id":"${id}","visibility":"team"}`);
        params.append('sort', '["created_at","DESC"]');
        params.append('range', [min, max]);
        data = await get(`/posts?${params.toString()}`);
      } else {
        data = await get(`/posts/feed?includePublic=1&range=[${min},${max}]&sort=["created_at","DESC"]`);
      }
      let total = 0;
      if (refresh) {
        setPosts(data);
        setInfiniteDisabled(false);
        total = data.length;
      } else {
        setPosts([...posts, ...data]);
        total = posts.length + data.length;
      }
      if (total >= response?.headers?.get('x-total-count')) setInfiniteDisabled(true);
      setLoading(false);
    }
  };

  const loadFeed = async (ev) => {
    await pushData();
    ev.target.complete();
    if (posts.length === 1000) {
      setInfiniteDisabled(true);
    }
  };

  const onIonRefresh = async (event) => {
    await pushData(true);
    event.detail.complete();
  };

  useEffect(() => {
    if (user) {
      pushData(true);
    }
    if (user && !user.is_verified) {
      history.push('/tabs/backr/tasks');
    }
  }, [user]);

  const handleClose = () => {
    localStorage.setItem('isFlagged', 'false');
    setReported(false);
  };

  return (
    <IonPage>
      <Header />
      {user?.role === 'admin' && id && (
        <IonToolbar
          className="back-button-toolbar"
          style={{ '--background': 'var(--ion-background-color)', '--border-width': 0 }}
          mode="ios"
        >
          <IonButtons slot="start">
            <IonBackButton
              icon="/assets/back-arrow.svg"
              style={{
                '--icon-font-size': '1.25rem',
                '--icon-padding-start': '8px',
                '--icon-padding-end': '12px',
                '--icon-padding-top': '4px',
                '--icon-margin-end': '6px',
              }}
              className="back-button-custom"
              defaultHref="/tabs/teams"
            />
          </IonButtons>
          <Chip
            slot="end"
            label="Post to this feed"
            onClick={postToSpecificFeed}
            sx={{
              fontSize: '0.75rem',
              fontWeight: 700,
              backgroundColor: '#03295F',
              color: '#fff',
              mr: 1,
            }}
          />
        </IonToolbar>
      )}
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={onIonRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {loading && posts.length < 1 ? (
          <LoadingSpinner />
        ) : (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              {reported && (
              <ReportCueCard userRole={user.role} onClickCallBack={handleClose} />
              )}
              <Feed posts={posts} setPosts={setPosts} />
            </motion.div>
            <IonInfiniteScroll
              onIonInfinite={loadFeed}
              threshold="300px"
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent loadingSpinner="crescent" />
            </IonInfiniteScroll>
          </>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default UserFeed;
