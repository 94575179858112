/* eslint-disable max-len */
import React from 'react';
import {
  Typography,
  Stack,
  SvgIcon,
} from '@mui/material';

const getCelebrationIconSvg = ({
  type,
}) => {
  let svg = null;

  switch (type) {
    case 'location':
      svg = (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="currentColor" d="M7.00021 1.16669C4.42713 1.16669 2.33354 3.26027 2.33354 5.83044C2.31663 9.59002 6.82288 12.7074 7.00021 12.8334C7.00021 12.8334 11.6838 9.59002 11.6669 5.83335C11.6669 3.26027 9.57329 1.16669 7.00021 1.16669ZM7.00021 8.16669C5.71104 8.16669 4.66688 7.12252 4.66688 5.83335C4.66688 4.54419 5.71104 3.50002 7.00021 3.50002C8.28938 3.50002 9.33354 4.54419 9.33354 5.83335C9.33354 7.12252 8.28938 8.16669 7.00021 8.16669Z" />
        </svg>
      );
      break;

    case 'school_type':
      svg = (
        <svg
          width="21"
          height="21"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4.37499 11.5316V13.9904C4.37499 14.6291 4.72499 15.2241 5.28499 15.5304L9.65999 17.9191C10.185 18.2079 10.815 18.2079 11.34 17.9191L15.715 15.5304C16.275 15.2241 16.625 14.6291 16.625 13.9904V11.5316L11.34 14.4191C10.815 14.7079 10.185 14.7079 9.65999 14.4191L4.37499 11.5316ZM9.65999 3.07914L2.28375 7.10414C1.68 7.43664 1.68 8.31164 2.28375 8.64414L9.65999 12.6691C10.185 12.9579 10.815 12.9579 11.34 12.6691L18.375 8.82789V13.9991C18.375 14.4804 18.7687 14.8741 19.25 14.8741C19.7312 14.8741 20.125 14.4804 20.125 13.9991V8.39039C20.125 8.06664 19.95 7.77789 19.67 7.62039L11.34 3.07914C11.0815 2.94126 10.793 2.86914 10.5 2.86914C10.207 2.86914 9.91852 2.94126 9.65999 3.07914Z" fill="currentColor" />
        </svg>
      );
      break;

    case 'job_title':
      svg = (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="currentColor" d="M11.7752 4.68036C11.7039 4.60034 11.6191 4.53689 11.5256 4.49367C11.4322 4.45045 11.332 4.42832 11.2308 4.42857H9.6923V4C9.6923 3.20435 9.40865 2.44129 8.90375 1.87868C8.39884 1.31607 7.71404 1 7 1C6.28595 1 5.60116 1.31607 5.09625 1.87868C4.59134 2.44129 4.30769 3.20435 4.30769 4V4.42857H2.76923C2.56522 4.42857 2.36956 4.51888 2.2253 4.67962C2.08104 4.84037 2 5.05839 2 5.28571V11.0714C2 12.1161 2.79327 13 3.73077 13H10.2692C10.7227 13.0002 11.1582 12.8026 11.4827 12.4496C11.646 12.276 11.776 12.0674 11.8649 11.8362C11.9538 11.6051 11.9998 11.3564 12 11.1049V5.28571C12.0003 5.17326 11.9806 5.06186 11.942 4.95795C11.9034 4.85404 11.8468 4.75969 11.7752 4.68036ZM5.07692 4C5.07692 3.43168 5.27953 2.88663 5.64018 2.48477C6.00082 2.08291 6.48997 1.85714 7 1.85714C7.51003 1.85714 7.99917 2.08291 8.35982 2.48477C8.72047 2.88663 8.92307 3.43168 8.92307 4V4.42857H5.07692V4ZM9.6923 6.57143C9.6923 7.36708 9.40865 8.13014 8.90375 8.69275C8.39884 9.25536 7.71404 9.57143 7 9.57143C6.28595 9.57143 5.60116 9.25536 5.09625 8.69275C4.59134 8.13014 4.30769 7.36708 4.30769 6.57143V6.14286C4.30769 6.02919 4.34821 5.92018 4.42034 5.83981C4.49247 5.75944 4.5903 5.71429 4.69231 5.71429C4.79431 5.71429 4.89214 5.75944 4.96427 5.83981C5.0364 5.92018 5.07692 6.02919 5.07692 6.14286V6.57143C5.07692 7.13975 5.27953 7.68479 5.64018 8.08666C6.00082 8.48852 6.48997 8.71429 7 8.71429C7.51003 8.71429 7.99917 8.48852 8.35982 8.08666C8.72047 7.68479 8.92307 7.13975 8.92307 6.57143V6.14286C8.92307 6.02919 8.9636 5.92018 9.03573 5.83981C9.10785 5.75944 9.20568 5.71429 9.30769 5.71429C9.4097 5.71429 9.50752 5.75944 9.57965 5.83981C9.65178 5.92018 9.6923 6.02919 9.6923 6.14286V6.57143Z" />
        </svg>
      );
      break;

    default:
      return null;
  }

  return svg;
};

const getCelebrationIconTypography = ({
  type,
  userData,
}) => {
  let content = null;

  switch (type) {
    case 'location':
      content = `${userData?.city}${userData?.state && ', '}${userData?.state && userData.state}`.trim();
      break;

    case 'school_type':
      content = userData?.school_type === 'HS' ? 'High School' : 'College';
      break;

    case 'job_title':
      content = userData?.job_title;
      break;

    default:
      return null;
  }

  return (
    <Typography color="white" sx={{ fontSize: 14 }}>
      {content}
    </Typography>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const buildCelebrationIcon = ({
  type,
  userData,
}) => (
  <Stack spacing={1} direction="row" alignItems="center">
    <SvgIcon
      sx={{ color: '#f7ae41', fontSize: 18 }}
      viewBox="0 0 14 14"
    >
      {getCelebrationIconSvg({ type })}
    </SvgIcon>
    {getCelebrationIconTypography({ type, userData })}
  </Stack>
);
