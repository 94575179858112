import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  Input,
} from '@mui/material';
import { GoldDropdownSvg } from 'common/atoms/adornments/InputIcons';
import { firstOf } from '../../../pages/wholeme/util/profile_util';

const DropdownInput = ({
  items,
  onChange,
  selectedItem,
}) => {
  const [selectedItemState, setSelectedItemState] = useState([]);

  const handleChange = (event) => {
    setSelectedItemState([event.target.value]);
    onChange([event.target.value]);
  };

  useEffect(() => {
    setSelectedItemState(selectedItem);
  }, []);
  return (
    <FormControl>
      <Select
        disableUnderline
        IconComponent={() => null}
        sx={{
          height: '44px',
          borderRadius: '8px',
          background: '#fff',
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: 400,
          color: '#000',
          margin: '0 20px',
        }}
        value={selectedItemState}
        onChange={handleChange}
        input={(
          <Input startAdornment={(
            <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
              <GoldDropdownSvg />
            </InputAdornment>
          )}
          />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: '150px',
              borderRadius: '8px',
              boxShadow: '0.5px 1px 4px 0px rgba(0, 0, 0, 0.25)',
              gap: '20px',
            },
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={{
              backgroundColor: firstOf(selectedItemState) === item.value && '#F7AE41',
              color: firstOf(selectedItemState) === item.value && '#fff',
              height: '50px',
              fontSize: '15px',
              fontWeight: firstOf(selectedItemState) === item.value ? 700 : 400,
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const DropdownInputPropTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const DropdownInputDefualtProps = {
  items: [],
  label: '',
  onChange: () => { },
};

export default DropdownInput;
