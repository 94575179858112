import React, { useEffect } from 'react';
import { IonLoading, IonRouterOutlet } from '@ionic/react';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  Route, useHistory, Redirect,
  Switch,
} from 'react-router-dom';

import WholeMeProfileRoute from 'pages/wholeme/routes/WholeMeProfileRoute';
import ProfileCompleted from 'pages/wholeme/pages/profile/profileCompleted/ProfileCompleted';
import ProtectedRoute from './components/ProtectedRoute';
import WhatIsBackrs from './pages/WhatIsBackrs';
import MeetProtegesWorkFlow from './workflows/meetProteges/MeetProtegesWorkFlow/MeetProtegesWorkFlow';
import BackingInAction from './workflows/backingInAction/BackingInAction';
import MeetOurBackrs from './workflows/meetOurBackrs/MeetOurBackrs';
import WhatIsBacking from './workflows/whatIsBacking/WhatIsBacking';
import Goals from './pages/protege/goals/Goals';
import GoalsCreate from './pages/protege/goals/GoalsCreate';
import GoalsEdit from './pages/protege/goals/GoalsEdit';
import GoalsSmart from './pages/protege/goals/SmartGoals';
import GoalsCelebration from './pages/protege/Celebration';
import NoGoals from './pages/NoGoals';
import NotificationsPage from './pages/notifications/Notifications';
import GoalsPlaceholder from './pages/GoalsPlaceholder';
import BackrGoals from './pages/backr/goals/Goals';
import BackgroundCheck from './pages/backr/BackgroundCheck';
import StartBackgroundCheckScreen from './pages/backr/StartBackgroundCheckScreen';
import UserFeed from './pages/UserFeed';
import UnifiedUserFeed from './pages/UnifiedUserFeed';
import TeamList from './pages/TeamList';
import NoTeamPage from './pages/backr/NoTeamPage';
import BackrTeamView from './pages/backr/BackrTeamView';
import GeneralInvestLanding from './pages/backr/GeneralInvestLanding';
import BackrGeneralInvest from './pages/backr/BackrGeneralInvest';
import ProtegeTeamDetails from './pages/protege/ProtegeTeamDetails';
import BackrTeamDetails from './pages/backr/BackrTeamDetails';
import MatchingCelebration from './pages/MatchingCelebration';
import Conversations from './pages/enhancedConversations/Conversations';
import Chat from './pages/enhancedConversations/Chat';
import CreatePost from './pages/CreatePost';
import EditPost from './pages/EditPost';
import EditAccountInfo from './pages/profile/EditAccountInfo';
import EditAdditionalInfo from './pages/profile/EditAdditionalInfo';
import EditProfileInfo from './pages/profile/EditProfileInfo';
import Comments from './pages/Comments';
import ReportPage from './pages/ReportPage';
import PostPage from './pages/Post';
import MatchQuizResults from './pages/matching/MatchQuizResults';
import BackgroundCheckMeterScreen from './pages/backr/checkr/BackgroundCheckMeterScreen';
import CheckrBGCheck from './pages/backr/CheckrBGCheck';
import Disclosure from './pages/backr/checkr/Disclosure';
import FairCredit from './pages/backr/checkr/FairCredit';
import Agreement from './pages/backr/checkr/Agreement';
import Pay from './pages/backr/checkr/Pay';
import ProfileIntakeWizardRoute from './pages/wholeme/routes/ProfileIntakeWizardRoute';
import ProfileIntakePortraitRoute from './pages/wholeme/routes/ProfileIntakePortraitRoute';
import MatchingWizardRoute from './pages/wholeme/routes/MatchingWizardRoute';
import FAQ from './pages/FAQ';
import YoureInScreen from './pages/register/YoureInScreen';
import LogoutPage from './pages/register/Logout';
import NewFeature from './pages/oneTimeScreens/NewFeature';
import { sessionSelector } from './redux/slices/session';

import { StoreProvider } from './providers/store-provider';
import { useAuthContext } from './providers/auth-provider';
import {
  useAppState,
  useAppStateDispatch,
  actions,
} from './providers/app-state/app-state-provider';
import TourOverlay from './common/atoms/tourOverlay/TourOverlay';

const App = () => {
  const history = useHistory();
  const { isLoading } = useAuthContext();
  const { tour } = useAppState();
  const appStateDispatch = useAppStateDispatch();
  const { initialLocation } = useSelector(sessionSelector);

  const handleCloseTour = () => appStateDispatch(actions.setTourIsOpen(false));

  useEffect(() => {
    if (initialLocation) {
      history.replace(initialLocation);
    }
  }, [initialLocation]);

  return (
    <StoreProvider>
      <IonLoading isOpen={isLoading} message="Loading..." cssClass="ion-loading-custom" />
      <IonRouterOutlet>
        {
          // Paths that start with with /tabs will render with the tabs footer
          // Once React Router v6 is added to Ionic wrap this in nested Route
        }
        <Switch>
          <ProtectedRoute exact path="/tabs/profile" component={WholeMeProfileRoute} />
          <ProtectedRoute exact path="/tabs/feed" component={UnifiedUserFeed} />
          <ProtectedRoute exact path="/tabs/no-teams" component={NoTeamPage} />
          <ProtectedRoute exact path="/tabs/teams/admin" component={TeamList} />
          <ProtectedRoute exact path="/tabs/teams/admin/:id" component={UserFeed} />
          <ProtectedRoute exact path="/tabs/updates" component={NotificationsPage} />
          <ProtectedRoute exact path="/tabs/backr/background-check" component={BackgroundCheck} />
          <ProtectedRoute
            exact
            path="/tabs/backr/background-check-v2"
            component={StartBackgroundCheckScreen}
          />

          <ProtectedRoute exact path="/tabs/protege/goals" component={Goals} />
          <ProtectedRoute exact path="/tabs/protege/goals-empty" component={NoGoals} />
          <ProtectedRoute exact path="/tabs/protege/goals/celebration" component={GoalsCelebration} />
          <ProtectedRoute exact path="/tabs/backr/goals" component={BackrGoals} />
          <ProtectedRoute exact path="/tabs/backr/goals-empty" component={NoGoals} />
          <ProtectedRoute exact path="/tabs/admin/goals" component={GoalsPlaceholder} />
          <ProtectedRoute exact path="/tabs/protege/teams" component={ProtegeTeamDetails} />
          <ProtectedRoute exact path="/tabs/backr/teams" component={BackrTeamDetails} />
          <ProtectedRoute exact path="/tabs/backr/teams/details/:id" component={BackrTeamView} />
          <ProtectedRoute
            exact
            path="/tabs/backr/general-invest-landing"
            component={GeneralInvestLanding}
          />
          <ProtectedRoute exact path="/tabs/backr/general-invest" component={BackrGeneralInvest} />
          <ProtectedRoute exact path="/wholeme/portrait" component={ProfileIntakePortraitRoute} />
          <ProtectedRoute exact path="/wholeme/wizard" component={ProfileIntakeWizardRoute} />
          <ProtectedRoute
            exact
            path="/wholeme/wizard/:categoryId"
            component={ProfileIntakeWizardRoute}
          />
          <ProtectedRoute
            exact
            path="/wholeme/wizard/:categoryId/:stepId"
            component={ProfileIntakeWizardRoute}
          />
          <ProtectedRoute exact path="/wholeme/pre-matching" component={MatchingWizardRoute} />
          <ProtectedRoute exact path="/new-feature" component={NewFeature} />
          {/* todo: why are these routes not protected?  Should we protect now that we are deploying to web? */}
          <Route exact path="/protege/goals-create" component={GoalsCreate} />
          <Route exact path="/protege/goals-create/smart" component={GoalsSmart} />
          <Route exact path="/protege/goals-edit" component={GoalsEdit} />
          <Route exact path="/protege/goals-edit/smart" component={GoalsSmart} />
          <Route exact path="/post" component={CreatePost} />
          <Route exact path="/post-edit" component={EditPost} />
          <Route exact path="/tabs/profile/edit-account-info" component={EditAccountInfo} />
          <Route exact path="/tabs/profile/edit-additional-info" component={EditAdditionalInfo} />
          <Route exact path="/tabs/profile/edit-profile-info" component={EditProfileInfo} />
          <ProtectedRoute exact path="/profile/:id" component={WholeMeProfileRoute} />
          <Route exact path="/celebrations/:id" component={MatchingCelebration} />
          <Route exact path="/conversations" component={Conversations} />
          <ProtectedRoute exact path="/chat/:conversationId" component={Chat} />
          <Route exact path="/posts/:id" component={PostPage} />
          <ProtectedRoute exact path="/posts/:id/comments" component={Comments} />
          <Route exact path="/:type/:id/report" component={ReportPage} />
          <Route exact path="/what-is-backing" component={WhatIsBacking} />
          <Route exact path="/meet-the-proteges" component={MeetProtegesWorkFlow} />
          <Route exact path="/backing-in-action" component={BackingInAction} />
          <Route exact path="/meet-our-backrs" component={MeetOurBackrs} />
          <Route exact path="/about" component={WhatIsBackrs} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/match-preview-screen" component={MatchQuizResults} />
          <Route exact path="/background-status-meter" component={BackgroundCheckMeterScreen} />
          <Route exact path="/checkr-form" component={CheckrBGCheck} />
          <Route exact path="/checkr-disclosure" component={Disclosure} />
          <Route exact path="/checkr-fair-credit" component={FairCredit} />
          <Route exact path="/checkr-agreement" component={Agreement} />
          <Route exact path="/checkr-pay" component={Pay} />
          <Route exact path="/profile-completed" component={ProfileCompleted} />
          <Route exact path="/youre-in" component={YoureInScreen} />
          <Route exact path="/register/logout" component={LogoutPage} />
          <Redirect to="/tabs/feed" />
        </Switch>
      </IonRouterOutlet>

      <Toaster
        containerStyle={{ top: 60, zIndex: '9999 !important' }}
        toastOptions={{ className: 'toaster', style: { zIndex: '9999 !important' } }}
      />
      {tour.isOpen && <TourOverlay handleClose={handleCloseTour} isOpen={tour.isOpen} />}
    </StoreProvider>
  );
};

export default App;
