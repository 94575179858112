import { useEffect, useRef } from 'react';
import { App } from '@capacitor/app';
import useFetch from 'use-http';

const useAppActivityMonitor = (user) => {
  const { post } = useFetch();
  const listenerRef = useRef(null);

  const clearListener = async () => {
    if (listenerRef.current) {
      await listenerRef.current.remove();
      listenerRef.current = null;
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(async () => {
    if (user && user.id) {
      await clearListener();
      listenerRef.current = await App.addListener('appStateChange', async ({ isActive: active }) => {
        if (active) {
          try {
            await post('/users/activity/access'); // this call is made when the app is foregrounded
          } catch (e) {
            console.error(e);
          }
        }
      });
    } else {
      await clearListener();
    }

    return () => {
      clearListener();
    };
  }, [user]);
};

// eslint-disable-next-line import/prefer-default-export
export default useAppActivityMonitor;
