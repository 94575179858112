import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import {
  Stack, MenuItem, Grid, Typography, Box,
} from '@mui/material';
import { arrowForwardOutline } from 'ionicons/icons';
import { useForm, Controller } from 'react-hook-form';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { ValidationError } from '../../helpers/errors';
import { useAuthContext } from '../../providers/auth-provider';
import { types } from '../types';
import { grades } from '../grades';
import { states } from '../states';
import { ethnicities } from '../ethnicities';
import { ages } from '../ages';
import Footer from '../../components/Footer';
import PageWithBackButton from '../../components/generic/PageWithBackButton';
import StyledInput, { TaupeTextField } from '../../components/StyledInput';
import './EditProfileInfo.css';

const EditProfileInfo = () => {
  const { user, refreshUser } = useAuthContext();
  const { put: editAdditionalInfo, response } = useFetch(`/users/${user?.id}`);
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardIsOpen(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardIsOpen(false);
    });
  }

  const onSubmit = async (data) => {
    try {
      const cleanedData = data;
      delete cleanedData.email_verified;
      const res = await editAdditionalInfo(cleanedData);
      if (!response.ok) {
        if (response.status === 400) {
          throw new ValidationError('Invalid inputs', res.errors);
        } else {
          throw new Error('Unknown Error');
        }
      }
      await refreshUser();
      history.push('/tabs/profile');
    } catch (e) {
      if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      }
    }
  };

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => () => {
    if (isNative) {
      Keyboard.removeAllListeners();
    }
  });

  return (
    <PageWithBackButton backButtonText="Profile">
      <IonContent>
        <Typography
          sx={{
            color: '#2d2d2d',
            fontSize: 28,
            fontWeight: 700,
            textAlign: 'center',
            mt: 2,
          }}
        >
          Profile Info
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              background: '#fff',
              p: 2,
              mx: 3,
              my: 3.5,
              borderRadius: 2,
            }}
          >
            <Stack spacing={1.5}>
              <div>
                <IonLabel style={{ color: '#2d2d2d' }} position="stacked">
                  Location *
                </IonLabel>
                <Grid container spacing={1} py={0}>
                  <Grid item xs={8}>
                    <StyledInput
                      theme="taupe"
                      placeholder="City"
                      control={control}
                      name="city"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <TaupeTextField
                          placeholder="State"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                          value={value || ''}
                          size="small"
                          fullWidth
                          select
                          SelectProps={{
                            MenuProps: {
                              sx: { maxHeight: 250 },
                            },
                          }}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.value} value={state.value}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </TaupeTextField>
                      )}
                    />
                  </Grid>
                </Grid>
                <Typography color="error" fontSize="12px !important">
                  {errors?.city?.message}
                </Typography>
                <Typography color="error" fontSize="12px !important">
                  {errors?.state?.message}
                </Typography>
              </div>
              {user?.role === 'protege' ? (
                <>
                  <StyledInput
                    theme="taupe"
                    title="School *"
                    placeholder="School"
                    control={control}
                    name="school"
                    error={errors?.school}
                    position="stacked"
                  />
                  <div>
                    <IonLabel style={{ color: '#2d2d2d' }} position="stacked">
                      School Type *
                    </IonLabel>
                    <Controller
                      name="school_type"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <TaupeTextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                          placeholder="Choose an option"
                          value={value || ''}
                          error={!!errors?.school_type?.message}
                          helperText={errors?.school_type?.message}
                          size="small"
                          fullWidth
                          select
                        >
                          {types.map((schoolType) => (
                            <MenuItem
                              key={schoolType.name}
                              value={schoolType.value}
                            >
                              {schoolType.name}
                            </MenuItem>
                          ))}
                        </TaupeTextField>
                      )}
                    />
                  </div>
                  <div>
                    <IonLabel style={{ color: '#2d2d2d' }} position="stacked">
                      Grade *
                    </IonLabel>
                    <Controller
                      name="grade"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <TaupeTextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                          placeholder="Choose an option"
                          value={value || ''}
                          error={!!errors?.grade?.message}
                          helperText={errors?.grade?.message}
                          size="small"
                          fullWidth
                          select
                        >
                          {grades.map((grade) => (
                            <MenuItem key={grade.name} value={grade.name}>
                              {grade.name}
                            </MenuItem>
                          ))}
                        </TaupeTextField>
                      )}
                    />
                  </div>
                </>
              ) : (
                <StyledInput
                  theme="taupe"
                  title="Occupation"
                  placeholder="Occupation"
                  control={control}
                  name="job_title"
                  error={errors?.job_title}
                  position="stacked"
                />
              )}
              <StyledInput
                theme="taupe"
                title="Preferred Pronouns"
                placeholder="preferred pronouns"
                control={control}
                name="pronouns"
                error={errors?.pronouns}
                position="stacked"
              />
              <div>
                <IonLabel style={{ color: '#2d2d2d' }} position="stacked">
                  Ethnicity
                </IonLabel>
                <Controller
                  name="ethnicity"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <TaupeTextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...rest}
                      placeholder="Choose an option"
                      value={value || ''}
                      error={!!errors?.ethnicity?.message}
                      helperText={errors?.ethnicity?.message}
                      size="small"
                      fullWidth
                      select
                      sx={{
                        '& .MuiSelect-select .notranslate::after': {
                          content: '"Prefer not to answer"',
                          opacity: 0.5,
                        },
                      }}
                    >
                      {ethnicities.map((ethnicity) => (
                        <MenuItem key={ethnicity.label} value={ethnicity.value}>
                          {ethnicity.label}
                        </MenuItem>
                      ))}
                    </TaupeTextField>
                  )}
                />
              </div>
              {user?.role === 'protege' ? (
                <StyledInput
                  theme="taupe"
                  title="Share a little about yourself:"
                  // eslint-disable-next-line max-len
                  placeholder="I'm a senior in high school! I'm hoping to learn more about which college will fit me best, and I want to be a video game designer."
                  control={control}
                  name="user_bio"
                  error={errors?.user_bio}
                  textarea
                  position="stacked"
                />
              ) : (
                <>
                  <div>
                    <IonLabel style={{ color: '#2d2d2d' }} position="stacked">
                      Age Group
                    </IonLabel>
                    <Controller
                      name="age_group"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <TaupeTextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                          placeholder="Choose an option"
                          value={value || ''}
                          error={!!errors?.age_group?.message}
                          helperText={errors?.age_group?.message}
                          size="small"
                          fullWidth
                          select
                          sx={{
                            '& .MuiSelect-select .notranslate::after': {
                              content: '"22-30"',
                              opacity: 0.5,
                            },
                          }}
                        >
                          {ages.map((age) => (
                            <MenuItem key={age.name} value={age.name}>
                              {age.name}
                            </MenuItem>
                          ))}
                        </TaupeTextField>
                      )}
                    />
                  </div>
                  <StyledInput
                    theme="taupe"
                    title="Why are you excited to be a backr? *"
                    // eslint-disable-next-line max-len
                    placeholder="I'm excited to be a backr because I want to give young people the same great opportunities I had while growing up."
                    control={control}
                    name="excited"
                    error={errors?.excited}
                    textarea
                    position="stacked"
                  />
                </>
              )}
            </Stack>
          </Box>
          <IonButton
            size="large"
            expand="block"
            shape="round"
            className="edit-info-submit-btn"
            type="submit"
          >
            Save Profile
            <IonIcon
              style={{
                position: 'relative',
                top: '2px',
                fontSize: '1.25rem',
                marginLeft: '4px',
              }}
              src={arrowForwardOutline}
            />
          </IonButton>
        </form>
      </IonContent>
      {!keyboardIsOpen && <Footer />}
    </PageWithBackButton>
  );
};

export default EditProfileInfo;
