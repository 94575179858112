import React, { useState, useEffect, useCallback } from 'react';
import { IonRouterLink } from '@ionic/react';
import { Stack, Typography, Skeleton } from '@mui/material';
import moment from 'moment';
import useFetch from 'use-http';
import AvatarRole from '../../components/AvatarRole';
import AvatarAdmin from '../../components/AvatarAdmin';
import './Conversations.css';

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const deriveMessageBody = (lastMessage, isReported) => {
  if (isReported) {
    return 'Conversation Reported';
  }

  return lastMessage?.body || 'No messages yet';
};

// TODO: possibly refactor into shared component with AuthorAvatar in ./Chat.jsx
const Avatar = ({ user: avatarUser }) => {
  const style = { marginRight: 24 };
  if (avatarUser?.role === 'system') {
    return <AvatarAdmin style={style} />;
  }

  return <AvatarRole user={avatarUser} style={style} />;
};

const ConversationItem = ({ conversation }) => {
  const {
    unreadMessagesCount,
    lastMessage: convoLastMessage,
    id,
    external_id: externalId,
    participants = [],
    friendlyName,
    last_message_at: lastMessageAt,
    isReported = false,
  } = conversation;
  const { get: getLastMessage, response, loading } = useFetch();
  const [lastMessage, setLastMessage] = useState(convoLastMessage);
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleGetLastMessage = useCallback(async () => {
    if (hasLoaded) {
      return;
    }

    try {
      const lastMessageResponse = await getLastMessage(`/conversations/${id}/last-message`);

      if (response.ok) {
        setLastMessage(lastMessageResponse);
      } else {
        setLastMessage(null);
      }
    } catch (error) {
      setLastMessage(null);
    } finally {
      setHasLoaded(true);
    }
  }, [externalId, hasLoaded]);

  useEffect(async () => {
    if (!lastMessage && !hasLoaded && !loading) {
      await handleGetLastMessage();
    } else {
      setHasLoaded(true);
    }
  }, [lastMessage, hasLoaded, loading]);

  const showSkeleton = loading;
  const messageBody = deriveMessageBody(lastMessage, isReported);
  const conversationRead = !unreadMessagesCount;
  const timeStamp = lastMessageAt ? moment(lastMessageAt.toLocaleString()).fromNow() : '';

  return (
    <IonRouterLink key={externalId} routerLink={`/chat/${externalId}`}>
      <div className="convos-item-wrapper">
        <Avatar user={participants[0]}>
          <img src={defaultProfileImage} alt="profile" />
        </Avatar>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{ color: '#063d8f', fontWeight: 700 }}>{friendlyName}</Typography>
            {!conversationRead && <div className={`convos-item-${isReported ? 'grey' : 'unread'}`} />}
            <Typography
              sx={{
                position: 'relative',
                top: 1,
                color: conversationRead ? 'hsl(0 0% 18% / 0.5)' : 'hsl(0 0% 18%)',
                fontSize: 10,
                fontWeight: 600,
              }}
            >
              {timeStamp}
            </Typography>
          </Stack>
          <Typography
            className="convos-item-msg"
            component="div"
            sx={{
              color: lastMessage ? 'hsl(0 0% 18%)' : 'hsl(216, 92%, 29%)',
              fontSize: 14,
              mt: 0,
              fontStyle: lastMessage && !isReported ? 'normal' : 'italic',
              opacity: lastMessage ? 1 : 0.5,
              paddingRight: isReported ? 1 : 0,
            }}
          >
            {showSkeleton ? <Skeleton variant="text" width="100%" /> : messageBody}
          </Typography>
        </Stack>
      </div>
    </IonRouterLink>
  );
};

export default ConversationItem;
