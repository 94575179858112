import React, { useEffect, useState } from 'react';
import InputField from 'common/atoms/inputField/InputField';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import ProfileInputPage from 'pages/wholeme/molecules/inputPage/ProfileInputPage';
import { useFeatureFlagsContext } from 'providers/featureflags-provider';

const inputContainerStyles = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
};

const dayCountMonthLookup = {
  '01': 31,
  '02': 29,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const checkForLeapYear = (year) => {
  if (year % 4 === 0) {
    if (year % 100 === 0) {
      if (year % 400 === 0) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};

const ProfileIdentityIntakeBirthdayPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [dayState, setDayState] = useState();
  const [monthState, setMonthState] = useState();
  const [yearState, setYearState] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const { isSaveAndContinueAlwaysEnabled } = useFeatureFlagsContext() || {};

  useEffect(async () => {
    const state = await wholeMeClient.fetchBirthdayState();
    const birthdayString = (state?.birthday[0]?.value?.split('/') || ['', '', '']);
    setDayState(birthdayString[1]);
    setMonthState(birthdayString[0]);
    setYearState(birthdayString[2]);
  }, []);

  const saveBirthday = () => {
    setErrorMessage('');
    if (monthState < 1 || monthState > 12) setErrorMessage('Invalid month');
    else if (dayState < 1 || dayState > dayCountMonthLookup[monthState]) setErrorMessage('Invalid day');
    else if (yearState < 1900) setErrorMessage('Invalid year');
    // eslint-disable-next-line eqeqeq
    else if (dayState == 29 && monthState == 2 && !checkForLeapYear(yearState)) setErrorMessage('Invalid date');
    else {
      wholeMeClient
        .saveBirthdayState([
          {
            category_id: 'identity',
            question_id: 'birthday',
            value: (yearState && monthState && dayState) ? new Date(yearState, (monthState - 1), dayState) : null,
          },
        ])
        .then((res) => {
          const { error } = res || {};
          if (error) setErrorMessage('Invalid Date');
          nextOnClick();
        });
    }
  };

  const mainImageData = {
    imageSrc: './assets/profile/identity/birthday-page-2.svg',
    imageAlt: 'birthday present',
  };

  const isLoading = dayState === undefined && monthState === undefined && yearState === undefined;
  const isCtaDisabled = isSaveAndContinueAlwaysEnabled && !((dayState?.length && monthState?.length && yearState?.length === 4));

  return (
    <ProfileInputPage
      paginationMeta={paginationMeta}
      nextOnClick={saveBirthday}
      isCtaDisabled={isCtaDisabled}
      nextButtonIsLoading={isLoading}
      prevOnClick={prevOnClick}
      wholeMeClient={wholeMeClient}
      title="What's your birthday?"
      subtitle="Backrs come from all generations, and this helps us build diverse teams. Your info is private."
      backgroundOverlay="/assets/profile/identity/date-of-birth-overlay-3.svg"
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      <div style={inputContainerStyles}>
        <InputField
          onChange={(value) => setMonthState(value)}
          placeholder="MM"
          fieldValue={monthState}
          stylesOverride={{ width: '106px' }}
          masker="number"
          icon="dob"
          maxValueLength="2"
          enableNumpad
          error={!!errorMessage && { message: errorMessage }}
          theme="light"
        />
        <InputField
          onChange={(value) => setDayState(value)}
          placeholder="DD"
          fieldValue={dayState}
          stylesOverride={{ width: '71px' }}
          isInputFieldContentCentered
          masker="number"
          forcePadding
          maxValueLength="2"
          enableNumpad
          error={!!errorMessage}
          theme="light"
        />
        <InputField
          onChange={(value) => setYearState(value)}
          placeholder="YYYY"
          fieldValue={yearState}
          stylesOverride={{ width: '89px' }}
          isInputFieldContentCentered
          masker="number"
          forcePadding
          maxValueLength="4"
          enableNumpad
          error={!!errorMessage}
          theme="light"
        />
      </div>
      <MainImage
        imageSrc={mainImageData.imageSrc}
        imageAlt={mainImageData.imageAlt}
      />
    </ProfileInputPage>
  );
};

export default ProfileIdentityIntakeBirthdayPage;
