import React, { useState } from 'react';
import {
  IonPage, IonContent, IonHeader, IonToolbar,
} from '@ionic/react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useFetch } from 'use-http';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import toast from 'react-hot-toast';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import * as Sentry from '@sentry/capacitor';
import { ValidationError } from '../../../helpers/errors';
import UnderlineTextField from '../../../common/atoms/inputs/UnderlineTextField';
import appStrings from '../../../common/app_strings';
import LoadingSpinner from '../../../components/generic/LoadingSpinner';
import useServiceLogger from '../../../hooks/userServiceLogger';
import pdfPaths from '../../../common/pdf_paths';
import './Pdfs.css';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import PdfDocument from './PdfDocument';

const { checkrFormScreen } = appStrings;

const Agreement = () => {
  const [wantsCheckrReport, setWantsCheckrReport] = useState(false);
  const [loading, setLoading] = useState(false);

  const { serviceLogger } = useServiceLogger();
  const agreementPdf = pdfPaths('checkrAgreement');
  const { platform } = useAppState();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      signature: '',
    },
  });

  const { request, response } = useFetch();
  const history = useHistory();
  const location = useLocation();

  const { consents: currentConsents, formData, pdfs } = location?.state || {};
  const agreementInfo = pdfs.find(
    (userConsent) => userConsent.consentFormName === 'Agreement',
  );

  const handleCheckrEmail = (event) => setWantsCheckrReport(event.target.checked);

  const goToFeed = () => {
    history.push('/tabs/feed');
  };

  const dismissKeyboardOnEnter = (e) => {
    if (e.key === 'Enter' && Capacitor.isNativePlatform()) {
      Keyboard.hide();
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await request.put('/users-consent/start-background', {
        ...currentConsents,
        agreement: agreementInfo?.userConsentFormId,
        signature: data.signature,
        email_checkr_report: wantsCheckrReport,
        ...formData,
      });
      if (!response.ok) {
        if (response?.data?.message === 'Invalid Signature') {
          throw new Error('Invalid Signature');
        }
      }
      if (response.ok) {
        history.push('/checkr-pay');
      } else if (response.data.errors) {
        throw new ValidationError('Errors:', response.data.errors);
      } else {
        console.error();
        toast.error('Unknown error. Please try again later.', {
          id: 'checkr-error',
        });
      }
    } catch (e) {
      if (e.message === 'Invalid Signature') {
        // eslint-disable-next-line max-len
        const inputName = `${response?.data?.input_name.first_name} ${response?.data?.input_name?.middle_name} ${response?.data?.input_name.last_name}`;
        setError('signature', { type: 'server', message: `Signature must match full name: ${JSON.stringify(inputName)}` });
      } else if (e instanceof ValidationError) {
        Object.keys(e.errors).forEach((k) => {
          setError(k, { type: 'server', message: e.errors[k] });
        });
      }
    }
    setLoading(false);
  };

  const handleDocumentLoadError = async ({
    error,
  }) => {
    Sentry.captureException(error);
    await serviceLogger({
      key: 'checkr',
      type: 'error',
      header: 'Checkr Agreement Consent Screen',
      error: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : null,
    });
  };

  const RenderDocument = (
    <PdfDocument
      pdfDocument={agreementPdf}
      onDocumentLoadSuccess={async () => {
        await serviceLogger({
          key: 'checkr',
          header: 'Checkr Agreement Screen',
          message: 'React-Pdf Document Loaded Checkr Agreement PDF',
        });
      }}
      handleDocumentLoadError={handleDocumentLoadError}
      pageNumber={1}
    />
  );

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="" style={{ '--background': '#1CB7A4' }}>
          <Box
            sx={{
              bgcolor: '#1CB7A4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              pt: 3,
            }}
          >
            <img src="/assets/checkr-logo.png" alt="Checkr logo" />
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Stack
          spacing={2}
          sx={{
            bgcolor: '#fff',
            alignItems: 'center',
            pt: 2,
            pb: 4,
            px: 4,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          {platform?.isNativePlatform ? (
            <TransformWrapper>
              <TransformComponent>
                {RenderDocument}
              </TransformComponent>
            </TransformWrapper>
          ) : RenderDocument}
          <Stack px={1}>
            <UnderlineTextField
              name="signature"
              control={control}
              error={errors?.signature}
              placeholder="electronic signature"
              InputProps={{
                inputProps: { enterKeyHint: 'done' },
              }}
              required
              onKeyUp={dismissKeyboardOnEnter}
            />
            <FormControlLabel
              sx={{ mt: 1 }}
              label={(
                <Typography
                  sx={{
                    color: '#9c9c9c',
                    textTransform: 'uppercase',
                    letterSpacing: 2,
                  }}
                >
                  {checkrFormScreen.reportLabel}
                </Typography>
              )}
              control={(
                <Checkbox
                  checked={wantsCheckrReport}
                  onChange={handleCheckrEmail}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
          </Stack>
          {!loading ? (
            <Button
              color="green"
              variant="contained"
              disableElevation
              disableRipple
              disabled={!isValid || loading}
              sx={{
                color: '#fff',
                fontWeight: 700,
                letterSpacing: 2,
                borderRadius: '100px',
                display: 'inline-block',
                mx: 'auto',
              }}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          ) : (
            <LoadingSpinner />
          )}
        </Stack>
        <Button
          disableRipple
          sx={{
            color: '#9c9c9c',
            display: 'block',
            textTransform: 'initial',
            mt: 1,
            mb: 5,
            mx: 'auto',
          }}
          onClick={goToFeed}
        >
          {checkrFormScreen.continueExploring}
        </Button>
      </IonContent>
    </IonPage>
  );
};

export default Agreement;
