import React, { useState, useEffect } from 'react';
import {
  IonButton,
  IonContent,
  IonPage,
  IonToast,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import { useFetch } from 'use-http';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  Box,
  Stack,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
} from '@mui/material';
import { Close, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { alertOutline } from 'ionicons/icons';
import NumberFormat from 'react-number-format';
import appStrings from 'common/app_strings';
import '../Register.css';
import { TaupeTextField } from '../../../components/StyledInput';
import TermsAndConditionsContent from '../../TermsAndConditionsContent';
import { useAuthContext } from '../../../providers/auth-provider';
import { ValidationError } from '../../../helpers/errors';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import LoadingSpinner from '../../../components/generic/LoadingSpinner';

const {
  errors: { registrationFailed },
} = appStrings.onboarding;
const { contactBtn } = appStrings.otpValidation;
const {
  hey,
  activateAccount,
  tsAndCs,
  textVerification,
  tac,
} = appStrings.activate;

const CssTextField = styled(TextField)({
  '& fieldset': {
    borderRadius: 'inherit',
  },
  '& .MuiFilledInput-root': {
    background: '#f4f4ee',
  },
  // input label when focused
  '& label.Mui-focused': {
    color: '#063d8f',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#063d8f',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    background: '#f4f4ee',
    borderRadius: 8,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#063d8f',
      borderWidth: 1,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#2d2d2d',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
});

const headerImage = () => (
  <img
    style={{ width: '100%', objectFit: 'contain', maxWidth: 74 }}
    className="centered"
    src="/assets/logo_circle.png"
    alt="Backrs Logo"
  />
);

const DuplicateHelperText = (type, callback) => {
  const typeKeys = {
    email: 'email',
    phone: 'mobile number',
  };

  if (!typeKeys[type]) {
    return null;
  }

  return (
    <>
      {`An account with this ${typeKeys[type]} already exists, please `}
      <Typography
        component="span"
        sx={{
          color: '#063D8F',
          fontSize: 'inherit',
          fontWeight: 700,
          display: 'inline',
          cursor: 'pointer',
        }}
        onClick={callback}
      >
        log in
      </Typography>
      {' or reach out to '}
      <Link
        href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
        sx={{
          color: '#063D8F',
          fontSize: 'inherit',
          fontWeight: 700,
          display: 'inline',
          textDecoration: 'none',
        }}
      >
        support@backrs.com
      </Link>
      .
    </>
  );
};

const customHelperText = (error, key, callback) => {
  if (error === 'unavailable') {
    return DuplicateHelperText(key, callback);
  }

  return false;
};

const renderWelcome = (userData) => (
  <Typography
    sx={{
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center',
      mt: 3,
      mb: 4,
    }}
  >
    {hey}
    <span style={{ textDecoration: 'underline' }}>
      {`${userData?.first_name} ${userData?.last_name}.`}
    </span>
    <br />
    {activateAccount}
  </Typography>
);

const Activate = () => {
  const { handleUserActivationRequest } = useAuthContext();
  const { get, response: fetchResponse } = useFetch();
  const { platform: { isNativePlatform } } = useAppState();
  const history = useHistory();
  const bottomRef = React.useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [isFourteen, setIsFourteen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [toastState, setToastState] = React.useState({
    isOpen: false,
    message: '',
  });
  const [userData, setUserData] = useState(null);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const userActivationToken = query.get('token');

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardWillShow', () => {
      if (bottomRef && bottomRef.current) {
        bottomRef.current.style.visibility = 'hidden';
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (bottomRef && bottomRef.current) {
        bottomRef.current.style.visibility = 'visible';
      }
    });
  }

  const closeTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleClickShowPassword = (e) => {
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  const clearFieldErrors = () => {
    setFieldErrors(null);
  };

  const handleNavigation = () => {
    history.push('/activation/verify');
  };

  const navigateToLogin = () => {
    history.push('/register/login');
  };

  const navigateToError = () => {
    history.push('/register/error');
  };

  const goToLogin = async () => {
    if (isNativePlatform) {
      await Keyboard.hide();
    }
    history.push('/register/login');
  };

  const getUserDataFromToken = async (token) => {
    if (!token) {
      return;
    }

    try {
      const url = `/users/activation/read?token=${token}`;
      const res = await get(url);

      if (fetchResponse?.ok) {
        setUserData(res);
      }

      if (fetchResponse?.status !== 200) {
        throw new Error(fetchResponse.data.message);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      throw error;
    }
  };

  const doActivate = async () => {
    clearFieldErrors();
    try {
      const payload = {
        email,
        phone,
        password,
        activationToken: userActivationToken,
        userId: userData?.id,
      };

      const response = await handleUserActivationRequest(payload);
      if (response?.status_code !== 200) {
        throw new Error(response?.message);
      }

      handleNavigation();
    } catch (error) {
      if (error instanceof ValidationError) {
        setFieldErrors(error.errors);
      } else {
        navigateToError();
      }
    }
  };

  const isFourteenInputColor = fieldErrors?.is_fourteen_plus && !isFourteen ? '#d32f2f' : '#063D8F';

  useEffect(async () => {
    if (!userActivationToken) {
      navigateToLogin();
    }
    try {
      await getUserDataFromToken(userActivationToken);
    } catch (error) {
      navigateToError();
    }
  }, []);

  if (!userData) {
    return (
      <Box mt={8} mb={0}>
        <Box minHeight={8}>
          {headerImage()}
        </Box>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <IonPage>
      <IonContent>
        <Box mt={8}>
          {headerImage()}
        </Box>
        <IonToast
          expand="block"
          isOpen={toastState.isOpen}
          onDidDismiss={() => setToastState({ isOpen: false, message: '' })}
          message={toastState.message}
          icon={alertOutline}
          duration={5000}
          position="top"
        />
        <Stack sx={{ mt: 3 }}>
          <Box>
            {userData && renderWelcome(userData)}
            <Box
              sx={{
                background: '#fff',
                p: 3,
                pt: 3.5,
                mx: 3,
                mt: 1,
                borderRadius: 2,
              }}
            >
              <Stack spacing={1.5}>
                <Box>
                  <CssTextField
                    fullWidth
                    placeholder="Email Address"
                    value={email}
                    size="small"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={(e) => e.key === 'Enter' && doActivate()}
                    error={!!fieldErrors?.email}
                    helperText={fieldErrors?.email && (customHelperText(fieldErrors.email, 'email', goToLogin) || fieldErrors.email)}
                  />
                </Box>
                <Box>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    onValueChange={(v) => setPhone(v.value)}
                    value={phone}
                    customInput={TaupeTextField}
                    fullWidth
                    size="small"
                    error={!!fieldErrors?.phone}
                    helperText={fieldErrors?.phone && (customHelperText(fieldErrors.phone, 'phone', goToLogin) || fieldErrors.phone)}
                    type="tel"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="Mobile Number"
                  />
                </Box>
                <Box>
                  <CssTextField
                    fullWidth
                    placeholder="Password"
                    value={password}
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyUp={(e) => e.key === 'Enter' && doActivate()}
                    error={!!fieldErrors?.password}
                    helperText={fieldErrors?.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            disableRipple
                            sx={{ color: '#C3C3B9' }}
                          >
                            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Stack>
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{
                      color: isFourteenInputColor,
                      '&.Mui-checked': {
                        color: '#063D8F',
                      },
                      ml: 1,
                    }}
                    checked={isFourteen}
                    onChange={(e) => setIsFourteen(e.target.checked)}
                  />
                )}
                label={(
                  <Typography
                    sx={{
                      color: isFourteenInputColor,
                      fontSize: 12,
                    }}
                  >
                    {tsAndCs}
                  </Typography>
                )}
              />
              {fieldErrors?.unknown && (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <FormHelperText error>
                    {registrationFailed(true)}
                    <a
                      className="red"
                      href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
                    >
                      {(contactBtn).toLowerCase()}
                    </a>
                  </FormHelperText>
                </Box>
              )}
            </Box>
          </Box>
          <Stack
            ref={bottomRef}
            spacing={2.5}
            sx={{
              alignItems: 'center',
              mt: 3,
              mx: 3,
            }}
          >
            <object
              className="no-pointer-events"
              type="image/svg+xml"
              data="/assets/creation-connection.svg"
              style={{ width: '100%', maxWidth: '265px' }}
            >
              <img
                src="/assets/creation-connection.svg"
                alt="connection account creation"
              />
            </object>
            <IonButton
              className="register-btn"
              size="large"
              expand="block"
              shape="round"
              onClick={doActivate}
              disabled={
                !isFourteen
                || !email
                || !phone
                || !password
              }
              style={{
                '--background-hover': '#063D8F',
                '--background-activated': '#063D8F',
                '--background-focused': '#063D8F',
                width: '100%',
              }}
              mode="ios"
            >
              {textVerification}
            </IonButton>
            <Stack
              alignItems="center"
              mt={1.25}
              pb={3.5}
            >
              <Box>
                <Typography
                  sx={{
                    color: '#2D2D2D',
                    fontSize: 14,
                  }}
                >
                  {tac.acceptBtac}
                  <Typography
                    component="span"
                    sx={{
                      color: '#063D8F',
                      fontSize: 'inherit',
                      fontWeight: 700,
                      display: 'inline',
                      marginLeft: '4px',
                    }}
                    onClick={() => setOpenTermsModal(true)}
                  >
                    {tac.btac}
                  </Typography>
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Dialog
          PaperProps={{
            style: {
              borderRadius: 8,
              'max-height': 'calc(100% - 450px)',
            },
          }}
          open={openTermsModal}
          onClose={closeTermsModal}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontSize: 20,
                color: '#063D8F',
                fontWeight: 700,
              }}
            >
              {tac.btac}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={closeTermsModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              px: 2,
              pb: 2,
              borderTop: '0.5px solid grey',
            }}
          >
            <TermsAndConditionsContent whiteBgModal />
          </DialogContent>
        </Dialog>
      </IonContent>
    </IonPage>
  );
};

export default Activate;
