export const containerStyles = {
  '--gap': '10px',
  display: 'flex',
  gap: 'var(--gap)',
  flexWrap: 'wrap',
};

export const showMoreButtonStyles = {
  color: '#5981D1',
  fontSize: '14',
  fontWeight: 'bold',
  fontFamily: 'Sarabun, arial, sans-serif',
  border: 'none',
  margin: '0',
  padding: '0 5px',
  width: 'auto',
  background: 'transparent',
  cursor: 'pointer',
};

export const selectableStyles = {
  display: 'flex',
  height: '23px',
  color: '#848484',
};

export const selectedStyles = {
  backgroundColor: '#F7AE41',
  color: '#FFFFFF',
};
