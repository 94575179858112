import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase, Icon, Stack, Typography,
} from '@mui/material';
import { openInBrowser } from '../../../helpers/broswer';

const styles = {
  button: {
    background: '#E8EEF9',
    color: '#03295F',
    minHeight: '34px',
    fontSize: '13px',
    fontWeight: 700,
    paddingLeft: '8px',
    paddingRight: '20px',
    marginLeft: '16px',
    marginRight: '16px',
    marginBottom: '16px',
    borderRadius: '8px',
  },
  buttonText: {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(15ch + 10vw)',
  },
  buttonImg: {
    height: '21px',
    width: '21px',
  },
};

const PdfIndicator = ({ attachment, overrideStyle }) => {
  const viewPdf = async (e) => {
    e.stopPropagation();
    if (!attachment?.uri) {
      return;
    }
    await openInBrowser(attachment.uri);
  };
  const buttonStyles = {
    ...styles.button,
    ...overrideStyle,
  };
  return (
    <ButtonBase onClick={viewPdf} sx={buttonStyles}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Icon>
          <img src="/assets/icon/file-attachment.svg" alt="file icon" />
        </Icon>
        <Typography sx={styles.buttonText}>{attachment?.name || 'Pdf attachment'}</Typography>
      </Stack>
    </ButtonBase>
  );
};

PdfIndicator.propTypes = {
  attachment: PropTypes.shape({
    name: PropTypes.string,
    s3_key: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};

export default PdfIndicator;
