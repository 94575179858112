import { portraitStyleOverrides } from './profileIntakePortraitStyles';

export default {
  health: {
    column: 'left',
    category: 'health',
    label: 'Health',
    src: 'assets/meditation.svg',
    portraitStylesOverride: portraitStyleOverrides.health,
  },
  relationships: {
    column: 'left',
    category: 'relationships',
    label: 'Relationships',
    src: 'assets/infinite-heart.svg',
    portraitStylesOverride: portraitStyleOverrides.relationships,
  },
  identity: {
    column: 'left',
    category: 'identity',
    label: 'Identity',
    src: 'assets/sparkle-hand.svg',
  },
  education: {
    column: 'right',
    category: 'education',
    label: 'Education/Career',
    src: 'assets/apple-books.svg',
    portraitStylesOverride: portraitStyleOverrides.education,
  },
  interests: {
    column: 'right',
    category: 'interests',
    label: 'Interests',
    src: 'assets/telescope.svg',
    portraitStylesOverride: portraitStyleOverrides.interests,
  },
  finances: {
    column: 'right',
    category: 'finances',
    label: 'Finances',
    src: 'assets/finance-money.svg',
    portraitStylesOverride: portraitStyleOverrides.finances,
  },
};
