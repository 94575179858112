import {
  React,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import appStrings from '../../app_strings';

const { otpValidation: { otp } } = appStrings;

const CodeInput = ({
  value,
  hasErrored,
  numInputs,
  onClickCallBack,
  onChange,
  error,
  isRegister,
}) => (
  <Stack
    spacing={1}
    sx={{
      textAlign: 'center',
      width: '200px',
    }}
  >
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      isInputNum
      hasErrored={hasErrored}
      errorStyle={{
        border: 'solid 1px red',
      }}
      containerStyle={{
        display: 'grid',
        justifyItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr',
        rowGap: '11.67px',
      }}
      inputStyle={{
        fontSize: 15,
        height: '55px',
        width: '55px',
        border: 'none',
        borderRadius: 6,
        justifyContent: 'space-evenly',
        padding: 0,
      }}
    />
    {error && (
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {error}
      </Typography>
    )}
    {!isRegister && (
      <Typography
        onClick={onClickCallBack}
        sx={{
          fontSize: '1.125rem',
          fontWeight: 600,
          color: '#063D8F',
        }}
      >
        {otp.noCode}
      </Typography>
    )}
  </Stack>
);

CodeInput.propTypes = {
  hasErrored: PropTypes.bool,
  numInputs: PropTypes.number.isRequired,
  onClickCallBack: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

CodeInput.defaultProps = {
  hasErrored: false,
  onClickCallBack: () => { },
  value: '',
  onChange: () => { },
  error: '',
};

export default CodeInput;
