import { createTheme } from '@mui/material';
import { muiComponentOverrideStyles } from 'theme/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const useTheme = () => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        '"Sarabun"',
        'Arial',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        main: '#03295F',
        light: '#03295F',
        dark: '#FFFFFF',
      },
      green: createColor('#2CB669'),
    },
    components: { ...muiComponentOverrideStyles },
  });

  return theme;
};

export default useTheme;
