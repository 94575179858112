import React from 'react';
import WholeMeProfileEducationCareer from 'pages/wholeme/molecules/wholeMeProfileEducationCareer/WholeMeProfileEducationCareer';
import WholeMeProfileBadges from 'pages/wholeme/molecules/wholeMeProfileBadges/WholeMeProfileBadges';
import WholeMeProfileQuotation from 'pages/wholeme/molecules/wholeMeProfileQuotation/WholeMeProfileQuotation';
import WholeMeProfileHealth from 'pages/wholeme/molecules/wholeMeProfileHealth/WholeMeProfileHealth';
import WholeMeProfileInterests from 'pages/wholeme/molecules/wholeMeProfileInterests/WholeMeProfileInterests';
import WholeMeProfileRelationships from 'pages/wholeme/molecules/wholeMeProfileRelationships/WholeMeProfileRelationships';
import WholeMeProfileFinances from 'pages/wholeme/molecules/wholeMeProfileFinances/WholeMeProfileFinances';
import WholeMeProfileExperiences from 'pages/wholeme/molecules/wholeMeProfileExperiences/WholeMeProfileExperiences';
import WholeMeProfileLegacyProfile from 'pages/wholeme/molecules/wholeMeProfileLegacyProfile/WholeMeProfileLegacyProfile';
import WholeMeProfilePurposeVideo from 'pages/wholeme/molecules/wholeMeProfilePurposeVideo/WholeMeProfilePurposeVideo';
import WholeMeProfileLinkedIn from 'pages/wholeme/molecules/wholeMeProfileLinkedIn/WholeMeProfileLinkedIn';

import {
  Stack,
} from '@mui/material';

const WholeMeProfilePrivate = ({
  profile, wholeMeClient, editable, onEdit, onPost, isViewingAdmin, legacyProfileData,
}) => (
  <Stack spacing={1.5}>
    {profile && (
      <>
        <WholeMeProfilePurposeVideo profile={profile} editable={editable} wholeMeClient={wholeMeClient} />
        <WholeMeProfileBadges
          profile={profile}
          onEdit={onEdit}
          onPost={onPost}
          wholeMeClient={wholeMeClient}
        />
        {(editable || isViewingAdmin) && (
          <WholeMeProfileQuotation profile={profile} onEdit={onEdit} onPost={onPost} />
        )}
        {editable && (
          <WholeMeProfileLegacyProfile
            legacyProfileData={legacyProfileData}
            profile={profile}
            onEdit={onEdit}
            onPost={onPost}
            isViewOnly={!editable}
          />
        )}
        <WholeMeProfileLinkedIn profile={profile} isViewOnly />
        <WholeMeProfileEducationCareer profile={profile} onEdit={onEdit} onPost={onPost} isViewOnly={!editable} />
        <WholeMeProfileHealth profile={profile} onEdit={onEdit} onPost={onPost} isViewOnly={!editable} />
        <WholeMeProfileExperiences profile={profile} onEdit={onEdit} onPost={onPost} isViewOnly={!editable} />
        <WholeMeProfileInterests profile={profile} onEdit={onEdit} onPost={onPost} isViewOnly={!editable} />
        <WholeMeProfileRelationships profile={profile} onEdit={onEdit} onPost={onPost} isViewOnly={!editable} isViewingAdmin={isViewingAdmin} />
        <WholeMeProfileFinances profile={profile} onEdit={onEdit} onPost={onPost} isViewOnly={!editable} />
      </>
    )}
  </Stack>
);

export default WholeMeProfilePrivate;
