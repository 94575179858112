import React, { useState, useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useFetch } from 'use-http';
import PropTypes from 'prop-types';
import { useAuthContext } from 'providers/auth-provider';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import TeamDetailsView from 'components/TeamDetailsView/TeamDetailsView';
import { CoachLubavProfileData } from '../wholeme/util/coachLubav_Profile';

const ProtegeTeamDetails = ({ history }) => {
  const { user } = useAuthContext();
  const [team, setTeam] = useState([]);

  const {
    get: getTeamMembers, loading, response, abort,
  } = useFetch(
    '/users/teams',
  );

  const lubavProfileData = CoachLubavProfileData(user?.id).publicProfileData;
  const loadTeam = async () => {
    const backrs = await getTeamMembers();
    // Adds lubav to each team, new date ensures it last backr on team
    backrs[0].backrRelationships.push({
      related_user_id: lubavProfileData.id,
      relationship: 'backing',
      related_profile: lubavProfileData,
      created_at: new Date().toISOString(),
    });
    if (!response.ok || backrs.length < 1) history.push('/register/protege-matching');
    if (response.ok) setTeam(backrs[0].backrRelationships);
  };

  useEffect(() => {
    loadTeam();
    return () => {
      abort();
    };
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <Stack
          spacing={5}
          direction="row"
          justifyContent="center"
          alignItems="baseline"
          mb={5}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              px: 1,
              pb: 0.5,
              borderBottom: '3px solid var(--ion-color-warning)',
              textAlign: 'center',
            }}
          >
            Details
          </Typography>
        </Stack>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <TeamDetailsView protege={user} backrs={team} />
          </motion.div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

ProtegeTeamDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProtegeTeamDetails;
