import React, { useState } from 'react';
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
} from '@mui/material';
import { IonIcon } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import useWindowDimemsions from '../../../helpers/hooks';

const variants = {
  rotate: { rotate: 90, transition: { duration: 0.3 } },
  initial: { rotate: 0, transition: { duration: 0.3 } },
};

const IonIconBase = React.forwardRef(({
  className, slot, icon, onClick,
}, ref) => (
  <IonIcon className={className} slot={slot} icon={icon} ref={ref} onClick={onClick} />
));

const MotionIcon = motion(IonIconBase);

const AccordionCard = ({
  title,
  subtitle,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const { width } = useWindowDimemsions();
  const handleClick = (e) => {
    e.stopPropagation();
    setIsSelected(!isSelected);
  };
  const computeWidth = () => {
    if (width >= 390) {
      return '275px';
    }
    if (width <= 375) {
      return '250px';
    }
    return '300px';
  };

  return (
    <Accordion
      expanded={isSelected}
      sx={{
        borderRadius: '8px !important',
        boxShadow: 'none !important',
        '&::before': {
          display: 'none !important',
        },
      }}
    >
      <AccordionSummary
        sx={{
          paddingTop: '0',
          borderRadius: '8px !important',
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto !important',
          },
          color: '#2D2D2D',
          fontSize: '18px',
          fontWeight: '600',
          marginBottom: 0,
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: computeWidth(),
            }}
          >
            {title}
          </Box>
          <MotionIcon
            slot="icon-only"
            className="accordion-arrow"
            icon={arrowForwardCircleOutline}
            onClick={(e) => handleClick(e)}
            variants={variants}
            animate={isSelected ? 'rotate' : 'initial'}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontSize: '14px',
          fontWeight: '400',
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: subtitle }} />
      </AccordionDetails>
    </Accordion>
  );
};

AccordionCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default AccordionCard;
