// eslint-disable-next-line import/prefer-default-export
export const types = [
  {
    name: 'High School',
    value: 'HS',
  },
  {
    name: 'College',
    value: 'CO',
  },
];
