/* eslint-disable max-classes-per-file */
import appStrings from '../common/app_strings';

export class ValidationError extends Error {
  constructor(message, errors) {
    super(message);

    const errs = {};

    // get first error message for each field
    errors.slice().reverse().forEach((e) => {
      if (e.param !== '_error') {
        errs[e.param] = e.msg;
      }

      if (e.param === '_error') {
        errs[e.nestedErrors[0].param] = e.nestedErrors[0].msg;
      }
    });

    this.errors = errs;
  }
}

export class InvalidCredentialsError extends Error { }

// form field validators
const { validationErrors } = appStrings;

export const validateBackrPostRegistrationFields = ({
  city,
  state,
  excitedAgeGroup,
  excited,
}) => {
  const errs = [];
  if (!city) {
    errs.push({ msg: validationErrors.cityReq, param: 'city' });
  }
  if (!state) {
    errs.push({ msg: validationErrors.stateReq, param: 'state' });
  }
  if (!excitedAgeGroup) {
    errs.push({ msg: validationErrors.excitedAgeGroupReq, param: 'excited_age_group' });
  }
  if (!excited) {
    errs.push({ msg: validationErrors.excitedReq, param: 'excited' });
  }

  return errs;
};

export const CustomException = (message, statusCode = 400) => {
  const error = new Error(message);
  error.statusCode = statusCode;
  return error;
};
