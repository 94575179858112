import React from 'react';
import WholeMeProfileIdentity from '../wholeMeProfileIdentity/WholeMeProfileIdentity';

const generateDummyData = () => ({
  first_name: 'John',
  last_name: 'Doe',
  city: [
    {
      category_id: 'identity',
      question_id: 'city',
      value: 'City',
    },
  ],
  state: [
    {
      category_id: 'identity',
      question_id: 'state',
      value: 'PL',
    },
  ],
  zip_code: 12345,
  role: 'protege',
  profile_image: '/assets/profile/wholeMe/locked-profile/generic-profile-picture.svg', // use generic profile image
  job_title: [
    {
      category_id: 'identity',
      question_id: 'job_title',
      value: 'Assistant Manager',
    },
  ],
  quiz: [
    {
      id: 3290,
      created_at: '2024-04-17T02:03:33.000Z',
      updated_at: '2024-04-17T02:03:33.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'Astrology',
      suboption_id: 'What is your star sign?',
      value: 'Aries',
    },
    {
      id: 3291,
      created_at: '2024-04-17T02:03:33.000Z',
      updated_at: '2024-04-17T02:03:33.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'Enneagram',
      suboption_id: 'Enneagram Primary',
      value: 'One',
    },
    {
      id: 3292,
      created_at: '2024-04-17T02:03:33.000Z',
      updated_at: '2024-04-17T02:03:33.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'Enneagram',
      suboption_id: 'Enneagram Wing',
      value: 'Three',
    },
    {
      id: 3293,
      created_at: '2024-04-17T02:03:33.000Z',
      updated_at: '2024-04-17T02:03:33.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'StrengthsFinder',
      suboption_id: 'What are your top 5 strengths?',
      value: 'Achiever',
    },
    {
      id: 3294,
      created_at: '2024-04-17T02:03:33.000Z',
      updated_at: '2024-04-17T02:03:33.000Z',
      category_id: 'identity',
      question_id: 'quiz',
      option_id: 'HIGH5',
      suboption_id: 'What is your HIGH5 profile?',
      value: 'Deliverer',
    },
  ],
});

const BlockedProfileIdentity = () => {
  const dummyData = generateDummyData();

  return (
    <div style={{ filter: 'blur(3px)', pointerEvents: 'none' }}>
      <WholeMeProfileIdentity profile={dummyData} />
    </div>
  );
};

export default BlockedProfileIdentity;
