import useFetch from 'use-http';

const useSetOneTimeScreen = () => {
  const { get, response } = useFetch({ cachePolicy: 'no-cache' });

  const getOneTimeScreen = async () => {
    try {
      const oneTimeScreen = await get('/users/one-time-screen/current');
      if (response.status !== 200) {
        throw new Error('Bad Response', response.status);
      }

      return oneTimeScreen;
    } catch (e) {
      console.error(e);
      throw new Error('Error getting one-time-screen');
    }
  };

  return getOneTimeScreen;
};

export default useSetOneTimeScreen;
