import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { affinityData } from './affinityData';

const ProfileIdentityIntakeAffinityPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => (
  <SimpleMultiSelectPage
    fields={affinityData}
    itemVariant="rectangle"
    isTwoColumns
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Other Affinities"
    subtitle="Our proteges have a wide range of backgrounds and experiences. Do you identify with any of these?"
    categoryId="identity"
    questionId="affinity"
    dataFetcher={wholeMeClient.fetchAffinityState}
    dataSaver={wholeMeClient.saveAffinityState}
    backgroundOverlay="/assets/profile/identity/affinity-overlay.svg"
    childrenOverrideStyles={{
      margin: '1.5rem 0 auto 0',
    }}
    exitIconOnClickOverride={exitIconOnClickOverride}
  />
);

export default ProfileIdentityIntakeAffinityPage;
