import React, {
  useCallback, useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Button } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  carouselContainerStyles,
  navBtnStyles,
  navLeftStyles,
  navRightStyles,
  swiperSlideStyles,
} from './desktopCarouselStyles';

import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import '../carousel/Carousel.css';

const DesktopCarousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef(null);

  const showPrevNavigation = useMemo(() => currentSlide > 0, [currentSlide]);
  const showNextNavigation = useMemo(() => currentSlide !== slides.length - 1, [currentSlide, slides.length]);

  const handlePrevious = useCallback(() => {
    const swiper = swiperRef?.current?.swiper;
    if (swiper) {
      swiper.slidePrev();
    }
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    const swiper = swiperRef?.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  }, [swiperRef]);

  const updateIndex = useCallback(
    () => setCurrentSlide(swiperRef.current.swiper.realIndex),
    [],
  );

  useEffect(() => {
    const swiper = swiperRef?.current?.swiper;
    if (swiper) {
      swiper.on('slideChange', updateIndex);
    }

    return () => {
      if (swiper) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [updateIndex]);

  return (
    <Box sx={carouselContainerStyles}>
      <Button
        sx={navBtnStyles}
        onClick={handlePrevious}
        disabled={!showPrevNavigation}
      >
        <ArrowBackIos sx={navLeftStyles} />
      </Button>
      <Swiper
        className="carousel"
        ref={swiperRef}
      >
        {slides.map((slide) => (
          <SwiperSlide style={swiperSlideStyles} key={`card-${slide.key}`}>{slide}</SwiperSlide>
        ))}
      </Swiper>
      <Button
        sx={navBtnStyles}
        onClick={handleNext}
        disabled={!showNextNavigation}
      >
        <ArrowForwardIos sx={navRightStyles} />
      </Button>
    </Box>
  );
};

DesktopCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node),
};

DesktopCarousel.defaultProps = {
  slides: [],
};

export default DesktopCarousel;
