import React from 'react';
import { IonButton } from '@ionic/react';
import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const ToastContent = ({
  header,
  body,
  dismissMeta,
}) => (
  <Box sx={{
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  }}
  >
    {header
    && (
    <Typography
      variant="h6"
      marginBottom={1.5}
      textAlign="center"
    >
      {header}
    </Typography>
    )}
    <Typography
      variant="body"
      textAlign="center"
    >
      {body}
    </Typography>
    { dismissMeta
      && (
      <Box
        display="flex"
        justifyContent="center"
        marginTop={3}
      >
        <IonButton
          size="small"
          shape="round"
          className="btn-bold"
          onClick={dismissMeta.callback}
          style={{
            '--background-hover': '#063D8F',
            '--background-activated': '#063D8F',
            '--background-focused': '#063D8F',
            width: '30%',
          }}
          mode="ios"
        >
          {dismissMeta.label}
        </IonButton>
      </Box>
      )}
  </Box>
);

ToastContent.propTypes = {
  body: PropTypes.string.isRequired,
  dismissMeta: PropTypes.shape({
    label: PropTypes.string,
    callback: PropTypes.func,
  }),
  header: PropTypes.string,
};

ToastContent.defaultProps = {
  header: null,
  dismissMeta: null,
};

export default ToastContent;
