/* eslint-disable quote-props */
import React from 'react';
import BackrsButton from 'common/atoms/backrsButton/BackrsButton';
import { Stack, Box, Typography } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { useSelector } from 'react-redux';
import { openInBrowser } from 'helpers/broswer';
import { sessionSelector } from 'redux/slices/session';
import appStrings from 'common/app_strings';
import MainImage from 'pages/wholeme/molecules/mainImage/MainImage';
import { IonContent, IonPage } from '@ionic/react';

const fixedButtonContOverride = {
  position: 'fixed',
  bottom: '1px',
  width: '80%',
  height: '110px',
};

const updateAppBtnOverride = {
  width: '100%',
  maxWidth: '30rem',
  height: '57px',
  fontSize: '20px',
  fontWeight: 700,
  borderRadius: '40px',
  color: '#ffffff',
  backgroundColor: '#063D8F',
  border: 'none',
  '&:hover': {
    backgroundColor: '#063D8F',
  },
};

const bodyStyle = {
  textAlign: 'center',
  fontSize: '16px',
  color: '#2d2d2d',
};

const headerStyle = {
  color: '#03295F',
  fontSize: '24px',
  fontWeight: 700,
};

const UpdateUserApp = () => {
  const { version: { updateAppScreen } } = appStrings;
  const { appStoreUrl } = useSelector(sessionSelector);
  const platform = Capacitor.getPlatform();

  const openAppStore = async () => {
    if (!appStoreUrl) {
      console.log('Running on: ', platform);
      return;
    }
    await openInBrowser(appStoreUrl);
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <Box spacing={4} sx={{ mx: '2.5rem', my: '5rem' }}>
          <Stack spacing={0.5} direction="column" alignItems="center">
            <Typography
              sx={headerStyle}
            >
              {updateAppScreen.header1}
            </Typography>
            <Typography
              sx={headerStyle}
            >
              {updateAppScreen.header2}
            </Typography>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              my: 2,
            }}
          >
            <Typography
              sx={bodyStyle}
            >
              {updateAppScreen.body}
            </Typography>
            <MainImage
              imageSrc="/assets/register/update-app-hero.svg"
              imageAlt="update app svg"
            />
          </Stack>
          <Stack
            sx={fixedButtonContOverride}
          >
            <BackrsButton onClick={openAppStore} label={updateAppScreen.button.label} buttonStylesOverride={updateAppBtnOverride} />
          </Stack>
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default UpdateUserApp;
