import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import ProfileIntakeStepPage from 'pages/wholeme/molecules/profileIntakeStepPage/ProfileIntakeStepPage';
import { usePhotoGallery } from 'hooks/usePhotoGallery';
import PhotoSelector from 'common/molecules/PhotoSelector/PhotoSelector';
import { Stack } from '@mui/material';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { firstValueOf } from '../../../util/profile_util';

const ProfileIdentityIntakePhotoPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
  exitIconOnClickOverride,
}) => {
  const [previewMeta, setPreviewMeta] = useState(null);
  const [showCropCont, setShowCropCont] = useState(false);
  const [blob, setBlob] = useState(null);
  const [working, setWorking] = useState(false);
  const [isSaveContinueDisabled, setIsSaveContinueDisabled] = useState(true);
  const { isSaveAndContinueAlwaysEnabled } = useFeatureFlags();

  useEffect(() => {
    if (isSaveAndContinueAlwaysEnabled) setIsSaveContinueDisabled(false);
    else if (blob || previewMeta?.uri) setIsSaveContinueDisabled(false);
    else setIsSaveContinueDisabled(true);
  }, [isSaveAndContinueAlwaysEnabled, blob, previewMeta]);

  const { saveBlobAsMedia } = usePhotoGallery();
  useEffect(() => {
    wholeMeClient.fetchPhotoState().then((state) => {
      setPreviewMeta({
        uri: firstValueOf(state?.profile_image),
      });
    });
  }, []);

  const ingressBlob = (blobToUpdate) => {
    setBlob(blobToUpdate);
    setShowCropCont(false);
  };

  const handleSavePhoto = () => {
    if (blob) {
      setWorking(true);
      saveBlobAsMedia(blob).then((result) => {
        wholeMeClient.savePhotoState(result.media.id).then(() => {
          setWorking(false);
          nextOnClick();
        });
      });
    } else {
      nextOnClick();
    }
  };

  const setIsCropping = () => {
    setShowCropCont(true);
  };

  return (
    <ProfileIntakeStepPage
      paginationMeta={paginationMeta}
      title="Upload a Profile Pic"
      subtitle="It’s easier to connect when people can put a face to the name 😁"
      prevButton={{ onClick: prevOnClick }}
      nextButton={{ onClick: handleSavePhoto, isDisabled: isSaveContinueDisabled }}
      // eslint-disable-next-line
      NavComponent={showCropCont ? <></> : undefined}
      backgroundOverlay="/assets/profile/identity/profile-picture-overlay.svg"
      exitIconOnClickOverride={exitIconOnClickOverride}
    >
      {(!previewMeta) ? (
        <WholeMeLoadingCircle />
      ) : (
        <Stack alignItems="center">
          <PhotoSelector
            previewMeta={previewMeta}
            setBlob={ingressBlob}
            setIsCropping={setIsCropping}
            isWorking={working}
          />
        </Stack>
      )}
    </ProfileIntakeStepPage>
  );
};
export default ProfileIdentityIntakePhotoPage;
