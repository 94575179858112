import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IonButton, IonSpinner } from '@ionic/react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import appStrings from '../common/app_strings';
import { TaupeTextField } from './StyledInput';
import './ReportForm.css';

const ReportForm = ({ onSubmit, type, role }) => {
  const [category, setCategory] = useState('offensive');
  const [reason, setReason] = useState('');
  const [block, setBlock] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    report: {
      reportType, categories, submitButton, placeholders,
    },
  } = appStrings;

  const doSubmit = async () => {
    setLoading(true);
    await onSubmit({
      category,
      reason,
      block,
    });
    setLoading(false);
  };

  return (
    <Stack spacing={2.5} sx={{ mx: 3, mt: 3 }}>
      <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: 2 }}>
        <Typography
          component="h2"
          sx={{ color: '#2d2d2d', fontSize: '1.125rem', fontWeight: 600 }}
        >
          Why are you reporting this
          {' '}
          {reportType[type]}
          ? *
        </Typography>
        <TaupeTextField
          aria-modal="true"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          defaultValue="offensive"
          select
          fullWidth
          size="small"
          disabled={loading}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              sx: { maxHeight: 250 },
            },
          }}
          sx={{
            mt: 2,
            '& .MuiSelect-select .notranslate::after': {
              content: '"Select a state"',
              opacity: 0.5,
            },
          }}
        >
          <MenuItem value="offensive" disableRipple color="#2d2d2d">
            {categories[type].offensive}
          </MenuItem>
          <MenuItem value="spam" disableRipple>
            {categories[type].spam}
          </MenuItem>
          <MenuItem value="copyright" disableRipple>
            {categories[type].copyright}
          </MenuItem>
          {(type === 'users' || type === 'conversations') && (
            <MenuItem value="inappropriate" disableRipple>
              {categories[type].inappropriate}
            </MenuItem>
          )}
          <MenuItem value="other" disableRipple>
            Other
          </MenuItem>
        </TaupeTextField>
      </Box>
      <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: 2 }}>
        <Typography
          component="h2"
          sx={{ color: '#2d2d2d', fontSize: '1.125rem', fontWeight: 600 }}
        >
          Anything to add?
        </Typography>
        <TaupeTextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder={placeholders.reason}
          multiline
          minRows={5}
          fullWidth
          size="small"
          sx={{ mt: 2 }}
          disabled={loading}
        />
      </Box>
      {(type === 'users') ? (
        <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: 2 }}>
          <Typography
            component="h2"
            sx={{ color: '#2d2d2d', fontSize: '1.125rem', fontWeight: 600 }}
          >
            What else would you like to do?
          </Typography>
          <FormControlLabel
            control={(
              <Checkbox
                sx={{
                  color: '#063D8F',
                  '&.Mui-checked': {
                    color: '#063D8F',
                  },
                  pr: 1.75,
                }}
                checked={block}
                onChange={() => setBlock(!block)}
              />
            )}
            label={(
              <Typography
                sx={{
                  color: '#2d2d2d',
                }}
              >
                Block this user
              </Typography>
            )}
          />
        </Box>
      ) : null}
      {role === 'admin' && block && (
        <Typography color="error">
          {/* eslint-disable-next-line max-len */}
          Warning: Blocking Coach Lubav will prevent you from accessing Backrs content and the Backrs platform, effectively disabling your account. Proceed with caution.
        </Typography>
      )}
      <IonButton
        size="large"
        expand="block"
        shape="round"
        onClick={doSubmit}
        disabled={loading}
        style={{
          '--background': 'var(--ion-color-warning)',
          '--background-hover': 'var(--ion-color-warning)',
          '--background-activated': 'var(--ion-color-warning)',
          '--background-focused': 'var(--ion-color-warning)',
          fontWeight: 700,
        }}
      >
        {loading ? <IonSpinner name="crescent" /> : submitButton[type]}
      </IonButton>
    </Stack>
  );
};

ReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  role: PropTypes.string,
};

ReportForm.defaultProps = {
  role: '',
};

export default ReportForm;
