import React, { useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import HorizontalScrollItem from 'pages/wholeme/molecules/horizontalScrollItem/HorizontalScrollItem';
import { buildBarItems } from 'pages/wholeme/molecules/horizontalScrollBar/utils';

const stylesOverrides = {
  containerStyleOverride: {
    borderRadius: '8%',
    fontSize: '16px',
  },
};

const HorizontalScrollBar = ({ itemMeta }) => {
  const { onClickCallBack, experiences } = itemMeta;
  const [barItems] = useState(buildBarItems(experiences, onClickCallBack));
  const barItemsTrimmed = barItems.filter((i) => i.item !== null); // filter out empty results
  return (
    <Stack direction="row" spacing={2} sx={{ height: '95px' }}>
      {!!barItemsTrimmed
        && barItemsTrimmed.map((i) => (
          <HorizontalScrollItem
            key={i.item?.id}
            icon={i.item?.icon}
            text={i.item?.text}
            type={i.item?.type}
            onClick={i.onClick}
            stylesOverrides={stylesOverrides}
          />
        ))}
    </Stack>
  );
};

HorizontalScrollBar.propTypes = {
  itemMeta: PropTypes.shape({
    value: PropTypes.string,
    onClickCallBack: PropTypes.func,
  }).isRequired,
};

export default HorizontalScrollBar;
