import React, { useState, useEffect } from 'react';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import SearchInputField from './SearchInputField';
import SearchInputResultsList from './SearchInputResultList';

const SearchableDropdownInputField = ({
  options = [],
  maxNumberOfChoices = 1,
  iconOverride,
  inputPlaceholder,
  searchQuery,
  setSearchQuery,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [initializedInput, setInitializedInput] = useState();
  const [searchOptions, setSearchOptions] = useState(options);
  const [showSearchOptions, setShowSearchOptions] = useState();
  const isSingleValue = maxNumberOfChoices === 1;

  useEffect(() => {
    if (isSingleValue && selectedOptions[0]) {
      setInitializedInput(selectedOptions[0]);
      setSearchQuery(selectedOptions[0]);
    }
  }, [selectedOptions]);

  const createNewSelectedOptions = (alreadySelectedOptions, newOption) => {
    if (!isSingleValue && alreadySelectedOptions.length === maxNumberOfChoices) return alreadySelectedOptions;
    return isSingleValue
      ? [newOption]
      : alreadySelectedOptions.includes(newOption)
        ? alreadySelectedOptions
        : [...alreadySelectedOptions, newOption];
  };

  const handleInputOnChange = (query) => {
    setInitializedInput(null);
    setSearchQuery(query);
    if (query?.length >= 2) {
      setSearchOptions(options.filter((option) => option.toLowerCase().includes(query.toLowerCase())));
    } else setSearchOptions(options);
  };

  const handleOptionSelect = (newlySelectedOption) => {
    setInitializedInput(null);
    setSelectedOptions(createNewSelectedOptions(selectedOptions, newlySelectedOption));
    setSearchQuery(isSingleValue ? newlySelectedOption : '');
    setShowSearchOptions(false);
  };

  const handleUnselectPill = (option) => {
    setSelectedOptions(selectedOptions.filter((n) => n !== option));
  };

  const onInputExitClick = () => handleOptionSelect('');

  const inputFieldValue = initializedInput || searchQuery;
  const renderSearchOptions = searchOptions?.length > 0 && showSearchOptions;
  const renderPills = !isSingleValue;

  return (
    <SearchInputField
      inputPlaceholder={inputPlaceholder}
      iconOverride={iconOverride || 'search'}
      handleInputChange={(e) => handleInputOnChange(e.target.value)}
      handleOnFocus={() => setShowSearchOptions(true)}
      handleExitClick={isSingleValue ? () => onInputExitClick() : null}
      inputFieldValue={inputFieldValue}
      renderSearchOptions={renderSearchOptions}
      searchOptions={searchOptions}
      handleSearchOptionSelect={handleOptionSelect}
      renderPills={!isSingleValue}
      selectedOptions={selectedOptions}
      handlePillClick={(value) => handleUnselectPill(value)}
    >
      <>
        { renderSearchOptions && (
          <SearchInputResultsList
            searchOptions={searchOptions}
            handleSelect={handleOptionSelect}
          />
        )}
        { renderPills && (
          <div style={{ marginLeft: '4px' }}>
            <PillRenderer
              items={selectedOptions || []}
              activeItems={selectedOptions || []}
              handlePillClick={(value) => handleUnselectPill(value)}
            />
          </div>
        )}
      </>
    </SearchInputField>
  );
};

export default SearchableDropdownInputField;
