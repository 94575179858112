/* eslint-disable camelcase */
import React from 'react';
import { QuotationIcon, EditIcon } from 'pages/wholeme/icons/Icons';
import TruncateText from 'components/TruncateText';
import WholeMeProfileSection from '../wholeMeProfileSection/WholeMeProfileSection';

const quotationStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  fontWeight: '700',
  fontSize: '17px',
  color: '#858585F0',
  opacity: '94%',
  padding: '4px 5px',
};

const textStyle = {
  overflow: 'hidden',
  overflowWrap: 'break-word',
};

const WholeMeProfileQuotationTemplate = ({
  label, editHandler, editPrompt, postHandler, isViewOnly,
}) => {
  if (!label && !editHandler) {
    return null;
  }

  if (!label) {
    return <div onClick={editHandler}>{editPrompt}</div>;
  }
  return (
    <WholeMeProfileSection expanded expandedBorder={false}>
      <div style={quotationStyle} onClick={postHandler}>
        <span style={textStyle}>
          {editHandler && !isViewOnly && (
            <span onClick={editHandler}>
              <EditIcon width={19} height={24} paddingRight={7} cursor="pointer" marginBottom={-3} />
            </span>
          )}
          <TruncateText text={label} maxChars={115} />
        </span>
        <QuotationIcon width={40} height={41.49} marginLeft={1} />
      </div>
    </WholeMeProfileSection>
  );
};

export default WholeMeProfileQuotationTemplate;
