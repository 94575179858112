import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage, IonIcon } from '@ionic/react';
import { sparkles } from 'ionicons/icons';
import {
  Chip, Avatar, Divider, Typography, Box, Stack,
} from '@mui/material';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import useFetch, { CachePolicies } from 'use-http';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import { useAuthContext } from 'providers/auth-provider';
import { useAppStateDispatch, actions } from 'providers/app-state/app-state-provider';
import PostIndicator from 'components/PostIndicator';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './Goals.css';
import FilterBar from 'common/atoms/filterBar/FilterBar';
import appStrings from 'common/app_strings';
import UnlockScreen from '../../../common/molecules/UnlockScreen';
import { getTeamFilters } from './utils';

const { goals: strings } = appStrings;

const defaultProfileImage = '/assets/profile/default-profile-image.svg';

const BackrGoals = () => {
  const { user, reported } = useAuthContext();
  const [goals, setGoals] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const [showUnlock, setShowUnlock] = useState(false);
  const history = useHistory();
  const appStateDispatch = useAppStateDispatch();

  const { get: fetchUserTeams, loading: isRelationshipsLoading } = useFetch('/users/teams', {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const { get: fetchAllGoals, loading: isAllGoalsLoading } = useFetch('/goals', {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const { get: fetchProtegeGoals, response: protegeGoalRes } = useFetch(
    `/goals?protege_user_id=${selectedTeam?.value}`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
  );
  const { post: postMatchRun, response: matchRunResponse } = useFetch('/matching/match-run');

  const loadAllRelatedUsersGoals = async () => {
    const allProtegeGoals = await fetchAllGoals();
    setGoals(allProtegeGoals);
  };

  const loadSingleRelatedUserGoals = async () => {
    const protegeGoals = await fetchProtegeGoals();
    if (protegeGoalRes.ok) setGoals(protegeGoals);
    else setGoals([]);
  };

  const goToRelatedPost = (id) => {
    history.push(`/posts/${id}`);
  };

  const handleJoinATeamOnClick = async () => {
    try {
      const matchRun = await postMatchRun();
      if (!matchRunResponse.ok) {
        throw new Error(matchRunResponse.data);
      }
      appStateDispatch(actions.setMatchRun(matchRun));

      if (matchRun?.id) {
        history.push('/match-preview-screen');
      } else {
        history.push('/wholeme/pre-matching');
      }
    } catch (e) {
      console.error(e);
      toast.error(`Unable to Join a Team: ${e.message}`, { id: 'forbidden' });
      history.push('/tabs/feed');
    }
  };

  const unlockGoalsButtonMeta = {
    label: strings.unlockGoals.button,
    callback: handleJoinATeamOnClick,
  };

  useEffect(() => {
    if (!selectedTeam || selectedTeam.value === 'all') {
      loadAllRelatedUsersGoals();
    } else {
      loadSingleRelatedUserGoals();
    }
  }, [selectedTeam]);

  const fetchInitialData = async () => {
    if (user.role === 'backr') {
      const userTeams = await fetchUserTeams();
      const allGoals = await fetchAllGoals();
      return { goals: allGoals || [], teams: userTeams || [] };
    }
    return { goals: [], teams: [] };
  };

  useEffect(() => {
    fetchInitialData().then((res) => {
      if (!res.teams.length && !res.goals.length) {
        setShowUnlock(true);
      }
      const userHasTeams = !!res.teams.length;
      const handledItems = getTeamFilters(res.teams);
      if (handledItems) {
        const filterBarItems = handledItems.filter(
          (item) => item.relation === 'backing' || item.value === 'all',
        );
        setFilterItems(filterBarItems);
      }
      setGoals(res.goals);
      setIsInitiallyLoaded(true);
      if (userHasTeams && !res.goals.length) {
        history.push('/tabs/backr/goals-empty');
      }
    });
  }, []);

  const handleFilterChange = (item) => {
    setSelectedTeam(item);
  };

  const unlockGoalsMeta = {
    variant: 'goals',
    title: strings.unlockGoals.title,
    body: strings.unlockGoals.body,
    image: 'assets/bullseye.svg',
    buttonMeta: unlockGoalsButtonMeta,
    userReported: reported,
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        {(isAllGoalsLoading || isRelationshipsLoading) && !isInitiallyLoaded ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            className="goals-backr-list"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            {showUnlock ? (
              <Stack
                sx={{
                  pt: '4rem',
                }}
              >
                <UnlockScreen meta={unlockGoalsMeta} />
              </Stack>
            ) : (
              <>
                {filterItems?.length > 1 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                        mt: '15px',
                        pb: '6px',
                        ml: '24px',
                        mr: '24px',
                      }}
                    >
                      <FilterBar
                        disableClickEventsOverrideRef={null}
                        filterMeta={filterItems}
                        onChangeCallBack={handleFilterChange}
                        initialSelectedFilterValue="all"
                        selectedFilter={selectedTeam}
                        setSelectedFilter={setSelectedTeam}
                      />
                    </Box>
                  </motion.div>
                )}
                {goals?.length < 1 ? (
                  <Typography
                    sx={{
                      color: '#2d2d2d',
                      textAlign: 'center',
                      fontWeight: 500,
                      pt: 4,
                    }}
                  >
                    {selectedTeam && !isAllGoalsLoading ? strings.noPublicGoals : null}
                  </Typography>
                ) : (
                  goals?.map((goal) => (
                    <React.Fragment key={goal.id}>
                      <div className="goals-backr-item">
                        <div className="goals-backr-item-description">
                          <div className="goals-backr-avatar-wrapper">
                            <Avatar
                              src={goal?.user?.profile_image}
                              sx={{ width: 52, height: 52, fontSize: 24 }}
                            >
                              <img style={{ width: 52, height: 52 }} src={defaultProfileImage} alt="profile" />
                            </Avatar>
                            <Typography
                              sx={{
                                color: '#2d2d2d',
                                fontSize: 15,
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                mt: 0.5,
                              }}
                            >
                              {`${goal?.user?.first_name} ${goal?.user?.last_name?.charAt(0)}.`}
                            </Typography>
                          </div>
                          <div className="goals-backr-item-text-wrapper">
                            <PostIndicator
                              type={goal?.type}
                              text={`${goal?.type} goal`}
                              sx={{ height: '20px', mb: 1 }}
                            />
                            <Typography
                              className="goals-backr-item-text"
                              color="#2d2d2d"
                              sx={{
                                fontSize: '16px',
                              }}
                            >
                              {goal?.content}
                            </Typography>
                          </div>
                        </div>
                        <div className="goals-backr-item-status">
                          <div>
                            {goal?.post?.id && (
                              <Chip
                                label={strings.seeUpdates}
                                sx={{
                                  backgroundColor: '#03295F',
                                  color: '#fff',
                                  fontWeight: 700,
                                  height: 33,
                                }}
                                onClick={() => goToRelatedPost(goal?.post?.id)}
                              />
                            )}
                          </div>
                          {goal?.status === 'completed' && (
                            <div>
                              <Typography
                                variant="span"
                                sx={{
                                  color: '#2CB669',
                                  fontSize: 14,
                                  fontWeight: 700,
                                  mr: 0.75,
                                }}
                              >
                                {strings.achieved}
                              </Typography>
                              <IonIcon
                                style={{ color: '#2CB669' }}
                                slot="icon-only"
                                icon={sparkles}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <Divider sx={{ mt: 2 }} />
                    </React.Fragment>
                  ))
                )}
              </>
            )}
          </motion.div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default BackrGoals;
