import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  Box,
} from '@mui/material';
import VideoPlayer from '../../../components/VideoPlayer';

const ProtegeBottom = ({
  title,
  videoUrl,
  videoPreviewUrl,
}) => (
  <Stack spacing={1.5}>
    <Typography
      sx={{
        color: '#2d2d2d',
        fontWeight: 600,
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        width: 1,
        height: 215,
        overflow: 'hidden',
        '& > div': {
          width: 1,
          height: 1,
        },
        '& img': {
          objectFit: 'contain',
          objectPosition: 'center',
        },
        '& video': {
          width: 'inherit',
          height: 'inherit',
          objectFit: 'cover',
          objectPosition: 'center',
        },
      }}
    >
      <VideoPlayer
        videos={[
          {
            uri: videoUrl,
            thumbnail: videoPreviewUrl,
          },
        ]}
      />
    </Box>
  </Stack>
);

ProtegeBottom.propTypes = {
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  videoPreviewUrl: PropTypes.string.isRequired,
};

export default ProtegeBottom;
