export const deriveUserRole = ({
  role: userRole,
  aliasAdminAsSystem,
}) => {
  const systemRoles = aliasAdminAsSystem ? ['system', 'admin'] : ['system'];
  const shouldAliasRoleAsAdmin = systemRoles.includes(userRole);

  return shouldAliasRoleAsAdmin ? 'admin' : userRole;
};

export const identityBelongsToSystemAgent = (identity) => {
  const substrings = ['system', 'super-admin', 'agent'];
  return substrings.some((substring) => identity.includes(substring));
};
