import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { Client as Twilio } from '@twilio/conversations';
import { useAuthContext } from '../providers/auth-provider';
import { convertToMills } from '../helpers/dateTime';
import { computeEnvironment } from '../helpers/environment';
import useInterval from './useInterval';

const env = computeEnvironment();
const logTwilioStatus = (status, id) => {
  if (env === 'development') {
    console.log(`Twilio Connection Status: ${id} - ${status}`);
  }
};

const maxReconnectionAttempts = 10;
const reconnectionDelay = convertToMills(5, 'seconds');

const RECONNECTION_ACTIONS = {
  ABORT: 'abort',
  ATTEMPT: 'attempt',
  PASS: 'pass',
  ERROR: 'error',
};

const TWILIO_CONNECTION_STATES = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  DENIED: 'denied',
  ERROR: 'error',
};

const TWILIO_EVENTS = {
  TOKEN_EXPIRED: 'tokenExpired',
  TOKEN_ABOUT_TO_EXPIRE: 'tokenAboutToExpire',
  CONNECTION_STATE_CHANGED: 'connectionStateChanged',
  MESSAGE_ADDED: 'messageAdded',
  PARTICIPANT_UPDATED: 'participantUpdated',
};

const useTwilio = ({
  passedJwt = null,
  usingPassedJwt = false,
  onMessageAdded = () => {},
  disabled = false,
  onParticipantUpdated = () => {},
  tag = null,
} = {}) => {
  const {
    user,
    twilioJwt,
    refreshTwilioJwt,
    fetchingJwt,
  } = useAuthContext();

  const clientRef = useRef(null);
  const mountedRef = useRef(null);
  const reconnectionCountRef = useRef(0);
  const reconnectionInProgressRef = useRef(null);
  const isLoadingJwtRef = useRef(false);
  const idRef = useRef(null);

  const [client, setClient] = useState(null);
  const [connectionError, setConnectionError] = useState(null);
  const [clientConnected, setClientConnected] = useState(null);
  const [retryConnectionDelay, setRetryConnectionDelay] = useState(null);
  const [reconnectionOptions, setReconnectionOptions] = useState(null);

  const destroyClientRef = () => {
    if (clientRef.current) {
      clientRef.current.shutdown();
      clientRef.current = null;
    }
  };

  const cancelReconnectionAttempts = () => {
    setRetryConnectionDelay(null);
    reconnectionInProgressRef.current = null;
    reconnectionCountRef.current = 0;
  };

  const resetReconnectionState = () => {
    cancelReconnectionAttempts();
    setConnectionError(null);
  };

  const destroyClient = () => {
    destroyClientRef();
    resetReconnectionState();
    setClientConnected(false);
    if (client) {
      setClient(null);
    }
  };

  const handleRefreshTwilioClient = useCallback(async (shouldHardRefreshJwt = true) => {
    destroyClientRef();
    setClient(null);

    setConnectionError(null);
    await refreshTwilioJwt(shouldHardRefreshJwt);
  }, [
    setClient,
    setConnectionError,
    destroyClientRef,
    refreshTwilioJwt,
  ]);

  const getReconnectionAction = ({
    clientRequired = true,
  }) => {
    const hasHitConnectionAttemptLimit = reconnectionCountRef.current >= maxReconnectionAttempts;
    if (hasHitConnectionAttemptLimit) {
      return RECONNECTION_ACTIONS.ABORT;
    }

    const isMounted = mountedRef.current;
    if (!isMounted) {
      return RECONNECTION_ACTIONS.ABORT;
    }

    const clientExists = clientRef.current !== null;
    const allowedClientState = clientRequired ? clientExists : true;
    if (!allowedClientState && clientRequired) {
      return RECONNECTION_ACTIONS.ABORT;
    }

    const isLoading = isLoadingJwtRef.current;
    if (isLoading) {
      return RECONNECTION_ACTIONS.PASS;
    }

    const canAttempt = (isMounted && allowedClientState && !isLoading);
    if (canAttempt) {
      return RECONNECTION_ACTIONS.ATTEMPT;
    }

    return RECONNECTION_ACTIONS.ERROR;
  };

  const retryConnection = useCallback(async ({
    shouldHardRefreshJwt = true,
    clientRequired = true,
  }) => {
    reconnectionInProgressRef.current = true;
    const reconnectionAction = getReconnectionAction({ clientRequired });

    if (reconnectionAction === RECONNECTION_ACTIONS.ABORT) {
      cancelReconnectionAttempts();
      return;
    }

    if (reconnectionAction === RECONNECTION_ACTIONS.ERROR) {
      cancelReconnectionAttempts();
      destroyClient();
      setConnectionError(new Error('Failed to connect to Twilio'));
      return;
    }

    if (reconnectionAction === RECONNECTION_ACTIONS.ATTEMPT) {
      handleRefreshTwilioClient(shouldHardRefreshJwt);
      reconnectionCountRef.current += 1;
    }
  }, [reconnectionOptions]);

  const { stop: stopReconnectionAttempts } = useInterval(() => retryConnection(reconnectionOptions), retryConnectionDelay);

  const handleTokenExpirationEvent = useCallback(async () => {
    setReconnectionOptions({
      shouldHardRefreshJwt: true,
      clientRequired: true,
    });
    setRetryConnectionDelay(reconnectionDelay);
  }, [refreshTwilioJwt]);

  const handleStateChange = useCallback((state) => {
    logTwilioStatus(state, idRef.current);

    const handleSetClientConnection = (isConnected) => {
      if (mountedRef.current) {
        setClientConnected(isConnected);
      }
    };

    if (state === TWILIO_CONNECTION_STATES.DENIED || state === TWILIO_CONNECTION_STATES.ERROR) {
      setReconnectionOptions({
        shouldHardRefreshJwt: true,
        clientRequired: true,
      });
      setRetryConnectionDelay(reconnectionDelay);
    }

    if (state === TWILIO_CONNECTION_STATES.CONNECTED) {
      handleSetClientConnection(true);
      setClientConnected(true);
      resetReconnectionState();
    }

    if (state === TWILIO_CONNECTION_STATES.DISCONNECTED) {
      handleSetClientConnection(false);
      setReconnectionOptions({
        shouldHardRefreshJwt: false,
        clientRequired: true,
      });
      setRetryConnectionDelay(reconnectionDelay);
    }
  }, [setConnectionError]);

  const setDefaultEventListenersOnClient = useCallback((c) => {
    if (c) {
      c.removeAllListeners();
      c.on('tokenExpired', handleTokenExpirationEvent);
      c.on('tokenAboutToExpire', handleTokenExpirationEvent);
      c.on('connectionStateChanged', handleStateChange);
    }
  }, [handleTokenExpirationEvent, handleStateChange]);

  const handleGetNewTwilioClient = (jwt) => {
    const twilioClient = new Twilio(jwt);
    destroyClientRef();
    clientRef.current = twilioClient;
    setDefaultEventListenersOnClient(twilioClient);
    setClient(twilioClient);
  };

  // use effects
  useEffect(() => {
    mountedRef.current = true;
    idRef.current = `${tag}:${Math.random()}`;
    return () => {
      stopReconnectionAttempts();
      mountedRef.current = false;
      destroyClient();
    };
  }, []);

  useEffect(() => {
    if (disabled || !user) {
      destroyClient();
      return;
    }

    const jwtToUse = passedJwt || twilioJwt;
    if (!jwtToUse) {
      setReconnectionOptions({
        shouldHardRefreshJwt: false,
        clientRequired: false,
      });
      setRetryConnectionDelay(reconnectionDelay);
      return;
    }

    if (((usingPassedJwt && passedJwt) || (!usingPassedJwt && twilioJwt))) {
      if (!clientRef.current) {
        handleGetNewTwilioClient(jwtToUse);
      }
    }
  }, [twilioJwt, refreshTwilioJwt, passedJwt, disabled, user, tag]);

  // make sure that we only ever have one listener for messageAdded events
  useEffect(() => {
    if (client && onMessageAdded) {
      client.removeAllListeners(TWILIO_EVENTS.MESSAGE_ADDED);
      client.on(TWILIO_EVENTS.MESSAGE_ADDED, onMessageAdded);
    }
  }, [client, onMessageAdded]);

  // make sure that we only ever have one listener for participantUpdated events
  useEffect(() => {
    if (client && onParticipantUpdated) {
      client.removeAllListeners(TWILIO_EVENTS.PARTICIPANT_UPDATED);
      client.on(TWILIO_EVENTS.PARTICIPANT_UPDATED, onParticipantUpdated);
    }
  }, [client, onParticipantUpdated]);

  useEffect(() => {
    isLoadingJwtRef.current = fetchingJwt;
  }, [fetchingJwt]);

  return {
    client,
    handleRefreshTwilioClient,
    connectionError,
    clientConnected,
  };
};

export default useTwilio;
