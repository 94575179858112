import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { parseCSV } from '../../helpers/textManipulation';
import Pill from '../atoms/Pill/Pill';
import useWindowDimensions from '../../helpers/hooks';

const UserInterests = ({ interests }) => {
  const [meta, setMeta] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (Array.isArray(interests)) {
      setMeta(interests.slice(0, 3));
    }
    if (interests && (typeof interests === 'string' || interests instanceof String)) {
      setMeta(parseCSV(interests)[0].slice(0, 3));
    }
  }, [interests]);

  return (
    meta && (
      <Box
        sx={{
          '--gap': '8px',
          display: 'flex',
          gap: 'var(--gap)',
          flexWrap: 'wrap',
          mt: 1.5,
          width: width >= 415 ? '100%' : '200px',
        }}
      >
        {meta.map((item) => (
          item !== null && (
            <Pill
              key={item}
              label={item}
              pillStylesOverride={{
                backgroundColor: '#E8E8DC',
                color: '#848484',
                fontSize: '10px',
                textTransform: 'capitalize',
              }}
            />
          )
        ))}
      </Box>
    )
  );
};

UserInterests.propTypes = {
  interests: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

UserInterests.defaultProps = {
  interests: '',
};

export default UserInterests;
