import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import VideoPlayer from '../../../components/VideoPlayer';

const BackingMiddle = ({
  videoUrl,
  videoPreviewUrl,
}) => (
  <Box
    sx={{
      width: 1,
      borderRadius: 3,
      overflow: 'hidden',
      '& > div': {
        width: 1,
        height: 1,
      },
      '& img': {
        objectFit: 'contain',
        objectPosition: 'center',
      },
      '& video': {
        width: 1,
        height: 1,
        display: 'flex',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    }}
  >
    <VideoPlayer
      isAnimation
      animationRadius="12px"
      videos={[
        {
          uri: videoUrl,
          thumbnail: videoPreviewUrl,
        },
      ]}
    />
  </Box>
);

BackingMiddle.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  videoPreviewUrl: PropTypes.string.isRequired,
};

export default BackingMiddle;
