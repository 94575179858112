import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import BackrInterestsAtom from './BackrInterestsAtom';
import BackrAboutAtom from './backrAboutAtom';
import BackrBadge from './BackrBadgeAtom';
import appStrings from '../../app_strings';

const { workflows: { meetTheBackrs: { aboutHeader, interestsHeader } } } = appStrings;

const BackrInfoAvatar = ({
  meta,
}) => (
  <Stack alignItems="center">
    <Avatar
      aria-label="user profile picture"
      src={meta?.src}
      sx={{
        width: 180,
        height: 180,
        fontSize: 60,
        '& .MuiAvatar-img': {
          width: '100% !important',
          height: '100% !important',
        },
      }}
    />
    <Box
      sx={{
        position: 'relative',
        top: -32,
        background: '#063D8F',
        minWidth: 165,
        pb: 1,
        borderRadius: 2,
        zIndex: 10,
      }}
    >
      {meta.showBadge && (
      <BackrBadge
        imageSrc={meta.badge_url}
        style={{
          zIndex: 100,
          height: 35,
          width: 35,
          position: 'absolute',
          top: '-.7em',
          right: '-.7em',
        }}
      />
      )}
      <Stack spacing={-0.75}>
        <Typography
          align="center"
          sx={{ color: '#fff', fontSize: 22, fontWeight: 700 }}
        >
          {`${meta?.firstName} ${meta?.lastName?.charAt(0)}.`}
        </Typography>
        <Typography
          align="center"
          sx={{ color: '#fff', fontSize: 12, fontWeight: 700 }}
        >
          Backr
        </Typography>
      </Stack>
    </Box>
    <Box
      sx={{
        background: '#fff',
        width: 1,
        p: 2,
        pt: 4,
        mt: -7.25,
        borderRadius: 2,
      }}
    >
      <Stack
        sx={{
          width: '100%',
        }}
      >
        <Stack
          sx={{
            marginBottom: '15px',
          }}
        >
          <BackrAboutAtom
            header={aboutHeader}
            location={meta?.about?.location}
            occupation={meta?.about?.occupation}
            education={meta?.about?.education}
            organization={meta?.about?.organization}
          />
        </Stack>
        <Stack>
          <BackrInterestsAtom
            header={interestsHeader}
            meta={meta?.interests}
          />
        </Stack>
      </Stack>
    </Box>
  </Stack>
);

BackrInfoAvatar.propTypes = {
  meta: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    src: PropTypes.string,
    about: PropTypes.shape({
      location: PropTypes.string,
      occupation: PropTypes.string,
      education: PropTypes.string,
      organization: PropTypes.string,
    }),
    interests: PropTypes.arrayOf(PropTypes.string),
    showBadge: PropTypes.bool,
    badge_url: PropTypes.string,
  }).isRequired,
};

export default BackrInfoAvatar;
