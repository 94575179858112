const marginBottom = {
  marginBottom: '18px',
};
const fontStyle = {
  fontSize: '20px',
  padding: '0 10px',
};

// eslint-disable-next-line import/prefer-default-export
export const financesXpData = [
  {
    label: 'Rented an Apartment',
    value: 'rented-an-apartment',
    imageSrc: 'assets/profile/finances/rented-an-apartment.svg',
    imageAlt: 'A House',
    iconSrc: 'assets/profile/wholeMe/experiences/house.svg',
    infoUrl: 'https://www.rentcafe.com/blog/apartmentliving/tips-tricks-renters/know-rental-application-process/',
    style: { content: fontStyle },
  },
  {
    label: 'Created a Budget',
    value: 'created-a-budget',
    imageSrc: 'assets/profile/finances/created-a-budget.svg',
    imageAlt: 'Money in Wallet',
    iconSrc: 'assets/profile/wholeMe/experiences/money-wallet.svg',
    infoUrl: 'https://www.ramseysolutions.com/budgeting/steps-to-help-you-stick-to-your-budget',
    style: { content: fontStyle, img: marginBottom },
  },
  {
    label: 'Invested Money',
    value: 'invested-money',
    imageSrc: 'assets/profile/finances/invested-money.svg',
    imageAlt: 'sunflower on hill',
    iconSrc: 'assets/profile/wholeMe/experiences/money-flower.svg',
    infoUrl: 'https://www.nerdwallet.com/article/investing/investments-for-beginners',
    style: { content: fontStyle },
  },
  {
    label: 'Paid off Student Loans',
    value: 'paid-off-student-loans',
    imageSrc: 'assets/profile/finances/paid-off-student-loans.svg',
    imageAlt: 'A receipt',
    iconSrc: 'assets/profile/wholeMe/experiences/money-contract.svg',
    infoUrl: 'https://www.nerdwallet.com/article/loans/student-loans/paid-off-student-loans-now-what',
    style: { content: fontStyle, img: marginBottom },
  },
  {
    label: 'Opened a credit card',
    value: 'opened-a-credit-card',
    imageSrc: 'assets/profile/finances/opened-a-credit-card.svg',
    imageAlt: 'A credit card',
    iconSrc: 'assets/profile/wholeMe/experiences/blue-credit-card.svg',
    infoUrl: 'https://www.creditkarma.com/credit-cards/i/how-to-apply-for-a-credit-card',
    style: { content: fontStyle, img: marginBottom },
  },
  {
    label: 'Secured a Scholarship',
    value: 'secured-a-scholarship',
    imageSrc: 'assets/profile/finances/secured-a-scholarship.svg',
    imageAlt: 'A bag of money',
    iconSrc: 'assets/profile/wholeMe/experiences/handed-bag.svg',
    infoUrl: 'https://www.unigo.com/get-to-college/tips-and-strategies-to-secure-college-scholarships',
    style: { content: fontStyle, img: marginBottom },
  },
];
