import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import { backrsButtonStyles, variantStylesLookup } from './backrsButtonStyles';

const loadingStyles = {
  backgroundColor: 'hsl(216 92% 29% / 50%)',
};

const BackrsButton = ({
  label,
  iconSrc,
  variant,
  onClick,
  buttonStylesOverride,
  iconStyles,
  isIconLeftAligned,
  isDisabled,
  isLoading,
  labelElement,
  disableRipple,
}) => {
  const hasIconAndLabel = !!iconSrc && !!label;
  const RenderedIcon = !isLoading && <Icon style={iconStyles} icon={iconSrc} />;
  // Capture the overwritten background color
  const overwrittenBackgroundColor = buttonStylesOverride?.backgroundColor || 'initial';
  // if there is an Icon and Label, we let MUI button take care of rendering Icon
  const startIcon = hasIconAndLabel && isIconLeftAligned ? RenderedIcon : null;
  const endIcon = hasIconAndLabel && !isIconLeftAligned ? RenderedIcon : null;

  const variantStyles = variantStylesLookup[variant];
  const buttonSx = {
    ...backrsButtonStyles,
    ...variantStyles,
    ...buttonStylesOverride,
    ...(isLoading && { ...loadingStyles, backgroundColor: overwrittenBackgroundColor }),
  };

  const ButtonContent = labelElement || (label ? <Box>{label}</Box> : RenderedIcon);

  const LoadingButtonContent = (
    <CircularProgress
      size={24}
      thickness={5}
      sx={{ color: 'hsl(0 0% 100% / 50%)' }}
    />
  );

  return (
    <Button
      variant="contained"
      sx={buttonSx}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={isDisabled}
      disableElevation
      disableRipple={disableRipple}
    >
      {isLoading ? LoadingButtonContent : ButtonContent}
    </Button>
  );
};

export const BackrsButtonPropTypes = {
  label: PropTypes.string,
  labelElement: PropTypes.element,
  iconSrc: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  buttonStylesOverride: PropTypes.oneOfType([PropTypes.object]),
  iconStyles: PropTypes.oneOfType([PropTypes.object]),
  isIconLeftAligned: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export const BackrsButtonDefaultProps = {
  label: '',
  iconSrc: '',
  variant: 'primary',
  buttonStylesOverride: {},
  iconStyles: {},
  isIconLeftAligned: false,
  isDisabled: false,
  isLoading: false,
  labelElement: null,
  onClick: () => {},
};

BackrsButton.propTypes = BackrsButtonPropTypes;
BackrsButton.defaultProps = BackrsButtonDefaultProps;

export default BackrsButton;
