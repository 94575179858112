import React, { useEffect } from 'react';
import { IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../providers/auth-provider';

const LogoutPage = () => {
  const { doLogout } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    doLogout();
    history.push('/register/login');
  }, []);

  return (
    <IonPage />
  );
};

export default LogoutPage;
