import React, {
  useState, useEffect, useRef, useLayoutEffect,
} from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { motion } from 'framer-motion';
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import AffinityMember from './AffinityMember';
import LoadingSpinner from '../../../components/generic/LoadingSpinner';
import AffinityMemberEmptyList from './AffinityMemberEmptyList';
import { useAuthContext } from '../../../providers/auth-provider';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import useWindowDimensions from '../../../helpers/hooks';
import { feedSelector } from '../../../redux/slices/feed';

const AffinityMemberList = ({
  role, setIsLoadComplete,

}) => {
  const { activeFilter } = useSelector(feedSelector);
  const { height } = useWindowDimensions();
  const { platform } = useAppState();
  const loadingRef = useRef(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [affinityMembers, setAffinityMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [PER_PAGE, setPerPage] = useState();
  const [shouldRenderEmptyList, setShouldRenderEmptyList] = useState(false);

  const { user } = useAuthContext();
  const { get, response, abort } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const contentRef = useRef();

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(100);
  };

  const setLoadingRef = (_isLoading) => {
    loadingRef.current = _isLoading;
    setIsLoading(loadingRef.current);
  };

  const fetchAffinityMembers = async (page) => {
    try {
      const params = new URLSearchParams();
      params.append('currentPage', page);
      params.append('perPage', PER_PAGE);
      params.append('role', role);

      const res = await get(`/user-feed/group/${activeFilter.value}?${params.toString()}`);
      if (response.ok) {
        return res;
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // eslint-disable-next-line default-param-last
  const updateAffinityMembersList = async (isRefresh = false, page) => {
    setIsLoadComplete(false);
    if (isRefresh) {
      setIsLoading(true);
    }
    try {
      setLoadingRef(true);
      const newMembers = await fetchAffinityMembers(page);
      if (newMembers.length < PER_PAGE) {
        setInfiniteDisabled(true);
      }
      if (isRefresh) {
        scrollToTop();
        setAffinityMembers(newMembers);
        setInfiniteDisabled(false);
      } else {
        const uniqueNewMembers = newMembers.filter((newMember) => !affinityMembers.some((oldMember) => oldMember.user_id === newMember.user_id));
        setAffinityMembers((prevMembers) => [...prevMembers, ...uniqueNewMembers]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsLoadComplete(true);
      setLoadingRef(false);
    }
  };

  const resetFilters = () => {
    setInfiniteDisabled(true);
    setAffinityMembers([]);
    setCurrentPage(0);
    scrollToTop();
  };

  const doRefresh = async (ev) => {
    resetFilters();
    await updateAffinityMembersList(true, 0);
    ev?.detail?.complete();
  };

  const onScroll = async (e) => {
    if (shouldRenderEmptyList) {
      return;
    }
    try {
      if (!isInfiniteDisabled) {
        const nextPage = currentPage + 1;
        await updateAffinityMembersList(false, nextPage);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      e.target.complete();
    }
  };

  const handleScroll = async (e) => {
    if (shouldRenderEmptyList) {
      return;
    }
    const { scrollTop } = e.detail;
    if (scrollTop === 0) {
      setCurrentPage(0);
      await updateAffinityMembersList(false, 0);
    }
  };

  useEffect(() => {
    setIsInitialized(true);
    setLoadingRef(true);
    resetFilters();
    const initialize = async () => {
      if (role !== null && PER_PAGE !== undefined) {
        await updateAffinityMembersList(true, 0);
        setIsLoadComplete(false);
      }
    };

    initialize();

    return () => {
      resetFilters();
      abort();
    };
  }, [activeFilter.value, role, PER_PAGE]);

  useEffect(() => {
    let timer;
    if (user?.id && isInitialized && !isLoading && affinityMembers?.length === 0) {
      timer = setTimeout(() => {
        setShouldRenderEmptyList(true);
      }, 500); // Delay in milliseconds
    } else {
      setShouldRenderEmptyList(false);
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount or dependency change
  }, [user?.id, isInitialized, isLoading, affinityMembers]);

  useLayoutEffect(() => {
    if (platform?.isNativePlatform) {
      setPerPage(10);
      return;
    }

    if (contentRef.current) {
      setTimeout(() => {
        const ionContentHeight = contentRef.current.offsetHeight;
        const perPageCalc = Math.floor(ionContentHeight / 60) + 10;
        setPerPage(perPageCalc);
      }, 0);
    }
  }, [height]);

  const renderMemberList = () => (
    <motion.div
      style={{ marginTop: 0, paddingTop: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {affinityMembers.map((member, index) => (
        <AffinityMember index={index} key={member.user_id} member={member} role={role} />
      ))}
    </motion.div>
  );

  const renderEmptyList = () => <AffinityMemberEmptyList userId={user.id} />;

  return (
    <IonContent ref={contentRef} scrollEvents onIonScroll={handleScroll}>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {affinityMembers?.length > 0 ? (
        renderMemberList()
      ) : (
        (shouldRenderEmptyList && user?.id) ? renderEmptyList() : <LoadingSpinner />
      )}
      <IonInfiniteScroll onIonInfinite={onScroll} threshold="100px" disabled={isInfiniteDisabled || shouldRenderEmptyList}>
        <IonInfiniteScrollContent loadingSpinner="crescent" />
      </IonInfiniteScroll>
    </IonContent>
  );
};

export default AffinityMemberList;
