export const familyData = [
  {
    label: 'Parent/Guardian',
    value: 'parent-guardian',
    imageSrc: 'assets/profile/relationships/parent-guardian.svg',
    imageAlt: 'Family standing together',
  },
  {
    label: 'Married/Partner',
    value: 'married-partner',
    imageSrc: 'assets/profile/relationships/married-partner.svg',
    imageAlt: 'wedding ring on hand',
  },
  {
    label: 'Grandparent',
    value: 'grandparent',
    imageSrc: 'assets/profile/relationships/grandparent.svg',
    imageAlt: 'grandparent',
  },
  {
    label: 'Aunt/Uncle',
    value: 'aunt-uncle',
    imageSrc: 'assets/profile/relationships/aunt-uncle.svg',
    imageAlt: 'Woman hugging child',
  },
  {
    label: 'Step-Parent',
    value: 'step-parent',
    imageSrc: 'assets/profile/relationships/step-parent.svg',
    imageAlt: 'two people hugging',
  },
  {
    label: 'Adoptive Parent',
    value: 'adoptive-parent',
    imageSrc: 'assets/profile/relationships/adoptive-parent.svg',
    imageAlt: 'Family standing together',
  },
  {
    label: 'Foster Parent',
    value: 'foster-parent',
    imageSrc: 'assets/profile/relationships/foster-parent.svg',
    imageAlt: 'Family standing together',
  },
  {
    label: 'Godparent',
    value: 'godparent',
    imageSrc: 'assets/profile/relationships/god-parent.svg',
    imageAlt: 'Person with arm over the shoulder of another',
  },
];
