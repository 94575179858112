import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonHeader,
  IonIcon,
  IonContent,
  IonRouterLink,
  IonToolbar,
  IonButtons,
  IonPage,
} from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import {
  Box,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Browser } from '@capacitor/browser';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import useFetch, { CachePolicies } from 'use-http';
import Header from 'components/Header';
import Button from 'common/atoms/button/Button';
import LoadingSpinner from 'components/generic/LoadingSpinner';
import { sortTeamsByRelationship } from 'helpers/transformers';
import { useAuthContext } from '../../providers/auth-provider';
import './BackrTeamView.css';
import Footer from '../../components/Footer';
import TeamDetailsView from '../../components/TeamDetailsView/TeamDetailsView';
import NoDonorbox from './NoDonorbox';
import { CoachLubavProfileData } from '../wholeme/util/coachLubav_Profile';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.3, delay: 0.5 } },
};

const BackrTeamView = ({ history, location }) => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [currentTab, setCurrentTab] = useState('details');
  const [protege, setProtege] = useState({});
  const [requiresBgCheck, setRequiresBgCheck] = useState(false);
  const [protegesTeam, setProtegesTeam] = useState([]);
  const [campaignBar, setCampaignBar] = useState(false);
  const [campaignPayment, setCampaignPayment] = useState(false);
  const [showInvestmentModal, setShowInvestmentModal] = useState(false);

  const { isOneTeam } = location.state || false;

  const viewDetailsTab = () => {
    setCurrentTab('details');
  };

  const viewInvestTab = () => {
    setCurrentTab('invest');
  };

  const goToBgCheck = () => {
    history.push('/background-status-meter');
  };

  const goToGeneralInvest = () => {
    history.push('/tabs/backr/general-invest-landing');
  };

  const { get: fetchTeam, loading: isTeamLoading } = useFetch(
    `/users/teams/${id}`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
  );

  const closeInvestmentModal = () => {
    setShowInvestmentModal(false);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://donorbox.org/widget.js';
    script.async = true;
    script.paypalExpress = false;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fetchInitialprotegesTeam = async () => {
    if (user && user.role === 'backr') {
      const [team] = await fetchTeam();
      return { protege: team.related_profile, team: team.backrRelationships, bg_check_status: team.bg_check_status };
    }
    return [];
  };

  const lubavProfileData = CoachLubavProfileData(user?.id).publicProfileData;
  useEffect(() => {
    if (user) {
      fetchInitialprotegesTeam().then((r) => {
        if (r.team.length < 1 && user.role === 'backr') {
          history.push('/tabs/no-teams');
        }
        setProtege(r.protege);
        setRequiresBgCheck(r?.bg_check_status === 'incomplete');
        const team = r.team.map((backr) => {
          // eslint-disable-next-line no-param-reassign
          backr.related_profile = { ...backr?.related_backr };
          // eslint-disable-next-line no-param-reassign
          delete backr.related_backr;
          return backr;
        });
        // Adds lubav to each team view, new date ensures it is always last backr on team
        team.push({
          related_user_id: lubavProfileData.id,
          relationship: 'backing',
          related_profile: lubavProfileData,
          created_at: new Date().toISOString(),
        });
        setProtegesTeam(sortTeamsByRelationship(team));
      });
    }
  }, [user]);

  return (
    <IonPage>
      {isOneTeam ? (
        <Header />
      ) : (
        <IonHeader className="ion-no-border">
          <IonToolbar className="back-button-toolbar">
            <IonButtons slot="start">
              <IonRouterLink routerLink="/tabs/backr/teams">
                <Stack spacing={2} direction="row" alignItems="end">
                  <IonIcon
                    style={{ fontSize: '1.25rem' }}
                    slot="icon-only"
                    src="/assets/back-arrow.svg"
                  />
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {protege?.first_name
                      ? `${protege?.first_name}'s Team`
                      : null}
                  </Typography>
                </Stack>
              </IonRouterLink>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="baseline"
          mb={5}
          px={12}
        >
          <ButtonBase sx={{ position: 'relative' }} onClick={viewDetailsTab}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                px: 1,
                textAlign: 'center',
              }}
            >
              Details
            </Typography>
            {currentTab === 'details' ? (
              <motion.div
                initial={false}
                style={{
                  position: 'absolute',
                  background: '#FD5C36',
                  height: '3px',
                  left: 0,
                  right: 0,
                  bottom: '-6px',
                }}
              />
            ) : null}
          </ButtonBase>
          <ButtonBase sx={{ position: 'relative' }} onClick={viewInvestTab}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                px: 1,
                textAlign: 'center',
              }}
            >
              Invest
            </Typography>
            {currentTab === 'invest' ? (
              <motion.div
                initial={false}
                style={{
                  position: 'absolute',
                  background: '#FD5C36',
                  height: '3px',
                  left: 0,
                  right: 0,
                  bottom: '-6px',
                }}
              />
            ) : null}
          </ButtonBase>
        </Stack>
        {isTeamLoading ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {currentTab === 'details' ? (
              <Stack
                sx={{
                  width: 1,
                  minHeight: 'calc(100vh - 244px - var(--ion-safe-area-top))',
                  justifyContent: 'space-between',
                }}
              >
                <TeamDetailsView protege={protege} backrs={protegesTeam} />
                {requiresBgCheck && (
                  <Button
                    label="Check my status"
                    onClickCallBack={() => goToBgCheck()}
                    buttonStyles={{
                      background: '#03295F',
                      fontSize: 18,
                      width: '50%',
                      fontWeight: 500,
                      alignSelf: 'center',
                      p: 0,
                      mt: 4,
                      mr: 2,
                      mb: 2,
                    }}
                    labelStyles={{
                      pb: '8px',
                    }}
                  />
                )}
                <Box
                  sx={{
                    p: 2,
                    mx: 3,
                    bgcolor: '#063D8F',
                    color: '#fff',
                    borderRadius: 2,
                  }}
                  onClick={viewInvestTab}
                >
                  <div className="goals-create-card">
                    <img
                      className="card-leading-icon"
                      src="/assets/invest-coin.svg"
                      alt="giving out coin"
                    />
                    <div className="goals-create-card-message">
                      <Typography
                        component="h2"
                        sx={{
                          position: 'relative',
                          fontSize: '1rem !important',
                          fontWeight: '700 !important',
                        }}
                      >
                        Invest in your protege
                        <IonIcon
                          style={{
                            position: 'relative',
                            top: '5px',
                            fontSize: '1.25rem',
                            marginLeft: '4px',
                          }}
                          src={arrowForwardOutline}
                        />
                      </Typography>
                      <Typography
                        sx={{ fontSize: 13, lineHeight: 1.25 }}
                        mt="4px !important"
                      >
                        All cash goes directly to your protege to put toward
                        their aspirations.
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Stack>
            ) : null}
            <AnimatePresence>
              {currentTab === 'invest' && protege?.donorbox_id ? (
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate={campaignBar && campaignPayment && 'visible'}
                >
                  <iframe
                    title="donorbox android workaround"
                    src="https://donorbox.org"
                    style={{ border: 0 }}
                    width="0"
                    height="0"
                  />
                  <Stack
                    spacing={3}
                    sx={{
                      mx: 3,
                    }}
                  >
                    <Stack alignItems="center">
                      <iframe
                        title="donorbox bar"
                        height="93px"
                        name="donorbox"
                        scrolling="no"
                        seamless="seamless"
                        // eslint-disable-next-line max-len
                        src={`https://donorbox.org/embed/${protege?.donorbox_id}?only_donation_meter=true&donation_meter_color=%23ff5b2b`}
                        style={{
                          maxWidth: '500px',
                          minWidth: '250px',
                          maxHeight: 'none !important',
                          border: 0,
                          overflowY: 'hidden',
                          overflowX: 'hidden',
                        }}
                        width="100%"
                        onLoad={() => {
                          setCampaignBar(true);
                        }}
                      />
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: 14,
                        lineHeight: '21px',
                        color: '#2d2d2d',
                        'padding-left': '24px',
                        'padding-right': '24px',
                      }}
                    >
                      Financial investment is essential to the power of the
                      Backrs model. Every protege has set holistic goals, and
                      extra cash puts these goals more in reach and helps a
                      young person persist through challenges.
                      <Typography
                        onClick={() => setShowInvestmentModal(true)}
                        sx={{
                          display: 'inline-flex',
                          fontSize: 13,
                          lineHeight: 1.25,
                          ml: '2.5px',
                          mr: '2.5px',
                          color: '#063D8F',
                          fontWeight: '600',
                          'text-decoration': 'underline',
                        }}
                      >
                        Read More
                      </Typography>
                    </Typography>
                    <Stack alignItems="center">
                      <iframe
                        title="donorbox campaign"
                        // eslint-disable-next-line max-len
                        src={`https://donorbox.org/embed/${protege?.donorbox_id}?default_interval=m&hide_donation_meter=true&enable_auto_scroll=false`}
                        name="donorbox"
                        allowpaymentrequest="allowpaymentrequest"
                        seamless="seamless"
                        frameBorder="0"
                        scrolling="no"
                        height="900px"
                        width="100%"
                        style={{
                          maxWidth: '425px',
                          minWidth: '310px',
                          maxHeight: 'none! important',
                        }}
                        onLoad={() => {
                          setCampaignPayment(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Dialog
                    PaperProps={{
                      style: { borderRadius: 8 },
                    }}
                    open={showInvestmentModal}
                    onClose={closeInvestmentModal}
                  >
                    <DialogTitle>
                      <IconButton
                        aria-label="close investment information popup"
                        onClick={closeInvestmentModal}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                        }}
                      >
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ px: 2, pb: 2 }}>
                      <Stack spacing={2} alignItems="center" mt={2}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: '#2d2d2d',
                            fontWeight: '500',
                          }}
                        >
                          Why invest in your protege?
                        </Typography>
                      </Stack>
                      <Stack spacing={2} alignItems="center" mt={2} ml={0.5}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: '#2d2d2d',
                            mt: 1.5,
                            fontWeight: '700',
                          }}
                        >
                          Studies show that direct cash investment in a young
                          person can lead to outcomes like improved health and
                          higher test scores.
                        </Typography>
                      </Stack>
                      <Stack spacing={2} alignItems="center" mt={2} ml={0.5}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: '#2d2d2d',
                            mt: 1,
                          }}
                        >
                          Backrs aims to provide a $100 monthly investment to
                          qualifying protégés to put towards their aspirations.
                          Adding in processing fees and other platform expenses,
                          every protégé&apos;s team has a monthly goal of $116.
                        </Typography>
                      </Stack>
                      <Stack spacing={2} alignItems="center" mt={2} ml={0.5}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: '#2d2d2d',
                            mt: 1,
                            mb: 3,
                          }}
                        >
                          Learn more about protégé investments in the
                          {' '}
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-flex',
                              width: 'auto',
                              height: '12px',
                            }}
                            onClick={() => Browser.open({
                              url:
                                 'https://www.backrs.com/invest/protege-investment-faq',
                            })}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                color: '#063D8F',
                                fontWeight: '600',
                                'text-decoration': 'underline',
                              }}
                            >
                              Protégé Investment FAQs
                            </Typography>
                          </Box>
                          .
                        </Typography>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                </motion.div>
              ) : null}
              {currentTab === 'invest' && !protege?.donorbox_id ? (
                <NoDonorbox onClickCallback={goToGeneralInvest} />
              ) : null}
            </AnimatePresence>
          </motion.div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

BackrTeamView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      isOneTeam: PropTypes.bool,
    }),
  }),
};

BackrTeamView.defaultProps = {
  location: {
    state: {
      isOneTeam: false,
    },
  },
};

export default BackrTeamView;
