import React from 'react';
import {
  Typography,
  Box,
  ButtonBase,
  Stack,
} from '@mui/material';
import appStrings from '../common/app_strings';

const { feed: { errors } } = appStrings;

const FeedError = () => (
  <Box>
    <Box
      sx={{
        marginBottom: '100px',
        paddingLeft: '80px',
        paddingRight: '80px',
        width: '55%',
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '31px',
          textAlign: 'center',
        }}
      >
        {errors.feedUnavailable}
      </Typography>
    </Box>
    <Box
      sx={{
        marginBottom: '60px',
        width: '80%',
        paddingLeft: '9%',
      }}
    >
      <img
        src="/assets/bruised-heart.svg"
        alt="Feed load error"
      />
    </Box>
    <Stack
      style={{
        marginLeft: '84px',
        marginRight: '84px',
      }}
    >
      <ButtonBase
        href="mailto:support@backrs.com?subject=Backrs App - Contact Request"
      >
        <Box
          style={{
            '--background-hover': '#063D8F',
            '--background-activated': '#063D8F',
            '--background-focused': '#063D8F',
            width: '105%',
            borderRadius: '35px',
            background: '#03295F',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: '56px',
          }}
          mode="ios"
        >
          <Typography
            sx={{
              color: 'white',
              textAlign: 'center',
              fontSize: '15px',
              fontWeight: '600',
              paddingTop: '15px',
            }}
          >
            {errors.contactSupport}
          </Typography>
        </Box>
      </ButtonBase>
    </Stack>
  </Box>
);

export default FeedError;
