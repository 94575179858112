/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import WholeMeLoadingCircle from 'pages/wholeme/molecules/wholeMeLoadingCircle/WholeMeLoadingCircle';
import WholeMeProfileIdentity from 'pages/wholeme/molecules/wholeMeProfileIdentity/WholeMeProfileIdentity';
import { useHistory } from 'react-router';
import { Stack, Tab, Tabs } from '@mui/material';
import { transformTargetMeta } from '../../../../../common/atoms/dropDown/utils';
import AccountSettings from '../../../../profile/AccountSettings';
import WholeMeProfileProtected from './WholeMeProfileProtected';
import LockedProfile from '../../../molecules/lockedProfile/LockedProfile';
import { firstValueOf } from '../../../util/profile_util';
import BlockedProfileIdentity from '../../../molecules/blockedProfile/BlockedProfileIdentity';

const profileBlockedReasons = ['BLOCKED', 'FLAGGED'];

const WholeMeProfile = ({
  userId,
  viewingUser,
  wholeMeClient,
  postTargets,
  legacyProfileData,
  isViewingAdmin,
  permissions,
  setIsLoading,
  isLoading,
  refreshProfile,
}) => {
  const [profile, setProfile] = useState(null);
  const [mode, setMode] = useState('profile');
  const [fetching, setFetching] = useState(false);
  const [handledPostTargetMeta, setHandledPostTargetMeta] = useState(null);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const history = useHistory();
  const editable = permissions?.['edit-profile']?.allowed;

  useEffect(() => {
    if (postTargets) {
      const handledTargets = transformTargetMeta(postTargets);
      setHandledPostTargetMeta(handledTargets);
    }
  }, [postTargets]);

  useEffect(() => {
    if (!permissions) {
      return;
    }

    setIsLoading(true);
    if (permissions?.['view-profile']?.allowed) {
      wholeMeClient.fetchProfile(userId).then((fetched) => {
        setProfile(fetched);
      }).finally(() => {
        setIsLoading(false);
        setProfileLoaded(true);
      });
    } else {
      setProfileLoaded(true);
    }
  }, [permissions]);

  const renderLoadingScreen = isLoading || !profileLoaded || !permissions;

  if (renderLoadingScreen) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <WholeMeLoadingCircle />
      </div>
    );
  }

  const onEdit = editable
    ? (categoryId, stepId) => {
      if (!stepId) {
        history.push(`/wholeme/wizard/${categoryId}`);
      } else {
        history.push(`/wholeme/wizard/${categoryId}/${stepId}`);
      }
    }
    : undefined;

  const onPost = (!editable && !isViewingAdmin)
    ? async (label, infoUrl = '') => {
      if (fetching) return;
      setFetching(true);
      const target = await wholeMeClient.fetchProfilePostTarget(userId);
      const targetTeamId = target?.targetTeamId;
      if (!targetTeamId) {
        console.error("Not allowed to post, couldn't resolve target teamId");
        setFetching(false);
        return;
      }

      const { first_name } = profile;
      const prompt = `Start typing to comment or ask ${firstValueOf(
        first_name,
      )} about their profile!`;

      const foundTeam = (handledPostTargetMeta.teams || []).find(
        (team) => team.id === targetTeamId,
      );

      if (!foundTeam) {
        console.error("Not allowed to post, couldn't resolve target teamId", targetTeamId);
        setFetching(false);
        return;
      }

      history.push('/post', {
        subject: label,
        postPrompt: prompt,
        targetTeamId: foundTeam.id,
        infoUrl,
        postSubmitToast: 'Your post has been sent!',
      });
    }
    : undefined;

  const handleTabChange = (_e, value) => {
    setMode(value);
  };

  const nonRestrictedProfileData = {
    first_name: legacyProfileData?.first_name,
    last_name: legacyProfileData?.last_name,
    role: legacyProfileData?.role,
    profile_image: legacyProfileData?.profile_image,
  };

  const composedProfile = {
    ...profile || {},
    ...nonRestrictedProfileData,
    relationshipStatus: legacyProfileData?.relationshipStatus,
    donorboxId: legacyProfileData?.donorbox_id,
  };

  const restrictedProfileReason = permissions?.['view-profile']?.reason;
  const profileMessage = permissions?.['view-profile']?.message;

  const getLockedProfileVariant = () => {
    let lockedProfileVariant = 'unavailable';

    if (profileMessage === 'UNAVAILABLE') {
      return lockedProfileVariant;
    }
    lockedProfileVariant = profileBlockedReasons.includes(restrictedProfileReason) ? 'blocked' : 'unlock';
    return lockedProfileVariant;
  };

  const RenderIdentity = restrictedProfileReason === 'BLOCKED' ? (
    <BlockedProfileIdentity />
  ) : (
    <WholeMeProfileIdentity
      profile={composedProfile}
      onEdit={onEdit}
      onPost={onPost}
      isViewingAdmin={isViewingAdmin}
      permissions={permissions}
      refreshProfile={refreshProfile}
    />
  );

  const RenderProfile = permissions?.['view-profile']?.allowed ? (
    <WholeMeProfileProtected
      profile={profile}
      wholeMeClient={wholeMeClient}
      editable={editable}
      onEdit={onEdit}
      onPost={onPost}
      isViewingAdmin={isViewingAdmin}
      legacyProfileData={legacyProfileData}
    />
  ) : (
    <LockedProfile variant={getLockedProfileVariant()} />
  );

  return (
    <Stack sx={{ padding: '20px' }} spacing={2}>
      {
        editable
        && (
          <Tabs value={mode} onChange={handleTabChange}>
            <Tab value="profile" label="Profile" style={{ marginRight: '84px' }} />
            <Tab value="account" label="Account" />
          </Tabs>
        )
      }
      {mode === 'profile' ? (
        <Stack spacing={1.5}>
          {RenderIdentity}
          {RenderProfile}
        </Stack>
      ) : <AccountSettings user={viewingUser} />}
    </Stack>
  );
};

export default WholeMeProfile;
