import axiosInstance from './axios';
import { getStoredToken } from './tokenHelpers';

const preflight = (setAlert) => {
  if (setAlert) {
    setAlert(null);
  }
};

const genConfig = (token, multipart = false) => {
  let contentType = 'application/json';
  if (multipart) {
    contentType = 'multipart/form-data';
  }

  const config = {
    headers: {
      'Content-Type': contentType,
    },
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

export const putData = async (endpoint, setAlert, data, token = null, multipart = false) => {
  preflight(setAlert);
  const response = await axiosInstance.put(endpoint, data, genConfig(token, multipart));
  return response.data;
};

export const patchData = async (endpoint, setAlert, data, token = null, multipart = false) => {
  preflight(setAlert);
  const tokenToUse = token || getStoredToken();
  try {
    const response = await axiosInstance.patch(endpoint, data, genConfig(tokenToUse, multipart));
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const postData = async (endpoint, setAlert, data, token = null, multipart = false) => {
  preflight(setAlert);
  const response = await axiosInstance.post(endpoint, data, genConfig(token, multipart));
  return response.data;
};

export const deleteObject = async (endpoint, setAlert, token = null) => {
  preflight(setAlert);
  const response = await axiosInstance.delete(endpoint, genConfig(token));
  return response.data;
};

export const getDetail = async (endpoint, setAlert, token = null) => {
  preflight(setAlert);
  const tokenToUse = token || getStoredToken();
  try {
    const response = await axiosInstance.get(endpoint, genConfig(tokenToUse));
    return response.data;
  } catch (error) {
    console.error('getDetail helper error -', error);
    return [];
  }
};

export const getList = async (
  endpoint,
  setAlert,
  token = null,
  pageNumber = 1,
  itemsPerPage = 10,
) => {
  preflight(setAlert);

  // Pagination URL
  const startRow = (pageNumber - 1) * itemsPerPage;
  const endRow = pageNumber * itemsPerPage - 1;
  const urlAdder = `?range=[${startRow},${endRow}]`;

  // load the data
  const response = await axiosInstance.get(`${endpoint}${urlAdder}`, genConfig(token));
  const outResponse = {};
  outResponse.data = response.data;

  // Update record counts
  if (response?.headers && 'x-total-count' in response.headers) {
    outResponse.total_pages = Math.ceil(response.headers['x-total-count'] / itemsPerPage);
  }

  return outResponse;
};
