import React from 'react';
import InputField from 'common/atoms/inputField/InputField';

const SearchInputField = ({
  inputPlaceholder,
  iconOverride,
  handleInputChange,
  handleKeyUp,
  handleOnFocus,
  handleExitClick,
  inputFieldValue,
  inputTheme,
  children,
  inputRef,
}) => (
  <div style={{ position: 'relative' }}>
    <div style={{ marginBottom: '15px' }}>
      <InputField
        placeholder={inputPlaceholder || 'Search'}
        icon={iconOverride || 'search'}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
        onFocus={handleOnFocus}
        fieldValue={inputFieldValue}
        debounceMilliSeconds={750}
        onExitClick={handleExitClick}
        theme={inputTheme}
        ref={inputRef}
      />
    </div>
    {children}
  </div>
);

export default SearchInputField;
