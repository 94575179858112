import React, { useEffect, useState, useRef } from 'react';
import PillRenderer from 'common/molecules/pillRenderer/PillRenderer';
import SearchInputField from './SearchInputField';
import SearchInputResultsList from './SearchInputResultList';

const ResourceFetchingSearchInputField = ({
  isSingleValue,
  selectedOptions,
  setSelectedOptions,
  searchOptionsFetcher,
  resourceType,
  resultAttributeId,
  disableCustomInputs,
  inputTheme,
  iconOverride,
  hidePills,
  minQueryLength = 3,
  freeFormDisabled,
  queryOnInputFocus,
}) => {
  const [searchOptions, setSearchOptions] = useState([]);
  const [enterKeyState, setEnterKeyState] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const inputRef = useRef(null);

  const createNewSelectedOptions = (alreadySelectedOptions, newOption) => (isSingleValue ? [newOption] : [...alreadySelectedOptions, newOption]);

  useEffect(() => {
    if (freeFormDisabled) {
      const foundOption = searchOptions.find(
        (n) => n[resultAttributeId].toLowerCase() === enterKeyState.toLowerCase().trim(),
      );
      const foundOptionValue = foundOption?.[resultAttributeId];
      const foundOptionIsAlreadySelected = selectedOptions.includes(foundOptionValue);

      if (!foundOption) {
        return;
      }

      if (!foundOptionIsAlreadySelected) {
        setSelectedOptions(
          createNewSelectedOptions(selectedOptions, foundOption[resultAttributeId]),
        );
        inputRef.current.value = foundOptionValue;
      }

      setSearchOptions([]);
      return;
    }

    if (!enterKeyState) {
      return;
    }

    setSearchOptions([]);
    if (!selectedOptions.includes(enterKeyState)) {
      setSelectedOptions(createNewSelectedOptions(selectedOptions, enterKeyState));
    }
  }, [enterKeyState, freeFormDisabled]);

  useEffect(() => {
    if (isSingleValue && selectedOptions?.length > 0) {
      const [singleOption] = selectedOptions;
      inputRef.current.value = singleOption;
      setInitialValue(singleOption);
    }
  }, []);

  const handleInputOnChange = (query = '') => {
    if (isSingleValue && query.length === 0 && (initialValue || selectedOptions?.length)) {
      setSelectedOptions([]);
    }

    if (query?.length >= minQueryLength) {
      searchOptionsFetcher(resourceType, query).then((results) => {
        if (inputRef?.current?.value.toLowerCase() === query.toLowerCase()) {
          setSearchOptions(results?.results || []);
        }
      });
    } else setSearchOptions([]);
  };

  const handleOptionSelect = (option) => {
    setSearchOptions([]);
    const resource = option[resultAttributeId];
    inputRef.current.value = isSingleValue ? resource : '';
    if (!selectedOptions.includes(resource)) {
      setSelectedOptions(createNewSelectedOptions(selectedOptions, resource));
    }
  };

  const handleInputKeyUp = (key, query) => {
    if (disableCustomInputs) return;
    if (key === 'Enter' && query) setEnterKeyState(query);
  };

  const handleUnselectPill = (option) => {
    if (isSingleValue) {
      setSelectedOptions(null);
    } else {
      setSelectedOptions(selectedOptions.filter((n) => n !== option));
    }
  };

  const showList = searchOptions?.length > 0;
  const showPills = !hidePills && selectedOptions?.length > 0;

  const handleOnFocus = () => {
    if (queryOnInputFocus) {
      if (!searchOptions?.length && !inputRef?.current?.value) {
        handleInputOnChange();
      }
    }
  };

  return (
    <SearchInputField
      iconOverride={iconOverride}
      handleInputChange={(e) => handleInputOnChange(e.target.value)}
      handleKeyUp={(e) => handleInputKeyUp(e.key, e.target.value)}
      inputTheme={inputTheme}
      renderSearchOptions={!!searchOptions?.length}
      searchOptions={searchOptions}
      handleSearchOptionSelect={handleOptionSelect}
      resultAttributeId={resultAttributeId}
      selectedOptions={selectedOptions}
      handlePillClick={(value) => handleUnselectPill(value)}
      inputRef={inputRef}
      handleOnFocus={handleOnFocus}
    >
      <>
        {showList && (
          <SearchInputResultsList
            searchOptions={searchOptions}
            handleSelect={handleOptionSelect}
            resultAttributeId={resultAttributeId}
          />
        )}

        {showPills && (
          <div style={{ marginLeft: '4px' }}>
            <PillRenderer
              items={selectedOptions || []}
              activeItems={selectedOptions || []}
              handlePillClick={(value) => handleUnselectPill(value)}
            />
          </div>
        )}
      </>
    </SearchInputField>
  );
};

export default ResourceFetchingSearchInputField;
