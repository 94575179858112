import React from 'react';

export const PlusIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/orange-round-plus.svg" alt="orange round plus" />;
export const EditIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/orange-edit.svg" alt="orange edit" />;
export const EducationIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/education-apple.svg" alt="education apple" />;
export const PurposeVideoIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/intro-video.svg" alt="intro video clapperboard" />;
export const FinancesIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/finances-money.svg" alt="finances money" />;
export const HealthIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/health-meditation.svg" alt="health meditation" />;
export const InterestsIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/interests-telescope.svg" alt="interests telescope" />;
export const RelationshipsIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/relationships-heart.svg" alt="relationships heart" />;
export const WDYKIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/head-question.svg" alt="blue thinking head" />;
export const QuotationIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/quotation-mark.svg" alt="quotation mark" />;
export const GoalIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/white-bullseye.svg" alt="white bullseye" />;
export const ExperiencesIcon = (styles) => <img style={styles} src="/assets/profile/education/career-switch.svg" alt="wooden crossroads sign" />;
export const RoundPlusIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/round-plus.svg" alt="rounded plus sign" />;
export const PlayIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/play-icon.svg" alt="play icon" />;
export const TrophyIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/trophy-dark-bottom.svg" alt="goal trophy dark bottom" />;
export const BlueInfoIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/blue-info-icon.svg" alt="round blue info icon" />;
export const RoundEditIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/orange-edit-greybg.svg" alt="orange edit white bg" />;
export const CloseIcon = (styles) => <img style={styles} src="assets/icon/exitX.svg" alt="x icon" />;
export const PauseIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/pause-icon.svg" alt="pause icon" />;
export const BackrIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/backr-profile-icon.svg" alt="backr icon" />;
export const ProtegeIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/protege-profile-icon.svg" alt="protege icon" />;
export const LightbulbIcon = (styles) => <img style={styles} src="assets/icon/bulb-outline.svg" alt="lightbulb icon" />;
export const BackrBlueIcon = (styles) => <img style={styles} src="assets/backr-logo-white-blue-bckground.png" alt="backr icon blue background" />;
export const AdminRoleIcon = (styles) => <img style={styles} src="assets/icon/admin-role-indicator.svg" alt="orange A" />;
export const LinkedInIcon = (styles) => <img style={styles} src="assets/profile/wholeMe/linkedin-icon.svg" alt="linkedin icon" />;
