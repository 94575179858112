const isUserUnderage = (birthday) => {
  if (!birthday) {
    return new Error('Birthday is required');
  }
  const today = new Date();
  const birthdayDate = new Date(birthday);

  // Calculate the user's age
  let age = today.getFullYear() - birthdayDate.getFullYear();
  const monthDiff = today.getMonth() - birthdayDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdayDate.getDate())) {
    age--;
  }

  if (age < 18) {
    return true;
  }
  return false;
};

export default isUserUnderage;
