import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import TopCardSection from './TopCardSection';
import MiddleCardSection from './MiddleCardSection';
import BottomCardSection from './BottomCardSection';

const styles = {
  'meet-the-proteges': {
    container: {
      position: 'relative',
      bgcolor: '#fff',
      mx: 3,
      p: 3,
      borderRadius: 2,
      minHeight: 555,
      maxHeight: 555,
      width: 1,
      maxWidth: 750,
      overflow: 'hidden',
    },
    sectionSpacing: {
      middle: 2,
      bottom: 2,
    },
    intermission: {
      // bgcolor: '#03295F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    intermission_alt: {
      // bgcolor: '#063D8F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  'backing-in-action': {
    container: {
      position: 'relative',
      mx: 3,
      pb: 3,
      borderRadius: 2,
      minHeight: 600,
      maxHeight: 750,
      width: 1,
      maxWidth: 750,
      overflow: 'hidden',
    },
    sectionSpacing: {
      middle: 4,
      bottom: 3,
    },
    intermission: {
      // bgcolor: '#03295F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    intermission_alt: {
      // bgcolor: '#063D8F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  'meet-our-backrs': {
    container: {
      position: 'relative',
      // bgcolor: '#fff',
      mx: 3,
      pb: 3,
      borderRadius: 2,
      minHeight: 567,
      maxHeight: 567,
      width: 1,
      maxWidth: 750,
      overflow: 'hidden',
    },
    sectionSpacing: {
      middle: 2,
      bottom: 3,
    },
    intermission: {
      // bgcolor: '#03295F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    intermission_alt: {
      // bgcolor: '#063D8F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  'what-is-backing': {
    container: {
      position: 'relative',
      mx: 3,
      borderRadius: 2,
      minHeight: 600,
      maxHeight: 625,
      width: 1,
      maxWidth: 620,
      overflow: 'scroll',
    },
    sectionSpacing: {
      middle: 4,
      bottom: 3,
    },
    intermission: {
      // bgcolor: '#03295F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    intermission_alt: {
      // bgcolor: '#063D8F',
      color: '#fff',
      textAlign: 'center',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
};

const CarouselCard = ({ workflow, cardMeta }) => {
  const isAltIntermission = parseInt(cardMeta?.id?.split('-')[2], 10) % 2 !== 0;
  return (
    <Box
      sx={
        !cardMeta?.is_intermission
          ? styles[workflow].container
          : {
            ...styles[workflow].container,
            ...(isAltIntermission
              ? { ...styles[workflow].intermission_alt }
              : { ...styles[workflow].intermission }),
            ...(cardMeta?.background_image && {
              backgroundImage: `url(${cardMeta?.background_image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }),
          }
      }
    >
      <TopCardSection workflow={workflow} meta={cardMeta?.top} />
      <MiddleCardSection
        workflow={workflow}
        meta={cardMeta?.middle}
        spacing={styles[workflow].sectionSpacing.middle}
      />
      <BottomCardSection
        workflow={workflow}
        meta={cardMeta?.bottom}
        spacing={styles[workflow].sectionSpacing.bottom}
      />
    </Box>
  );
};

CarouselCard.propTypes = {
  workflow: PropTypes.string.isRequired,
  cardMeta: PropTypes.shape({
    id: PropTypes.string,
    top: PropTypes.oneOfType([PropTypes.object]),
    middle: PropTypes.oneOfType([PropTypes.object]),
    bottom: PropTypes.oneOfType([PropTypes.object]),
    background_image: PropTypes.string,
    is_intermission: PropTypes.bool,
  }),
};

CarouselCard.defaultProps = {
  cardMeta: null,
};

export default CarouselCard;
