import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonIcon,
} from '@ionic/react';
import { ButtonBase, Stack, Typography } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import LoadingSpinner from '../components/generic/LoadingSpinner';
import ReportForm from '../components/ReportForm';
import Footer from '../components/Footer';
import { useAuthContext } from '../providers/auth-provider';
import { displayName } from '../helpers/textManipulation';
import appStrings from '../common/app_strings';

const ReportPage = () => {
  const { id, type } = useParams();
  const { user, logout } = useAuthContext();
  const [reportedUser, setReportedUser] = useState(null);
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardIsOpen(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardIsOpen(false);
    });
  }
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  if (type !== 'posts' && type !== 'comments' && type !== 'users' && type !== 'conversations') {
    history.goBack();
  }

  const {
    report: { backButtonText },
  } = appStrings;

  const {
    get: getUserInfo,
    loading: loadingUserInfo,
    response: resUserInfo,
    abort: abortUserInfo,
  } = useFetch('/users');
  const { post, put: doPut } = useFetch();

  const fetchReportedUser = async () => {
    const data = await getUserInfo(`/${id}/public-profile`);
    if (resUserInfo.ok) setReportedUser(data);
  };

  const handleLeaveReport = () => {
    if (type === 'comments') {
      history.replace(`/posts/${location?.state?.id}/comments`);
      return;
    }
    history.goBack();
  };

  const onSubmit = async (data) => {
    if (type === 'users') {
      await post(`/users/${id}/report`, {
        category: data?.category,
        reason: data?.reason,
      });
      if (data?.block) {
        await post(`/users/current-user/blocked-users/${id}`);
        // For blocking admins, we want to disable the user, then log them out
        if (state?.reportedUser?.role === 'admin') {
          const disabledUser = await doPut(`/users/${user?.id}`, { email: user?.email, is_disabled: true });
          if (disabledUser) {
            console.log('Logging out');
            setTimeout(async () => {
              await logout();
            }, 1500);
          }
          return;
        }
      }
      history.push('/tabs/feed');
      return;
    }
    await post(`/${type}/${id}/report`, {
      category: data?.category,
      reason: data?.reason,
    });
    if (type === 'posts' || type === 'conversations') {
      history.push('/tabs/feed');
    } else if (type === 'comments') {
      history.replace(`/posts/${location?.state?.id}/comments`);
    }
  };

  useEffect(() => {
    if (type === 'users') {
      fetchReportedUser();
    }
    return () => {
      abortUserInfo();
      if (isNative) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  return (
    <IonPage>
      {loadingUserInfo ? (
        <LoadingSpinner />
      ) : (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="back-button-toolbar">
              <IonButtons slot="start">
                <ButtonBase sx={{ px: 0.5 }} onClick={handleLeaveReport}>
                  <Stack spacing={2} direction="row" alignItems="end">
                    <IonIcon
                      style={{ fontSize: '1.25rem' }}
                      slot="icon-only"
                      src="/assets/back-arrow.svg"
                    />
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {type !== 'users'
                        ? backButtonText[type]
                        : state.reportedUser.role === 'admin'
                          ? 'Coach L.'
                          : displayName(
                            reportedUser?.first_name,
                            reportedUser?.last_name,
                            true,
                          )}
                    </Typography>
                  </Stack>
                </ButtonBase>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <ReportForm onSubmit={onSubmit} type={type} role={state?.reportedUser?.role} />
          </IonContent>
        </>
      )}
      {keyboardIsOpen === true ? null : <Footer />}
    </IonPage>
  );
};

export default ReportPage;
