import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const InfoCardAtom = ({ body, imageSrc, altImgText }) => (
  <Box sx={{
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '8px',
  }}
  >
    <img
      style={{ alignSelf: 'center', paddingTop: '0.5rem' }}
      width="auto"
      src={imageSrc}
      alt={altImgText}
    />
    <Typography sx={{
      textAlign: 'left',
      width: '100%',
      paddingTop: '1rem',
      paddingLeft: '0.4rem',
      color: '#2D2D2D',
      fontSize: '1rem',
    }}
    >
      {body}

    </Typography>
  </Box>
);

InfoCardAtom.propTypes = {

  body: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altImgText: PropTypes.string.isRequired,

};

export default InfoCardAtom;
