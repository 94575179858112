import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import {
  multiSelectDataFetcherAdapter,
  multiSelectDataSaverAdapter,
} from '../../util/profile_util';

const childrenStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const SimpleSingleSelectPage = ({
  fields,
  paginationMeta,
  nextOnClick,
  prevOnClick,
  title,
  subtitle,
  categoryId,
  questionId,
  dataFetcher,
  dataSaver,
  children,
  backgroundOverlay,
  allowForDisabledButtonState,
  exitIconOnClickOverride,
}) => (
  <SimpleMultiSelectPage
    fields={fields}
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title={title}
    subtitle={subtitle}
    categoryId={categoryId}
    questionId={questionId}
    selectionType="single"
    dataFetcher={multiSelectDataFetcherAdapter(dataFetcher)}
    dataSaver={multiSelectDataSaverAdapter(dataSaver)}
    backgroundOverlay={backgroundOverlay}
    allowForDisabledButtonState={allowForDisabledButtonState}
    exitIconOnClickOverride={exitIconOnClickOverride}
  >
    {children && <div style={childrenStyles}>{children}</div>}
  </SimpleMultiSelectPage>
);

export default SimpleSingleSelectPage;
