import './BackgroundCheck.css';
import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonButton,
  IonModal,
  IonIcon,
  IonPage,
} from '@ionic/react';
import { Box, ButtonBase, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { Browser } from '@capacitor/browser';
import { closeOutline } from 'ionicons/icons';
import useFetch from 'use-http';
import CollapsibleCard from '../../components/CollapsibleCard';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const BackrBackgroundCheck = () => {
  const { put, get } = useFetch();

  const [loading, setLoading] = useState(false);
  const [started, setStarted] = useState();
  const [backgroundCheck, setBackgroundCheck] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getBackgroundCheck = async () => {
    try {
      const b = await get('/users/current-user/background-check');
      setBackgroundCheck(b !== 'Not Found' ? b : null);
      setStarted(b !== 'Not Found' ? true : null);
      setLoading(false);
    } catch (e) {
      if (e.response.status === 404) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBackgroundCheck();
  }, []);

  const createBackgroundCheck = async () => {
    try {
      await put('/users/current-user/background-check', {});
      toast.success(
        "We've started your background check via Checkr. You should receive an email invite from Checkr soon.",
        { duration: 3000 },
      );
      getBackgroundCheck();
    } catch (e) {
      toast.error(
        "We've started your background check via Checkr. You should receive an email invite from Checkr soon.",
        { duration: 3000 },
      );
    }
  };

  const steps = [
    {
      key: 0,
      title: 'Step 1: Get Started',
      content:
        'Click the button above to start a back-ground check. Check your email for a link from Checkr.',
      icon: '/assets/icon/mail.svg',
    },
    {
      key: 1,
      title: 'Step 2: Input your Info',
      content:
        'Securely input required information for the background check on Checkr. No registration on Checkr required.',
      icon: '/assets/icon/paper-pen.svg',
    },
    {
      key: 2,
      title: 'Step 3: Processing',
      content:
        "Sit back while Checkr works it's magic. Checkr often takes 1-3 days to run.",
      icon: '/assets/icon/processing.svg',
    },
    {
      key: 3,
      title: 'Step 4: Verification',
      content:
        "You'll recieve a push notification from Backrs once your backrground check clears.",
      icon: '/assets/icon/verification.svg',
    },
  ];

  return (
    <IonPage>
      <Header />
      <IonModal
        isOpen={showModal}
        showBackdrop
        swipeToClose
        cssClass="img-modal"
        onDidDismiss={() => setShowModal(false)}
      >
        <p className="checkr-modal">
          We take the security of the community seriously. We conduct background
          checks through a secure background check partner,
          <Typography
            className="checkr-text"
            onClick={() => Browser.open({ url: 'https://checkr.com' })}
          >
            Checkr
          </Typography>
          .
        </p>
        <p className="checkr-modal">
          Background checks quickly and confidentially validate our user&apos;s
          identity through a SSN screen and Backr&apos;s screens for sex
          offender status.
        </p>
        <p className="checkr-modal">
          At Backrs we never collect and store sensitive information like your
          social security number. Instead we conduct background checks through a
          secure, trusted partner. Here&apos;s a link to Checkr&apos;s
          <Typography
            className="checkr-privacy"
            onClick={() => Browser.open({ url: 'https://checkr.com/privacy-policy' })}
          >
            privacy policy
          </Typography>
          for those that want to know more about their service.
        </p>
        <IonButton
          fill="clear"
          size="small"
          onClick={() => setShowModal(false)}
        >
          <IonIcon slot="icon-only" icon={closeOutline} />
        </IonButton>
      </IonModal>
      <IonContent fullscreen>
        <Box mx={3} mt={3} mb={5}>
          {!started ? (
            <Box sx={{ p: 2, bgcolor: '#e8e8dc', borderRadius: 2 }}>
              <div className="goals-create-card">
                <img
                  className="card-leading-icon"
                  src="/assets/background-check.svg"
                  alt="happy chat icons"
                />
                <div className="goals-create-card-message">
                  <Typography
                    component="h2"
                    sx={{
                      position: 'relative',
                      color: '#03295F',
                      fontSize: '1rem !important',
                      fontWeight: '700 !important',
                    }}
                  >
                    Background Check
                  </Typography>
                  <Typography
                    sx={{ fontSize: 13, lineHeight: 1.25 }}
                    color="#2d2d2d"
                    mt="4px !important"
                  >
                    Because backing involves connecting with young people, all
                    community members over the age of 18 are required to pass a
                    background check.
                  </Typography>
                </div>
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                p: 2,
                color: '#fff',
                bgcolor: '#063D8F',
                borderRadius: 2,
              }}
            >
              <div className="goals-create-card">
                <img
                  className="card-leading-icon"
                  src="/assets/takeoff-blue.svg"
                  alt="happy chat icons"
                />
                <div className="goals-create-card-message">
                  <Typography
                    component="h2"
                    sx={{
                      position: 'relative',
                      fontSize: '1rem !important',
                      fontWeight: '700 !important',
                    }}
                  >
                    Thanks for getting started!
                  </Typography>
                  <Typography
                    sx={{ fontSize: 13, lineHeight: 1.25 }}
                    mt="4px !important"
                  >
                    Background checks can take up to 1-3 days, so once
                    you&apos;re in review, use this time to explore or update
                    your profile!
                  </Typography>
                </div>
              </div>
            </Box>
          )}
          <CollapsibleCard title="Background Check" duration="">
            <div className="content">
              <Typography
                sx={{
                  color: '#2d2d2d',
                  fontSize: '14px !important',
                  maxWidth: '37ch',
                }}
              >
                We partner with Checkr to verify our users quickly and
                confidentially. Once you tap to get verified, check your email
                for an invitation from Checkr and follow the process.
                {' '}
                <ButtonBase
                  sx={{ verticalAlign: 'baseline', display: 'contents' }}
                  onClick={() => setShowModal(true)}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: '#2d2d2d',
                      fontSize: '14px !important',
                      textDecoration: 'underline',
                    }}
                  >
                    More info on Checkr backgrounds.
                  </Typography>
                </ButtonBase>
              </Typography>
              <div
                className="d-flex justify-content-around"
                style={{ marginTop: '10px' }}
              >
                <IonButton
                  shape="round"
                  onClick={createBackgroundCheck}
                  disabled={loading || !!backgroundCheck}
                  style={{ fontWeight: 700 }}
                >
                  {(loading && 'Loading...')
                    || (backgroundCheck && 'Invitation Sent')
                    || 'Start Backrground Check'}
                </IonButton>
              </div>
            </div>
          </CollapsibleCard>
          <Box>
            {steps.map((s, i) => (
              <div key={s.key} className="step-row d-flex">
                <div>
                  <div className="icon-circle d-flex align-items-center justify-content-around">
                    <img src={s.icon} alt="" className={`step-icon ${s.key === 1 ? 'step-icon-shift' : ''}`} />
                  </div>
                  {i < steps.length - 1 && <div className="step-line" />}
                </div>
                <div>
                  <h3 className="step-title">{s.title}</h3>
                  <p className="step-description">{s.content}</p>
                </div>
              </div>
            ))}
          </Box>
        </Box>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default BackrBackgroundCheck;
