export const pageShellStyles = {
  backgroundColor: '#063D8F',
  backgroundImage: 'url(/assets/profile-completed-background-overlay.svg)',
};

export const pageContainerStyles = {
  height: 'calc(100vh - 40px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  padding: '20px',
};

export const navContainerStyles = {
  height: 'fit-content',
  width: '100%',
  marginTop: '20px',
};

export const titleStyles = {
  styles: {
    color: '#fff',
    maxWidth: '35ch',
  },
};

export const imageStyles = {
  width: '55%',
};

export const buttonOverrideStyles = {
  backgroundColor: '#F7AE41',
  fontWeight: 700,
  ':hover': {
    backgroundColor: '#F7AE41CC',
  },
};
