export const greekData = [
  'Acacia',
  'Adelphikos',
  'Alpha Chi Alpha',
  'Alpha Chi Rho',
  'Alpha Delta Gamma',
  'Alpha Delta Phi',
  'Alpha Epsilon Pi',
  'Alpha Gamma Omega',
  'Alpha Kappa Lambda',
  'Alpha Lambda Mu',
  'Alpha Phi Alpha',
  'Alpha Phi Delta',
  'Alpha Psi Rho',
  'Alpha Sigma Phi',
  'Alpha Tau Omega',
  'Beta Chi Theta',
  'Beta Epsilon Gamma Gamma Alpha Rho Sigma',
  'Beta Sigma Psi',
  'Beta Theta Pi',
  'Beta Upsilon Chi',
  'Bones Gate',
  'Chi Gamma Epsilon',
  'Chi Heorot',
  'Chi Phi',
  'Chi Psi',
  'Delphic Fraternity',
  'Delta Chi',
  'Delta Epsilon Psi',
  'Delta Kappa',
  'Delta Kappa Epsilon',
  'Delta Lambda Phi',
  'Delta Phi',
  'Delta Psi (St. Anthony Hall)',
  'Delta Rho Upsilon',
  'Delta Sigma Phi',
  'Delta Tau Delta',
  'Delta Upsilon',
  'FarmHouse',
  'Gamma Zeta Alpha',
  'Iota Nu Delta',
  'Iota Phi Theta',
  'Kappa Alpha Order',
  'Kappa Alpha Psi',
  'Kappa Alpha Society',
  'Kappa Delta Phi',
  'Kappa Delta Rho',
  'Kappa Pi Kappa',
  'Kappa Sigma',
  'Lambda Alpha Upsilon',
  'Lambda Chi Alpha',
  'Lambda Phi Epsilon',
  'Lambda Sigma Upsilon',
  'Lambda Theta Phi',
  'Lambda Upsilon Lambda',
  'Nu Alpha Kappa',
  'Nu Sigma Beta',
  'Omega Delta Phi',
  'Omega Phi Gamma',
  'Omega Psi Phi',
  'Pan Sophic',
  'Phi Beta Epsilon',
  'Phi Beta Sigma',
  'Phi Delta Alpha',
  'Phi Delta Gamma',
  'Phi Delta Psi',
  'Phi Delta Theta',
  'Phi Epsilon Chi',
  'Phi Eta Kappa',
  'Phi Eta Mu',
  'Phi Gamma Delta',
  'Phi Iota Alpha',
  'Phi Kappa Pi',
  'Phi Kappa Psi',
  'Phi Kappa Sigma',
  'Phi Kappa Tau',
  'Phi Kappa Theta',
  'Phi Lambda Chi',
  'Phi Mu Alpha Sinfonia',
  'Phi Mu Delta',
  'Phi Rho Eta',
  'Phi Sigma Alpha',
  'Phi Sigma Kappa',
  'Phi Sigma Nu',
  'Phi Sigma Phi',
  'Pi Alpha Phi',
  'Pi Delta Psi',
  'Pi Kappa Alpha',
  'Pi Kappa Phi',
  'Pi Lambda Phi',
  'Psi Sigma Phi',
  'Psi Upsilon',
  'Seal and Serpent',
  'Sigma Alpha Epsilon',
  'Sigma Alpha Mu',
  'Sigma Beta Rho',
  'Sigma Chi',
  'Sigma Delta Alpha',
  'Sigma Lambda Beta',
  'Sigma Nu',
  'Sigma Phi Epsilon',
  'Sigma Phi Society',
  'Sigma Pi',
  'Sigma Tau Gamma',
  'Sigma Thêta Pi',
  'Tau Delta Phi',
  'Tau Epsilon Phi',
  'Tau Phi Sigma',
  'Theta Chi',
  'Theta Delta Chi',
  'Theta Xi',
  'Triangle',
  'Zeta Beta Tau',
  'Zeta Chi',
  'Zeta Phi Rho',
  'Zeta Psi',
];
