import React, { useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonImg,
  IonRouterLink,
  isPlatform,
} from '@ionic/react';
import {
  Box,
  Stack,
  Menu,
  MenuItem,
  Icon,
  IconButton,
  Link,
  Typography,
  Chip,
} from '@mui/material';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { ellipsisVertical, linkOutline, copyOutline } from 'ionicons/icons';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { Clipboard } from '@awesome-cordova-plugins/clipboard';
import useFetch from 'use-http';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { displayName, linkifyPost } from '../helpers/textManipulation';
import AvatarRole from './AvatarRole';
import VideoPlayer from './VideoPlayer';
import PreviewComment from './PreviewComment';
import PostIndicator from './PostIndicator';
import './Post.css';
import { useAuthContext } from '../providers/auth-provider';
import appStrings from '../common/app_strings';
import PdfIndicator from '../common/atoms/pdfIndicator/PdfIndicator';
import AvatarAdmin from './AvatarAdmin';
import TruncateText from './TruncateText';
import { openInBrowser } from '../helpers/broswer';
import { useAppState } from '../providers/app-state/app-state-provider';
import { renderCommentBody } from '../helpers/media';

const computePostAuthorDisplayName = ({ post, user }) => {
  // alias author as Coach lubav if post is from an admin user
  if (post?.author?.role === 'admin') {
    return 'Coach Lubav';
  }

  // following conditions honor pre-existing logic
  const authorId = post?.author_id;
  const authorFirstName = post?.author?.first_name;
  const authorLastName = post?.author?.last_name;
  const currentUserId = user?.id;

  // compute full display name if possible
  if (authorFirstName && authorLastName) {
    return displayName(authorFirstName, authorLastName);
  }

  // if current user is post.author and there is no author.first name, display 'Me'
  if (authorId === currentUserId && !authorFirstName) {
    return 'Me';
  }

  // if post is from another user and there is no author.first name, display 'User {author_id}'
  if (authorId !== currentUserId && !authorFirstName) {
    return `User ${authorId}`;
  }

  return '';
};

const Post = ({
  post, setPosts, setPost, navigateToGroup, filterMeta,
}) => {
  const history = useHistory();
  const { get } = useFetch();
  const [likeId, setLikeId] = useState(post?.like_id);
  const [likeCount, setLikeCount] = useState(post?.numberOfLikes);
  const [loadingLike, setLoadingLike] = useState(false);
  const { user } = useAuthContext();
  const [showAlert, setShowAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkPreview, setLinkPreview] = useState(null);
  const open = Boolean(anchorEl);
  const { platform } = useAppState();
  const userIsFlagged = user?.isFlagged && user?.role !== 'admin';

  const groups = filterMeta?.filter((item) => post?.groups?.some((group) => group.id === item.value));

  const setGroupName = (group) => {
    if (platform?.isNativePlatform) return group.label || `${group.full_name.slice(0, 15)}...`;
    return group.label || group.full_name;
  };

  const handleGroupClick = (g) => {
    navigateToGroup(g);
  };

  const {
    post: { actions },
  } = appStrings;

  React.useEffect(() => {
    const getPreviewImage = async (text) => {
      const link = linkifyPost(text);
      if (link?.href) {
        const previewResponse = await get(
          `/posts/preview?href=${encodeURIComponent(link.href)}`,
        );
        setLinkPreview(previewResponse);
      }
    };

    if (post.body) {
      // eslint-disable-next-line no-console
      getPreviewImage(post.body).catch((e) => {
        console.error('GET_PREVIEW_LINK ERROR', e);
      });
    }
  }, [post]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { post: doPost, delete: doDelete, response } = useFetch();

  const viewComments = (singlePost) => {
    if (userIsFlagged) return;
    history.push(`/posts/${singlePost.id}/comments`);
  };

  const deleteFeedItem = async (id, isGoal = false) => {
    if (!isGoal) {
      await doDelete(`/posts/${id}`);
    }
    if (setPost && !post?.goal_id) {
      history.push('/tabs/feed');
    } else if (setPost && post?.goal_id) {
      history.push(`/tabs/${user?.role}/goals`);
    } else {
      setPosts((prevState) => prevState.filter((p) => p.id !== post?.id));
    }
  };

  const handlePostLike = async (likeStatus) => {
    if (loadingLike) {
      return;
    }
    setLoadingLike(true);
    try {
      if (!likeStatus) {
        const likePayload = {
          user_id: user.id,
          post_id: post.id,
        };
        const { id } = await doPost('/likes', likePayload);
        if (setPosts) {
          setPosts((prev) => prev.map((singlePost) => {
            if (singlePost.id === post.id) {
              return {
                ...singlePost,
                like_id: id,
                numberOfLikes: singlePost.numberOfLikes + 1,
              };
            }
            return singlePost;
          }));
        }
        if (setPost) {
          setPost({
            ...post,
            like_id: id,
            numberOfLikes: post.numberOfLikes + 1,
          });
        }
        setLikeId(id);
        setLikeCount((prev) => prev + 1);
      } else {
        await doDelete(`/likes/${likeStatus}`);
        if (setPosts) {
          setPosts((prev) => prev.map((singlePost) => {
            if (singlePost.id === post.id) {
              return {
                ...singlePost,
                like_id: null,
                numberOfLikes: singlePost.numberOfLikes - 1,
              };
            }
            return singlePost;
          }));
        }
        if (setPost) {
          setPost({
            ...post,
            like_id: null,
            numberOfLikes: post.numberOfLikes - 1,
          });
        }
        setLikeId(null);
        setLikeCount((prev) => prev - 1);
      }
    } catch (e) {
      //
    }
    setLoadingLike(false);
  };

  const handlePreviewCommentLike = async (
    commentId,
    likeStatus,
    likeStatusSet,
  ) => {
    if (loadingLike) {
      return;
    }
    setLoadingLike(true);
    try {
      if (!likeStatus) {
        const likePayload = {
          user_id: user.id,
          comment_id: commentId,
        };
        const { id } = await doPost('/likes', likePayload);
        if (response.status === 200 || response.status === 403) {
          likeStatusSet(id);
        }
      } else {
        doDelete(`/likes/${likeStatus}`);
        likeStatusSet(null);
      }
    } catch (e) {
      //
    }
    setLoadingLike(false);
  };

  const editPost = (evt) => {
    evt.preventDefault();
    history.push('/post-edit', post);
  };

  const copyClicked = () => {
    if (isPlatform('web') || isPlatform('mobileweb')) {
      navigator.clipboard.writeText(linkPreview.url);
    } else {
      Clipboard.copy(linkPreview.url);
    }
    toast(actions.copyLink, { id: `post-${post?.id}` });
  };

  const editGoal = () => {
    const currentGoal = { ...post?.goal };
    currentGoal.post = { id: post?.id };
    history.push('/protege/goals-edit', currentGoal);
  };

  const deleteGoalAndPost = async () => {
    doDelete(`/goals/${post?.goal?.id}`);
    await deleteFeedItem(post?.id, true);
  };

  const userPostOptions = [
    {
      label: actions.editPost,
      event: editPost,
      icon: 'assets/icon/edit.svg',
    },
    {
      label: actions.deletePost,
      event() {
        setShowAlert(true);
        handleMenuClose();
      },
      icon: 'assets/icon/delete.svg',
    },
  ];

  const userGoalOptions = [
    {
      label: actions.editGoal,
      event: editGoal,
      icon: 'assets/icon/edit.svg',
    },
    {
      label: actions.deleteGoal,
      event() {
        setShowAlert(true);
        handleMenuClose();
      },
      icon: 'assets/icon/delete.svg',
    },
  ];

  const otherPostOptions = [
    {
      label: actions.reportPost,
      event: null,
      icon: 'assets/icon/report.svg',
      link: `/posts/${post.id}/report`,
    },
  ];

  const totalComments = post.comments?.reduce(
    (count, current) => count + current.comments.length,
    post.comments.length,
  );

  const flatten = (into, node) => {
    if (node == null) return into;
    if (Array.isArray(node)) return node.reduce(flatten, into);
    into.push(node);
    return flatten(into, node.comments);
  };

  const organizeComments = (comments) => {
    if (!comments) return [];

    const finalComments = [];

    const refinedComments = comments.map((comment) => {
      if (comment?.comments.length < 1) return comment;
      const refinedComment = comment;
      refinedComment.comments = flatten([], comment.comments);
      return refinedComment;
    });

    refinedComments.forEach((comment) => {
      finalComments.push(
        <PreviewComment
          key={comment.id}
          comment={{ ...comment, comments: [] }}
          handlePreviewCommentLike={handlePreviewCommentLike}
          handleNavigation={() => viewComments(post)}
        />,
      );
      comment?.comments.forEach((subComment) => finalComments.push(
        <PreviewComment
          key={subComment.id}
          comment={subComment}
          handlePreviewCommentLike={handlePreviewCommentLike}
          handleNavigation={() => viewComments(post)}
          isSubcomment
        />,
      ));
    });

    return finalComments.slice(0, 3);
  };

  const organizedComments = organizeComments(post?.comments);
  const userRoute = `/profile/${post.author_id}`;
  const adminRoute = `/profile/${post.author_id}?role=admin`;
  const isViewingAdmin = post?.author?.role === 'admin';

  const authorDisplayName = computePostAuthorDisplayName({ post, user });

  return (
    <>
      <Menu
        aria-label="Post Options Menu"
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {post?.author_id !== user?.id
          && otherPostOptions.map((option) => {
            if (option.link) {
              return (
                <MenuItem
                  onClick={() => history.push(option.link)}
                  key={option.label}
                  disableRipple
                >
                  <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={option.icon}
                      height={16}
                      width={16}
                      alt={option.label}
                    />
                  </Icon>
                  {option.label}
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={option.label}
                disableRipple
                onClick={option.event || handleMenuClose}
              >
                <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={option.icon}
                    height={16}
                    width={16}
                    alt={option.label}
                  />
                </Icon>
                {option.label}
              </MenuItem>
            );
          })}
        {post?.author_id === user?.id
          && post?.goal_id
          && userGoalOptions.map((option) => (
            <MenuItem
              key={option.label}
              disableRipple
              onClick={option.event || handleMenuClose}
            >
              <Icon sx={{ display: 'grid', placeItems: 'center' }}>
                <IonIcon
                  style={{ fontSize: 16, color: '#9c9c9c' }}
                  slot="icon-only"
                  src={option.icon}
                />
              </Icon>
              {option.label}
            </MenuItem>
          ))}
        {post?.author_id === user?.id
          && !post?.goal_id
          && userPostOptions.map((option) => {
            if (option.link) {
              return (
                <Link
                  href={option.link}
                  color="inherit"
                  underline="none"
                  key={option.label}
                >
                  <MenuItem key={option.label} disableRipple>
                    <Icon sx={{ display: 'grid', placeItems: 'center' }}>
                      <IonIcon
                        style={{ fontSize: 16, color: '#9c9c9c' }}
                        slot="icon-only"
                        src={option.icon}
                      />
                    </Icon>
                    {option.label}
                  </MenuItem>
                </Link>
              );
            }
            return (
              <MenuItem
                key={option.label}
                disableRipple
                onClick={option.event || handleMenuClose}
              >
                <Icon sx={{ display: 'grid', placeItems: 'center' }}>
                  <IonIcon
                    style={{ fontSize: 16, color: '#9c9c9c' }}
                    slot="icon-only"
                    src={option.icon}
                  />
                </Icon>
                {option.label}
              </MenuItem>
            );
          })}
      </Menu>
      <IonAlert
        role="alertdialog"
        aria-modal="true"
        aria-label={
          post?.goal_id
            ? 'Delete goal confirmation'
            : 'Delete post confirmation'
        }
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={post?.goal_id ? 'Delete Goal' : 'Delete Post'}
        message={`Are you sure you want to delete this ${
          post?.goal_id ? 'goal' : 'post'
        }?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            id: 'cancel-button',
          },
          {
            text: 'Delete',
            role: 'destructive',
            id: 'delete-button',
            handler: () => {
              if (post?.goal_id) {
                deleteGoalAndPost(post);
              } else {
                deleteFeedItem(post?.id);
              }
            },
          },
        ]}
      />
      <div key={post.id} className="post-container">
        <div className="post-profile">
          <div className="post-profile-info">
            <IonRouterLink routerLink={isViewingAdmin ? adminRoute : userRoute}>
              {isViewingAdmin ? <AvatarAdmin /> : <AvatarRole user={post?.author} unsetTopMargin />}
            </IonRouterLink>
            <div className="post-profile-details">
              <IonRouterLink
                routerLink={post.author.role === 'admin' ? adminRoute : userRoute}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p>
                    {authorDisplayName}
                  </p>
                </div>
              </IonRouterLink>
              <span className="post-time">
                {formatDistanceToNowStrict(parseISO(post?.created_at), {
                  addSuffix: true,
                })}
              </span>
            </div>
          </div>
          {!userIsFlagged && (
            <IconButton
              onClick={handleMenuOpen}
              disabled={userIsFlagged}
              sx={{ color: '#03295F' }}
            >
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IconButton>
          )}
        </div>
        <Box
          sx={{
            bgcolor: '#fff',
            color: '#2d2d2d',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              overflowWrap: 'anywhere',
              wordWrap: 'anywhere',
              wordBreak: 'break-word',
              px: 2,
              pt: 2,
              mb: 2,
            }}
            onClick={() => viewComments(post)}
          >
            <TruncateText text={renderCommentBody(post, true, true)} maxChars={115} />
          </Typography>
          {post?.attachments[0]?.s3_key.startsWith('images') ? (
            <IonImg src={post?.attachments[0]?.uri} />
          ) : null}
          {post?.attachments[0]?.s3_key.startsWith('videos') ? (
            <div className="post-image">
              <VideoPlayer videos={post?.attachments[0]?.transcoded_videos} />
            </div>
          ) : null}
          {post?.attachments[0]?.s3_key.startsWith('pdfs') ? (
            <PdfIndicator attachment={post.attachments[0]} />
          ) : null}
          {linkPreview?.images
          && linkPreview?.images[0]
          && !post?.attachments[0]?.s3_key ? (
            <div className="post-link-preview">
              <IonImg
                className="post-link-preview-image"
                src={linkPreview?.images[0]}
                onClick={() => openInBrowser(linkPreview?.url)}
              />
              <div className="post-link-preview-overlay" />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  position: 'absolute',
                  color: '#f1f1f1',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 1,
                }}
              >
                <IonIcon
                  className="post-link-icon"
                  icon={linkOutline}
                  onClick={() => openInBrowser(linkPreview?.url)}
                />
                <b className="link-headline">{linkPreview?.title}</b>
                <IonIcon
                  icon={copyOutline}
                  className="post-link-icon"
                  onClick={copyClicked}
                />
              </Stack>
            </div>
            ) : null}
        </Box>
        <div className="post-actions-container">
          <Stack
            direction="row"
            spacing="4px 4px"
            flexWrap="wrap"
            useFlexGap
          >
            {linkPreview && !post?.goal?.type && (
            <PostIndicator type="share" text="sharing resources" />
            )}
            {post?.goal_id && (
            <PostIndicator
              type={post?.goal?.type}
              text={`${
                post?.goal?.type === 'health' ? 'wellness' : post?.goal?.type
              } goal`}
            />
            )}
            {post?.question && <PostIndicator type={post?.question.type} />}
            {groups?.map((g) => (
              <PostIndicator
                text={setGroupName(g)}
                onClick={() => handleGroupClick(g)}
                sx={{ backgroundColor: '#F66324', color: '#fff' }}
              />
            ))}
          </Stack>
          <div>
            <div className="post-actions">
              <IonButton
                aria-label="Post like button"
                fill="clear"
                size="small"
                onClick={() => handlePostLike(likeId)}
                disabled={userIsFlagged}
              >
                <IonIcon
                  color={
                    userIsFlagged
                      ? 'unverified'
                      : 'warning'
                  }
                  src={
                    likeId
                      ? 'assets/icon/heart.svg'
                      : 'assets/icon/heartOutline.svg'
                  }
                  slot="icon-only"
                />
              </IonButton>
              <span
                className={clsx(
                  userIsFlagged
                    ? 'unverified'
                    : 'verified',
                )}
              >
                {likeCount}
              </span>
              <IonButton
                aria-label="Comment button"
                fill="clear"
                size="small"
                onClick={() => viewComments(post)}
                disabled={userIsFlagged}
              >
                <IonIcon
                  color={
                    userIsFlagged
                      ? 'unverified'
                      : 'warning'
                  }
                  src="assets/icon/chatBubbleOutline.svg"
                  slot="icon-only"
                />
              </IonButton>
              <span
                className={clsx(
                  userIsFlagged
                    ? 'unverified'
                    : 'verified',
                )}
              >
                {totalComments}
              </span>
            </div>
          </div>
        </div>
        <div className="post-comments-preview">
          <Stack spacing={1}>
            {organizedComments}
          </Stack>
        </div>
        {totalComments - 3 > 0 && !userIsFlagged ? (
          <Chip
            variant="outlined"
            label={`${actions.moreComments} (${totalComments})`}
            sx={{
              maxWidth: 'fit-content',
              fontSize: 13,
              fontWeight: 700,
              color: '#03295F',
              border: '2px solid #03295F',
              mt: 1.5,
              cursor: 'pointer',
              '& .MuiChip-label': {
                px: 2,
              },
            }}
            onClick={() => viewComments(post)}
          />
        ) : null}
      </div>
    </>
  );
};

Post.propTypes = {
  post: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setPosts: PropTypes.func,
  setPost: PropTypes.func,
};

Post.defaultProps = {
  setPosts: null,
  setPost: null,
};

export default Post;
