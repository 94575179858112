import { useEffect, useState } from 'react';
import useFetch from 'use-http';
import {
  ENVIRONMENTS,
  computeEnvironment,
} from '../helpers/environment';

// Use this to compute default flags if the API is down
// The return object should be updated once a feature flag is "LOCKED" in the UI
const computeDefaultFlags = () => {
  const environment = computeEnvironment();

  return {
    wholeMe: true,
    enhancedConversations: true,
    isSaveAndContinueAlwaysEnabled: environment === ENVIRONMENTS.dev,
  };
};

const mapFeatureFlags = (flags) => {
  const {
    wholeMe,
    enhancedConversations,
    ...rest
  } = flags;
  return {
    isWholeMeEnabled: wholeMe,
    isEnhancedConversationsEnabled: enhancedConversations,
    ...rest,
  };
};

const useFeatureFlags = (authorized = false) => {
  const { get, abort, response } = useFetch();
  const [state, setState] = useState({});

  const resetState = () => {
    setState({});
  };

  const setFeatureFlags = async () => {
    let flags = {};
    try {
      const result = await get('/feature-flags/flags');

      if (response.ok) {
        flags = result;
      } else {
        throw new Error('Error fetching feature flags');
      }
    } catch (e) {
      console.error(e);
      flags = computeDefaultFlags();
    } finally {
      setState(mapFeatureFlags(flags));
    }
  };

  useEffect(() => {
    if (authorized) {
      setFeatureFlags();
    }

    if (!authorized && Object.keys(state).length) {
      resetState();
    }

    return () => abort();
  }, [authorized]);

  return state;
};

export default useFeatureFlags;
