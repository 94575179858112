import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  alpha,
  MenuList,
  Stack,
} from '@mui/material';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import useFetch from 'use-http';
import { ellipsisVertical } from 'ionicons/icons';
import { motion } from 'framer-motion';
import AvatarRole from '../../../components/AvatarRole';
import Pill from '../../../common/atoms/Pill/Pill';
import appStrings from '../../../common/app_strings';
import { useAppState } from '../../../providers/app-state/app-state-provider';
import { openInBrowser } from '../../../helpers/broswer';
import {
  affinityMemberContainer,
  pillStyles,
} from './AffinityNetworkStyles';
import useWindowDimensions from '../../../helpers/hooks';
import { truncateText } from '../../../helpers/transformers';
import { careerFieldData } from '../../wholeme/pages/career/career_fields/careerFieldData';

const {
  affinityNetwork: {
    affinityMember: {
      viewProfile, sendMessage, scheduleTime, loading,
    },
  },
} = appStrings;

const IonIconBase = React.forwardRef(({ className, slot, icon }, ref) => (
  <IonIcon className={className} slot={slot} icon={icon} ref={ref} />
));

const MotionIonIcon = motion(IonIconBase);

const variants = {
  rotate: { rotate: -90, transition: { duration: 0.3 } },
  initial: { rotate: 0, transition: { duration: 0.3 } },
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: 8,
    width: 'fit-content',
    color: '#03295F',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiList-root:focus-visible': {
      outline: 'none',
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontWeight: 500,
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const AffinityMember = ({ member, role, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMessageButtonLoading, setIsMessageButtonLoading] = useState(false);
  const { permissions } = member;

  const history = useHistory();
  const { platform } = useAppState();
  const { post, response } = useFetch();
  const { width } = useWindowDimensions();

  const firstName = width > 500 ? member?.first_name : truncateText(member?.first_name, 18);

  const displayName = `${firstName} ${member?.last_name.charAt(0)}.`;

  const open = Boolean(anchorEl);

  const canViewProfile = permissions['view-profile'].allowed || (
    !permissions['view-profile'].allowed && permissions['view-profile']?.reason === 'UNDER_AGE'
  );
  const canMeet = permissions.meet.allowed && member.calendly_url;
  const canMessage = permissions['direct-message'].allowed;

  const cityField = ['city'];
  const backrFields = ['career_experience', ...cityField];
  const protegeFields = ['school_type', ...cityField];
  const pillFields = role === 'backr' ? backrFields : protegeFields;

  const calculateCharCount = () => {
    let total = 0;
    total += displayName.replace(/\s/g, '').length;
    pillFields.forEach((field) => {
      if (member[field] !== null && member[field] !== undefined && member[field] !== '') {
        total += member[field].replace(/\s/g, '').length;
      }
    });

    return total;
  };

  const totalCharCount = calculateCharCount();

  const getCareerLabel = (value) => {
    const careerField = careerFieldData.find((field) => field.value === value);
    return careerField ? careerField.label : value;
  };

  const pillRenderer = (fields) => fields.map((field) => {
    if (member[field] !== null && member[field] !== undefined && member[field] !== '') {
      let label = member[field];

      if (field === 'career_experience') {
        label = getCareerLabel(label);
      }
      return <Pill label={label} pillStylesOverride={pillStyles} />;
    }
    return null;
  });

  const renderPills = () => {
    if (width <= 400) {
      if (totalCharCount >= 28) {
        return null;
      }
      return pillRenderer(cityField);
    }
    if (width < 570 && totalCharCount >= 28) {
      return pillRenderer(cityField);
    }
    return pillRenderer(pillFields);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMessageButtonClick = async () => {
    setIsMessageButtonLoading(true);
    let conversationSid = null;
    try {
      const res = await post(`/conversations/request-sid/${member?.user_id}/p2p`);
      if (response.ok && res?.sid) {
        conversationSid = res.sid;
      } else {
        throw new Error('request failed');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
      toast.error('Direct Messaging Unavailable. Try again later.');
      handleMenuClose();
    } finally {
      if (conversationSid) {
        history.push(`/chat/${conversationSid}`);
      }
    }
    setIsMessageButtonLoading(false);
  };

  const handleCalendlyClick = async () => {
    if (platform?.isNativePlatform) {
      await openInBrowser(member.calendly_url);
    } else {
      window.open(member.calendly_url, '_blank');
    }
  };

  return (
    <Box sx={{ ...affinityMemberContainer, borderRadius: index === 0 && '10px 10px 0 0' }}>
      <Box className="post-profile" style={{ paddingBottom: 0 }}>
        <Stack className="post-profile-info" sx={{ flexDirection: 'row', gap: '.5rem' }}>
          <IonRouterLink routerLink={`/profile/${member?.user_id}`}>
            <AvatarRole user={member} unsetTopMargin />
          </IonRouterLink>
          <Box className="post-profile-details" sx={{ color: '#063D8F', fontWeight: 700 }}>
            {displayName}
          </Box>
        </Stack>
        <Stack sx={{ flexDirection: 'row', gap: '.5rem' }}>
          <Stack sx={{ flexDirection: 'row', gap: '.5rem', alignItems: 'center' }}>
            {renderPills()}
          </Stack>
          <StyledMenu
            aria-label="Member List Menu"
            open={open}
            onClose={handleMenuClose}
            anchorEl={anchorEl}
            anchorReference="anchorEl"
          >
            <MenuList dense>
              <MenuItem key="view-profile" disabled={!canViewProfile} disableRipple>
                <IonRouterLink routerLink={`/profile/${member?.user_id}`}>
                  {viewProfile}
                </IonRouterLink>
              </MenuItem>
              <MenuItem onClick={handleMessageButtonClick} key="send-message" disabled={!canMessage} disableRipple>
                {isMessageButtonLoading ? loading : sendMessage}
              </MenuItem>
              <MenuItem onClick={handleCalendlyClick} key="meet" disabled={!canMeet} disableRipple>
                {scheduleTime}
              </MenuItem>
            </MenuList>
          </StyledMenu>
          <IconButton
            onClick={handleMenuOpen}
            sx={{ color: '#03295F' }}
          >
            <MotionIonIcon
              variants={variants}
              className="header-menu-btn"
              slot="icon-only"
              icon={ellipsisVertical}
              animate={anchorEl ? 'rotate' : 'initial'}
            />
          </IconButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default AffinityMember;
