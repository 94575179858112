import React from 'react';
import { Box } from '@mui/material';
import { useAppState } from '../../../../providers/app-state/app-state-provider';

const MainImage = ({ imageSrc, imageAlt, imageStylesOverride }) => {
  const { platform } = useAppState();
  const imageStyles = {
    margin: '40px',
    width: platform?.isNativePlatform ? 'calc(100% - 80px)' : 'calc(70% - 80px)',
    ...imageStylesOverride,
  };
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        src={imageSrc}
        alt={imageAlt}
        style={imageStyles}
      />
    </Box>
  );
};

export default MainImage;
