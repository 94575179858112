/* eslint-disable max-len */
export const healthInterestsData = [
  {
    label: 'Mental and spiritual health',
    value: 'Mental and spiritual health',
    labelImage: 'assets/profile/health/interest-mental-health.svg',
    subQuestions: [
      {
        label: 'Acceptance and commitment therapy (ACT)',
        value: 'Acceptance and commitment therapy (ACT)',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=f89548b5b5a5eedeJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIyNw&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=acceptance+and+commitment+therapy&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvQWNjZXB0YW5jZV9hbmRfY29tbWl0bWVudF90aGVyYXB5&ntb=1',
      },
      {
        label: 'Cognitive Behavior Therapy ',
        value: 'Cognitive Behavior Therapy ',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=c32123d8f747fcaaJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTE5NQ&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=CBT&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvQ29nbml0aXZlX2JlaGF2aW9yYWxfdGhlcmFweQ&ntb=1',
      },
      {
        label: 'Dialetic Behavior Therapy ',
        value: 'Dialetic Behavior Therapy ',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=923a56fd664d9167JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIwOA&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=DBT&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvRGlhbGVjdGljYWxfYmVoYXZpb3JfdGhlcmFweQ&ntb=1',
      },
      {
        label: 'Feelings / emotions ',
        value: 'Feelings / emotions ',
        infoUrl: '',
      },
      {
        label: 'Gratitude',
        value: 'Gratitude',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=dd2fda6714b677bcJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIyNA&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=gratitude&u=a1aHR0cHM6Ly93d3cudmVyeXdlbGxtaW5kLmNvbS93aGF0LWlzLWdyYXRpdHVkZS01MjA2ODE3&ntb=1',
      },
      {
        label: 'Interpersonal therapy (IPT)',
        value: 'Interpersonal therapy (IPT)',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=c1f8eed9c5146147JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxMg&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=interpersonal+therapy&u=a1aHR0cHM6Ly93d3cudmVyeXdlbGxtaW5kLmNvbS9pbnRlcnBlcnNvbmFsLXRoZXJhcHktMTA2NzQwNA&ntb=1',
      },
      {
        label: 'Journaling ',
        value: 'Journaling ',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=01ba522123c96227JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxOA&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=journaling&u=a1aHR0cHM6Ly93d3cuaGVhbHRobGluZS5jb20vaGVhbHRoL2JlbmVmaXRzLW9mLWpvdXJuYWxpbmc&ntb=1',
      },
      {
        label: 'Meditation',
        value: 'Meditation',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=9106526d537a8045JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIyNg&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=meditation&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvTWVkaXRhdGlvbg&ntb=1',
      },
      {
        label: 'Mindfulness',
        value: 'Mindfulness',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=fe8c2d5cda8d98eeJmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTIxNw&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=mindfulness&u=a1aHR0cHM6Ly9lbi53aWtpcGVkaWEub3JnL3dpa2kvTWluZGZ1bG5lc3M&ntb=1',
      },
      {
        label: 'Music / art therapy ',
        value: 'Music / art therapy ',
        infoUrl: 'https://www.bing.com/ck/a?!&&p=508b266ffd849b97JmltdHM9MTY4NTkyMzIwMCZpZ3VpZD0zODVkMWM0NC00NTVlLTZlZDUtMWI1Yi0wZGY0NDRjMTZmYjMmaW5zaWQ9NTI4Nw&ptn=3&hsh=3&fclid=385d1c44-455e-6ed5-1b5b-0df444c16fb3&psq=music%2fart+therapy&u=a1aHR0cHM6Ly9wb3NpdGl2ZXBzeWNob2xvZ3kuY29tL211c2ljLXRoZXJhcHktYWN0aXZpdGllcy10b29scy8&ntb=1',
      },
      {
        label: 'Pet therapy',
        value: 'Pet therapy',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi__oyF8K7_AhWommoFHc1MAZUQFnoECBAQAw&url=https%3A%2F%2Fwww.healthline.com%2Fhealth%2Fpet-therapy%23%3A~%3Atext%3DWhat%2520is%2520pet%2520therapy%253F%2Ccommonly%2520used%2520in%2520pet%2520therapy.&usg=AOvVaw2gxHxakgrbwn6flTH-WpTJ',
      },
      {
        label: 'Play therapy ',
        value: 'Play therapy ',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjHvPOL8K7_AhUsmmoFHWn_AaAQFnoECAkQAQ&url=https%3A%2F%2Fwww.a4pt.org%2F&usg=AOvVaw1lEPtMUvA_nktAZliQ3Wqu',
      },
      {
        label: 'Positive psychology ',
        value: 'Positive psychology ',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwivjv3R8q7_AhXBk2oFHUA2BlUQFnoECBIQAQ&url=https%3A%2F%2Fpositivepsychology.com%2F&usg=AOvVaw3QJEBWyp_DjzqvPOKmgISK',
      },
      {
        label: 'Praise and worship',
        value: 'Praise and worship',
        infoUrl: '',
      },
      {
        label: 'Prayer',
        value: 'Prayer',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjwpILc8q7_AhXGmWoFHQnJAvUQFnoECBwQAw&url=https%3A%2F%2Fwww.britannica.com%2Ftopic%2Fprayer%23%3A~%3Atext%3Dprayer%252C%2520an%2520act%2520of%2520communication%2Cutilizing%2520various%2520forms%2520and%2520techniques.&usg=AOvVaw0dKL5FI13IYq3VPoZ5zTh0',
      },
      {
        label: 'Psychodynamic therapy',
        value: 'Psychodynamic therapy',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjY07Hh8q7_AhV0k2oFHZo5BuIQFnoECEEQAQ&url=https%3A%2F%2Fwww.psychologytoday.com%2Fus%2Ftherapy-types%2Fpsychodynamic-therapy&usg=AOvVaw2NKm_UJ_P4zQ5CAe97zoTX',
      },
      {
        label: 'Spiritual health',
        value: 'Spiritual health',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjVwfHo8q7_AhU7mGoFHXrXBIAQFnoECBoQAQ&url=https%3A%2F%2Fwww.wespath.org%2Fhealth-well-being%2Fhealth-well-being-resources%2Fspiritual-well-being%2Fthe-basics-what-is-spiritual-health&usg=AOvVaw3BXwjPsx5V3D7pGQ9t5YLE',
      },
    ],
  },
  {
    label: 'Nutritional practices',
    value: 'Nutritional practices',
    labelImage: 'assets/profile/health/interest-nutrition.svg',
    subQuestions: [
      {
        label: 'DASH',
        value: 'DASH',
        infoUrl: '',
      },
      {
        label: 'Eating local',
        value: 'Eating local',
        infoUrl: '',
      },
      {
        label: 'Gluten free',
        value: 'Gluten free',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiL9peH867_AhWQk2oFHbTzCywQFnoECBoQAQ&url=https%3A%2F%2Fwww.mayoclinic.org%2Fhealthy-lifestyle%2Fnutrition-and-healthy-eating%2Fin-depth%2Fgluten-free-diet%2Fart-20048530&usg=AOvVaw3jSNStsBUAtlZmgCKlY7mN',
      },
      {
        label: 'Keto ',
        value: 'Keto ',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi6i_uM867_AhVkl2oFHawxDEkQFnoECAkQAQ&url=https%3A%2F%2Fwww.healthline.com%2Fnutrition%2Fketogenic-diet-101&usg=AOvVaw0gx71427-D5e5WvRYQ7URd',
      },
      {
        label: 'Mediterranean',
        value: 'Mediterranean',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwigvLuI-67_AhVBmGoFHYkQBSoQFnoECAwQAQ&url=https%3A%2F%2Fmy.clevelandclinic.org%2Fhealth%2Farticles%2F16037-mediterranean-diet&usg=AOvVaw27eeZR5KI1uzbQqB5OqWFB',
      },
      {
        label: 'MIND',
        value: 'MIND',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiH37-N-67_AhUglGoFHdOYBUYQFnoECAkQAQ&url=https%3A%2F%2Fwww.hsph.harvard.edu%2Fnutritionsource%2Fhealthy-weight%2Fdiet-reviews%2Fmind-diet%2F&usg=AOvVaw269bxu_uCTpGYD4FPNVZSP',
      },
      {
        label: 'Non-GMO',
        value: 'Non-GMO',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjd8vGT-67_AhUblmoFHY2oBjYQFnoECAkQAQ&url=https%3A%2F%2Fwww.mamanatural.com%2Fhow-to-avoid-gmos%2F&usg=AOvVaw1Sz28RcqZs9hGoFTUUS2iN',
      },
      {
        label: 'Organic',
        value: 'Organic',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiImJGZ-67_AhXwlWoFHRh0CNcQFnoECAkQAQ&url=https%3A%2F%2Fwww.helpguide.org%2Farticles%2Fhealthy-eating%2Forganic-foods.htm&usg=AOvVaw0ci2d-3ODPEEwvolTOxsXu',
      },
      {
        label: 'Paleo',
        value: 'Paleo',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiFleuc-67_AhWvlGoFHfttBFwQFnoECAwQAQ&url=https%3A%2F%2Fwww.mayoclinic.org%2Fhealthy-lifestyle%2Fnutrition-and-healthy-eating%2Fin-depth%2Fpaleo-diet%2Fart-20111182&usg=AOvVaw2QVrrijca5wsVNGr8DvZe-',
      },
      {
        label: 'Peganism',
        value: 'Peganism',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwimkICh-67_AhV1l2oFHbEyDVUQFnoECBUQAQ&url=https%3A%2F%2Fwww.webmd.com%2Fdiet%2Fpegan-diet-what-to-know&usg=AOvVaw14g0S3hqZFwbH7SWjbWtfz',
      },
      {
        label: 'Pescatarianism',
        value: 'Pescatarianism',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjZmoKn-67_AhVQnWoFHX9qDM8QFnoECBAQAw&url=https%3A%2F%2Fchickenofthesea.com%2Ffresh-ideas%2Fpescatarian-diet%2F%23%3A~%3Atext%3DA%2520pescatarian%2520diet%2520refers%2520to%2520a%2520vegetarian%2520diet%2520with%2520the%2Cand%2520omega%252D3%2520fatty%2520acids.&usg=AOvVaw0VopFRAbjT4jUhN0fFwXR5',
      },
      {
        label: 'Protein',
        value: 'Protein',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjfmfuq-67_AhUSl2oFHYgrBwMQFnoECAIQAQ&url=https%3A%2F%2Fwww.webmd.com%2Fdiet%2Fss%2Fslideshow-high-protein-diet&usg=AOvVaw22ulZjK8uI83G_ybh-RRGw',
      },
      {
        label: 'Raw food',
        value: 'Raw food',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjLkOCv-67_AhU3mWoFHQaqCz4QFnoECAgQAQ&url=https%3A%2F%2Fwww.healthline.com%2Fnutrition%2Fraw-food-diet&usg=AOvVaw3VVmIK6Cp6X_FU_x7tQ2Oh',
      },
      {
        label: 'Supplements',
        value: 'Supplements',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj91Iq0-67_AhXPlWoFHfp_BT8QFnoECAkQAQ&url=https%3A%2F%2Fods.od.nih.gov%2Ffactsheets%2FWYNTK-Consumer%2F&usg=AOvVaw3loH7vzRzN679DzDYQenl4',
      },
      {
        label: 'Sustainable food',
        value: 'Sustainable food',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwin3oS5-67_AhUZmmoFHQh4BrMQFnoECBIQAw&url=https%3A%2F%2Fwww.hsph.harvard.edu%2Fnutritionsource%2Fsustainability%2F%23%3A~%3Atext%3DSustainable%2520Diets%2520are%2520those%2520diets%2Cfor%2520present%2520and%2520future%2520generations.&usg=AOvVaw1tZaBGA-piysXoEkzGJ9R_',
      },
      {
        label: 'Veganism',
        value: 'Veganism',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiMyfTD-67_AhUnnWoFHVgiAbQQFnoECAsQAw&url=https%3A%2F%2Fwww.vegansociety.com%2Fabout-us%2Ffurther-information%2Fkey-facts%23%3A~%3Atext%3DWhat%2520is%2520veganism%253F%2Cpossible%252C%2520products%2520tested%2520on%2520animals.&usg=AOvVaw0s0OCX5JYJe4YpzntYKSWO',
      },
      {
        label: 'Vegetarianism',
        value: 'Vegetarianism',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj77YPK-67_AhWFmmoFHa94DQgQFnoECBEQAw&url=https%3A%2F%2Fwww.britannica.com%2Ftopic%2Fvegetarianism%23%3A~%3Atext%3Dvegetarianism%252C%2520the%2520theory%2520or%2520practice%2C%252C%2520environmental%252C%2520or%2520nutritional%2520reasons.&usg=AOvVaw2Y-Iw7x9AiVa45MmiLpv3U',
      },
    ],
  },
  {
    label: 'Food',
    value: 'Food',
    labelImage: 'assets/profile/health/interest-food.svg',
    subQuestions: [
      {
        label: 'Baking',
        value: 'Baking',
      },
      {
        label: 'Gardening',
        value: 'Gardening',
      },
      {
        label: 'Cooking',
        value: 'Cooking',
      },
      {
        label: 'Brewing',
        value: 'Brewing',
      },
      {
        label: 'Distilling',
        value: 'Distilling',
      },
      {
        label: 'Pickling',
        value: 'Pickling',
      },
      {
        label: 'Grilling',
        value: 'Grilling',
      },
      {
        label: 'Coffee roasting',
        value: 'Coffee roasting',
      },
    ],
  },
  {
    label: 'Outdoors',
    value: 'Outdoors',
    labelImage: 'assets/profile/health/interest-outdoors.svg',
    subQuestions: [
      {
        label: 'Backpacking',
        value: 'Backpacking',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiP2v3V-67_AhVPl2oFHYZ6A88QFnoECA0QAQ&url=https%3A%2F%2Fwww.rei.com%2Flearn%2Fexpert-advice%2Fbackpacking-beginners.html&usg=AOvVaw15FgI-L_sumeQN6D6VRkLY',
      },
      {
        label: 'Base jumping',
        value: 'Base jumping',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwicy8fc-67_AhUBkmoFHbfMB0EQFnoECA0QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBASE_jumping&usg=AOvVaw09yQz0voqtZKz8mk9fjjxN',
      },
      {
        label: 'Birdwatching',
        value: 'Birdwatching',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAz_Hh-67_AhVam2oFHa9kAYEQFnoECAkQAQ&url=https%3A%2F%2Fblog.twitter.com%2Fen_us%2Ftopics%2Fproduct%2F2021%2Fintroducing-birdwatch-a-community-based-approach-to-misinformation&usg=AOvVaw0Xb3o5FY6bwBL8GmG5XFDy',
      },
      {
        label: 'Camping',
        value: 'Camping',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjHq6j7-67_AhXDmmoFHe8FAhQQFnoECBgQAw&url=https%3A%2F%2Fkoa.com%2Fblog%2F10-tips-for-tent-camping%2F&usg=AOvVaw2YCbnoQel-0T9jqA_b33Cd',
      },
      {
        label: 'Canoeing',
        value: 'Canoeing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjXpveA_K7_AhVqnGoFHbY-C5EQFnoECBkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCanoeing&usg=AOvVaw0KlPfkNlrvHoiFgn7f8OAi',
      },
      {
        label: 'Canyoning',
        value: 'Canyoning',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjOqPOG_K7_AhUogGoFHSo3DSwQFnoECA8QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCanyoning&usg=AOvVaw2gDHIQ3wYzUenLsdYDCLWl',
      },
      {
        label: 'Fishing',
        value: 'Fishing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwidjI7S_K7_AhUSlGoFHfJZCoAQFnoECAgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFishing&usg=AOvVaw2j002_0Buek6Q1m_PvbhFS',
      },
      {
        label: 'Hang gliding',
        value: 'Hang gliding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwicxd7W_K7_AhUcnWoFHRrpCPwQFnoECA0QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHang_gliding&usg=AOvVaw35r0rXNabcktLtwb37ZUdq',
      },
      {
        label: 'Hiking',
        value: 'Hiking',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiV19Hb_K7_AhWAkmoFHYLkAOIQFnoECFIQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHiking&usg=AOvVaw3iJBK1pbvVSIjNyVopStwy',
      },
      {
        label: 'Hunting',
        value: 'Hunting',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiquZfj_K7_AhVwkmoFHUv7BokQFnoECA4QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHunting&usg=AOvVaw0acQsx0xM-KmPWg4NMJUwX',
      },
      {
        label: 'Kayaking',
        value: 'Kayaking',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi04a3n_K7_AhWclGoFHeKwA50QFnoECBgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FKayaking&usg=AOvVaw0nafrmJPsTWR2qhpXtJI_8',
      },
      {
        label: 'Mountain biking',
        value: 'Mountain biking',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjv4YXt_K7_AhWvlWoFHXNbB4IQFnoECFEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FMountain_biking&usg=AOvVaw1k3KXDjB2wEPtMQzJ5oVL-',
      },
      {
        label: 'Mountaineering',
        value: 'Mountaineering',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiSzKTy_K7_AhVulGoFHXw6AzwQFnoECBEQAw&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FMountaineering%23%3A~%3Atext%3DMountaineering%252C%2520mountain%2520climbing%252C%2520or%2520alpinism%2Csports%2520in%2520their%2520own%2520right.&usg=AOvVaw1KAGc--odfGFtjPlf8g9in',
      },
      {
        label: 'Paragliding',
        value: 'Paragliding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwin--TxgK__AhXzmWoFHc3lCcAQFnoECCYQAw&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FParagliding%23%3A~%3Atext%3DParagliding%2520is%2520the%2520recreational%2520and%2Csuspended%2520below%2520a%2520fabric%2520wing.&usg=AOvVaw1Sh2bswupX3y0NL5mc9UVl',
      },
      {
        label: 'Rafting',
        value: 'Rafting',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiUu771gK__AhUflWoFHSAdAl8QFnoECBcQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FRafting&usg=AOvVaw1mV7AsST9foB9tYbg_iJis',
      },
      {
        label: 'Rock climbing',
        value: 'Rock climbing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiSzPT7gK__AhUZkmoFHZBKBRIQFnoECBkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FRock_climbing&usg=AOvVaw12LCgbr9PYloR_GKqaXmgp',
      },
    ],
  },
  {
    label: 'Fitness',
    value: 'Fitness',
    labelImage: 'assets/profile/health/interest-fitness.svg',
    subQuestions: [
      {
        label: 'Calisthenics',
        value: 'Calisthenics',
        infoUrl: '',
      },
      {
        label: 'Circuit training',
        value: 'Circuit training',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwighYWEga__AhXokmoFHUlZDjwQFnoECBMQAw&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCircuit_training%23%3A~%3Atext%3DCircuit%2520training%2520is%2520a%2520form%2Cstrength%2520building%2520and%2520muscular%2520endurance.&usg=AOvVaw2dYquwELKkpHo4yXP3kRNH',
      },
      {
        label: 'Crossfit',
        value: 'Crossfit',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjei6yIga__AhWgmmoFHSOvAOIQFnoECDMQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCrossFit&usg=AOvVaw3dHFUcnYBU8KQL3ikCPmC3',
      },
      {
        label: 'Dance',
        value: 'Dance',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjaop6Nga__AhWVlWoFHU8tCJEQFnoECCMQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FDance&usg=AOvVaw396WVGQgMH_hM6LWpafJUl',
      },
      {
        label: 'HIIT',
        value: 'HIIT',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjZx9eRga__AhUKmWoFHYv2BAoQFnoECBQQAw&url=https%3A%2F%2Fwww.hsph.harvard.edu%2Fnutritionsource%2Fhigh-intensity-interval-training%2F%23%3A~%3Atext%3DHIIT%2520is%2520a%2520type%2520of%2Cperiods%2520of%2520lower%2520intensity%2520movements.&usg=AOvVaw3EcSdamYuPA8DTkhnSHjog',
      },
      {
        label: 'Pilates',
        value: 'Pilates',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwijhZCWga__AhXYkmoFHfRFDE4QFnoECBgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FPilates&usg=AOvVaw0dF9D0MxhYAZZ1tKGCZgyo',
      },
      {
        label: 'Powerlifting',
        value: 'Powerlifting',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvq4mcga__AhVNlmoFHTJVBYgQFnoECA0QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FPowerlifting&usg=AOvVaw0lGbccbao6cwZ392jElDyZ',
      },
      {
        label: 'Running',
        value: 'Running',
        infoUrl: '',
      },
      {
        label: 'Spin',
        value: 'Spin',
        infoUrl: '',
      },
      {
        label: 'Weight-lifting',
        value: 'Weight-lifting',
        infoUrl: '',
      },
      {
        label: 'Swimming',
        value: 'Swimming',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi-mZDDga__AhWykWoFHSF6A4UQFnoECBYQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSwimming_(sport)&usg=AOvVaw1pbmoTeqSap2yYMZIC9pXp',
      },
      {
        label: 'Walking',
        value: 'Walking',
        infoUrl: '',
      },
      {
        label: 'Yoga',
        value: 'Yoga',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjupNnLga__AhXhnGoFHbe2AP0QFnoECAgQAQ&url=https%3A%2F%2Fwww.nytimes.com%2Fguides%2Fwell%2Fbeginner-yoga&usg=AOvVaw3SnbnyNYVmiCgVYjst6w3n',
      },
    ],
  },
  {
    label: 'Cold-weather Sports',
    value: 'Cold-weather Sports',
    labelImage: 'assets/profile/health/interest-winter-sports.svg',
    subQuestions: [
      {
        label: 'Alpine skiing',
        value: 'Alpine skiing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiV1Iqzgq__AhWgl2oFHagABnYQFnoECDQQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FAlpine_skiing&usg=AOvVaw12trK7oKV3AUMVFFUNlczK',
      },
      {
        label: 'Cross-country skiing',
        value: 'Cross-country skiing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiWpOPPg6__AhWGmmoFHf9lDecQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCross-country_skiing&usg=AOvVaw3ieuvFUmC1D-pi-2m4GizW',
      },
      {
        label: 'Curling',
        value: 'Curling',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwic1oqVhK__AhXPpIkEHdTyCgcQFnoECBEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCurling&usg=AOvVaw1cSvsEheWPtARBC0O3Y-j4',
      },
      {
        label: 'Figure skating',
        value: 'Figure skating',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjAivyZhK__AhWnk4kEHc2qDwAQFnoECEYQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFigure_skating&usg=AOvVaw3Wo8J335H3YNx4mjaDi1IH',
      },
      {
        label: 'Freestyle skiing',
        value: 'Freestyle skiing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiw5q2hhK__AhX4hYkEHadbA1kQFnoECDIQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFreestyle_skateboarding&usg=AOvVaw3uIcbH0e0KKZuszwegNc5y',
      },
      {
        label: 'Ice hockey',
        value: 'Ice hockey',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjM5-KohK__AhVYj4kEHQ84BZwQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FIce_hockey&usg=AOvVaw2dy5rdYzJWdnNipr_h08zO',
      },
      {
        label: 'Ice skating',
        value: 'Ice skating',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi7-PashK__AhXxkYkEHUBCBSAQFnoECB8QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FIce_skating&usg=AOvVaw11gaZfHY1dJ_vgfzM8yF_W',
      },
      {
        label: 'Ski jumping',
        value: 'Ski jumping',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiO0NC1hK__AhWdkYkEHdfhD8gQFnoECAkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSki_jumping&usg=AOvVaw187b_zppU4NQE6K4Q1pRA2',
      },
      {
        label: 'Skiing',
        value: 'Skiing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiJyem4hK__AhUFmmoFHQ31BQAQFnoECAsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSkiing&usg=AOvVaw1nVlwLOnAq2H70U60rfIUs',
      },
      {
        label: 'Snowboarding',
        value: 'Snowboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjjn57WhK__AhXJlYkEHcV3Bn4QFnoECAcQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSnowboarding&usg=AOvVaw3l3OkmgBYRebgXnMxKygEF',
      },
      {
        label: 'Speed skating',
        value: 'Speed skating',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjNp9m9hK__AhWFpokEHfsjC9IQFnoECCgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSpeed_skating&usg=AOvVaw0XS_SrXoKXqBiq_2HvaSO9',
      },
    ],
  },
  {
    label: 'Water sports',
    value: 'Water sports',
    labelImage: 'assets/profile/health/interest-water-sports.svg',
    subQuestions: [
      {
        label: 'Bodyboarding',
        value: 'Bodyboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj_6c7ehK__AhUopokEHah0ARwQFnoECCEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBodyboarding&usg=AOvVaw3a9G50u6n27aAAaTT-DgMC',
      },
      {
        label: 'Bodysurfing',
        value: 'Bodysurfing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiyyd3jhK__AhXUkIkEHbuvAP4QFnoECA0QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBodysurfing&usg=AOvVaw3SMI9w94nWjcYys1y0PrH8',
      },
      {
        label: 'Diving',
        value: 'Diving',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjv1siAha__AhXikIkEHdLXChgQFnoECBEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FDiving_(sport)&usg=AOvVaw0jRm30HEiyEHvfTvrZRxMf',
      },
      {
        label: 'Freediving',
        value: 'Freediving',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiw-KWJha__AhU4pIkEHbuIAtIQFnoECA0QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFreediving&usg=AOvVaw2lJnpItggsmf-o1CXNiOed',
      },
      {
        label: 'Kayaking',
        value: 'Kayaking',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjIkqTch6__AhUglmoFHdh1CJYQFnoECDcQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FKayaking&usg=AOvVaw0nafrmJPsTWR2qhpXtJI_8',
      },
      {
        label: 'Kiteboarding',
        value: 'Kiteboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwillM3hh6__AhWAlWoFHb9YBW0QFnoECD8QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FKiteboarding&usg=AOvVaw0CvmwxNbJZzYkr6tQ76GsV',
      },
      {
        label: 'Kneeboarding',
        value: 'Kneeboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj3h4Dnh6__AhW7mmoFHev7AsQQFnoECCsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FKneeboarding_(towsport)&usg=AOvVaw0DHYrwxTFPqRvbRFA_SgQI',
      },
      {
        label: 'Paddleboarding',
        value: 'Paddleboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwixmOTsh6__AhWommoFHYRsAa0QFnoECAsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FPaddleboarding&usg=AOvVaw06PoLoZnbnFRA26OLen6MV',
      },
      {
        label: 'Sailing',
        value: 'Sailing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiCgdXwh6__AhVhnGoFHWrWAEwQFnoECCcQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSailing&usg=AOvVaw3wM8gFzxD2c0Bl_DMo8d7Q',
      },
      {
        label: 'Scuba diving',
        value: 'Scuba diving',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjS-NT0h6__AhWGkWoFHaBmBy8QFnoECBkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FScuba_diving&usg=AOvVaw2WNFH4-L-XucKkO6ugxS1A',
      },
      {
        label: 'Skimboarding',
        value: 'Skimboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjbocv7h6__AhU7lGoFHf8jCY0QFnoECCUQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSkimboarding&usg=AOvVaw3TDSMFU9ewHEyfaj78zlN0',
      },
      {
        label: 'Snorkeling',
        value: 'Snorkeling',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwit0q6BiK__AhUblWoFHazcAVEQFnoECBAQAw&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSnorkeling%23%3A~%3Atext%3DSnorkeling%2520(British%2520and%2520Commonwealth%2520English%2Cwetsuit%2520may%2520also%2520be%2520worn.&usg=AOvVaw0IMmyvgqe4khf0pttx4lYF',
      },
      {
        label: 'Spearfishing',
        value: 'Spearfishing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiauLagiK__AhWmmmoFHQ0sDJwQFnoECAsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSpearfishing&usg=AOvVaw13LcwSQSh5rvDx-CruKCXr',
      },
      {
        label: 'Sport diving',
        value: 'Sport diving',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiOqNykiK__AhWzlWoFHZ2VB-sQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSport_diving_(sport)&usg=AOvVaw2_eEnpXvnmueOshUdCx1pI',
      },
      {
        label: 'Stand-Up Paddleboarding',
        value: 'Stand-Up Paddleboarding',
        infoUrl: '',
      },
      {
        label: 'Surfing',
        value: 'Surfing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjc3JuoiK__AhUbkWoFHSqNBHcQFnoECBEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSurfing&usg=AOvVaw0sf5q5e4MYx1nXUvQnoLyh',
      },
      {
        label: 'Swimming',
        value: 'Swimming',
        infoUrl: '',
      },
      {
        label: 'Synchronized swimming',
        value: 'Synchronized swimming',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwih6bPfkq__AhXblGoFHT39CsYQFnoECCcQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSynchronized_swimming&usg=AOvVaw2-0d7whciAt1FUvnxkhL8j',
      },
      {
        label: 'Wakeboarding',
        value: 'Wakeboarding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjqt_Dmkq__AhWKl2oFHdNtC_4QFnoECCkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FWakeboarding&usg=AOvVaw06ori7PKwxuR893-s_OYYo',
      },
      {
        label: 'Water Polo',
        value: 'Water Polo',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjl5Irskq__AhU7lGoFHf8jCY0QFnoECAsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FWater_polo&usg=AOvVaw1O4CeuzADUtrfm4-gewCii',
      },
      {
        label: 'Water skiing',
        value: 'Water skiing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiu9OLxkq__AhV9k2oFHXDiD3sQFnoECAwQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FWater_skiing&usg=AOvVaw2CUNB9xMW7ZwWS6JJlIopz',
      },
      {
        label: 'Windsurfing',
        value: 'Windsurfing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjTmZb3kq__AhUymWoFHS7wC9QQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FWindsurfing&usg=AOvVaw0uyBpEx6W5Bdh0QBiM8jrh',
      },
    ],
  },
  {
    label: 'Sports I',
    value: 'Sports I',
    labelImage: 'assets/profile/health/interest-sports-I.svg',
    subQuestions: [
      {
        label: 'Baseball',
        value: 'Baseball',
        infoUrl: '',
      },
      {
        label: 'Basketball',
        value: 'Basketball',
        infoUrl: '',
      },
      {
        label: 'Boxing',
        value: 'Boxing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjv-L7lk6__AhWKnWoFHb6DA14QFnoECBoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBoxing&usg=AOvVaw1PJFIcKmpbYE9M92Cd3yHP',
      },
      {
        label: 'Cheerleading',
        value: 'Cheerleading',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi_qPjtk6__AhXIlGoFHcmXD9cQFnoECBQQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCheerleading&usg=AOvVaw3FRXCJjx3wzIawuQx6opoB',
      },
      {
        label: 'Cross-country',
        value: 'Cross-country',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj_rPTyk6__AhUwkWoFHVLdCOcQFnoECC4QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCross_country_running&usg=AOvVaw2r6Q7fyIc-FHg1Krq_JqLA',
      },
      {
        label: 'Decathlon',
        value: 'Decathlon',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwibs_SBlK__AhVglWoFHRJ3DPIQFnoECDIQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FDecathlon&usg=AOvVaw2ZCA8mLk2ISikwfn8_Vu0S',
      },
      {
        label: 'Discus',
        value: 'Discus',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjkzfeFlK__AhWRnGoFHb3BAmsQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FDiscus_throw&usg=AOvVaw3fozswBVPVBtG_MSkNhayH',
      },
      {
        label: 'Fencing',
        value: 'Fencing',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvxcSKlK__AhXkl2oFHek2DzIQFnoFCJkBEAE&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFencing&usg=AOvVaw1dRpUbC09ZOVTXVWNTfScw',
      },
      {
        label: 'Field hockey',
        value: 'Field hockey',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjSo4CPlK__AhUelmoFHUYHAMUQFnoECAkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FField_hockey&usg=AOvVaw1gdjr82s6pvTJRP7oTBpLQ',
      },
      {
        label: 'Football (American)',
        value: 'Football (American)',
        infoUrl: '',
      },
      {
        label: 'Golf',
        value: 'Golf',
        infoUrl: '',
      },
      {
        label: 'Gymnastics',
        value: 'Gymnastics',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiMka-qlK__AhUfnWoFHd53ApgQFnoECCcQAQ&url=https%3A%2F%2Fwww.adult-gymnastics.com%2Fhow-to-teach-yourself-gymnastics.html&usg=AOvVaw3a-TMLbRnuhBtjai4PFSfk',
      },
      {
        label: 'Lacrosse',
        value: 'Lacrosse',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjg1tC2lK__AhXokmoFHUlZDjwQFnoECAwQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FLacrosse&usg=AOvVaw3yQX6_8pZ3-24YQ2L3aUuj',
      },
      {
        label: 'Martial Arts',
        value: 'Martial Arts',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwik_rq6lK__AhVmkmoFHVb9CrgQFnoECAkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FMartial_arts&usg=AOvVaw1GW4gzAuACN8UwG4-nHPGf',
      },
      {
        label: 'Rugby',
        value: 'Rugby',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjDmOzElK__AhVEm2oFHTv9DE8QFnoECFAQAQ&url=https%3A%2F%2Fwww.britannica.com%2Fsports%2Frugby&usg=AOvVaw3ba-h5uzL8-Wuqhs8gjlQX',
      },
      {
        label: 'Shotput',
        value: 'Shotput',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwii_57MlK__AhVvkmoFHQTFCqYQFnoECA4QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FShot_put&usg=AOvVaw202uWTDr2KdiDBzUE4DPc6',
      },
      {
        label: 'Skateboarding',
        value: 'Skateboarding',
        infoUrl: '',
      },
      {
        label: 'Soccer (football)',
        value: 'Soccer (football)',
        infoUrl: '',
      },
      {
        label: 'Softball',
        value: 'Softball',
        infoUrl: '',
      },
      {
        label: 'Tennis',
        value: 'Tennis',
        infoUrl: '',
      },
      {
        label: 'Track-and-field',
        value: 'Track-and-field',
        infoUrl: '',
      },
      {
        label: 'Trapshooting',
        value: 'Trapshooting',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi89oXZlK__AhVpgGoFHW3LAP0QFnoECBAQAQ&url=https%3A%2F%2Fshootata.com%2FGeneral-Information%2FTrapshooting-Overview&usg=AOvVaw3IwfYuyKKMAUh8ua9aT6gI',
      },
      {
        label: 'Volleyball',
        value: 'Volleyball',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjlit3flK__AhXqmWoFHUX3DU8QFnoECCAQAQ&url=https%3A%2F%2Folympics.com%2Fen%2Fsports%2Fvolleyball%2F&usg=AOvVaw1xeKEQOW4oMKTPXsE5awon',
      },
      {
        label: 'Wrestling',
        value: 'Wrestling',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiakPLllK__AhWAkWoFHYLBAvAQFnoECBAQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FWrestling&usg=AOvVaw0QW-f1dwGhnyYkQS3MRzaV',
      },
    ],
  },
  {
    label: 'Sport II',
    value: 'Sport II',
    labelImage: 'assets/profile/health/interest-sports-II.svg',
    subQuestions: [
      {
        label: 'Acrobatics',
        value: 'Acrobatics',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjq3uSQla__AhXjnGoFHbeEDMMQFnoECCUQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FAcrobatics&usg=AOvVaw0Sa94QL56uNt7eP1yVgy8c',
      },
      {
        label: 'Archery',
        value: 'Archery',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjQlLOVla__AhWWlWoFHayXCUkQFnoECBYQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FArchery&usg=AOvVaw2r40UZmJQgGDDzfuA1Xh-d',
      },
      {
        label: 'Australian rules football',
        value: 'Australian rules football',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi8wcKbla__AhWunWoFHS93AzIQFnoECAgQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FAustralian_rules_football&usg=AOvVaw0jNC3-6hssq53wK1VGxTql',
      },
      {
        label: 'Badminton',
        value: 'Badminton',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi28aufla__AhXYl2oFHeXSB18QFnoECBAQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBadminton&usg=AOvVaw1ip45PiONe10yoecZil38G',
      },
      {
        label: 'Biathlon',
        value: 'Biathlon',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi--5b7qrH_AhXWlWoFHTGBBScQFnoECAwQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBiathlon&usg=AOvVaw0PIAirTkl7ZhljzZ2lihU7',
      },
      {
        label: 'Bowling',
        value: 'Bowling',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvzJKyrbH_AhUBmGoFHbSOD8gQFnoECEUQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FBowling&usg=AOvVaw1DBxOI821IyFyOC5adq0vN',
      },
      {
        label: 'Cricket',
        value: 'Cricket',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjDj-m9rbH_AhUTm2oFHYhECCsQFnoECAkQAQ&url=https%3A%2F%2Fwww.cricket-rules.com%2F&usg=AOvVaw2mUqonvXNWeEKEpynBe2r2',
      },
      {
        label: 'Cycling',
        value: 'Cycling',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiYh-HDrbH_AhVRkmoFHUFMBRAQFnoECAkQAw&url=https%3A%2F%2Fstagescycling.com%2Fen_us%2Fcontent%2Fa-beginners-guide-to-road-biking%23%3A~%3Atext%3DStart%2520by%2520riding%252030%2520to%2Ccycling%2520group%2520or%2520group%2520ride.&usg=AOvVaw0EMhjK_NsnnBmRaclpNkPq',
      },
      {
        label: 'Handball',
        value: 'Handball',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiVkJTOrbH_AhW9mGoFHd3_AHUQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHandball&usg=AOvVaw2aeTXs26qspNZJRkociy1v',
      },
      {
        label: 'Heptathlon',
        value: 'Heptathlon',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwja2YTVrbH_AhU_lGoFHf7NBgsQFnoECCIQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHeptathlon&usg=AOvVaw1sAjCBDMPcEfhOkCT-qgPc',
      },
      {
        label: 'Horseback riding',
        value: 'Horseback riding',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwji8K_erbH_AhWflWoFHWezBVUQFnoECAsQAw&url=https%3A%2F%2Fequinehelper.com%2Fhow-to-start-horseback-riding%2F%23%3A~%3Atext%3DSo%252C%2520how%2520do%2520you%2520get%2Ca%2520good%2520fit%2520for%2520you.&usg=AOvVaw0NEMgnxTQSpX5DFEuTOK3R',
      },
      {
        label: 'Hurling',
        value: 'Hurling',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi-xIDmrbH_AhXmk2oFHXQ0C3oQFnoECBEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FHurling&usg=AOvVaw3Uo7HSG3ssXerd4E7Lpg2R',
      },
      {
        label: 'Jai alai',
        value: 'Jai alai',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjct5zrrbH_AhVKm2oFHU5VBn8QFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FJai_alai&usg=AOvVaw17wUhadhBGwNdUUWDuMlgq',
      },
      {
        label: 'Long-distance running',
        value: 'Long-distance running',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiIw97zrbH_AhW1lGoFHZYSB5oQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FLong-distance_running&usg=AOvVaw2LtuQUFEtRWOJrhVXrTT9v',
      },
      {
        label: 'Paddle tennis',
        value: 'Paddle tennis',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjC7tj4rbH_AhUJkWoFHTf_BNYQFnoECAwQAQ&url=https%3A%2F%2Fwww.epiruslondon.com%2Fes%2Fblogs%2Ftennis-hacks-blog%2Fwhat-is-padel-paddle-tennis-platform-tennis-and-what-are-the-differences&usg=AOvVaw3qgM_EKM9MzGbtCVxTpzgG',
      },
      {
        label: 'Paintball',
        value: 'Paintball',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjPg5GerrH_AhUWlWoFHZaqBOIQFnoECBkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FPaintball&usg=AOvVaw08EDAx25fP_CVrSTCnx5_1',
      },
      {
        label: 'Pall-mall',
        value: 'Pall-mall',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjEtoWqrrH_AhUUkWoFHVmPAEcQFnoECBEQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FPall-mall&usg=AOvVaw2aKBqyhyYcgpd5Xq9-o85b',
      },
      {
        label: 'Pelota',
        value: 'Pelota',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwionqGxrrH_AhWrlGoFHfDdC4sQFnoECDQQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FPelota&usg=AOvVaw0jFHc-MxEzAs4ZNP4-QOI_',
      },
      {
        label: 'Pickleball',
        value: 'Pickleball',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjlxcO2rrH_AhUMlGoFHZ88BogQFnoECA0QAQ&url=https%3A%2F%2Fusapickleball.org%2Fwhat-is-pickleball%2F&usg=AOvVaw0atSoe0UX0rzSDC0WrRoww',
      },
      {
        label: 'Racing Cars',
        value: 'Racing Cars',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjj3Ny6rrH_AhWIl2oFHa5_BukQFnoECBgQAQ&url=https%3A%2F%2Fwww.themanual.com%2Fauto%2Ftypes-of-car-racing%2F&usg=AOvVaw2XNZ4ZoULgRHJjaJnwx3KP',
      },
      {
        label: 'Rackets',
        value: 'Rackets',
        infoUrl: '',
      },
      {
        label: 'Racquetball',
        value: 'Racquetball',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiv2bDErrH_AhVzkmoFHe_dCLYQFnoECC8QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FRacquetball&usg=AOvVaw18NehftUbRhdWywiQS0LBS',
      },
      {
        label: 'Roller-skating',
        value: 'Roller-skating',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjerZXJrrH_AhVxk2oFHXJZDoEQFnoECD4QAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FRoller_skating&usg=AOvVaw0ai2-WvGAducQ0gQsixsTp',
      },
      {
        label: 'Rounders',
        value: 'Rounders',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjmtdTOrrH_AhVzkmoFHenaB4MQFnoECBYQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FRounders&usg=AOvVaw0NuFSK3Lid8aRSJZmv7HGx',
      },
      {
        label: 'Shuffleboard',
        value: 'Shuffleboard',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiW8OeDr7H_AhUtmGoFHVelC9gQFnoECBkQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FShuffleboard&usg=AOvVaw1fz3E-WOwPKtoWsvk9-pYk',
      },
      {
        label: 'Squash',
        value: 'Squash',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiq_8aIr7H_AhWAm2oFHQxgBGMQFnoECAsQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSquash_(sport)&usg=AOvVaw0KXTfyXQoCv0fWYNLVrR-g',
      },
      {
        label: 'Steeplechase',
        value: 'Steeplechase',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjb4fWMr7H_AhXWlWoFHTGBBScQFnoECAwQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSteeplechase_(athletics)&usg=AOvVaw3SPm8ZQdDshppwBMU0i_nZ',
      },
      {
        label: 'Table tennis',
        value: 'Table tennis',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjsg9eSr7H_AhXhkmoFHWGhCO0QFnoECAwQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FTable_tennis&usg=AOvVaw1yKmOK4-atD2P0Xa0URTJe',
      },
      {
        label: 'Triathlon',
        value: 'Triathlon',
        infoUrl: 'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwicodCXr7H_AhWxlGoFHcGND9kQFnoECAoQAQ&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FTriathlon&usg=AOvVaw3zSzGd9utroeHnjETkF2lG',
      },
    ],
  },
];
