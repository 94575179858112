/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { App } from '@capacitor/app';

let inactiveTimeout = null;
// timeout after an hour
const inactiveTimeoutThreshold = 60 * 60 * 1000;

function useAppListener(key) {
  const keyRef = useRef(null);
  const [isActive, setIsActive] = useState(true);

  const getAppIsActive = async () => {
    const state = await App.getState();
    return state.isActive;
  };
  useEffect(() => {
    if (key !== keyRef.current) {
      App.addListener('appStateChange', ({ isActive: active }) => {
        clearTimeout(inactiveTimeout);
        if (isActive) {
          // current state is active
          if (!active) {
            inactiveTimeout = setTimeout(() => {
              setIsActive(false);
            }, inactiveTimeoutThreshold);
          } else {
            // noop, we're already active
          }
        } else {
          // current state is inactive
          if (active) {
            setIsActive(true);
          } else {
            // noop, we're already inactive
          }
        }

        keyRef.current = key;
      });
    }

    return () => {
      clearTimeout(inactiveTimeout);
    };
  }, [key]);

  return { isActive, getAppIsActive };
}

// eslint-disable-next-line import/prefer-default-export
export default useAppListener;
