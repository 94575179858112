export const muiComponentOverrideStyles = {
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: '#FD5C36',
        width: '64px',
        height: '3px',
      },
      flexContainer: {
        justifyContent: 'center',
        padding: '0 40px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
        },
        textTransform: 'capitalize',
        width: '64px',
        minWidth: '64px',
        padding: '0 0 8px 0',
        justifyContent: 'end',
        color: '#03295F',
        fontWeight: 700,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: '#063D8F',
        },
      },
    },
  },
};

export const buttonNoSelectStyles = {
  WebkitTapHighlightColor: 'transparent',
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  outline: 'none !important',
  backgroundColor: 'inherit',
};
