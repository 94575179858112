import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'use-http';
import { useHistory } from 'react-router';
import { Stack, Typography, Box } from '@mui/material';
import BaseModal from '../../../../common/atoms/BaseModal/BaseModal';
import BackrsButton from '../../../../common/atoms/backrsButton/BackrsButton';
import appStrings from '../../../../common/app_strings';

const successColor = '#2CB669';
const errorColor = '#FF0000';
const defaultColor = '#03295F';

const {
  profile: {
    backingModal: {
      ctaLabelBuilder,
      modalTitleBuilder,
      modalListData,
      topBody,
      errorState: { errorModalListData, topBodyError, contactLubavLabel },
      pendingState: { backgroundCheckPendingList, topBodyPending, statusButtonLabel },
    },
  },
} = appStrings;

const styles = {
  modalContainer: {
    width: '300px',
    height: '275px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: '20px',
  },
  modalTitle: {
    color: '#03295F',
    fontSize: 22,
    fontWeight: 800,
    textAlign: 'center',
  },
  modalContent: {
    padding: '15px',
    lineHeight: 1.4,
  },
  modalText: {
    fontSize: 13,
  },
  actionButton: {
    height: '42px',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'currentColor',
    },
    '&:focus': {
      backgroundColor: 'currentColor',
    },
  },
  stack: {
    mx: '10%',
    textAlign: 'center',
    width: '80%',
    position: 'absolute',
    bottom: '25px',
  },
};

const BackingModal = ({
  meta, isOpen, handleClose, onSuccess,
}) => {
  const history = useHistory();
  const { subjectUserId, profile, accessorUserId } = meta;
  const protegeFirstName = profile?.first_name;

  const { post, response } = useFetch();
  const { post: postCommunitySid, response: communitySidRes } = useFetch(`/conversations/request-sid/${accessorUserId}/community`);

  const [isLoading, setIsLoading] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const bgCheckStatusRef = useRef(null);

  const handleAdminSupportRoute = async () => {
    try {
      const res = await postCommunitySid();
      if (communitySidRes.ok && res?.sid) {
        history.push(`/chat/${res.sid}`);
      } else {
        throw new Error('Community conversation request fail');
      }
    } catch (error) {
      console.error('Conversation Request Error: ', error);
    }
  };

  const handleBackgroundCheckStatusRoute = () => {
    history.push('/background-status-meter');
  };

  const initiateMatchRun = async () => {
    const url = '/matching/match-run/request-protege/trigger';

    try {
      setIsLoading(true);
      const res = await post(url, { user_id: accessorUserId, protege_id: subjectUserId });

      if (response.status === 200 && res?.background_check_status === 'completed') {
        await onSuccess();
        handleClose();
        return;
      }

      if (response.status === 200) {
        const bgCheckStatus = res?.background_check_status ? res?.background_check_status : 'unknown';
        bgCheckStatusRef.current = bgCheckStatus;
        await onSuccess();
        return;
      }

      setMatchError(true);
      throw new Error('Backing dialog error');
    } catch (error) {
      console.log('Backing dialog error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalText = (data) => (
    <ul className="modalTextList">
      {(data).map((item) => (
        <li key={item}>
          {' '}
          <Typography
            key={item}
            sx={{
              color: '#2d2d2d',
              fontSize: 14,
            }}
          >
            {item}
          </Typography>
        </li>
      ))}
    </ul>
  );

  const getDialogMeta = () => {
    if (matchError) {
      return {
        onClick: handleAdminSupportRoute,
        label: contactLubavLabel,
        body: errorModalListData,
        color: errorColor,
        headerData: topBodyError,
      };
    }

    if (bgCheckStatusRef.current === 'pending' || bgCheckStatusRef.current === 'required') {
      return {
        onClick: handleBackgroundCheckStatusRoute,
        label: statusButtonLabel,
        body: backgroundCheckPendingList,
        color: defaultColor,
        headerData: topBodyPending,
      };
    }

    return {
      onClick: initiateMatchRun,
      label: ctaLabelBuilder(protegeFirstName),
      body: modalListData,
      color: successColor,
      headerData: topBody,
    };
  };

  useEffect(() => {
    if (matchError) {
      getDialogMeta();
    }
  }, [matchError]);

  const dialogMeta = getDialogMeta();
  const modalTitle = modalTitleBuilder(protegeFirstName);

  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose}>
      <Box sx={styles.modalContainer}>
        <Stack>
          <Typography sx={styles.modalTitle}>
            {modalTitle}
          </Typography>
        </Stack>
        <Box sx={styles.modalContent}>
          <Typography sx={styles.modalText}>
            {dialogMeta.headerData}
          </Typography>
          <Box sx={styles.modalText}>
            {dialogMeta.body ? renderModalText(dialogMeta.body) : null}
          </Box>
        </Box>
        <Stack sx={styles.stack}>
          <BackrsButton
            buttonStylesOverride={{
              height: '42px',
              fontSize: '16px',
              fontWeight: 700,
              cursor: 'pointer',
              backgroundColor: dialogMeta.color,
              '&:hover': {
                backgroundColor: dialogMeta.color,
              },
              '&:focus': {
                backgroundColor: dialogMeta.color,
              },
            }}
            onClick={dialogMeta.onClick}
            label={dialogMeta.label}
            isLoading={isLoading}
          />
        </Stack>
      </Box>
    </BaseModal>
  );
};

BackingModal.propTypes = {
  meta: PropTypes.shape({
    subjectUserId: PropTypes.number.isRequired,
    profile: PropTypes.oneOfType([PropTypes.object]).isRequired,
    accessorUserId: PropTypes.number.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

BackingModal.defaultProps = {
  isOpen: false,
  handleClose: () => { },
};

export default BackingModal;
