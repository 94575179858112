import React from 'react';
import SimpleMultiSelectPage from 'pages/wholeme/molecules/simpleMultiSelectPage/SimpleMultiSelectPage';
import { healthCredentialsData } from './healthCredentialsData';
import { multiSelectDataFetcherAdapter, multiSelectDataSaverAdapter } from '../../../util/profile_util';

const ProfileIdentityIntakeAffinityPage = ({
  paginationMeta,
  nextOnClick,
  prevOnClick,
  wholeMeClient,
}) => (
  <SimpleMultiSelectPage
    fields={healthCredentialsData}
    itemVariant="rectangle"
    isTwoColumns
    paginationMeta={paginationMeta}
    nextOnClick={nextOnClick}
    prevOnClick={prevOnClick}
    title="Health Credentials"
    subtitle="Check any of the following that apply to you."
    categoryId="health"
    questionId="health_credentials"
    dataFetcher={multiSelectDataFetcherAdapter(wholeMeClient.fetchHealthCredentialsState)}
    dataSaver={multiSelectDataSaverAdapter(wholeMeClient.saveHealthCredentialsState)}
    backgroundOverlay="/assets/profile/health/health-credentials-overlay.svg"
  />
);

export default ProfileIdentityIntakeAffinityPage;
