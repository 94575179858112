/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  IonIcon, IonButton, useIonActionSheet, IonImg, IonCard,
} from '@ionic/react';
import PropTypes from 'prop-types';
import useFetch from 'use-http';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import Linkify from 'linkify-react';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../providers/auth-provider';
import { displayName, linkifyOpts } from '../helpers/textManipulation';
import {
  getActionButtons,
  authorIsUser,
} from '../helpers/comments';
import AvatarRole from './AvatarRole';
import RoleIndicator from './RoleIndicator';
import AvatarAdmin from './AvatarAdmin';
import VideoPlayer from './VideoPlayer';
import PdfIndicator from '../common/atoms/pdfIndicator/PdfIndicator';
import { renderCommentBody } from '../helpers/media';

const imgStyle = {
  borderRadius: '10px !important',
  padding: 0,
  margin: '1rem 0 0 0',
};

const Comment = ({
  comment,
  setReplyTo,
  deleteComment,
  reportComment,
  expand,
  resetReplyTo,
}) => {
  const initialLikeMap = new Map();
  const buildLikeMap = (topComment) => {
    const likeData = {
      like_id: topComment.like_id,
      numberOfLikes: topComment.numberOfLikes,
    };
    initialLikeMap.set(topComment.id, likeData);
    if (topComment.comments?.length > 0) {
      topComment.comments.map((childComment) => buildLikeMap(childComment));
    }
  };
  buildLikeMap(comment);

  const [likeMap, setLikeMap] = useState(initialLikeMap);
  const [loadingLike, setLoadingLike] = useState(false);
  const [initialCommentCount] = useState(comment?.comments?.length || 0);
  const [showComments, setShowComments] = useState(false);
  const [selectedComment, setSelectedComment] = useState(comment);
  const [actionSheetResult, setActionSheetResult] = useState(null);
  const { user } = useAuthContext();
  const history = useHistory();
  const [present] = useIonActionSheet();
  const { post: postLike, delete: deleteLike } = useFetch();
  const userRoute = `/profile/${comment?.author_id}`;
  const adminRoute = `/profile/${comment?.author_id}?role=admin`;

  useEffect(() => {
    if (comment?.comments?.length > initialCommentCount) {
      setShowComments(true);
    }
  }, [comment?.comments?.length]);

  const handleAction = (action) => {
    switch (action) {
      case 'reply':
        setReplyTo({ data: selectedComment, type: 'comment' });
        break;
      case 'report':
        reportComment(selectedComment?.id);
        break;
      case 'delete':
        deleteComment(selectedComment);
        break;
      default:
        resetReplyTo();
    }
  };

  const presentActionSheet = (buttons) => {
    resetReplyTo();
    present({
      buttons,
      onDidDismiss: ({ detail }) => setActionSheetResult(detail),
    });
  };

  const handlePresentActionSheet = ({ comment: _comment, isSubComment }) => {
    const isAuthor = authorIsUser(user, _comment);

    // short circuit presentAction
    //  * users can't reply to sub comments
    //  * author can't self report
    const actionSheetButtons = getActionButtons({
      showReplyAction: !isSubComment,
      showDeleteAction: isAuthor,
      showReportAction: !isAuthor,
    });

    setSelectedComment(_comment);
    presentActionSheet(actionSheetButtons);
  };
  const handleCommentLike = async (likedCommentId) => {
    const getLikeId = likeMap.get(likedCommentId)?.like_id;
    if (loadingLike) {
      return;
    }
    setLoadingLike(true);
    try {
      if (!getLikeId) {
        const likePayload = {
          user_id: user.id,
          comment_id: likedCommentId,
        };
        const { id } = await postLike('/likes', likePayload);
        setLikeMap(likeMap.set(likedCommentId, {
          like_id: id,
          numberOfLikes: (likeMap.get(likedCommentId)?.numberOfLikes || 0) + 1,
        }));
      } else {
        deleteLike(`/likes/${getLikeId}`);
        const oldLikeCount = likeMap.get(comment.id)?.numberOfLikes || 0;
        const newLikeCount = oldLikeCount > 1 ? oldLikeCount - 1 : 0;
        setLikeMap(likeMap.set(likedCommentId, {
          like_id: null,
          numberOfLikes: newLikeCount,
        }));
      }
    } catch (e) {
      console.error(e);
    }
    setLoadingLike(false);
  };

  const renderCommentMedia = (comm) => {
    if (comm?.attachments[0]?.s3_key.startsWith('images')) {
      return (
        <IonCard style={imgStyle}>
          <IonImg src={comm?.attachments[0]?.uri} />
        </IonCard>
      );
    }
    if (comm?.attachments[0]?.s3_key.startsWith('videos')) {
      return (
        <div className="post-image">
          <VideoPlayer videos={comm?.attachments[0]?.transcoded_videos} />
        </div>
      );
    }
    if (comm?.attachments[0]?.s3_key.startsWith('pdfs')) {
      return (
        <PdfIndicator attachment={comm.attachments[0]} overrideStyle={{ margin: '.5rem 0 0 0' }} />
      );
    }
    return null;
  };

  React.useEffect(() => {
    if (actionSheetResult?.data) {
      const {
        data: { action },
      } = actionSheetResult;
      handleAction(action);
    }
  }, [actionSheetResult]);

  // TODO: need to refactor + put styles into css
  if (expand) {
    const likeCount = likeMap.get(comment.id).numberOfLikes;
    const likeId = likeMap.get(comment.id).like_id;
    return (
      <Box className="comment-container comment-post-caption comment-unpad">
        <Box
          className="d-flex"
          onClick={() => handlePresentActionSheet({ comment, isSubComment: false })}
        >
          {comment?.author?.role === 'admin'
            ? <AvatarAdmin style={{ marginRight: '1rem' }} />
            : (
              <AvatarRole
                unsetTopMargin
                user={comment?.author}
                style={{ marginRight: '1rem' }}
              />
            )}
          <div style={{ width: '100%' }}>
            <Linkify
              className="comment-caption"
              tagName="p"
              options={linkifyOpts}
            >
              <span
                role="button"
                tabIndex={0}
                className="comment-name"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(comment?.author?.role === 'admin' ? adminRoute : userRoute);
                }}
              >
                {comment?.author?.role !== 'admin'
                  && comment.author.first_name
                  && displayName(
                    comment?.author?.first_name,
                    comment?.author?.last_name,
                  )}
                {comment?.author?.role !== 'admin'
                  && comment?.author_id === user?.id
                  && !comment.author.first_name
                  && 'Me '}
                {comment?.author?.role !== 'admin'
                  && comment?.author_id !== user?.id
                  && !comment.author.first_name
                  && `User ${comment?.author_id} `}

                {comment?.author?.role === 'admin' && 'Coach Lubav '}
              </span>
              {renderCommentBody(comment)}
              <br />
              {renderCommentMedia(comment)}
            </Linkify>
            <span className="comment-post-time">
              {formatDistanceToNowStrict(parseISO(comment?.created_at), {
                addSuffix: true,
              })}
            </span>
            {likeCount > 0 && (
              <span className="comment-like-count">
                {likeCount === 1 ? '1 like' : `${likeCount} likes`}
              </span>
            )}
            <span
              role="button"
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
                setReplyTo({ data: comment, type: 'comment' });
              }}
              style={{
                color: 'var(--ion-color-warning)',
                fontSize: '0.625rem',
                marginLeft: '8px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              Reply
            </span>
          </div>
          <IonButton
            aria-label="Comment like button"
            style={{
              position: 'relative',
              bottom: '0.9rem',
              '--padding-end': '0',
            }}
            fill="clear"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              // call with the parent comment's id
              // se if we get the comment form id
              handleCommentLike(comment.id);
            }}
          >
            <IonIcon
              style={{
                color: `${
                  // look up the like id of the comment from data structure (A)
                  likeId ? 'var(--ion-color-warning)' : 'rgba(45, 45, 45, 0.42)'
                }`,
                minWidth: '13px',
                minHeight: '13px',
                paddingTop: '20px',
                marginBottom: '8px',
              }}
              src={
                likeId
                  ? 'assets/icon/heart.svg'
                  : 'assets/icon/heartOutline.svg'
              }
            />
          </IonButton>
        </Box>
        <Box>
          <Box style={{ padding: 0, margin: '4px 0 0 56px' }}>
            {comment?.comments && comment?.comments?.length > 1 ? (
              <Box>
                <span
                  style={{
                    display: 'block',
                    width: 'fit-content',
                    fontSize: '0.625rem',
                  }}
                  role="button"
                  tabIndex={0}
                  onClick={() => setShowComments(!showComments)}
                >
                  &mdash;
                  {showComments
                    ? ' Hide replies'
                    : ` View replies (${comment?.comments?.length})`}
                </span>
              </Box>
            ) : null}
            {showComments || comment?.comments?.length === 1 ? (
              <Box>

                {comment?.comments?.map((subComment) => {
                  const getLikeCount = likeMap.get(subComment.id)?.numberOfLikes;
                  const getlikeId = likeMap.get(subComment.id)?.like_id;
                  return (
                    <Box
                      className="comment-wrapper"
                      key={subComment.id}
                      onClick={() => handlePresentActionSheet({
                        comment: subComment, isSubComment: true,
                      })}
                    >

                      {subComment?.author?.role !== 'admin' && (

                      <AvatarRole
                        unsetTopMargin
                        subComment
                        user={subComment?.author}
                        style={{
                          marginRight: '0.5rem',
                        }}
                      />
                      )}
                      {subComment?.author?.role === 'admin' && (
                      <AvatarAdmin
                        unsetTopMargin
                        subComment
                        user={subComment?.author}
                        style={{
                          marginRight: '0.5rem',
                        }}
                      />
                      )}
                      <div>
                        {subComment?.author?.role === 'admin' && (
                        <Linkify className="comment-caption" tagName="div" options={linkifyOpts}>
                          <h3
                            style={{
                              fontSize: 'inherit',
                              display: 'inline-flex',
                              margin: 0,
                              marginRight: '6px',
                              color: '#063d8f',
                              fontWeight: 700,
                            }}
                          >
                            Coach Lubav
                            {' '}
                          </h3>
                          {subComment?.body}
                        </Linkify>
                        )}
                        {subComment?.author?.role !== 'admin' && (
                        <Linkify
                          className="comment-caption"
                          tagName="div"
                          options={linkifyOpts}
                        >
                          <span
                            role="button"
                            tabIndex={0}
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(`/profile/${subComment.author_id}`);
                            }}
                          >
                            <h3
                              style={{
                                color: '#063d8f',
                                fontSize: 'inherit',
                                display: 'inline-flex',
                                margin: 0,
                                marginRight: '6px',
                              }}
                            >
                              {subComment?.author?.first_name
                                && displayName(
                                  subComment?.author?.first_name,
                                  subComment?.author?.last_name,
                                )}
                              {subComment?.author_id === user?.id
                                && !subComment?.author?.first_name
                                && 'Me '}
                              {subComment?.author_id !== user?.id
                                && !subComment?.author?.first_name
                                && `User ${subComment?.author_id} `}
                            </h3>
                          </span>
                          {renderCommentBody(subComment)}
                        </Linkify>
                        )}
                        {renderCommentMedia(subComment)}
                        <div>
                          <span className="comment-post-time">
                            {formatDistanceToNowStrict(
                              parseISO(subComment?.created_at),
                              {
                                addSuffix: true,
                              },
                            )}
                          </span>
                          {getLikeCount > 0 && (
                          <span className="comment-like-count">
                            {getLikeCount === 1 ? '1 like' : `${getLikeCount} likes`}
                          </span>
                          )}
                          <span
                            role="button"
                            tabIndex={0}
                            onClick={(e) => {
                              e.stopPropagation();
                              setReplyTo({ data: subComment, topLevelComment: comment, type: 'subComment' });
                            }}
                            style={{
                              color: 'var(--ion-color-warning)',
                              fontSize: '0.625rem',
                              marginLeft: '8px',
                              fontWeight: 'bold',
                            }}
                          >
                            Reply
                          </span>
                        </div>
                      </div>
                      <IonButton
                        aria-label="Comment like button"
                        style={{
                          position: 'relative',
                          bottom: '0.9rem',
                          '--padding-end': '0',
                        }}
                        fill="clear"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          // call with the parent comment's id
                          // se if we get the comment form id
                          handleCommentLike(subComment.id);
                        }}
                      >
                        <IonIcon
                          style={{
                            color: `${
                              // look up the like id of the comment from data structure (A)
                              getlikeId ? 'var(--ion-color-warning)' : 'rgba(45, 45, 45, 0.42)'
                            }`,
                            minWidth: '13px',
                            minHeight: '13px',
                            paddingTop: '20px',
                            marginBottom: '8px',
                          }}
                          src={
                getlikeId
                  ? 'assets/icon/heart.svg'
                  : 'assets/icon/heartOutline.svg'
              }
                        />
                      </IonButton>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  }
  const likeId = likeMap.get(comment.id).like_id;
  return (
    <div className="post-comment">
      <RoleIndicator
        userRole={comment?.author?.role}
        style={{
          minWidth: '16px',
          minHeight: '16px',
          marginRight: '0.5rem',
        }}
      />
      <Linkify tagName="p" options={linkifyOpts}>
        <span className="post-comment-name">
          {comment?.author?.role !== 'admin'
            && comment?.author?.first_name
            && displayName(
              comment?.author?.first_name,
              comment?.author?.last_name,
            )}
          {comment?.author?.role !== 'admin'
            && comment?.author_id === user?.id
            && !comment?.author?.first_name
            && 'Me '}
          {comment?.author?.role !== 'admin'
            && comment?.author_id !== user?.id
            && !comment?.author?.first_name
            && `User ${comment?.author_id} `}

          {comment?.author?.role === 'admin' && 'Coach Lubav '}
        </span>
        {comment?.body}
      </Linkify>
      <IonButton
        aria-label="Comment like button"
        fill="clear"
        size="small"
        style={{
          position: 'relative',
          top: '4px',
          '--padding-bottom': '0.9em',
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleCommentLike(comment.id);
        }}
      >
        <IonIcon
          style={{
            color: `${
              likeId ? 'var(--ion-color-warning)' : 'rgba(45, 45, 45, 0.42)'
            }`,
            minWidth: '13px',
            minHeight: '13px',
          }}
          src={
            likeId ? 'assets/icon/heart.svg' : 'assets/icon/heartOutline.svg'
          }
          className="post-comment-like"
        />
      </IonButton>
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setReplyTo: PropTypes.func,
  deleteComment: PropTypes.func,
  resetReplyTo: PropTypes.func,
  reportComment: PropTypes.func,
  expand: PropTypes.bool,
};

Comment.defaultProps = {
  setReplyTo: () => {},
  deleteComment: () => {},
  resetReplyTo: () => {},
  reportComment: () => {},
  expand: false,
};

export default Comment;
