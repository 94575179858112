import appStrings from '../common/app_strings';

const {
  comments: {
    photo, video, addPhoto, addVideo,
  },
} = appStrings;

const reportActionButton = {
  text: 'Find Support or Report Comment',
  role: 'destructive',
  data: {
    action: 'report',
  },
};

const deleteActionButton = {
  text: 'Delete Comment',
  role: 'destructive',
  data: {
    action: 'delete',
  },
};

const cancelActionButton = {
  text: 'Cancel',
  role: 'cancel',
  data: {
    action: 'cancel',
  },
};

const replyActionButton = {
  text: 'Reply',
  data: {
    action: 'reply',
  },
};

const photoActionButton = {
  text: addPhoto,
  data: {
    action: photo,
  },
};

const videoActionButton = {
  text: addVideo,
  data: {
    action: video,
  },
};

export const getActionButtons = ({
  showReplyAction,
  showDeleteAction,
  showReportAction,
}) => [
  ...(showReplyAction ? [replyActionButton] : []),
  ...(showDeleteAction ? [deleteActionButton] : []),
  ...(showReportAction ? [reportActionButton] : []),
  cancelActionButton,
];

export const getMediaActionButtons = () => [
  photoActionButton,
  videoActionButton,
  cancelActionButton,
];

export const authorIsUser = (user, comment) => user.id === comment.author_id;
export const authorIsAdmin = (comment) => comment.author.role === 'admin';
