import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const defaultStyles = {
  width: '100%',
  textAlign: 'center',
  color: '#000000',
};

const TitleAtom = ({
  header,
  subHeader,
  stylesOverride,
  hasLargeSubheader,
}) => {
  const {
    styles: defaultStylesOverride,
    headerStyles: titleStylesOverride,
    subHeaderStyles: subHeaderStylesOverride,
  } = stylesOverride;
  const largerSubheader = hasLargeSubheader && {
    fontWeight: '400',
    fontSize: '36px',
  };
  const wmpi = hasLargeSubheader && {
    marginTop: '60px',
  };

  return (
    <Stack
      spacing={1}
      sx={{
        ...defaultStyles,
        ...defaultStylesOverride,
        ...wmpi,
      }}
    >
      <Typography
        as="h1"
        style={{
          fontWeight: '700',
          fontSize: '28px',
          fontFamily: 'Sarabun, arial, sans-serif',
          ...titleStylesOverride,
        }}
      >
        {header}
      </Typography>
      {subHeader && (
        <Typography
          as="h2"
          sx={{
            fontSize: '20px',
            fontFamily: 'Sarabun, arial, sans-serif',
            ...subHeaderStylesOverride,
            ...largerSubheader,
          }}
        >
          {subHeader}
        </Typography>
      )}
    </Stack>
  );
};

export const TitleAtomPropTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  stylesOverride: PropTypes.oneOfType([PropTypes.object]),
};

export const TitleAtomDefaultProps = {
  subHeader: '',
  stylesOverride: { styles: {}, headerStyles: {}, subHeaderStyles: {} },
};

TitleAtom.propTypes = TitleAtomPropTypes;
TitleAtom.defaultProps = TitleAtomDefaultProps;
export default TitleAtom;
