import React from 'react';
import {
  IonPage,
  IonContent,
  IonIcon,
  IonRouterLink,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
} from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';

import './NoTeamPage.css';
import { Box, Stack, Typography } from '@mui/material';

const NoTeamPage = () => (
  <IonPage>
    <IonHeader className="ion-no-border">
      <IonToolbar
        className="back-button-toolbar"
        style={{ '--background': 'transparent' }}
      >
        <IonButtons slot="start">
          <IonRouterLink routerLink="/tabs/feed">
            <IonButton>
              <IonIcon
                style={{ fontSize: '1.25rem' }}
                slot="icon-only"
                src="/assets/back-arrow-white.svg"
              />
            </IonButton>
          </IonRouterLink>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent style={{ '--background': '#03295F' }} fullscreen>
      <Box
        sx={{
          position: 'absolute',
          bgcolor: '#063d8f',
          width: 150,
          height: 150,
          top: -90,
          right: -25,
          zIndex: 10,
          borderRadius: '50%',
        }}
      />
      <Stack
        spacing={2}
        sx={{
          color: '#fff',
          mx: 3,
          mt: 3,
          alignItems: 'center',
        }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: 24,
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Matching in Progress
        </Typography>
        <Typography
          sx={{ maxWidth: '34ch', lineHeight: 1.25, textAlign: 'center' }}
        >
          You have completed the Backrs Checklist! While you wait for proteges
          to invite you to their team, explore our community!
        </Typography>
      </Stack>
      <Box mt={3}>
        <Box position="relative">
          <img
            src="/assets/matching_shape_1.svg"
            alt=""
            role="presentation"
            className="decorative-shape decorative-shape-1"
          />
          <Box className="decorative-shape decorative-shape-2" />
          <img
            src="/assets/matching_shape_3.svg"
            alt=""
            role="presentation"
            className="decorative-shape decorative-shape-3"
          />
          <img
            src="/assets/watered-plant.svg"
            alt="plant being watered"
            className="no-team-plant-img"
          />
        </Box>
        <Box
          sx={{
            p: 2,
            mx: 3,
            mt: -0.2,
            bgcolor: '#063d8f',
            borderRadius: 2,
          }}
        >
          <IonRouterLink routerLink="/tabs/feed" style={{ color: '#fff' }}>
            <div className="goals-create-card">
              <img
                className="card-leading-icon"
                src="/assets/circle-senses.svg"
                alt="circle of senses"
              />
              <div className="goals-create-card-message">
                <Typography
                  component="h2"
                  sx={{
                    position: 'relative',
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                >
                  Explore the community
                  <IonIcon
                    style={{
                      position: 'relative',
                      top: '5px',
                      fontSize: '1.25rem',
                      marginLeft: '4px',
                    }}
                    src={arrowForwardOutline}
                  />
                </Typography>
                <Typography
                  sx={{ maxWidth: '30ch', fontSize: 13, lineHeight: 1.25 }}
                  mt={0.5}
                >
                  Now that you&apos;ve completed your checklist, you&apos;re free
                  to move about the Backrs platform.
                </Typography>
              </div>
            </div>
          </IonRouterLink>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          mx: 3,
          mt: 2.5,
          mb: 5,
          bgcolor: '#063d8f',
          color: '#fff',
          borderRadius: 2,
        }}
      >
        <IonRouterLink routerLink="/backing-is-action" style={{ color: '#fff' }}>
          <div className="goals-create-card">
            <img
              className="card-leading-icon card-leading-icon-shrink"
              src="/assets/folder.svg"
              alt="folder"
            />
            <div className="goals-create-card-message">
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: '1rem !important',
                  fontWeight: '700 !important',
                }}
              >
                Learn how to back
                <IonIcon
                  style={{
                    position: 'relative',
                    top: '5px',
                    fontSize: '1.25rem',
                    marginLeft: '4px',
                  }}
                  src={arrowForwardOutline}
                />
              </Typography>
              <Typography
                sx={{ maxWidth: '30ch', fontSize: 13, lineHeight: 1.25 }}
                mt={0.5}
              >
                We&apos;ve curated videos and articles to support you in being an
                awersome backr! Modules can be completed in any order, at any
                time.
              </Typography>
            </div>
          </div>
        </IonRouterLink>
      </Box>
    </IonContent>
  </IonPage>
);

export default NoTeamPage;
