export const defaultPillStyles = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20px',
  padding: '1px 6px',
  minWidth: '50px',
  width: 'fit-content',
  borderRadius: '16px',
  backgroundColor: '#E8E8DC',
  color: '#848484',
  fontWeight: 700,
  fontSize: '12px',
  WebkitTapHighlightColor: 'transparent',
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  outline: 'none !important',
};

export const selectablePillIconStyles = {
  height: '12px',
  width: '12px',
  margin: '0 5px 5px -5px',
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
};

export const boxStyles = {
  minWidth: '14px',
  height: '14px',
  background: '#E8E8DC',
  color: '#03295F',
  fontSize: '9px',
  fontWeight: 700,
  order: 1,
  marginRight: '5px',
  marginLeft: '-6px',
  lineHeight: 1.5625,
  textAlign: 'center',
  borderRadius: '100px',
};

export const pillTextStyles = {
  padding: '0 10px',
  marginBottom: '2px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
};

export const selectablePillTextStyles = {
  padding: '0 14px 0 10px',
};
